import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(axios, {
  // Endpoints
  loginEndpoint: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + process.env.VUE_APP_WEB_API_KEY,
  registerEndpoint: 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + process.env.VUE_APP_WEB_API_KEY,
  refreshEndpoint: 'https://securetoken.googleapis.com/v1/token?key=' + process.env.VUE_APP_WEB_API_KEY,
  logoutEndpoint: '/jwt/logout',
})
export default jwt