import mock from './mock'

/* eslint-disable import/extensions */
// Apps
import './data/apps/customer'
import './data/apps/employee'
import './data/apps/treatment'
import './data/apps/service'
import './data/apps/address'


import './data/dashboard/ecommerce'
import './data/dashboard/analytics'

/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network