import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = [{
    "Ortschaftsname": "Aeugst am Albis",
    "PLZ": "8914",
    "Zusatzziffer": "0",
    "Gemeindename": "Aeugst am Albis",
    "BFS-Nr": "1",
    "Kantonskürzel": "ZH",
    "E": "2679435.8170000017",
    "N": "1235653.1849999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aeugstertal",
    "PLZ": "8914",
    "Zusatzziffer": "2",
    "Gemeindename": "Aeugst am Albis",
    "BFS-Nr": "1",
    "Kantonskürzel": "ZH",
    "E": "2679815.4750000015",
    "N": "1237410.2149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zwillikon",
    "PLZ": "8909",
    "Zusatzziffer": "0",
    "Gemeindename": "Affoltern am Albis",
    "BFS-Nr": "2",
    "Kantonskürzel": "ZH",
    "E": "2675104.5190000013",
    "N": "1237890.4880000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Affoltern am Albis",
    "PLZ": "8910",
    "Zusatzziffer": "0",
    "Gemeindename": "Affoltern am Albis",
    "BFS-Nr": "2",
    "Kantonskürzel": "ZH",
    "E": "2676439.357999999",
    "N": "1236966.3709999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bonstetten",
    "PLZ": "8906",
    "Zusatzziffer": "0",
    "Gemeindename": "Bonstetten",
    "BFS-Nr": "3",
    "Kantonskürzel": "ZH",
    "E": "2677798.5709999986",
    "N": "1241024.8429999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hausen am Albis",
    "PLZ": "8915",
    "Zusatzziffer": "0",
    "Gemeindename": "Hausen am Albis",
    "BFS-Nr": "4",
    "Kantonskürzel": "ZH",
    "E": "2682996.333999999",
    "N": "1232961.6499999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ebertswil",
    "PLZ": "8925",
    "Zusatzziffer": "0",
    "Gemeindename": "Hausen am Albis",
    "BFS-Nr": "4",
    "Kantonskürzel": "ZH",
    "E": "2684168.704",
    "N": "1231176.6389999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hedingen",
    "PLZ": "8908",
    "Zusatzziffer": "0",
    "Gemeindename": "Hedingen",
    "BFS-Nr": "5",
    "Kantonskürzel": "ZH",
    "E": "2676518.511",
    "N": "1239054.3000000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kappel am Albis",
    "PLZ": "8926",
    "Zusatzziffer": "0",
    "Gemeindename": "Kappel am Albis",
    "BFS-Nr": "6",
    "Kantonskürzel": "ZH",
    "E": "2682261.5689999983",
    "N": "1231284.4239999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hauptikon",
    "PLZ": "8926",
    "Zusatzziffer": "2",
    "Gemeindename": "Kappel am Albis",
    "BFS-Nr": "6",
    "Kantonskürzel": "ZH",
    "E": "2680023.421",
    "N": "1231589.697999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uerzlikon",
    "PLZ": "8926",
    "Zusatzziffer": "3",
    "Gemeindename": "Kappel am Albis",
    "BFS-Nr": "6",
    "Kantonskürzel": "ZH",
    "E": "2680340.965999998",
    "N": "1230665.1000000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Knonau",
    "PLZ": "8934",
    "Zusatzziffer": "0",
    "Gemeindename": "Knonau",
    "BFS-Nr": "7",
    "Kantonskürzel": "ZH",
    "E": "2677402.530000001",
    "N": "1230821.8949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Maschwanden",
    "PLZ": "8933",
    "Zusatzziffer": "0",
    "Gemeindename": "Maschwanden",
    "BFS-Nr": "8",
    "Kantonskürzel": "ZH",
    "E": "2674785.6380000003",
    "N": "1232061.1039999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mettmenstetten",
    "PLZ": "8932",
    "Zusatzziffer": "0",
    "Gemeindename": "Mettmenstetten",
    "BFS-Nr": "9",
    "Kantonskürzel": "ZH",
    "E": "2677613.728",
    "N": "1232937.7749999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obfelden",
    "PLZ": "8912",
    "Zusatzziffer": "0",
    "Gemeindename": "Obfelden",
    "BFS-Nr": "10",
    "Kantonskürzel": "ZH",
    "E": "2674501.5700000003",
    "N": "1235213.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ottenbach",
    "PLZ": "8913",
    "Zusatzziffer": "0",
    "Gemeindename": "Ottenbach",
    "BFS-Nr": "11",
    "Kantonskürzel": "ZH",
    "E": "2673060.1900000013",
    "N": "1237324.4100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rifferswil",
    "PLZ": "8911",
    "Zusatzziffer": "0",
    "Gemeindename": "Rifferswil",
    "BFS-Nr": "12",
    "Kantonskürzel": "ZH",
    "E": "2680092.7639999986",
    "N": "1233046.1469999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stallikon",
    "PLZ": "8143",
    "Zusatzziffer": "0",
    "Gemeindename": "Stallikon",
    "BFS-Nr": "13",
    "Kantonskürzel": "ZH",
    "E": "2679551.151999999",
    "N": "1242199.329",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uetliberg",
    "PLZ": "8143",
    "Zusatzziffer": "2",
    "Gemeindename": "Stallikon",
    "BFS-Nr": "13",
    "Kantonskürzel": "ZH",
    "E": "2679542.2190000005",
    "N": "1244824.9580000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wettswil",
    "PLZ": "8907",
    "Zusatzziffer": "0",
    "Gemeindename": "Wettswil am Albis",
    "BFS-Nr": "14",
    "Kantonskürzel": "ZH",
    "E": "2678321.2749999985",
    "N": "1243534.5769999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Adlikon b. Andelfingen",
    "PLZ": "8452",
    "Zusatzziffer": "0",
    "Gemeindename": "Adlikon",
    "BFS-Nr": "21",
    "Kantonskürzel": "ZH",
    "E": "2694310.927000001",
    "N": "1271012.8409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Benken ZH",
    "PLZ": "8463",
    "Zusatzziffer": "0",
    "Gemeindename": "Benken (ZH)",
    "BFS-Nr": "22",
    "Kantonskürzel": "ZH",
    "E": "2691310.5100000016",
    "N": "1278717.046",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berg am Irchel",
    "PLZ": "8415",
    "Zusatzziffer": "0",
    "Gemeindename": "Berg am Irchel",
    "BFS-Nr": "23",
    "Kantonskürzel": "ZH",
    "E": "2687249.2300000004",
    "N": "1269506.0879999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gräslikon",
    "PLZ": "8415",
    "Zusatzziffer": "1",
    "Gemeindename": "Berg am Irchel",
    "BFS-Nr": "23",
    "Kantonskürzel": "ZH",
    "E": "2687807.9439999983",
    "N": "1268052.9820000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buch am Irchel",
    "PLZ": "8414",
    "Zusatzziffer": "0",
    "Gemeindename": "Buch am Irchel",
    "BFS-Nr": "24",
    "Kantonskürzel": "ZH",
    "E": "2689123.458999999",
    "N": "1267147.9039999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dachsen",
    "PLZ": "8447",
    "Zusatzziffer": "0",
    "Gemeindename": "Dachsen",
    "BFS-Nr": "25",
    "Kantonskürzel": "ZH",
    "E": "2688270.1510000005",
    "N": "1280253.563000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dorf",
    "PLZ": "8458",
    "Zusatzziffer": "0",
    "Gemeindename": "Dorf",
    "BFS-Nr": "26",
    "Kantonskürzel": "ZH",
    "E": "2690995.859000001",
    "N": "1269698.7589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Feuerthalen",
    "PLZ": "8245",
    "Zusatzziffer": "0",
    "Gemeindename": "Feuerthalen",
    "BFS-Nr": "27",
    "Kantonskürzel": "ZH",
    "E": "2690270.7190000005",
    "N": "1282950.164999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langwiesen",
    "PLZ": "8246",
    "Zusatzziffer": "0",
    "Gemeindename": "Feuerthalen",
    "BFS-Nr": "27",
    "Kantonskürzel": "ZH",
    "E": "2691950.484000001",
    "N": "1282201.614",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flaach",
    "PLZ": "8416",
    "Zusatzziffer": "0",
    "Gemeindename": "Flaach",
    "BFS-Nr": "28",
    "Kantonskürzel": "ZH",
    "E": "2687597.7509999983",
    "N": "1269830.6510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flurlingen",
    "PLZ": "8247",
    "Zusatzziffer": "0",
    "Gemeindename": "Flurlingen",
    "BFS-Nr": "29",
    "Kantonskürzel": "ZH",
    "E": "2689293.004999999",
    "N": "1282181.5540000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Andelfingen",
    "PLZ": "8450",
    "Zusatzziffer": "0",
    "Gemeindename": "Andelfingen",
    "BFS-Nr": "30",
    "Kantonskürzel": "ZH",
    "E": "2693252.732999999",
    "N": "1272313.4629999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Henggart",
    "PLZ": "8444",
    "Zusatzziffer": "0",
    "Gemeindename": "Henggart",
    "BFS-Nr": "31",
    "Kantonskürzel": "ZH",
    "E": "2693670.004999999",
    "N": "1268741.7239999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Humlikon",
    "PLZ": "8457",
    "Zusatzziffer": "0",
    "Gemeindename": "Humlikon",
    "BFS-Nr": "32",
    "Kantonskürzel": "ZH",
    "E": "2692613.574000001",
    "N": "1270162.3399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleinandelfingen",
    "PLZ": "8451",
    "Zusatzziffer": "0",
    "Gemeindename": "Kleinandelfingen",
    "BFS-Nr": "33",
    "Kantonskürzel": "ZH",
    "E": "2693580.745000001",
    "N": "1272835.3299999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alten",
    "PLZ": "8453",
    "Zusatzziffer": "0",
    "Gemeindename": "Kleinandelfingen",
    "BFS-Nr": "33",
    "Kantonskürzel": "ZH",
    "E": "2690909.103",
    "N": "1272782.1259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oerlingen",
    "PLZ": "8461",
    "Zusatzziffer": "0",
    "Gemeindename": "Kleinandelfingen",
    "BFS-Nr": "33",
    "Kantonskürzel": "ZH",
    "E": "2693030.9470000006",
    "N": "1275546.2369999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nohl",
    "PLZ": "8212",
    "Zusatzziffer": "4",
    "Gemeindename": "Laufen-Uhwiesen",
    "BFS-Nr": "34",
    "Kantonskürzel": "ZH",
    "E": "2687813.2380000018",
    "N": "1280577.243999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uhwiesen",
    "PLZ": "8248",
    "Zusatzziffer": "0",
    "Gemeindename": "Laufen-Uhwiesen",
    "BFS-Nr": "34",
    "Kantonskürzel": "ZH",
    "E": "2689803.243999999",
    "N": "1280733.3460000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Marthalen",
    "PLZ": "8460",
    "Zusatzziffer": "0",
    "Gemeindename": "Marthalen",
    "BFS-Nr": "35",
    "Kantonskürzel": "ZH",
    "E": "2690936.245000001",
    "N": "1275649.4059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ellikon am Rhein",
    "PLZ": "8464",
    "Zusatzziffer": "0",
    "Gemeindename": "Marthalen",
    "BFS-Nr": "35",
    "Kantonskürzel": "ZH",
    "E": "2687135.142000001",
    "N": "1273399.0799999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ossingen",
    "PLZ": "8475",
    "Zusatzziffer": "0",
    "Gemeindename": "Ossingen",
    "BFS-Nr": "37",
    "Kantonskürzel": "ZH",
    "E": "2696777.454999998",
    "N": "1274089.2259999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rheinau",
    "PLZ": "8462",
    "Zusatzziffer": "0",
    "Gemeindename": "Rheinau",
    "BFS-Nr": "38",
    "Kantonskürzel": "ZH",
    "E": "2687521.2479999997",
    "N": "1277681.5379999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thalheim an der Thur",
    "PLZ": "8478",
    "Zusatzziffer": "0",
    "Gemeindename": "Thalheim an der Thur",
    "BFS-Nr": "39",
    "Kantonskürzel": "ZH",
    "E": "2698983.9979999997",
    "N": "1270637.5810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rudolfingen",
    "PLZ": "8465",
    "Zusatzziffer": "0",
    "Gemeindename": "Trüllikon",
    "BFS-Nr": "40",
    "Kantonskürzel": "ZH",
    "E": "2692789.3819999993",
    "N": "1277363.7109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wildensbuch",
    "PLZ": "8465",
    "Zusatzziffer": "1",
    "Gemeindename": "Trüllikon",
    "BFS-Nr": "40",
    "Kantonskürzel": "ZH",
    "E": "2692956.1330000013",
    "N": "1278630.7289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trüllikon",
    "PLZ": "8466",
    "Zusatzziffer": "0",
    "Gemeindename": "Trüllikon",
    "BFS-Nr": "40",
    "Kantonskürzel": "ZH",
    "E": "2694209.7509999983",
    "N": "1277042.3480000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Truttikon",
    "PLZ": "8467",
    "Zusatzziffer": "0",
    "Gemeindename": "Truttikon",
    "BFS-Nr": "41",
    "Kantonskürzel": "ZH",
    "E": "2696797.7399999984",
    "N": "1276290.1750000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Volken",
    "PLZ": "8459",
    "Zusatzziffer": "0",
    "Gemeindename": "Volken",
    "BFS-Nr": "43",
    "Kantonskürzel": "ZH",
    "E": "2689565.9580000006",
    "N": "1269805.068",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bachenbülach",
    "PLZ": "8184",
    "Zusatzziffer": "0",
    "Gemeindename": "Bachenbülach",
    "BFS-Nr": "51",
    "Kantonskürzel": "ZH",
    "E": "2683462.274",
    "N": "1262012.8949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bassersdorf",
    "PLZ": "8303",
    "Zusatzziffer": "0",
    "Gemeindename": "Bassersdorf",
    "BFS-Nr": "52",
    "Kantonskürzel": "ZH",
    "E": "2689695.006000001",
    "N": "1255514.4789999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bülach",
    "PLZ": "8180",
    "Zusatzziffer": "0",
    "Gemeindename": "Bülach",
    "BFS-Nr": "53",
    "Kantonskürzel": "ZH",
    "E": "2683021.829999998",
    "N": "1263508.0659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dietlikon",
    "PLZ": "8305",
    "Zusatzziffer": "0",
    "Gemeindename": "Dietlikon",
    "BFS-Nr": "54",
    "Kantonskürzel": "ZH",
    "E": "2688817.0119999982",
    "N": "1253412.9809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eglisau",
    "PLZ": "8193",
    "Zusatzziffer": "0",
    "Gemeindename": "Eglisau",
    "BFS-Nr": "55",
    "Kantonskürzel": "ZH",
    "E": "2681815.6790000014",
    "N": "1269952.9140000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Embrach",
    "PLZ": "8424",
    "Zusatzziffer": "0",
    "Gemeindename": "Embrach",
    "BFS-Nr": "56",
    "Kantonskürzel": "ZH",
    "E": "2687141.458999999",
    "N": "1261805.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Freienstein",
    "PLZ": "8427",
    "Zusatzziffer": "2",
    "Gemeindename": "Freienstein-Teufen",
    "BFS-Nr": "57",
    "Kantonskürzel": "ZH",
    "E": "2686761.5040000007",
    "N": "1265290.239",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Teufen ZH",
    "PLZ": "8428",
    "Zusatzziffer": "0",
    "Gemeindename": "Freienstein-Teufen",
    "BFS-Nr": "57",
    "Kantonskürzel": "ZH",
    "E": "2684966.796",
    "N": "1267185.2690000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Glattfelden",
    "PLZ": "8192",
    "Zusatzziffer": "0",
    "Gemeindename": "Glattfelden",
    "BFS-Nr": "58",
    "Kantonskürzel": "ZH",
    "E": "2679801.9569999985",
    "N": "1268104.9809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zweidlen",
    "PLZ": "8192",
    "Zusatzziffer": "1",
    "Gemeindename": "Glattfelden",
    "BFS-Nr": "58",
    "Kantonskürzel": "ZH",
    "E": "2677369.414000001",
    "N": "1268307.6609999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hochfelden",
    "PLZ": "8182",
    "Zusatzziffer": "0",
    "Gemeindename": "Hochfelden",
    "BFS-Nr": "59",
    "Kantonskürzel": "ZH",
    "E": "2681099.6909999996",
    "N": "1264180.5260000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Höri",
    "PLZ": "8181",
    "Zusatzziffer": "0",
    "Gemeindename": "Höri",
    "BFS-Nr": "60",
    "Kantonskürzel": "ZH",
    "E": "2680594.4800000004",
    "N": "1262401.7360000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hüntwangen",
    "PLZ": "8194",
    "Zusatzziffer": "0",
    "Gemeindename": "Hüntwangen",
    "BFS-Nr": "61",
    "Kantonskürzel": "ZH",
    "E": "2679293.493999999",
    "N": "1272172.4609999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kloten",
    "PLZ": "8302",
    "Zusatzziffer": "0",
    "Gemeindename": "Kloten",
    "BFS-Nr": "62",
    "Kantonskürzel": "ZH",
    "E": "2686403.335999999",
    "N": "1256379.7699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lufingen",
    "PLZ": "8426",
    "Zusatzziffer": "0",
    "Gemeindename": "Lufingen",
    "BFS-Nr": "63",
    "Kantonskürzel": "ZH",
    "E": "2687042.412999999",
    "N": "1260600.1279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nürensdorf",
    "PLZ": "8309",
    "Zusatzziffer": "0",
    "Gemeindename": "Nürensdorf",
    "BFS-Nr": "64",
    "Kantonskürzel": "ZH",
    "E": "2691300.232000001",
    "N": "1255946.8399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberembrach",
    "PLZ": "8425",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberembrach",
    "BFS-Nr": "65",
    "Kantonskürzel": "ZH",
    "E": "2688837.840999998",
    "N": "1260294.0410000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Glattbrugg",
    "PLZ": "8152",
    "Zusatzziffer": "0",
    "Gemeindename": "Opfikon",
    "BFS-Nr": "66",
    "Kantonskürzel": "ZH",
    "E": "2685213.0080000013",
    "N": "1254283.7820000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Opfikon",
    "PLZ": "8152",
    "Zusatzziffer": "1",
    "Gemeindename": "Opfikon",
    "BFS-Nr": "66",
    "Kantonskürzel": "ZH",
    "E": "2685912.2760000005",
    "N": "1254113.3520000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Glattpark (Opfikon)",
    "PLZ": "8152",
    "Zusatzziffer": "2",
    "Gemeindename": "Opfikon",
    "BFS-Nr": "66",
    "Kantonskürzel": "ZH",
    "E": "2684738.5760000013",
    "N": "1253099.5489999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rafz",
    "PLZ": "8197",
    "Zusatzziffer": "0",
    "Gemeindename": "Rafz",
    "BFS-Nr": "67",
    "Kantonskürzel": "ZH",
    "E": "2682428.159000002",
    "N": "1274321.568",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rorbas",
    "PLZ": "8427",
    "Zusatzziffer": "3",
    "Gemeindename": "Rorbas",
    "BFS-Nr": "68",
    "Kantonskürzel": "ZH",
    "E": "2685808.5309999995",
    "N": "1264872.1400000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wallisellen",
    "PLZ": "8304",
    "Zusatzziffer": "0",
    "Gemeindename": "Wallisellen",
    "BFS-Nr": "69",
    "Kantonskürzel": "ZH",
    "E": "2687028.296",
    "N": "1251957.2989999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wasterkingen",
    "PLZ": "8195",
    "Zusatzziffer": "0",
    "Gemeindename": "Wasterkingen",
    "BFS-Nr": "70",
    "Kantonskürzel": "ZH",
    "E": "2677774.329",
    "N": "1271560.3530000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wil ZH",
    "PLZ": "8196",
    "Zusatzziffer": "0",
    "Gemeindename": "Wil (ZH)",
    "BFS-Nr": "71",
    "Kantonskürzel": "ZH",
    "E": "2680078.234000001",
    "N": "1272987.4400000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winkel",
    "PLZ": "8185",
    "Zusatzziffer": "0",
    "Gemeindename": "Winkel",
    "BFS-Nr": "72",
    "Kantonskürzel": "ZH",
    "E": "2684086.006000001",
    "N": "1260790.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bachs",
    "PLZ": "8164",
    "Zusatzziffer": "0",
    "Gemeindename": "Bachs",
    "BFS-Nr": "81",
    "Kantonskürzel": "ZH",
    "E": "2675343.7540000007",
    "N": "1264293.432",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Boppelsen",
    "PLZ": "8113",
    "Zusatzziffer": "0",
    "Gemeindename": "Boppelsen",
    "BFS-Nr": "82",
    "Kantonskürzel": "ZH",
    "E": "2672671.3170000017",
    "N": "1258197.0890000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchs ZH",
    "PLZ": "8107",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchs (ZH)",
    "BFS-Nr": "83",
    "Kantonskürzel": "ZH",
    "E": "2675366.427000001",
    "N": "1256927.3170000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dällikon",
    "PLZ": "8108",
    "Zusatzziffer": "0",
    "Gemeindename": "Dällikon",
    "BFS-Nr": "84",
    "Kantonskürzel": "ZH",
    "E": "2675488.101",
    "N": "1254829.539999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dänikon ZH",
    "PLZ": "8114",
    "Zusatzziffer": "0",
    "Gemeindename": "Dänikon",
    "BFS-Nr": "85",
    "Kantonskürzel": "ZH",
    "E": "2672916.9459999986",
    "N": "1255465.7490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dielsdorf",
    "PLZ": "8157",
    "Zusatzziffer": "0",
    "Gemeindename": "Dielsdorf",
    "BFS-Nr": "86",
    "Kantonskürzel": "ZH",
    "E": "2676493.291000001",
    "N": "1259763.9389999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hüttikon",
    "PLZ": "8115",
    "Zusatzziffer": "0",
    "Gemeindename": "Hüttikon",
    "BFS-Nr": "87",
    "Kantonskürzel": "ZH",
    "E": "2671541.3630000018",
    "N": "1255465.1160000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neerach",
    "PLZ": "8173",
    "Zusatzziffer": "0",
    "Gemeindename": "Neerach",
    "BFS-Nr": "88",
    "Kantonskürzel": "ZH",
    "E": "2678030.4169999994",
    "N": "1263041.392000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederglatt ZH",
    "PLZ": "8172",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederglatt",
    "BFS-Nr": "89",
    "Kantonskürzel": "ZH",
    "E": "2680253.6209999993",
    "N": "1260177.561999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederhasli",
    "PLZ": "8155",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederhasli",
    "BFS-Nr": "90",
    "Kantonskürzel": "ZH",
    "E": "2678776.3249999993",
    "N": "1259586.3150000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nassenwil",
    "PLZ": "8155",
    "Zusatzziffer": "1",
    "Gemeindename": "Niederhasli",
    "BFS-Nr": "90",
    "Kantonskürzel": "ZH",
    "E": "2677870.3260000013",
    "N": "1257732.6449999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhasli",
    "PLZ": "8156",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederhasli",
    "BFS-Nr": "90",
    "Kantonskürzel": "ZH",
    "E": "2679892.3249999993",
    "N": "1257721.4450000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederweningen",
    "PLZ": "8166",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederweningen",
    "BFS-Nr": "91",
    "Kantonskürzel": "ZH",
    "E": "2670093.1180000007",
    "N": "1261902.881000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberglatt ZH",
    "PLZ": "8154",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberglatt",
    "BFS-Nr": "92",
    "Kantonskürzel": "ZH",
    "E": "2681567.1790000014",
    "N": "1258991.046",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberweningen",
    "PLZ": "8165",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberweningen",
    "BFS-Nr": "93",
    "Kantonskürzel": "ZH",
    "E": "2672985.153000001",
    "N": "1261896.8850000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Otelfingen",
    "PLZ": "8112",
    "Zusatzziffer": "0",
    "Gemeindename": "Otelfingen",
    "BFS-Nr": "94",
    "Kantonskürzel": "ZH",
    "E": "2671517.0199999996",
    "N": "1257223.6099999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Regensberg",
    "PLZ": "8158",
    "Zusatzziffer": "0",
    "Gemeindename": "Regensberg",
    "BFS-Nr": "95",
    "Kantonskürzel": "ZH",
    "E": "2675383.7060000002",
    "N": "1259633.8090000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Regensdorf",
    "PLZ": "8105",
    "Zusatzziffer": "0",
    "Gemeindename": "Regensdorf",
    "BFS-Nr": "96",
    "Kantonskürzel": "ZH",
    "E": "2677477.967",
    "N": "1253827.0989999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Watt",
    "PLZ": "8105",
    "Zusatzziffer": "2",
    "Gemeindename": "Regensdorf",
    "BFS-Nr": "96",
    "Kantonskürzel": "ZH",
    "E": "2678645.1999999993",
    "N": "1255191.0859999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Adlikon b. Regensdorf",
    "PLZ": "8106",
    "Zusatzziffer": "2",
    "Gemeindename": "Regensdorf",
    "BFS-Nr": "96",
    "Kantonskürzel": "ZH",
    "E": "2677521.2639999986",
    "N": "1255768.4279999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rümlang",
    "PLZ": "8153",
    "Zusatzziffer": "0",
    "Gemeindename": "Rümlang",
    "BFS-Nr": "97",
    "Kantonskürzel": "ZH",
    "E": "2682508.3720000014",
    "N": "1256231",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schleinikon",
    "PLZ": "8165",
    "Zusatzziffer": "3",
    "Gemeindename": "Schleinikon",
    "BFS-Nr": "98",
    "Kantonskürzel": "ZH",
    "E": "2671798.3280000016",
    "N": "1261250.7639999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schöfflisdorf",
    "PLZ": "8165",
    "Zusatzziffer": "0",
    "Gemeindename": "Schöfflisdorf",
    "BFS-Nr": "99",
    "Kantonskürzel": "ZH",
    "E": "2673707.449000001",
    "N": "1261493.386",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stadel b. Niederglatt",
    "PLZ": "8174",
    "Zusatzziffer": "0",
    "Gemeindename": "Stadel",
    "BFS-Nr": "100",
    "Kantonskürzel": "ZH",
    "E": "2677374.5089999996",
    "N": "1264551.4560000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Windlach",
    "PLZ": "8175",
    "Zusatzziffer": "0",
    "Gemeindename": "Stadel",
    "BFS-Nr": "100",
    "Kantonskürzel": "ZH",
    "E": "2678023.5890000015",
    "N": "1266117.4820000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinmaur",
    "PLZ": "8162",
    "Zusatzziffer": "0",
    "Gemeindename": "Steinmaur",
    "BFS-Nr": "101",
    "Kantonskürzel": "ZH",
    "E": "2676631.158",
    "N": "1261425.7710000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sünikon",
    "PLZ": "8162",
    "Zusatzziffer": "1",
    "Gemeindename": "Steinmaur",
    "BFS-Nr": "101",
    "Kantonskürzel": "ZH",
    "E": "2675153.9499999993",
    "N": "1260470.886",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weiach",
    "PLZ": "8187",
    "Zusatzziffer": "0",
    "Gemeindename": "Weiach",
    "BFS-Nr": "102",
    "Kantonskürzel": "ZH",
    "E": "2675209.778999999",
    "N": "1267912.5009999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bäretswil",
    "PLZ": "8344",
    "Zusatzziffer": "0",
    "Gemeindename": "Bäretswil",
    "BFS-Nr": "111",
    "Kantonskürzel": "ZH",
    "E": "2707099.767999999",
    "N": "1243930.6279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Adetswil",
    "PLZ": "8345",
    "Zusatzziffer": "0",
    "Gemeindename": "Bäretswil",
    "BFS-Nr": "111",
    "Kantonskürzel": "ZH",
    "E": "2705932.4800000004",
    "N": "1244241.6180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bubikon",
    "PLZ": "8608",
    "Zusatzziffer": "0",
    "Gemeindename": "Bubikon",
    "BFS-Nr": "112",
    "Kantonskürzel": "ZH",
    "E": "2704320.3319999985",
    "N": "1236261.1959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wolfhausen",
    "PLZ": "8633",
    "Zusatzziffer": "0",
    "Gemeindename": "Bubikon",
    "BFS-Nr": "112",
    "Kantonskürzel": "ZH",
    "E": "2703047.6130000018",
    "N": "1234854.4470000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tann",
    "PLZ": "8632",
    "Zusatzziffer": "0",
    "Gemeindename": "Dürnten",
    "BFS-Nr": "113",
    "Kantonskürzel": "ZH",
    "E": "2706928.524",
    "N": "1235700.1640000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dürnten",
    "PLZ": "8635",
    "Zusatzziffer": "0",
    "Gemeindename": "Dürnten",
    "BFS-Nr": "113",
    "Kantonskürzel": "ZH",
    "E": "2706266.4219999984",
    "N": "1237310.7210000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steg im Tösstal",
    "PLZ": "8496",
    "Zusatzziffer": "0",
    "Gemeindename": "Fischenthal",
    "BFS-Nr": "114",
    "Kantonskürzel": "ZH",
    "E": "2712851.5359999985",
    "N": "1245858.5199999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fischenthal",
    "PLZ": "8497",
    "Zusatzziffer": "0",
    "Gemeindename": "Fischenthal",
    "BFS-Nr": "114",
    "Kantonskürzel": "ZH",
    "E": "2711914.208999999",
    "N": "1243345.454",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gibswil",
    "PLZ": "8498",
    "Zusatzziffer": "0",
    "Gemeindename": "Fischenthal",
    "BFS-Nr": "114",
    "Kantonskürzel": "ZH",
    "E": "2711646.0260000005",
    "N": "1241391.824000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bertschikon (Gossau ZH)",
    "PLZ": "8614",
    "Zusatzziffer": "0",
    "Gemeindename": "Gossau (ZH)",
    "BFS-Nr": "115",
    "Kantonskürzel": "ZH",
    "E": "2699688.1499999985",
    "N": "1241957.4910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grüt (Gossau ZH)",
    "PLZ": "8624",
    "Zusatzziffer": "0",
    "Gemeindename": "Gossau (ZH)",
    "BFS-Nr": "115",
    "Kantonskürzel": "ZH",
    "E": "2701575.8440000005",
    "N": "1240888.1579999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gossau ZH",
    "PLZ": "8625",
    "Zusatzziffer": "0",
    "Gemeindename": "Gossau (ZH)",
    "BFS-Nr": "115",
    "Kantonskürzel": "ZH",
    "E": "2700134.8740000017",
    "N": "1239751.313000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ottikon (Gossau ZH)",
    "PLZ": "8626",
    "Zusatzziffer": "0",
    "Gemeindename": "Gossau (ZH)",
    "BFS-Nr": "115",
    "Kantonskürzel": "ZH",
    "E": "2701404.153999999",
    "N": "1239266.307",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grüningen",
    "PLZ": "8627",
    "Zusatzziffer": "0",
    "Gemeindename": "Grüningen",
    "BFS-Nr": "116",
    "Kantonskürzel": "ZH",
    "E": "2700106.3110000007",
    "N": "1237944.3460000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hinwil",
    "PLZ": "8340",
    "Zusatzziffer": "0",
    "Gemeindename": "Hinwil",
    "BFS-Nr": "117",
    "Kantonskürzel": "ZH",
    "E": "2706524.739",
    "N": "1239877.859000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wernetshausen",
    "PLZ": "8342",
    "Zusatzziffer": "0",
    "Gemeindename": "Hinwil",
    "BFS-Nr": "117",
    "Kantonskürzel": "ZH",
    "E": "2707983.7690000013",
    "N": "1239658.4739999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüti ZH",
    "PLZ": "8630",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüti (ZH)",
    "BFS-Nr": "118",
    "Kantonskürzel": "ZH",
    "E": "2706756.409000002",
    "N": "1235203.6730000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aathal-Seegräben",
    "PLZ": "8607",
    "Zusatzziffer": "0",
    "Gemeindename": "Seegräben",
    "BFS-Nr": "119",
    "Kantonskürzel": "ZH",
    "E": "2700585.8550000004",
    "N": "1244029.1050000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wald ZH",
    "PLZ": "8636",
    "Zusatzziffer": "0",
    "Gemeindename": "Wald (ZH)",
    "BFS-Nr": "120",
    "Kantonskürzel": "ZH",
    "E": "2711773.2809999995",
    "N": "1237290.6490000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Laupen ZH",
    "PLZ": "8637",
    "Zusatzziffer": "0",
    "Gemeindename": "Wald (ZH)",
    "BFS-Nr": "120",
    "Kantonskürzel": "ZH",
    "E": "2712733.8269999996",
    "N": "1235981.3839999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wetzikon ZH",
    "PLZ": "8620",
    "Zusatzziffer": "0",
    "Gemeindename": "Wetzikon (ZH)",
    "BFS-Nr": "121",
    "Kantonskürzel": "ZH",
    "E": "2703395.907059642",
    "N": "1242180.5484999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wetzikon ZH",
    "PLZ": "8623",
    "Zusatzziffer": "0",
    "Gemeindename": "Wetzikon (ZH)",
    "BFS-Nr": "121",
    "Kantonskürzel": "ZH",
    "E": "2704513.556046247",
    "N": "1243287.8794999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Adliswil",
    "PLZ": "8134",
    "Zusatzziffer": "0",
    "Gemeindename": "Adliswil",
    "BFS-Nr": "131",
    "Kantonskürzel": "ZH",
    "E": "2681938.4120000005",
    "N": "1240783.7630000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kilchberg ZH",
    "PLZ": "8802",
    "Zusatzziffer": "0",
    "Gemeindename": "Kilchberg (ZH)",
    "BFS-Nr": "135",
    "Kantonskürzel": "ZH",
    "E": "2683478.103",
    "N": "1241647.6779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langnau am Albis",
    "PLZ": "8135",
    "Zusatzziffer": "0",
    "Gemeindename": "Langnau am Albis",
    "BFS-Nr": "136",
    "Kantonskürzel": "ZH",
    "E": "2682904.1330000013",
    "N": "1238062.2630000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberrieden",
    "PLZ": "8942",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberrieden",
    "BFS-Nr": "137",
    "Kantonskürzel": "ZH",
    "E": "2686107.9880000018",
    "N": "1236859.1959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Richterswil",
    "PLZ": "8805",
    "Zusatzziffer": "0",
    "Gemeindename": "Richterswil",
    "BFS-Nr": "138",
    "Kantonskürzel": "ZH",
    "E": "2695631.5040000007",
    "N": "1229360.0989999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Samstagern",
    "PLZ": "8833",
    "Zusatzziffer": "0",
    "Gemeindename": "Richterswil",
    "BFS-Nr": "138",
    "Kantonskürzel": "ZH",
    "E": "2693942.346000001",
    "N": "1227812.5080000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüschlikon",
    "PLZ": "8803",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüschlikon",
    "BFS-Nr": "139",
    "Kantonskürzel": "ZH",
    "E": "2684407.1209999993",
    "N": "1240427.3539999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gattikon",
    "PLZ": "8136",
    "Zusatzziffer": "0",
    "Gemeindename": "Thalwil",
    "BFS-Nr": "141",
    "Kantonskürzel": "ZH",
    "E": "2684120.1380000003",
    "N": "1237676.4090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thalwil",
    "PLZ": "8800",
    "Zusatzziffer": "0",
    "Gemeindename": "Thalwil",
    "BFS-Nr": "141",
    "Kantonskürzel": "ZH",
    "E": "2685516.671",
    "N": "1238319.3460000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erlenbach ZH",
    "PLZ": "8703",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlenbach (ZH)",
    "BFS-Nr": "151",
    "Kantonskürzel": "ZH",
    "E": "2687193.4860000014",
    "N": "1239686.2369999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herrliberg",
    "PLZ": "8704",
    "Zusatzziffer": "0",
    "Gemeindename": "Herrliberg",
    "BFS-Nr": "152",
    "Kantonskürzel": "ZH",
    "E": "2689134.3850000016",
    "N": "1237813.7289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hombrechtikon",
    "PLZ": "8634",
    "Zusatzziffer": "0",
    "Gemeindename": "Hombrechtikon",
    "BFS-Nr": "153",
    "Kantonskürzel": "ZH",
    "E": "2700313.4869999997",
    "N": "1234409.234000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Feldbach",
    "PLZ": "8714",
    "Zusatzziffer": "0",
    "Gemeindename": "Hombrechtikon",
    "BFS-Nr": "153",
    "Kantonskürzel": "ZH",
    "E": "2701724.364",
    "N": "1232869.1429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Forch",
    "PLZ": "8127",
    "Zusatzziffer": "0",
    "Gemeindename": "Küsnacht (ZH)",
    "BFS-Nr": "154",
    "Kantonskürzel": "ZH",
    "E": "2691437.226",
    "N": "1242318.8449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Küsnacht ZH",
    "PLZ": "8700",
    "Zusatzziffer": "0",
    "Gemeindename": "Küsnacht (ZH)",
    "BFS-Nr": "154",
    "Kantonskürzel": "ZH",
    "E": "2686560.666000001",
    "N": "1241297.6290000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Männedorf",
    "PLZ": "8708",
    "Zusatzziffer": "0",
    "Gemeindename": "Männedorf",
    "BFS-Nr": "155",
    "Kantonskürzel": "ZH",
    "E": "2695008.9360000007",
    "N": "1234513.5549999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meilen",
    "PLZ": "8706",
    "Zusatzziffer": "0",
    "Gemeindename": "Meilen",
    "BFS-Nr": "156",
    "Kantonskürzel": "ZH",
    "E": "2690904.598000001",
    "N": "1235938.4809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oetwil am See",
    "PLZ": "8618",
    "Zusatzziffer": "0",
    "Gemeindename": "Oetwil am See",
    "BFS-Nr": "157",
    "Kantonskürzel": "ZH",
    "E": "2697205.392000001",
    "N": "1236271.4160000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stäfa",
    "PLZ": "8712",
    "Zusatzziffer": "0",
    "Gemeindename": "Stäfa",
    "BFS-Nr": "158",
    "Kantonskürzel": "ZH",
    "E": "2697854.545000002",
    "N": "1232808.7850000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uerikon",
    "PLZ": "8713",
    "Zusatzziffer": "0",
    "Gemeindename": "Stäfa",
    "BFS-Nr": "158",
    "Kantonskürzel": "ZH",
    "E": "2699808.7239999995",
    "N": "1232354.1779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uetikon am See",
    "PLZ": "8707",
    "Zusatzziffer": "0",
    "Gemeindename": "Uetikon am See",
    "BFS-Nr": "159",
    "Kantonskürzel": "ZH",
    "E": "2693716.881000001",
    "N": "1235285.4010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zumikon",
    "PLZ": "8126",
    "Zusatzziffer": "0",
    "Gemeindename": "Zumikon",
    "BFS-Nr": "160",
    "Kantonskürzel": "ZH",
    "E": "2689540.5469999984",
    "N": "1242957.6259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zollikerberg",
    "PLZ": "8125",
    "Zusatzziffer": "0",
    "Gemeindename": "Zollikon",
    "BFS-Nr": "161",
    "Kantonskürzel": "ZH",
    "E": "2687924.800999999",
    "N": "1244779.6369999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zollikon",
    "PLZ": "8702",
    "Zusatzziffer": "0",
    "Gemeindename": "Zollikon",
    "BFS-Nr": "161",
    "Kantonskürzel": "ZH",
    "E": "2685818.6999999993",
    "N": "1243666.6669999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fehraltorf",
    "PLZ": "8320",
    "Zusatzziffer": "0",
    "Gemeindename": "Fehraltorf",
    "BFS-Nr": "172",
    "Kantonskürzel": "ZH",
    "E": "2699186.2019999996",
    "N": "1249307.5359999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hittnau",
    "PLZ": "8335",
    "Zusatzziffer": "0",
    "Gemeindename": "Hittnau",
    "BFS-Nr": "173",
    "Kantonskürzel": "ZH",
    "E": "2704660.1510000005",
    "N": "1246488.175999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kemptthal",
    "PLZ": "8310",
    "Zusatzziffer": "0",
    "Gemeindename": "Lindau",
    "BFS-Nr": "176",
    "Kantonskürzel": "ZH",
    "E": "2695550.9279999994",
    "N": "1256551.6950000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grafstal",
    "PLZ": "8310",
    "Zusatzziffer": "1",
    "Gemeindename": "Lindau",
    "BFS-Nr": "176",
    "Kantonskürzel": "ZH",
    "E": "2695003.517999999",
    "N": "1255548.6000000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winterberg ZH",
    "PLZ": "8312",
    "Zusatzziffer": "0",
    "Gemeindename": "Lindau",
    "BFS-Nr": "176",
    "Kantonskürzel": "ZH",
    "E": "2694518.7030000016",
    "N": "1256951.1319999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lindau",
    "PLZ": "8315",
    "Zusatzziffer": "0",
    "Gemeindename": "Lindau",
    "BFS-Nr": "176",
    "Kantonskürzel": "ZH",
    "E": "2692927.8280000016",
    "N": "1255292.4400000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tagelswangen",
    "PLZ": "8317",
    "Zusatzziffer": "0",
    "Gemeindename": "Lindau",
    "BFS-Nr": "176",
    "Kantonskürzel": "ZH",
    "E": "2693038.1000000015",
    "N": "1254021.2430000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfäffikon ZH",
    "PLZ": "8330",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfäffikon",
    "BFS-Nr": "177",
    "Kantonskürzel": "ZH",
    "E": "2701404.0639999993",
    "N": "1246909.6380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Auslikon",
    "PLZ": "8331",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfäffikon",
    "BFS-Nr": "177",
    "Kantonskürzel": "ZH",
    "E": "2703391.1810000017",
    "N": "1244751.0009999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Madetswil",
    "PLZ": "8322",
    "Zusatzziffer": "0",
    "Gemeindename": "Russikon",
    "BFS-Nr": "178",
    "Kantonskürzel": "ZH",
    "E": "2702098.6939999983",
    "N": "1251919.0659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gündisau",
    "PLZ": "8322",
    "Zusatzziffer": "2",
    "Gemeindename": "Russikon",
    "BFS-Nr": "178",
    "Kantonskürzel": "ZH",
    "E": "2703330.1799999997",
    "N": "1250589.6099999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Russikon",
    "PLZ": "8332",
    "Zusatzziffer": "0",
    "Gemeindename": "Russikon",
    "BFS-Nr": "178",
    "Kantonskürzel": "ZH",
    "E": "2700793.245000001",
    "N": "1250344.3949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rumlikon",
    "PLZ": "8332",
    "Zusatzziffer": "2",
    "Gemeindename": "Russikon",
    "BFS-Nr": "178",
    "Kantonskürzel": "ZH",
    "E": "2699706.9389999993",
    "N": "1251781.432",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weisslingen",
    "PLZ": "8484",
    "Zusatzziffer": "0",
    "Gemeindename": "Weisslingen",
    "BFS-Nr": "180",
    "Kantonskürzel": "ZH",
    "E": "2700069.1959999986",
    "N": "1254471.688000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neschwil",
    "PLZ": "8484",
    "Zusatzziffer": "2",
    "Gemeindename": "Weisslingen",
    "BFS-Nr": "180",
    "Kantonskürzel": "ZH",
    "E": "2701818.2019999996",
    "N": "1254142.7069999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Theilingen",
    "PLZ": "8484",
    "Zusatzziffer": "3",
    "Gemeindename": "Weisslingen",
    "BFS-Nr": "180",
    "Kantonskürzel": "ZH",
    "E": "2700292.401999999",
    "N": "1253105.006000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wila",
    "PLZ": "8492",
    "Zusatzziffer": "0",
    "Gemeindename": "Wila",
    "BFS-Nr": "181",
    "Kantonskürzel": "ZH",
    "E": "2706184.9329999983",
    "N": "1253019.2829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wildberg",
    "PLZ": "8489",
    "Zusatzziffer": "0",
    "Gemeindename": "Wildberg",
    "BFS-Nr": "182",
    "Kantonskürzel": "ZH",
    "E": "2703969.6849999987",
    "N": "1253880.5190000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schalchen",
    "PLZ": "8489",
    "Zusatzziffer": "1",
    "Gemeindename": "Wildberg",
    "BFS-Nr": "182",
    "Kantonskürzel": "ZH",
    "E": "2704729.4239999987",
    "N": "1251604.1779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ehrikon",
    "PLZ": "8489",
    "Zusatzziffer": "2",
    "Gemeindename": "Wildberg",
    "BFS-Nr": "182",
    "Kantonskürzel": "ZH",
    "E": "2703408.6900000013",
    "N": "1252918.1730000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gockhausen",
    "PLZ": "8044",
    "Zusatzziffer": "1",
    "Gemeindename": "Dübendorf",
    "BFS-Nr": "191",
    "Kantonskürzel": "ZH",
    "E": "2687882.7349999994",
    "N": "1248707.3979999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dübendorf",
    "PLZ": "8600",
    "Zusatzziffer": "0",
    "Gemeindename": "Dübendorf",
    "BFS-Nr": "191",
    "Kantonskürzel": "ZH",
    "E": "2689039.1779999994",
    "N": "1250030.5859999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Egg b. Zürich",
    "PLZ": "8132",
    "Zusatzziffer": "0",
    "Gemeindename": "Egg",
    "BFS-Nr": "192",
    "Kantonskürzel": "ZH",
    "E": "2694747.197999999",
    "N": "1239619.3940000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hinteregg",
    "PLZ": "8132",
    "Zusatzziffer": "2",
    "Gemeindename": "Egg",
    "BFS-Nr": "192",
    "Kantonskürzel": "ZH",
    "E": "2694193.1160000004",
    "N": "1240198.4930000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Esslingen",
    "PLZ": "8133",
    "Zusatzziffer": "0",
    "Gemeindename": "Egg",
    "BFS-Nr": "192",
    "Kantonskürzel": "ZH",
    "E": "2696145.539000001",
    "N": "1238274.3110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fällanden",
    "PLZ": "8117",
    "Zusatzziffer": "0",
    "Gemeindename": "Fällanden",
    "BFS-Nr": "193",
    "Kantonskürzel": "ZH",
    "E": "2690613.796",
    "N": "1247324.4659999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfaffhausen",
    "PLZ": "8118",
    "Zusatzziffer": "0",
    "Gemeindename": "Fällanden",
    "BFS-Nr": "193",
    "Kantonskürzel": "ZH",
    "E": "2689480.5940000005",
    "N": "1246774.4100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Benglen",
    "PLZ": "8121",
    "Zusatzziffer": "0",
    "Gemeindename": "Fällanden",
    "BFS-Nr": "193",
    "Kantonskürzel": "ZH",
    "E": "2690551.5610000007",
    "N": "1246283.2140000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Greifensee",
    "PLZ": "8606",
    "Zusatzziffer": "2",
    "Gemeindename": "Greifensee",
    "BFS-Nr": "194",
    "Kantonskürzel": "ZH",
    "E": "2693540.3139999993",
    "N": "1246753.4670000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Binz",
    "PLZ": "8122",
    "Zusatzziffer": "0",
    "Gemeindename": "Maur",
    "BFS-Nr": "195",
    "Kantonskürzel": "ZH",
    "E": "2689784.272999998",
    "N": "1245779.1059999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ebmatingen",
    "PLZ": "8123",
    "Zusatzziffer": "0",
    "Gemeindename": "Maur",
    "BFS-Nr": "195",
    "Kantonskürzel": "ZH",
    "E": "2690768.0940000005",
    "N": "1245208.2349999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Maur",
    "PLZ": "8124",
    "Zusatzziffer": "0",
    "Gemeindename": "Maur",
    "BFS-Nr": "195",
    "Kantonskürzel": "ZH",
    "E": "2693275.4880000018",
    "N": "1243727.5769999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mönchaltorf",
    "PLZ": "8617",
    "Zusatzziffer": "0",
    "Gemeindename": "Mönchaltorf",
    "BFS-Nr": "196",
    "Kantonskürzel": "ZH",
    "E": "2697038.4629999995",
    "N": "1240712.9910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwerzenbach",
    "PLZ": "8603",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwerzenbach",
    "BFS-Nr": "197",
    "Kantonskürzel": "ZH",
    "E": "2691638.045000002",
    "N": "1248370.511",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nänikon",
    "PLZ": "8606",
    "Zusatzziffer": "0",
    "Gemeindename": "Uster",
    "BFS-Nr": "198",
    "Kantonskürzel": "ZH",
    "E": "2694770.1570000015",
    "N": "1247350.9079999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uster",
    "PLZ": "8610",
    "Zusatzziffer": "0",
    "Gemeindename": "Uster",
    "BFS-Nr": "198",
    "Kantonskürzel": "ZH",
    "E": "2696590.153000001",
    "N": "1244675.0100000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sulzbach",
    "PLZ": "8614",
    "Zusatzziffer": "2",
    "Gemeindename": "Uster",
    "BFS-Nr": "198",
    "Kantonskürzel": "ZH",
    "E": "2698874.1059999987",
    "N": "1242834.329",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wermatswil",
    "PLZ": "8615",
    "Zusatzziffer": "0",
    "Gemeindename": "Uster",
    "BFS-Nr": "198",
    "Kantonskürzel": "ZH",
    "E": "2698753.6559999995",
    "N": "1246517.8409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Freudwil",
    "PLZ": "8615",
    "Zusatzziffer": "2",
    "Gemeindename": "Uster",
    "BFS-Nr": "198",
    "Kantonskürzel": "ZH",
    "E": "2697836.381000001",
    "N": "1247907.2419999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riedikon",
    "PLZ": "8616",
    "Zusatzziffer": "0",
    "Gemeindename": "Uster",
    "BFS-Nr": "198",
    "Kantonskürzel": "ZH",
    "E": "2696208.2490000017",
    "N": "1243186.9750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Volketswil",
    "PLZ": "8604",
    "Zusatzziffer": "0",
    "Gemeindename": "Volketswil",
    "BFS-Nr": "199",
    "Kantonskürzel": "ZH",
    "E": "2694588.4120000005",
    "N": "1249446.6130000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gutenswil",
    "PLZ": "8605",
    "Zusatzziffer": "0",
    "Gemeindename": "Volketswil",
    "BFS-Nr": "199",
    "Kantonskürzel": "ZH",
    "E": "2696600.7699999996",
    "N": "1249044.3729999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brüttisellen",
    "PLZ": "8306",
    "Zusatzziffer": "0",
    "Gemeindename": "Wangen-Brüttisellen",
    "BFS-Nr": "200",
    "Kantonskürzel": "ZH",
    "E": "2689874.096000001",
    "N": "1253172.4910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wangen b. Dübendorf",
    "PLZ": "8602",
    "Zusatzziffer": "0",
    "Gemeindename": "Wangen-Brüttisellen",
    "BFS-Nr": "200",
    "Kantonskürzel": "ZH",
    "E": "2691158.8869999982",
    "N": "1251662.1220000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altikon",
    "PLZ": "8479",
    "Zusatzziffer": "0",
    "Gemeindename": "Altikon",
    "BFS-Nr": "211",
    "Kantonskürzel": "ZH",
    "E": "2701260.546",
    "N": "1270261.1180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brütten",
    "PLZ": "8311",
    "Zusatzziffer": "0",
    "Gemeindename": "Brütten",
    "BFS-Nr": "213",
    "Kantonskürzel": "ZH",
    "E": "2693140.9959999993",
    "N": "1258879.3649999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rutschwil (Dägerlen)",
    "PLZ": "8471",
    "Zusatzziffer": "0",
    "Gemeindename": "Dägerlen",
    "BFS-Nr": "214",
    "Kantonskürzel": "ZH",
    "E": "2697211.2989999987",
    "N": "1268112.732999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dägerlen",
    "PLZ": "8471",
    "Zusatzziffer": "2",
    "Gemeindename": "Dägerlen",
    "BFS-Nr": "214",
    "Kantonskürzel": "ZH",
    "E": "2696592.9959999993",
    "N": "1268587.8209999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwil (Dägerlen)",
    "PLZ": "8471",
    "Zusatzziffer": "3",
    "Gemeindename": "Dägerlen",
    "BFS-Nr": "214",
    "Kantonskürzel": "ZH",
    "E": "2696158.484000001",
    "N": "1269881.1119999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berg (Dägerlen)",
    "PLZ": "8471",
    "Zusatzziffer": "4",
    "Gemeindename": "Dägerlen",
    "BFS-Nr": "214",
    "Kantonskürzel": "ZH",
    "E": "2697420.1209999993",
    "N": "1268915.2679999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bänk (Dägerlen)",
    "PLZ": "8471",
    "Zusatzziffer": "5",
    "Gemeindename": "Dägerlen",
    "BFS-Nr": "214",
    "Kantonskürzel": "ZH",
    "E": "2697902.4750000015",
    "N": "1267217.1209999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dättlikon",
    "PLZ": "8421",
    "Zusatzziffer": "0",
    "Gemeindename": "Dättlikon",
    "BFS-Nr": "215",
    "Kantonskürzel": "ZH",
    "E": "2689282.8660000004",
    "N": "1264468.0089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dinhard",
    "PLZ": "8474",
    "Zusatzziffer": "0",
    "Gemeindename": "Dinhard",
    "BFS-Nr": "216",
    "Kantonskürzel": "ZH",
    "E": "2700078.493999999",
    "N": "1268012.8790000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ellikon an der Thur",
    "PLZ": "8548",
    "Zusatzziffer": "0",
    "Gemeindename": "Ellikon an der Thur",
    "BFS-Nr": "218",
    "Kantonskürzel": "ZH",
    "E": "2704363.969999999",
    "N": "1268844.6609999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Elsau",
    "PLZ": "8352",
    "Zusatzziffer": "0",
    "Gemeindename": "Elsau",
    "BFS-Nr": "219",
    "Kantonskürzel": "ZH",
    "E": "2702749.1889999993",
    "N": "1262604.0949999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hagenbuch ZH",
    "PLZ": "8523",
    "Zusatzziffer": "0",
    "Gemeindename": "Hagenbuch",
    "BFS-Nr": "220",
    "Kantonskürzel": "ZH",
    "E": "2709145.344999999",
    "N": "1264371.6229999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hettlingen",
    "PLZ": "8442",
    "Zusatzziffer": "0",
    "Gemeindename": "Hettlingen",
    "BFS-Nr": "221",
    "Kantonskürzel": "ZH",
    "E": "2695562.2989999987",
    "N": "1267179.381000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aesch (Neftenbach)",
    "PLZ": "8412",
    "Zusatzziffer": "0",
    "Gemeindename": "Neftenbach",
    "BFS-Nr": "223",
    "Kantonskürzel": "ZH",
    "E": "2693410.533",
    "N": "1266354.6829999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riet (Neftenbach)",
    "PLZ": "8412",
    "Zusatzziffer": "1",
    "Gemeindename": "Neftenbach",
    "BFS-Nr": "223",
    "Kantonskürzel": "ZH",
    "E": "2693980.765999999",
    "N": "1265828.3110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hünikon (Neftenbach)",
    "PLZ": "8412",
    "Zusatzziffer": "2",
    "Gemeindename": "Neftenbach",
    "BFS-Nr": "223",
    "Kantonskürzel": "ZH",
    "E": "2692552.151999999",
    "N": "1267549.7089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neftenbach",
    "PLZ": "8413",
    "Zusatzziffer": "0",
    "Gemeindename": "Neftenbach",
    "BFS-Nr": "223",
    "Kantonskürzel": "ZH",
    "E": "2692563.9010000005",
    "N": "1264927.289999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfungen",
    "PLZ": "8422",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfungen",
    "BFS-Nr": "224",
    "Kantonskürzel": "ZH",
    "E": "2690982.649",
    "N": "1263492.629999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach ZH",
    "PLZ": "8545",
    "Zusatzziffer": "0",
    "Gemeindename": "Rickenbach (ZH)",
    "BFS-Nr": "225",
    "Kantonskürzel": "ZH",
    "E": "2702012.5709999986",
    "N": "1267628.1680000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach Sulz",
    "PLZ": "8545",
    "Zusatzziffer": "1",
    "Gemeindename": "Rickenbach (ZH)",
    "BFS-Nr": "225",
    "Kantonskürzel": "ZH",
    "E": "2701580.333999999",
    "N": "1266425.2309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schlatt ZH",
    "PLZ": "8418",
    "Zusatzziffer": "0",
    "Gemeindename": "Schlatt (ZH)",
    "BFS-Nr": "226",
    "Kantonskürzel": "ZH",
    "E": "2704590.2490000017",
    "N": "1258388.5340000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seuzach",
    "PLZ": "8472",
    "Zusatzziffer": "0",
    "Gemeindename": "Seuzach",
    "BFS-Nr": "227",
    "Kantonskürzel": "ZH",
    "E": "2697861.4690000005",
    "N": "1265866.2109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Turbenthal",
    "PLZ": "8488",
    "Zusatzziffer": "0",
    "Gemeindename": "Turbenthal",
    "BFS-Nr": "228",
    "Kantonskürzel": "ZH",
    "E": "2706270.8999999985",
    "N": "1254771.925999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schmidrüti",
    "PLZ": "8495",
    "Zusatzziffer": "0",
    "Gemeindename": "Turbenthal",
    "BFS-Nr": "228",
    "Kantonskürzel": "ZH",
    "E": "2710539.1330000013",
    "N": "1252587.175999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ricketwil (Winterthur)",
    "PLZ": "8352",
    "Zusatzziffer": "2",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2702669.2989999987",
    "N": "1260354.1119999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winterthur",
    "PLZ": "8400",
    "Zusatzziffer": "0",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2697279.147",
    "N": "1260895.569880492",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winterthur",
    "PLZ": "8404",
    "Zusatzziffer": "0",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2699485.4014999997",
    "N": "1262695.2036524042",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reutlingen (Winterthur)",
    "PLZ": "8404",
    "Zusatzziffer": "2",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2698853.0480000004",
    "N": "1264961.7410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stadel (Winterthur)",
    "PLZ": "8404",
    "Zusatzziffer": "3",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2699603.8379999995",
    "N": "1265707.4609999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winterthur",
    "PLZ": "8405",
    "Zusatzziffer": "0",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2700976.918138365",
    "N": "1259392.6925000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winterthur",
    "PLZ": "8406",
    "Zusatzziffer": "0",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2695114.822999999",
    "N": "1259926.568680733",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winterthur",
    "PLZ": "8408",
    "Zusatzziffer": "0",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2693769.0635",
    "N": "1262631.145826763",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winterthur",
    "PLZ": "8409",
    "Zusatzziffer": "0",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2701155.6459999997",
    "N": "1262984.8811697608",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sennhof (Winterthur)",
    "PLZ": "8482",
    "Zusatzziffer": "0",
    "Gemeindename": "Winterthur",
    "BFS-Nr": "230",
    "Kantonskürzel": "ZH",
    "E": "2699466.5529999994",
    "N": "1258434.8409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kollbrunn",
    "PLZ": "8483",
    "Zusatzziffer": "0",
    "Gemeindename": "Zell (ZH)",
    "BFS-Nr": "231",
    "Kantonskürzel": "ZH",
    "E": "2700891.403000001",
    "N": "1257139.0859999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rikon im Tösstal",
    "PLZ": "8486",
    "Zusatzziffer": "0",
    "Gemeindename": "Zell (ZH)",
    "BFS-Nr": "231",
    "Kantonskürzel": "ZH",
    "E": "2702434.6909999996",
    "N": "1255767.5650000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zell ZH",
    "PLZ": "8487",
    "Zusatzziffer": "0",
    "Gemeindename": "Zell (ZH)",
    "BFS-Nr": "231",
    "Kantonskürzel": "ZH",
    "E": "2704382.300999999",
    "N": "1256224.1959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rämismühle",
    "PLZ": "8487",
    "Zusatzziffer": "1",
    "Gemeindename": "Zell (ZH)",
    "BFS-Nr": "231",
    "Kantonskürzel": "ZH",
    "E": "2704123.280000001",
    "N": "1255446.5890000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aesch ZH",
    "PLZ": "8904",
    "Zusatzziffer": "0",
    "Gemeindename": "Aesch (ZH)",
    "BFS-Nr": "241",
    "Kantonskürzel": "ZH",
    "E": "2675547.079999998",
    "N": "1243424.8869999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birmensdorf ZH",
    "PLZ": "8903",
    "Zusatzziffer": "0",
    "Gemeindename": "Birmensdorf (ZH)",
    "BFS-Nr": "242",
    "Kantonskürzel": "ZH",
    "E": "2675748.6939999983",
    "N": "1245018.364",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dietikon",
    "PLZ": "8953",
    "Zusatzziffer": "0",
    "Gemeindename": "Dietikon",
    "BFS-Nr": "243",
    "Kantonskürzel": "ZH",
    "E": "2672846.655000001",
    "N": "1250902.7829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fahrweid",
    "PLZ": "8951",
    "Zusatzziffer": "0",
    "Gemeindename": "Geroldswil",
    "BFS-Nr": "244",
    "Kantonskürzel": "ZH",
    "E": "2673726.971999999",
    "N": "1251525.6999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Geroldswil",
    "PLZ": "8954",
    "Zusatzziffer": "0",
    "Gemeindename": "Geroldswil",
    "BFS-Nr": "244",
    "Kantonskürzel": "ZH",
    "E": "2673324.2140000015",
    "N": "1252856.857999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberengstringen",
    "PLZ": "8102",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberengstringen",
    "BFS-Nr": "245",
    "Kantonskürzel": "ZH",
    "E": "2677266.833999999",
    "N": "1251343.4979999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oetwil an der Limmat",
    "PLZ": "8955",
    "Zusatzziffer": "0",
    "Gemeindename": "Oetwil an der Limmat",
    "BFS-Nr": "246",
    "Kantonskürzel": "ZH",
    "E": "2672155.9420000017",
    "N": "1253478.6240000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schlieren",
    "PLZ": "8952",
    "Zusatzziffer": "0",
    "Gemeindename": "Schlieren",
    "BFS-Nr": "247",
    "Kantonskürzel": "ZH",
    "E": "2676033.0210000016",
    "N": "1250018.8990000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uitikon Waldegg",
    "PLZ": "8142",
    "Zusatzziffer": "0",
    "Gemeindename": "Uitikon",
    "BFS-Nr": "248",
    "Kantonskürzel": "ZH",
    "E": "2676688.344999999",
    "N": "1247163.7950000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterengstringen",
    "PLZ": "8103",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterengstringen",
    "BFS-Nr": "249",
    "Kantonskürzel": "ZH",
    "E": "2676285.3779999986",
    "N": "1251881.3990000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Urdorf",
    "PLZ": "8902",
    "Zusatzziffer": "0",
    "Gemeindename": "Urdorf",
    "BFS-Nr": "250",
    "Kantonskürzel": "ZH",
    "E": "2674389.1909999996",
    "N": "1248448.074000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weiningen ZH",
    "PLZ": "8104",
    "Zusatzziffer": "0",
    "Gemeindename": "Weiningen (ZH)",
    "BFS-Nr": "251",
    "Kantonskürzel": "ZH",
    "E": "2675258.9230000004",
    "N": "1252680.6240000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8001",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2683284.989500001",
    "N": "1247385.9113530102",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8002",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2682633.6619999986",
    "N": "1246102.0341832852",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8003",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2681380.488323015",
    "N": "1247418.3890000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8004",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2681918.0249530617",
    "N": "1248028.8600000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8005",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2681701.754888905",
    "N": "1249000.387",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8006",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2683401.3835210884",
    "N": "1248912.8015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8008",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2684827.365338693",
    "N": "1245360.892000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8032",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2685037.286176228",
    "N": "1246814.829500001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8037",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2681953.835000001",
    "N": "1250308.0074301884",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8038",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2682996.749",
    "N": "1243993.3643453028",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8041",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2681400.1305",
    "N": "1243358.0465884916",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8044",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2685649.0378381447",
    "N": "1248349.892000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8045",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2680624.190797659",
    "N": "1245077.091500001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8046",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2680711.467",
    "N": "1252924.289465604",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8047",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2679179.3334651394",
    "N": "1247534.6519999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8048",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2678922.3545629634",
    "N": "1248960.545",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8049",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2680142.3267266615",
    "N": "1251417.7799999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8050",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2683936.0179204587",
    "N": "1251830.5604999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8051",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2686070.1849958436",
    "N": "1250494.9440000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8052",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2683149.824321597",
    "N": "1253212.3065000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8053",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2687537.085256765",
    "N": "1246250.8774999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8055",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2679440.756064834",
    "N": "1246459.1970000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8057",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2683697.5970556987",
    "N": "1250491.5015000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zürich",
    "PLZ": "8064",
    "Zusatzziffer": "0",
    "Gemeindename": "Zürich",
    "BFS-Nr": "261",
    "Kantonskürzel": "ZH",
    "E": "2679008.5970267328",
    "N": "1250286.0814999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Waltalingen",
    "PLZ": "8468",
    "Zusatzziffer": "0",
    "Gemeindename": "Stammheim",
    "BFS-Nr": "292",
    "Kantonskürzel": "ZH",
    "E": "2700750.4970000014",
    "N": "1275506.3999999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guntalingen",
    "PLZ": "8468",
    "Zusatzziffer": "2",
    "Gemeindename": "Stammheim",
    "BFS-Nr": "292",
    "Kantonskürzel": "ZH",
    "E": "2699996.6510000005",
    "N": "1276849.0309999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterstammheim",
    "PLZ": "8476",
    "Zusatzziffer": "0",
    "Gemeindename": "Stammheim",
    "BFS-Nr": "292",
    "Kantonskürzel": "ZH",
    "E": "2701723.3550000004",
    "N": "1277563.8469999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberstammheim",
    "PLZ": "8477",
    "Zusatzziffer": "0",
    "Gemeindename": "Stammheim",
    "BFS-Nr": "292",
    "Kantonskürzel": "ZH",
    "E": "2702399.030000001",
    "N": "1276817.052000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilen b. Neunforn",
    "PLZ": "8525",
    "Zusatzziffer": "2",
    "Gemeindename": "Stammheim",
    "BFS-Nr": "292",
    "Kantonskürzel": "ZH",
    "E": "2702160.721999999",
    "N": "1273317.0179999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Au ZH",
    "PLZ": "8804",
    "Zusatzziffer": "0",
    "Gemeindename": "Wädenswil",
    "BFS-Nr": "293",
    "Kantonskürzel": "ZH",
    "E": "2691173.272",
    "N": "1233596.4160000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wädenswil",
    "PLZ": "8820",
    "Zusatzziffer": "0",
    "Gemeindename": "Wädenswil",
    "BFS-Nr": "293",
    "Kantonskürzel": "ZH",
    "E": "2693383.3550000004",
    "N": "1231571.7030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönenberg ZH",
    "PLZ": "8824",
    "Zusatzziffer": "0",
    "Gemeindename": "Wädenswil",
    "BFS-Nr": "293",
    "Kantonskürzel": "ZH",
    "E": "2691422.017000001",
    "N": "1227586.4679999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hütten",
    "PLZ": "8825",
    "Zusatzziffer": "0",
    "Gemeindename": "Wädenswil",
    "BFS-Nr": "293",
    "Kantonskürzel": "ZH",
    "E": "2693268.4919999987",
    "N": "1225696.7259999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Elgg",
    "PLZ": "8353",
    "Zusatzziffer": "0",
    "Gemeindename": "Elgg",
    "BFS-Nr": "294",
    "Kantonskürzel": "ZH",
    "E": "2707682.2129999995",
    "N": "1260915.7729999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hofstetten ZH",
    "PLZ": "8354",
    "Zusatzziffer": "0",
    "Gemeindename": "Elgg",
    "BFS-Nr": "294",
    "Kantonskürzel": "ZH",
    "E": "2706672.2639999986",
    "N": "1258998.3790000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dickbuch",
    "PLZ": "8354",
    "Zusatzziffer": "1",
    "Gemeindename": "Elgg",
    "BFS-Nr": "294",
    "Kantonskürzel": "ZH",
    "E": "2704962.920000002",
    "N": "1261558.0590000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sihlbrugg Station",
    "PLZ": "8135",
    "Zusatzziffer": "2",
    "Gemeindename": "Horgen",
    "BFS-Nr": "295",
    "Kantonskürzel": "ZH",
    "E": "2686207.2589999996",
    "N": "1232655.4549999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sihlwald",
    "PLZ": "8135",
    "Zusatzziffer": "3",
    "Gemeindename": "Horgen",
    "BFS-Nr": "295",
    "Kantonskürzel": "ZH",
    "E": "2684670.6259999983",
    "N": "1235867.8740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Horgen",
    "PLZ": "8810",
    "Zusatzziffer": "0",
    "Gemeindename": "Horgen",
    "BFS-Nr": "295",
    "Kantonskürzel": "ZH",
    "E": "2687701.655000001",
    "N": "1234937.7780000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Horgenberg",
    "PLZ": "8815",
    "Zusatzziffer": "0",
    "Gemeindename": "Horgen",
    "BFS-Nr": "295",
    "Kantonskürzel": "ZH",
    "E": "2686699.5540000014",
    "N": "1233626.5949999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hirzel",
    "PLZ": "8816",
    "Zusatzziffer": "0",
    "Gemeindename": "Horgen",
    "BFS-Nr": "295",
    "Kantonskürzel": "ZH",
    "E": "2688474.5929999985",
    "N": "1230223.9010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Effretikon",
    "PLZ": "8307",
    "Zusatzziffer": "0",
    "Gemeindename": "Illnau-Effretikon",
    "BFS-Nr": "296",
    "Kantonskürzel": "ZH",
    "E": "2694372.704999998",
    "N": "1253790.7459999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ottikon b. Kemptthal",
    "PLZ": "8307",
    "Zusatzziffer": "2",
    "Gemeindename": "Illnau-Effretikon",
    "BFS-Nr": "296",
    "Kantonskürzel": "ZH",
    "E": "2696427.4849999994",
    "N": "1254549.8489999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Illnau",
    "PLZ": "8308",
    "Zusatzziffer": "0",
    "Gemeindename": "Illnau-Effretikon",
    "BFS-Nr": "296",
    "Kantonskürzel": "ZH",
    "E": "2696907.795000002",
    "N": "1251697.4580000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Agasul",
    "PLZ": "8308",
    "Zusatzziffer": "2",
    "Gemeindename": "Illnau-Effretikon",
    "BFS-Nr": "296",
    "Kantonskürzel": "ZH",
    "E": "2698440.234000001",
    "N": "1253527.370000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kyburg",
    "PLZ": "8314",
    "Zusatzziffer": "0",
    "Gemeindename": "Illnau-Effretikon",
    "BFS-Nr": "296",
    "Kantonskürzel": "ZH",
    "E": "2698482.653999999",
    "N": "1257152.5130000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saland",
    "PLZ": "8493",
    "Zusatzziffer": "0",
    "Gemeindename": "Bauma",
    "BFS-Nr": "297",
    "Kantonskürzel": "ZH",
    "E": "2706818.761",
    "N": "1249883.7289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bauma",
    "PLZ": "8494",
    "Zusatzziffer": "0",
    "Gemeindename": "Bauma",
    "BFS-Nr": "297",
    "Kantonskürzel": "ZH",
    "E": "2708661.1559999995",
    "N": "1247267.620000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sternenberg",
    "PLZ": "8499",
    "Zusatzziffer": "0",
    "Gemeindename": "Bauma",
    "BFS-Nr": "297",
    "Kantonskürzel": "ZH",
    "E": "2711225.658",
    "N": "1249540.796",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiesendangen",
    "PLZ": "8542",
    "Zusatzziffer": "0",
    "Gemeindename": "Wiesendangen",
    "BFS-Nr": "298",
    "Kantonskürzel": "ZH",
    "E": "2701740.8390000015",
    "N": "1264266.5439999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bertschikon",
    "PLZ": "8543",
    "Zusatzziffer": "0",
    "Gemeindename": "Wiesendangen",
    "BFS-Nr": "298",
    "Kantonskürzel": "ZH",
    "E": "2703612.1180000007",
    "N": "1264915.7430000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gundetswil",
    "PLZ": "8543",
    "Zusatzziffer": "1",
    "Gemeindename": "Wiesendangen",
    "BFS-Nr": "298",
    "Kantonskürzel": "ZH",
    "E": "2704176.039999999",
    "N": "1266375.9530000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kefikon ZH",
    "PLZ": "8543",
    "Zusatzziffer": "2",
    "Gemeindename": "Wiesendangen",
    "BFS-Nr": "298",
    "Kantonskürzel": "ZH",
    "E": "2704811.598000001",
    "N": "1267392.8489999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Attikon",
    "PLZ": "8544",
    "Zusatzziffer": "0",
    "Gemeindename": "Wiesendangen",
    "BFS-Nr": "298",
    "Kantonskürzel": "ZH",
    "E": "2702348.7540000007",
    "N": "1265902.114",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Menzengrüt",
    "PLZ": "8546",
    "Zusatzziffer": "3",
    "Gemeindename": "Wiesendangen",
    "BFS-Nr": "298",
    "Kantonskürzel": "ZH",
    "E": "2704120.239",
    "N": "1267465.0529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aarberg",
    "PLZ": "3270",
    "Zusatzziffer": "0",
    "Gemeindename": "Aarberg",
    "BFS-Nr": "301",
    "Kantonskürzel": "BE",
    "E": "2587587.5150000006",
    "N": "1210292.322999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bargen BE",
    "PLZ": "3282",
    "Zusatzziffer": "0",
    "Gemeindename": "Bargen (BE)",
    "BFS-Nr": "302",
    "Kantonskürzel": "BE",
    "E": "2586219.0130000003",
    "N": "1209918.7380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grossaffoltern",
    "PLZ": "3257",
    "Zusatzziffer": "0",
    "Gemeindename": "Grossaffoltern",
    "BFS-Nr": "303",
    "Kantonskürzel": "BE",
    "E": "2593938.2459999993",
    "N": "1212749.2800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ammerzwil BE",
    "PLZ": "3257",
    "Zusatzziffer": "1",
    "Gemeindename": "Grossaffoltern",
    "BFS-Nr": "303",
    "Kantonskürzel": "BE",
    "E": "2592620.7239999995",
    "N": "1213398.0249999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Suberg",
    "PLZ": "3262",
    "Zusatzziffer": "0",
    "Gemeindename": "Grossaffoltern",
    "BFS-Nr": "303",
    "Kantonskürzel": "BE",
    "E": "2592420.300999999",
    "N": "1212034.6889999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Golaten",
    "PLZ": "3207",
    "Zusatzziffer": "2",
    "Gemeindename": "Kallnach",
    "BFS-Nr": "304",
    "Kantonskürzel": "BE",
    "E": "2585060.7509999983",
    "N": "1203868.2980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kallnach",
    "PLZ": "3283",
    "Zusatzziffer": "0",
    "Gemeindename": "Kallnach",
    "BFS-Nr": "304",
    "Kantonskürzel": "BE",
    "E": "2584135.732999999",
    "N": "1207439.2129999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederried b. Kallnach",
    "PLZ": "3283",
    "Zusatzziffer": "2",
    "Gemeindename": "Kallnach",
    "BFS-Nr": "304",
    "Kantonskürzel": "BE",
    "E": "2585837.1559999995",
    "N": "1206627.1380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kappelen",
    "PLZ": "3273",
    "Zusatzziffer": "0",
    "Gemeindename": "Kappelen",
    "BFS-Nr": "305",
    "Kantonskürzel": "BE",
    "E": "2587005.1689999998",
    "N": "1212144.6110000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lyss",
    "PLZ": "3250",
    "Zusatzziffer": "0",
    "Gemeindename": "Lyss",
    "BFS-Nr": "306",
    "Kantonskürzel": "BE",
    "E": "2590040.3440000005",
    "N": "1213293.261",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Busswil BE",
    "PLZ": "3292",
    "Zusatzziffer": "0",
    "Gemeindename": "Lyss",
    "BFS-Nr": "306",
    "Kantonskürzel": "BE",
    "E": "2591179.028999999",
    "N": "1216628.4759999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ortschwaben",
    "PLZ": "3042",
    "Zusatzziffer": "0",
    "Gemeindename": "Meikirch",
    "BFS-Nr": "307",
    "Kantonskürzel": "BE",
    "E": "2597205.397",
    "N": "1204392.4959999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meikirch",
    "PLZ": "3045",
    "Zusatzziffer": "0",
    "Gemeindename": "Meikirch",
    "BFS-Nr": "307",
    "Kantonskürzel": "BE",
    "E": "2594221.7239999995",
    "N": "1206480.164999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wahlendorf",
    "PLZ": "3046",
    "Zusatzziffer": "0",
    "Gemeindename": "Meikirch",
    "BFS-Nr": "307",
    "Kantonskürzel": "BE",
    "E": "2592335.1889999993",
    "N": "1206301.1900000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Detligen",
    "PLZ": "3036",
    "Zusatzziffer": "0",
    "Gemeindename": "Radelfingen",
    "BFS-Nr": "309",
    "Kantonskürzel": "BE",
    "E": "2587387.041000001",
    "N": "1205645.0659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Radelfingen b. Aarberg",
    "PLZ": "3271",
    "Zusatzziffer": "0",
    "Gemeindename": "Radelfingen",
    "BFS-Nr": "309",
    "Kantonskürzel": "BE",
    "E": "2587107.9750000015",
    "N": "1207882.745000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lätti",
    "PLZ": "3053",
    "Zusatzziffer": "5",
    "Gemeindename": "Rapperswil (BE)",
    "BFS-Nr": "310",
    "Kantonskürzel": "BE",
    "E": "2599362.524",
    "N": "1209827.2490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ruppoldsried",
    "PLZ": "3251",
    "Zusatzziffer": "2",
    "Gemeindename": "Rapperswil (BE)",
    "BFS-Nr": "310",
    "Kantonskürzel": "BE",
    "E": "2599000.473000001",
    "N": "1215231.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rapperswil BE",
    "PLZ": "3255",
    "Zusatzziffer": "0",
    "Gemeindename": "Rapperswil (BE)",
    "BFS-Nr": "310",
    "Kantonskürzel": "BE",
    "E": "2597820.651999999",
    "N": "1212457.8200000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dieterswil",
    "PLZ": "3256",
    "Zusatzziffer": "0",
    "Gemeindename": "Rapperswil (BE)",
    "BFS-Nr": "310",
    "Kantonskürzel": "BE",
    "E": "2598966.5430000015",
    "N": "1211576.0599999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bangerten b. Dieterswil",
    "PLZ": "3256",
    "Zusatzziffer": "2",
    "Gemeindename": "Rapperswil (BE)",
    "BFS-Nr": "310",
    "Kantonskürzel": "BE",
    "E": "2600685.473000001",
    "N": "1211745.1259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seewil",
    "PLZ": "3256",
    "Zusatzziffer": "3",
    "Gemeindename": "Rapperswil (BE)",
    "BFS-Nr": "310",
    "Kantonskürzel": "BE",
    "E": "2597739.5720000006",
    "N": "1210781.796",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schüpfen",
    "PLZ": "3054",
    "Zusatzziffer": "0",
    "Gemeindename": "Schüpfen",
    "BFS-Nr": "311",
    "Kantonskürzel": "BE",
    "E": "2595300.4400000013",
    "N": "1209688.3189999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frieswil",
    "PLZ": "3035",
    "Zusatzziffer": "0",
    "Gemeindename": "Seedorf (BE)",
    "BFS-Nr": "312",
    "Kantonskürzel": "BE",
    "E": "2588350.2410000004",
    "N": "1204886.2630000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiler b. Seedorf",
    "PLZ": "3266",
    "Zusatzziffer": "0",
    "Gemeindename": "Seedorf (BE)",
    "BFS-Nr": "312",
    "Kantonskürzel": "BE",
    "E": "2590982.903999999",
    "N": "1210841.1950000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seedorf BE",
    "PLZ": "3267",
    "Zusatzziffer": "0",
    "Gemeindename": "Seedorf (BE)",
    "BFS-Nr": "312",
    "Kantonskürzel": "BE",
    "E": "2590306.5500000007",
    "N": "1209250.5080000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lobsigen",
    "PLZ": "3268",
    "Zusatzziffer": "0",
    "Gemeindename": "Seedorf (BE)",
    "BFS-Nr": "312",
    "Kantonskürzel": "BE",
    "E": "2588973.897999998",
    "N": "1208428.3049999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aarwangen",
    "PLZ": "4912",
    "Zusatzziffer": "0",
    "Gemeindename": "Aarwangen",
    "BFS-Nr": "321",
    "Kantonskürzel": "BE",
    "E": "2625060.8489999995",
    "N": "1232269.8669999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Auswil",
    "PLZ": "4944",
    "Zusatzziffer": "0",
    "Gemeindename": "Auswil",
    "BFS-Nr": "322",
    "Kantonskürzel": "BE",
    "E": "2629894.476",
    "N": "1220483.857999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bannwil",
    "PLZ": "4913",
    "Zusatzziffer": "0",
    "Gemeindename": "Bannwil",
    "BFS-Nr": "323",
    "Kantonskürzel": "BE",
    "E": "2622058.6750000007",
    "N": "1231872.7159999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bleienbach",
    "PLZ": "3368",
    "Zusatzziffer": "0",
    "Gemeindename": "Bleienbach",
    "BFS-Nr": "324",
    "Kantonskürzel": "BE",
    "E": "2624183.783",
    "N": "1226005.9789999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Busswil b. Melchnau",
    "PLZ": "4917",
    "Zusatzziffer": "2",
    "Gemeindename": "Busswil bei Melchnau",
    "BFS-Nr": "325",
    "Kantonskürzel": "BE",
    "E": "2629509.1259999983",
    "N": "1226199.2140000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gondiswil",
    "PLZ": "4955",
    "Zusatzziffer": "0",
    "Gemeindename": "Gondiswil",
    "BFS-Nr": "326",
    "Kantonskürzel": "BE",
    "E": "2632865.5810000002",
    "N": "1221786.5810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langenthal",
    "PLZ": "4900",
    "Zusatzziffer": "0",
    "Gemeindename": "Langenthal",
    "BFS-Nr": "329",
    "Kantonskürzel": "BE",
    "E": "2626807.1449999996",
    "N": "1229021.870000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Untersteckholz",
    "PLZ": "4916",
    "Zusatzziffer": "0",
    "Gemeindename": "Langenthal",
    "BFS-Nr": "329",
    "Kantonskürzel": "BE",
    "E": "2630920.0709999986",
    "N": "1228305.289999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obersteckholz",
    "PLZ": "4924",
    "Zusatzziffer": "0",
    "Gemeindename": "Langenthal",
    "BFS-Nr": "329",
    "Kantonskürzel": "BE",
    "E": "2628772.835999999",
    "N": "1227688.2309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lotzwil",
    "PLZ": "4932",
    "Zusatzziffer": "0",
    "Gemeindename": "Lotzwil",
    "BFS-Nr": "331",
    "Kantonskürzel": "BE",
    "E": "2626502.346000001",
    "N": "1226590.6590000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gutenburg",
    "PLZ": "4932",
    "Zusatzziffer": "2",
    "Gemeindename": "Madiswil",
    "BFS-Nr": "332",
    "Kantonskürzel": "BE",
    "E": "2627051.671",
    "N": "1225686.3299999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Madiswil",
    "PLZ": "4934",
    "Zusatzziffer": "0",
    "Gemeindename": "Madiswil",
    "BFS-Nr": "332",
    "Kantonskürzel": "BE",
    "E": "2627353.045000002",
    "N": "1224108.0799999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leimiswil",
    "PLZ": "4935",
    "Zusatzziffer": "0",
    "Gemeindename": "Madiswil",
    "BFS-Nr": "332",
    "Kantonskürzel": "BE",
    "E": "2624455.204999998",
    "N": "1222044.511",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleindietwil",
    "PLZ": "4936",
    "Zusatzziffer": "0",
    "Gemeindename": "Madiswil",
    "BFS-Nr": "332",
    "Kantonskürzel": "BE",
    "E": "2626735.147999998",
    "N": "1221756.335000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Melchnau",
    "PLZ": "4917",
    "Zusatzziffer": "0",
    "Gemeindename": "Melchnau",
    "BFS-Nr": "333",
    "Kantonskürzel": "BE",
    "E": "2631702.6240000017",
    "N": "1225894.2360000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oeschenbach",
    "PLZ": "4943",
    "Zusatzziffer": "0",
    "Gemeindename": "Oeschenbach",
    "BFS-Nr": "335",
    "Kantonskürzel": "BE",
    "E": "2623421.4459999986",
    "N": "1216791.4899999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reisiswil",
    "PLZ": "4919",
    "Zusatzziffer": "0",
    "Gemeindename": "Reisiswil",
    "BFS-Nr": "336",
    "Kantonskürzel": "BE",
    "E": "2630909.8209999986",
    "N": "1223875.989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Roggwil BE",
    "PLZ": "4914",
    "Zusatzziffer": "0",
    "Gemeindename": "Roggwil (BE)",
    "BFS-Nr": "337",
    "Kantonskürzel": "BE",
    "E": "2629003.364",
    "N": "1232180.636",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rohrbach",
    "PLZ": "4938",
    "Zusatzziffer": "0",
    "Gemeindename": "Rohrbach",
    "BFS-Nr": "338",
    "Kantonskürzel": "BE",
    "E": "2628344.1330000013",
    "N": "1220278.7989999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rohrbachgraben",
    "PLZ": "4938",
    "Zusatzziffer": "2",
    "Gemeindename": "Rohrbachgraben",
    "BFS-Nr": "339",
    "Kantonskürzel": "BE",
    "E": "2627460.2190000005",
    "N": "1218935.8759999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rütschelen",
    "PLZ": "4933",
    "Zusatzziffer": "0",
    "Gemeindename": "Rütschelen",
    "BFS-Nr": "340",
    "Kantonskürzel": "BE",
    "E": "2625204.050999999",
    "N": "1224749.1700000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzhäusern",
    "PLZ": "4911",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzhäusern",
    "BFS-Nr": "341",
    "Kantonskürzel": "BE",
    "E": "2625085.6730000004",
    "N": "1233853.1750000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bützberg",
    "PLZ": "4922",
    "Zusatzziffer": "0",
    "Gemeindename": "Thunstetten",
    "BFS-Nr": "342",
    "Kantonskürzel": "BE",
    "E": "2623326.425999999",
    "N": "1229476.3999999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thunstetten",
    "PLZ": "4922",
    "Zusatzziffer": "2",
    "Gemeindename": "Thunstetten",
    "BFS-Nr": "342",
    "Kantonskürzel": "BE",
    "E": "2623890.2069999985",
    "N": "1228147.8949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ursenbach",
    "PLZ": "4937",
    "Zusatzziffer": "0",
    "Gemeindename": "Ursenbach",
    "BFS-Nr": "344",
    "Kantonskürzel": "BE",
    "E": "2625359.8850000016",
    "N": "1220576.4530000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wynau",
    "PLZ": "4923",
    "Zusatzziffer": "0",
    "Gemeindename": "Wynau",
    "BFS-Nr": "345",
    "Kantonskürzel": "BE",
    "E": "2628372.778000001",
    "N": "1234163.0879999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3004",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2601017.9170000013",
    "N": "1203268.9945343854",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3005",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2600860.329",
    "N": "1198700.1531964713",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3006",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2602485.4395000003",
    "N": "1199414.5445742167",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3007",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2599687.529409061",
    "N": "1198678.2489999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3008",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2598116.743095278",
    "N": "1199335.272500001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3010",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2598897.2124357736",
    "N": "1199540.6435000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3011",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2600709.9518465437",
    "N": "1199572.7129999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3012",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2599040.436999999",
    "N": "1201167.5225712154",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3013",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2600576.0952364225",
    "N": "1200569.8949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3014",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2601505.2720051687",
    "N": "1201236.046",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3015",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2603515.8858782677",
    "N": "1198792.0769999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3018",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2596229.026375994",
    "N": "1198089.7774999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3019",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2593094.2439668803",
    "N": "1197769.5325000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3020",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2591033.1165988934",
    "N": "1198450.1400000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bern",
    "PLZ": "3027",
    "Zusatzziffer": "0",
    "Gemeindename": "Bern",
    "BFS-Nr": "351",
    "Kantonskürzel": "BE",
    "E": "2596071.4589948747",
    "N": "1200498.2440000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bolligen",
    "PLZ": "3065",
    "Zusatzziffer": "0",
    "Gemeindename": "Bolligen",
    "BFS-Nr": "352",
    "Kantonskürzel": "BE",
    "E": "2604386.1449999996",
    "N": "1202664.0029999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bremgarten b. Bern",
    "PLZ": "3047",
    "Zusatzziffer": "0",
    "Gemeindename": "Bremgarten bei Bern",
    "BFS-Nr": "353",
    "Kantonskürzel": "BE",
    "E": "2599673.897999998",
    "N": "1203050.1700000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herrenschwanden",
    "PLZ": "3037",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchlindach",
    "BFS-Nr": "354",
    "Kantonskürzel": "BE",
    "E": "2598452.890999999",
    "N": "1203017.9770000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kirchlindach",
    "PLZ": "3038",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchlindach",
    "BFS-Nr": "354",
    "Kantonskürzel": "BE",
    "E": "2598149.289999999",
    "N": "1205400.2459999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wabern",
    "PLZ": "3084",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2600830.272999998",
    "N": "1197499.2360000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Spiegel b. Bern",
    "PLZ": "3095",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2599565.9849999994",
    "N": "1197163.4140000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Liebefeld",
    "PLZ": "3097",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2598259.7360000014",
    "N": "1197626.046",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Köniz",
    "PLZ": "3098",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2598079.6059999987",
    "N": "1196668.3249999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Köniz",
    "PLZ": "3098",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2599744.087000001",
    "N": "1197696.1660000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schliern b. Köniz",
    "PLZ": "3098",
    "Zusatzziffer": "1",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2598285.2639999986",
    "N": "1195348.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gasel",
    "PLZ": "3144",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2597111.6909999996",
    "N": "1194418.7490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederscherli",
    "PLZ": "3145",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2595951.1559999995",
    "N": "1192729.493999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mittelhäusern",
    "PLZ": "3147",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2594615.6240000017",
    "N": "1191708.004999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederwangen b. Bern",
    "PLZ": "3172",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2595297.0760000013",
    "N": "1197198.8409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwangen b. Bern",
    "PLZ": "3173",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2594098.2529999986",
    "N": "1196086.8330000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thörishaus",
    "PLZ": "3174",
    "Zusatzziffer": "0",
    "Gemeindename": "Köniz",
    "BFS-Nr": "355",
    "Kantonskürzel": "BE",
    "E": "2593382.3830000013",
    "N": "1193663.379999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gümligen",
    "PLZ": "3073",
    "Zusatzziffer": "0",
    "Gemeindename": "Muri bei Bern",
    "BFS-Nr": "356",
    "Kantonskürzel": "BE",
    "E": "2605378.8090000004",
    "N": "1198220.0940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Muri b. Bern",
    "PLZ": "3074",
    "Zusatzziffer": "0",
    "Gemeindename": "Muri bei Bern",
    "BFS-Nr": "356",
    "Kantonskürzel": "BE",
    "E": "2603796.399",
    "N": "1197476.0689999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberbalm",
    "PLZ": "3096",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberbalm",
    "BFS-Nr": "357",
    "Kantonskürzel": "BE",
    "E": "2597407.2760000005",
    "N": "1191316.4910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stettlen",
    "PLZ": "3066",
    "Zusatzziffer": "0",
    "Gemeindename": "Stettlen",
    "BFS-Nr": "358",
    "Kantonskürzel": "BE",
    "E": "2606390.859000001",
    "N": "1200898.9039999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Boll",
    "PLZ": "3067",
    "Zusatzziffer": "0",
    "Gemeindename": "Vechigen",
    "BFS-Nr": "359",
    "Kantonskürzel": "BE",
    "E": "2608295.609000001",
    "N": "1200277.4340000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Utzigen",
    "PLZ": "3068",
    "Zusatzziffer": "0",
    "Gemeindename": "Vechigen",
    "BFS-Nr": "359",
    "Kantonskürzel": "BE",
    "E": "2609592.585000001",
    "N": "1201089.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hinterkappelen",
    "PLZ": "3032",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlen bei Bern",
    "BFS-Nr": "360",
    "Kantonskürzel": "BE",
    "E": "2595344.482999999",
    "N": "1201870.4109999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wohlen b. Bern",
    "PLZ": "3033",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlen bei Bern",
    "BFS-Nr": "360",
    "Kantonskürzel": "BE",
    "E": "2593686.824000001",
    "N": "1202174.9589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Murzelen",
    "PLZ": "3034",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlen bei Bern",
    "BFS-Nr": "360",
    "Kantonskürzel": "BE",
    "E": "2590581.2540000007",
    "N": "1203421.3550000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uettligen",
    "PLZ": "3043",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlen bei Bern",
    "BFS-Nr": "360",
    "Kantonskürzel": "BE",
    "E": "2595972.3680000007",
    "N": "1204025.8550000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Innerberg",
    "PLZ": "3044",
    "Zusatzziffer": "2",
    "Gemeindename": "Wohlen bei Bern",
    "BFS-Nr": "360",
    "Kantonskürzel": "BE",
    "E": "2590177.7030000016",
    "N": "1204925.9400000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Säriswil",
    "PLZ": "3049",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlen bei Bern",
    "BFS-Nr": "360",
    "Kantonskürzel": "BE",
    "E": "2592053.719999999",
    "N": "1204530.993999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zollikofen",
    "PLZ": "3052",
    "Zusatzziffer": "0",
    "Gemeindename": "Zollikofen",
    "BFS-Nr": "361",
    "Kantonskürzel": "BE",
    "E": "2601733.408",
    "N": "1204649.4860000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Worblaufen",
    "PLZ": "3048",
    "Zusatzziffer": "0",
    "Gemeindename": "Ittigen",
    "BFS-Nr": "362",
    "Kantonskürzel": "BE",
    "E": "2601448.1950000003",
    "N": "1203081.2529999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ittigen",
    "PLZ": "3063",
    "Zusatzziffer": "0",
    "Gemeindename": "Ittigen",
    "BFS-Nr": "362",
    "Kantonskürzel": "BE",
    "E": "2603137.9919999987",
    "N": "1202689.0080000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ostermundigen",
    "PLZ": "3072",
    "Zusatzziffer": "0",
    "Gemeindename": "Ostermundigen",
    "BFS-Nr": "363",
    "Kantonskürzel": "BE",
    "E": "2603683.971999999",
    "N": "1200617.6490000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biel/Bienne",
    "PLZ": "2502",
    "Zusatzziffer": "0",
    "Gemeindename": "Biel/Bienne",
    "BFS-Nr": "371",
    "Kantonskürzel": "BE",
    "E": "2585367.2365000006",
    "N": "1221241.1934583862",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biel/Bienne",
    "PLZ": "2503",
    "Zusatzziffer": "0",
    "Gemeindename": "Biel/Bienne",
    "BFS-Nr": "371",
    "Kantonskürzel": "BE",
    "E": "2586337.720574505",
    "N": "1220093.9825",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biel/Bienne",
    "PLZ": "2504",
    "Zusatzziffer": "0",
    "Gemeindename": "Biel/Bienne",
    "BFS-Nr": "371",
    "Kantonskürzel": "BE",
    "E": "2588171.4464999996",
    "N": "1222829.6760310102",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biel/Bienne",
    "PLZ": "2505",
    "Zusatzziffer": "0",
    "Gemeindename": "Biel/Bienne",
    "BFS-Nr": "371",
    "Kantonskürzel": "BE",
    "E": "2583260.6012076675",
    "N": "1220325.754999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Magglingen/Macolin",
    "PLZ": "2532",
    "Zusatzziffer": "0",
    "Gemeindename": "Evilard",
    "BFS-Nr": "372",
    "Kantonskürzel": "BE",
    "E": "2583165.965",
    "N": "1221255.2679999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Evilard",
    "PLZ": "2533",
    "Zusatzziffer": "0",
    "Gemeindename": "Evilard",
    "BFS-Nr": "372",
    "Kantonskürzel": "BE",
    "E": "2584906.7309999987",
    "N": "1222112.9750000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arch",
    "PLZ": "3296",
    "Zusatzziffer": "0",
    "Gemeindename": "Arch",
    "BFS-Nr": "381",
    "Kantonskürzel": "BE",
    "E": "2599157.9239999987",
    "N": "1223655.0709999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büetigen",
    "PLZ": "3263",
    "Zusatzziffer": "0",
    "Gemeindename": "Büetigen",
    "BFS-Nr": "382",
    "Kantonskürzel": "BE",
    "E": "2592603.8049999997",
    "N": "1217290.5879999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büren an der Aare",
    "PLZ": "3294",
    "Zusatzziffer": "0",
    "Gemeindename": "Büren an der Aare",
    "BFS-Nr": "383",
    "Kantonskürzel": "BE",
    "E": "2595101.9800000004",
    "N": "1220992.6900000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diessbach b. Büren",
    "PLZ": "3264",
    "Zusatzziffer": "0",
    "Gemeindename": "Diessbach bei Büren",
    "BFS-Nr": "385",
    "Kantonskürzel": "BE",
    "E": "2594183.6719999984",
    "N": "1217481.515999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dotzigen",
    "PLZ": "3293",
    "Zusatzziffer": "0",
    "Gemeindename": "Dotzigen",
    "BFS-Nr": "386",
    "Kantonskürzel": "BE",
    "E": "2592775.245000001",
    "N": "1218887.9090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lengnau BE",
    "PLZ": "2543",
    "Zusatzziffer": "0",
    "Gemeindename": "Lengnau (BE)",
    "BFS-Nr": "387",
    "Kantonskürzel": "BE",
    "E": "2594735.8280000016",
    "N": "1225715.993999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leuzigen",
    "PLZ": "3297",
    "Zusatzziffer": "0",
    "Gemeindename": "Leuzigen",
    "BFS-Nr": "388",
    "Kantonskürzel": "BE",
    "E": "2601193.223000001",
    "N": "1224687.6629999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meienried",
    "PLZ": "3294",
    "Zusatzziffer": "2",
    "Gemeindename": "Meienried",
    "BFS-Nr": "389",
    "Kantonskürzel": "BE",
    "E": "2592622.045000002",
    "N": "1220860.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meinisberg",
    "PLZ": "2554",
    "Zusatzziffer": "0",
    "Gemeindename": "Meinisberg",
    "BFS-Nr": "390",
    "Kantonskürzel": "BE",
    "E": "2593203.3940000013",
    "N": "1223423.4560000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwil b. Büren",
    "PLZ": "3298",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberwil bei Büren",
    "BFS-Nr": "391",
    "Kantonskürzel": "BE",
    "E": "2597775.8139999993",
    "N": "1219558.9880000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pieterlen",
    "PLZ": "2542",
    "Zusatzziffer": "0",
    "Gemeindename": "Pieterlen",
    "BFS-Nr": "392",
    "Kantonskürzel": "BE",
    "E": "2591634.1099999994",
    "N": "1224802.4699999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüti b. Büren",
    "PLZ": "3295",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüti bei Büren",
    "BFS-Nr": "393",
    "Kantonskürzel": "BE",
    "E": "2597596.6460000016",
    "N": "1222611.5980000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wengi b. Büren",
    "PLZ": "3251",
    "Zusatzziffer": "0",
    "Gemeindename": "Wengi",
    "BFS-Nr": "394",
    "Kantonskürzel": "BE",
    "E": "2597074.171",
    "N": "1214583.3079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aefligen",
    "PLZ": "3426",
    "Zusatzziffer": "0",
    "Gemeindename": "Aefligen",
    "BFS-Nr": "401",
    "Kantonskürzel": "BE",
    "E": "2608587.8200000003",
    "N": "1216084.9549999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alchenstorf",
    "PLZ": "3473",
    "Zusatzziffer": "0",
    "Gemeindename": "Alchenstorf",
    "BFS-Nr": "402",
    "Kantonskürzel": "BE",
    "E": "2615228.3489999995",
    "N": "1219502.0009999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bäriswil BE",
    "PLZ": "3323",
    "Zusatzziffer": "0",
    "Gemeindename": "Bäriswil",
    "BFS-Nr": "403",
    "Kantonskürzel": "BE",
    "E": "2607230.4189999998",
    "N": "1207386.9200000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Burgdorf",
    "PLZ": "3400",
    "Zusatzziffer": "0",
    "Gemeindename": "Burgdorf",
    "BFS-Nr": "404",
    "Kantonskürzel": "BE",
    "E": "2614133.971999999",
    "N": "1211738.8390000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ersigen",
    "PLZ": "3423",
    "Zusatzziffer": "0",
    "Gemeindename": "Ersigen",
    "BFS-Nr": "405",
    "Kantonskürzel": "BE",
    "E": "2611694.278000001",
    "N": "1216074.6009999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederösch",
    "PLZ": "3424",
    "Zusatzziffer": "0",
    "Gemeindename": "Ersigen",
    "BFS-Nr": "405",
    "Kantonskürzel": "BE",
    "E": "2613182.7430000007",
    "N": "1218392.9290000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberösch",
    "PLZ": "3424",
    "Zusatzziffer": "2",
    "Gemeindename": "Ersigen",
    "BFS-Nr": "405",
    "Kantonskürzel": "BE",
    "E": "2612859.0480000004",
    "N": "1217631.0529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hasle b. Burgdorf",
    "PLZ": "3415",
    "Zusatzziffer": "1",
    "Gemeindename": "Hasle bei Burgdorf",
    "BFS-Nr": "406",
    "Kantonskürzel": "BE",
    "E": "2615805.9189999998",
    "N": "1207286.1389999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schafhausen im Emmental",
    "PLZ": "3415",
    "Zusatzziffer": "2",
    "Gemeindename": "Hasle bei Burgdorf",
    "BFS-Nr": "406",
    "Kantonskürzel": "BE",
    "E": "2616556.3310000002",
    "N": "1204954.7809999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biembach im Emmental",
    "PLZ": "3419",
    "Zusatzziffer": "0",
    "Gemeindename": "Hasle bei Burgdorf",
    "BFS-Nr": "406",
    "Kantonskürzel": "BE",
    "E": "2613504.6829999983",
    "N": "1204695.072999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heimiswil",
    "PLZ": "3412",
    "Zusatzziffer": "0",
    "Gemeindename": "Heimiswil",
    "BFS-Nr": "407",
    "Kantonskürzel": "BE",
    "E": "2616810.7010000013",
    "N": "1212665.9189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaltacker",
    "PLZ": "3413",
    "Zusatzziffer": "0",
    "Gemeindename": "Heimiswil",
    "BFS-Nr": "407",
    "Kantonskürzel": "BE",
    "E": "2617997.4109999985",
    "N": "1214000.1739999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hellsau",
    "PLZ": "3429",
    "Zusatzziffer": "3",
    "Gemeindename": "Hellsau",
    "BFS-Nr": "408",
    "Kantonskürzel": "BE",
    "E": "2616165.079999998",
    "N": "1221671.8379999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hindelbank",
    "PLZ": "3324",
    "Zusatzziffer": "0",
    "Gemeindename": "Hindelbank",
    "BFS-Nr": "409",
    "Kantonskürzel": "BE",
    "E": "2607706.370000001",
    "N": "1210086.6330000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mötschwil",
    "PLZ": "3324",
    "Zusatzziffer": "2",
    "Gemeindename": "Hindelbank",
    "BFS-Nr": "409",
    "Kantonskürzel": "BE",
    "E": "2609784.8680000007",
    "N": "1210874.6750000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Höchstetten",
    "PLZ": "3429",
    "Zusatzziffer": "2",
    "Gemeindename": "Höchstetten",
    "BFS-Nr": "410",
    "Kantonskürzel": "BE",
    "E": "2614617.2190000005",
    "N": "1221582.2300000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kernenried",
    "PLZ": "3309",
    "Zusatzziffer": "0",
    "Gemeindename": "Kernenried",
    "BFS-Nr": "411",
    "Kantonskürzel": "BE",
    "E": "2608449.642999999",
    "N": "1213204.688000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kirchberg BE",
    "PLZ": "3422",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchberg (BE)",
    "BFS-Nr": "412",
    "Kantonskürzel": "BE",
    "E": "2611143.1130000018",
    "N": "1214762.5560000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Koppigen",
    "PLZ": "3425",
    "Zusatzziffer": "0",
    "Gemeindename": "Koppigen",
    "BFS-Nr": "413",
    "Kantonskürzel": "BE",
    "E": "2611904.796",
    "N": "1220260.6660000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hettiswil b. Hindelbank",
    "PLZ": "3325",
    "Zusatzziffer": "0",
    "Gemeindename": "Krauchthal",
    "BFS-Nr": "414",
    "Kantonskürzel": "BE",
    "E": "2608783.5540000014",
    "N": "1208668.9329999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Krauchthal",
    "PLZ": "3326",
    "Zusatzziffer": "0",
    "Gemeindename": "Krauchthal",
    "BFS-Nr": "414",
    "Kantonskürzel": "BE",
    "E": "2609876.9750000015",
    "N": "1206445.118999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lyssach",
    "PLZ": "3421",
    "Zusatzziffer": "0",
    "Gemeindename": "Lyssach",
    "BFS-Nr": "415",
    "Kantonskürzel": "BE",
    "E": "2610805.6160000004",
    "N": "1212484.7349999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberburg",
    "PLZ": "3414",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberburg",
    "BFS-Nr": "418",
    "Kantonskürzel": "BE",
    "E": "2613820.4959999993",
    "N": "1209843.140999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lyssach",
    "PLZ": "3421",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberburg",
    "BFS-Nr": "418",
    "Kantonskürzel": "BE",
    "E": "2611752.75",
    "N": "1210857.813000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüdtligen",
    "PLZ": "3422",
    "Zusatzziffer": "1",
    "Gemeindename": "Rüdtligen-Alchenflüh",
    "BFS-Nr": "420",
    "Kantonskürzel": "BE",
    "E": "2609960.835000001",
    "N": "1215153.5529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alchenflüh",
    "PLZ": "3422",
    "Zusatzziffer": "2",
    "Gemeindename": "Rüdtligen-Alchenflüh",
    "BFS-Nr": "420",
    "Kantonskürzel": "BE",
    "E": "2610797.859000001",
    "N": "1214680.5659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rumendingen",
    "PLZ": "3472",
    "Zusatzziffer": "2",
    "Gemeindename": "Rumendingen",
    "BFS-Nr": "421",
    "Kantonskürzel": "BE",
    "E": "2615240.59",
    "N": "1217228.938000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüti b. Lyssach",
    "PLZ": "3421",
    "Zusatzziffer": "2",
    "Gemeindename": "Rüti bei Lyssach",
    "BFS-Nr": "422",
    "Kantonskürzel": "BE",
    "E": "2610506.0199999996",
    "N": "1211744.9930000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Willadingen",
    "PLZ": "3425",
    "Zusatzziffer": "2",
    "Gemeindename": "Willadingen",
    "BFS-Nr": "423",
    "Kantonskürzel": "BE",
    "E": "2613082.840999998",
    "N": "1221758.7800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wynigen",
    "PLZ": "3472",
    "Zusatzziffer": "0",
    "Gemeindename": "Wynigen",
    "BFS-Nr": "424",
    "Kantonskürzel": "BE",
    "E": "2617310.454",
    "N": "1217260.3049999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüedisbach",
    "PLZ": "3474",
    "Zusatzziffer": "0",
    "Gemeindename": "Wynigen",
    "BFS-Nr": "424",
    "Kantonskürzel": "BE",
    "E": "2620043.835000001",
    "N": "1218776.9719999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Corgémont",
    "PLZ": "2606",
    "Zusatzziffer": "0",
    "Gemeindename": "Corgémont",
    "BFS-Nr": "431",
    "Kantonskürzel": "BE",
    "E": "2577433.272999998",
    "N": "1227076.3660000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mont-Crosin",
    "PLZ": "2610",
    "Zusatzziffer": "4",
    "Gemeindename": "Cormoret",
    "BFS-Nr": "432",
    "Kantonskürzel": "BE",
    "E": "2569437.1889999993",
    "N": "1225984.0549999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cormoret",
    "PLZ": "2612",
    "Zusatzziffer": "0",
    "Gemeindename": "Cormoret",
    "BFS-Nr": "432",
    "Kantonskürzel": "BE",
    "E": "2570847.5249999985",
    "N": "1224731.8150000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cortébert",
    "PLZ": "2607",
    "Zusatzziffer": "0",
    "Gemeindename": "Cortébert",
    "BFS-Nr": "433",
    "Kantonskürzel": "BE",
    "E": "2574842.278000001",
    "N": "1226791.609000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montagne-de-Courtelary",
    "PLZ": "2608",
    "Zusatzziffer": "2",
    "Gemeindename": "Cortébert",
    "BFS-Nr": "433",
    "Kantonskürzel": "BE",
    "E": "2575512.1499999985",
    "N": "1224434.265999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtelary",
    "PLZ": "2608",
    "Zusatzziffer": "0",
    "Gemeindename": "Courtelary",
    "BFS-Nr": "434",
    "Kantonskürzel": "BE",
    "E": "2571999.2430000007",
    "N": "1225538.1160000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Ferrière",
    "PLZ": "2333",
    "Zusatzziffer": "0",
    "Gemeindename": "La Ferrière",
    "BFS-Nr": "435",
    "Kantonskürzel": "BE",
    "E": "2558711.925999999",
    "N": "1221262.1909999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mont-Tramelan",
    "PLZ": "2723",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Tramelan",
    "BFS-Nr": "437",
    "Kantonskürzel": "BE",
    "E": "2570812.789999999",
    "N": "1228614.7879999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Orvin",
    "PLZ": "2534",
    "Zusatzziffer": "0",
    "Gemeindename": "Orvin",
    "BFS-Nr": "438",
    "Kantonskürzel": "BE",
    "E": "2583146.6380000003",
    "N": "1223154.9780000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Prés-d'Orvin",
    "PLZ": "2534",
    "Zusatzziffer": "2",
    "Gemeindename": "Orvin",
    "BFS-Nr": "438",
    "Kantonskürzel": "BE",
    "E": "2579907.3310000002",
    "N": "1222544.1950000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Renan BE",
    "PLZ": "2616",
    "Zusatzziffer": "0",
    "Gemeindename": "Renan (BE)",
    "BFS-Nr": "441",
    "Kantonskürzel": "BE",
    "E": "2561337.403999999",
    "N": "1219699.796",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Romont BE",
    "PLZ": "2538",
    "Zusatzziffer": "0",
    "Gemeindename": "Romont (BE)",
    "BFS-Nr": "442",
    "Kantonskürzel": "BE",
    "E": "2592553.9789999984",
    "N": "1226439.761",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Imier",
    "PLZ": "2610",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Imier",
    "BFS-Nr": "443",
    "Kantonskürzel": "BE",
    "E": "2566485.1959999986",
    "N": "1222497.5280000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mont-Soleil",
    "PLZ": "2610",
    "Zusatzziffer": "2",
    "Gemeindename": "Saint-Imier",
    "BFS-Nr": "443",
    "Kantonskürzel": "BE",
    "E": "2565735.0940000005",
    "N": "1223291.1629999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Pontins",
    "PLZ": "2610",
    "Zusatzziffer": "3",
    "Gemeindename": "Saint-Imier",
    "BFS-Nr": "443",
    "Kantonskürzel": "BE",
    "E": "2566472.583999999",
    "N": "1220155.6730000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sonceboz-Sombeval",
    "PLZ": "2605",
    "Zusatzziffer": "0",
    "Gemeindename": "Sonceboz-Sombeval",
    "BFS-Nr": "444",
    "Kantonskürzel": "BE",
    "E": "2579535.3559999987",
    "N": "1227161.8299999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sonvilier",
    "PLZ": "2615",
    "Zusatzziffer": "0",
    "Gemeindename": "Sonvilier",
    "BFS-Nr": "445",
    "Kantonskürzel": "BE",
    "E": "2564020.6779999994",
    "N": "1221109.6909999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montagne-de-Sonvilier",
    "PLZ": "2615",
    "Zusatzziffer": "2",
    "Gemeindename": "Sonvilier",
    "BFS-Nr": "445",
    "Kantonskürzel": "BE",
    "E": "2562680.074000001",
    "N": "1218999.9059999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Tramelan",
    "PLZ": "2720",
    "Zusatzziffer": "0",
    "Gemeindename": "Tramelan",
    "BFS-Nr": "446",
    "Kantonskürzel": "BE",
    "E": "2574138.296",
    "N": "1230301.379999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Reussilles",
    "PLZ": "2722",
    "Zusatzziffer": "0",
    "Gemeindename": "Tramelan",
    "BFS-Nr": "446",
    "Kantonskürzel": "BE",
    "E": "2573028.984000001",
    "N": "1230436.2529999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villeret",
    "PLZ": "2613",
    "Zusatzziffer": "0",
    "Gemeindename": "Villeret",
    "BFS-Nr": "448",
    "Kantonskürzel": "BE",
    "E": "2568043.8440000005",
    "N": "1222917.1499999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Frinvillier",
    "PLZ": "2535",
    "Zusatzziffer": "0",
    "Gemeindename": "Sauge",
    "BFS-Nr": "449",
    "Kantonskürzel": "BE",
    "E": "2586260.715",
    "N": "1224301.7300000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Plagne",
    "PLZ": "2536",
    "Zusatzziffer": "0",
    "Gemeindename": "Sauge",
    "BFS-Nr": "449",
    "Kantonskürzel": "BE",
    "E": "2588538.0650000013",
    "N": "1226393.3110000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vauffelin",
    "PLZ": "2537",
    "Zusatzziffer": "0",
    "Gemeindename": "Sauge",
    "BFS-Nr": "449",
    "Kantonskürzel": "BE",
    "E": "2589746.912999999",
    "N": "1226238.8170000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Péry",
    "PLZ": "2603",
    "Zusatzziffer": "0",
    "Gemeindename": "Péry-La Heutte",
    "BFS-Nr": "450",
    "Kantonskürzel": "BE",
    "E": "2585671.0599999987",
    "N": "1227128.8999999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Heutte",
    "PLZ": "2604",
    "Zusatzziffer": "0",
    "Gemeindename": "Péry-La Heutte",
    "BFS-Nr": "450",
    "Kantonskürzel": "BE",
    "E": "2583786.267999999",
    "N": "1226548.609000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brüttelen",
    "PLZ": "3237",
    "Zusatzziffer": "0",
    "Gemeindename": "Brüttelen",
    "BFS-Nr": "491",
    "Kantonskürzel": "BE",
    "E": "2577859.868999999",
    "N": "1207961.050999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erlach",
    "PLZ": "3235",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlach",
    "BFS-Nr": "492",
    "Kantonskürzel": "BE",
    "E": "2573913.9910000004",
    "N": "1210163.4450000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Finsterhennen",
    "PLZ": "2577",
    "Zusatzziffer": "2",
    "Gemeindename": "Finsterhennen",
    "BFS-Nr": "493",
    "Kantonskürzel": "BE",
    "E": "2580214.765999999",
    "N": "1208363.8280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gals",
    "PLZ": "3238",
    "Zusatzziffer": "0",
    "Gemeindename": "Gals",
    "BFS-Nr": "494",
    "Kantonskürzel": "BE",
    "E": "2570430.2639999986",
    "N": "1208608.2190000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gampelen",
    "PLZ": "3236",
    "Zusatzziffer": "0",
    "Gemeindename": "Gampelen",
    "BFS-Nr": "495",
    "Kantonskürzel": "BE",
    "E": "2571152.3379999995",
    "N": "1207130.5639999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ins",
    "PLZ": "3232",
    "Zusatzziffer": "0",
    "Gemeindename": "Ins",
    "BFS-Nr": "496",
    "Kantonskürzel": "BE",
    "E": "2574395.030000001",
    "N": "1206504.7250000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lüscherz",
    "PLZ": "2576",
    "Zusatzziffer": "0",
    "Gemeindename": "Lüscherz",
    "BFS-Nr": "497",
    "Kantonskürzel": "BE",
    "E": "2578184.7490000017",
    "N": "1210664.7829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Müntschemier",
    "PLZ": "3225",
    "Zusatzziffer": "0",
    "Gemeindename": "Müntschemier",
    "BFS-Nr": "498",
    "Kantonskürzel": "BE",
    "E": "2577445.2859999985",
    "N": "1204744.1149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Siselen BE",
    "PLZ": "2577",
    "Zusatzziffer": "0",
    "Gemeindename": "Siselen",
    "BFS-Nr": "499",
    "Kantonskürzel": "BE",
    "E": "2580970.4990000017",
    "N": "1208947.414999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Treiten",
    "PLZ": "3226",
    "Zusatzziffer": "0",
    "Gemeindename": "Treiten",
    "BFS-Nr": "500",
    "Kantonskürzel": "BE",
    "E": "2578883.715999998",
    "N": "1206484.6510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tschugg",
    "PLZ": "3233",
    "Zusatzziffer": "0",
    "Gemeindename": "Tschugg",
    "BFS-Nr": "501",
    "Kantonskürzel": "BE",
    "E": "2572436.0130000003",
    "N": "1208483.4629999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vinelz",
    "PLZ": "3234",
    "Zusatzziffer": "0",
    "Gemeindename": "Vinelz",
    "BFS-Nr": "502",
    "Kantonskürzel": "BE",
    "E": "2575115.9990000017",
    "N": "1209246.8060000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bätterkinden",
    "PLZ": "3315",
    "Zusatzziffer": "0",
    "Gemeindename": "Bätterkinden",
    "BFS-Nr": "533",
    "Kantonskürzel": "BE",
    "E": "2607717.219999999",
    "N": "1219783.7540000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kräiligen",
    "PLZ": "3315",
    "Zusatzziffer": "1",
    "Gemeindename": "Bätterkinden",
    "BFS-Nr": "533",
    "Kantonskürzel": "BE",
    "E": "2607177.2399999984",
    "N": "1222043.0040000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Deisswil b. Münchenbuchsee",
    "PLZ": "3053",
    "Zusatzziffer": "2",
    "Gemeindename": "Deisswil bei Münchenbuchsee",
    "BFS-Nr": "535",
    "Kantonskürzel": "BE",
    "E": "2601141.6350000016",
    "N": "1209589.6160000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diemerswil",
    "PLZ": "3053",
    "Zusatzziffer": "3",
    "Gemeindename": "Diemerswil",
    "BFS-Nr": "536",
    "Kantonskürzel": "BE",
    "E": "2599185.7030000016",
    "N": "1207559.6479999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Etzelkofen",
    "PLZ": "3306",
    "Zusatzziffer": "0",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2603190.414000001",
    "N": "1214829.8090000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grafenried",
    "PLZ": "3308",
    "Zusatzziffer": "0",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2605778.335000001",
    "N": "1213949.8949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zauggenried",
    "PLZ": "3309",
    "Zusatzziffer": "2",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2607770.3900000006",
    "N": "1213579.7639999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fraubrunnen",
    "PLZ": "3312",
    "Zusatzziffer": "0",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2605942.960000001",
    "N": "1214791.5749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büren zum Hof",
    "PLZ": "3313",
    "Zusatzziffer": "0",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2605702.037999999",
    "N": "1216088.9800000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schalunen",
    "PLZ": "3314",
    "Zusatzziffer": "0",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2606512.9739999995",
    "N": "1217712.506000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Limpach",
    "PLZ": "3317",
    "Zusatzziffer": "0",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2604496.8040000014",
    "N": "1217411.8209999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mülchi",
    "PLZ": "3317",
    "Zusatzziffer": "1",
    "Gemeindename": "Fraubrunnen",
    "BFS-Nr": "538",
    "Kantonskürzel": "BE",
    "E": "2602657.7690000013",
    "N": "1216754.7280000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jegenstorf",
    "PLZ": "3303",
    "Zusatzziffer": "0",
    "Gemeindename": "Jegenstorf",
    "BFS-Nr": "540",
    "Kantonskürzel": "BE",
    "E": "2605195.09",
    "N": "1211024.6660000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münchringen",
    "PLZ": "3303",
    "Zusatzziffer": "2",
    "Gemeindename": "Jegenstorf",
    "BFS-Nr": "540",
    "Kantonskürzel": "BE",
    "E": "2606349.868999999",
    "N": "1210577.2809999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ballmoos",
    "PLZ": "3303",
    "Zusatzziffer": "4",
    "Gemeindename": "Jegenstorf",
    "BFS-Nr": "540",
    "Kantonskürzel": "BE",
    "E": "2602829.4580000006",
    "N": "1209939.9860000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Scheunen",
    "PLZ": "3305",
    "Zusatzziffer": "2",
    "Gemeindename": "Jegenstorf",
    "BFS-Nr": "540",
    "Kantonskürzel": "BE",
    "E": "2601219.2349999994",
    "N": "1213232.607999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Iffwil",
    "PLZ": "3305",
    "Zusatzziffer": "0",
    "Gemeindename": "Iffwil",
    "BFS-Nr": "541",
    "Kantonskürzel": "BE",
    "E": "2603008.5309999995",
    "N": "1212555.6130000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mattstetten",
    "PLZ": "3322",
    "Zusatzziffer": "1",
    "Gemeindename": "Mattstetten",
    "BFS-Nr": "543",
    "Kantonskürzel": "BE",
    "E": "2605710.973000001",
    "N": "1208768.675999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Moosseedorf",
    "PLZ": "3302",
    "Zusatzziffer": "0",
    "Gemeindename": "Moosseedorf",
    "BFS-Nr": "544",
    "Kantonskürzel": "BE",
    "E": "2603313.335999999",
    "N": "1207386.7410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münchenbuchsee",
    "PLZ": "3053",
    "Zusatzziffer": "0",
    "Gemeindename": "Münchenbuchsee",
    "BFS-Nr": "546",
    "Kantonskürzel": "BE",
    "E": "2600561.390999999",
    "N": "1207851.0890000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Urtenen-Schönbühl",
    "PLZ": "3322",
    "Zusatzziffer": "0",
    "Gemeindename": "Urtenen-Schönbühl",
    "BFS-Nr": "551",
    "Kantonskürzel": "BE",
    "E": "2604657.522999998",
    "N": "1208282.197999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Utzenstorf",
    "PLZ": "3427",
    "Zusatzziffer": "0",
    "Gemeindename": "Utzenstorf",
    "BFS-Nr": "552",
    "Kantonskürzel": "BE",
    "E": "2609002.835000001",
    "N": "1219542.7309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiggiswil",
    "PLZ": "3053",
    "Zusatzziffer": "4",
    "Gemeindename": "Wiggiswil",
    "BFS-Nr": "553",
    "Kantonskürzel": "BE",
    "E": "2602450.140999999",
    "N": "1208919.9230000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiler b. Utzenstorf",
    "PLZ": "3428",
    "Zusatzziffer": "0",
    "Gemeindename": "Wiler bei Utzenstorf",
    "BFS-Nr": "554",
    "Kantonskürzel": "BE",
    "E": "2608889.6790000014",
    "N": "1222384.4100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zielebach",
    "PLZ": "4564",
    "Zusatzziffer": "1",
    "Gemeindename": "Zielebach",
    "BFS-Nr": "556",
    "Kantonskürzel": "BE",
    "E": "2610186.3900000006",
    "N": "1223188.3200000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zuzwil BE",
    "PLZ": "3303",
    "Zusatzziffer": "3",
    "Gemeindename": "Zuzwil (BE)",
    "BFS-Nr": "557",
    "Kantonskürzel": "BE",
    "E": "2602503.1559999995",
    "N": "1211145.9310000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Adelboden",
    "PLZ": "3715",
    "Zusatzziffer": "0",
    "Gemeindename": "Adelboden",
    "BFS-Nr": "561",
    "Kantonskürzel": "BE",
    "E": "2609226.993999999",
    "N": "1149073.204",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aeschi b. Spiez",
    "PLZ": "3703",
    "Zusatzziffer": "0",
    "Gemeindename": "Aeschi bei Spiez",
    "BFS-Nr": "562",
    "Kantonskürzel": "BE",
    "E": "2619577.7490000017",
    "N": "1167662.9409999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aeschiried",
    "PLZ": "3703",
    "Zusatzziffer": "41",
    "Gemeindename": "Aeschi bei Spiez",
    "BFS-Nr": "562",
    "Kantonskürzel": "BE",
    "E": "2621966.653999999",
    "N": "1166040.6860000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Emdthal",
    "PLZ": "3711",
    "Zusatzziffer": "0",
    "Gemeindename": "Aeschi bei Spiez",
    "BFS-Nr": "562",
    "Kantonskürzel": "BE",
    "E": "2618757.574000001",
    "N": "1167106.7600000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frutigen",
    "PLZ": "3714",
    "Zusatzziffer": "0",
    "Gemeindename": "Frutigen",
    "BFS-Nr": "563",
    "Kantonskürzel": "BE",
    "E": "2615640.846000001",
    "N": "1159665.868999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ried (Frutigen)",
    "PLZ": "3724",
    "Zusatzziffer": "0",
    "Gemeindename": "Frutigen",
    "BFS-Nr": "563",
    "Kantonskürzel": "BE",
    "E": "2613281.4459999986",
    "N": "1157011.5980000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Achseten",
    "PLZ": "3725",
    "Zusatzziffer": "0",
    "Gemeindename": "Frutigen",
    "BFS-Nr": "563",
    "Kantonskürzel": "BE",
    "E": "2612259.3550000004",
    "N": "1152869.8390000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kandergrund",
    "PLZ": "3716",
    "Zusatzziffer": "0",
    "Gemeindename": "Kandergrund",
    "BFS-Nr": "564",
    "Kantonskürzel": "BE",
    "E": "2617208.1460000016",
    "N": "1154952.0029999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Blausee-Mitholz",
    "PLZ": "3717",
    "Zusatzziffer": "0",
    "Gemeindename": "Kandergrund",
    "BFS-Nr": "564",
    "Kantonskürzel": "BE",
    "E": "2618164.438000001",
    "N": "1153095.756000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kandersteg",
    "PLZ": "3718",
    "Zusatzziffer": "0",
    "Gemeindename": "Kandersteg",
    "BFS-Nr": "565",
    "Kantonskürzel": "BE",
    "E": "2618124.8249999993",
    "N": "1149288.5370000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Krattigen",
    "PLZ": "3704",
    "Zusatzziffer": "0",
    "Gemeindename": "Krattigen",
    "BFS-Nr": "566",
    "Kantonskürzel": "BE",
    "E": "2621592.995000001",
    "N": "1167496.1889999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mülenen",
    "PLZ": "3711",
    "Zusatzziffer": "2",
    "Gemeindename": "Reichenbach im Kandertal",
    "BFS-Nr": "567",
    "Kantonskürzel": "BE",
    "E": "2619477.7190000005",
    "N": "1165233.175999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reichenbach im Kandertal",
    "PLZ": "3713",
    "Zusatzziffer": "0",
    "Gemeindename": "Reichenbach im Kandertal",
    "BFS-Nr": "567",
    "Kantonskürzel": "BE",
    "E": "2619560.9109999985",
    "N": "1163909.8079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wengi b. Frutigen",
    "PLZ": "3714",
    "Zusatzziffer": "3",
    "Gemeindename": "Reichenbach im Kandertal",
    "BFS-Nr": "567",
    "Kantonskürzel": "BE",
    "E": "2617176.3489999995",
    "N": "1162060.1550000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Scharnachtal",
    "PLZ": "3722",
    "Zusatzziffer": "0",
    "Gemeindename": "Reichenbach im Kandertal",
    "BFS-Nr": "567",
    "Kantonskürzel": "BE",
    "E": "2619877.0100000016",
    "N": "1162656.8900000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kiental",
    "PLZ": "3723",
    "Zusatzziffer": "0",
    "Gemeindename": "Reichenbach im Kandertal",
    "BFS-Nr": "567",
    "Kantonskürzel": "BE",
    "E": "2621898.743999999",
    "N": "1159832.6229999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sundlauenen",
    "PLZ": "3800",
    "Zusatzziffer": "5",
    "Gemeindename": "Beatenberg",
    "BFS-Nr": "571",
    "Kantonskürzel": "BE",
    "E": "2627304.8660000004",
    "N": "1170758.2760000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beatenberg",
    "PLZ": "3803",
    "Zusatzziffer": "0",
    "Gemeindename": "Beatenberg",
    "BFS-Nr": "571",
    "Kantonskürzel": "BE",
    "E": "2626934.9250000007",
    "N": "1171703.7159999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bönigen b. Interlaken",
    "PLZ": "3806",
    "Zusatzziffer": "0",
    "Gemeindename": "Bönigen",
    "BFS-Nr": "572",
    "Kantonskürzel": "BE",
    "E": "2635333.4880000018",
    "N": "1170415.7789999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brienz BE",
    "PLZ": "3855",
    "Zusatzziffer": "0",
    "Gemeindename": "Brienz (BE)",
    "BFS-Nr": "573",
    "Kantonskürzel": "BE",
    "E": "2644686.7309999987",
    "N": "1178504.4279999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Axalp",
    "PLZ": "3855",
    "Zusatzziffer": "1",
    "Gemeindename": "Brienz (BE)",
    "BFS-Nr": "573",
    "Kantonskürzel": "BE",
    "E": "2645845.2600000016",
    "N": "1174645.5089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brienzwiler",
    "PLZ": "3856",
    "Zusatzziffer": "0",
    "Gemeindename": "Brienzwiler",
    "BFS-Nr": "574",
    "Kantonskürzel": "BE",
    "E": "2650453.5650000013",
    "N": "1177875.5810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Därligen",
    "PLZ": "3707",
    "Zusatzziffer": "0",
    "Gemeindename": "Därligen",
    "BFS-Nr": "575",
    "Kantonskürzel": "BE",
    "E": "2628265.289000001",
    "N": "1167886.6180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Burglauenen",
    "PLZ": "3816",
    "Zusatzziffer": "1",
    "Gemeindename": "Grindelwald",
    "BFS-Nr": "576",
    "Kantonskürzel": "BE",
    "E": "2641305.454999998",
    "N": "1165328.7630000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grindelwald",
    "PLZ": "3818",
    "Zusatzziffer": "0",
    "Gemeindename": "Grindelwald",
    "BFS-Nr": "576",
    "Kantonskürzel": "BE",
    "E": "2646504.5879999995",
    "N": "1163902.5179999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gsteigwiler",
    "PLZ": "3814",
    "Zusatzziffer": "0",
    "Gemeindename": "Gsteigwiler",
    "BFS-Nr": "577",
    "Kantonskürzel": "BE",
    "E": "2633223.524",
    "N": "1167159.5749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilderswil",
    "PLZ": "3812",
    "Zusatzziffer": "0",
    "Gemeindename": "Gündlischwand",
    "BFS-Nr": "578",
    "Kantonskürzel": "BE",
    "E": "2636181.5500000007",
    "N": "1166906.2170000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zweilütschinen",
    "PLZ": "3815",
    "Zusatzziffer": "0",
    "Gemeindename": "Gündlischwand",
    "BFS-Nr": "578",
    "Kantonskürzel": "BE",
    "E": "2635322.8489999995",
    "N": "1164735.5810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gündlischwand",
    "PLZ": "3815",
    "Zusatzziffer": "2",
    "Gemeindename": "Gündlischwand",
    "BFS-Nr": "578",
    "Kantonskürzel": "BE",
    "E": "2636231.046",
    "N": "1164887.3740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Habkern",
    "PLZ": "3804",
    "Zusatzziffer": "0",
    "Gemeindename": "Habkern",
    "BFS-Nr": "579",
    "Kantonskürzel": "BE",
    "E": "2632422.914000001",
    "N": "1174967.868999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hofstetten b. Brienz",
    "PLZ": "3858",
    "Zusatzziffer": "0",
    "Gemeindename": "Hofstetten bei Brienz",
    "BFS-Nr": "580",
    "Kantonskürzel": "BE",
    "E": "2648343.364",
    "N": "1178282.9250000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Interlaken",
    "PLZ": "3800",
    "Zusatzziffer": "0",
    "Gemeindename": "Interlaken",
    "BFS-Nr": "581",
    "Kantonskürzel": "BE",
    "E": "2632453.9299999997",
    "N": "1170717.8090000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Iseltwald",
    "PLZ": "3807",
    "Zusatzziffer": "0",
    "Gemeindename": "Iseltwald",
    "BFS-Nr": "582",
    "Kantonskürzel": "BE",
    "E": "2640230.5199999996",
    "N": "1173294.6380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lauterbrunnen",
    "PLZ": "3822",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2636016.3049999997",
    "N": "1160272.3029999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Isenfluh",
    "PLZ": "3822",
    "Zusatzziffer": "2",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2635002.9849999994",
    "N": "1163323.254999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wengen",
    "PLZ": "3823",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2636828.563000001",
    "N": "1161886.6229999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleine Scheidegg",
    "PLZ": "3823",
    "Zusatzziffer": "1",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2639998.6099999994",
    "N": "1159446.636",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eigergletscher",
    "PLZ": "3823",
    "Zusatzziffer": "2",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2641027.1380000003",
    "N": "1158323.4549999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stechelberg",
    "PLZ": "3824",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2635507.074000001",
    "N": "1155302.4079999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mürren",
    "PLZ": "3825",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2634957.3889999986",
    "N": "1156687.4180000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gimmelwald",
    "PLZ": "3826",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauterbrunnen",
    "BFS-Nr": "584",
    "Kantonskürzel": "BE",
    "E": "2634871.6240000017",
    "N": "1155190.3429999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leissigen",
    "PLZ": "3706",
    "Zusatzziffer": "0",
    "Gemeindename": "Leissigen",
    "BFS-Nr": "585",
    "Kantonskürzel": "BE",
    "E": "2625583.4739999995",
    "N": "1167136.1810000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lütschental",
    "PLZ": "3816",
    "Zusatzziffer": "0",
    "Gemeindename": "Lütschental",
    "BFS-Nr": "586",
    "Kantonskürzel": "BE",
    "E": "2639063.1259999983",
    "N": "1165220.4649999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Matten b. Interlaken",
    "PLZ": "3800",
    "Zusatzziffer": "3",
    "Gemeindename": "Matten bei Interlaken",
    "BFS-Nr": "587",
    "Kantonskürzel": "BE",
    "E": "2632983.403000001",
    "N": "1169651.1259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederried b. Interlaken",
    "PLZ": "3853",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederried bei Interlaken",
    "BFS-Nr": "588",
    "Kantonskürzel": "BE",
    "E": "2637522.6149999984",
    "N": "1174062.800999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberried am Brienzersee",
    "PLZ": "3854",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberried am Brienzersee",
    "BFS-Nr": "589",
    "Kantonskürzel": "BE",
    "E": "2639595.0489999987",
    "N": "1176438.6460000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Goldswil b. Interlaken",
    "PLZ": "3805",
    "Zusatzziffer": "0",
    "Gemeindename": "Ringgenberg (BE)",
    "BFS-Nr": "590",
    "Kantonskürzel": "BE",
    "E": "2633779.1840000004",
    "N": "1171802.5229999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ringgenberg BE",
    "PLZ": "3852",
    "Zusatzziffer": "0",
    "Gemeindename": "Ringgenberg (BE)",
    "BFS-Nr": "590",
    "Kantonskürzel": "BE",
    "E": "2635017.3850000016",
    "N": "1172284.9560000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saxeten",
    "PLZ": "3813",
    "Zusatzziffer": "0",
    "Gemeindename": "Saxeten",
    "BFS-Nr": "591",
    "Kantonskürzel": "BE",
    "E": "2630042.280000001",
    "N": "1165106.4389999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwanden b. Brienz",
    "PLZ": "3855",
    "Zusatzziffer": "41",
    "Gemeindename": "Schwanden bei Brienz",
    "BFS-Nr": "592",
    "Kantonskürzel": "BE",
    "E": "2647343.989",
    "N": "1178641.1999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterseen",
    "PLZ": "3800",
    "Zusatzziffer": "2",
    "Gemeindename": "Unterseen",
    "BFS-Nr": "593",
    "Kantonskürzel": "BE",
    "E": "2631433.754999999",
    "N": "1170793.364",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilderswil",
    "PLZ": "3812",
    "Zusatzziffer": "0",
    "Gemeindename": "Wilderswil",
    "BFS-Nr": "594",
    "Kantonskürzel": "BE",
    "E": "2632972.295000002",
    "N": "1168407.5579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arni BE",
    "PLZ": "3508",
    "Zusatzziffer": "0",
    "Gemeindename": "Arni (BE)",
    "BFS-Nr": "602",
    "Kantonskürzel": "BE",
    "E": "2617472.719999999",
    "N": "1198361.3940000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biglen",
    "PLZ": "3507",
    "Zusatzziffer": "0",
    "Gemeindename": "Biglen",
    "BFS-Nr": "603",
    "Kantonskürzel": "BE",
    "E": "2614487.285",
    "N": "1197670.9739999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bowil",
    "PLZ": "3533",
    "Zusatzziffer": "0",
    "Gemeindename": "Bowil",
    "BFS-Nr": "605",
    "Kantonskürzel": "BE",
    "E": "2619537.397999998",
    "N": "1193578.7049999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brenzikofen",
    "PLZ": "3671",
    "Zusatzziffer": "0",
    "Gemeindename": "Brenzikofen",
    "BFS-Nr": "606",
    "Kantonskürzel": "BE",
    "E": "2613366.715999998",
    "N": "1185013.1229999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Freimettigen",
    "PLZ": "3510",
    "Zusatzziffer": "8",
    "Gemeindename": "Freimettigen",
    "BFS-Nr": "607",
    "Kantonskürzel": "BE",
    "E": "2614390.971000001",
    "N": "1190775.1229999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schlosswil",
    "PLZ": "3082",
    "Zusatzziffer": "0",
    "Gemeindename": "Grosshöchstetten",
    "BFS-Nr": "608",
    "Kantonskürzel": "BE",
    "E": "2613185.8630000018",
    "N": "1195301.9530000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grosshöchstetten",
    "PLZ": "3506",
    "Zusatzziffer": "0",
    "Gemeindename": "Grosshöchstetten",
    "BFS-Nr": "608",
    "Kantonskürzel": "BE",
    "E": "2615467.8209999986",
    "N": "1195127.4730000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Häutligen",
    "PLZ": "3510",
    "Zusatzziffer": "9",
    "Gemeindename": "Häutligen",
    "BFS-Nr": "609",
    "Kantonskürzel": "BE",
    "E": "2612816.7650000006",
    "N": "1189620.9679999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herbligen",
    "PLZ": "3671",
    "Zusatzziffer": "2",
    "Gemeindename": "Herbligen",
    "BFS-Nr": "610",
    "Kantonskürzel": "BE",
    "E": "2612936.857999999",
    "N": "1186254.1660000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kiesen",
    "PLZ": "3629",
    "Zusatzziffer": "0",
    "Gemeindename": "Kiesen",
    "BFS-Nr": "611",
    "Kantonskürzel": "BE",
    "E": "2610439.2399999984",
    "N": "1185376.0280000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gysenstein",
    "PLZ": "3503",
    "Zusatzziffer": "0",
    "Gemeindename": "Konolfingen",
    "BFS-Nr": "612",
    "Kantonskürzel": "BE",
    "E": "2611900.596000001",
    "N": "1193173.631000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Konolfingen",
    "PLZ": "3510",
    "Zusatzziffer": "0",
    "Gemeindename": "Konolfingen",
    "BFS-Nr": "612",
    "Kantonskürzel": "BE",
    "E": "2613784.9250000007",
    "N": "1191909.9279999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obergoldbach",
    "PLZ": "3434",
    "Zusatzziffer": "0",
    "Gemeindename": "Landiswil",
    "BFS-Nr": "613",
    "Kantonskürzel": "BE",
    "E": "2617824.631000001",
    "N": "1201084.6950000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Landiswil",
    "PLZ": "3434",
    "Zusatzziffer": "1",
    "Gemeindename": "Landiswil",
    "BFS-Nr": "613",
    "Kantonskürzel": "BE",
    "E": "2618372.795000002",
    "N": "1200838.9310000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Linden",
    "PLZ": "3673",
    "Zusatzziffer": "0",
    "Gemeindename": "Linden",
    "BFS-Nr": "614",
    "Kantonskürzel": "BE",
    "E": "2618194.2360000014",
    "N": "1188483.0909999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mirchel",
    "PLZ": "3532",
    "Zusatzziffer": "2",
    "Gemeindename": "Mirchel",
    "BFS-Nr": "615",
    "Kantonskürzel": "BE",
    "E": "2615895.5830000006",
    "N": "1193854.697999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trimstein",
    "PLZ": "3083",
    "Zusatzziffer": "0",
    "Gemeindename": "Münsingen",
    "BFS-Nr": "616",
    "Kantonskürzel": "BE",
    "E": "2610774.072999999",
    "N": "1194966.443",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münsingen",
    "PLZ": "3110",
    "Zusatzziffer": "0",
    "Gemeindename": "Münsingen",
    "BFS-Nr": "616",
    "Kantonskürzel": "BE",
    "E": "2608899.164999999",
    "N": "1191709.868999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tägertschi",
    "PLZ": "3111",
    "Zusatzziffer": "0",
    "Gemeindename": "Münsingen",
    "BFS-Nr": "616",
    "Kantonskürzel": "BE",
    "E": "2611278.1510000005",
    "N": "1191441.3079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederhünigen",
    "PLZ": "3504",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederhünigen",
    "BFS-Nr": "617",
    "Kantonskürzel": "BE",
    "E": "2615083.5009999983",
    "N": "1191877.3740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberdiessbach",
    "PLZ": "3672",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberdiessbach",
    "BFS-Nr": "619",
    "Kantonskürzel": "BE",
    "E": "2614122.506000001",
    "N": "1187462.2430000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aeschlen b. Oberdiessbach",
    "PLZ": "3672",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberdiessbach",
    "BFS-Nr": "619",
    "Kantonskürzel": "BE",
    "E": "2615855.046",
    "N": "1187398.6440000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bleiken b. Oberdiessbach",
    "PLZ": "3674",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberdiessbach",
    "BFS-Nr": "619",
    "Kantonskürzel": "BE",
    "E": "2615281.8900000006",
    "N": "1185029.6499999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberthal",
    "PLZ": "3531",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberthal",
    "BFS-Nr": "620",
    "Kantonskürzel": "BE",
    "E": "2617993.9800000004",
    "N": "1196197.572999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oppligen",
    "PLZ": "3629",
    "Zusatzziffer": "2",
    "Gemeindename": "Oppligen",
    "BFS-Nr": "622",
    "Kantonskürzel": "BE",
    "E": "2611920.2930000015",
    "N": "1185445.6730000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rubigen",
    "PLZ": "3113",
    "Zusatzziffer": "0",
    "Gemeindename": "Rubigen",
    "BFS-Nr": "623",
    "Kantonskürzel": "BE",
    "E": "2608428.848000001",
    "N": "1194095.1609999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walkringen",
    "PLZ": "3512",
    "Zusatzziffer": "0",
    "Gemeindename": "Walkringen",
    "BFS-Nr": "626",
    "Kantonskürzel": "BE",
    "E": "2613413.631000001",
    "N": "1199676.6380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bigenthal",
    "PLZ": "3513",
    "Zusatzziffer": "0",
    "Gemeindename": "Walkringen",
    "BFS-Nr": "626",
    "Kantonskürzel": "BE",
    "E": "2614065.1460000016",
    "N": "1201637.0009999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüfenacht BE",
    "PLZ": "3075",
    "Zusatzziffer": "0",
    "Gemeindename": "Worb",
    "BFS-Nr": "627",
    "Kantonskürzel": "BE",
    "E": "2607606.8790000007",
    "N": "1197628.504999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vielbringen b. Worb",
    "PLZ": "3075",
    "Zusatzziffer": "2",
    "Gemeindename": "Worb",
    "BFS-Nr": "627",
    "Kantonskürzel": "BE",
    "E": "2608413.7630000003",
    "N": "1196030.5949999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Worb",
    "PLZ": "3076",
    "Zusatzziffer": "0",
    "Gemeindename": "Worb",
    "BFS-Nr": "627",
    "Kantonskürzel": "BE",
    "E": "2609571.5890000015",
    "N": "1197679.6009999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Enggistein",
    "PLZ": "3077",
    "Zusatzziffer": "0",
    "Gemeindename": "Worb",
    "BFS-Nr": "627",
    "Kantonskürzel": "BE",
    "E": "2612052.239",
    "N": "1197911.802000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Richigen",
    "PLZ": "3078",
    "Zusatzziffer": "0",
    "Gemeindename": "Worb",
    "BFS-Nr": "627",
    "Kantonskürzel": "BE",
    "E": "2611294.4299999997",
    "N": "1196438.3759999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zäziwil",
    "PLZ": "3532",
    "Zusatzziffer": "0",
    "Gemeindename": "Zäziwil",
    "BFS-Nr": "628",
    "Kantonskürzel": "BE",
    "E": "2616949.6939999983",
    "N": "1194616.8740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhünigen",
    "PLZ": "3504",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberhünigen",
    "BFS-Nr": "629",
    "Kantonskürzel": "BE",
    "E": "2616764.261",
    "N": "1192092.1429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Allmendingen b. Bern",
    "PLZ": "3112",
    "Zusatzziffer": "0",
    "Gemeindename": "Allmendingen",
    "BFS-Nr": "630",
    "Kantonskürzel": "BE",
    "E": "2606513.2410000004",
    "N": "1195967.6579999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wichtrach",
    "PLZ": "3114",
    "Zusatzziffer": "0",
    "Gemeindename": "Wichtrach",
    "BFS-Nr": "632",
    "Kantonskürzel": "BE",
    "E": "2610739.789000001",
    "N": "1188274.859000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Clavaleyres",
    "PLZ": "1595",
    "Zusatzziffer": "2",
    "Gemeindename": "Clavaleyres",
    "BFS-Nr": "661",
    "Kantonskürzel": "BE",
    "E": "2573530.226",
    "N": "1194319.8000000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rizenbach",
    "PLZ": "3206",
    "Zusatzziffer": "0",
    "Gemeindename": "Ferenbalm",
    "BFS-Nr": "662",
    "Kantonskürzel": "BE",
    "E": "2583893.6350000016",
    "N": "1199177.4239999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ferenbalm",
    "PLZ": "3206",
    "Zusatzziffer": "1",
    "Gemeindename": "Ferenbalm",
    "BFS-Nr": "662",
    "Kantonskürzel": "BE",
    "E": "2582704.408",
    "N": "1199720.629999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biberen",
    "PLZ": "3206",
    "Zusatzziffer": "3",
    "Gemeindename": "Ferenbalm",
    "BFS-Nr": "662",
    "Kantonskürzel": "BE",
    "E": "2582746.864",
    "N": "1198799.7710000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gammen",
    "PLZ": "3206",
    "Zusatzziffer": "4",
    "Gemeindename": "Ferenbalm",
    "BFS-Nr": "662",
    "Kantonskürzel": "BE",
    "E": "2583886.039000001",
    "N": "1196522.4329999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frauenkappelen",
    "PLZ": "3202",
    "Zusatzziffer": "0",
    "Gemeindename": "Frauenkappelen",
    "BFS-Nr": "663",
    "Kantonskürzel": "BE",
    "E": "2592313.3880000003",
    "N": "1200513.296",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gurbrü",
    "PLZ": "3208",
    "Zusatzziffer": "0",
    "Gemeindename": "Gurbrü",
    "BFS-Nr": "665",
    "Kantonskürzel": "BE",
    "E": "2583212.4250000007",
    "N": "1201471.0500000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kriechenwil",
    "PLZ": "3179",
    "Zusatzziffer": "0",
    "Gemeindename": "Kriechenwil",
    "BFS-Nr": "666",
    "Kantonskürzel": "BE",
    "E": "2583860.7289999984",
    "N": "1195541.618999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Laupen BE",
    "PLZ": "3177",
    "Zusatzziffer": "0",
    "Gemeindename": "Laupen",
    "BFS-Nr": "667",
    "Kantonskürzel": "BE",
    "E": "2584925.7639999986",
    "N": "1194666.7089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühleberg",
    "PLZ": "3203",
    "Zusatzziffer": "0",
    "Gemeindename": "Mühleberg",
    "BFS-Nr": "668",
    "Kantonskürzel": "BE",
    "E": "2586549.921",
    "N": "1200317.811999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rosshäusern",
    "PLZ": "3204",
    "Zusatzziffer": "0",
    "Gemeindename": "Mühleberg",
    "BFS-Nr": "668",
    "Kantonskürzel": "BE",
    "E": "2587604.785",
    "N": "1198062.379999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gümmenen",
    "PLZ": "3205",
    "Zusatzziffer": "0",
    "Gemeindename": "Mühleberg",
    "BFS-Nr": "668",
    "Kantonskürzel": "BE",
    "E": "2585590.348000001",
    "N": "1199217.1180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münchenwiler",
    "PLZ": "1797",
    "Zusatzziffer": "0",
    "Gemeindename": "Münchenwiler",
    "BFS-Nr": "669",
    "Kantonskürzel": "BE",
    "E": "2576209.2980000004",
    "N": "1195673.5749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuenegg",
    "PLZ": "3176",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuenegg",
    "BFS-Nr": "670",
    "Kantonskürzel": "BE",
    "E": "2589321.0029999986",
    "N": "1194009.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wileroltigen",
    "PLZ": "3207",
    "Zusatzziffer": "0",
    "Gemeindename": "Wileroltigen",
    "BFS-Nr": "671",
    "Kantonskürzel": "BE",
    "E": "2584797.2699999996",
    "N": "1202212.1479999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Belprahon",
    "PLZ": "2744",
    "Zusatzziffer": "0",
    "Gemeindename": "Belprahon",
    "BFS-Nr": "681",
    "Kantonskürzel": "BE",
    "E": "2597523.149",
    "N": "1237274.1279999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champoz",
    "PLZ": "2735",
    "Zusatzziffer": "3",
    "Gemeindename": "Champoz",
    "BFS-Nr": "683",
    "Kantonskürzel": "BE",
    "E": "2589397.5549999997",
    "N": "1233858.539999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corcelles BE",
    "PLZ": "2747",
    "Zusatzziffer": "0",
    "Gemeindename": "Corcelles (BE)",
    "BFS-Nr": "687",
    "Kantonskürzel": "BE",
    "E": "2601240.017999999",
    "N": "1237173.9120000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Court",
    "PLZ": "2738",
    "Zusatzziffer": "0",
    "Gemeindename": "Court",
    "BFS-Nr": "690",
    "Kantonskürzel": "BE",
    "E": "2591942.234000001",
    "N": "1232121.5760000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Crémines",
    "PLZ": "2746",
    "Zusatzziffer": "0",
    "Gemeindename": "Crémines",
    "BFS-Nr": "691",
    "Kantonskürzel": "BE",
    "E": "2600034.7509999983",
    "N": "1237035.9079999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Eschert",
    "PLZ": "2743",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschert",
    "BFS-Nr": "692",
    "Kantonskürzel": "BE",
    "E": "2596989.9459999986",
    "N": "1236078.0329999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandval",
    "PLZ": "2745",
    "Zusatzziffer": "0",
    "Gemeindename": "Grandval",
    "BFS-Nr": "694",
    "Kantonskürzel": "BE",
    "E": "2598986.045000002",
    "N": "1236950.3500000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Loveresse",
    "PLZ": "2732",
    "Zusatzziffer": "4",
    "Gemeindename": "Loveresse",
    "BFS-Nr": "696",
    "Kantonskürzel": "BE",
    "E": "2584669.1229999997",
    "N": "1232378.8680000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Moutier",
    "PLZ": "2740",
    "Zusatzziffer": "0",
    "Gemeindename": "Moutier",
    "BFS-Nr": "700",
    "Kantonskürzel": "BE",
    "E": "2594971.0150000006",
    "N": "1236623.8379999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Perrefitte",
    "PLZ": "2742",
    "Zusatzziffer": "0",
    "Gemeindename": "Perrefitte",
    "BFS-Nr": "701",
    "Kantonskürzel": "BE",
    "E": "2592680.9860000014",
    "N": "1235987.1030000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Reconvilier",
    "PLZ": "2732",
    "Zusatzziffer": "0",
    "Gemeindename": "Reconvilier",
    "BFS-Nr": "703",
    "Kantonskürzel": "BE",
    "E": "2583997.454",
    "N": "1231613.8449999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Roches BE",
    "PLZ": "2762",
    "Zusatzziffer": "0",
    "Gemeindename": "Roches (BE)",
    "BFS-Nr": "704",
    "Kantonskürzel": "BE",
    "E": "2595757.631000001",
    "N": "1239021.4010000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Fuet",
    "PLZ": "2712",
    "Zusatzziffer": "0",
    "Gemeindename": "Saicourt",
    "BFS-Nr": "706",
    "Kantonskürzel": "BE",
    "E": "2580764.153999999",
    "N": "1232621.3999999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bellelay",
    "PLZ": "2713",
    "Zusatzziffer": "0",
    "Gemeindename": "Saicourt",
    "BFS-Nr": "706",
    "Kantonskürzel": "BE",
    "E": "2579541.8599999994",
    "N": "1234820.0989999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saicourt",
    "PLZ": "2732",
    "Zusatzziffer": "2",
    "Gemeindename": "Saicourt",
    "BFS-Nr": "706",
    "Kantonskürzel": "BE",
    "E": "2582506.8740000017",
    "N": "1232477.6779999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saules BE",
    "PLZ": "2732",
    "Zusatzziffer": "3",
    "Gemeindename": "Saules (BE)",
    "BFS-Nr": "707",
    "Kantonskürzel": "BE",
    "E": "2583364.0009999983",
    "N": "1232691.1380000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Schelten",
    "PLZ": "2827",
    "Zusatzziffer": "2",
    "Gemeindename": "Schelten",
    "BFS-Nr": "708",
    "Kantonskürzel": "BE",
    "E": "2608514.796",
    "N": "1242354.1339999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seehof",
    "PLZ": "2747",
    "Zusatzziffer": "2",
    "Gemeindename": "Seehof",
    "BFS-Nr": "709",
    "Kantonskürzel": "BE",
    "E": "2606529.3280000016",
    "N": "1239313.8880000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sorvilier",
    "PLZ": "2736",
    "Zusatzziffer": "0",
    "Gemeindename": "Sorvilier",
    "BFS-Nr": "711",
    "Kantonskürzel": "BE",
    "E": "2589932.283",
    "N": "1232063.8729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Tavannes",
    "PLZ": "2710",
    "Zusatzziffer": "0",
    "Gemeindename": "Tavannes",
    "BFS-Nr": "713",
    "Kantonskürzel": "BE",
    "E": "2581728.493999999",
    "N": "1230091.6130000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Tanne",
    "PLZ": "2720",
    "Zusatzziffer": "2",
    "Gemeindename": "Tavannes",
    "BFS-Nr": "713",
    "Kantonskürzel": "BE",
    "E": "2578552.166000001",
    "N": "1229999.7859999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rebévelier",
    "PLZ": "2717",
    "Zusatzziffer": "2",
    "Gemeindename": "Rebévelier",
    "BFS-Nr": "715",
    "Kantonskürzel": "BE",
    "E": "2581390.0489999987",
    "N": "1237837.1810000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châtelat",
    "PLZ": "2715",
    "Zusatzziffer": "1",
    "Gemeindename": "Petit-Val",
    "BFS-Nr": "716",
    "Kantonskürzel": "BE",
    "E": "2581520.8839999996",
    "N": "1235713.4849999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Monible",
    "PLZ": "2715",
    "Zusatzziffer": "2",
    "Gemeindename": "Petit-Val",
    "BFS-Nr": "716",
    "Kantonskürzel": "BE",
    "E": "2581963.833999999",
    "N": "1236187.3339999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sornetan",
    "PLZ": "2716",
    "Zusatzziffer": "0",
    "Gemeindename": "Petit-Val",
    "BFS-Nr": "716",
    "Kantonskürzel": "BE",
    "E": "2582999.6460000016",
    "N": "1235931.0980000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fornet-Dessous",
    "PLZ": "2717",
    "Zusatzziffer": "0",
    "Gemeindename": "Petit-Val",
    "BFS-Nr": "716",
    "Kantonskürzel": "BE",
    "E": "2579911.103",
    "N": "1236631.2580000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Souboz",
    "PLZ": "2748",
    "Zusatzziffer": "0",
    "Gemeindename": "Petit-Val",
    "BFS-Nr": "716",
    "Kantonskürzel": "BE",
    "E": "2585214.4180000015",
    "N": "1235933.3729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Ecorcheresses",
    "PLZ": "2748",
    "Zusatzziffer": "2",
    "Gemeindename": "Petit-Val",
    "BFS-Nr": "716",
    "Kantonskürzel": "BE",
    "E": "2587970.3740000017",
    "N": "1236223.5340000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pontenet",
    "PLZ": "2733",
    "Zusatzziffer": "0",
    "Gemeindename": "Valbirse",
    "BFS-Nr": "717",
    "Kantonskürzel": "BE",
    "E": "2586053.1510000005",
    "N": "1232016.9990000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bévilard",
    "PLZ": "2735",
    "Zusatzziffer": "2",
    "Gemeindename": "Valbirse",
    "BFS-Nr": "717",
    "Kantonskürzel": "BE",
    "E": "2588171.5359999985",
    "N": "1231907.0219999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Malleray",
    "PLZ": "2735",
    "Zusatzziffer": "4",
    "Gemeindename": "Valbirse",
    "BFS-Nr": "717",
    "Kantonskürzel": "BE",
    "E": "2587602.412999999",
    "N": "1231942.1629999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Neuveville",
    "PLZ": "2520",
    "Zusatzziffer": "0",
    "Gemeindename": "La Neuveville",
    "BFS-Nr": "723",
    "Kantonskürzel": "BE",
    "E": "2574052.210999999",
    "N": "1212660.5650000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Nods",
    "PLZ": "2518",
    "Zusatzziffer": "0",
    "Gemeindename": "Nods",
    "BFS-Nr": "724",
    "Kantonskürzel": "BE",
    "E": "2572803.91",
    "N": "1217986.2699999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prêles",
    "PLZ": "2515",
    "Zusatzziffer": "0",
    "Gemeindename": "Plateau de Diesse",
    "BFS-Nr": "726",
    "Kantonskürzel": "BE",
    "E": "2576561.017999999",
    "N": "1216526.6400000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lamboing",
    "PLZ": "2516",
    "Zusatzziffer": "0",
    "Gemeindename": "Plateau de Diesse",
    "BFS-Nr": "726",
    "Kantonskürzel": "BE",
    "E": "2577060.495000001",
    "N": "1218529.6110000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Diesse",
    "PLZ": "2517",
    "Zusatzziffer": "0",
    "Gemeindename": "Plateau de Diesse",
    "BFS-Nr": "726",
    "Kantonskürzel": "BE",
    "E": "2575621.3830000013",
    "N": "1217968.7950000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aegerten",
    "PLZ": "2558",
    "Zusatzziffer": "0",
    "Gemeindename": "Aegerten",
    "BFS-Nr": "731",
    "Kantonskürzel": "BE",
    "E": "2588846.5639999993",
    "N": "1218917.3339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bellmund",
    "PLZ": "2564",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellmund",
    "BFS-Nr": "732",
    "Kantonskürzel": "BE",
    "E": "2585332.164000001",
    "N": "1217350.921",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brügg BE",
    "PLZ": "2555",
    "Zusatzziffer": "0",
    "Gemeindename": "Brügg",
    "BFS-Nr": "733",
    "Kantonskürzel": "BE",
    "E": "2587820.399",
    "N": "1219185.1499999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bühl b. Aarberg",
    "PLZ": "3274",
    "Zusatzziffer": "2",
    "Gemeindename": "Bühl",
    "BFS-Nr": "734",
    "Kantonskürzel": "BE",
    "E": "2585410.329",
    "N": "1213215.1799999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Epsach",
    "PLZ": "3272",
    "Zusatzziffer": "2",
    "Gemeindename": "Epsach",
    "BFS-Nr": "735",
    "Kantonskürzel": "BE",
    "E": "2583524.800999999",
    "N": "1213295.1860000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hagneck",
    "PLZ": "2575",
    "Zusatzziffer": "2",
    "Gemeindename": "Hagneck",
    "BFS-Nr": "736",
    "Kantonskürzel": "BE",
    "E": "2580955.721000001",
    "N": "1211931.6779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hermrigen",
    "PLZ": "3274",
    "Zusatzziffer": "0",
    "Gemeindename": "Hermrigen",
    "BFS-Nr": "737",
    "Kantonskürzel": "BE",
    "E": "2585216.6400000006",
    "N": "1214511.3649999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jens",
    "PLZ": "2565",
    "Zusatzziffer": "0",
    "Gemeindename": "Jens",
    "BFS-Nr": "738",
    "Kantonskürzel": "BE",
    "E": "2586710.5480000004",
    "N": "1216161.381000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ipsach",
    "PLZ": "2563",
    "Zusatzziffer": "0",
    "Gemeindename": "Ipsach",
    "BFS-Nr": "739",
    "Kantonskürzel": "BE",
    "E": "2584563.3839999996",
    "N": "1218494.5529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ligerz",
    "PLZ": "2514",
    "Zusatzziffer": "0",
    "Gemeindename": "Ligerz",
    "BFS-Nr": "740",
    "Kantonskürzel": "BE",
    "E": "2576973.7399999984",
    "N": "1215027.199000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Merzligen",
    "PLZ": "3274",
    "Zusatzziffer": "3",
    "Gemeindename": "Merzligen",
    "BFS-Nr": "741",
    "Kantonskürzel": "BE",
    "E": "2586006.8330000006",
    "N": "1215198.256000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mörigen",
    "PLZ": "2572",
    "Zusatzziffer": "2",
    "Gemeindename": "Mörigen",
    "BFS-Nr": "742",
    "Kantonskürzel": "BE",
    "E": "2582786.9299999997",
    "N": "1214837.5599999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nidau",
    "PLZ": "2560",
    "Zusatzziffer": "0",
    "Gemeindename": "Nidau",
    "BFS-Nr": "743",
    "Kantonskürzel": "BE",
    "E": "2584970.3680000007",
    "N": "1219358.4640000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Orpund",
    "PLZ": "2552",
    "Zusatzziffer": "0",
    "Gemeindename": "Orpund",
    "BFS-Nr": "744",
    "Kantonskürzel": "BE",
    "E": "2590457.0639999993",
    "N": "1220703.4690000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Port",
    "PLZ": "2562",
    "Zusatzziffer": "0",
    "Gemeindename": "Port",
    "BFS-Nr": "745",
    "Kantonskürzel": "BE",
    "E": "2586096.0659999996",
    "N": "1218486.6559999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Safnern",
    "PLZ": "2553",
    "Zusatzziffer": "0",
    "Gemeindename": "Safnern",
    "BFS-Nr": "746",
    "Kantonskürzel": "BE",
    "E": "2591592.664000001",
    "N": "1222158.982999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Scheuren",
    "PLZ": "2556",
    "Zusatzziffer": "0",
    "Gemeindename": "Scheuren",
    "BFS-Nr": "747",
    "Kantonskürzel": "BE",
    "E": "2591068.0760000013",
    "N": "1220421.2309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwadernau",
    "PLZ": "2556",
    "Zusatzziffer": "2",
    "Gemeindename": "Schwadernau",
    "BFS-Nr": "748",
    "Kantonskürzel": "BE",
    "E": "2590071.829",
    "N": "1219654.5009999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Studen BE",
    "PLZ": "2557",
    "Zusatzziffer": "0",
    "Gemeindename": "Studen (BE)",
    "BFS-Nr": "749",
    "Kantonskürzel": "BE",
    "E": "2589724.3610000014",
    "N": "1218015.1130000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sutz",
    "PLZ": "2572",
    "Zusatzziffer": "0",
    "Gemeindename": "Sutz-Lattrigen",
    "BFS-Nr": "750",
    "Kantonskürzel": "BE",
    "E": "2583169.0940000005",
    "N": "1217023.4699999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Täuffelen",
    "PLZ": "2575",
    "Zusatzziffer": "0",
    "Gemeindename": "Täuffelen",
    "BFS-Nr": "751",
    "Kantonskürzel": "BE",
    "E": "2581772.620000001",
    "N": "1212702.109000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gerolfingen",
    "PLZ": "2575",
    "Zusatzziffer": "1",
    "Gemeindename": "Täuffelen",
    "BFS-Nr": "751",
    "Kantonskürzel": "BE",
    "E": "2581972.0639999993",
    "N": "1213804.6380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walperswil",
    "PLZ": "3272",
    "Zusatzziffer": "0",
    "Gemeindename": "Walperswil",
    "BFS-Nr": "754",
    "Kantonskürzel": "BE",
    "E": "2584155.965999998",
    "N": "1212037.5300000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Worben",
    "PLZ": "3252",
    "Zusatzziffer": "0",
    "Gemeindename": "Worben",
    "BFS-Nr": "755",
    "Kantonskürzel": "BE",
    "E": "2588889.5249999985",
    "N": "1216580.682",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tüscherz-Alfermée",
    "PLZ": "2512",
    "Zusatzziffer": "0",
    "Gemeindename": "Twann-Tüscherz",
    "BFS-Nr": "756",
    "Kantonskürzel": "BE",
    "E": "2581569.2630000003",
    "N": "1218318.6070000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Twann",
    "PLZ": "2513",
    "Zusatzziffer": "0",
    "Gemeindename": "Twann-Tüscherz",
    "BFS-Nr": "756",
    "Kantonskürzel": "BE",
    "E": "2578837.3559999987",
    "N": "1216074.3249999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Därstetten",
    "PLZ": "3763",
    "Zusatzziffer": "0",
    "Gemeindename": "Därstetten",
    "BFS-Nr": "761",
    "Kantonskürzel": "BE",
    "E": "2604368.3900000006",
    "N": "1167507.9510000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weissenburg",
    "PLZ": "3764",
    "Zusatzziffer": "0",
    "Gemeindename": "Därstetten",
    "BFS-Nr": "761",
    "Kantonskürzel": "BE",
    "E": "2602829.1460000016",
    "N": "1167474.6429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oey",
    "PLZ": "3753",
    "Zusatzziffer": "0",
    "Gemeindename": "Diemtigen",
    "BFS-Nr": "762",
    "Kantonskürzel": "BE",
    "E": "2610774.658",
    "N": "1167641.2349999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diemtigen",
    "PLZ": "3754",
    "Zusatzziffer": "0",
    "Gemeindename": "Diemtigen",
    "BFS-Nr": "762",
    "Kantonskürzel": "BE",
    "E": "2609758.914999999",
    "N": "1166468.6730000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Horboden",
    "PLZ": "3755",
    "Zusatzziffer": "0",
    "Gemeindename": "Diemtigen",
    "BFS-Nr": "762",
    "Kantonskürzel": "BE",
    "E": "2609614.6279999986",
    "N": "1164357.0439999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zwischenflüh",
    "PLZ": "3756",
    "Zusatzziffer": "0",
    "Gemeindename": "Diemtigen",
    "BFS-Nr": "762",
    "Kantonskürzel": "BE",
    "E": "2605895.969999999",
    "N": "1161801.3310000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwenden im Diemtigtal",
    "PLZ": "3757",
    "Zusatzziffer": "0",
    "Gemeindename": "Diemtigen",
    "BFS-Nr": "762",
    "Kantonskürzel": "BE",
    "E": "2603714.664999999",
    "N": "1158205.7890000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Latterbach",
    "PLZ": "3758",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlenbach im Simmental",
    "BFS-Nr": "763",
    "Kantonskürzel": "BE",
    "E": "2610670.938000001",
    "N": "1168103.313000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erlenbach im Simmental",
    "PLZ": "3762",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlenbach im Simmental",
    "BFS-Nr": "763",
    "Kantonskürzel": "BE",
    "E": "2608682.9959999993",
    "N": "1167754.4439999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwil im Simmental",
    "PLZ": "3765",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberwil im Simmental",
    "BFS-Nr": "766",
    "Kantonskürzel": "BE",
    "E": "2599536.085999999",
    "N": "1167347.1009999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reutigen",
    "PLZ": "3647",
    "Zusatzziffer": "0",
    "Gemeindename": "Reutigen",
    "BFS-Nr": "767",
    "Kantonskürzel": "BE",
    "E": "2613834.1259999983",
    "N": "1171345.3000000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gwatt (Thun)",
    "PLZ": "3645",
    "Zusatzziffer": "0",
    "Gemeindename": "Spiez",
    "BFS-Nr": "768",
    "Kantonskürzel": "BE",
    "E": "2614408.015999999",
    "N": "1174152.1400000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Einigen",
    "PLZ": "3646",
    "Zusatzziffer": "0",
    "Gemeindename": "Spiez",
    "BFS-Nr": "768",
    "Kantonskürzel": "BE",
    "E": "2615858.149",
    "N": "1173156.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Spiez",
    "PLZ": "3700",
    "Zusatzziffer": "0",
    "Gemeindename": "Spiez",
    "BFS-Nr": "768",
    "Kantonskürzel": "BE",
    "E": "2618246.5650000013",
    "N": "1171084.1389999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hondrich",
    "PLZ": "3702",
    "Zusatzziffer": "0",
    "Gemeindename": "Spiez",
    "BFS-Nr": "768",
    "Kantonskürzel": "BE",
    "E": "2618501.1099999994",
    "N": "1169045.1149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Faulensee",
    "PLZ": "3705",
    "Zusatzziffer": "0",
    "Gemeindename": "Spiez",
    "BFS-Nr": "768",
    "Kantonskürzel": "BE",
    "E": "2620199.666000001",
    "N": "1169469.0179999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wimmis",
    "PLZ": "3752",
    "Zusatzziffer": "0",
    "Gemeindename": "Wimmis",
    "BFS-Nr": "769",
    "Kantonskürzel": "BE",
    "E": "2615048",
    "N": "1169101.7030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Höfen b. Thun",
    "PLZ": "3631",
    "Zusatzziffer": "0",
    "Gemeindename": "Stocken-Höfen",
    "BFS-Nr": "770",
    "Kantonskürzel": "BE",
    "E": "2609564.2809999995",
    "N": "1174672.3509999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederstocken",
    "PLZ": "3632",
    "Zusatzziffer": "2",
    "Gemeindename": "Stocken-Höfen",
    "BFS-Nr": "770",
    "Kantonskürzel": "BE",
    "E": "2610281.2699999996",
    "N": "1173268.914999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberstocken",
    "PLZ": "3632",
    "Zusatzziffer": "3",
    "Gemeindename": "Stocken-Höfen",
    "BFS-Nr": "770",
    "Kantonskürzel": "BE",
    "E": "2608851.482999999",
    "N": "1173849.0639999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guttannen",
    "PLZ": "3864",
    "Zusatzziffer": "0",
    "Gemeindename": "Guttannen",
    "BFS-Nr": "782",
    "Kantonskürzel": "BE",
    "E": "2665146.800999999",
    "N": "1167438.25",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hasliberg Hohfluh",
    "PLZ": "6083",
    "Zusatzziffer": "0",
    "Gemeindename": "Hasliberg",
    "BFS-Nr": "783",
    "Kantonskürzel": "BE",
    "E": "2656184.1440000013",
    "N": "1178086.2010000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hasliberg Wasserwendi",
    "PLZ": "6084",
    "Zusatzziffer": "0",
    "Gemeindename": "Hasliberg",
    "BFS-Nr": "783",
    "Kantonskürzel": "BE",
    "E": "2657969.276999999",
    "N": "1177553.4609999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hasliberg Goldern",
    "PLZ": "6085",
    "Zusatzziffer": "0",
    "Gemeindename": "Hasliberg",
    "BFS-Nr": "783",
    "Kantonskürzel": "BE",
    "E": "2658079.1510000005",
    "N": "1176773.2760000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hasliberg Reuti",
    "PLZ": "6086",
    "Zusatzziffer": "0",
    "Gemeindename": "Hasliberg",
    "BFS-Nr": "783",
    "Kantonskürzel": "BE",
    "E": "2658895.131000001",
    "N": "1176017.5870000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Innertkirchen",
    "PLZ": "3862",
    "Zusatzziffer": "0",
    "Gemeindename": "Innertkirchen",
    "BFS-Nr": "784",
    "Kantonskürzel": "BE",
    "E": "2660576.539000001",
    "N": "1173101.0579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gadmen",
    "PLZ": "3863",
    "Zusatzziffer": "0",
    "Gemeindename": "Innertkirchen",
    "BFS-Nr": "784",
    "Kantonskürzel": "BE",
    "E": "2669877.6719999984",
    "N": "1176755.4739999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterbach BE",
    "PLZ": "3857",
    "Zusatzziffer": "0",
    "Gemeindename": "Meiringen",
    "BFS-Nr": "785",
    "Kantonskürzel": "BE",
    "E": "2651473.4409999996",
    "N": "1176664.0430000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meiringen",
    "PLZ": "3860",
    "Zusatzziffer": "0",
    "Gemeindename": "Meiringen",
    "BFS-Nr": "785",
    "Kantonskürzel": "BE",
    "E": "2657371.3029999994",
    "N": "1175635.1559999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brünig",
    "PLZ": "3860",
    "Zusatzziffer": "4",
    "Gemeindename": "Meiringen",
    "BFS-Nr": "785",
    "Kantonskürzel": "BE",
    "E": "2653445.8999999985",
    "N": "1178725.421",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rosenlaui",
    "PLZ": "3860",
    "Zusatzziffer": "2",
    "Gemeindename": "Schattenhalb",
    "BFS-Nr": "786",
    "Kantonskürzel": "BE",
    "E": "2654724.7580000013",
    "N": "1170066.4979999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schattenhalb",
    "PLZ": "3860",
    "Zusatzziffer": "3",
    "Gemeindename": "Schattenhalb",
    "BFS-Nr": "786",
    "Kantonskürzel": "BE",
    "E": "2657483.4409999996",
    "N": "1173141.2149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Boltigen",
    "PLZ": "3766",
    "Zusatzziffer": "0",
    "Gemeindename": "Boltigen",
    "BFS-Nr": "791",
    "Kantonskürzel": "BE",
    "E": "2596602.030000001",
    "N": "1164351.0940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lenk im Simmental",
    "PLZ": "3775",
    "Zusatzziffer": "0",
    "Gemeindename": "Lenk",
    "BFS-Nr": "792",
    "Kantonskürzel": "BE",
    "E": "2600173.221000001",
    "N": "1145014.425999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Stephan",
    "PLZ": "3772",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Stephan",
    "BFS-Nr": "793",
    "Kantonskürzel": "BE",
    "E": "2597058.4800000004",
    "N": "1150468.6180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Matten (St. Stephan)",
    "PLZ": "3773",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Stephan",
    "BFS-Nr": "793",
    "Kantonskürzel": "BE",
    "E": "2598689.7760000005",
    "N": "1149159.9189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zweisimmen",
    "PLZ": "3770",
    "Zusatzziffer": "0",
    "Gemeindename": "Zweisimmen",
    "BFS-Nr": "794",
    "Kantonskürzel": "BE",
    "E": "2594876.403999999",
    "N": "1155638.9640000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Blankenburg",
    "PLZ": "3771",
    "Zusatzziffer": "0",
    "Gemeindename": "Zweisimmen",
    "BFS-Nr": "794",
    "Kantonskürzel": "BE",
    "E": "2596229.1050000004",
    "N": "1154470.6609999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oeschseite",
    "PLZ": "3776",
    "Zusatzziffer": "0",
    "Gemeindename": "Zweisimmen",
    "BFS-Nr": "794",
    "Kantonskürzel": "BE",
    "E": "2592627.3680000007",
    "N": "1152521.3040000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Feutersoey",
    "PLZ": "3784",
    "Zusatzziffer": "0",
    "Gemeindename": "Gsteig",
    "BFS-Nr": "841",
    "Kantonskürzel": "BE",
    "E": "2587095.778999999",
    "N": "1140740.7179999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gsteig b. Gstaad",
    "PLZ": "3785",
    "Zusatzziffer": "0",
    "Gemeindename": "Gsteig",
    "BFS-Nr": "841",
    "Kantonskürzel": "BE",
    "E": "2586812.068",
    "N": "1137046.5610000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lauenen b. Gstaad",
    "PLZ": "3782",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauenen",
    "BFS-Nr": "842",
    "Kantonskürzel": "BE",
    "E": "2590962.0359999985",
    "N": "1141499.265999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Abländschen",
    "PLZ": "1657",
    "Zusatzziffer": "0",
    "Gemeindename": "Saanen",
    "BFS-Nr": "843",
    "Kantonskürzel": "BE",
    "E": "2588821.153999999",
    "N": "1158428.386",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saanenmöser",
    "PLZ": "3777",
    "Zusatzziffer": "0",
    "Gemeindename": "Saanen",
    "BFS-Nr": "843",
    "Kantonskürzel": "BE",
    "E": "2590260.9400000013",
    "N": "1151804.7399999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönried",
    "PLZ": "3778",
    "Zusatzziffer": "0",
    "Gemeindename": "Saanen",
    "BFS-Nr": "843",
    "Kantonskürzel": "BE",
    "E": "2588668.199000001",
    "N": "1150327.7600000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gstaad",
    "PLZ": "3780",
    "Zusatzziffer": "0",
    "Gemeindename": "Saanen",
    "BFS-Nr": "843",
    "Kantonskürzel": "BE",
    "E": "2588299.0650000013",
    "N": "1147082.5579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Turbach",
    "PLZ": "3781",
    "Zusatzziffer": "0",
    "Gemeindename": "Saanen",
    "BFS-Nr": "843",
    "Kantonskürzel": "BE",
    "E": "2592091.368999999",
    "N": "1147520.9439999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grund b. Gstaad",
    "PLZ": "3783",
    "Zusatzziffer": "0",
    "Gemeindename": "Saanen",
    "BFS-Nr": "843",
    "Kantonskürzel": "BE",
    "E": "2587374.272",
    "N": "1143813.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saanen",
    "PLZ": "3792",
    "Zusatzziffer": "0",
    "Gemeindename": "Saanen",
    "BFS-Nr": "843",
    "Kantonskürzel": "BE",
    "E": "2586167.375",
    "N": "1148796.0249999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sangernboden",
    "PLZ": "1738",
    "Zusatzziffer": "0",
    "Gemeindename": "Guggisberg",
    "BFS-Nr": "852",
    "Kantonskürzel": "BE",
    "E": "2593480.6750000007",
    "N": "1173545.390999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riffenmatt",
    "PLZ": "3156",
    "Zusatzziffer": "0",
    "Gemeindename": "Guggisberg",
    "BFS-Nr": "852",
    "Kantonskürzel": "BE",
    "E": "2593452.8200000003",
    "N": "1179383.8880000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guggisberg",
    "PLZ": "3158",
    "Zusatzziffer": "0",
    "Gemeindename": "Guggisberg",
    "BFS-Nr": "852",
    "Kantonskürzel": "BE",
    "E": "2591643.083999999",
    "N": "1179499.3090000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riedstätt",
    "PLZ": "3159",
    "Zusatzziffer": "0",
    "Gemeindename": "Guggisberg",
    "BFS-Nr": "852",
    "Kantonskürzel": "BE",
    "E": "2590926.533",
    "N": "1181410.7230000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüschegg Gambach",
    "PLZ": "3153",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüschegg",
    "BFS-Nr": "853",
    "Kantonskürzel": "BE",
    "E": "2595066.642999999",
    "N": "1180562.5810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüschegg Heubach",
    "PLZ": "3154",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüschegg",
    "BFS-Nr": "853",
    "Kantonskürzel": "BE",
    "E": "2597603.6389999986",
    "N": "1181207.4360000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lanzenhäusern",
    "PLZ": "3148",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzenburg",
    "BFS-Nr": "855",
    "Kantonskürzel": "BE",
    "E": "2593026.41",
    "N": "1187864.8489999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzenburg",
    "PLZ": "3150",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzenburg",
    "BFS-Nr": "855",
    "Kantonskürzel": "BE",
    "E": "2592614.4189999998",
    "N": "1185096.6739999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mamishaus",
    "PLZ": "3152",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzenburg",
    "BFS-Nr": "855",
    "Kantonskürzel": "BE",
    "E": "2595417.5190000013",
    "N": "1184292.0249999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Milken",
    "PLZ": "3157",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzenburg",
    "BFS-Nr": "855",
    "Kantonskürzel": "BE",
    "E": "2593896.745000001",
    "N": "1182381.5340000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Albligen",
    "PLZ": "3183",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzenburg",
    "BFS-Nr": "855",
    "Kantonskürzel": "BE",
    "E": "2590885.66",
    "N": "1188964.5830000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Belp",
    "PLZ": "3123",
    "Zusatzziffer": "0",
    "Gemeindename": "Belp",
    "BFS-Nr": "861",
    "Kantonskürzel": "BE",
    "E": "2604715.109000001",
    "N": "1193312.3539999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Belpberg",
    "PLZ": "3124",
    "Zusatzziffer": "0",
    "Gemeindename": "Belp",
    "BFS-Nr": "861",
    "Kantonskürzel": "BE",
    "E": "2606302.688000001",
    "N": "1190690.5089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Burgistein",
    "PLZ": "3664",
    "Zusatzziffer": "0",
    "Gemeindename": "Burgistein",
    "BFS-Nr": "863",
    "Kantonskürzel": "BE",
    "E": "2605080.0749999993",
    "N": "1182792.2589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gerzensee",
    "PLZ": "3115",
    "Zusatzziffer": "0",
    "Gemeindename": "Gerzensee",
    "BFS-Nr": "866",
    "Kantonskürzel": "BE",
    "E": "2608298.329",
    "N": "1187468.4140000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gurzelen",
    "PLZ": "3663",
    "Zusatzziffer": "0",
    "Gemeindename": "Gurzelen",
    "BFS-Nr": "867",
    "Kantonskürzel": "BE",
    "E": "2607229.535",
    "N": "1180954.204",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jaberg",
    "PLZ": "3629",
    "Zusatzziffer": "3",
    "Gemeindename": "Jaberg",
    "BFS-Nr": "868",
    "Kantonskürzel": "BE",
    "E": "2609906.0020000003",
    "N": "1185219.454",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaufdorf",
    "PLZ": "3126",
    "Zusatzziffer": "0",
    "Gemeindename": "Kaufdorf",
    "BFS-Nr": "869",
    "Kantonskürzel": "BE",
    "E": "2604581.4349999987",
    "N": "1187433.9710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kehrsatz",
    "PLZ": "3122",
    "Zusatzziffer": "0",
    "Gemeindename": "Kehrsatz",
    "BFS-Nr": "870",
    "Kantonskürzel": "BE",
    "E": "2602659.721000001",
    "N": "1195726.2540000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kirchdorf BE",
    "PLZ": "3116",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchdorf (BE)",
    "BFS-Nr": "872",
    "Kantonskürzel": "BE",
    "E": "2608544.039000001",
    "N": "1185488.0599999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühledorf BE",
    "PLZ": "3116",
    "Zusatzziffer": "2",
    "Gemeindename": "Kirchdorf (BE)",
    "BFS-Nr": "872",
    "Kantonskürzel": "BE",
    "E": "2606966.9860000014",
    "N": "1186318.7410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Noflen BE",
    "PLZ": "3116",
    "Zusatzziffer": "3",
    "Gemeindename": "Kirchdorf (BE)",
    "BFS-Nr": "872",
    "Kantonskürzel": "BE",
    "E": "2607989.425999999",
    "N": "1183831.993999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gelterfingen",
    "PLZ": "3126",
    "Zusatzziffer": "2",
    "Gemeindename": "Kirchdorf (BE)",
    "BFS-Nr": "872",
    "Kantonskürzel": "BE",
    "E": "2605873.7129999995",
    "N": "1188152.8999999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niedermuhlern",
    "PLZ": "3087",
    "Zusatzziffer": "0",
    "Gemeindename": "Niedermuhlern",
    "BFS-Nr": "877",
    "Kantonskürzel": "BE",
    "E": "2602035.6950000003",
    "N": "1190027.982999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüti b. Riggisberg",
    "PLZ": "3099",
    "Zusatzziffer": "0",
    "Gemeindename": "Riggisberg",
    "BFS-Nr": "879",
    "Kantonskürzel": "BE",
    "E": "2600678.579",
    "N": "1181292.3839999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rümligen",
    "PLZ": "3128",
    "Zusatzziffer": "2",
    "Gemeindename": "Riggisberg",
    "BFS-Nr": "879",
    "Kantonskürzel": "BE",
    "E": "2604353.3649999984",
    "N": "1186654.2630000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riggisberg",
    "PLZ": "3132",
    "Zusatzziffer": "0",
    "Gemeindename": "Riggisberg",
    "BFS-Nr": "879",
    "Kantonskürzel": "BE",
    "E": "2602903.6440000013",
    "N": "1184496.8110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüeggisberg",
    "PLZ": "3088",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüeggisberg",
    "BFS-Nr": "880",
    "Kantonskürzel": "BE",
    "E": "2600005.028000001",
    "N": "1185567.1319999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberbütschel",
    "PLZ": "3088",
    "Zusatzziffer": "2",
    "Gemeindename": "Rüeggisberg",
    "BFS-Nr": "880",
    "Kantonskürzel": "BE",
    "E": "2601870.438000001",
    "N": "1187391.7509999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hinterfultigen",
    "PLZ": "3089",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüeggisberg",
    "BFS-Nr": "880",
    "Kantonskürzel": "BE",
    "E": "2597140.9629999995",
    "N": "1187553.0859999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Helgisried-Rohrbach",
    "PLZ": "3155",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüeggisberg",
    "BFS-Nr": "880",
    "Kantonskürzel": "BE",
    "E": "2598861.3310000002",
    "N": "1184532.3889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seftigen",
    "PLZ": "3662",
    "Zusatzziffer": "0",
    "Gemeindename": "Seftigen",
    "BFS-Nr": "883",
    "Kantonskürzel": "BE",
    "E": "2607791.3599999994",
    "N": "1181745.2300000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Toffen",
    "PLZ": "3125",
    "Zusatzziffer": "0",
    "Gemeindename": "Toffen",
    "BFS-Nr": "884",
    "Kantonskürzel": "BE",
    "E": "2604132.050999999",
    "N": "1189710.9899999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uttigen",
    "PLZ": "3628",
    "Zusatzziffer": "0",
    "Gemeindename": "Uttigen",
    "BFS-Nr": "885",
    "Kantonskürzel": "BE",
    "E": "2610361.8960000016",
    "N": "1182449.1279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wattenwil",
    "PLZ": "3665",
    "Zusatzziffer": "0",
    "Gemeindename": "Wattenwil",
    "BFS-Nr": "886",
    "Kantonskürzel": "BE",
    "E": "2605059.3550000004",
    "N": "1179930.3060000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zimmerwald",
    "PLZ": "3086",
    "Zusatzziffer": "0",
    "Gemeindename": "Wald (BE)",
    "BFS-Nr": "888",
    "Kantonskürzel": "BE",
    "E": "2602404.409000002",
    "N": "1191708.0810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Englisberg",
    "PLZ": "3086",
    "Zusatzziffer": "2",
    "Gemeindename": "Wald (BE)",
    "BFS-Nr": "888",
    "Kantonskürzel": "BE",
    "E": "2602268.034000002",
    "N": "1194087.8330000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühlethurnen",
    "PLZ": "3127",
    "Zusatzziffer": "0",
    "Gemeindename": "Thurnen",
    "BFS-Nr": "889",
    "Kantonskürzel": "BE",
    "E": "2605264.4400000013",
    "N": "1184768.9250000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lohnstorf",
    "PLZ": "3127",
    "Zusatzziffer": "2",
    "Gemeindename": "Thurnen",
    "BFS-Nr": "889",
    "Kantonskürzel": "BE",
    "E": "2605510.1290000007",
    "N": "1183958.9250000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kirchenthurnen",
    "PLZ": "3128",
    "Zusatzziffer": "0",
    "Gemeindename": "Thurnen",
    "BFS-Nr": "889",
    "Kantonskürzel": "BE",
    "E": "2605164.3759999983",
    "N": "1185985.7699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aeschau",
    "PLZ": "3536",
    "Zusatzziffer": "0",
    "Gemeindename": "Eggiwil",
    "BFS-Nr": "901",
    "Kantonskürzel": "BE",
    "E": "2624975.2129999995",
    "N": "1195360.563000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eggiwil",
    "PLZ": "3537",
    "Zusatzziffer": "0",
    "Gemeindename": "Eggiwil",
    "BFS-Nr": "901",
    "Kantonskürzel": "BE",
    "E": "2627226.522",
    "N": "1191539.7820000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langnau im Emmental",
    "PLZ": "3550",
    "Zusatzziffer": "0",
    "Gemeindename": "Langnau im Emmental",
    "BFS-Nr": "902",
    "Kantonskürzel": "BE",
    "E": "2626380.204999998",
    "N": "1199032.9079999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberfrittenbach",
    "PLZ": "3551",
    "Zusatzziffer": "0",
    "Gemeindename": "Langnau im Emmental",
    "BFS-Nr": "902",
    "Kantonskürzel": "BE",
    "E": "2627498.199000001",
    "N": "1203019.7459999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bärau",
    "PLZ": "3552",
    "Zusatzziffer": "0",
    "Gemeindename": "Langnau im Emmental",
    "BFS-Nr": "902",
    "Kantonskürzel": "BE",
    "E": "2628360.829999998",
    "N": "1198055.9290000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gohl",
    "PLZ": "3553",
    "Zusatzziffer": "0",
    "Gemeindename": "Langnau im Emmental",
    "BFS-Nr": "902",
    "Kantonskürzel": "BE",
    "E": "2628141.386",
    "N": "1200608.5529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lauperswil",
    "PLZ": "3438",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauperswil",
    "BFS-Nr": "903",
    "Kantonskürzel": "BE",
    "E": "2622774.9739999995",
    "N": "1201761.489",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Emmenmatt",
    "PLZ": "3543",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauperswil",
    "BFS-Nr": "903",
    "Kantonskürzel": "BE",
    "E": "2623522.9910000004",
    "N": "1199706.9129999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Röthenbach im Emmental",
    "PLZ": "3538",
    "Zusatzziffer": "0",
    "Gemeindename": "Röthenbach im Emmental",
    "BFS-Nr": "904",
    "Kantonskürzel": "BE",
    "E": "2623093.5670000017",
    "N": "1189287.868999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwanden im Emmental",
    "PLZ": "3433",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüderswil",
    "BFS-Nr": "905",
    "Kantonskürzel": "BE",
    "E": "2619463.4349999987",
    "N": "1203868.4809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zollbrück",
    "PLZ": "3436",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüderswil",
    "BFS-Nr": "905",
    "Kantonskürzel": "BE",
    "E": "2623031.710999999",
    "N": "1202793.1909999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüderswil",
    "PLZ": "3437",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüderswil",
    "BFS-Nr": "905",
    "Kantonskürzel": "BE",
    "E": "2621375.572999999",
    "N": "1203560.2699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schangnau",
    "PLZ": "6197",
    "Zusatzziffer": "0",
    "Gemeindename": "Schangnau",
    "BFS-Nr": "906",
    "Kantonskürzel": "BE",
    "E": "2632438.0150000006",
    "N": "1186430.4589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Signau",
    "PLZ": "3534",
    "Zusatzziffer": "0",
    "Gemeindename": "Signau",
    "BFS-Nr": "907",
    "Kantonskürzel": "BE",
    "E": "2621774.9919999987",
    "N": "1196647.4990000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schüpbach",
    "PLZ": "3535",
    "Zusatzziffer": "0",
    "Gemeindename": "Signau",
    "BFS-Nr": "907",
    "Kantonskürzel": "BE",
    "E": "2622554.6209999993",
    "N": "1197544.688000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trub",
    "PLZ": "3556",
    "Zusatzziffer": "0",
    "Gemeindename": "Trub",
    "BFS-Nr": "908",
    "Kantonskürzel": "BE",
    "E": "2633408.162999999",
    "N": "1199210.1449999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fankhaus (Trub)",
    "PLZ": "3557",
    "Zusatzziffer": "0",
    "Gemeindename": "Trub",
    "BFS-Nr": "908",
    "Kantonskürzel": "BE",
    "E": "2635768.392000001",
    "N": "1201905.3550000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trubschachen",
    "PLZ": "3555",
    "Zusatzziffer": "0",
    "Gemeindename": "Trubschachen",
    "BFS-Nr": "909",
    "Kantonskürzel": "BE",
    "E": "2631004.774",
    "N": "1196948.0410000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Amsoldingen",
    "PLZ": "3633",
    "Zusatzziffer": "0",
    "Gemeindename": "Amsoldingen",
    "BFS-Nr": "921",
    "Kantonskürzel": "BE",
    "E": "2610743.9310000017",
    "N": "1175114.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Blumenstein",
    "PLZ": "3638",
    "Zusatzziffer": "0",
    "Gemeindename": "Blumenstein",
    "BFS-Nr": "922",
    "Kantonskürzel": "BE",
    "E": "2606247.4979999997",
    "N": "1176665.1070000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heimenschwand",
    "PLZ": "3615",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchholterberg",
    "BFS-Nr": "923",
    "Kantonskürzel": "BE",
    "E": "2619735.7459999993",
    "N": "1186400.4979999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eriz",
    "PLZ": "3619",
    "Zusatzziffer": "0",
    "Gemeindename": "Eriz",
    "BFS-Nr": "924",
    "Kantonskürzel": "BE",
    "E": "2625874.5689999983",
    "N": "1181955.1119999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fahrni b. Thun",
    "PLZ": "3617",
    "Zusatzziffer": "0",
    "Gemeindename": "Fahrni",
    "BFS-Nr": "925",
    "Kantonskürzel": "BE",
    "E": "2616772.9750000015",
    "N": "1183012.1730000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heiligenschwendi",
    "PLZ": "3625",
    "Zusatzziffer": "0",
    "Gemeindename": "Heiligenschwendi",
    "BFS-Nr": "927",
    "Kantonskürzel": "BE",
    "E": "2618386.7179999985",
    "N": "1177826.0929999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heimberg",
    "PLZ": "3627",
    "Zusatzziffer": "0",
    "Gemeindename": "Heimberg",
    "BFS-Nr": "928",
    "Kantonskürzel": "BE",
    "E": "2612439.5439999998",
    "N": "1182062.5150000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hünibach",
    "PLZ": "3626",
    "Zusatzziffer": "0",
    "Gemeindename": "Hilterfingen",
    "BFS-Nr": "929",
    "Kantonskürzel": "BE",
    "E": "2616247.4809999987",
    "N": "1177138.6149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hilterfingen",
    "PLZ": "3652",
    "Zusatzziffer": "0",
    "Gemeindename": "Hilterfingen",
    "BFS-Nr": "929",
    "Kantonskürzel": "BE",
    "E": "2617092.8249999993",
    "N": "1176634.2829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Homberg b. Thun",
    "PLZ": "3622",
    "Zusatzziffer": "0",
    "Gemeindename": "Homberg",
    "BFS-Nr": "931",
    "Kantonskürzel": "BE",
    "E": "2618724.655000001",
    "N": "1180664.9409999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Horrenbach",
    "PLZ": "3623",
    "Zusatzziffer": "2",
    "Gemeindename": "Horrenbach-Buchen",
    "BFS-Nr": "932",
    "Kantonskürzel": "BE",
    "E": "2623966.405000001",
    "N": "1180792.5810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchen BE",
    "PLZ": "3623",
    "Zusatzziffer": "3",
    "Gemeindename": "Horrenbach-Buchen",
    "BFS-Nr": "932",
    "Kantonskürzel": "BE",
    "E": "2620660.835999999",
    "N": "1181295.3110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhofen am Thunersee",
    "PLZ": "3653",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberhofen am Thunersee",
    "BFS-Nr": "934",
    "Kantonskürzel": "BE",
    "E": "2617570.875",
    "N": "1175581.0760000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pohlern",
    "PLZ": "3638",
    "Zusatzziffer": "1",
    "Gemeindename": "Pohlern",
    "BFS-Nr": "936",
    "Kantonskürzel": "BE",
    "E": "2607386.1130000018",
    "N": "1174887.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gunten",
    "PLZ": "3654",
    "Zusatzziffer": "0",
    "Gemeindename": "Sigriswil",
    "BFS-Nr": "938",
    "Kantonskürzel": "BE",
    "E": "2620102.5989999995",
    "N": "1173547.3159999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sigriswil",
    "PLZ": "3655",
    "Zusatzziffer": "0",
    "Gemeindename": "Sigriswil",
    "BFS-Nr": "938",
    "Kantonskürzel": "BE",
    "E": "2620843.5940000005",
    "N": "1174006.1900000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tschingel ob Gunten",
    "PLZ": "3656",
    "Zusatzziffer": "0",
    "Gemeindename": "Sigriswil",
    "BFS-Nr": "938",
    "Kantonskürzel": "BE",
    "E": "2620640.822999999",
    "N": "1174778.0940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aeschlen ob Gunten",
    "PLZ": "3656",
    "Zusatzziffer": "1",
    "Gemeindename": "Sigriswil",
    "BFS-Nr": "938",
    "Kantonskürzel": "BE",
    "E": "2619898.028000001",
    "N": "1174323.0329999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ringoldswil",
    "PLZ": "3656",
    "Zusatzziffer": "2",
    "Gemeindename": "Sigriswil",
    "BFS-Nr": "938",
    "Kantonskürzel": "BE",
    "E": "2619559.9750000015",
    "N": "1175832.8079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwanden (Sigriswil)",
    "PLZ": "3657",
    "Zusatzziffer": "0",
    "Gemeindename": "Sigriswil",
    "BFS-Nr": "938",
    "Kantonskürzel": "BE",
    "E": "2621222.4849999994",
    "N": "1176087.2589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Merligen",
    "PLZ": "3658",
    "Zusatzziffer": "0",
    "Gemeindename": "Sigriswil",
    "BFS-Nr": "938",
    "Kantonskürzel": "BE",
    "E": "2622865.954",
    "N": "1172011.9750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steffisburg",
    "PLZ": "3612",
    "Zusatzziffer": "0",
    "Gemeindename": "Steffisburg",
    "BFS-Nr": "939",
    "Kantonskürzel": "BE",
    "E": "2615243.5632004337",
    "N": "1181298.3100000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steffisburg",
    "PLZ": "3613",
    "Zusatzziffer": "0",
    "Gemeindename": "Steffisburg",
    "BFS-Nr": "939",
    "Kantonskürzel": "BE",
    "E": "2613435.0590000004",
    "N": "1179999.6518471236",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwendibach",
    "PLZ": "3624",
    "Zusatzziffer": "2",
    "Gemeindename": "Steffisburg",
    "BFS-Nr": "939",
    "Kantonskürzel": "BE",
    "E": "2617054.886",
    "N": "1179885.3940000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Teuffenthal b. Thun",
    "PLZ": "3623",
    "Zusatzziffer": "0",
    "Gemeindename": "Teuffenthal (BE)",
    "BFS-Nr": "940",
    "Kantonskürzel": "BE",
    "E": "2620812.8060000017",
    "N": "1179550.6490000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thierachern",
    "PLZ": "3634",
    "Zusatzziffer": "0",
    "Gemeindename": "Thierachern",
    "BFS-Nr": "941",
    "Kantonskürzel": "BE",
    "E": "2610309.938000001",
    "N": "1178111.0089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thun",
    "PLZ": "3600",
    "Zusatzziffer": "0",
    "Gemeindename": "Thun",
    "BFS-Nr": "942",
    "Kantonskürzel": "BE",
    "E": "2614134.7037723223",
    "N": "1178264.2225000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thun",
    "PLZ": "3603",
    "Zusatzziffer": "0",
    "Gemeindename": "Thun",
    "BFS-Nr": "942",
    "Kantonskürzel": "BE",
    "E": "2612338.342",
    "N": "1178997.0839100578",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thun",
    "PLZ": "3604",
    "Zusatzziffer": "0",
    "Gemeindename": "Thun",
    "BFS-Nr": "942",
    "Kantonskürzel": "BE",
    "E": "2613913.9902505586",
    "N": "1176606.756000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thun",
    "PLZ": "3608",
    "Zusatzziffer": "0",
    "Gemeindename": "Thun",
    "BFS-Nr": "942",
    "Kantonskürzel": "BE",
    "E": "2612244.0395",
    "N": "1176539.5919727175",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Goldiwil (Thun)",
    "PLZ": "3624",
    "Zusatzziffer": "0",
    "Gemeindename": "Thun",
    "BFS-Nr": "942",
    "Kantonskürzel": "BE",
    "E": "2617309.0009999983",
    "N": "1178982.1209999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uebeschi",
    "PLZ": "3635",
    "Zusatzziffer": "0",
    "Gemeindename": "Uebeschi",
    "BFS-Nr": "943",
    "Kantonskürzel": "BE",
    "E": "2608886.916000001",
    "N": "1176336.8610000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uetendorf",
    "PLZ": "3661",
    "Zusatzziffer": "0",
    "Gemeindename": "Uetendorf",
    "BFS-Nr": "944",
    "Kantonskürzel": "BE",
    "E": "2610330.545000002",
    "N": "1180406.6570000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterlangenegg",
    "PLZ": "3614",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterlangenegg",
    "BFS-Nr": "945",
    "Kantonskürzel": "BE",
    "E": "2619402.6050000004",
    "N": "1183029.2780000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzenegg",
    "PLZ": "3616",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterlangenegg",
    "BFS-Nr": "945",
    "Kantonskürzel": "BE",
    "E": "2621082.620000001",
    "N": "1182884.188000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Süderen",
    "PLZ": "3618",
    "Zusatzziffer": "0",
    "Gemeindename": "Wachseldorn",
    "BFS-Nr": "946",
    "Kantonskürzel": "BE",
    "E": "2624249.2760000005",
    "N": "1185258.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zwieselberg",
    "PLZ": "3645",
    "Zusatzziffer": "2",
    "Gemeindename": "Zwieselberg",
    "BFS-Nr": "947",
    "Kantonskürzel": "BE",
    "E": "2613662.4420000017",
    "N": "1173045.7399999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Längenbühl",
    "PLZ": "3636",
    "Zusatzziffer": "0",
    "Gemeindename": "Forst-Längenbühl",
    "BFS-Nr": "948",
    "Kantonskürzel": "BE",
    "E": "2606899.6559999995",
    "N": "1178317.5289999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Forst b. Längenbühl",
    "PLZ": "3636",
    "Zusatzziffer": "2",
    "Gemeindename": "Forst-Längenbühl",
    "BFS-Nr": "948",
    "Kantonskürzel": "BE",
    "E": "2606396.853",
    "N": "1179138.1000000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Affoltern im Emmental",
    "PLZ": "3416",
    "Zusatzziffer": "0",
    "Gemeindename": "Affoltern im Emmental",
    "BFS-Nr": "951",
    "Kantonskürzel": "BE",
    "E": "2622494.807",
    "N": "1212651.9219999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weier im Emmental",
    "PLZ": "3462",
    "Zusatzziffer": "0",
    "Gemeindename": "Affoltern im Emmental",
    "BFS-Nr": "951",
    "Kantonskürzel": "BE",
    "E": "2623910.3029999994",
    "N": "1212441.8339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Häusernmoos im Emmental",
    "PLZ": "3463",
    "Zusatzziffer": "0",
    "Gemeindename": "Affoltern im Emmental",
    "BFS-Nr": "951",
    "Kantonskürzel": "BE",
    "E": "2623575.261",
    "N": "1214315.4140000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dürrenroth",
    "PLZ": "3465",
    "Zusatzziffer": "0",
    "Gemeindename": "Dürrenroth",
    "BFS-Nr": "952",
    "Kantonskürzel": "BE",
    "E": "2626782.9439999983",
    "N": "1215558.4899999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eriswil",
    "PLZ": "4952",
    "Zusatzziffer": "0",
    "Gemeindename": "Eriswil",
    "BFS-Nr": "953",
    "Kantonskürzel": "BE",
    "E": "2631296.829999998",
    "N": "1214204.5650000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Huttwil",
    "PLZ": "4950",
    "Zusatzziffer": "0",
    "Gemeindename": "Huttwil",
    "BFS-Nr": "954",
    "Kantonskürzel": "BE",
    "E": "2631217.473000001",
    "N": "1218162.335000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzenbach (Huttwil)",
    "PLZ": "4953",
    "Zusatzziffer": "0",
    "Gemeindename": "Huttwil",
    "BFS-Nr": "954",
    "Kantonskürzel": "BE",
    "E": "2629891.1449999996",
    "N": "1217338.2800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lützelflüh-Goldbach",
    "PLZ": "3432",
    "Zusatzziffer": "0",
    "Gemeindename": "Lützelflüh",
    "BFS-Nr": "955",
    "Kantonskürzel": "BE",
    "E": "2618532.9120000005",
    "N": "1206035.1620000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ramsei",
    "PLZ": "3435",
    "Zusatzziffer": "0",
    "Gemeindename": "Lützelflüh",
    "BFS-Nr": "955",
    "Kantonskürzel": "BE",
    "E": "2620520.318",
    "N": "1205300.9809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ranflüh",
    "PLZ": "3439",
    "Zusatzziffer": "0",
    "Gemeindename": "Lützelflüh",
    "BFS-Nr": "955",
    "Kantonskürzel": "BE",
    "E": "2622769.723000001",
    "N": "1204209.5199999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grünenmatt",
    "PLZ": "3452",
    "Zusatzziffer": "0",
    "Gemeindename": "Lützelflüh",
    "BFS-Nr": "955",
    "Kantonskürzel": "BE",
    "E": "2621700.210999999",
    "N": "1206460.4880000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüegsauschachen",
    "PLZ": "3415",
    "Zusatzziffer": "3",
    "Gemeindename": "Rüegsau",
    "BFS-Nr": "956",
    "Kantonskürzel": "BE",
    "E": "2616883.1380000003",
    "N": "1207486.5500000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüegsau",
    "PLZ": "3417",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüegsau",
    "BFS-Nr": "956",
    "Kantonskürzel": "BE",
    "E": "2617934.3669999987",
    "N": "1208219.2850000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüegsbach",
    "PLZ": "3418",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüegsau",
    "BFS-Nr": "956",
    "Kantonskürzel": "BE",
    "E": "2619239.2060000002",
    "N": "1209241.0240000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sumiswald",
    "PLZ": "3454",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumiswald",
    "BFS-Nr": "957",
    "Kantonskürzel": "BE",
    "E": "2623288.653999999",
    "N": "1208570.8060000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grünen",
    "PLZ": "3455",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumiswald",
    "BFS-Nr": "957",
    "Kantonskürzel": "BE",
    "E": "2622995.6460000016",
    "N": "1208254.5289999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wasen im Emmental",
    "PLZ": "3457",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumiswald",
    "BFS-Nr": "957",
    "Kantonskürzel": "BE",
    "E": "2627244.4389999993",
    "N": "1210288.9710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heimisbach",
    "PLZ": "3453",
    "Zusatzziffer": "0",
    "Gemeindename": "Trachselwald",
    "BFS-Nr": "958",
    "Kantonskürzel": "BE",
    "E": "2624511.6099999994",
    "N": "1206822.9459999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trachselwald",
    "PLZ": "3456",
    "Zusatzziffer": "0",
    "Gemeindename": "Trachselwald",
    "BFS-Nr": "958",
    "Kantonskürzel": "BE",
    "E": "2622744.5199999996",
    "N": "1207325.4030000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schmidigen-Mühleweg",
    "PLZ": "3464",
    "Zusatzziffer": "0",
    "Gemeindename": "Walterswil (BE)",
    "BFS-Nr": "959",
    "Kantonskürzel": "BE",
    "E": "2623157.199000001",
    "N": "1216015.0980000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walterswil BE",
    "PLZ": "4942",
    "Zusatzziffer": "0",
    "Gemeindename": "Walterswil (BE)",
    "BFS-Nr": "959",
    "Kantonskürzel": "BE",
    "E": "2625638.405000001",
    "N": "1218037.7430000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wyssachen",
    "PLZ": "4954",
    "Zusatzziffer": "0",
    "Gemeindename": "Wyssachen",
    "BFS-Nr": "960",
    "Kantonskürzel": "BE",
    "E": "2629300.397999998",
    "N": "1214311.0639999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Attiswil",
    "PLZ": "4536",
    "Zusatzziffer": "0",
    "Gemeindename": "Attiswil",
    "BFS-Nr": "971",
    "Kantonskürzel": "BE",
    "E": "2613278.3999999985",
    "N": "1232860.4360000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berken",
    "PLZ": "3376",
    "Zusatzziffer": "2",
    "Gemeindename": "Berken",
    "BFS-Nr": "972",
    "Kantonskürzel": "BE",
    "E": "2620245.6950000003",
    "N": "1230572.8480000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bettenhausen",
    "PLZ": "3366",
    "Zusatzziffer": "0",
    "Gemeindename": "Bettenhausen",
    "BFS-Nr": "973",
    "Kantonskürzel": "BE",
    "E": "2621201.090999998",
    "N": "1224744.1640000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bollodingen",
    "PLZ": "3366",
    "Zusatzziffer": "2",
    "Gemeindename": "Bettenhausen",
    "BFS-Nr": "973",
    "Kantonskürzel": "BE",
    "E": "2620243.534000002",
    "N": "1223947.2600000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Farnern",
    "PLZ": "4539",
    "Zusatzziffer": "2",
    "Gemeindename": "Farnern",
    "BFS-Nr": "975",
    "Kantonskürzel": "BE",
    "E": "2613558.7129999995",
    "N": "1235129.379999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Graben",
    "PLZ": "3376",
    "Zusatzziffer": "0",
    "Gemeindename": "Graben",
    "BFS-Nr": "976",
    "Kantonskürzel": "BE",
    "E": "2621147.8189999983",
    "N": "1229408.335000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wanzwil",
    "PLZ": "3372",
    "Zusatzziffer": "0",
    "Gemeindename": "Heimenhausen",
    "BFS-Nr": "977",
    "Kantonskürzel": "BE",
    "E": "2619199.0760000013",
    "N": "1227582.0100000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Röthenbach Herzogenbuchsee",
    "PLZ": "3373",
    "Zusatzziffer": "0",
    "Gemeindename": "Heimenhausen",
    "BFS-Nr": "977",
    "Kantonskürzel": "BE",
    "E": "2618547.8029999994",
    "N": "1228439.0489999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heimenhausen",
    "PLZ": "3373",
    "Zusatzziffer": "2",
    "Gemeindename": "Heimenhausen",
    "BFS-Nr": "977",
    "Kantonskürzel": "BE",
    "E": "2619643.3390000015",
    "N": "1229217.4010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herzogenbuchsee",
    "PLZ": "3360",
    "Zusatzziffer": "0",
    "Gemeindename": "Herzogenbuchsee",
    "BFS-Nr": "979",
    "Kantonskürzel": "BE",
    "E": "2620895.3000000007",
    "N": "1227101.8139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberönz",
    "PLZ": "3363",
    "Zusatzziffer": "0",
    "Gemeindename": "Herzogenbuchsee",
    "BFS-Nr": "979",
    "Kantonskürzel": "BE",
    "E": "2619381.5819999985",
    "N": "1225418.0150000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Inkwil",
    "PLZ": "3375",
    "Zusatzziffer": "0",
    "Gemeindename": "Inkwil",
    "BFS-Nr": "980",
    "Kantonskürzel": "BE",
    "E": "2617809.8159999996",
    "N": "1227856.1209999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederbipp",
    "PLZ": "4704",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederbipp",
    "BFS-Nr": "981",
    "Kantonskürzel": "BE",
    "E": "2619917.993999999",
    "N": "1235309.7199999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wolfisberg",
    "PLZ": "4704",
    "Zusatzziffer": "2",
    "Gemeindename": "Niederbipp",
    "BFS-Nr": "981",
    "Kantonskürzel": "BE",
    "E": "2616745.1290000007",
    "N": "1236018.3630000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederönz",
    "PLZ": "3362",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederönz",
    "BFS-Nr": "982",
    "Kantonskürzel": "BE",
    "E": "2619164.1900000013",
    "N": "1225964.4710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberbipp",
    "PLZ": "4538",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberbipp",
    "BFS-Nr": "983",
    "Kantonskürzel": "BE",
    "E": "2616509.344999999",
    "N": "1234376.7030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ochlenberg",
    "PLZ": "3367",
    "Zusatzziffer": "2",
    "Gemeindename": "Ochlenberg",
    "BFS-Nr": "985",
    "Kantonskürzel": "BE",
    "E": "2622556.6400000006",
    "N": "1222151.2980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oschwand",
    "PLZ": "3476",
    "Zusatzziffer": "0",
    "Gemeindename": "Ochlenberg",
    "BFS-Nr": "985",
    "Kantonskürzel": "BE",
    "E": "2620815.7490000017",
    "N": "1221099.8399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rumisberg",
    "PLZ": "4539",
    "Zusatzziffer": "0",
    "Gemeindename": "Rumisberg",
    "BFS-Nr": "987",
    "Kantonskürzel": "BE",
    "E": "2615224.278000001",
    "N": "1234986.2190000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grasswil",
    "PLZ": "3365",
    "Zusatzziffer": "0",
    "Gemeindename": "Seeberg",
    "BFS-Nr": "988",
    "Kantonskürzel": "BE",
    "E": "2617307.655000001",
    "N": "1221666.813000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seeberg",
    "PLZ": "3365",
    "Zusatzziffer": "2",
    "Gemeindename": "Seeberg",
    "BFS-Nr": "988",
    "Kantonskürzel": "BE",
    "E": "2617132.6970000006",
    "N": "1222687.9109999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riedtwil",
    "PLZ": "3475",
    "Zusatzziffer": "0",
    "Gemeindename": "Seeberg",
    "BFS-Nr": "988",
    "Kantonskürzel": "BE",
    "E": "2619609.2930000015",
    "N": "1221302.2129999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hermiswil",
    "PLZ": "3475",
    "Zusatzziffer": "1",
    "Gemeindename": "Seeberg",
    "BFS-Nr": "988",
    "Kantonskürzel": "BE",
    "E": "2619864.1750000007",
    "N": "1222806.3390000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thörigen",
    "PLZ": "3367",
    "Zusatzziffer": "0",
    "Gemeindename": "Thörigen",
    "BFS-Nr": "989",
    "Kantonskürzel": "BE",
    "E": "2622066.5040000007",
    "N": "1224929.4750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walliswil b. Niederbipp",
    "PLZ": "3380",
    "Zusatzziffer": "2",
    "Gemeindename": "Walliswil bei Niederbipp",
    "BFS-Nr": "990",
    "Kantonskürzel": "BE",
    "E": "2619168.9290000014",
    "N": "1231858.131000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walliswil b. Wangen",
    "PLZ": "3377",
    "Zusatzziffer": "0",
    "Gemeindename": "Walliswil bei Wangen",
    "BFS-Nr": "991",
    "Kantonskürzel": "BE",
    "E": "2618499.221000001",
    "N": "1230909.693",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wangen an der Aare",
    "PLZ": "3380",
    "Zusatzziffer": "0",
    "Gemeindename": "Wangen an der Aare",
    "BFS-Nr": "992",
    "Kantonskürzel": "BE",
    "E": "2616461.890999999",
    "N": "1231688.8469999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wangenried",
    "PLZ": "3374",
    "Zusatzziffer": "0",
    "Gemeindename": "Wangenried",
    "BFS-Nr": "993",
    "Kantonskürzel": "BE",
    "E": "2616439.2580000013",
    "N": "1229599.8449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiedlisbach",
    "PLZ": "4537",
    "Zusatzziffer": "0",
    "Gemeindename": "Wiedlisbach",
    "BFS-Nr": "995",
    "Kantonskürzel": "BE",
    "E": "2615795.4789999984",
    "N": "1233544.199000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Doppleschwand",
    "PLZ": "6112",
    "Zusatzziffer": "0",
    "Gemeindename": "Doppleschwand",
    "BFS-Nr": "1001",
    "Kantonskürzel": "LU",
    "E": "2646896.1149999984",
    "N": "1207725.9290000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Entlebuch",
    "PLZ": "6162",
    "Zusatzziffer": "0",
    "Gemeindename": "Entlebuch",
    "BFS-Nr": "1002",
    "Kantonskürzel": "LU",
    "E": "2647625.232999999",
    "N": "1204763.1209999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rengg",
    "PLZ": "6162",
    "Zusatzziffer": "2",
    "Gemeindename": "Entlebuch",
    "BFS-Nr": "1002",
    "Kantonskürzel": "LU",
    "E": "2649636.4239999987",
    "N": "1206029.4160000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Finsterwald b. Entlebuch",
    "PLZ": "6162",
    "Zusatzziffer": "3",
    "Gemeindename": "Entlebuch",
    "BFS-Nr": "1002",
    "Kantonskürzel": "LU",
    "E": "2650507.7080000006",
    "N": "1202703.4059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ebnet",
    "PLZ": "6163",
    "Zusatzziffer": "0",
    "Gemeindename": "Entlebuch",
    "BFS-Nr": "1002",
    "Kantonskürzel": "LU",
    "E": "2648543.636",
    "N": "1208057.1959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flühli LU",
    "PLZ": "6173",
    "Zusatzziffer": "0",
    "Gemeindename": "Flühli",
    "BFS-Nr": "1004",
    "Kantonskürzel": "LU",
    "E": "2643959.2470000014",
    "N": "1192804.2030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sörenberg",
    "PLZ": "6174",
    "Zusatzziffer": "0",
    "Gemeindename": "Flühli",
    "BFS-Nr": "1004",
    "Kantonskürzel": "LU",
    "E": "2645300.283",
    "N": "1185974.197999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hasle LU",
    "PLZ": "6166",
    "Zusatzziffer": "0",
    "Gemeindename": "Hasle (LU)",
    "BFS-Nr": "1005",
    "Kantonskürzel": "LU",
    "E": "2646620.772999998",
    "N": "1203413.6600000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fontannen b. Wolhusen",
    "PLZ": "6110",
    "Zusatzziffer": "2",
    "Gemeindename": "Romoos",
    "BFS-Nr": "1007",
    "Kantonskürzel": "LU",
    "E": "2641607.7969999984",
    "N": "1208468.6279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Romoos",
    "PLZ": "6113",
    "Zusatzziffer": "0",
    "Gemeindename": "Romoos",
    "BFS-Nr": "1007",
    "Kantonskürzel": "LU",
    "E": "2644512.9629999995",
    "N": "1206826.9180000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bramboden",
    "PLZ": "6167",
    "Zusatzziffer": "0",
    "Gemeindename": "Romoos",
    "BFS-Nr": "1007",
    "Kantonskürzel": "LU",
    "E": "2641610.846000001",
    "N": "1203252.0300000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schüpfheim",
    "PLZ": "6170",
    "Zusatzziffer": "0",
    "Gemeindename": "Schüpfheim",
    "BFS-Nr": "1008",
    "Kantonskürzel": "LU",
    "E": "2644237.9400000013",
    "N": "1200395.0340000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schachen LU",
    "PLZ": "6105",
    "Zusatzziffer": "0",
    "Gemeindename": "Werthenstein",
    "BFS-Nr": "1009",
    "Kantonskürzel": "LU",
    "E": "2653518.6799999997",
    "N": "1209934.7120000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Werthenstein",
    "PLZ": "6106",
    "Zusatzziffer": "0",
    "Gemeindename": "Werthenstein",
    "BFS-Nr": "1009",
    "Kantonskürzel": "LU",
    "E": "2650512.2589999996",
    "N": "1211698.6889999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Escholzmatt",
    "PLZ": "6182",
    "Zusatzziffer": "0",
    "Gemeindename": "Escholzmatt-Marbach",
    "BFS-Nr": "1010",
    "Kantonskürzel": "LU",
    "E": "2637792.3759999983",
    "N": "1195994.0830000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiggen",
    "PLZ": "6192",
    "Zusatzziffer": "0",
    "Gemeindename": "Escholzmatt-Marbach",
    "BFS-Nr": "1010",
    "Kantonskürzel": "LU",
    "E": "2635904.3139999993",
    "N": "1193924.9849999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Marbach LU",
    "PLZ": "6196",
    "Zusatzziffer": "0",
    "Gemeindename": "Escholzmatt-Marbach",
    "BFS-Nr": "1010",
    "Kantonskürzel": "LU",
    "E": "2635241.813000001",
    "N": "1189189.6660000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aesch LU",
    "PLZ": "6287",
    "Zusatzziffer": "0",
    "Gemeindename": "Aesch (LU)",
    "BFS-Nr": "1021",
    "Kantonskürzel": "LU",
    "E": "2660592.614",
    "N": "1234064.0810000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ballwil",
    "PLZ": "6275",
    "Zusatzziffer": "0",
    "Gemeindename": "Ballwil",
    "BFS-Nr": "1023",
    "Kantonskürzel": "LU",
    "E": "2666896.9189999998",
    "N": "1222744.2140000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Emmenbrücke",
    "PLZ": "6020",
    "Zusatzziffer": "0",
    "Gemeindename": "Emmen",
    "BFS-Nr": "1024",
    "Kantonskürzel": "LU",
    "E": "2663418.8790000007",
    "N": "1214369.0639999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Emmen",
    "PLZ": "6032",
    "Zusatzziffer": "0",
    "Gemeindename": "Emmen",
    "BFS-Nr": "1024",
    "Kantonskürzel": "LU",
    "E": "2665603.6849999987",
    "N": "1214583.2939999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ermensee",
    "PLZ": "6294",
    "Zusatzziffer": "0",
    "Gemeindename": "Ermensee",
    "BFS-Nr": "1025",
    "Kantonskürzel": "LU",
    "E": "2660898.3900000006",
    "N": "1231086.4340000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eschenbach LU",
    "PLZ": "6274",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenbach (LU)",
    "BFS-Nr": "1026",
    "Kantonskürzel": "LU",
    "E": "2666888.886",
    "N": "1220574.5229999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gelfingen",
    "PLZ": "6284",
    "Zusatzziffer": "0",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2663093.3949999996",
    "N": "1229631.8139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sulz LU",
    "PLZ": "6284",
    "Zusatzziffer": "2",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2664404.3029999994",
    "N": "1230257.8330000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hitzkirch",
    "PLZ": "6285",
    "Zusatzziffer": "0",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2662539.2580000013",
    "N": "1230806.3330000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Retschwil",
    "PLZ": "6285",
    "Zusatzziffer": "2",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2661751.045000002",
    "N": "1227204.879999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altwis",
    "PLZ": "6286",
    "Zusatzziffer": "0",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2661269.875",
    "N": "1232264.5199999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Müswangen",
    "PLZ": "6289",
    "Zusatzziffer": "0",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2664400.8079999983",
    "N": "1232280.7589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hämikon",
    "PLZ": "6289",
    "Zusatzziffer": "2",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2665213.335999999",
    "N": "1232832.3880000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hämikon",
    "PLZ": "6289",
    "Zusatzziffer": "2",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2663093.2190000005",
    "N": "1232157.5610000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mosen",
    "PLZ": "6295",
    "Zusatzziffer": "0",
    "Gemeindename": "Hitzkirch",
    "BFS-Nr": "1030",
    "Kantonskürzel": "LU",
    "E": "2659747.6559999995",
    "N": "1232734.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hochdorf",
    "PLZ": "6280",
    "Zusatzziffer": "0",
    "Gemeindename": "Hochdorf",
    "BFS-Nr": "1031",
    "Kantonskürzel": "LU",
    "E": "2664779.386",
    "N": "1224344.364",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Urswil",
    "PLZ": "6280",
    "Zusatzziffer": "2",
    "Gemeindename": "Hochdorf",
    "BFS-Nr": "1031",
    "Kantonskürzel": "LU",
    "E": "2664801.5130000003",
    "N": "1222416.7930000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Baldegg",
    "PLZ": "6283",
    "Zusatzziffer": "0",
    "Gemeindename": "Hochdorf",
    "BFS-Nr": "1031",
    "Kantonskürzel": "LU",
    "E": "2663805.3220000006",
    "N": "1226417.493999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hohenrain",
    "PLZ": "6276",
    "Zusatzziffer": "0",
    "Gemeindename": "Hohenrain",
    "BFS-Nr": "1032",
    "Kantonskürzel": "LU",
    "E": "2666683.34",
    "N": "1225765.6900000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleinwangen",
    "PLZ": "6277",
    "Zusatzziffer": "0",
    "Gemeindename": "Hohenrain",
    "BFS-Nr": "1032",
    "Kantonskürzel": "LU",
    "E": "2664867.8869999982",
    "N": "1227462.061999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lieli LU",
    "PLZ": "6277",
    "Zusatzziffer": "2",
    "Gemeindename": "Hohenrain",
    "BFS-Nr": "1032",
    "Kantonskürzel": "LU",
    "E": "2665162.864",
    "N": "1229109.5300000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Inwil",
    "PLZ": "6034",
    "Zusatzziffer": "0",
    "Gemeindename": "Inwil",
    "BFS-Nr": "1033",
    "Kantonskürzel": "LU",
    "E": "2669177.6059999987",
    "N": "1219424.3110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rain",
    "PLZ": "6026",
    "Zusatzziffer": "0",
    "Gemeindename": "Rain",
    "BFS-Nr": "1037",
    "Kantonskürzel": "LU",
    "E": "2662560.3960000016",
    "N": "1220188.7490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Römerswil LU",
    "PLZ": "6027",
    "Zusatzziffer": "0",
    "Gemeindename": "Römerswil",
    "BFS-Nr": "1039",
    "Kantonskürzel": "LU",
    "E": "2661204.2010000013",
    "N": "1224620.1640000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herlisberg",
    "PLZ": "6028",
    "Zusatzziffer": "0",
    "Gemeindename": "Römerswil",
    "BFS-Nr": "1039",
    "Kantonskürzel": "LU",
    "E": "2659973.5780000016",
    "N": "1227801.5639999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rothenburg",
    "PLZ": "6023",
    "Zusatzziffer": "0",
    "Gemeindename": "Rothenburg",
    "BFS-Nr": "1040",
    "Kantonskürzel": "LU",
    "E": "2663351.340999998",
    "N": "1216216.5949999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schongau",
    "PLZ": "6288",
    "Zusatzziffer": "0",
    "Gemeindename": "Schongau",
    "BFS-Nr": "1041",
    "Kantonskürzel": "LU",
    "E": "2662720.8260000013",
    "N": "1235685.890999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Adligenswil",
    "PLZ": "6043",
    "Zusatzziffer": "0",
    "Gemeindename": "Adligenswil",
    "BFS-Nr": "1051",
    "Kantonskürzel": "LU",
    "E": "2670369.465999998",
    "N": "1213699.3110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchrain",
    "PLZ": "6033",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchrain",
    "BFS-Nr": "1052",
    "Kantonskürzel": "LU",
    "E": "2668889.0760000013",
    "N": "1216371.4659999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Perlen",
    "PLZ": "6035",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchrain",
    "BFS-Nr": "1052",
    "Kantonskürzel": "LU",
    "E": "2669913.7080000006",
    "N": "1218147.813000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dierikon",
    "PLZ": "6036",
    "Zusatzziffer": "0",
    "Gemeindename": "Dierikon",
    "BFS-Nr": "1053",
    "Kantonskürzel": "LU",
    "E": "2670849.280000001",
    "N": "1216681.6510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ebikon",
    "PLZ": "6030",
    "Zusatzziffer": "0",
    "Gemeindename": "Ebikon",
    "BFS-Nr": "1054",
    "Kantonskürzel": "LU",
    "E": "2668466.041000001",
    "N": "1214923.947999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gisikon",
    "PLZ": "6038",
    "Zusatzziffer": "0",
    "Gemeindename": "Gisikon",
    "BFS-Nr": "1055",
    "Kantonskürzel": "LU",
    "E": "2672889.0309999995",
    "N": "1220016.868999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Greppen",
    "PLZ": "6404",
    "Zusatzziffer": "0",
    "Gemeindename": "Greppen",
    "BFS-Nr": "1056",
    "Kantonskürzel": "LU",
    "E": "2675324.4930000007",
    "N": "1212084.3990000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Honau",
    "PLZ": "6038",
    "Zusatzziffer": "2",
    "Gemeindename": "Honau",
    "BFS-Nr": "1057",
    "Kantonskürzel": "LU",
    "E": "2673406.5100000016",
    "N": "1220660.7740000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Niklausen LU",
    "PLZ": "6005",
    "Zusatzziffer": "2",
    "Gemeindename": "Horw",
    "BFS-Nr": "1058",
    "Kantonskürzel": "LU",
    "E": "2668558.5989999995",
    "N": "1207980.1460000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kastanienbaum",
    "PLZ": "6047",
    "Zusatzziffer": "0",
    "Gemeindename": "Horw",
    "BFS-Nr": "1058",
    "Kantonskürzel": "LU",
    "E": "2668066.142999999",
    "N": "1207319.9349999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Horw",
    "PLZ": "6048",
    "Zusatzziffer": "0",
    "Gemeindename": "Horw",
    "BFS-Nr": "1058",
    "Kantonskürzel": "LU",
    "E": "2666453.3680000007",
    "N": "1207941.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kriens",
    "PLZ": "6010",
    "Zusatzziffer": "0",
    "Gemeindename": "Kriens",
    "BFS-Nr": "1059",
    "Kantonskürzel": "LU",
    "E": "2663882.3139999993",
    "N": "1209448.9360000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obernau",
    "PLZ": "6012",
    "Zusatzziffer": "0",
    "Gemeindename": "Kriens",
    "BFS-Nr": "1059",
    "Kantonskürzel": "LU",
    "E": "2661930.927000001",
    "N": "1209333.6559999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luzern",
    "PLZ": "6003",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzern",
    "BFS-Nr": "1061",
    "Kantonskürzel": "LU",
    "E": "2665223.5156800505",
    "N": "1211360.329500001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luzern",
    "PLZ": "6004",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzern",
    "BFS-Nr": "1061",
    "Kantonskürzel": "LU",
    "E": "2665295.0727855144",
    "N": "1212484.8260000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luzern",
    "PLZ": "6005",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzern",
    "BFS-Nr": "1061",
    "Kantonskürzel": "LU",
    "E": "2666622.699732594",
    "N": "1210180.0105000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luzern",
    "PLZ": "6006",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzern",
    "BFS-Nr": "1061",
    "Kantonskürzel": "LU",
    "E": "2668011.2915000003",
    "N": "1211635.5502110845",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luzern",
    "PLZ": "6014",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzern",
    "BFS-Nr": "1061",
    "Kantonskürzel": "LU",
    "E": "2661014.610131393",
    "N": "1212152.0190000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luzern",
    "PLZ": "6015",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzern",
    "BFS-Nr": "1061",
    "Kantonskürzel": "LU",
    "E": "2663751.6885",
    "N": "1212860.8014802882",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Malters",
    "PLZ": "6102",
    "Zusatzziffer": "0",
    "Gemeindename": "Malters",
    "BFS-Nr": "1062",
    "Kantonskürzel": "LU",
    "E": "2656608.995000001",
    "N": "1209702.3720000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meggen",
    "PLZ": "6045",
    "Zusatzziffer": "0",
    "Gemeindename": "Meggen",
    "BFS-Nr": "1063",
    "Kantonskürzel": "LU",
    "E": "2670930.4499999993",
    "N": "1211027.585000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meierskappel",
    "PLZ": "6344",
    "Zusatzziffer": "0",
    "Gemeindename": "Meierskappel",
    "BFS-Nr": "1064",
    "Kantonskürzel": "LU",
    "E": "2676184.4899999984",
    "N": "1219853.550999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Root",
    "PLZ": "6037",
    "Zusatzziffer": "0",
    "Gemeindename": "Root",
    "BFS-Nr": "1065",
    "Kantonskürzel": "LU",
    "E": "2672323.4809999987",
    "N": "1218575.8500000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Root D4",
    "PLZ": "6039",
    "Zusatzziffer": "0",
    "Gemeindename": "Root",
    "BFS-Nr": "1065",
    "Kantonskürzel": "LU",
    "E": "2670960.4910000004",
    "N": "1217469.6900000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eigenthal",
    "PLZ": "6013",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzenberg",
    "BFS-Nr": "1066",
    "Kantonskürzel": "LU",
    "E": "2659361.4990000017",
    "N": "1206517.1629999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzenberg LU",
    "PLZ": "6103",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwarzenberg",
    "BFS-Nr": "1066",
    "Kantonskürzel": "LU",
    "E": "2655926.993999999",
    "N": "1207488.0089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Udligenswil",
    "PLZ": "6044",
    "Zusatzziffer": "0",
    "Gemeindename": "Udligenswil",
    "BFS-Nr": "1067",
    "Kantonskürzel": "LU",
    "E": "2672938.3260000013",
    "N": "1216014.0500000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vitznau",
    "PLZ": "6354",
    "Zusatzziffer": "0",
    "Gemeindename": "Vitznau",
    "BFS-Nr": "1068",
    "Kantonskürzel": "LU",
    "E": "2679473.2030000016",
    "N": "1207120.421",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weggis",
    "PLZ": "6353",
    "Zusatzziffer": "0",
    "Gemeindename": "Weggis",
    "BFS-Nr": "1069",
    "Kantonskürzel": "LU",
    "E": "2675680.693",
    "N": "1209552.745000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rigi Kaltbad",
    "PLZ": "6356",
    "Zusatzziffer": "0",
    "Gemeindename": "Weggis",
    "BFS-Nr": "1069",
    "Kantonskürzel": "LU",
    "E": "2678450.754999999",
    "N": "1210840",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neudorf",
    "PLZ": "6025",
    "Zusatzziffer": "0",
    "Gemeindename": "Beromünster",
    "BFS-Nr": "1081",
    "Kantonskürzel": "LU",
    "E": "2658464.41",
    "N": "1225359.3819999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beromünster",
    "PLZ": "6215",
    "Zusatzziffer": "0",
    "Gemeindename": "Beromünster",
    "BFS-Nr": "1081",
    "Kantonskürzel": "LU",
    "E": "2657163.743999999",
    "N": "1228728.0320000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzenbach LU",
    "PLZ": "6215",
    "Zusatzziffer": "2",
    "Gemeindename": "Beromünster",
    "BFS-Nr": "1081",
    "Kantonskürzel": "LU",
    "E": "2658604.2289999984",
    "N": "1231727.8539999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gunzwil",
    "PLZ": "6222",
    "Zusatzziffer": "0",
    "Gemeindename": "Beromünster",
    "BFS-Nr": "1081",
    "Kantonskürzel": "LU",
    "E": "2656137.3440000005",
    "N": "1229186.5469999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büron",
    "PLZ": "6233",
    "Zusatzziffer": "0",
    "Gemeindename": "Büron",
    "BFS-Nr": "1082",
    "Kantonskürzel": "LU",
    "E": "2649414.614",
    "N": "1229516.5940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buttisholz",
    "PLZ": "6018",
    "Zusatzziffer": "0",
    "Gemeindename": "Buttisholz",
    "BFS-Nr": "1083",
    "Kantonskürzel": "LU",
    "E": "2649781.6149999984",
    "N": "1218415.3280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eich",
    "PLZ": "6205",
    "Zusatzziffer": "0",
    "Gemeindename": "Eich",
    "BFS-Nr": "1084",
    "Kantonskürzel": "LU",
    "E": "2655432.6790000014",
    "N": "1222705.1909999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Geuensee",
    "PLZ": "6232",
    "Zusatzziffer": "0",
    "Gemeindename": "Geuensee",
    "BFS-Nr": "1085",
    "Kantonskürzel": "LU",
    "E": "2651099.239",
    "N": "1227932.2289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grosswangen",
    "PLZ": "6022",
    "Zusatzziffer": "0",
    "Gemeindename": "Grosswangen",
    "BFS-Nr": "1086",
    "Kantonskürzel": "LU",
    "E": "2646386.4340000004",
    "N": "1220437.5300000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hildisrieden",
    "PLZ": "6024",
    "Zusatzziffer": "0",
    "Gemeindename": "Hildisrieden",
    "BFS-Nr": "1088",
    "Kantonskürzel": "LU",
    "E": "2660275.697999999",
    "N": "1222430.2089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Erhard",
    "PLZ": "6212",
    "Zusatzziffer": "0",
    "Gemeindename": "Knutwil",
    "BFS-Nr": "1089",
    "Kantonskürzel": "LU",
    "E": "2648175.4750000015",
    "N": "1225942.2749999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Knutwil",
    "PLZ": "6213",
    "Zusatzziffer": "0",
    "Gemeindename": "Knutwil",
    "BFS-Nr": "1089",
    "Kantonskürzel": "LU",
    "E": "2648050.541000001",
    "N": "1228046.2729999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaltbach",
    "PLZ": "6212",
    "Zusatzziffer": "2",
    "Gemeindename": "Mauensee",
    "BFS-Nr": "1091",
    "Kantonskürzel": "LU",
    "E": "2647007.800999999",
    "N": "1226056.245000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mauensee",
    "PLZ": "6216",
    "Zusatzziffer": "0",
    "Gemeindename": "Mauensee",
    "BFS-Nr": "1091",
    "Kantonskürzel": "LU",
    "E": "2647644.2459999993",
    "N": "1224276.8029999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hellbühl",
    "PLZ": "6016",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuenkirch",
    "BFS-Nr": "1093",
    "Kantonskürzel": "LU",
    "E": "2657591.6849999987",
    "N": "1213513.5150000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sempach Station",
    "PLZ": "6203",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuenkirch",
    "BFS-Nr": "1093",
    "Kantonskürzel": "LU",
    "E": "2657252.9979999997",
    "N": "1218456.489",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuenkirch",
    "PLZ": "6206",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuenkirch",
    "BFS-Nr": "1093",
    "Kantonskürzel": "LU",
    "E": "2658070.34",
    "N": "1216832.4279999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nottwil",
    "PLZ": "6207",
    "Zusatzziffer": "0",
    "Gemeindename": "Nottwil",
    "BFS-Nr": "1094",
    "Kantonskürzel": "LU",
    "E": "2652742.2289999984",
    "N": "1220609.8660000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberkirch LU",
    "PLZ": "6208",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberkirch",
    "BFS-Nr": "1095",
    "Kantonskürzel": "LU",
    "E": "2651314.094999999",
    "N": "1223282.2430000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfeffikon LU",
    "PLZ": "5735",
    "Zusatzziffer": "0",
    "Gemeindename": "Rickenbach (LU)",
    "BFS-Nr": "1097",
    "Kantonskürzel": "LU",
    "E": "2655942.5819999985",
    "N": "1233470.1020000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach LU",
    "PLZ": "6221",
    "Zusatzziffer": "0",
    "Gemeindename": "Rickenbach (LU)",
    "BFS-Nr": "1097",
    "Kantonskürzel": "LU",
    "E": "2654105.837000001",
    "N": "1230125.2019999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ruswil",
    "PLZ": "6017",
    "Zusatzziffer": "0",
    "Gemeindename": "Ruswil",
    "BFS-Nr": "1098",
    "Kantonskürzel": "LU",
    "E": "2652245.447999999",
    "N": "1215129.329",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sigigen",
    "PLZ": "6019",
    "Zusatzziffer": "0",
    "Gemeindename": "Ruswil",
    "BFS-Nr": "1098",
    "Kantonskürzel": "LU",
    "E": "2652910.313000001",
    "N": "1212714.7419999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schenkon",
    "PLZ": "6214",
    "Zusatzziffer": "0",
    "Gemeindename": "Schenkon",
    "BFS-Nr": "1099",
    "Kantonskürzel": "LU",
    "E": "2652831.4109999985",
    "N": "1224933.6290000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schlierbach",
    "PLZ": "6231",
    "Zusatzziffer": "0",
    "Gemeindename": "Schlierbach",
    "BFS-Nr": "1100",
    "Kantonskürzel": "LU",
    "E": "2650844.5199999996",
    "N": "1230781.2699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sempach",
    "PLZ": "6204",
    "Zusatzziffer": "0",
    "Gemeindename": "Sempach",
    "BFS-Nr": "1102",
    "Kantonskürzel": "LU",
    "E": "2657689.9679999985",
    "N": "1220504.9580000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sursee",
    "PLZ": "6210",
    "Zusatzziffer": "0",
    "Gemeindename": "Sursee",
    "BFS-Nr": "1103",
    "Kantonskürzel": "LU",
    "E": "2650852.3889999986",
    "N": "1224923.9250000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Triengen",
    "PLZ": "6234",
    "Zusatzziffer": "0",
    "Gemeindename": "Triengen",
    "BFS-Nr": "1104",
    "Kantonskürzel": "LU",
    "E": "2648365.526999999",
    "N": "1231657.9070000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kulmerau",
    "PLZ": "6234",
    "Zusatzziffer": "1",
    "Gemeindename": "Triengen",
    "BFS-Nr": "1104",
    "Kantonskürzel": "LU",
    "E": "2649112.0529999994",
    "N": "1233987.2980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winikon",
    "PLZ": "6235",
    "Zusatzziffer": "0",
    "Gemeindename": "Triengen",
    "BFS-Nr": "1104",
    "Kantonskürzel": "LU",
    "E": "2646239.7239999995",
    "N": "1231996.9340000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilihof",
    "PLZ": "6236",
    "Zusatzziffer": "0",
    "Gemeindename": "Triengen",
    "BFS-Nr": "1104",
    "Kantonskürzel": "LU",
    "E": "2647361.6680000015",
    "N": "1230453.3440000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wolhusen",
    "PLZ": "6110",
    "Zusatzziffer": "0",
    "Gemeindename": "Wolhusen",
    "BFS-Nr": "1107",
    "Kantonskürzel": "LU",
    "E": "2648329.8539999984",
    "N": "1212275.1479999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinhuserberg",
    "PLZ": "6114",
    "Zusatzziffer": "0",
    "Gemeindename": "Wolhusen",
    "BFS-Nr": "1107",
    "Kantonskürzel": "LU",
    "E": "2646188.204",
    "N": "1210392.2490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alberswil",
    "PLZ": "6248",
    "Zusatzziffer": "0",
    "Gemeindename": "Alberswil",
    "BFS-Nr": "1121",
    "Kantonskürzel": "LU",
    "E": "2642366.0430000015",
    "N": "1222223.1779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altbüron",
    "PLZ": "6147",
    "Zusatzziffer": "0",
    "Gemeindename": "Altbüron",
    "BFS-Nr": "1122",
    "Kantonskürzel": "LU",
    "E": "2633639.478",
    "N": "1225534.8299999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ebersecken",
    "PLZ": "6245",
    "Zusatzziffer": "0",
    "Gemeindename": "Altishofen",
    "BFS-Nr": "1123",
    "Kantonskürzel": "LU",
    "E": "2637462.6130000018",
    "N": "1225858.7199999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altishofen",
    "PLZ": "6246",
    "Zusatzziffer": "0",
    "Gemeindename": "Altishofen",
    "BFS-Nr": "1123",
    "Kantonskürzel": "LU",
    "E": "2639531.5529999994",
    "N": "1227980.5830000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchs LU",
    "PLZ": "6211",
    "Zusatzziffer": "1",
    "Gemeindename": "Dagmersellen",
    "BFS-Nr": "1125",
    "Kantonskürzel": "LU",
    "E": "2645348.822999999",
    "N": "1227945.822999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dagmersellen",
    "PLZ": "6252",
    "Zusatzziffer": "0",
    "Gemeindename": "Dagmersellen",
    "BFS-Nr": "1125",
    "Kantonskürzel": "LU",
    "E": "2641792.914000001",
    "N": "1229396.370000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uffikon",
    "PLZ": "6253",
    "Zusatzziffer": "0",
    "Gemeindename": "Dagmersellen",
    "BFS-Nr": "1125",
    "Kantonskürzel": "LU",
    "E": "2643911.278999999",
    "N": "1229097.3949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Egolzwil",
    "PLZ": "6243",
    "Zusatzziffer": "0",
    "Gemeindename": "Egolzwil",
    "BFS-Nr": "1127",
    "Kantonskürzel": "LU",
    "E": "2643030.1319999993",
    "N": "1226212.2650000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kottwil",
    "PLZ": "6217",
    "Zusatzziffer": "0",
    "Gemeindename": "Ettiswil",
    "BFS-Nr": "1128",
    "Kantonskürzel": "LU",
    "E": "2645985.8709999993",
    "N": "1223711.5650000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ettiswil",
    "PLZ": "6218",
    "Zusatzziffer": "0",
    "Gemeindename": "Ettiswil",
    "BFS-Nr": "1128",
    "Kantonskürzel": "LU",
    "E": "2643677.063000001",
    "N": "1222111.3339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fischbach LU",
    "PLZ": "6145",
    "Zusatzziffer": "0",
    "Gemeindename": "Fischbach",
    "BFS-Nr": "1129",
    "Kantonskürzel": "LU",
    "E": "2635583.6380000003",
    "N": "1222897.7930000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grossdietwil",
    "PLZ": "6146",
    "Zusatzziffer": "0",
    "Gemeindename": "Grossdietwil",
    "BFS-Nr": "1131",
    "Kantonskürzel": "LU",
    "E": "2634112.715999998",
    "N": "1224606.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hergiswil b. Willisau",
    "PLZ": "6133",
    "Zusatzziffer": "0",
    "Gemeindename": "Hergiswil bei Willisau",
    "BFS-Nr": "1132",
    "Kantonskürzel": "LU",
    "E": "2639527.754999999",
    "N": "1215118.3379999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hofstatt",
    "PLZ": "6154",
    "Zusatzziffer": "0",
    "Gemeindename": "Luthern",
    "BFS-Nr": "1135",
    "Kantonskürzel": "LU",
    "E": "2636178.0810000002",
    "N": "1214969.0390000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luthern",
    "PLZ": "6156",
    "Zusatzziffer": "0",
    "Gemeindename": "Luthern",
    "BFS-Nr": "1135",
    "Kantonskürzel": "LU",
    "E": "2636297.596999999",
    "N": "1212119.767000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luthern Bad",
    "PLZ": "6156",
    "Zusatzziffer": "2",
    "Gemeindename": "Luthern",
    "BFS-Nr": "1135",
    "Kantonskürzel": "LU",
    "E": "2637134.761",
    "N": "1208682.7600000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Menznau",
    "PLZ": "6122",
    "Zusatzziffer": "0",
    "Gemeindename": "Menznau",
    "BFS-Nr": "1136",
    "Kantonskürzel": "LU",
    "E": "2645615.0579999983",
    "N": "1214954.5179999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Geiss",
    "PLZ": "6123",
    "Zusatzziffer": "0",
    "Gemeindename": "Menznau",
    "BFS-Nr": "1136",
    "Kantonskürzel": "LU",
    "E": "2646876.210999999",
    "N": "1215653.3480000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Menzberg",
    "PLZ": "6125",
    "Zusatzziffer": "0",
    "Gemeindename": "Menznau",
    "BFS-Nr": "1136",
    "Kantonskürzel": "LU",
    "E": "2642532.8940000013",
    "N": "1210168.699000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nebikon",
    "PLZ": "6244",
    "Zusatzziffer": "0",
    "Gemeindename": "Nebikon",
    "BFS-Nr": "1137",
    "Kantonskürzel": "LU",
    "E": "2640623.0139999986",
    "N": "1226836.245000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Urban",
    "PLZ": "4915",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfaffnau",
    "BFS-Nr": "1139",
    "Kantonskürzel": "LU",
    "E": "2630373.5830000006",
    "N": "1231353.9589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfaffnau",
    "PLZ": "6264",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfaffnau",
    "BFS-Nr": "1139",
    "Kantonskürzel": "LU",
    "E": "2634812.0560000017",
    "N": "1230947.686999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reiden",
    "PLZ": "6260",
    "Zusatzziffer": "0",
    "Gemeindename": "Reiden",
    "BFS-Nr": "1140",
    "Kantonskürzel": "LU",
    "E": "2640394.949000001",
    "N": "1232650.9250000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reidermoos",
    "PLZ": "6260",
    "Zusatzziffer": "2",
    "Gemeindename": "Reiden",
    "BFS-Nr": "1140",
    "Kantonskürzel": "LU",
    "E": "2641742.094999999",
    "N": "1233880.6689999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mehlsecken",
    "PLZ": "6260",
    "Zusatzziffer": "4",
    "Gemeindename": "Reiden",
    "BFS-Nr": "1140",
    "Kantonskürzel": "LU",
    "E": "2639405.664000001",
    "N": "1232859.3449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langnau b. Reiden",
    "PLZ": "6262",
    "Zusatzziffer": "0",
    "Gemeindename": "Reiden",
    "BFS-Nr": "1140",
    "Kantonskürzel": "LU",
    "E": "2639725.546",
    "N": "1231264.4710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Richenthal",
    "PLZ": "6263",
    "Zusatzziffer": "0",
    "Gemeindename": "Reiden",
    "BFS-Nr": "1140",
    "Kantonskürzel": "LU",
    "E": "2638462.3900000006",
    "N": "1229951.8960000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Roggliswil",
    "PLZ": "6265",
    "Zusatzziffer": "0",
    "Gemeindename": "Roggliswil",
    "BFS-Nr": "1142",
    "Kantonskürzel": "LU",
    "E": "2633911.8379999995",
    "N": "1229113.2879999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ohmstal",
    "PLZ": "6143",
    "Zusatzziffer": "0",
    "Gemeindename": "Schötz",
    "BFS-Nr": "1143",
    "Kantonskürzel": "LU",
    "E": "2638864.4010000005",
    "N": "1223692.5650000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schötz",
    "PLZ": "6247",
    "Zusatzziffer": "0",
    "Gemeindename": "Schötz",
    "BFS-Nr": "1143",
    "Kantonskürzel": "LU",
    "E": "2641725.3150000013",
    "N": "1224419.011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ufhusen",
    "PLZ": "6153",
    "Zusatzziffer": "0",
    "Gemeindename": "Ufhusen",
    "BFS-Nr": "1145",
    "Kantonskürzel": "LU",
    "E": "2635011.75",
    "N": "1218610.9389999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wauwil",
    "PLZ": "6242",
    "Zusatzziffer": "0",
    "Gemeindename": "Wauwil",
    "BFS-Nr": "1146",
    "Kantonskürzel": "LU",
    "E": "2644258.954999998",
    "N": "1226313.4589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wikon",
    "PLZ": "4806",
    "Zusatzziffer": "0",
    "Gemeindename": "Wikon",
    "BFS-Nr": "1147",
    "Kantonskürzel": "LU",
    "E": "2640003.4690000005",
    "N": "1234836.0089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hintermoos",
    "PLZ": "6260",
    "Zusatzziffer": "3",
    "Gemeindename": "Wikon",
    "BFS-Nr": "1147",
    "Kantonskürzel": "LU",
    "E": "2642363.4800000004",
    "N": "1235620.625",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zell LU",
    "PLZ": "6144",
    "Zusatzziffer": "0",
    "Gemeindename": "Zell (LU)",
    "BFS-Nr": "1150",
    "Kantonskürzel": "LU",
    "E": "2636716.1970000006",
    "N": "1221116.4970000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hüswil",
    "PLZ": "6152",
    "Zusatzziffer": "0",
    "Gemeindename": "Zell (LU)",
    "BFS-Nr": "1150",
    "Kantonskürzel": "LU",
    "E": "2635199.778999999",
    "N": "1219758.9649999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Daiwil",
    "PLZ": "6126",
    "Zusatzziffer": "0",
    "Gemeindename": "Willisau",
    "BFS-Nr": "1151",
    "Kantonskürzel": "LU",
    "E": "2643590.170000002",
    "N": "1216391.9169999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Willisau",
    "PLZ": "6130",
    "Zusatzziffer": "0",
    "Gemeindename": "Willisau",
    "BFS-Nr": "1151",
    "Kantonskürzel": "LU",
    "E": "2641817.1959999986",
    "N": "1218918.0080000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rohrmatt",
    "PLZ": "6132",
    "Zusatzziffer": "0",
    "Gemeindename": "Willisau",
    "BFS-Nr": "1151",
    "Kantonskürzel": "LU",
    "E": "2641494.5670000017",
    "N": "1214730.607999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gettnau",
    "PLZ": "6142",
    "Zusatzziffer": "0",
    "Gemeindename": "Willisau",
    "BFS-Nr": "1151",
    "Kantonskürzel": "LU",
    "E": "2640307.166000001",
    "N": "1221379.449000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altdorf UR",
    "PLZ": "6460",
    "Zusatzziffer": "0",
    "Gemeindename": "Altdorf (UR)",
    "BFS-Nr": "1201",
    "Kantonskürzel": "UR",
    "E": "2691739.1920000017",
    "N": "1193170.4079999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Andermatt",
    "PLZ": "6490",
    "Zusatzziffer": "0",
    "Gemeindename": "Andermatt",
    "BFS-Nr": "1202",
    "Kantonskürzel": "UR",
    "E": "2688730.9389999993",
    "N": "1165164.699000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Attinghausen",
    "PLZ": "6468",
    "Zusatzziffer": "0",
    "Gemeindename": "Attinghausen",
    "BFS-Nr": "1203",
    "Kantonskürzel": "UR",
    "E": "2690376.899",
    "N": "1191109.0329999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bürglen UR",
    "PLZ": "6463",
    "Zusatzziffer": "0",
    "Gemeindename": "Bürglen (UR)",
    "BFS-Nr": "1205",
    "Kantonskürzel": "UR",
    "E": "2693292.228",
    "N": "1192292.0410000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erstfeld",
    "PLZ": "6472",
    "Zusatzziffer": "0",
    "Gemeindename": "Erstfeld",
    "BFS-Nr": "1206",
    "Kantonskürzel": "UR",
    "E": "2692465.697999999",
    "N": "1186175.7010000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flüelen",
    "PLZ": "6454",
    "Zusatzziffer": "0",
    "Gemeindename": "Flüelen",
    "BFS-Nr": "1207",
    "Kantonskürzel": "UR",
    "E": "2690664.4340000004",
    "N": "1195605.7740000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Göschenen",
    "PLZ": "6487",
    "Zusatzziffer": "0",
    "Gemeindename": "Göschenen",
    "BFS-Nr": "1208",
    "Kantonskürzel": "UR",
    "E": "2687638.34",
    "N": "1169347.3399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Intschi",
    "PLZ": "6476",
    "Zusatzziffer": "0",
    "Gemeindename": "Gurtnellen",
    "BFS-Nr": "1209",
    "Kantonskürzel": "UR",
    "E": "2692519.368999999",
    "N": "1179522.2919999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gurtnellen",
    "PLZ": "6482",
    "Zusatzziffer": "0",
    "Gemeindename": "Gurtnellen",
    "BFS-Nr": "1209",
    "Kantonskürzel": "UR",
    "E": "2690933.0139999986",
    "N": "1177000.9930000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hospental",
    "PLZ": "6493",
    "Zusatzziffer": "0",
    "Gemeindename": "Hospental",
    "BFS-Nr": "1210",
    "Kantonskürzel": "UR",
    "E": "2686865.028999999",
    "N": "1163725.2190000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Isenthal",
    "PLZ": "6461",
    "Zusatzziffer": "0",
    "Gemeindename": "Isenthal",
    "BFS-Nr": "1211",
    "Kantonskürzel": "UR",
    "E": "2685500.6050000004",
    "N": "1196245.3889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Realp",
    "PLZ": "6491",
    "Zusatzziffer": "0",
    "Gemeindename": "Realp",
    "BFS-Nr": "1212",
    "Kantonskürzel": "UR",
    "E": "2681469.0720000006",
    "N": "1161383.0980000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schattdorf",
    "PLZ": "6467",
    "Zusatzziffer": "0",
    "Gemeindename": "Schattdorf",
    "BFS-Nr": "1213",
    "Kantonskürzel": "UR",
    "E": "2693040.41",
    "N": "1191049.2089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Haldi b. Schattdorf",
    "PLZ": "6469",
    "Zusatzziffer": "0",
    "Gemeindename": "Schattdorf",
    "BFS-Nr": "1213",
    "Kantonskürzel": "UR",
    "E": "2694274.5579999983",
    "N": "1190820.1999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seedorf UR",
    "PLZ": "6462",
    "Zusatzziffer": "0",
    "Gemeindename": "Seedorf (UR)",
    "BFS-Nr": "1214",
    "Kantonskürzel": "UR",
    "E": "2689325.6889999993",
    "N": "1193212.6259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bauen",
    "PLZ": "6466",
    "Zusatzziffer": "0",
    "Gemeindename": "Seedorf (UR)",
    "BFS-Nr": "1214",
    "Kantonskürzel": "UR",
    "E": "2686731.9860000014",
    "N": "1198856.9050000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seelisberg",
    "PLZ": "6377",
    "Zusatzziffer": "0",
    "Gemeindename": "Seelisberg",
    "BFS-Nr": "1215",
    "Kantonskürzel": "UR",
    "E": "2687278.3330000006",
    "N": "1203147.1640000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rütli",
    "PLZ": "6441",
    "Zusatzziffer": "2",
    "Gemeindename": "Seelisberg",
    "BFS-Nr": "1215",
    "Kantonskürzel": "UR",
    "E": "2687834.460000001",
    "N": "1202600.4730000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Silenen",
    "PLZ": "6473",
    "Zusatzziffer": "0",
    "Gemeindename": "Silenen",
    "BFS-Nr": "1216",
    "Kantonskürzel": "UR",
    "E": "2694223.390999999",
    "N": "1183040.1220000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Amsteg",
    "PLZ": "6474",
    "Zusatzziffer": "0",
    "Gemeindename": "Silenen",
    "BFS-Nr": "1216",
    "Kantonskürzel": "UR",
    "E": "2694104.7699999996",
    "N": "1180681.6130000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bristen",
    "PLZ": "6475",
    "Zusatzziffer": "0",
    "Gemeindename": "Silenen",
    "BFS-Nr": "1216",
    "Kantonskürzel": "UR",
    "E": "2695659.287999999",
    "N": "1180563.7529999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sisikon",
    "PLZ": "6452",
    "Zusatzziffer": "0",
    "Gemeindename": "Sisikon",
    "BFS-Nr": "1217",
    "Kantonskürzel": "UR",
    "E": "2690207.2930000015",
    "N": "1200346.2850000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Spiringen",
    "PLZ": "6464",
    "Zusatzziffer": "0",
    "Gemeindename": "Spiringen",
    "BFS-Nr": "1218",
    "Kantonskürzel": "UR",
    "E": "2698327.2939999998",
    "N": "1191906.2140000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Urnerboden",
    "PLZ": "8751",
    "Zusatzziffer": "0",
    "Gemeindename": "Spiringen",
    "BFS-Nr": "1218",
    "Kantonskürzel": "UR",
    "E": "2711553.3759999983",
    "N": "1194134.3509999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterschächen",
    "PLZ": "6465",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterschächen",
    "BFS-Nr": "1219",
    "Kantonskürzel": "UR",
    "E": "2701395.572999999",
    "N": "1190867.7239999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wassen UR",
    "PLZ": "6484",
    "Zusatzziffer": "0",
    "Gemeindename": "Wassen",
    "BFS-Nr": "1220",
    "Kantonskürzel": "UR",
    "E": "2688481.118999999",
    "N": "1173446.761",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meien",
    "PLZ": "6485",
    "Zusatzziffer": "0",
    "Gemeindename": "Wassen",
    "BFS-Nr": "1220",
    "Kantonskürzel": "UR",
    "E": "2684911.101",
    "N": "1175545.9010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bennau",
    "PLZ": "8836",
    "Zusatzziffer": "0",
    "Gemeindename": "Einsiedeln",
    "BFS-Nr": "1301",
    "Kantonskürzel": "SZ",
    "E": "2697933.5890000015",
    "N": "1222763.2479999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Einsiedeln",
    "PLZ": "8840",
    "Zusatzziffer": "0",
    "Gemeindename": "Einsiedeln",
    "BFS-Nr": "1301",
    "Kantonskürzel": "SZ",
    "E": "2699480.8189999983",
    "N": "1220540.0610000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trachslau",
    "PLZ": "8840",
    "Zusatzziffer": "2",
    "Gemeindename": "Einsiedeln",
    "BFS-Nr": "1301",
    "Kantonskürzel": "SZ",
    "E": "2697685.522999998",
    "N": "1217110.9349999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gross",
    "PLZ": "8841",
    "Zusatzziffer": "0",
    "Gemeindename": "Einsiedeln",
    "BFS-Nr": "1301",
    "Kantonskürzel": "SZ",
    "E": "2701525.5540000014",
    "N": "1219185.0199999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Euthal",
    "PLZ": "8844",
    "Zusatzziffer": "0",
    "Gemeindename": "Einsiedeln",
    "BFS-Nr": "1301",
    "Kantonskürzel": "SZ",
    "E": "2704596.8680000007",
    "N": "1217000.0500000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Willerzell",
    "PLZ": "8846",
    "Zusatzziffer": "0",
    "Gemeindename": "Einsiedeln",
    "BFS-Nr": "1301",
    "Kantonskürzel": "SZ",
    "E": "2702838.4340000004",
    "N": "1220761.3409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Egg SZ",
    "PLZ": "8847",
    "Zusatzziffer": "0",
    "Gemeindename": "Einsiedeln",
    "BFS-Nr": "1301",
    "Kantonskürzel": "SZ",
    "E": "2701756.4860000014",
    "N": "1224241.2859999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rigi Scheidegg",
    "PLZ": "6410",
    "Zusatzziffer": "5",
    "Gemeindename": "Gersau",
    "BFS-Nr": "1311",
    "Kantonskürzel": "SZ",
    "E": "2682274.585000001",
    "N": "1209038.2639999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gersau",
    "PLZ": "6442",
    "Zusatzziffer": "0",
    "Gemeindename": "Gersau",
    "BFS-Nr": "1311",
    "Kantonskürzel": "SZ",
    "E": "2682787.601",
    "N": "1205215.015999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schindellegi",
    "PLZ": "8834",
    "Zusatzziffer": "0",
    "Gemeindename": "Feusisberg",
    "BFS-Nr": "1321",
    "Kantonskürzel": "SZ",
    "E": "2696582.379999999",
    "N": "1225607.7690000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Feusisberg",
    "PLZ": "8835",
    "Zusatzziffer": "0",
    "Gemeindename": "Feusisberg",
    "BFS-Nr": "1321",
    "Kantonskürzel": "SZ",
    "E": "2699214.4750000015",
    "N": "1227135.1579999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hurden",
    "PLZ": "8640",
    "Zusatzziffer": "3",
    "Gemeindename": "Freienbach",
    "BFS-Nr": "1322",
    "Kantonskürzel": "SZ",
    "E": "2703565.443",
    "N": "1230162.921",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bäch SZ",
    "PLZ": "8806",
    "Zusatzziffer": "0",
    "Gemeindename": "Freienbach",
    "BFS-Nr": "1322",
    "Kantonskürzel": "SZ",
    "E": "2698042.465",
    "N": "1228915.8889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Freienbach",
    "PLZ": "8807",
    "Zusatzziffer": "0",
    "Gemeindename": "Freienbach",
    "BFS-Nr": "1322",
    "Kantonskürzel": "SZ",
    "E": "2700150.3049999997",
    "N": "1229108.359000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfäffikon SZ",
    "PLZ": "8808",
    "Zusatzziffer": "0",
    "Gemeindename": "Freienbach",
    "BFS-Nr": "1322",
    "Kantonskürzel": "SZ",
    "E": "2701481.465",
    "N": "1228580.697999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wollerau",
    "PLZ": "8832",
    "Zusatzziffer": "0",
    "Gemeindename": "Freienbach",
    "BFS-Nr": "1322",
    "Kantonskürzel": "SZ",
    "E": "2699555.1389999986",
    "N": "1228088.4739999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilen b. Wollerau",
    "PLZ": "8832",
    "Zusatzziffer": "2",
    "Gemeindename": "Freienbach",
    "BFS-Nr": "1322",
    "Kantonskürzel": "SZ",
    "E": "2698202.039000001",
    "N": "1228150.3110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wollerau",
    "PLZ": "8832",
    "Zusatzziffer": "0",
    "Gemeindename": "Wollerau",
    "BFS-Nr": "1323",
    "Kantonskürzel": "SZ",
    "E": "2697009.5700000003",
    "N": "1228015.3399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Merlischachen",
    "PLZ": "6402",
    "Zusatzziffer": "0",
    "Gemeindename": "Küssnacht (SZ)",
    "BFS-Nr": "1331",
    "Kantonskürzel": "SZ",
    "E": "2673637.5430000015",
    "N": "1213271.9439999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Küssnacht am Rigi",
    "PLZ": "6403",
    "Zusatzziffer": "0",
    "Gemeindename": "Küssnacht (SZ)",
    "BFS-Nr": "1331",
    "Kantonskürzel": "SZ",
    "E": "2676050.7080000006",
    "N": "1214860.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Immensee",
    "PLZ": "6405",
    "Zusatzziffer": "0",
    "Gemeindename": "Küssnacht (SZ)",
    "BFS-Nr": "1331",
    "Kantonskürzel": "SZ",
    "E": "2677651.783",
    "N": "1216582.5890000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altendorf",
    "PLZ": "8852",
    "Zusatzziffer": "0",
    "Gemeindename": "Altendorf",
    "BFS-Nr": "1341",
    "Kantonskürzel": "SZ",
    "E": "2705506.2540000007",
    "N": "1227714.0960000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Galgenen",
    "PLZ": "8854",
    "Zusatzziffer": "2",
    "Gemeindename": "Galgenen",
    "BFS-Nr": "1342",
    "Kantonskürzel": "SZ",
    "E": "2708806.546",
    "N": "1226701.4140000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Innerthal",
    "PLZ": "8858",
    "Zusatzziffer": "0",
    "Gemeindename": "Innerthal",
    "BFS-Nr": "1343",
    "Kantonskürzel": "SZ",
    "E": "2712379.5650000013",
    "N": "1218305.7289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lachen SZ",
    "PLZ": "8853",
    "Zusatzziffer": "0",
    "Gemeindename": "Lachen",
    "BFS-Nr": "1344",
    "Kantonskürzel": "SZ",
    "E": "2707535.9690000005",
    "N": "1228362.0639999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reichenburg",
    "PLZ": "8864",
    "Zusatzziffer": "0",
    "Gemeindename": "Reichenburg",
    "BFS-Nr": "1345",
    "Kantonskürzel": "SZ",
    "E": "2716409.8660000004",
    "N": "1225636.9180000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Siebnen",
    "PLZ": "8854",
    "Zusatzziffer": "0",
    "Gemeindename": "Schübelbach",
    "BFS-Nr": "1346",
    "Kantonskürzel": "SZ",
    "E": "2710558.9860000014",
    "N": "1226049.2219999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schübelbach",
    "PLZ": "8862",
    "Zusatzziffer": "0",
    "Gemeindename": "Schübelbach",
    "BFS-Nr": "1346",
    "Kantonskürzel": "SZ",
    "E": "2712917.2069999985",
    "N": "1225769.6070000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buttikon SZ",
    "PLZ": "8863",
    "Zusatzziffer": "0",
    "Gemeindename": "Schübelbach",
    "BFS-Nr": "1346",
    "Kantonskürzel": "SZ",
    "E": "2714823.2859999985",
    "N": "1225863.7529999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tuggen",
    "PLZ": "8856",
    "Zusatzziffer": "0",
    "Gemeindename": "Tuggen",
    "BFS-Nr": "1347",
    "Kantonskürzel": "SZ",
    "E": "2713786.9219999984",
    "N": "1229147.3839999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vorderthal",
    "PLZ": "8857",
    "Zusatzziffer": "0",
    "Gemeindename": "Vorderthal",
    "BFS-Nr": "1348",
    "Kantonskürzel": "SZ",
    "E": "2710851.1160000004",
    "N": "1220114.572999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wangen SZ",
    "PLZ": "8855",
    "Zusatzziffer": "0",
    "Gemeindename": "Wangen (SZ)",
    "BFS-Nr": "1349",
    "Kantonskürzel": "SZ",
    "E": "2710407.4230000004",
    "N": "1227889.9809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alpthal",
    "PLZ": "8849",
    "Zusatzziffer": "0",
    "Gemeindename": "Alpthal",
    "BFS-Nr": "1361",
    "Kantonskürzel": "SZ",
    "E": "2697032.789000001",
    "N": "1214090.3339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Goldau",
    "PLZ": "6410",
    "Zusatzziffer": "0",
    "Gemeindename": "Arth",
    "BFS-Nr": "1362",
    "Kantonskürzel": "SZ",
    "E": "2684063.045000002",
    "N": "1211212.2289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rigi Klösterli",
    "PLZ": "6410",
    "Zusatzziffer": "2",
    "Gemeindename": "Arth",
    "BFS-Nr": "1362",
    "Kantonskürzel": "SZ",
    "E": "2679571.4809999987",
    "N": "1210805.625",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rigi Staffel",
    "PLZ": "6410",
    "Zusatzziffer": "3",
    "Gemeindename": "Arth",
    "BFS-Nr": "1362",
    "Kantonskürzel": "SZ",
    "E": "2678763.4690000005",
    "N": "1211927.0659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rigi Kulm",
    "PLZ": "6410",
    "Zusatzziffer": "4",
    "Gemeindename": "Arth",
    "BFS-Nr": "1362",
    "Kantonskürzel": "SZ",
    "E": "2679338.199000001",
    "N": "1212100.0839999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberarth",
    "PLZ": "6414",
    "Zusatzziffer": "0",
    "Gemeindename": "Arth",
    "BFS-Nr": "1362",
    "Kantonskürzel": "SZ",
    "E": "2683191.818",
    "N": "1212253.7080000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arth",
    "PLZ": "6415",
    "Zusatzziffer": "0",
    "Gemeindename": "Arth",
    "BFS-Nr": "1362",
    "Kantonskürzel": "SZ",
    "E": "2682383.6510000005",
    "N": "1213076.664999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Illgau",
    "PLZ": "6434",
    "Zusatzziffer": "0",
    "Gemeindename": "Illgau",
    "BFS-Nr": "1363",
    "Kantonskürzel": "SZ",
    "E": "2697727.484000001",
    "N": "1204861.1530000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brunnen",
    "PLZ": "6440",
    "Zusatzziffer": "0",
    "Gemeindename": "Ingenbohl",
    "BFS-Nr": "1364",
    "Kantonskürzel": "SZ",
    "E": "2688736.101",
    "N": "1205475.1669999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lauerz",
    "PLZ": "6424",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauerz",
    "BFS-Nr": "1365",
    "Kantonskürzel": "SZ",
    "E": "2686753.7360000014",
    "N": "1210004.265999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stoos SZ",
    "PLZ": "6433",
    "Zusatzziffer": "0",
    "Gemeindename": "Morschach",
    "BFS-Nr": "1366",
    "Kantonskürzel": "SZ",
    "E": "2693269.903999999",
    "N": "1203993.3409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Morschach",
    "PLZ": "6443",
    "Zusatzziffer": "0",
    "Gemeindename": "Morschach",
    "BFS-Nr": "1366",
    "Kantonskürzel": "SZ",
    "E": "2689906.8500000015",
    "N": "1204072.7729999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Muotathal",
    "PLZ": "6436",
    "Zusatzziffer": "0",
    "Gemeindename": "Muotathal",
    "BFS-Nr": "1367",
    "Kantonskürzel": "SZ",
    "E": "2700531.223000001",
    "N": "1203787.1600000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bisisthal",
    "PLZ": "6436",
    "Zusatzziffer": "2",
    "Gemeindename": "Muotathal",
    "BFS-Nr": "1367",
    "Kantonskürzel": "SZ",
    "E": "2706036.9010000005",
    "N": "1200145.261",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ried (Muotathal)",
    "PLZ": "6436",
    "Zusatzziffer": "3",
    "Gemeindename": "Muotathal",
    "BFS-Nr": "1367",
    "Kantonskürzel": "SZ",
    "E": "2697013.522999998",
    "N": "1204711.1559999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberiberg",
    "PLZ": "8843",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberiberg",
    "BFS-Nr": "1368",
    "Kantonskürzel": "SZ",
    "E": "2702428.0650000013",
    "N": "1210953.4549999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riemenstalden",
    "PLZ": "6452",
    "Zusatzziffer": "2",
    "Gemeindename": "Riemenstalden",
    "BFS-Nr": "1369",
    "Kantonskürzel": "SZ",
    "E": "2693464.9499999993",
    "N": "1200275.8550000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rothenthurm",
    "PLZ": "6418",
    "Zusatzziffer": "0",
    "Gemeindename": "Rothenthurm",
    "BFS-Nr": "1370",
    "Kantonskürzel": "SZ",
    "E": "2693856.534000002",
    "N": "1217858.1479999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sattel",
    "PLZ": "6417",
    "Zusatzziffer": "0",
    "Gemeindename": "Sattel",
    "BFS-Nr": "1371",
    "Kantonskürzel": "SZ",
    "E": "2691055.1169999987",
    "N": "1215443.710000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seewen SZ",
    "PLZ": "6423",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwyz",
    "BFS-Nr": "1372",
    "Kantonskürzel": "SZ",
    "E": "2690625.688000001",
    "N": "1209434.8029999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwyz",
    "PLZ": "6430",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwyz",
    "BFS-Nr": "1372",
    "Kantonskürzel": "SZ",
    "E": "2692371.7129999995",
    "N": "1208527.2030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach b. Schwyz",
    "PLZ": "6432",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwyz",
    "BFS-Nr": "1372",
    "Kantonskürzel": "SZ",
    "E": "2693305.079999998",
    "N": "1207990.886",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ibach",
    "PLZ": "6438",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwyz",
    "BFS-Nr": "1372",
    "Kantonskürzel": "SZ",
    "E": "2691718.1270000003",
    "N": "1207571.5879999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinen",
    "PLZ": "6422",
    "Zusatzziffer": "0",
    "Gemeindename": "Steinen",
    "BFS-Nr": "1373",
    "Kantonskürzel": "SZ",
    "E": "2689245.4349999987",
    "N": "1211672.8390000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinerberg",
    "PLZ": "6416",
    "Zusatzziffer": "0",
    "Gemeindename": "Steinerberg",
    "BFS-Nr": "1374",
    "Kantonskürzel": "SZ",
    "E": "2687124.6220000014",
    "N": "1211864.3599999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unteriberg",
    "PLZ": "8842",
    "Zusatzziffer": "0",
    "Gemeindename": "Unteriberg",
    "BFS-Nr": "1375",
    "Kantonskürzel": "SZ",
    "E": "2703673.9990000017",
    "N": "1212606.1539999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Studen SZ",
    "PLZ": "8845",
    "Zusatzziffer": "0",
    "Gemeindename": "Unteriberg",
    "BFS-Nr": "1375",
    "Kantonskürzel": "SZ",
    "E": "2705955.7309999987",
    "N": "1214538.8079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pilatus Kulm",
    "PLZ": "6010",
    "Zusatzziffer": "2",
    "Gemeindename": "Alpnach",
    "BFS-Nr": "1401",
    "Kantonskürzel": "OW",
    "E": "2662090.585999999",
    "N": "1203455.3740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alpnachstad",
    "PLZ": "6053",
    "Zusatzziffer": "0",
    "Gemeindename": "Alpnach",
    "BFS-Nr": "1401",
    "Kantonskürzel": "OW",
    "E": "2663772.2479999997",
    "N": "1200633.7470000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alpnach Dorf",
    "PLZ": "6055",
    "Zusatzziffer": "0",
    "Gemeindename": "Alpnach",
    "BFS-Nr": "1401",
    "Kantonskürzel": "OW",
    "E": "2663438.2710000016",
    "N": "1199223.4420000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grafenort",
    "PLZ": "6388",
    "Zusatzziffer": "0",
    "Gemeindename": "Engelberg",
    "BFS-Nr": "1402",
    "Kantonskürzel": "OW",
    "E": "2671188.954999998",
    "N": "1191465.493999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Engelberg",
    "PLZ": "6390",
    "Zusatzziffer": "0",
    "Gemeindename": "Engelberg",
    "BFS-Nr": "1402",
    "Kantonskürzel": "OW",
    "E": "2673650.284000002",
    "N": "1185458.6429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Giswil",
    "PLZ": "6074",
    "Zusatzziffer": "0",
    "Gemeindename": "Giswil",
    "BFS-Nr": "1403",
    "Kantonskürzel": "OW",
    "E": "2656655.947999999",
    "N": "1187569.9899999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kerns",
    "PLZ": "6064",
    "Zusatzziffer": "0",
    "Gemeindename": "Kerns",
    "BFS-Nr": "1404",
    "Kantonskürzel": "OW",
    "E": "2664125.636",
    "N": "1194883.0700000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Niklausen OW",
    "PLZ": "6066",
    "Zusatzziffer": "0",
    "Gemeindename": "Kerns",
    "BFS-Nr": "1404",
    "Kantonskürzel": "OW",
    "E": "2664390.829999998",
    "N": "1191837.7529999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Melchtal",
    "PLZ": "6067",
    "Zusatzziffer": "0",
    "Gemeindename": "Kerns",
    "BFS-Nr": "1404",
    "Kantonskürzel": "OW",
    "E": "2665162.068",
    "N": "1187325.6950000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Melchsee-Frutt",
    "PLZ": "6068",
    "Zusatzziffer": "0",
    "Gemeindename": "Kerns",
    "BFS-Nr": "1404",
    "Kantonskürzel": "OW",
    "E": "2663426.6039999984",
    "N": "1180600.5780000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lungern",
    "PLZ": "6078",
    "Zusatzziffer": "0",
    "Gemeindename": "Lungern",
    "BFS-Nr": "1405",
    "Kantonskürzel": "OW",
    "E": "2655009.6669999994",
    "N": "1181515.2729999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bürglen OW",
    "PLZ": "6078",
    "Zusatzziffer": "2",
    "Gemeindename": "Lungern",
    "BFS-Nr": "1405",
    "Kantonskürzel": "OW",
    "E": "2655519.5080000013",
    "N": "1184802.357999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sachseln",
    "PLZ": "6072",
    "Zusatzziffer": "0",
    "Gemeindename": "Sachseln",
    "BFS-Nr": "1406",
    "Kantonskürzel": "OW",
    "E": "2660571.2430000007",
    "N": "1190686.4609999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flüeli-Ranft",
    "PLZ": "6073",
    "Zusatzziffer": "0",
    "Gemeindename": "Sachseln",
    "BFS-Nr": "1406",
    "Kantonskürzel": "OW",
    "E": "2662910.1750000007",
    "N": "1191534.2360000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kägiswil",
    "PLZ": "6056",
    "Zusatzziffer": "0",
    "Gemeindename": "Sarnen",
    "BFS-Nr": "1407",
    "Kantonskürzel": "OW",
    "E": "2662454.7179999985",
    "N": "1196816.1629999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sarnen",
    "PLZ": "6060",
    "Zusatzziffer": "0",
    "Gemeindename": "Sarnen",
    "BFS-Nr": "1407",
    "Kantonskürzel": "OW",
    "E": "2661797.3040000014",
    "N": "1193924.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ramersberg",
    "PLZ": "6060",
    "Zusatzziffer": "2",
    "Gemeindename": "Sarnen",
    "BFS-Nr": "1407",
    "Kantonskürzel": "OW",
    "E": "2660412.4010000005",
    "N": "1194635.0980000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilen (Sarnen)",
    "PLZ": "6062",
    "Zusatzziffer": "0",
    "Gemeindename": "Sarnen",
    "BFS-Nr": "1407",
    "Kantonskürzel": "OW",
    "E": "2659862.969999999",
    "N": "1192597.0329999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stalden (Sarnen)",
    "PLZ": "6063",
    "Zusatzziffer": "0",
    "Gemeindename": "Sarnen",
    "BFS-Nr": "1407",
    "Kantonskürzel": "OW",
    "E": "2658684.8440000005",
    "N": "1193206.2140000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beckenried",
    "PLZ": "6375",
    "Zusatzziffer": "0",
    "Gemeindename": "Beckenried",
    "BFS-Nr": "1501",
    "Kantonskürzel": "NW",
    "E": "2678395.767999999",
    "N": "1202071.0989999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buochs",
    "PLZ": "6374",
    "Zusatzziffer": "0",
    "Gemeindename": "Buochs",
    "BFS-Nr": "1502",
    "Kantonskürzel": "NW",
    "E": "2674684.4990000017",
    "N": "1202724.8079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dallenwil",
    "PLZ": "6383",
    "Zusatzziffer": "0",
    "Gemeindename": "Dallenwil",
    "BFS-Nr": "1503",
    "Kantonskürzel": "NW",
    "E": "2672496.787999999",
    "N": "1198402.745000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiesenberg",
    "PLZ": "6383",
    "Zusatzziffer": "3",
    "Gemeindename": "Dallenwil",
    "BFS-Nr": "1503",
    "Kantonskürzel": "NW",
    "E": "2670486.335000001",
    "N": "1197520.3280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wirzweli",
    "PLZ": "6383",
    "Zusatzziffer": "4",
    "Gemeindename": "Dallenwil",
    "BFS-Nr": "1503",
    "Kantonskürzel": "NW",
    "E": "2670579.9329999983",
    "N": "1196220.8960000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Emmetten",
    "PLZ": "6376",
    "Zusatzziffer": "0",
    "Gemeindename": "Emmetten",
    "BFS-Nr": "1504",
    "Kantonskürzel": "NW",
    "E": "2682548.454",
    "N": "1201069.9230000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bürgenstock",
    "PLZ": "6363",
    "Zusatzziffer": "2",
    "Gemeindename": "Ennetbürgen",
    "BFS-Nr": "1505",
    "Kantonskürzel": "NW",
    "E": "2671773.846999999",
    "N": "1205561.0379999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ennetbürgen",
    "PLZ": "6373",
    "Zusatzziffer": "0",
    "Gemeindename": "Ennetbürgen",
    "BFS-Nr": "1505",
    "Kantonskürzel": "NW",
    "E": "2674238.844999999",
    "N": "1204217.6510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ennetmoos",
    "PLZ": "6372",
    "Zusatzziffer": "0",
    "Gemeindename": "Ennetmoos",
    "BFS-Nr": "1506",
    "Kantonskürzel": "NW",
    "E": "2668465.636",
    "N": "1201016.0300000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hergiswil NW",
    "PLZ": "6052",
    "Zusatzziffer": "0",
    "Gemeindename": "Hergiswil (NW)",
    "BFS-Nr": "1507",
    "Kantonskürzel": "NW",
    "E": "2665843.9640000015",
    "N": "1204372.2259999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberdorf NW",
    "PLZ": "6370",
    "Zusatzziffer": "41",
    "Gemeindename": "Oberdorf (NW)",
    "BFS-Nr": "1508",
    "Kantonskürzel": "NW",
    "E": "2672159.101",
    "N": "1201267.6110000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büren NW",
    "PLZ": "6382",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberdorf (NW)",
    "BFS-Nr": "1508",
    "Kantonskürzel": "NW",
    "E": "2673043.8040000014",
    "N": "1199246.4880000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederrickenbach",
    "PLZ": "6383",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberdorf (NW)",
    "BFS-Nr": "1508",
    "Kantonskürzel": "NW",
    "E": "2676031.4189999998",
    "N": "1197179.7030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stans",
    "PLZ": "6370",
    "Zusatzziffer": "0",
    "Gemeindename": "Stans",
    "BFS-Nr": "1509",
    "Kantonskürzel": "NW",
    "E": "2670613.506000001",
    "N": "1201233.2840000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stansstad",
    "PLZ": "6362",
    "Zusatzziffer": "0",
    "Gemeindename": "Stansstad",
    "BFS-Nr": "1510",
    "Kantonskürzel": "NW",
    "E": "2668551.033",
    "N": "1203229.2060000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obbürgen",
    "PLZ": "6363",
    "Zusatzziffer": "0",
    "Gemeindename": "Stansstad",
    "BFS-Nr": "1510",
    "Kantonskürzel": "NW",
    "E": "2670135.375",
    "N": "1203801.9039999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fürigen",
    "PLZ": "6363",
    "Zusatzziffer": "1",
    "Gemeindename": "Stansstad",
    "BFS-Nr": "1510",
    "Kantonskürzel": "NW",
    "E": "2669479.140999999",
    "N": "1203829.300999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kehrsiten",
    "PLZ": "6365",
    "Zusatzziffer": "0",
    "Gemeindename": "Stansstad",
    "BFS-Nr": "1510",
    "Kantonskürzel": "NW",
    "E": "2670823.3390000015",
    "N": "1205975.6479999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wolfenschiessen",
    "PLZ": "6386",
    "Zusatzziffer": "0",
    "Gemeindename": "Wolfenschiessen",
    "BFS-Nr": "1511",
    "Kantonskürzel": "NW",
    "E": "2672973.4400000013",
    "N": "1195745.4250000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberrickenbach",
    "PLZ": "6387",
    "Zusatzziffer": "0",
    "Gemeindename": "Wolfenschiessen",
    "BFS-Nr": "1511",
    "Kantonskürzel": "NW",
    "E": "2675096.8830000013",
    "N": "1193472.9789999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Näfels",
    "PLZ": "8752",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2722853.563000001",
    "N": "1217946.4059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mollis",
    "PLZ": "8753",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2724287.7399999984",
    "N": "1217151.3319999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Filzbach",
    "PLZ": "8757",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2728432.829",
    "N": "1220213.5269999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obstalden",
    "PLZ": "8758",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2729950.0119999982",
    "N": "1219922.2699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bilten",
    "PLZ": "8865",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2720297.4109999985",
    "N": "1223345.2149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederurnen",
    "PLZ": "8867",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2722482.392999999",
    "N": "1220673.4310000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberurnen",
    "PLZ": "8868",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2722953.8500000015",
    "N": "1219449.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühlehorn",
    "PLZ": "8874",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Nord",
    "BFS-Nr": "1630",
    "Kantonskürzel": "GL",
    "E": "2731586.6000000015",
    "N": "1219937.3770000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mitlödi",
    "PLZ": "8756",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2724728.984000001",
    "N": "1207904.6739999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwanden GL",
    "PLZ": "8762",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2724441.8150000013",
    "N": "1206203.9180000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwändi b. Schwanden",
    "PLZ": "8762",
    "Zusatzziffer": "2",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2723805.2060000002",
    "N": "1207356.574000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sool",
    "PLZ": "8762",
    "Zusatzziffer": "3",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2725263.7650000006",
    "N": "1206833.6530000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Engi",
    "PLZ": "8765",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2730044.9340000004",
    "N": "1204992.7980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Matt",
    "PLZ": "8766",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2731776.344999999",
    "N": "1202805.0130000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Elm",
    "PLZ": "8767",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2732035.467",
    "N": "1197846.8630000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nidfurn",
    "PLZ": "8772",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2722679.1440000013",
    "N": "1205243.9499999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Haslen GL",
    "PLZ": "8773",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2723355.7010000013",
    "N": "1204565.6380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leuggelbach",
    "PLZ": "8774",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2721919.1400000006",
    "N": "1204095.5599999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luchsingen",
    "PLZ": "8775",
    "Zusatzziffer": "1",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2721537.3189999983",
    "N": "1202970.7829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hätzingen",
    "PLZ": "8775",
    "Zusatzziffer": "2",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2721909.0500000007",
    "N": "1202399.7280000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diesbach GL",
    "PLZ": "8777",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2721544.7859999985",
    "N": "1201330.3709999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Betschwanden",
    "PLZ": "8777",
    "Zusatzziffer": "2",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2720952.3839999996",
    "N": "1200451.640999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüti GL",
    "PLZ": "8782",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2719869.5480000004",
    "N": "1199370.4109999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Linthal",
    "PLZ": "8783",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2719090.5760000013",
    "N": "1198240.9219999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Braunwald",
    "PLZ": "8784",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus Süd",
    "BFS-Nr": "1631",
    "Kantonskürzel": "GL",
    "E": "2718733.2479999997",
    "N": "1200023.1429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Glarus",
    "PLZ": "8750",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus",
    "BFS-Nr": "1632",
    "Kantonskürzel": "GL",
    "E": "2723644.552000001",
    "N": "1211218.5219999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riedern",
    "PLZ": "8750",
    "Zusatzziffer": "2",
    "Gemeindename": "Glarus",
    "BFS-Nr": "1632",
    "Kantonskürzel": "GL",
    "E": "2722224.3759999983",
    "N": "1212146.0130000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Klöntal",
    "PLZ": "8750",
    "Zusatzziffer": "3",
    "Gemeindename": "Glarus",
    "BFS-Nr": "1632",
    "Kantonskürzel": "GL",
    "E": "2714304.7300000004",
    "N": "1209374.300999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Netstal",
    "PLZ": "8754",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus",
    "BFS-Nr": "1632",
    "Kantonskürzel": "GL",
    "E": "2722466.8090000004",
    "N": "1213770.5029999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ennenda",
    "PLZ": "8755",
    "Zusatzziffer": "0",
    "Gemeindename": "Glarus",
    "BFS-Nr": "1632",
    "Kantonskürzel": "GL",
    "E": "2724674.193",
    "N": "1210502.1020000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Allenwinden",
    "PLZ": "6319",
    "Zusatzziffer": "0",
    "Gemeindename": "Baar",
    "BFS-Nr": "1701",
    "Kantonskürzel": "ZG",
    "E": "2684339.1779999994",
    "N": "1224328.5529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Baar",
    "PLZ": "6340",
    "Zusatzziffer": "0",
    "Gemeindename": "Baar",
    "BFS-Nr": "1701",
    "Kantonskürzel": "ZG",
    "E": "2682158.776999999",
    "N": "1227489.6609999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Cham",
    "PLZ": "6330",
    "Zusatzziffer": "0",
    "Gemeindename": "Cham",
    "BFS-Nr": "1702",
    "Kantonskürzel": "ZG",
    "E": "2677391.723000001",
    "N": "1225873.7219999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hagendorn",
    "PLZ": "6332",
    "Zusatzziffer": "0",
    "Gemeindename": "Cham",
    "BFS-Nr": "1702",
    "Kantonskürzel": "ZG",
    "E": "2675258.8680000007",
    "N": "1228475.3060000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hünenberg",
    "PLZ": "6331",
    "Zusatzziffer": "0",
    "Gemeindename": "Hünenberg",
    "BFS-Nr": "1703",
    "Kantonskürzel": "ZG",
    "E": "2675002.8630000018",
    "N": "1225334.7840000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hünenberg See",
    "PLZ": "6333",
    "Zusatzziffer": "0",
    "Gemeindename": "Hünenberg",
    "BFS-Nr": "1703",
    "Kantonskürzel": "ZG",
    "E": "2676700.688000001",
    "N": "1224749.175999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Menzingen",
    "PLZ": "6313",
    "Zusatzziffer": "0",
    "Gemeindename": "Menzingen",
    "BFS-Nr": "1704",
    "Kantonskürzel": "ZG",
    "E": "2690826.114",
    "N": "1225099.5749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Menzingen",
    "PLZ": "6313",
    "Zusatzziffer": "0",
    "Gemeindename": "Menzingen",
    "BFS-Nr": "1704",
    "Kantonskürzel": "ZG",
    "E": "2687298.3139999993",
    "N": "1225954.2210000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Edlibach",
    "PLZ": "6313",
    "Zusatzziffer": "2",
    "Gemeindename": "Menzingen",
    "BFS-Nr": "1704",
    "Kantonskürzel": "ZG",
    "E": "2685869.75",
    "N": "1226040.625",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Finstersee",
    "PLZ": "6313",
    "Zusatzziffer": "3",
    "Gemeindename": "Menzingen",
    "BFS-Nr": "1704",
    "Kantonskürzel": "ZG",
    "E": "2690308.107000001",
    "N": "1224702.449000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sihlbrugg",
    "PLZ": "6340",
    "Zusatzziffer": "4",
    "Gemeindename": "Neuheim",
    "BFS-Nr": "1705",
    "Kantonskürzel": "ZG",
    "E": "2686225.2619999982",
    "N": "1230250.6319999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuheim",
    "PLZ": "6345",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuheim",
    "BFS-Nr": "1705",
    "Kantonskürzel": "ZG",
    "E": "2686150.7509999983",
    "N": "1228912.515999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberägeri",
    "PLZ": "6315",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberägeri",
    "BFS-Nr": "1706",
    "Kantonskürzel": "ZG",
    "E": "2689194.807",
    "N": "1221067.5119999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alosen",
    "PLZ": "6315",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberägeri",
    "BFS-Nr": "1706",
    "Kantonskürzel": "ZG",
    "E": "2690732.1970000006",
    "N": "1221776.8850000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Morgarten",
    "PLZ": "6315",
    "Zusatzziffer": "3",
    "Gemeindename": "Oberägeri",
    "BFS-Nr": "1706",
    "Kantonskürzel": "ZG",
    "E": "2691229.0760000013",
    "N": "1217729.2239999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rotkreuz",
    "PLZ": "6343",
    "Zusatzziffer": "0",
    "Gemeindename": "Risch",
    "BFS-Nr": "1707",
    "Kantonskürzel": "ZG",
    "E": "2675168.802000001",
    "N": "1221442.2529999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buonas",
    "PLZ": "6343",
    "Zusatzziffer": "2",
    "Gemeindename": "Risch",
    "BFS-Nr": "1707",
    "Kantonskürzel": "ZG",
    "E": "2677235.0139999986",
    "N": "1221618.8090000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Risch",
    "PLZ": "6343",
    "Zusatzziffer": "3",
    "Gemeindename": "Risch",
    "BFS-Nr": "1707",
    "Kantonskürzel": "ZG",
    "E": "2678005.4560000002",
    "N": "1220841.4450000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Holzhäusern ZG",
    "PLZ": "6343",
    "Zusatzziffer": "4",
    "Gemeindename": "Risch",
    "BFS-Nr": "1707",
    "Kantonskürzel": "ZG",
    "E": "2675998.1779999994",
    "N": "1223042.3029999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinhausen",
    "PLZ": "6312",
    "Zusatzziffer": "0",
    "Gemeindename": "Steinhausen",
    "BFS-Nr": "1708",
    "Kantonskürzel": "ZG",
    "E": "2679304.0029999986",
    "N": "1227758.3530000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterägeri",
    "PLZ": "6314",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterägeri",
    "BFS-Nr": "1709",
    "Kantonskürzel": "ZG",
    "E": "2686787.1180000007",
    "N": "1221456.3139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuägeri",
    "PLZ": "6314",
    "Zusatzziffer": "1",
    "Gemeindename": "Unterägeri",
    "BFS-Nr": "1709",
    "Kantonskürzel": "ZG",
    "E": "2685254.4239999987",
    "N": "1222988.5689999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walchwil",
    "PLZ": "6318",
    "Zusatzziffer": "0",
    "Gemeindename": "Walchwil",
    "BFS-Nr": "1710",
    "Kantonskürzel": "ZG",
    "E": "2681692.4880000018",
    "N": "1217143.3330000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zug",
    "PLZ": "6300",
    "Zusatzziffer": "0",
    "Gemeindename": "Zug",
    "BFS-Nr": "1711",
    "Kantonskürzel": "ZG",
    "E": "2681687.465",
    "N": "1224362.3460000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zugerberg",
    "PLZ": "6300",
    "Zusatzziffer": "5",
    "Gemeindename": "Zug",
    "BFS-Nr": "1711",
    "Kantonskürzel": "ZG",
    "E": "2683041.7710000016",
    "N": "1221956.8139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwil b. Zug",
    "PLZ": "6317",
    "Zusatzziffer": "0",
    "Gemeindename": "Zug",
    "BFS-Nr": "1711",
    "Kantonskürzel": "ZG",
    "E": "2681039.1779999994",
    "N": "1222420.5150000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Châtillon FR",
    "PLZ": "1473",
    "Zusatzziffer": "2",
    "Gemeindename": "Châtillon (FR)",
    "BFS-Nr": "2008",
    "Kantonskürzel": "FR",
    "E": "2553388.6290000007",
    "N": "1187063.8709999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cugy FR",
    "PLZ": "1482",
    "Zusatzziffer": "0",
    "Gemeindename": "Cugy (FR)",
    "BFS-Nr": "2011",
    "Kantonskürzel": "FR",
    "E": "2558364.4450000003",
    "N": "1185003.6290000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vesin",
    "PLZ": "1483",
    "Zusatzziffer": "3",
    "Gemeindename": "Cugy (FR)",
    "BFS-Nr": "2011",
    "Kantonskürzel": "FR",
    "E": "2556808.405000001",
    "N": "1184211.8949999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fétigny",
    "PLZ": "1532",
    "Zusatzziffer": "0",
    "Gemeindename": "Fétigny",
    "BFS-Nr": "2016",
    "Kantonskürzel": "FR",
    "E": "2560060.1330000013",
    "N": "1183014.9059999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gletterens",
    "PLZ": "1544",
    "Zusatzziffer": "0",
    "Gemeindename": "Gletterens",
    "BFS-Nr": "2022",
    "Kantonskürzel": "FR",
    "E": "2561785.405000001",
    "N": "1193913.4439999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lully FR",
    "PLZ": "1470",
    "Zusatzziffer": "2",
    "Gemeindename": "Lully (FR)",
    "BFS-Nr": "2025",
    "Kantonskürzel": "FR",
    "E": "2554944.8550000004",
    "N": "1187263.7179999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Seiry",
    "PLZ": "1470",
    "Zusatzziffer": "3",
    "Gemeindename": "Lully (FR)",
    "BFS-Nr": "2025",
    "Kantonskürzel": "FR",
    "E": "2553705.9990000017",
    "N": "1184727.3090000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bollion",
    "PLZ": "1470",
    "Zusatzziffer": "4",
    "Gemeindename": "Lully (FR)",
    "BFS-Nr": "2025",
    "Kantonskürzel": "FR",
    "E": "2553338.3709999993",
    "N": "1185285.3740000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ménières",
    "PLZ": "1533",
    "Zusatzziffer": "0",
    "Gemeindename": "Ménières",
    "BFS-Nr": "2027",
    "Kantonskürzel": "FR",
    "E": "2557451.9109999985",
    "N": "1181593.9699999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cousset",
    "PLZ": "1774",
    "Zusatzziffer": "0",
    "Gemeindename": "Montagny (FR)",
    "BFS-Nr": "2029",
    "Kantonskürzel": "FR",
    "E": "2564342.3049999997",
    "N": "1186283.6600000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cousset",
    "PLZ": "1774",
    "Zusatzziffer": "0",
    "Gemeindename": "Montagny (FR)",
    "BFS-Nr": "2029",
    "Kantonskürzel": "FR",
    "E": "2564845.0670000017",
    "N": "1185369.3480000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montagny-les-Monts",
    "PLZ": "1774",
    "Zusatzziffer": "2",
    "Gemeindename": "Montagny (FR)",
    "BFS-Nr": "2029",
    "Kantonskürzel": "FR",
    "E": "2565910.5700000003",
    "N": "1184467.5989999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mannens",
    "PLZ": "1775",
    "Zusatzziffer": "2",
    "Gemeindename": "Montagny (FR)",
    "BFS-Nr": "2029",
    "Kantonskürzel": "FR",
    "E": "2564230.5210000016",
    "N": "1182485.2259999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandsivaz",
    "PLZ": "1775",
    "Zusatzziffer": "3",
    "Gemeindename": "Montagny (FR)",
    "BFS-Nr": "2029",
    "Kantonskürzel": "FR",
    "E": "2565039.4979999997",
    "N": "1181680.6099999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montagny-la-Ville",
    "PLZ": "1776",
    "Zusatzziffer": "0",
    "Gemeindename": "Montagny (FR)",
    "BFS-Nr": "2029",
    "Kantonskürzel": "FR",
    "E": "2566177.390999999",
    "N": "1185245.835000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Nuvilly",
    "PLZ": "1485",
    "Zusatzziffer": "0",
    "Gemeindename": "Nuvilly",
    "BFS-Nr": "2035",
    "Kantonskürzel": "FR",
    "E": "2553571.8709999993",
    "N": "1181612.0009999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prévondavaux",
    "PLZ": "1410",
    "Zusatzziffer": "4",
    "Gemeindename": "Prévondavaux",
    "BFS-Nr": "2038",
    "Kantonskürzel": "FR",
    "E": "2550667.0439999998",
    "N": "1175333.3489999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Aubin FR",
    "PLZ": "1566",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Aubin (FR)",
    "BFS-Nr": "2041",
    "Kantonskürzel": "FR",
    "E": "2565073.7650000006",
    "N": "1193326.7430000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Friques",
    "PLZ": "1566",
    "Zusatzziffer": "2",
    "Gemeindename": "Saint-Aubin (FR)",
    "BFS-Nr": "2041",
    "Kantonskürzel": "FR",
    "E": "2565667.4010000005",
    "N": "1194790.5830000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sévaz",
    "PLZ": "1541",
    "Zusatzziffer": "3",
    "Gemeindename": "Sévaz",
    "BFS-Nr": "2043",
    "Kantonskürzel": "FR",
    "E": "2557041.649",
    "N": "1187693.8390000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villeneuve FR",
    "PLZ": "1527",
    "Zusatzziffer": "0",
    "Gemeindename": "Surpierre",
    "BFS-Nr": "2044",
    "Kantonskürzel": "FR",
    "E": "2556439.1889999993",
    "N": "1177355.5240000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Surpierre",
    "PLZ": "1528",
    "Zusatzziffer": "0",
    "Gemeindename": "Surpierre",
    "BFS-Nr": "2044",
    "Kantonskürzel": "FR",
    "E": "2555883.482999999",
    "N": "1177251.3790000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Praratoud",
    "PLZ": "1528",
    "Zusatzziffer": "2",
    "Gemeindename": "Surpierre",
    "BFS-Nr": "2044",
    "Kantonskürzel": "FR",
    "E": "2554820.659000002",
    "N": "1176105.7840000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cheiry",
    "PLZ": "1529",
    "Zusatzziffer": "0",
    "Gemeindename": "Surpierre",
    "BFS-Nr": "2044",
    "Kantonskürzel": "FR",
    "E": "2553766.2690000013",
    "N": "1177750.0540000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chapelle (Broye)",
    "PLZ": "1534",
    "Zusatzziffer": "2",
    "Gemeindename": "Surpierre",
    "BFS-Nr": "2044",
    "Kantonskürzel": "FR",
    "E": "2555159.083999999",
    "N": "1180045.5359999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vallon",
    "PLZ": "1565",
    "Zusatzziffer": "2",
    "Gemeindename": "Vallon",
    "BFS-Nr": "2045",
    "Kantonskürzel": "FR",
    "E": "2563012.1130000018",
    "N": "1192743.4659999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montet (Broye)",
    "PLZ": "1483",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Montets",
    "BFS-Nr": "2050",
    "Kantonskürzel": "FR",
    "E": "2556504.3110000007",
    "N": "1185144.0289999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Frasses",
    "PLZ": "1483",
    "Zusatzziffer": "2",
    "Gemeindename": "Les Montets",
    "BFS-Nr": "2050",
    "Kantonskürzel": "FR",
    "E": "2556121.030000001",
    "N": "1186340.0089999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aumont",
    "PLZ": "1484",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Montets",
    "BFS-Nr": "2050",
    "Kantonskürzel": "FR",
    "E": "2554959.359000001",
    "N": "1183506.0439999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Granges-de-Vesin",
    "PLZ": "1484",
    "Zusatzziffer": "2",
    "Gemeindename": "Les Montets",
    "BFS-Nr": "2050",
    "Kantonskürzel": "FR",
    "E": "2554513.5",
    "N": "1184743.375",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Delley",
    "PLZ": "1567",
    "Zusatzziffer": "0",
    "Gemeindename": "Delley-Portalban",
    "BFS-Nr": "2051",
    "Kantonskürzel": "FR",
    "E": "2564115.4990000017",
    "N": "1195890.3029999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Portalban",
    "PLZ": "1568",
    "Zusatzziffer": "0",
    "Gemeindename": "Delley-Portalban",
    "BFS-Nr": "2051",
    "Kantonskürzel": "FR",
    "E": "2563196.072999999",
    "N": "1196430.1290000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dompierre FR",
    "PLZ": "1563",
    "Zusatzziffer": "0",
    "Gemeindename": "Belmont-Broye",
    "BFS-Nr": "2053",
    "Kantonskürzel": "FR",
    "E": "2565676.272999998",
    "N": "1189200.0610000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Domdidier",
    "PLZ": "1564",
    "Zusatzziffer": "0",
    "Gemeindename": "Belmont-Broye",
    "BFS-Nr": "2053",
    "Kantonskürzel": "FR",
    "E": "2567725.721000001",
    "N": "1190491.4050000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Léchelles",
    "PLZ": "1773",
    "Zusatzziffer": "0",
    "Gemeindename": "Belmont-Broye",
    "BFS-Nr": "2053",
    "Kantonskürzel": "FR",
    "E": "2567751.2459999993",
    "N": "1186519.0130000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chandon",
    "PLZ": "1773",
    "Zusatzziffer": "2",
    "Gemeindename": "Belmont-Broye",
    "BFS-Nr": "2053",
    "Kantonskürzel": "FR",
    "E": "2570210.9640000015",
    "N": "1187620.0229999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Russy",
    "PLZ": "1773",
    "Zusatzziffer": "3",
    "Gemeindename": "Belmont-Broye",
    "BFS-Nr": "2053",
    "Kantonskürzel": "FR",
    "E": "2566652.658",
    "N": "1188055.9189999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Estavayer-le-Lac",
    "PLZ": "1470",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2554892.318",
    "N": "1188932.6660000011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Font",
    "PLZ": "1473",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2552808.5020000003",
    "N": "1187408.011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Font",
    "PLZ": "1473",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2554164.5780000016",
    "N": "1186203.2490000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Autavaux",
    "PLZ": "1475",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2557213.414999999",
    "N": "1190606.085000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Forel FR",
    "PLZ": "1475",
    "Zusatzziffer": "2",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2558031.8559999987",
    "N": "1191751.1680000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montbrelloz",
    "PLZ": "1475",
    "Zusatzziffer": "3",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2557840.6110000014",
    "N": "1189310.4450000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuissens",
    "PLZ": "1486",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2548575.4459999986",
    "N": "1176530.995000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Murist",
    "PLZ": "1489",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2551797.142999999",
    "N": "1182336.074000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bussy FR",
    "PLZ": "1541",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2558090.1460000016",
    "N": "1187228.4990000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Morens FR",
    "PLZ": "1541",
    "Zusatzziffer": "2",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2559486.8949999996",
    "N": "1188283.3079999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rueyres-les-Prés",
    "PLZ": "1542",
    "Zusatzziffer": "0",
    "Gemeindename": "Estavayer",
    "BFS-Nr": "2054",
    "Kantonskürzel": "FR",
    "E": "2560200.537999999",
    "N": "1189966.2089999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cheyres",
    "PLZ": "1468",
    "Zusatzziffer": "0",
    "Gemeindename": "Cheyres-Châbles",
    "BFS-Nr": "2055",
    "Kantonskürzel": "FR",
    "E": "2550277.5190000013",
    "N": "1184995.1550000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châbles FR",
    "PLZ": "1474",
    "Zusatzziffer": "0",
    "Gemeindename": "Cheyres-Châbles",
    "BFS-Nr": "2055",
    "Kantonskürzel": "FR",
    "E": "2551990.6840000004",
    "N": "1186310.5929999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Auboranges",
    "PLZ": "1673",
    "Zusatzziffer": "3",
    "Gemeindename": "Auboranges",
    "BFS-Nr": "2061",
    "Kantonskürzel": "FR",
    "E": "2551487.295000002",
    "N": "1159243.4659999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Billens",
    "PLZ": "1681",
    "Zusatzziffer": "0",
    "Gemeindename": "Billens-Hennens",
    "BFS-Nr": "2063",
    "Kantonskürzel": "FR",
    "E": "2558804.449000001",
    "N": "1171219.1860000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Hennens",
    "PLZ": "1681",
    "Zusatzziffer": "2",
    "Gemeindename": "Billens-Hennens",
    "BFS-Nr": "2063",
    "Kantonskürzel": "FR",
    "E": "2557610.5879999995",
    "N": "1170364.039999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chapelle (Glâne)",
    "PLZ": "1608",
    "Zusatzziffer": "4",
    "Gemeindename": "Chapelle (Glâne)",
    "BFS-Nr": "2066",
    "Kantonskürzel": "FR",
    "E": "2553682.3189999983",
    "N": "1159796.5989999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Châtelard-près-Romont",
    "PLZ": "1689",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Châtelard",
    "BFS-Nr": "2067",
    "Kantonskürzel": "FR",
    "E": "2564776.829999998",
    "N": "1169694.5080000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châtonnaye",
    "PLZ": "1553",
    "Zusatzziffer": "0",
    "Gemeindename": "Châtonnaye",
    "BFS-Nr": "2068",
    "Kantonskürzel": "FR",
    "E": "2561768.851",
    "N": "1178224.1739999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ecublens FR",
    "PLZ": "1673",
    "Zusatzziffer": "4",
    "Gemeindename": "Ecublens (FR)",
    "BFS-Nr": "2072",
    "Kantonskürzel": "FR",
    "E": "2551722.4329999983",
    "N": "1162120.8139999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grangettes-près-Romont",
    "PLZ": "1686",
    "Zusatzziffer": "0",
    "Gemeindename": "Grangettes",
    "BFS-Nr": "2079",
    "Kantonskürzel": "FR",
    "E": "2563736.7690000013",
    "N": "1169577.6530000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Massonnens",
    "PLZ": "1692",
    "Zusatzziffer": "0",
    "Gemeindename": "Massonnens",
    "BFS-Nr": "2086",
    "Kantonskürzel": "FR",
    "E": "2564419.4930000007",
    "N": "1172264.9039999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Berlens",
    "PLZ": "1680",
    "Zusatzziffer": "2",
    "Gemeindename": "Mézières (FR)",
    "BFS-Nr": "2087",
    "Kantonskürzel": "FR",
    "E": "2562873.7540000007",
    "N": "1171446.4340000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mézières FR",
    "PLZ": "1684",
    "Zusatzziffer": "0",
    "Gemeindename": "Mézières (FR)",
    "BFS-Nr": "2087",
    "Kantonskürzel": "FR",
    "E": "2560803.818",
    "N": "1169895.296",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montet (Glâne)",
    "PLZ": "1674",
    "Zusatzziffer": "2",
    "Gemeindename": "Montet (Glâne)",
    "BFS-Nr": "2089",
    "Kantonskürzel": "FR",
    "E": "2552175.8379999995",
    "N": "1166118.2509999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Romont FR",
    "PLZ": "1680",
    "Zusatzziffer": "0",
    "Gemeindename": "Romont (FR)",
    "BFS-Nr": "2096",
    "Kantonskürzel": "FR",
    "E": "2560194.594999999",
    "N": "1171708.949000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Promasens",
    "PLZ": "1673",
    "Zusatzziffer": "0",
    "Gemeindename": "Rue",
    "BFS-Nr": "2097",
    "Kantonskürzel": "FR",
    "E": "2552834.375",
    "N": "1161313.3740000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gillarens",
    "PLZ": "1673",
    "Zusatzziffer": "2",
    "Gemeindename": "Rue",
    "BFS-Nr": "2097",
    "Kantonskürzel": "FR",
    "E": "2553113.6350000016",
    "N": "1159763.3880000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rue",
    "PLZ": "1673",
    "Zusatzziffer": "5",
    "Gemeindename": "Rue",
    "BFS-Nr": "2097",
    "Kantonskürzel": "FR",
    "E": "2552791.9349999987",
    "N": "1163338.8500000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Blessens",
    "PLZ": "1675",
    "Zusatzziffer": "2",
    "Gemeindename": "Rue",
    "BFS-Nr": "2097",
    "Kantonskürzel": "FR",
    "E": "2553994.8060000017",
    "N": "1162344.7699999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-les-Forts",
    "PLZ": "1676",
    "Zusatzziffer": "0",
    "Gemeindename": "Siviriez",
    "BFS-Nr": "2099",
    "Kantonskürzel": "FR",
    "E": "2558590.414000001",
    "N": "1166867.7179999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prez-vers-Siviriez",
    "PLZ": "1677",
    "Zusatzziffer": "0",
    "Gemeindename": "Siviriez",
    "BFS-Nr": "2099",
    "Kantonskürzel": "FR",
    "E": "2556802.379999999",
    "N": "1165363.631000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Siviriez",
    "PLZ": "1678",
    "Zusatzziffer": "0",
    "Gemeindename": "Siviriez",
    "BFS-Nr": "2099",
    "Kantonskürzel": "FR",
    "E": "2557248.0639999993",
    "N": "1167554.7890000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villaraboud",
    "PLZ": "1679",
    "Zusatzziffer": "0",
    "Gemeindename": "Siviriez",
    "BFS-Nr": "2099",
    "Kantonskürzel": "FR",
    "E": "2559758.1209999993",
    "N": "1167658.0839999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ursy",
    "PLZ": "1670",
    "Zusatzziffer": "0",
    "Gemeindename": "Ursy",
    "BFS-Nr": "2102",
    "Kantonskürzel": "FR",
    "E": "2553715.6829999983",
    "N": "1165105.7930000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bionnens",
    "PLZ": "1670",
    "Zusatzziffer": "2",
    "Gemeindename": "Ursy",
    "BFS-Nr": "2102",
    "Kantonskürzel": "FR",
    "E": "2555343.5",
    "N": "1165034",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Esmonts",
    "PLZ": "1670",
    "Zusatzziffer": "3",
    "Gemeindename": "Ursy",
    "BFS-Nr": "2102",
    "Kantonskürzel": "FR",
    "E": "2554488.6039999984",
    "N": "1165819.7309999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuarmarens",
    "PLZ": "1674",
    "Zusatzziffer": "0",
    "Gemeindename": "Ursy",
    "BFS-Nr": "2102",
    "Kantonskürzel": "FR",
    "E": "2553167.789000001",
    "N": "1166150.3900000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Morlens",
    "PLZ": "1674",
    "Zusatzziffer": "3",
    "Gemeindename": "Ursy",
    "BFS-Nr": "2102",
    "Kantonskürzel": "FR",
    "E": "2553921",
    "N": "1167044.7479999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vauderens",
    "PLZ": "1675",
    "Zusatzziffer": "0",
    "Gemeindename": "Ursy",
    "BFS-Nr": "2102",
    "Kantonskürzel": "FR",
    "E": "2554728.0780000016",
    "N": "1163726.9589999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mossel",
    "PLZ": "1675",
    "Zusatzziffer": "3",
    "Gemeindename": "Ursy",
    "BFS-Nr": "2102",
    "Kantonskürzel": "FR",
    "E": "2555336.6180000007",
    "N": "1162296.8150000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villariaz",
    "PLZ": "1685",
    "Zusatzziffer": "0",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2561833.8759999983",
    "N": "1168299.1539999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Neirigue",
    "PLZ": "1686",
    "Zusatzziffer": "2",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2562976.688000001",
    "N": "1170410.2030000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuisternens-devant-Romont",
    "PLZ": "1687",
    "Zusatzziffer": "0",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2561046.607999999",
    "N": "1167345.7060000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Magne",
    "PLZ": "1687",
    "Zusatzziffer": "2",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2561237.6959999986",
    "N": "1164926.3570000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Estévenens",
    "PLZ": "1687",
    "Zusatzziffer": "3",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2563110.5199999996",
    "N": "1168761.3330000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sommentier",
    "PLZ": "1688",
    "Zusatzziffer": "0",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2559692.068",
    "N": "1165368.0489999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lieffrens",
    "PLZ": "1688",
    "Zusatzziffer": "2",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2558810.3839999996",
    "N": "1165192.2010000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Joux FR",
    "PLZ": "1697",
    "Zusatzziffer": "0",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2561742.636",
    "N": "1164331.4340000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Ecasseys",
    "PLZ": "1697",
    "Zusatzziffer": "2",
    "Gemeindename": "Vuisternens-devant-Romont",
    "BFS-Nr": "2113",
    "Kantonskürzel": "FR",
    "E": "2559456.1330000013",
    "N": "1163586.3330000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarsiviriaux",
    "PLZ": "1694",
    "Zusatzziffer": "0",
    "Gemeindename": "Villorsonnens",
    "BFS-Nr": "2114",
    "Kantonskürzel": "FR",
    "E": "2567351.7289999984",
    "N": "1172201.5859999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villargiroud",
    "PLZ": "1694",
    "Zusatzziffer": "2",
    "Gemeindename": "Villorsonnens",
    "BFS-Nr": "2114",
    "Kantonskürzel": "FR",
    "E": "2566553.965999998",
    "N": "1172652.6620000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Orsonnens",
    "PLZ": "1694",
    "Zusatzziffer": "3",
    "Gemeindename": "Villorsonnens",
    "BFS-Nr": "2114",
    "Kantonskürzel": "FR",
    "E": "2565997.8550000004",
    "N": "1174119.2060000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-sous-Orsonnens",
    "PLZ": "1694",
    "Zusatzziffer": "4",
    "Gemeindename": "Villorsonnens",
    "BFS-Nr": "2114",
    "Kantonskürzel": "FR",
    "E": "2565858.2349999994",
    "N": "1175282.6389999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Torny-le-Grand",
    "PLZ": "1748",
    "Zusatzziffer": "0",
    "Gemeindename": "Torny",
    "BFS-Nr": "2115",
    "Kantonskürzel": "FR",
    "E": "2564093.8729999997",
    "N": "1180047.004999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Middes",
    "PLZ": "1749",
    "Zusatzziffer": "0",
    "Gemeindename": "Torny",
    "BFS-Nr": "2115",
    "Kantonskürzel": "FR",
    "E": "2562626.2179999985",
    "N": "1179772.107999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villaz-St-Pierre",
    "PLZ": "1690",
    "Zusatzziffer": "0",
    "Gemeindename": "Villaz",
    "BFS-Nr": "2117",
    "Kantonskürzel": "FR",
    "E": "2563173.598000001",
    "N": "1174423.9959999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lussy FR",
    "PLZ": "1690",
    "Zusatzziffer": "2",
    "Gemeindename": "Villaz",
    "BFS-Nr": "2117",
    "Kantonskürzel": "FR",
    "E": "2562191.666000001",
    "N": "1173878.8330000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarimboud",
    "PLZ": "1691",
    "Zusatzziffer": "0",
    "Gemeindename": "Villaz",
    "BFS-Nr": "2117",
    "Kantonskürzel": "FR",
    "E": "2563637.131000001",
    "N": "1176587.289999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Albeuve",
    "PLZ": "1669",
    "Zusatzziffer": "0",
    "Gemeindename": "Haut-Intyamon",
    "BFS-Nr": "2121",
    "Kantonskürzel": "FR",
    "E": "2570634.9250000007",
    "N": "1151805.5190000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lessoc",
    "PLZ": "1669",
    "Zusatzziffer": "2",
    "Gemeindename": "Haut-Intyamon",
    "BFS-Nr": "2121",
    "Kantonskürzel": "FR",
    "E": "2571128.0689999983",
    "N": "1150553.699000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Sciernes-d'Albeuve",
    "PLZ": "1669",
    "Zusatzziffer": "3",
    "Gemeindename": "Haut-Intyamon",
    "BFS-Nr": "2121",
    "Kantonskürzel": "FR",
    "E": "2569019.267999999",
    "N": "1149587.9090000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Neirivue",
    "PLZ": "1669",
    "Zusatzziffer": "4",
    "Gemeindename": "Haut-Intyamon",
    "BFS-Nr": "2121",
    "Kantonskürzel": "FR",
    "E": "2570795.3440000005",
    "N": "1152733.4230000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montbovon",
    "PLZ": "1669",
    "Zusatzziffer": "5",
    "Gemeindename": "Haut-Intyamon",
    "BFS-Nr": "2121",
    "Kantonskürzel": "FR",
    "E": "2569829.4750000015",
    "N": "1148400.9730000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gumefens",
    "PLZ": "1643",
    "Zusatzziffer": "0",
    "Gemeindename": "Pont-en-Ogoz",
    "BFS-Nr": "2122",
    "Kantonskürzel": "FR",
    "E": "2572316.704999998",
    "N": "1169593.6909999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Avry-devant-Pont",
    "PLZ": "1644",
    "Zusatzziffer": "0",
    "Gemeindename": "Pont-en-Ogoz",
    "BFS-Nr": "2122",
    "Kantonskürzel": "FR",
    "E": "2573054.0709999986",
    "N": "1170726.1479999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Bry",
    "PLZ": "1645",
    "Zusatzziffer": "0",
    "Gemeindename": "Pont-en-Ogoz",
    "BFS-Nr": "2122",
    "Kantonskürzel": "FR",
    "E": "2572845.936999999",
    "N": "1172583.9070000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Botterens",
    "PLZ": "1652",
    "Zusatzziffer": "0",
    "Gemeindename": "Botterens",
    "BFS-Nr": "2123",
    "Kantonskürzel": "FR",
    "E": "2575052.778000001",
    "N": "1163389.756000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarbeney",
    "PLZ": "1652",
    "Zusatzziffer": "2",
    "Gemeindename": "Botterens",
    "BFS-Nr": "2123",
    "Kantonskürzel": "FR",
    "E": "2574730.506000001",
    "N": "1164258.9299999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Broc",
    "PLZ": "1636",
    "Zusatzziffer": "0",
    "Gemeindename": "Broc",
    "BFS-Nr": "2124",
    "Kantonskürzel": "FR",
    "E": "2574014.386",
    "N": "1161578.5780000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bulle",
    "PLZ": "1630",
    "Zusatzziffer": "0",
    "Gemeindename": "Bulle",
    "BFS-Nr": "2125",
    "Kantonskürzel": "FR",
    "E": "2570880.0599999987",
    "N": "1163295.4160000011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Tour-de-Trême",
    "PLZ": "1635",
    "Zusatzziffer": "0",
    "Gemeindename": "Bulle",
    "BFS-Nr": "2125",
    "Kantonskürzel": "FR",
    "E": "2571469.1460000016",
    "N": "1162047.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châtel-sur-Montsalvens",
    "PLZ": "1653",
    "Zusatzziffer": "3",
    "Gemeindename": "Châtel-sur-Montsalvens",
    "BFS-Nr": "2128",
    "Kantonskürzel": "FR",
    "E": "2576082.5810000002",
    "N": "1162531.232999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corbières",
    "PLZ": "1647",
    "Zusatzziffer": "0",
    "Gemeindename": "Corbières",
    "BFS-Nr": "2129",
    "Kantonskürzel": "FR",
    "E": "2574167.1050000004",
    "N": "1167849.3280000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarvolard",
    "PLZ": "1651",
    "Zusatzziffer": "0",
    "Gemeindename": "Corbières",
    "BFS-Nr": "2129",
    "Kantonskürzel": "FR",
    "E": "2574635.193",
    "N": "1166056.079",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Crésuz",
    "PLZ": "1653",
    "Zusatzziffer": "2",
    "Gemeindename": "Crésuz",
    "BFS-Nr": "2130",
    "Kantonskürzel": "FR",
    "E": "2577196.601",
    "N": "1163074.2159999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Echarlens",
    "PLZ": "1646",
    "Zusatzziffer": "0",
    "Gemeindename": "Echarlens",
    "BFS-Nr": "2131",
    "Kantonskürzel": "FR",
    "E": "2572207.818",
    "N": "1166356.6559999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandvillard",
    "PLZ": "1666",
    "Zusatzziffer": "0",
    "Gemeindename": "Grandvillard",
    "BFS-Nr": "2134",
    "Kantonskürzel": "FR",
    "E": "2572923.3830000013",
    "N": "1154228.3960000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gruyères",
    "PLZ": "1663",
    "Zusatzziffer": "0",
    "Gemeindename": "Gruyères",
    "BFS-Nr": "2135",
    "Kantonskürzel": "FR",
    "E": "2572769.022999998",
    "N": "1159202.5130000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pringy",
    "PLZ": "1663",
    "Zusatzziffer": "2",
    "Gemeindename": "Gruyères",
    "BFS-Nr": "2135",
    "Kantonskürzel": "FR",
    "E": "2571903.283",
    "N": "1158652.25",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Epagny",
    "PLZ": "1663",
    "Zusatzziffer": "3",
    "Gemeindename": "Gruyères",
    "BFS-Nr": "2135",
    "Kantonskürzel": "FR",
    "E": "2572934.829",
    "N": "1159710.800999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Moléson-sur-Gruyères",
    "PLZ": "1663",
    "Zusatzziffer": "4",
    "Gemeindename": "Gruyères",
    "BFS-Nr": "2135",
    "Kantonskürzel": "FR",
    "E": "2569229.2399999984",
    "N": "1156825.1700000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Hauteville",
    "PLZ": "1648",
    "Zusatzziffer": "0",
    "Gemeindename": "Hauteville",
    "BFS-Nr": "2137",
    "Kantonskürzel": "FR",
    "E": "2574338.1350000016",
    "N": "1168912.982999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Jaun",
    "PLZ": "1656",
    "Zusatzziffer": "0",
    "Gemeindename": "Jaun",
    "BFS-Nr": "2138",
    "Kantonskürzel": "FR",
    "E": "2587432.3429999985",
    "N": "1162250.0450000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Im Fang",
    "PLZ": "1656",
    "Zusatzziffer": "1",
    "Gemeindename": "Jaun",
    "BFS-Nr": "2138",
    "Kantonskürzel": "FR",
    "E": "2584493.425999999",
    "N": "1160777.193",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Marsens",
    "PLZ": "1633",
    "Zusatzziffer": "0",
    "Gemeindename": "Marsens",
    "BFS-Nr": "2140",
    "Kantonskürzel": "FR",
    "E": "2571159.66",
    "N": "1167239.4030000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuippens",
    "PLZ": "1633",
    "Zusatzziffer": "2",
    "Gemeindename": "Marsens",
    "BFS-Nr": "2140",
    "Kantonskürzel": "FR",
    "E": "2572009.375",
    "N": "1167578.5859999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Morlon",
    "PLZ": "1638",
    "Zusatzziffer": "0",
    "Gemeindename": "Morlon",
    "BFS-Nr": "2143",
    "Kantonskürzel": "FR",
    "E": "2573128.958999999",
    "N": "1164135.9789999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Pâquier-Montbarry",
    "PLZ": "1661",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Pâquier (FR)",
    "BFS-Nr": "2145",
    "Kantonskürzel": "FR",
    "E": "2570589.537999999",
    "N": "1160423.1039999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pont-la-Ville",
    "PLZ": "1649",
    "Zusatzziffer": "0",
    "Gemeindename": "Pont-la-Ville",
    "BFS-Nr": "2147",
    "Kantonskürzel": "FR",
    "E": "2574945.421",
    "N": "1172151.6530000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Riaz",
    "PLZ": "1632",
    "Zusatzziffer": "0",
    "Gemeindename": "Riaz",
    "BFS-Nr": "2148",
    "Kantonskürzel": "FR",
    "E": "2571098.9959999993",
    "N": "1165654.1389999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Roche FR",
    "PLZ": "1634",
    "Zusatzziffer": "0",
    "Gemeindename": "La Roche",
    "BFS-Nr": "2149",
    "Kantonskürzel": "FR",
    "E": "2577001.594999999",
    "N": "1171727.579",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sâles (Gruyère)",
    "PLZ": "1625",
    "Zusatzziffer": "0",
    "Gemeindename": "Sâles",
    "BFS-Nr": "2152",
    "Kantonskürzel": "FR",
    "E": "2564355.6499999985",
    "N": "1165036.039999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Maules",
    "PLZ": "1625",
    "Zusatzziffer": "2",
    "Gemeindename": "Sâles",
    "BFS-Nr": "2152",
    "Kantonskürzel": "FR",
    "E": "2565748.2600000016",
    "N": "1165367.7129999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Romanens",
    "PLZ": "1626",
    "Zusatzziffer": "0",
    "Gemeindename": "Sâles",
    "BFS-Nr": "2152",
    "Kantonskürzel": "FR",
    "E": "2564169.4580000006",
    "N": "1166720.6810000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Treyfayes",
    "PLZ": "1626",
    "Zusatzziffer": "2",
    "Gemeindename": "Sâles",
    "BFS-Nr": "2152",
    "Kantonskürzel": "FR",
    "E": "2562841.912999999",
    "N": "1167102.9310000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rueyres-Treyfayes",
    "PLZ": "1626",
    "Zusatzziffer": "3",
    "Gemeindename": "Sâles",
    "BFS-Nr": "2152",
    "Kantonskürzel": "FR",
    "E": "2563265.659000002",
    "N": "1166494.1790000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sorens",
    "PLZ": "1642",
    "Zusatzziffer": "0",
    "Gemeindename": "Sorens",
    "BFS-Nr": "2153",
    "Kantonskürzel": "FR",
    "E": "2571046.6959999986",
    "N": "1168702.6229999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vaulruz",
    "PLZ": "1627",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaulruz",
    "BFS-Nr": "2155",
    "Kantonskürzel": "FR",
    "E": "2565880.9510000013",
    "N": "1163716.1530000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuadens",
    "PLZ": "1628",
    "Zusatzziffer": "0",
    "Gemeindename": "Vuadens",
    "BFS-Nr": "2160",
    "Kantonskürzel": "FR",
    "E": "2567866.649",
    "N": "1162887.3509999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Estavannens",
    "PLZ": "1665",
    "Zusatzziffer": "0",
    "Gemeindename": "Bas-Intyamon",
    "BFS-Nr": "2162",
    "Kantonskürzel": "FR",
    "E": "2574166.2459999993",
    "N": "1157223.2109999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-sous-Mont",
    "PLZ": "1666",
    "Zusatzziffer": "2",
    "Gemeindename": "Bas-Intyamon",
    "BFS-Nr": "2162",
    "Kantonskürzel": "FR",
    "E": "2571681.3000000007",
    "N": "1154363.9200000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Enney",
    "PLZ": "1667",
    "Zusatzziffer": "0",
    "Gemeindename": "Bas-Intyamon",
    "BFS-Nr": "2162",
    "Kantonskürzel": "FR",
    "E": "2572777.603",
    "N": "1157282.8280000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Charmey (Gruyère)",
    "PLZ": "1637",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Charmey",
    "BFS-Nr": "2163",
    "Kantonskürzel": "FR",
    "E": "2578870.710000001",
    "N": "1163129.7529999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cerniat FR",
    "PLZ": "1654",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Charmey",
    "BFS-Nr": "2163",
    "Kantonskürzel": "FR",
    "E": "2578415.2349999994",
    "N": "1164638.5839999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Autigny",
    "PLZ": "1742",
    "Zusatzziffer": "0",
    "Gemeindename": "Autigny",
    "BFS-Nr": "2173",
    "Kantonskürzel": "FR",
    "E": "2568047.787999999",
    "N": "1176230.063000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corjolens",
    "PLZ": "1754",
    "Zusatzziffer": "2",
    "Gemeindename": "Avry",
    "BFS-Nr": "2174",
    "Kantonskürzel": "FR",
    "E": "2569982.851",
    "N": "1181682.9160000011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Avry-sur-Matran",
    "PLZ": "1754",
    "Zusatzziffer": "4",
    "Gemeindename": "Avry",
    "BFS-Nr": "2174",
    "Kantonskürzel": "FR",
    "E": "2571902.943",
    "N": "1181919.2850000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Belfaux",
    "PLZ": "1782",
    "Zusatzziffer": "0",
    "Gemeindename": "Belfaux",
    "BFS-Nr": "2175",
    "Kantonskürzel": "FR",
    "E": "2574688.958999999",
    "N": "1185748.5130000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Autafond",
    "PLZ": "1782",
    "Zusatzziffer": "2",
    "Gemeindename": "Belfaux",
    "BFS-Nr": "2175",
    "Kantonskürzel": "FR",
    "E": "2572466.1849999987",
    "N": "1184781.8029999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chénens",
    "PLZ": "1744",
    "Zusatzziffer": "0",
    "Gemeindename": "Chénens",
    "BFS-Nr": "2177",
    "Kantonskürzel": "FR",
    "E": "2566665.098000001",
    "N": "1176590.697999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corminboeuf",
    "PLZ": "1720",
    "Zusatzziffer": "0",
    "Gemeindename": "Corminboeuf",
    "BFS-Nr": "2183",
    "Kantonskürzel": "FR",
    "E": "2574330.1059999987",
    "N": "1184180.9459999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chésopelloz",
    "PLZ": "1720",
    "Zusatzziffer": "2",
    "Gemeindename": "Corminboeuf",
    "BFS-Nr": "2183",
    "Kantonskürzel": "FR",
    "E": "2572490.2490000017",
    "N": "1184060.5029999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cottens FR",
    "PLZ": "1741",
    "Zusatzziffer": "0",
    "Gemeindename": "Cottens (FR)",
    "BFS-Nr": "2186",
    "Kantonskürzel": "FR",
    "E": "2569005.579999998",
    "N": "1177796.4789999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ferpicloz",
    "PLZ": "1724",
    "Zusatzziffer": "3",
    "Gemeindename": "Ferpicloz",
    "BFS-Nr": "2194",
    "Kantonskürzel": "FR",
    "E": "2579055.7360000014",
    "N": "1177449.3409999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fribourg",
    "PLZ": "1700",
    "Zusatzziffer": "0",
    "Gemeindename": "Fribourg",
    "BFS-Nr": "2196",
    "Kantonskürzel": "FR",
    "E": "2578944.813000001",
    "N": "1183929.0500000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bourguillon",
    "PLZ": "1722",
    "Zusatzziffer": "0",
    "Gemeindename": "Fribourg",
    "BFS-Nr": "2196",
    "Kantonskürzel": "FR",
    "E": "2580023.473000001",
    "N": "1183236.9809999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Givisiez",
    "PLZ": "1762",
    "Zusatzziffer": "0",
    "Gemeindename": "Givisiez",
    "BFS-Nr": "2197",
    "Kantonskürzel": "FR",
    "E": "2576183.504999999",
    "N": "1184608.7690000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Granges-Paccot",
    "PLZ": "1763",
    "Zusatzziffer": "0",
    "Gemeindename": "Granges-Paccot",
    "BFS-Nr": "2198",
    "Kantonskürzel": "FR",
    "E": "2577327.7179999985",
    "N": "1186036.5940000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grolley",
    "PLZ": "1772",
    "Zusatzziffer": "0",
    "Gemeindename": "Grolley",
    "BFS-Nr": "2200",
    "Kantonskürzel": "FR",
    "E": "2571963.969999999",
    "N": "1187014.3530000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Marly",
    "PLZ": "1723",
    "Zusatzziffer": "0",
    "Gemeindename": "Marly",
    "BFS-Nr": "2206",
    "Kantonskürzel": "FR",
    "E": "2578636.7479999997",
    "N": "1180453.3949999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Matran",
    "PLZ": "1753",
    "Zusatzziffer": "0",
    "Gemeindename": "Matran",
    "BFS-Nr": "2208",
    "Kantonskürzel": "FR",
    "E": "2573859.3390000015",
    "N": "1181754.2589999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Neyruz FR",
    "PLZ": "1740",
    "Zusatzziffer": "0",
    "Gemeindename": "Neyruz (FR)",
    "BFS-Nr": "2211",
    "Kantonskürzel": "FR",
    "E": "2571475.465",
    "N": "1179703.4010000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pierrafortscha",
    "PLZ": "1723",
    "Zusatzziffer": "4",
    "Gemeindename": "Pierrafortscha",
    "BFS-Nr": "2216",
    "Kantonskürzel": "FR",
    "E": "2580682.0150000006",
    "N": "1181786.0480000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Nierlet-les-Bois",
    "PLZ": "1772",
    "Zusatzziffer": "1",
    "Gemeindename": "Ponthaux",
    "BFS-Nr": "2217",
    "Kantonskürzel": "FR",
    "E": "2570940.7459999993",
    "N": "1185319.631000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ponthaux",
    "PLZ": "1772",
    "Zusatzziffer": "2",
    "Gemeindename": "Ponthaux",
    "BFS-Nr": "2217",
    "Kantonskürzel": "FR",
    "E": "2569584.783",
    "N": "1185013.5799999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Mouret",
    "PLZ": "1724",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Mouret",
    "BFS-Nr": "2220",
    "Kantonskürzel": "FR",
    "E": "2580067.897999998",
    "N": "1177799.5089999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Essert FR",
    "PLZ": "1724",
    "Zusatzziffer": "2",
    "Gemeindename": "Le Mouret",
    "BFS-Nr": "2220",
    "Kantonskürzel": "FR",
    "E": "2579047.469999999",
    "N": "1176353.188000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montévraz",
    "PLZ": "1724",
    "Zusatzziffer": "4",
    "Gemeindename": "Le Mouret",
    "BFS-Nr": "2220",
    "Kantonskürzel": "FR",
    "E": "2579915.708999999",
    "N": "1175044.879999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Oberried FR",
    "PLZ": "1724",
    "Zusatzziffer": "5",
    "Gemeindename": "Le Mouret",
    "BFS-Nr": "2220",
    "Kantonskürzel": "FR",
    "E": "2580916.7989999987",
    "N": "1176069.6950000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Zénauva",
    "PLZ": "1724",
    "Zusatzziffer": "7",
    "Gemeindename": "Le Mouret",
    "BFS-Nr": "2220",
    "Kantonskürzel": "FR",
    "E": "2580384.0599999987",
    "N": "1176008.2380000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bonnefontaine",
    "PLZ": "1724",
    "Zusatzziffer": "8",
    "Gemeindename": "Le Mouret",
    "BFS-Nr": "2220",
    "Kantonskürzel": "FR",
    "E": "2581800.039999999",
    "N": "1176593.6279999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Treyvaux",
    "PLZ": "1733",
    "Zusatzziffer": "0",
    "Gemeindename": "Treyvaux",
    "BFS-Nr": "2226",
    "Kantonskürzel": "FR",
    "E": "2576985.9959999993",
    "N": "1175301.8150000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-sur-Glâne",
    "PLZ": "1752",
    "Zusatzziffer": "0",
    "Gemeindename": "Villars-sur-Glâne",
    "BFS-Nr": "2228",
    "Kantonskürzel": "FR",
    "E": "2575369.153000001",
    "N": "1182258.5949999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarsel-sur-Marly",
    "PLZ": "1723",
    "Zusatzziffer": "3",
    "Gemeindename": "Villarsel-sur-Marly",
    "BFS-Nr": "2230",
    "Kantonskürzel": "FR",
    "E": "2579706.114",
    "N": "1178840.5940000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Posieux",
    "PLZ": "1725",
    "Zusatzziffer": "0",
    "Gemeindename": "Hauterive (FR)",
    "BFS-Nr": "2233",
    "Kantonskürzel": "FR",
    "E": "2573745.912999999",
    "N": "1179073.789999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ecuvillens",
    "PLZ": "1730",
    "Zusatzziffer": "0",
    "Gemeindename": "Hauterive (FR)",
    "BFS-Nr": "2233",
    "Kantonskürzel": "FR",
    "E": "2573124.664999999",
    "N": "1178605.0639999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lentigny",
    "PLZ": "1745",
    "Zusatzziffer": "0",
    "Gemeindename": "La Brillaz",
    "BFS-Nr": "2234",
    "Kantonskürzel": "FR",
    "E": "2566730.978",
    "N": "1178858.8249999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Onnens FR",
    "PLZ": "1756",
    "Zusatzziffer": "0",
    "Gemeindename": "La Brillaz",
    "BFS-Nr": "2234",
    "Kantonskürzel": "FR",
    "E": "2569511.1689999998",
    "N": "1180595.7589999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lovens",
    "PLZ": "1756",
    "Zusatzziffer": "2",
    "Gemeindename": "La Brillaz",
    "BFS-Nr": "2234",
    "Kantonskürzel": "FR",
    "E": "2568031.897999998",
    "N": "1180274.5749999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lossy",
    "PLZ": "1782",
    "Zusatzziffer": "3",
    "Gemeindename": "La Sonnaz",
    "BFS-Nr": "2235",
    "Kantonskürzel": "FR",
    "E": "2575014.3139999993",
    "N": "1186935.8310000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Corbaz",
    "PLZ": "1782",
    "Zusatzziffer": "4",
    "Gemeindename": "La Sonnaz",
    "BFS-Nr": "2235",
    "Kantonskürzel": "FR",
    "E": "2575310.4400000013",
    "N": "1187618.3409999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cormagens",
    "PLZ": "1782",
    "Zusatzziffer": "5",
    "Gemeindename": "La Sonnaz",
    "BFS-Nr": "2235",
    "Kantonskürzel": "FR",
    "E": "2576841.583999999",
    "N": "1187074.4140000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Formangueires",
    "PLZ": "1782",
    "Zusatzziffer": "6",
    "Gemeindename": "La Sonnaz",
    "BFS-Nr": "2235",
    "Kantonskürzel": "FR",
    "E": "2575481.085999999",
    "N": "1186304.8409999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarlod",
    "PLZ": "1695",
    "Zusatzziffer": "0",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2568197.0599999987",
    "N": "1172749.1350000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rueyres-St-Laurent",
    "PLZ": "1695",
    "Zusatzziffer": "2",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2568960.291000001",
    "N": "1174109.7890000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Estavayer-le-Gibloux",
    "PLZ": "1695",
    "Zusatzziffer": "3",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2568389.6440000013",
    "N": "1174614.164999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarsel-le-Gibloux",
    "PLZ": "1695",
    "Zusatzziffer": "4",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2567781.102000002",
    "N": "1173740.118999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuisternens-en-Ogoz",
    "PLZ": "1696",
    "Zusatzziffer": "0",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2570517.4290000014",
    "N": "1173027.6510000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Farvagny-le-Grand",
    "PLZ": "1726",
    "Zusatzziffer": "0",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2571524.197999999",
    "N": "1174590.5909999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Farvagny-le-Petit",
    "PLZ": "1726",
    "Zusatzziffer": "2",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2572026.603",
    "N": "1175319.2250000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grenilles",
    "PLZ": "1726",
    "Zusatzziffer": "3",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2570131.995000001",
    "N": "1175216.9250000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Posat",
    "PLZ": "1726",
    "Zusatzziffer": "4",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2570893.267999999",
    "N": "1176380.4349999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corpataux",
    "PLZ": "1727",
    "Zusatzziffer": "0",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2573960.364",
    "N": "1177059.756000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Magnedens",
    "PLZ": "1727",
    "Zusatzziffer": "2",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2572689.506000001",
    "N": "1176827.3480000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rossens FR",
    "PLZ": "1728",
    "Zusatzziffer": "0",
    "Gemeindename": "Gibloux",
    "BFS-Nr": "2236",
    "Kantonskürzel": "FR",
    "E": "2574166.379999999",
    "N": "1174364.8740000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prez-vers-Noréaz",
    "PLZ": "1746",
    "Zusatzziffer": "0",
    "Gemeindename": "Prez",
    "BFS-Nr": "2237",
    "Kantonskürzel": "FR",
    "E": "2567657.045000002",
    "N": "1181682.754999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corserey",
    "PLZ": "1747",
    "Zusatzziffer": "0",
    "Gemeindename": "Prez",
    "BFS-Nr": "2237",
    "Kantonskürzel": "FR",
    "E": "2565956.795000002",
    "N": "1180359.7459999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Noréaz",
    "PLZ": "1757",
    "Zusatzziffer": "0",
    "Gemeindename": "Prez",
    "BFS-Nr": "2237",
    "Kantonskürzel": "FR",
    "E": "2568632.033",
    "N": "1183590.7250000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Senèdes",
    "PLZ": "1724",
    "Zusatzziffer": "6",
    "Gemeindename": "Bois-d'Amont",
    "BFS-Nr": "2238",
    "Kantonskürzel": "FR",
    "E": "2577379.91",
    "N": "1176843.4809999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ependes FR",
    "PLZ": "1731",
    "Zusatzziffer": "0",
    "Gemeindename": "Bois-d'Amont",
    "BFS-Nr": "2238",
    "Kantonskürzel": "FR",
    "E": "2577664.1799999997",
    "N": "1178189.2399999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arconciel",
    "PLZ": "1732",
    "Zusatzziffer": "0",
    "Gemeindename": "Bois-d'Amont",
    "BFS-Nr": "2238",
    "Kantonskürzel": "FR",
    "E": "2575883.5549999997",
    "N": "1177346.8550000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courgevaux",
    "PLZ": "1796",
    "Zusatzziffer": "0",
    "Gemeindename": "Courgevaux",
    "BFS-Nr": "2250",
    "Kantonskürzel": "FR",
    "E": "2575116.8839999996",
    "N": "1195188.5579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Villarepos",
    "PLZ": "1583",
    "Zusatzziffer": "0",
    "Gemeindename": "Courtepin",
    "BFS-Nr": "2254",
    "Kantonskürzel": "FR",
    "E": "2572084.4959999993",
    "N": "1192560.982999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pensier",
    "PLZ": "1783",
    "Zusatzziffer": "0",
    "Gemeindename": "Courtepin",
    "BFS-Nr": "2254",
    "Kantonskürzel": "FR",
    "E": "2576855.8729999997",
    "N": "1188169.574000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Barberêche",
    "PLZ": "1783",
    "Zusatzziffer": "4",
    "Gemeindename": "Courtepin",
    "BFS-Nr": "2254",
    "Kantonskürzel": "FR",
    "E": "2578849.8940000013",
    "N": "1189541.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtepin",
    "PLZ": "1784",
    "Zusatzziffer": "0",
    "Gemeindename": "Courtepin",
    "BFS-Nr": "2254",
    "Kantonskürzel": "FR",
    "E": "2576167.653000001",
    "N": "1190332.8949999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Wallenried",
    "PLZ": "1784",
    "Zusatzziffer": "3",
    "Gemeindename": "Courtepin",
    "BFS-Nr": "2254",
    "Kantonskürzel": "FR",
    "E": "2575361.511",
    "N": "1191544.063000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtaman",
    "PLZ": "1791",
    "Zusatzziffer": "0",
    "Gemeindename": "Courtepin",
    "BFS-Nr": "2254",
    "Kantonskürzel": "FR",
    "E": "2576499.8440000005",
    "N": "1191121.8880000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cressier FR",
    "PLZ": "1785",
    "Zusatzziffer": "0",
    "Gemeindename": "Cressier (FR)",
    "BFS-Nr": "2257",
    "Kantonskürzel": "FR",
    "E": "2577261.125",
    "N": "1194162.3249999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fräschels",
    "PLZ": "3284",
    "Zusatzziffer": "0",
    "Gemeindename": "Fräschels",
    "BFS-Nr": "2258",
    "Kantonskürzel": "FR",
    "E": "2582695.3110000007",
    "N": "1204952.1389999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Galmiz",
    "PLZ": "3285",
    "Zusatzziffer": "0",
    "Gemeindename": "Galmiz",
    "BFS-Nr": "2259",
    "Kantonskürzel": "FR",
    "E": "2578624.938000001",
    "N": "1199913.7479999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gempenach",
    "PLZ": "3215",
    "Zusatzziffer": "0",
    "Gemeindename": "Gempenach",
    "BFS-Nr": "2260",
    "Kantonskürzel": "FR",
    "E": "2581645.75",
    "N": "1198877.9910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Greng",
    "PLZ": "3280",
    "Zusatzziffer": "4",
    "Gemeindename": "Greng",
    "BFS-Nr": "2261",
    "Kantonskürzel": "FR",
    "E": "2573542.844999999",
    "N": "1195908.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Cordast",
    "PLZ": "1792",
    "Zusatzziffer": "0",
    "Gemeindename": "Gurmels",
    "BFS-Nr": "2262",
    "Kantonskürzel": "FR",
    "E": "2578237.699000001",
    "N": "1191226.2380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guschelmuth",
    "PLZ": "1792",
    "Zusatzziffer": "2",
    "Gemeindename": "Gurmels",
    "BFS-Nr": "2262",
    "Kantonskürzel": "FR",
    "E": "2576889.6149999984",
    "N": "1192208.245000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wallenbuch",
    "PLZ": "3206",
    "Zusatzziffer": "2",
    "Gemeindename": "Gurmels",
    "BFS-Nr": "2262",
    "Kantonskürzel": "FR",
    "E": "2583710.789999999",
    "N": "1197638.7840000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gurmels",
    "PLZ": "3212",
    "Zusatzziffer": "0",
    "Gemeindename": "Gurmels",
    "BFS-Nr": "2262",
    "Kantonskürzel": "FR",
    "E": "2579673.390999999",
    "N": "1193547.9090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleingurmels",
    "PLZ": "3212",
    "Zusatzziffer": "2",
    "Gemeindename": "Gurmels",
    "BFS-Nr": "2262",
    "Kantonskürzel": "FR",
    "E": "2580754.4239999987",
    "N": "1192717.5659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Liebistorf",
    "PLZ": "3213",
    "Zusatzziffer": "0",
    "Gemeindename": "Gurmels",
    "BFS-Nr": "2262",
    "Kantonskürzel": "FR",
    "E": "2581387.217",
    "N": "1195286.6479999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kerzers",
    "PLZ": "3210",
    "Zusatzziffer": "0",
    "Gemeindename": "Kerzers",
    "BFS-Nr": "2265",
    "Kantonskürzel": "FR",
    "E": "2581567.2080000006",
    "N": "1202815.072999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleinbösingen",
    "PLZ": "3213",
    "Zusatzziffer": "2",
    "Gemeindename": "Kleinbösingen",
    "BFS-Nr": "2266",
    "Kantonskürzel": "FR",
    "E": "2582205.4860000014",
    "N": "1193708.6180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meyriez",
    "PLZ": "3280",
    "Zusatzziffer": "2",
    "Gemeindename": "Meyriez",
    "BFS-Nr": "2271",
    "Kantonskürzel": "FR",
    "E": "2574901.25",
    "N": "1197149.5879999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Misery",
    "PLZ": "1721",
    "Zusatzziffer": "1",
    "Gemeindename": "Misery-Courtion",
    "BFS-Nr": "2272",
    "Kantonskürzel": "FR",
    "E": "2571822.375",
    "N": "1188980",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cormérod",
    "PLZ": "1721",
    "Zusatzziffer": "2",
    "Gemeindename": "Misery-Courtion",
    "BFS-Nr": "2272",
    "Kantonskürzel": "FR",
    "E": "2573307.1959999986",
    "N": "1190558.0700000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtion",
    "PLZ": "1721",
    "Zusatzziffer": "4",
    "Gemeindename": "Misery-Courtion",
    "BFS-Nr": "2272",
    "Kantonskürzel": "FR",
    "E": "2571838.1730000004",
    "N": "1189696.0439999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cournillens",
    "PLZ": "1721",
    "Zusatzziffer": "5",
    "Gemeindename": "Misery-Courtion",
    "BFS-Nr": "2272",
    "Kantonskürzel": "FR",
    "E": "2574315.1889999993",
    "N": "1189636.6600000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Muntelier",
    "PLZ": "3286",
    "Zusatzziffer": "0",
    "Gemeindename": "Muntelier",
    "BFS-Nr": "2274",
    "Kantonskürzel": "FR",
    "E": "2576042.8379999995",
    "N": "1198307.7589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jeuss",
    "PLZ": "1793",
    "Zusatzziffer": "0",
    "Gemeindename": "Murten",
    "BFS-Nr": "2275",
    "Kantonskürzel": "FR",
    "E": "2579079.6939999983",
    "N": "1194727.6149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Salvenach",
    "PLZ": "1794",
    "Zusatzziffer": "0",
    "Gemeindename": "Murten",
    "BFS-Nr": "2275",
    "Kantonskürzel": "FR",
    "E": "2578042.958999999",
    "N": "1195756.107999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Courlevon",
    "PLZ": "1795",
    "Zusatzziffer": "0",
    "Gemeindename": "Murten",
    "BFS-Nr": "2275",
    "Kantonskürzel": "FR",
    "E": "2575036.103",
    "N": "1193395.822999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büchslen",
    "PLZ": "3215",
    "Zusatzziffer": "2",
    "Gemeindename": "Murten",
    "BFS-Nr": "2275",
    "Kantonskürzel": "FR",
    "E": "2580283.1860000007",
    "N": "1199378.4640000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lurtigen",
    "PLZ": "3215",
    "Zusatzziffer": "3",
    "Gemeindename": "Murten",
    "BFS-Nr": "2275",
    "Kantonskürzel": "FR",
    "E": "2579672.784000002",
    "N": "1197576.443",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Murten",
    "PLZ": "3280",
    "Zusatzziffer": "0",
    "Gemeindename": "Murten",
    "BFS-Nr": "2275",
    "Kantonskürzel": "FR",
    "E": "2575665.787999999",
    "N": "1197530.5890000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ried b. Kerzers",
    "PLZ": "3216",
    "Zusatzziffer": "0",
    "Gemeindename": "Ried bei Kerzers",
    "BFS-Nr": "2276",
    "Kantonskürzel": "FR",
    "E": "2580780.0560000017",
    "N": "1200394.5210000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Agriswil",
    "PLZ": "3216",
    "Zusatzziffer": "2",
    "Gemeindename": "Ried bei Kerzers",
    "BFS-Nr": "2276",
    "Kantonskürzel": "FR",
    "E": "2581619.629999999",
    "N": "1200376.2780000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ulmiz",
    "PLZ": "3214",
    "Zusatzziffer": "0",
    "Gemeindename": "Ulmiz",
    "BFS-Nr": "2278",
    "Kantonskürzel": "FR",
    "E": "2581880.2349999994",
    "N": "1197971.1950000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sugiez",
    "PLZ": "1786",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Vully",
    "BFS-Nr": "2284",
    "Kantonskürzel": "FR",
    "E": "2575201.653999999",
    "N": "1201253.1389999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Môtier (Vully)",
    "PLZ": "1787",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Vully",
    "BFS-Nr": "2284",
    "Kantonskürzel": "FR",
    "E": "2572924.5199999996",
    "N": "1199770.039999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mur (Vully) FR",
    "PLZ": "1787",
    "Zusatzziffer": "1",
    "Gemeindename": "Mont-Vully",
    "BFS-Nr": "2284",
    "Kantonskürzel": "FR",
    "E": "2571429.971000001",
    "N": "1199341.7459999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Praz (Vully)",
    "PLZ": "1788",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Vully",
    "BFS-Nr": "2284",
    "Kantonskürzel": "FR",
    "E": "2574015.3940000013",
    "N": "1200269.7380000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lugnorre",
    "PLZ": "1789",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Vully",
    "BFS-Nr": "2284",
    "Kantonskürzel": "FR",
    "E": "2571953.535",
    "N": "1199981.982999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brünisried",
    "PLZ": "1719",
    "Zusatzziffer": "0",
    "Gemeindename": "Brünisried",
    "BFS-Nr": "2292",
    "Kantonskürzel": "FR",
    "E": "2587820.2430000007",
    "N": "1178800.5410000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Düdingen",
    "PLZ": "3186",
    "Zusatzziffer": "0",
    "Gemeindename": "Düdingen",
    "BFS-Nr": "2293",
    "Kantonskürzel": "FR",
    "E": "2581086.329",
    "N": "1188352.1149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Giffers",
    "PLZ": "1735",
    "Zusatzziffer": "0",
    "Gemeindename": "Giffers",
    "BFS-Nr": "2294",
    "Kantonskürzel": "FR",
    "E": "2582564.0469999984",
    "N": "1178910.0760000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bösingen",
    "PLZ": "3178",
    "Zusatzziffer": "0",
    "Gemeindename": "Bösingen",
    "BFS-Nr": "2295",
    "Kantonskürzel": "FR",
    "E": "2583977.265999999",
    "N": "1193698.0650000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heitenried",
    "PLZ": "1714",
    "Zusatzziffer": "0",
    "Gemeindename": "Heitenried",
    "BFS-Nr": "2296",
    "Kantonskürzel": "FR",
    "E": "2589320.6039999984",
    "N": "1186538.864",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Plaffeien",
    "PLZ": "1716",
    "Zusatzziffer": "0",
    "Gemeindename": "Plaffeien",
    "BFS-Nr": "2299",
    "Kantonskürzel": "FR",
    "E": "2588339.4580000006",
    "N": "1176798.6719999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberschrot",
    "PLZ": "1716",
    "Zusatzziffer": "2",
    "Gemeindename": "Plaffeien",
    "BFS-Nr": "2299",
    "Kantonskürzel": "FR",
    "E": "2587403.640999999",
    "N": "1176491.7540000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzsee",
    "PLZ": "1716",
    "Zusatzziffer": "3",
    "Gemeindename": "Plaffeien",
    "BFS-Nr": "2299",
    "Kantonskürzel": "FR",
    "E": "2588725.1669999994",
    "N": "1168817.7459999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zumholz",
    "PLZ": "1719",
    "Zusatzziffer": "2",
    "Gemeindename": "Plaffeien",
    "BFS-Nr": "2299",
    "Kantonskürzel": "FR",
    "E": "2588721.245000001",
    "N": "1178127.4420000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Plasselb",
    "PLZ": "1737",
    "Zusatzziffer": "0",
    "Gemeindename": "Plasselb",
    "BFS-Nr": "2300",
    "Kantonskürzel": "FR",
    "E": "2585730.7239999995",
    "N": "1176052.129999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rechthalten",
    "PLZ": "1718",
    "Zusatzziffer": "0",
    "Gemeindename": "Rechthalten",
    "BFS-Nr": "2301",
    "Kantonskürzel": "FR",
    "E": "2584889.034000002",
    "N": "1179702.7809999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Silvester",
    "PLZ": "1736",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Silvester",
    "BFS-Nr": "2303",
    "Kantonskürzel": "FR",
    "E": "2583645.916000001",
    "N": "1176676.4360000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Ursen",
    "PLZ": "1717",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Ursen",
    "BFS-Nr": "2304",
    "Kantonskürzel": "FR",
    "E": "2583402.7520000003",
    "N": "1182257.0500000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schmitten FR",
    "PLZ": "3185",
    "Zusatzziffer": "0",
    "Gemeindename": "Schmitten (FR)",
    "BFS-Nr": "2305",
    "Kantonskürzel": "FR",
    "E": "2585575.9640000015",
    "N": "1189484.5720000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tafers",
    "PLZ": "1712",
    "Zusatzziffer": "0",
    "Gemeindename": "Tafers",
    "BFS-Nr": "2306",
    "Kantonskürzel": "FR",
    "E": "2583131.607000001",
    "N": "1184942.6490000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Antoni",
    "PLZ": "1713",
    "Zusatzziffer": "0",
    "Gemeindename": "Tafers",
    "BFS-Nr": "2306",
    "Kantonskürzel": "FR",
    "E": "2586424.5210000016",
    "N": "1185693.3500000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alterswil FR",
    "PLZ": "1715",
    "Zusatzziffer": "0",
    "Gemeindename": "Tafers",
    "BFS-Nr": "2306",
    "Kantonskürzel": "FR",
    "E": "2586271.6039999984",
    "N": "1182727.2259999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tentlingen",
    "PLZ": "1734",
    "Zusatzziffer": "0",
    "Gemeindename": "Tentlingen",
    "BFS-Nr": "2307",
    "Kantonskürzel": "FR",
    "E": "2581596.129999999",
    "N": "1179945.107999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ueberstorf",
    "PLZ": "3182",
    "Zusatzziffer": "0",
    "Gemeindename": "Ueberstorf",
    "BFS-Nr": "2308",
    "Kantonskürzel": "FR",
    "E": "2590522.9880000018",
    "N": "1190489.313000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flamatt",
    "PLZ": "3175",
    "Zusatzziffer": "0",
    "Gemeindename": "Wünnewil-Flamatt",
    "BFS-Nr": "2309",
    "Kantonskürzel": "FR",
    "E": "2590199.267000001",
    "N": "1193257.9609999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wünnewil",
    "PLZ": "3184",
    "Zusatzziffer": "0",
    "Gemeindename": "Wünnewil-Flamatt",
    "BFS-Nr": "2309",
    "Kantonskürzel": "FR",
    "E": "2587542.9860000014",
    "N": "1191510.3940000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Attalens",
    "PLZ": "1616",
    "Zusatzziffer": "0",
    "Gemeindename": "Attalens",
    "BFS-Nr": "2321",
    "Kantonskürzel": "FR",
    "E": "2554762.4189999998",
    "N": "1151230.568",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Tatroz",
    "PLZ": "1617",
    "Zusatzziffer": "2",
    "Gemeindename": "Attalens",
    "BFS-Nr": "2321",
    "Kantonskürzel": "FR",
    "E": "2556297.9340000004",
    "N": "1152922.2109999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bossonnens",
    "PLZ": "1615",
    "Zusatzziffer": "0",
    "Gemeindename": "Bossonnens",
    "BFS-Nr": "2323",
    "Kantonskürzel": "FR",
    "E": "2554836.4389999993",
    "N": "1152448.1700000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châtel-St-Denis",
    "PLZ": "1618",
    "Zusatzziffer": "0",
    "Gemeindename": "Châtel-Saint-Denis",
    "BFS-Nr": "2325",
    "Kantonskürzel": "FR",
    "E": "2558755.5610000007",
    "N": "1153197.7289999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Paccots",
    "PLZ": "1619",
    "Zusatzziffer": "0",
    "Gemeindename": "Châtel-Saint-Denis",
    "BFS-Nr": "2325",
    "Kantonskürzel": "FR",
    "E": "2562052.4899999984",
    "N": "1152498.8409999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Granges (Veveyse)",
    "PLZ": "1614",
    "Zusatzziffer": "0",
    "Gemeindename": "Granges (Veveyse)",
    "BFS-Nr": "2328",
    "Kantonskürzel": "FR",
    "E": "2553394.9679999985",
    "N": "1152777.2309999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Remaufens",
    "PLZ": "1617",
    "Zusatzziffer": "0",
    "Gemeindename": "Remaufens",
    "BFS-Nr": "2333",
    "Kantonskürzel": "FR",
    "E": "2557034.5709999986",
    "N": "1153081.5190000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Martin FR",
    "PLZ": "1609",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Martin (FR)",
    "BFS-Nr": "2335",
    "Kantonskürzel": "FR",
    "E": "2556363.045000002",
    "N": "1158450.4580000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Besencens",
    "PLZ": "1609",
    "Zusatzziffer": "2",
    "Gemeindename": "Saint-Martin (FR)",
    "BFS-Nr": "2335",
    "Kantonskürzel": "FR",
    "E": "2556395.2080000006",
    "N": "1159719.739",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fiaugères",
    "PLZ": "1609",
    "Zusatzziffer": "3",
    "Gemeindename": "Saint-Martin (FR)",
    "BFS-Nr": "2335",
    "Kantonskürzel": "FR",
    "E": "2558044.3079999983",
    "N": "1159586.2230000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Semsales",
    "PLZ": "1623",
    "Zusatzziffer": "0",
    "Gemeindename": "Semsales",
    "BFS-Nr": "2336",
    "Kantonskürzel": "FR",
    "E": "2560831.6149999984",
    "N": "1158084.8310000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Porsel",
    "PLZ": "1699",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Flon",
    "BFS-Nr": "2337",
    "Kantonskürzel": "FR",
    "E": "2556121.034000002",
    "N": "1161105.4140000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pont (Veveyse)",
    "PLZ": "1699",
    "Zusatzziffer": "2",
    "Gemeindename": "Le Flon",
    "BFS-Nr": "2337",
    "Kantonskürzel": "FR",
    "E": "2555168.0890000015",
    "N": "1159944.995000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bouloz",
    "PLZ": "1699",
    "Zusatzziffer": "3",
    "Gemeindename": "Le Flon",
    "BFS-Nr": "2337",
    "Kantonskürzel": "FR",
    "E": "2557495.7129999995",
    "N": "1162411.7140000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Crêt-près-Semsales",
    "PLZ": "1611",
    "Zusatzziffer": "0",
    "Gemeindename": "La Verrerie",
    "BFS-Nr": "2338",
    "Kantonskürzel": "FR",
    "E": "2559507.693",
    "N": "1162101.5080000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Verrerie",
    "PLZ": "1624",
    "Zusatzziffer": "0",
    "Gemeindename": "La Verrerie",
    "BFS-Nr": "2338",
    "Kantonskürzel": "FR",
    "E": "2560359.7820000015",
    "N": "1159920.4899999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grattavache",
    "PLZ": "1624",
    "Zusatzziffer": "2",
    "Gemeindename": "La Verrerie",
    "BFS-Nr": "2338",
    "Kantonskürzel": "FR",
    "E": "2560202.1970000006",
    "N": "1160550.0069999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Progens",
    "PLZ": "1624",
    "Zusatzziffer": "3",
    "Gemeindename": "La Verrerie",
    "BFS-Nr": "2338",
    "Kantonskürzel": "FR",
    "E": "2561860.800999999",
    "N": "1160580.3969999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Progens",
    "PLZ": "1624",
    "Zusatzziffer": "3",
    "Gemeindename": "La Verrerie",
    "BFS-Nr": "2338",
    "Kantonskürzel": "FR",
    "E": "2559501.524",
    "N": "1159269.6510000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Egerkingen",
    "PLZ": "4622",
    "Zusatzziffer": "0",
    "Gemeindename": "Egerkingen",
    "BFS-Nr": "2401",
    "Kantonskürzel": "SO",
    "E": "2627037.739",
    "N": "1241764.5839999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Härkingen",
    "PLZ": "4624",
    "Zusatzziffer": "0",
    "Gemeindename": "Härkingen",
    "BFS-Nr": "2402",
    "Kantonskürzel": "SO",
    "E": "2629130.7250000015",
    "N": "1239578.3249999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kestenholz",
    "PLZ": "4703",
    "Zusatzziffer": "0",
    "Gemeindename": "Kestenholz",
    "BFS-Nr": "2403",
    "Kantonskürzel": "SO",
    "E": "2623869.4340000004",
    "N": "1236192.675999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuendorf",
    "PLZ": "4623",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuendorf",
    "BFS-Nr": "2404",
    "Kantonskürzel": "SO",
    "E": "2627077.960000001",
    "N": "1239023.8929999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederbuchsiten",
    "PLZ": "4626",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederbuchsiten",
    "BFS-Nr": "2405",
    "Kantonskürzel": "SO",
    "E": "2625244.3429999985",
    "N": "1238267",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberbuchsiten",
    "PLZ": "4625",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberbuchsiten",
    "BFS-Nr": "2406",
    "Kantonskürzel": "SO",
    "E": "2625268.289000001",
    "N": "1239989.574000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oensingen",
    "PLZ": "4702",
    "Zusatzziffer": "0",
    "Gemeindename": "Oensingen",
    "BFS-Nr": "2407",
    "Kantonskürzel": "SO",
    "E": "2621574.5659999996",
    "N": "1237417.7760000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wolfwil",
    "PLZ": "4628",
    "Zusatzziffer": "0",
    "Gemeindename": "Wolfwil",
    "BFS-Nr": "2408",
    "Kantonskürzel": "SO",
    "E": "2626849.8900000006",
    "N": "1235589.3040000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aedermannsdorf",
    "PLZ": "4714",
    "Zusatzziffer": "0",
    "Gemeindename": "Aedermannsdorf",
    "BFS-Nr": "2421",
    "Kantonskürzel": "SO",
    "E": "2612829.289000001",
    "N": "1239205.3889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Balsthal",
    "PLZ": "4710",
    "Zusatzziffer": "0",
    "Gemeindename": "Balsthal",
    "BFS-Nr": "2422",
    "Kantonskürzel": "SO",
    "E": "2619627.3260000013",
    "N": "1240149.8409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herbetswil",
    "PLZ": "4715",
    "Zusatzziffer": "0",
    "Gemeindename": "Herbetswil",
    "BFS-Nr": "2424",
    "Kantonskürzel": "SO",
    "E": "2611319.598000001",
    "N": "1238135.636",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Holderbank SO",
    "PLZ": "4718",
    "Zusatzziffer": "0",
    "Gemeindename": "Holderbank (SO)",
    "BFS-Nr": "2425",
    "Kantonskürzel": "SO",
    "E": "2623983.3960000016",
    "N": "1242382.3960000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Laupersdorf",
    "PLZ": "4712",
    "Zusatzziffer": "0",
    "Gemeindename": "Laupersdorf",
    "BFS-Nr": "2426",
    "Kantonskürzel": "SO",
    "E": "2615789.6559999995",
    "N": "1240505.614",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Matzendorf",
    "PLZ": "4713",
    "Zusatzziffer": "0",
    "Gemeindename": "Matzendorf",
    "BFS-Nr": "2427",
    "Kantonskürzel": "SO",
    "E": "2614153.2349999994",
    "N": "1239363.1339999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mümliswil",
    "PLZ": "4717",
    "Zusatzziffer": "0",
    "Gemeindename": "Mümliswil-Ramiswil",
    "BFS-Nr": "2428",
    "Kantonskürzel": "SO",
    "E": "2619384.425999999",
    "N": "1243400.4629999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ramiswil",
    "PLZ": "4719",
    "Zusatzziffer": "0",
    "Gemeindename": "Mümliswil-Ramiswil",
    "BFS-Nr": "2428",
    "Kantonskürzel": "SO",
    "E": "2616706.164000001",
    "N": "1244028.1130000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Welschenrohr",
    "PLZ": "4716",
    "Zusatzziffer": "0",
    "Gemeindename": "Welschenrohr-Gänsbrunnen",
    "BFS-Nr": "2430",
    "Kantonskürzel": "SO",
    "E": "2606012.414000001",
    "N": "1236562.9809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gänsbrunnen",
    "PLZ": "4716",
    "Zusatzziffer": "1",
    "Gemeindename": "Welschenrohr-Gänsbrunnen",
    "BFS-Nr": "2430",
    "Kantonskürzel": "SO",
    "E": "2602447.142000001",
    "N": "1234779.1440000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biezwil",
    "PLZ": "4585",
    "Zusatzziffer": "0",
    "Gemeindename": "Biezwil",
    "BFS-Nr": "2445",
    "Kantonskürzel": "SO",
    "E": "2598242.658",
    "N": "1218394.7780000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lüterkofen",
    "PLZ": "4571",
    "Zusatzziffer": "1",
    "Gemeindename": "Lüterkofen-Ichertswil",
    "BFS-Nr": "2455",
    "Kantonskürzel": "SO",
    "E": "2605381.473000001",
    "N": "1223817.1279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ichertswil",
    "PLZ": "4571",
    "Zusatzziffer": "2",
    "Gemeindename": "Lüterkofen-Ichertswil",
    "BFS-Nr": "2455",
    "Kantonskürzel": "SO",
    "E": "2604336.6799999997",
    "N": "1223301.6050000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lüterswil",
    "PLZ": "4584",
    "Zusatzziffer": "2",
    "Gemeindename": "Lüterswil-Gächliwil",
    "BFS-Nr": "2456",
    "Kantonskürzel": "SO",
    "E": "2599854.2140000015",
    "N": "1218743.7890000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gächliwil",
    "PLZ": "4584",
    "Zusatzziffer": "3",
    "Gemeindename": "Lüterswil-Gächliwil",
    "BFS-Nr": "2456",
    "Kantonskürzel": "SO",
    "E": "2600645.7060000002",
    "N": "1219808.9589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Messen",
    "PLZ": "3254",
    "Zusatzziffer": "0",
    "Gemeindename": "Messen",
    "BFS-Nr": "2457",
    "Kantonskürzel": "SO",
    "E": "2600765.8099999987",
    "N": "1215609.140999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Balm b. Messen",
    "PLZ": "3254",
    "Zusatzziffer": "2",
    "Gemeindename": "Messen",
    "BFS-Nr": "2457",
    "Kantonskürzel": "SO",
    "E": "2599462.3390000015",
    "N": "1216967.9420000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brunnenthal",
    "PLZ": "3307",
    "Zusatzziffer": "0",
    "Gemeindename": "Messen",
    "BFS-Nr": "2457",
    "Kantonskürzel": "SO",
    "E": "2602306.5749999993",
    "N": "1215050.495000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberramsern",
    "PLZ": "4588",
    "Zusatzziffer": "2",
    "Gemeindename": "Messen",
    "BFS-Nr": "2457",
    "Kantonskürzel": "SO",
    "E": "2602031.4180000015",
    "N": "1218081.9189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schnottwil",
    "PLZ": "3253",
    "Zusatzziffer": "0",
    "Gemeindename": "Schnottwil",
    "BFS-Nr": "2461",
    "Kantonskürzel": "SO",
    "E": "2596849.829",
    "N": "1217862.6429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterramsern",
    "PLZ": "4588",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterramsern",
    "BFS-Nr": "2463",
    "Kantonskürzel": "SO",
    "E": "2603341.9739999995",
    "N": "1218528.6510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nennigkofen",
    "PLZ": "4574",
    "Zusatzziffer": "0",
    "Gemeindename": "Lüsslingen-Nennigkofen",
    "BFS-Nr": "2464",
    "Kantonskürzel": "SO",
    "E": "2603872.9739999995",
    "N": "1226241.7109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lüsslingen",
    "PLZ": "4574",
    "Zusatzziffer": "1",
    "Gemeindename": "Lüsslingen-Nennigkofen",
    "BFS-Nr": "2464",
    "Kantonskürzel": "SO",
    "E": "2604698.1119999997",
    "N": "1226337.3090000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tscheppach",
    "PLZ": "4576",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2603343.539999999",
    "N": "1221968.3850000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hessigkofen",
    "PLZ": "4577",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2601606.710999999",
    "N": "1220801.5300000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bibern SO",
    "PLZ": "4578",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2601130.9250000007",
    "N": "1221909.7239999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gossliwil",
    "PLZ": "4579",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2599460.221000001",
    "N": "1220622.193",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Küttigkofen",
    "PLZ": "4581",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2605669.1939999983",
    "N": "1222277.6889999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brügglen",
    "PLZ": "4582",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2604316.920000002",
    "N": "1221419.9450000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühledorf SO",
    "PLZ": "4583",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2603012.8249999993",
    "N": "1220325.9730000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aetigkofen",
    "PLZ": "4583",
    "Zusatzziffer": "2",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2602128.7930000015",
    "N": "1219137.2159999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kyburg-Buchegg",
    "PLZ": "4586",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2605632.0579999983",
    "N": "1221157.7800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aetingen",
    "PLZ": "4587",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2605244.2060000002",
    "N": "1220111.0579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brittern",
    "PLZ": "4588",
    "Zusatzziffer": "1",
    "Gemeindename": "Buchegg",
    "BFS-Nr": "2465",
    "Kantonskürzel": "SO",
    "E": "2604260.7030000016",
    "N": "1218885.3399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bättwil",
    "PLZ": "4112",
    "Zusatzziffer": "1",
    "Gemeindename": "Bättwil",
    "BFS-Nr": "2471",
    "Kantonskürzel": "SO",
    "E": "2605376.4629999995",
    "N": "1260131.921",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büren SO",
    "PLZ": "4413",
    "Zusatzziffer": "0",
    "Gemeindename": "Büren (SO)",
    "BFS-Nr": "2472",
    "Kantonskürzel": "SO",
    "E": "2617495.780000001",
    "N": "1255162.1400000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dornach",
    "PLZ": "4143",
    "Zusatzziffer": "0",
    "Gemeindename": "Dornach",
    "BFS-Nr": "2473",
    "Kantonskürzel": "SO",
    "E": "2613421.655000001",
    "N": "1258672.0320000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gempen",
    "PLZ": "4145",
    "Zusatzziffer": "0",
    "Gemeindename": "Gempen",
    "BFS-Nr": "2474",
    "Kantonskürzel": "SO",
    "E": "2616360.504999999",
    "N": "1258368.4710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hochwald",
    "PLZ": "4146",
    "Zusatzziffer": "0",
    "Gemeindename": "Hochwald",
    "BFS-Nr": "2475",
    "Kantonskürzel": "SO",
    "E": "2615263.267999999",
    "N": "1256169.2950000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flüh",
    "PLZ": "4112",
    "Zusatzziffer": "2",
    "Gemeindename": "Hofstetten-Flüh",
    "BFS-Nr": "2476",
    "Kantonskürzel": "SO",
    "E": "2604522.011",
    "N": "1259196.1059999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hofstetten SO",
    "PLZ": "4114",
    "Zusatzziffer": "0",
    "Gemeindename": "Hofstetten-Flüh",
    "BFS-Nr": "2476",
    "Kantonskürzel": "SO",
    "E": "2605558.5439999998",
    "N": "1258503.9809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mariastein",
    "PLZ": "4115",
    "Zusatzziffer": "0",
    "Gemeindename": "Metzerlen-Mariastein",
    "BFS-Nr": "2477",
    "Kantonskürzel": "SO",
    "E": "2604014.4739999995",
    "N": "1258393.7580000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Metzerlen",
    "PLZ": "4116",
    "Zusatzziffer": "0",
    "Gemeindename": "Metzerlen-Mariastein",
    "BFS-Nr": "2477",
    "Kantonskürzel": "SO",
    "E": "2602058.8249999993",
    "N": "1257213.7060000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nuglar",
    "PLZ": "4412",
    "Zusatzziffer": "0",
    "Gemeindename": "Nuglar-St. Pantaleon",
    "BFS-Nr": "2478",
    "Kantonskürzel": "SO",
    "E": "2619069.7430000007",
    "N": "1257721.6860000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Pantaleon",
    "PLZ": "4421",
    "Zusatzziffer": "0",
    "Gemeindename": "Nuglar-St. Pantaleon",
    "BFS-Nr": "2478",
    "Kantonskürzel": "SO",
    "E": "2619017.397999998",
    "N": "1256570.4010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rodersdorf",
    "PLZ": "4118",
    "Zusatzziffer": "0",
    "Gemeindename": "Rodersdorf",
    "BFS-Nr": "2479",
    "Kantonskürzel": "SO",
    "E": "2601317.9849999994",
    "N": "1258932.5529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seewen SO",
    "PLZ": "4206",
    "Zusatzziffer": "0",
    "Gemeindename": "Seewen",
    "BFS-Nr": "2480",
    "Kantonskürzel": "SO",
    "E": "2616723.9120000005",
    "N": "1253782.5890000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Witterswil",
    "PLZ": "4108",
    "Zusatzziffer": "0",
    "Gemeindename": "Witterswil",
    "BFS-Nr": "2481",
    "Kantonskürzel": "SO",
    "E": "2606763.583999999",
    "N": "1259637.1279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hauenstein",
    "PLZ": "4633",
    "Zusatzziffer": "0",
    "Gemeindename": "Hauenstein-Ifenthal",
    "BFS-Nr": "2491",
    "Kantonskürzel": "SO",
    "E": "2632678.153999999",
    "N": "1247525.4420000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kienberg",
    "PLZ": "4468",
    "Zusatzziffer": "0",
    "Gemeindename": "Kienberg",
    "BFS-Nr": "2492",
    "Kantonskürzel": "SO",
    "E": "2639914.984000001",
    "N": "1254364.561999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lostorf",
    "PLZ": "4654",
    "Zusatzziffer": "0",
    "Gemeindename": "Lostorf",
    "BFS-Nr": "2493",
    "Kantonskürzel": "SO",
    "E": "2638744.1999999993",
    "N": "1248152.7800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niedergösgen",
    "PLZ": "5013",
    "Zusatzziffer": "0",
    "Gemeindename": "Niedergösgen",
    "BFS-Nr": "2495",
    "Kantonskürzel": "SO",
    "E": "2641498.8489999995",
    "N": "1247543.0579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obergösgen",
    "PLZ": "4653",
    "Zusatzziffer": "0",
    "Gemeindename": "Obergösgen",
    "BFS-Nr": "2497",
    "Kantonskürzel": "SO",
    "E": "2639071.2509999983",
    "N": "1245864.9549999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stüsslingen",
    "PLZ": "4655",
    "Zusatzziffer": "0",
    "Gemeindename": "Stüsslingen",
    "BFS-Nr": "2499",
    "Kantonskürzel": "SO",
    "E": "2640440.1160000004",
    "N": "1249410.4010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rohr b. Olten",
    "PLZ": "4655",
    "Zusatzziffer": "2",
    "Gemeindename": "Stüsslingen",
    "BFS-Nr": "2499",
    "Kantonskürzel": "SO",
    "E": "2639068.704",
    "N": "1251216.5650000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trimbach",
    "PLZ": "4632",
    "Zusatzziffer": "0",
    "Gemeindename": "Trimbach",
    "BFS-Nr": "2500",
    "Kantonskürzel": "SO",
    "E": "2634543.4510000013",
    "N": "1246389.0080000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winznau",
    "PLZ": "4652",
    "Zusatzziffer": "0",
    "Gemeindename": "Winznau",
    "BFS-Nr": "2501",
    "Kantonskürzel": "SO",
    "E": "2637127.083999999",
    "N": "1245973.1860000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wisen SO",
    "PLZ": "4634",
    "Zusatzziffer": "0",
    "Gemeindename": "Wisen (SO)",
    "BFS-Nr": "2502",
    "Kantonskürzel": "SO",
    "E": "2633876.870000001",
    "N": "1249216.3029999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erlinsbach SO",
    "PLZ": "5015",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlinsbach (SO)",
    "BFS-Nr": "2503",
    "Kantonskürzel": "SO",
    "E": "2642418.3949999996",
    "N": "1250136.704",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aeschi SO",
    "PLZ": "4556",
    "Zusatzziffer": "0",
    "Gemeindename": "Aeschi (SO)",
    "BFS-Nr": "2511",
    "Kantonskürzel": "SO",
    "E": "2616847.7690000013",
    "N": "1225641.7600000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Burgäschi",
    "PLZ": "4556",
    "Zusatzziffer": "3",
    "Gemeindename": "Aeschi (SO)",
    "BFS-Nr": "2511",
    "Kantonskürzel": "SO",
    "E": "2617622.914000001",
    "N": "1224664.9450000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinhof SO",
    "PLZ": "4556",
    "Zusatzziffer": "4",
    "Gemeindename": "Aeschi (SO)",
    "BFS-Nr": "2511",
    "Kantonskürzel": "SO",
    "E": "2618783.5249999985",
    "N": "1223161.3079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biberist",
    "PLZ": "4562",
    "Zusatzziffer": "0",
    "Gemeindename": "Biberist",
    "BFS-Nr": "2513",
    "Kantonskürzel": "SO",
    "E": "2608615.364",
    "N": "1225810.6700000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bolken",
    "PLZ": "4556",
    "Zusatzziffer": "2",
    "Gemeindename": "Bolken",
    "BFS-Nr": "2514",
    "Kantonskürzel": "SO",
    "E": "2617254.6739999987",
    "N": "1226730.329",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Deitingen",
    "PLZ": "4543",
    "Zusatzziffer": "0",
    "Gemeindename": "Deitingen",
    "BFS-Nr": "2516",
    "Kantonskürzel": "SO",
    "E": "2614235.037999999",
    "N": "1229510.5749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Derendingen",
    "PLZ": "4552",
    "Zusatzziffer": "0",
    "Gemeindename": "Derendingen",
    "BFS-Nr": "2517",
    "Kantonskürzel": "SO",
    "E": "2611209.140999999",
    "N": "1227101.5969999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Etziken",
    "PLZ": "4554",
    "Zusatzziffer": "0",
    "Gemeindename": "Etziken",
    "BFS-Nr": "2518",
    "Kantonskürzel": "SO",
    "E": "2615817.4789999984",
    "N": "1226513.1600000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gerlafingen",
    "PLZ": "4563",
    "Zusatzziffer": "0",
    "Gemeindename": "Gerlafingen",
    "BFS-Nr": "2519",
    "Kantonskürzel": "SO",
    "E": "2610086.2349999994",
    "N": "1224402.989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Halten",
    "PLZ": "4566",
    "Zusatzziffer": "2",
    "Gemeindename": "Halten",
    "BFS-Nr": "2520",
    "Kantonskürzel": "SO",
    "E": "2612863.6609999985",
    "N": "1224332.0879999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Horriwil",
    "PLZ": "4557",
    "Zusatzziffer": "0",
    "Gemeindename": "Horriwil",
    "BFS-Nr": "2523",
    "Kantonskürzel": "SO",
    "E": "2613965.0929999985",
    "N": "1225514.4750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hüniken",
    "PLZ": "4554",
    "Zusatzziffer": "2",
    "Gemeindename": "Hüniken",
    "BFS-Nr": "2524",
    "Kantonskürzel": "SO",
    "E": "2614943.7380000018",
    "N": "1225808.8729999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kriegstetten",
    "PLZ": "4566",
    "Zusatzziffer": "0",
    "Gemeindename": "Kriegstetten",
    "BFS-Nr": "2525",
    "Kantonskürzel": "SO",
    "E": "2611825.289000001",
    "N": "1224828.0579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lohn-Ammannsegg",
    "PLZ": "4573",
    "Zusatzziffer": "0",
    "Gemeindename": "Lohn-Ammannsegg",
    "BFS-Nr": "2526",
    "Kantonskürzel": "SO",
    "E": "2606730.7129999995",
    "N": "1224704.4690000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luterbach",
    "PLZ": "4542",
    "Zusatzziffer": "0",
    "Gemeindename": "Luterbach",
    "BFS-Nr": "2527",
    "Kantonskürzel": "SO",
    "E": "2610813.204999998",
    "N": "1229190.7259999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obergerlafingen",
    "PLZ": "4564",
    "Zusatzziffer": "0",
    "Gemeindename": "Obergerlafingen",
    "BFS-Nr": "2528",
    "Kantonskürzel": "SO",
    "E": "2611032.8599999994",
    "N": "1223345.8889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oekingen",
    "PLZ": "4566",
    "Zusatzziffer": "3",
    "Gemeindename": "Oekingen",
    "BFS-Nr": "2529",
    "Kantonskürzel": "SO",
    "E": "2612697.7380000018",
    "N": "1225606.7199999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Recherswil",
    "PLZ": "4565",
    "Zusatzziffer": "0",
    "Gemeindename": "Recherswil",
    "BFS-Nr": "2530",
    "Kantonskürzel": "SO",
    "E": "2612244.0309999995",
    "N": "1223288.7690000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Subingen",
    "PLZ": "4553",
    "Zusatzziffer": "0",
    "Gemeindename": "Subingen",
    "BFS-Nr": "2532",
    "Kantonskürzel": "SO",
    "E": "2613954.545000002",
    "N": "1227317.6779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zuchwil",
    "PLZ": "4528",
    "Zusatzziffer": "0",
    "Gemeindename": "Zuchwil",
    "BFS-Nr": "2534",
    "Kantonskürzel": "SO",
    "E": "2609387.6039999984",
    "N": "1228169.7509999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hersiwil",
    "PLZ": "4558",
    "Zusatzziffer": "0",
    "Gemeindename": "Drei Höfe",
    "BFS-Nr": "2535",
    "Kantonskürzel": "SO",
    "E": "2614871.6779999994",
    "N": "1223884.2950000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heinrichswil",
    "PLZ": "4558",
    "Zusatzziffer": "2",
    "Gemeindename": "Drei Höfe",
    "BFS-Nr": "2535",
    "Kantonskürzel": "SO",
    "E": "2614836.776999999",
    "N": "1222868.3889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winistorf",
    "PLZ": "4558",
    "Zusatzziffer": "3",
    "Gemeindename": "Drei Höfe",
    "BFS-Nr": "2535",
    "Kantonskürzel": "SO",
    "E": "2616071.2030000016",
    "N": "1222429.9770000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Balmberg",
    "PLZ": "4524",
    "Zusatzziffer": "2",
    "Gemeindename": "Balm bei Günsberg",
    "BFS-Nr": "2541",
    "Kantonskürzel": "SO",
    "E": "2608558.6259999983",
    "N": "1235460.414999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberbalmberg",
    "PLZ": "4524",
    "Zusatzziffer": "3",
    "Gemeindename": "Balm bei Günsberg",
    "BFS-Nr": "2541",
    "Kantonskürzel": "SO",
    "E": "2607619.9290000014",
    "N": "1234836.7679999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Balm b. Günsberg",
    "PLZ": "4525",
    "Zusatzziffer": "0",
    "Gemeindename": "Balm bei Günsberg",
    "BFS-Nr": "2541",
    "Kantonskürzel": "SO",
    "E": "2608738.7980000004",
    "N": "1233625.0260000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bellach",
    "PLZ": "4512",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellach",
    "BFS-Nr": "2542",
    "Kantonskürzel": "SO",
    "E": "2604723.857000001",
    "N": "1229024.6009999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bettlach",
    "PLZ": "2544",
    "Zusatzziffer": "0",
    "Gemeindename": "Bettlach",
    "BFS-Nr": "2543",
    "Kantonskürzel": "SO",
    "E": "2599324.2639999986",
    "N": "1228050.370000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Feldbrunnen",
    "PLZ": "4532",
    "Zusatzziffer": "0",
    "Gemeindename": "Feldbrunnen-St. Niklaus",
    "BFS-Nr": "2544",
    "Kantonskürzel": "SO",
    "E": "2608648.035",
    "N": "1230040.7380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flumenthal",
    "PLZ": "4534",
    "Zusatzziffer": "0",
    "Gemeindename": "Flumenthal",
    "BFS-Nr": "2545",
    "Kantonskürzel": "SO",
    "E": "2612335.4800000004",
    "N": "1231632.8409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grenchen",
    "PLZ": "2540",
    "Zusatzziffer": "0",
    "Gemeindename": "Grenchen",
    "BFS-Nr": "2546",
    "Kantonskürzel": "SO",
    "E": "2596672.579999998",
    "N": "1226875.1020000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Günsberg",
    "PLZ": "4524",
    "Zusatzziffer": "0",
    "Gemeindename": "Günsberg",
    "BFS-Nr": "2547",
    "Kantonskürzel": "SO",
    "E": "2610393.412999999",
    "N": "1234335.7589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hubersdorf",
    "PLZ": "4535",
    "Zusatzziffer": "0",
    "Gemeindename": "Hubersdorf",
    "BFS-Nr": "2548",
    "Kantonskürzel": "SO",
    "E": "2611367.914000001",
    "N": "1232662.614",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kammersrohr",
    "PLZ": "4535",
    "Zusatzziffer": "2",
    "Gemeindename": "Kammersrohr",
    "BFS-Nr": "2549",
    "Kantonskürzel": "SO",
    "E": "2611701.1460000016",
    "N": "1233572.0439999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langendorf",
    "PLZ": "4513",
    "Zusatzziffer": "0",
    "Gemeindename": "Langendorf",
    "BFS-Nr": "2550",
    "Kantonskürzel": "SO",
    "E": "2605750.5830000006",
    "N": "1230093.2829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lommiswil",
    "PLZ": "4514",
    "Zusatzziffer": "0",
    "Gemeindename": "Lommiswil",
    "BFS-Nr": "2551",
    "Kantonskürzel": "SO",
    "E": "2601894.5100000016",
    "N": "1230168.2129999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberdorf SO",
    "PLZ": "4515",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberdorf (SO)",
    "BFS-Nr": "2553",
    "Kantonskürzel": "SO",
    "E": "2604808.6259999983",
    "N": "1230759.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weissenstein b. Solothurn",
    "PLZ": "4515",
    "Zusatzziffer": "1",
    "Gemeindename": "Oberdorf (SO)",
    "BFS-Nr": "2553",
    "Kantonskürzel": "SO",
    "E": "2605098.914999999",
    "N": "1233347.2309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederwil SO",
    "PLZ": "4523",
    "Zusatzziffer": "0",
    "Gemeindename": "Riedholz",
    "BFS-Nr": "2554",
    "Kantonskürzel": "SO",
    "E": "2609943.4849999994",
    "N": "1233169.8049999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riedholz",
    "PLZ": "4533",
    "Zusatzziffer": "0",
    "Gemeindename": "Riedholz",
    "BFS-Nr": "2554",
    "Kantonskürzel": "SO",
    "E": "2609672.4750000015",
    "N": "1231438.2630000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüttenen",
    "PLZ": "4522",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüttenen",
    "BFS-Nr": "2555",
    "Kantonskürzel": "SO",
    "E": "2606928.142000001",
    "N": "1231064.0419999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Selzach",
    "PLZ": "2545",
    "Zusatzziffer": "0",
    "Gemeindename": "Selzach",
    "BFS-Nr": "2556",
    "Kantonskürzel": "SO",
    "E": "2601159.4409999996",
    "N": "1228348.4360000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Boningen",
    "PLZ": "4618",
    "Zusatzziffer": "0",
    "Gemeindename": "Boningen",
    "BFS-Nr": "2571",
    "Kantonskürzel": "SO",
    "E": "2631598.1380000003",
    "N": "1239773.504999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Däniken SO",
    "PLZ": "4658",
    "Zusatzziffer": "0",
    "Gemeindename": "Däniken",
    "BFS-Nr": "2572",
    "Kantonskürzel": "SO",
    "E": "2640608.1909999996",
    "N": "1244710.7639999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dulliken",
    "PLZ": "4657",
    "Zusatzziffer": "0",
    "Gemeindename": "Dulliken",
    "BFS-Nr": "2573",
    "Kantonskürzel": "SO",
    "E": "2638357.3150000013",
    "N": "1244236.7190000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wöschnau",
    "PLZ": "5012",
    "Zusatzziffer": "3",
    "Gemeindename": "Eppenberg-Wöschnau",
    "BFS-Nr": "2574",
    "Kantonskürzel": "SO",
    "E": "2644286.6889999993",
    "N": "1248241.7760000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eppenberg",
    "PLZ": "5012",
    "Zusatzziffer": "4",
    "Gemeindename": "Eppenberg-Wöschnau",
    "BFS-Nr": "2574",
    "Kantonskürzel": "SO",
    "E": "2644180.8079999983",
    "N": "1247510.4079999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fulenbach",
    "PLZ": "4629",
    "Zusatzziffer": "0",
    "Gemeindename": "Fulenbach",
    "BFS-Nr": "2575",
    "Kantonskürzel": "SO",
    "E": "2629714.8429999985",
    "N": "1236337.2190000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gretzenbach",
    "PLZ": "5014",
    "Zusatzziffer": "0",
    "Gemeindename": "Gretzenbach",
    "BFS-Nr": "2576",
    "Kantonskürzel": "SO",
    "E": "2642594.300999999",
    "N": "1245416.8999999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gunzgen",
    "PLZ": "4617",
    "Zusatzziffer": "0",
    "Gemeindename": "Gunzgen",
    "BFS-Nr": "2578",
    "Kantonskürzel": "SO",
    "E": "2629835.4860000014",
    "N": "1240612.1559999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hägendorf",
    "PLZ": "4614",
    "Zusatzziffer": "0",
    "Gemeindename": "Hägendorf",
    "BFS-Nr": "2579",
    "Kantonskürzel": "SO",
    "E": "2630085.1059999987",
    "N": "1243082.0749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Allerheiligenberg",
    "PLZ": "4615",
    "Zusatzziffer": "0",
    "Gemeindename": "Hägendorf",
    "BFS-Nr": "2579",
    "Kantonskürzel": "SO",
    "E": "2628428.1149999984",
    "N": "1244699.0909999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kappel SO",
    "PLZ": "4616",
    "Zusatzziffer": "0",
    "Gemeindename": "Kappel (SO)",
    "BFS-Nr": "2580",
    "Kantonskürzel": "SO",
    "E": "2631437.739",
    "N": "1241375.7760000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Olten",
    "PLZ": "4600",
    "Zusatzziffer": "0",
    "Gemeindename": "Olten",
    "BFS-Nr": "2581",
    "Kantonskürzel": "SO",
    "E": "2634756.7710000016",
    "N": "1244557.1330000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach SO",
    "PLZ": "4613",
    "Zusatzziffer": "0",
    "Gemeindename": "Rickenbach (SO)",
    "BFS-Nr": "2582",
    "Kantonskürzel": "SO",
    "E": "2631442.879999999",
    "N": "1243587.640999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönenwerd",
    "PLZ": "5012",
    "Zusatzziffer": "0",
    "Gemeindename": "Schönenwerd",
    "BFS-Nr": "2583",
    "Kantonskürzel": "SO",
    "E": "2643226.533",
    "N": "1246680.9560000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Starrkirch-Wil",
    "PLZ": "4656",
    "Zusatzziffer": "0",
    "Gemeindename": "Starrkirch-Wil",
    "BFS-Nr": "2584",
    "Kantonskürzel": "SO",
    "E": "2637085.670000002",
    "N": "1244755.5419999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walterswil SO",
    "PLZ": "5746",
    "Zusatzziffer": "0",
    "Gemeindename": "Walterswil (SO)",
    "BFS-Nr": "2585",
    "Kantonskürzel": "SO",
    "E": "2640292.1739999987",
    "N": "1242047.068",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wangen b. Olten",
    "PLZ": "4612",
    "Zusatzziffer": "0",
    "Gemeindename": "Wangen bei Olten",
    "BFS-Nr": "2586",
    "Kantonskürzel": "SO",
    "E": "2633156.574000001",
    "N": "1243627.239",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Solothurn",
    "PLZ": "4500",
    "Zusatzziffer": "0",
    "Gemeindename": "Solothurn",
    "BFS-Nr": "2601",
    "Kantonskürzel": "SO",
    "E": "2607659.6160000004",
    "N": "1228614.6510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bärschwil",
    "PLZ": "4252",
    "Zusatzziffer": "0",
    "Gemeindename": "Bärschwil",
    "BFS-Nr": "2611",
    "Kantonskürzel": "SO",
    "E": "2602602.3720000014",
    "N": "1248063.9609999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beinwil SO",
    "PLZ": "4229",
    "Zusatzziffer": "0",
    "Gemeindename": "Beinwil (SO)",
    "BFS-Nr": "2612",
    "Kantonskürzel": "SO",
    "E": "2611242.978",
    "N": "1245704.2219999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Breitenbach",
    "PLZ": "4226",
    "Zusatzziffer": "0",
    "Gemeindename": "Breitenbach",
    "BFS-Nr": "2613",
    "Kantonskürzel": "SO",
    "E": "2608480.0309999995",
    "N": "1250265.5980000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büsserach",
    "PLZ": "4227",
    "Zusatzziffer": "0",
    "Gemeindename": "Büsserach",
    "BFS-Nr": "2614",
    "Kantonskürzel": "SO",
    "E": "2608165.859000001",
    "N": "1248791.4129999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erschwil",
    "PLZ": "4228",
    "Zusatzziffer": "0",
    "Gemeindename": "Erschwil",
    "BFS-Nr": "2615",
    "Kantonskürzel": "SO",
    "E": "2607444.9239999987",
    "N": "1246914.864",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fehren",
    "PLZ": "4232",
    "Zusatzziffer": "0",
    "Gemeindename": "Fehren",
    "BFS-Nr": "2616",
    "Kantonskürzel": "SO",
    "E": "2610445.829999998",
    "N": "1249531.6950000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grindel",
    "PLZ": "4247",
    "Zusatzziffer": "0",
    "Gemeindename": "Grindel",
    "BFS-Nr": "2617",
    "Kantonskürzel": "SO",
    "E": "2604738.66",
    "N": "1247838.9569999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Himmelried",
    "PLZ": "4204",
    "Zusatzziffer": "0",
    "Gemeindename": "Himmelried",
    "BFS-Nr": "2618",
    "Kantonskürzel": "SO",
    "E": "2612064.465",
    "N": "1252384.454",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleinlützel",
    "PLZ": "4245",
    "Zusatzziffer": "0",
    "Gemeindename": "Kleinlützel",
    "BFS-Nr": "2619",
    "Kantonskürzel": "SO",
    "E": "2598441.670000002",
    "N": "1252606.9560000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meltingen",
    "PLZ": "4233",
    "Zusatzziffer": "0",
    "Gemeindename": "Meltingen",
    "BFS-Nr": "2620",
    "Kantonskürzel": "SO",
    "E": "2611287.41",
    "N": "1248415.1510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nunningen",
    "PLZ": "4208",
    "Zusatzziffer": "0",
    "Gemeindename": "Nunningen",
    "BFS-Nr": "2621",
    "Kantonskürzel": "SO",
    "E": "2613637.0610000007",
    "N": "1249190.4439999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zullwil",
    "PLZ": "4234",
    "Zusatzziffer": "0",
    "Gemeindename": "Zullwil",
    "BFS-Nr": "2622",
    "Kantonskürzel": "SO",
    "E": "2612172.1790000014",
    "N": "1248937.609000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4001",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2611308.8495000005",
    "N": "1267305.821372061",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4031",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2610365.023499999",
    "N": "1267984.082549672",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4031",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2610853.3499999996",
    "N": "1267927.1153629953",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4051",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2611145.7872306295",
    "N": "1267063.1049999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4052",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2611866.152999999",
    "N": "1263537.7285255822",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4052",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2612708.0989999995",
    "N": "1266308.217898595",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4053",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2612226.9200122748",
    "N": "1265301.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4054",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2609809.8482692093",
    "N": "1266649.7149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4055",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2609432.7605836727",
    "N": "1268120.5724999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4056",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2610152.0134999994",
    "N": "1268765.2219559525",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4057",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2612116.2293009",
    "N": "1269497.9189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4058",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2613158.6697818534",
    "N": "1268570.8504999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basel",
    "PLZ": "4059",
    "Zusatzziffer": "0",
    "Gemeindename": "Basel",
    "BFS-Nr": "2701",
    "Kantonskürzel": "BS",
    "E": "2611656.0885000005",
    "N": "1264552.684071823",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bettingen",
    "PLZ": "4126",
    "Zusatzziffer": "0",
    "Gemeindename": "Bettingen",
    "BFS-Nr": "2702",
    "Kantonskürzel": "BS",
    "E": "2617031.9679999985",
    "N": "1268941.754999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riehen",
    "PLZ": "4125",
    "Zusatzziffer": "0",
    "Gemeindename": "Riehen",
    "BFS-Nr": "2703",
    "Kantonskürzel": "BS",
    "E": "2615842.664999999",
    "N": "1270467.3630000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aesch BL",
    "PLZ": "4147",
    "Zusatzziffer": "0",
    "Gemeindename": "Aesch (BL)",
    "BFS-Nr": "2761",
    "Kantonskürzel": "BL",
    "E": "2611908.914999999",
    "N": "1257353.6689999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Allschwil",
    "PLZ": "4123",
    "Zusatzziffer": "0",
    "Gemeindename": "Allschwil",
    "BFS-Nr": "2762",
    "Kantonskürzel": "BL",
    "E": "2607303.2430000007",
    "N": "1266660.2479999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arlesheim",
    "PLZ": "4144",
    "Zusatzziffer": "0",
    "Gemeindename": "Arlesheim",
    "BFS-Nr": "2763",
    "Kantonskürzel": "BL",
    "E": "2614177.125",
    "N": "1260497.4589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biel-Benken BL",
    "PLZ": "4105",
    "Zusatzziffer": "0",
    "Gemeindename": "Biel-Benken",
    "BFS-Nr": "2764",
    "Kantonskürzel": "BL",
    "E": "2606077.4109999985",
    "N": "1261427.5100000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bruderholz",
    "PLZ": "4101",
    "Zusatzziffer": "0",
    "Gemeindename": "Binningen",
    "BFS-Nr": "2765",
    "Kantonskürzel": "BL",
    "E": "2610804.278999999",
    "N": "1264214.5650000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Binningen",
    "PLZ": "4102",
    "Zusatzziffer": "0",
    "Gemeindename": "Binningen",
    "BFS-Nr": "2765",
    "Kantonskürzel": "BL",
    "E": "2610457.226",
    "N": "1265390.4250000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birsfelden",
    "PLZ": "4127",
    "Zusatzziffer": "0",
    "Gemeindename": "Birsfelden",
    "BFS-Nr": "2766",
    "Kantonskürzel": "BL",
    "E": "2614070.4789999984",
    "N": "1267209.1030000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bottmingen",
    "PLZ": "4103",
    "Zusatzziffer": "0",
    "Gemeindename": "Bottmingen",
    "BFS-Nr": "2767",
    "Kantonskürzel": "BL",
    "E": "2610229.596000001",
    "N": "1263900.0549999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ettingen",
    "PLZ": "4107",
    "Zusatzziffer": "0",
    "Gemeindename": "Ettingen",
    "BFS-Nr": "2768",
    "Kantonskürzel": "BL",
    "E": "2607727.3200000003",
    "N": "1258852.3220000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münchenstein",
    "PLZ": "4142",
    "Zusatzziffer": "0",
    "Gemeindename": "Münchenstein",
    "BFS-Nr": "2769",
    "Kantonskürzel": "BL",
    "E": "2613779.9880000018",
    "N": "1262565.386",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Muttenz",
    "PLZ": "4132",
    "Zusatzziffer": "0",
    "Gemeindename": "Muttenz",
    "BFS-Nr": "2770",
    "Kantonskürzel": "BL",
    "E": "2616256.8110000007",
    "N": "1263384.3759999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwil BL",
    "PLZ": "4104",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberwil (BL)",
    "BFS-Nr": "2771",
    "Kantonskürzel": "BL",
    "E": "2608691.897999998",
    "N": "1262555.7069999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfeffingen",
    "PLZ": "4148",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfeffingen",
    "BFS-Nr": "2772",
    "Kantonskürzel": "BL",
    "E": "2611334.7140000015",
    "N": "1256533.0940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reinach BL",
    "PLZ": "4153",
    "Zusatzziffer": "0",
    "Gemeindename": "Reinach (BL)",
    "BFS-Nr": "2773",
    "Kantonskürzel": "BL",
    "E": "2611444.8999999985",
    "N": "1260221.4230000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönenbuch",
    "PLZ": "4124",
    "Zusatzziffer": "0",
    "Gemeindename": "Schönenbuch",
    "BFS-Nr": "2774",
    "Kantonskürzel": "BL",
    "E": "2605065.1449999996",
    "N": "1264903.1840000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Therwil",
    "PLZ": "4106",
    "Zusatzziffer": "0",
    "Gemeindename": "Therwil",
    "BFS-Nr": "2775",
    "Kantonskürzel": "BL",
    "E": "2608668.0879999995",
    "N": "1260725.6889999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Blauen",
    "PLZ": "4223",
    "Zusatzziffer": "0",
    "Gemeindename": "Blauen",
    "BFS-Nr": "2781",
    "Kantonskürzel": "BL",
    "E": "2605994.1779999994",
    "N": "1255555.9239999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brislach",
    "PLZ": "4225",
    "Zusatzziffer": "0",
    "Gemeindename": "Brislach",
    "BFS-Nr": "2782",
    "Kantonskürzel": "BL",
    "E": "2607925.5940000005",
    "N": "1251921.5480000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Burg im Leimental",
    "PLZ": "4117",
    "Zusatzziffer": "0",
    "Gemeindename": "Burg im Leimental",
    "BFS-Nr": "2783",
    "Kantonskürzel": "BL",
    "E": "2600065.732999999",
    "N": "1256143.0430000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dittingen",
    "PLZ": "4243",
    "Zusatzziffer": "0",
    "Gemeindename": "Dittingen",
    "BFS-Nr": "2784",
    "Kantonskürzel": "BL",
    "E": "2604350.3260000013",
    "N": "1254595.8049999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Duggingen",
    "PLZ": "4202",
    "Zusatzziffer": "0",
    "Gemeindename": "Duggingen",
    "BFS-Nr": "2785",
    "Kantonskürzel": "BL",
    "E": "2612650.846000001",
    "N": "1255737.4200000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grellingen",
    "PLZ": "4203",
    "Zusatzziffer": "0",
    "Gemeindename": "Grellingen",
    "BFS-Nr": "2786",
    "Kantonskürzel": "BL",
    "E": "2611236.9120000005",
    "N": "1254552.7049999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Laufen",
    "PLZ": "4242",
    "Zusatzziffer": "0",
    "Gemeindename": "Laufen",
    "BFS-Nr": "2787",
    "Kantonskürzel": "BL",
    "E": "2604512.8029999994",
    "N": "1252258.234000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Liesberg",
    "PLZ": "4253",
    "Zusatzziffer": "0",
    "Gemeindename": "Liesberg",
    "BFS-Nr": "2788",
    "Kantonskürzel": "BL",
    "E": "2598728.219999999",
    "N": "1249571.3729999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Liesberg Dorf",
    "PLZ": "4254",
    "Zusatzziffer": "0",
    "Gemeindename": "Liesberg",
    "BFS-Nr": "2788",
    "Kantonskürzel": "BL",
    "E": "2599289.728",
    "N": "1250394.9519999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nenzlingen",
    "PLZ": "4224",
    "Zusatzziffer": "0",
    "Gemeindename": "Nenzlingen",
    "BFS-Nr": "2789",
    "Kantonskürzel": "BL",
    "E": "2609433.8090000004",
    "N": "1255261.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Roggenburg",
    "PLZ": "2814",
    "Zusatzziffer": "0",
    "Gemeindename": "Roggenburg",
    "BFS-Nr": "2790",
    "Kantonskürzel": "BL",
    "E": "2592662.9340000004",
    "N": "1253587.7089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Röschenz",
    "PLZ": "4244",
    "Zusatzziffer": "0",
    "Gemeindename": "Röschenz",
    "BFS-Nr": "2791",
    "Kantonskürzel": "BL",
    "E": "2602543.976",
    "N": "1253000.1030000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wahlen b. Laufen",
    "PLZ": "4246",
    "Zusatzziffer": "0",
    "Gemeindename": "Wahlen",
    "BFS-Nr": "2792",
    "Kantonskürzel": "BL",
    "E": "2605849.9629999995",
    "N": "1250182.8330000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zwingen",
    "PLZ": "4222",
    "Zusatzziffer": "0",
    "Gemeindename": "Zwingen",
    "BFS-Nr": "2793",
    "Kantonskürzel": "BL",
    "E": "2606868.932",
    "N": "1254076.8139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arisdorf",
    "PLZ": "4422",
    "Zusatzziffer": "0",
    "Gemeindename": "Arisdorf",
    "BFS-Nr": "2821",
    "Kantonskürzel": "BL",
    "E": "2624728.6860000007",
    "N": "1262276.8929999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Augst BL",
    "PLZ": "4302",
    "Zusatzziffer": "0",
    "Gemeindename": "Augst",
    "BFS-Nr": "2822",
    "Kantonskürzel": "BL",
    "E": "2621187.528000001",
    "N": "1264676.2459999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bubendorf",
    "PLZ": "4416",
    "Zusatzziffer": "0",
    "Gemeindename": "Bubendorf",
    "BFS-Nr": "2823",
    "Kantonskürzel": "BL",
    "E": "2622325.9349999987",
    "N": "1255389.9670000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frenkendorf",
    "PLZ": "4402",
    "Zusatzziffer": "0",
    "Gemeindename": "Frenkendorf",
    "BFS-Nr": "2824",
    "Kantonskürzel": "BL",
    "E": "2620201.5590000004",
    "N": "1261267.8280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Füllinsdorf",
    "PLZ": "4414",
    "Zusatzziffer": "0",
    "Gemeindename": "Füllinsdorf",
    "BFS-Nr": "2825",
    "Kantonskürzel": "BL",
    "E": "2621855.517000001",
    "N": "1261908.2109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Giebenach",
    "PLZ": "4304",
    "Zusatzziffer": "0",
    "Gemeindename": "Giebenach",
    "BFS-Nr": "2826",
    "Kantonskürzel": "BL",
    "E": "2622945.289999999",
    "N": "1263684.0119999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hersberg",
    "PLZ": "4423",
    "Zusatzziffer": "0",
    "Gemeindename": "Hersberg",
    "BFS-Nr": "2827",
    "Kantonskürzel": "BL",
    "E": "2625875.1779999994",
    "N": "1260127.204",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lausen",
    "PLZ": "4415",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausen",
    "BFS-Nr": "2828",
    "Kantonskürzel": "BL",
    "E": "2624195.186999999",
    "N": "1257778.1689999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Liestal",
    "PLZ": "4410",
    "Zusatzziffer": "0",
    "Gemeindename": "Liestal",
    "BFS-Nr": "2829",
    "Kantonskürzel": "BL",
    "E": "2622325.3599999994",
    "N": "1259378.739",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lupsingen",
    "PLZ": "4419",
    "Zusatzziffer": "0",
    "Gemeindename": "Lupsingen",
    "BFS-Nr": "2830",
    "Kantonskürzel": "BL",
    "E": "2619263.561999999",
    "N": "1255032.3610000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pratteln",
    "PLZ": "4133",
    "Zusatzziffer": "0",
    "Gemeindename": "Pratteln",
    "BFS-Nr": "2831",
    "Kantonskürzel": "BL",
    "E": "2618790.1739999987",
    "N": "1262799.0450000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ramlinsburg",
    "PLZ": "4433",
    "Zusatzziffer": "0",
    "Gemeindename": "Ramlinsburg",
    "BFS-Nr": "2832",
    "Kantonskürzel": "BL",
    "E": "2624959.4560000002",
    "N": "1255424.3209999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seltisberg",
    "PLZ": "4411",
    "Zusatzziffer": "0",
    "Gemeindename": "Seltisberg",
    "BFS-Nr": "2833",
    "Kantonskürzel": "BL",
    "E": "2620856.511",
    "N": "1256520.4910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ziefen",
    "PLZ": "4417",
    "Zusatzziffer": "0",
    "Gemeindename": "Ziefen",
    "BFS-Nr": "2834",
    "Kantonskürzel": "BL",
    "E": "2620120.3200000003",
    "N": "1253194.2360000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Anwil",
    "PLZ": "4469",
    "Zusatzziffer": "0",
    "Gemeindename": "Anwil",
    "BFS-Nr": "2841",
    "Kantonskürzel": "BL",
    "E": "2637803.1499999985",
    "N": "1255739.6499999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Böckten",
    "PLZ": "4461",
    "Zusatzziffer": "0",
    "Gemeindename": "Böckten",
    "BFS-Nr": "2842",
    "Kantonskürzel": "BL",
    "E": "2629707.796",
    "N": "1257254.4710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buckten",
    "PLZ": "4446",
    "Zusatzziffer": "0",
    "Gemeindename": "Buckten",
    "BFS-Nr": "2843",
    "Kantonskürzel": "BL",
    "E": "2630645.1779999994",
    "N": "1251122.3740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buus",
    "PLZ": "4463",
    "Zusatzziffer": "0",
    "Gemeindename": "Buus",
    "BFS-Nr": "2844",
    "Kantonskürzel": "BL",
    "E": "2632216.274",
    "N": "1261767.5500000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diepflingen",
    "PLZ": "4442",
    "Zusatzziffer": "0",
    "Gemeindename": "Diepflingen",
    "BFS-Nr": "2845",
    "Kantonskürzel": "BL",
    "E": "2630003.7179999985",
    "N": "1254925.1110000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gelterkinden",
    "PLZ": "4460",
    "Zusatzziffer": "0",
    "Gemeindename": "Gelterkinden",
    "BFS-Nr": "2846",
    "Kantonskürzel": "BL",
    "E": "2631680.723000001",
    "N": "1257135.107999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Häfelfingen",
    "PLZ": "4445",
    "Zusatzziffer": "0",
    "Gemeindename": "Häfelfingen",
    "BFS-Nr": "2847",
    "Kantonskürzel": "BL",
    "E": "2632256.2569999993",
    "N": "1251668.0130000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hemmiken",
    "PLZ": "4465",
    "Zusatzziffer": "0",
    "Gemeindename": "Hemmiken",
    "BFS-Nr": "2848",
    "Kantonskürzel": "BL",
    "E": "2634239.438000001",
    "N": "1259836.870000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Itingen",
    "PLZ": "4452",
    "Zusatzziffer": "0",
    "Gemeindename": "Itingen",
    "BFS-Nr": "2849",
    "Kantonskürzel": "BL",
    "E": "2625918.1959999986",
    "N": "1257257.3949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Känerkinden",
    "PLZ": "4447",
    "Zusatzziffer": "0",
    "Gemeindename": "Känerkinden",
    "BFS-Nr": "2850",
    "Kantonskürzel": "BL",
    "E": "2629819.006000001",
    "N": "1251619.5839999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kilchberg BL",
    "PLZ": "4496",
    "Zusatzziffer": "0",
    "Gemeindename": "Kilchberg (BL)",
    "BFS-Nr": "2851",
    "Kantonskürzel": "BL",
    "E": "2634734.2939999998",
    "N": "1252884.6779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Läufelfingen",
    "PLZ": "4448",
    "Zusatzziffer": "0",
    "Gemeindename": "Läufelfingen",
    "BFS-Nr": "2852",
    "Kantonskürzel": "BL",
    "E": "2631773.1169999987",
    "N": "1249764.050999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Maisprach",
    "PLZ": "4464",
    "Zusatzziffer": "0",
    "Gemeindename": "Maisprach",
    "BFS-Nr": "2853",
    "Kantonskürzel": "BL",
    "E": "2630528.3630000018",
    "N": "1263877.6550000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nusshof",
    "PLZ": "4453",
    "Zusatzziffer": "0",
    "Gemeindename": "Nusshof",
    "BFS-Nr": "2854",
    "Kantonskürzel": "BL",
    "E": "2627538.495000001",
    "N": "1260151.6889999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oltingen",
    "PLZ": "4494",
    "Zusatzziffer": "0",
    "Gemeindename": "Oltingen",
    "BFS-Nr": "2855",
    "Kantonskürzel": "BL",
    "E": "2637355.0439999998",
    "N": "1253708.5370000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ormalingen",
    "PLZ": "4466",
    "Zusatzziffer": "0",
    "Gemeindename": "Ormalingen",
    "BFS-Nr": "2856",
    "Kantonskürzel": "BL",
    "E": "2632829.171",
    "N": "1257713.539999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach BL",
    "PLZ": "4462",
    "Zusatzziffer": "0",
    "Gemeindename": "Rickenbach (BL)",
    "BFS-Nr": "2857",
    "Kantonskürzel": "BL",
    "E": "2631043.603",
    "N": "1259409.6380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rothenfluh",
    "PLZ": "4467",
    "Zusatzziffer": "0",
    "Gemeindename": "Rothenfluh",
    "BFS-Nr": "2858",
    "Kantonskürzel": "BL",
    "E": "2635873.579999998",
    "N": "1256905.204",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rümlingen",
    "PLZ": "4444",
    "Zusatzziffer": "0",
    "Gemeindename": "Rümlingen",
    "BFS-Nr": "2859",
    "Kantonskürzel": "BL",
    "E": "2631302.7930000015",
    "N": "1252856.1849999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rünenberg",
    "PLZ": "4497",
    "Zusatzziffer": "0",
    "Gemeindename": "Rünenberg",
    "BFS-Nr": "2860",
    "Kantonskürzel": "BL",
    "E": "2633840.6499999985",
    "N": "1253675.2980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sissach",
    "PLZ": "4450",
    "Zusatzziffer": "0",
    "Gemeindename": "Sissach",
    "BFS-Nr": "2861",
    "Kantonskürzel": "BL",
    "E": "2628447.6779999994",
    "N": "1257843.9310000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tecknau",
    "PLZ": "4492",
    "Zusatzziffer": "0",
    "Gemeindename": "Tecknau",
    "BFS-Nr": "2862",
    "Kantonskürzel": "BL",
    "E": "2633849.186999999",
    "N": "1255107.938000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tenniken",
    "PLZ": "4456",
    "Zusatzziffer": "0",
    "Gemeindename": "Tenniken",
    "BFS-Nr": "2863",
    "Kantonskürzel": "BL",
    "E": "2628498.8660000004",
    "N": "1254038.5430000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thürnen",
    "PLZ": "4441",
    "Zusatzziffer": "0",
    "Gemeindename": "Thürnen",
    "BFS-Nr": "2864",
    "Kantonskürzel": "BL",
    "E": "2629352.5210000016",
    "N": "1256072.8139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wenslingen",
    "PLZ": "4493",
    "Zusatzziffer": "0",
    "Gemeindename": "Wenslingen",
    "BFS-Nr": "2865",
    "Kantonskürzel": "BL",
    "E": "2635521.313000001",
    "N": "1254458.3090000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wintersingen",
    "PLZ": "4451",
    "Zusatzziffer": "0",
    "Gemeindename": "Wintersingen",
    "BFS-Nr": "2866",
    "Kantonskürzel": "BL",
    "E": "2629188.511",
    "N": "1260269.2430000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wittinsburg",
    "PLZ": "4443",
    "Zusatzziffer": "0",
    "Gemeindename": "Wittinsburg",
    "BFS-Nr": "2867",
    "Kantonskürzel": "BL",
    "E": "2630229.778999999",
    "N": "1253016.1680000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zeglingen",
    "PLZ": "4495",
    "Zusatzziffer": "0",
    "Gemeindename": "Zeglingen",
    "BFS-Nr": "2868",
    "Kantonskürzel": "BL",
    "E": "2635173.3709999993",
    "N": "1251885.8449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zunzgen",
    "PLZ": "4455",
    "Zusatzziffer": "0",
    "Gemeindename": "Zunzgen",
    "BFS-Nr": "2869",
    "Kantonskürzel": "BL",
    "E": "2627458.943",
    "N": "1255442.6689999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arboldswil",
    "PLZ": "4424",
    "Zusatzziffer": "0",
    "Gemeindename": "Arboldswil",
    "BFS-Nr": "2881",
    "Kantonskürzel": "BL",
    "E": "2621128.774",
    "N": "1251794.8889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bennwil",
    "PLZ": "4431",
    "Zusatzziffer": "0",
    "Gemeindename": "Bennwil",
    "BFS-Nr": "2882",
    "Kantonskürzel": "BL",
    "E": "2625856.784000002",
    "N": "1250046.829",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bretzwil",
    "PLZ": "4207",
    "Zusatzziffer": "0",
    "Gemeindename": "Bretzwil",
    "BFS-Nr": "2883",
    "Kantonskürzel": "BL",
    "E": "2615868.833999999",
    "N": "1249877.3139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diegten",
    "PLZ": "4457",
    "Zusatzziffer": "0",
    "Gemeindename": "Diegten",
    "BFS-Nr": "2884",
    "Kantonskürzel": "BL",
    "E": "2628243.8060000017",
    "N": "1251594.3839999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eptingen",
    "PLZ": "4458",
    "Zusatzziffer": "0",
    "Gemeindename": "Eptingen",
    "BFS-Nr": "2885",
    "Kantonskürzel": "BL",
    "E": "2629079.1460000016",
    "N": "1248435.546",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hölstein",
    "PLZ": "4434",
    "Zusatzziffer": "0",
    "Gemeindename": "Hölstein",
    "BFS-Nr": "2886",
    "Kantonskürzel": "BL",
    "E": "2625426.7710000016",
    "N": "1252973.6110000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lampenberg",
    "PLZ": "4432",
    "Zusatzziffer": "0",
    "Gemeindename": "Lampenberg",
    "BFS-Nr": "2887",
    "Kantonskürzel": "BL",
    "E": "2623808.3429999985",
    "N": "1252861.0579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langenbruck",
    "PLZ": "4438",
    "Zusatzziffer": "0",
    "Gemeindename": "Langenbruck",
    "BFS-Nr": "2888",
    "Kantonskürzel": "BL",
    "E": "2624849.6730000004",
    "N": "1244278.7089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lauwil",
    "PLZ": "4426",
    "Zusatzziffer": "0",
    "Gemeindename": "Lauwil",
    "BFS-Nr": "2889",
    "Kantonskürzel": "BL",
    "E": "2617878.171",
    "N": "1248572.2789999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Liedertswil",
    "PLZ": "4436",
    "Zusatzziffer": "2",
    "Gemeindename": "Liedertswil",
    "BFS-Nr": "2890",
    "Kantonskürzel": "BL",
    "E": "2621294.9059999995",
    "N": "1248892.7930000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederdorf",
    "PLZ": "4435",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederdorf",
    "BFS-Nr": "2891",
    "Kantonskürzel": "BL",
    "E": "2623469.6950000003",
    "N": "1251006.234000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberdorf BL",
    "PLZ": "4436",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberdorf (BL)",
    "BFS-Nr": "2892",
    "Kantonskürzel": "BL",
    "E": "2623799.489",
    "N": "1248987.3530000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reigoldswil",
    "PLZ": "4418",
    "Zusatzziffer": "0",
    "Gemeindename": "Reigoldswil",
    "BFS-Nr": "2893",
    "Kantonskürzel": "BL",
    "E": "2618724.3949999996",
    "N": "1249983.2210000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Titterten",
    "PLZ": "4425",
    "Zusatzziffer": "0",
    "Gemeindename": "Titterten",
    "BFS-Nr": "2894",
    "Kantonskürzel": "BL",
    "E": "2621204.0760000013",
    "N": "1250380.693",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Waldenburg",
    "PLZ": "4437",
    "Zusatzziffer": "0",
    "Gemeindename": "Waldenburg",
    "BFS-Nr": "2895",
    "Kantonskürzel": "BL",
    "E": "2623423.261",
    "N": "1248053.4640000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gächlingen",
    "PLZ": "8214",
    "Zusatzziffer": "0",
    "Gemeindename": "Gächlingen",
    "BFS-Nr": "2901",
    "Kantonskürzel": "SH",
    "E": "2679659.2630000003",
    "N": "1284052.7509999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gächlingen",
    "PLZ": "8214",
    "Zusatzziffer": "0",
    "Gemeindename": "Gächlingen",
    "BFS-Nr": "2901",
    "Kantonskürzel": "SH",
    "E": "2680395.351",
    "N": "1287718.4649999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Löhningen",
    "PLZ": "8224",
    "Zusatzziffer": "0",
    "Gemeindename": "Löhningen",
    "BFS-Nr": "2903",
    "Kantonskürzel": "SH",
    "E": "2683653.283",
    "N": "1283762.1579999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neunkirch",
    "PLZ": "8213",
    "Zusatzziffer": "0",
    "Gemeindename": "Neunkirch",
    "BFS-Nr": "2904",
    "Kantonskürzel": "SH",
    "E": "2679584.795000002",
    "N": "1282755.296",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büttenhardt",
    "PLZ": "8236",
    "Zusatzziffer": "0",
    "Gemeindename": "Büttenhardt",
    "BFS-Nr": "2914",
    "Kantonskürzel": "SH",
    "E": "2691054.859000001",
    "N": "1290199.5549999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dörflingen",
    "PLZ": "8239",
    "Zusatzziffer": "0",
    "Gemeindename": "Dörflingen",
    "BFS-Nr": "2915",
    "Kantonskürzel": "SH",
    "E": "2696383.9400000013",
    "N": "1284738.8839999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lohn SH",
    "PLZ": "8235",
    "Zusatzziffer": "0",
    "Gemeindename": "Lohn (SH)",
    "BFS-Nr": "2917",
    "Kantonskürzel": "SH",
    "E": "2692422.037999999",
    "N": "1290262.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stetten SH",
    "PLZ": "8234",
    "Zusatzziffer": "0",
    "Gemeindename": "Stetten (SH)",
    "BFS-Nr": "2919",
    "Kantonskürzel": "SH",
    "E": "2691842.3390000015",
    "N": "1288450.8960000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Opfertshofen SH",
    "PLZ": "8236",
    "Zusatzziffer": "2",
    "Gemeindename": "Thayngen",
    "BFS-Nr": "2920",
    "Kantonskürzel": "SH",
    "E": "2691739.6130000018",
    "N": "1292580.868999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thayngen",
    "PLZ": "8240",
    "Zusatzziffer": "0",
    "Gemeindename": "Thayngen",
    "BFS-Nr": "2920",
    "Kantonskürzel": "SH",
    "E": "2695434.8330000006",
    "N": "1289314.1590000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Barzheim",
    "PLZ": "8241",
    "Zusatzziffer": "0",
    "Gemeindename": "Thayngen",
    "BFS-Nr": "2920",
    "Kantonskürzel": "SH",
    "E": "2696158.5480000004",
    "N": "1290797.8209999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bibern SH",
    "PLZ": "8242",
    "Zusatzziffer": "0",
    "Gemeindename": "Thayngen",
    "BFS-Nr": "2920",
    "Kantonskürzel": "SH",
    "E": "2692848.728",
    "N": "1292074.7239999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hofen SH",
    "PLZ": "8242",
    "Zusatzziffer": "1",
    "Gemeindename": "Thayngen",
    "BFS-Nr": "2920",
    "Kantonskürzel": "SH",
    "E": "2692799.2179999985",
    "N": "1293197.7740000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altdorf SH",
    "PLZ": "8243",
    "Zusatzziffer": "0",
    "Gemeindename": "Thayngen",
    "BFS-Nr": "2920",
    "Kantonskürzel": "SH",
    "E": "2691425.7179999985",
    "N": "1293327.0289999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bargen SH",
    "PLZ": "8233",
    "Zusatzziffer": "0",
    "Gemeindename": "Bargen (SH)",
    "BFS-Nr": "2931",
    "Kantonskürzel": "SH",
    "E": "2687791.0639999993",
    "N": "1294140.732999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beringen",
    "PLZ": "8222",
    "Zusatzziffer": "0",
    "Gemeindename": "Beringen",
    "BFS-Nr": "2932",
    "Kantonskürzel": "SH",
    "E": "2685120.335999999",
    "N": "1283788.7529999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guntmadingen",
    "PLZ": "8223",
    "Zusatzziffer": "0",
    "Gemeindename": "Beringen",
    "BFS-Nr": "2932",
    "Kantonskürzel": "SH",
    "E": "2683988.2509999983",
    "N": "1282308.6389999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchberg",
    "PLZ": "8454",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchberg",
    "BFS-Nr": "2933",
    "Kantonskürzel": "SH",
    "E": "2684588.846000001",
    "N": "1269725.1149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Merishausen",
    "PLZ": "8232",
    "Zusatzziffer": "0",
    "Gemeindename": "Merishausen",
    "BFS-Nr": "2936",
    "Kantonskürzel": "SH",
    "E": "2687605.596000001",
    "N": "1290598.8200000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuhausen am Rheinfall",
    "PLZ": "8212",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuhausen am Rheinfall",
    "BFS-Nr": "2937",
    "Kantonskürzel": "SH",
    "E": "2688514.5009999983",
    "N": "1281909.074000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüdlingen",
    "PLZ": "8455",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüdlingen",
    "BFS-Nr": "2938",
    "Kantonskürzel": "SH",
    "E": "2685284.3779999986",
    "N": "1270446.7280000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schaffhausen",
    "PLZ": "8200",
    "Zusatzziffer": "0",
    "Gemeindename": "Schaffhausen",
    "BFS-Nr": "2939",
    "Kantonskürzel": "SH",
    "E": "2689092.226",
    "N": "1285903.075645082",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schaffhausen",
    "PLZ": "8203",
    "Zusatzziffer": "0",
    "Gemeindename": "Schaffhausen",
    "BFS-Nr": "2939",
    "Kantonskürzel": "SH",
    "E": "2691832.6679999996",
    "N": "1283954.232889056",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schaffhausen",
    "PLZ": "8207",
    "Zusatzziffer": "0",
    "Gemeindename": "Schaffhausen",
    "BFS-Nr": "2939",
    "Kantonskürzel": "SH",
    "E": "2692595.310737199",
    "N": "1286602.921",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schaffhausen",
    "PLZ": "8208",
    "Zusatzziffer": "0",
    "Gemeindename": "Schaffhausen",
    "BFS-Nr": "2939",
    "Kantonskürzel": "SH",
    "E": "2689849.018000001",
    "N": "1285273.6036371393",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hemmental",
    "PLZ": "8231",
    "Zusatzziffer": "0",
    "Gemeindename": "Schaffhausen",
    "BFS-Nr": "2939",
    "Kantonskürzel": "SH",
    "E": "2685550.0599999987",
    "N": "1287814.9239999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beggingen",
    "PLZ": "8228",
    "Zusatzziffer": "0",
    "Gemeindename": "Beggingen",
    "BFS-Nr": "2951",
    "Kantonskürzel": "SH",
    "E": "2682090.454999998",
    "N": "1291357.3759999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schleitheim",
    "PLZ": "8226",
    "Zusatzziffer": "0",
    "Gemeindename": "Schleitheim",
    "BFS-Nr": "2952",
    "Kantonskürzel": "SH",
    "E": "2678740.6149999984",
    "N": "1289310.0859999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Siblingen",
    "PLZ": "8225",
    "Zusatzziffer": "0",
    "Gemeindename": "Siblingen",
    "BFS-Nr": "2953",
    "Kantonskürzel": "SH",
    "E": "2681102.7380000018",
    "N": "1285401.0940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buch SH",
    "PLZ": "8263",
    "Zusatzziffer": "0",
    "Gemeindename": "Buch (SH)",
    "BFS-Nr": "2961",
    "Kantonskürzel": "SH",
    "E": "2700878.1849999987",
    "N": "1286030.7089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hemishofen",
    "PLZ": "8261",
    "Zusatzziffer": "0",
    "Gemeindename": "Hemishofen",
    "BFS-Nr": "2962",
    "Kantonskürzel": "SH",
    "E": "2704638.1840000004",
    "N": "1281622.4090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ramsen",
    "PLZ": "8262",
    "Zusatzziffer": "0",
    "Gemeindename": "Ramsen",
    "BFS-Nr": "2963",
    "Kantonskürzel": "SH",
    "E": "2703147.9560000002",
    "N": "1284938.8359999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stein am Rhein",
    "PLZ": "8260",
    "Zusatzziffer": "0",
    "Gemeindename": "Stein am Rhein",
    "BFS-Nr": "2964",
    "Kantonskürzel": "SH",
    "E": "2706784.346000001",
    "N": "1279666.2380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hallau",
    "PLZ": "8215",
    "Zusatzziffer": "0",
    "Gemeindename": "Hallau",
    "BFS-Nr": "2971",
    "Kantonskürzel": "SH",
    "E": "2676832.0029999986",
    "N": "1283589.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhallau",
    "PLZ": "8216",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberhallau",
    "BFS-Nr": "2972",
    "Kantonskürzel": "SH",
    "E": "2678049.7529999986",
    "N": "1284325.046",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trasadingen",
    "PLZ": "8219",
    "Zusatzziffer": "0",
    "Gemeindename": "Trasadingen",
    "BFS-Nr": "2973",
    "Kantonskürzel": "SH",
    "E": "2674510.824000001",
    "N": "1280170.7939999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilchingen",
    "PLZ": "8217",
    "Zusatzziffer": "0",
    "Gemeindename": "Wilchingen",
    "BFS-Nr": "2974",
    "Kantonskürzel": "SH",
    "E": "2677061.785",
    "N": "1280348.2789999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilchingen",
    "PLZ": "8217",
    "Zusatzziffer": "0",
    "Gemeindename": "Wilchingen",
    "BFS-Nr": "2974",
    "Kantonskürzel": "SH",
    "E": "2681191.243999999",
    "N": "1278876.2919999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Osterfingen",
    "PLZ": "8218",
    "Zusatzziffer": "0",
    "Gemeindename": "Wilchingen",
    "BFS-Nr": "2974",
    "Kantonskürzel": "SH",
    "E": "2679056.403999999",
    "N": "1279689.3660000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herisau",
    "PLZ": "9100",
    "Zusatzziffer": "0",
    "Gemeindename": "Herisau",
    "BFS-Nr": "3001",
    "Kantonskürzel": "AR",
    "E": "2738986.995000001",
    "N": "1249974.1059999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schachen b. Herisau",
    "PLZ": "9112",
    "Zusatzziffer": "0",
    "Gemeindename": "Herisau",
    "BFS-Nr": "3001",
    "Kantonskürzel": "AR",
    "E": "2736248.462000001",
    "N": "1249817.6669999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hundwil",
    "PLZ": "9064",
    "Zusatzziffer": "0",
    "Gemeindename": "Hundwil",
    "BFS-Nr": "3002",
    "Kantonskürzel": "AR",
    "E": "2742142.7430000007",
    "N": "1247793.5749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönengrund",
    "PLZ": "9105",
    "Zusatzziffer": "0",
    "Gemeindename": "Schönengrund",
    "BFS-Nr": "3003",
    "Kantonskürzel": "AR",
    "E": "2735131.6160000004",
    "N": "1243196.5309999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwellbrunn",
    "PLZ": "9103",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwellbrunn",
    "BFS-Nr": "3004",
    "Kantonskürzel": "AR",
    "E": "2736794.8280000016",
    "N": "1246178.9609999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stein AR",
    "PLZ": "9063",
    "Zusatzziffer": "0",
    "Gemeindename": "Stein (AR)",
    "BFS-Nr": "3005",
    "Kantonskürzel": "AR",
    "E": "2743888.734000001",
    "N": "1248888.454",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Urnäsch",
    "PLZ": "9107",
    "Zusatzziffer": "0",
    "Gemeindename": "Urnäsch",
    "BFS-Nr": "3006",
    "Kantonskürzel": "AR",
    "E": "2739430.125",
    "N": "1242323.954",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Waldstatt",
    "PLZ": "9104",
    "Zusatzziffer": "0",
    "Gemeindename": "Waldstatt",
    "BFS-Nr": "3007",
    "Kantonskürzel": "AR",
    "E": "2739370.0430000015",
    "N": "1246708.1400000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bühler",
    "PLZ": "9055",
    "Zusatzziffer": "0",
    "Gemeindename": "Bühler",
    "BFS-Nr": "3021",
    "Kantonskürzel": "AR",
    "E": "2750072.848000001",
    "N": "1248850.9230000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gais",
    "PLZ": "9056",
    "Zusatzziffer": "0",
    "Gemeindename": "Gais",
    "BFS-Nr": "3022",
    "Kantonskürzel": "AR",
    "E": "2752308.3489999995",
    "N": "1247671.3539999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Speicherschwendi",
    "PLZ": "9037",
    "Zusatzziffer": "0",
    "Gemeindename": "Speicher",
    "BFS-Nr": "3023",
    "Kantonskürzel": "AR",
    "E": "2750741.243999999",
    "N": "1254626.9100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Speicher",
    "PLZ": "9042",
    "Zusatzziffer": "0",
    "Gemeindename": "Speicher",
    "BFS-Nr": "3023",
    "Kantonskürzel": "AR",
    "E": "2751233.454999998",
    "N": "1253305.9360000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederteufen",
    "PLZ": "9052",
    "Zusatzziffer": "0",
    "Gemeindename": "Teufen (AR)",
    "BFS-Nr": "3024",
    "Kantonskürzel": "AR",
    "E": "2745604.818",
    "N": "1250836.4750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Teufen AR",
    "PLZ": "9053",
    "Zusatzziffer": "0",
    "Gemeindename": "Teufen (AR)",
    "BFS-Nr": "3024",
    "Kantonskürzel": "AR",
    "E": "2747138.1180000007",
    "N": "1250608.7829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lustmühle",
    "PLZ": "9062",
    "Zusatzziffer": "0",
    "Gemeindename": "Teufen (AR)",
    "BFS-Nr": "3024",
    "Kantonskürzel": "AR",
    "E": "2744966.3949999996",
    "N": "1251715.0340000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trogen",
    "PLZ": "9043",
    "Zusatzziffer": "0",
    "Gemeindename": "Trogen",
    "BFS-Nr": "3025",
    "Kantonskürzel": "AR",
    "E": "2752961.0439999998",
    "N": "1252807.7719999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grub AR",
    "PLZ": "9035",
    "Zusatzziffer": "0",
    "Gemeindename": "Grub (AR)",
    "BFS-Nr": "3031",
    "Kantonskürzel": "AR",
    "E": "2756170.658",
    "N": "1257356.368999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heiden",
    "PLZ": "9410",
    "Zusatzziffer": "0",
    "Gemeindename": "Heiden",
    "BFS-Nr": "3032",
    "Kantonskürzel": "AR",
    "E": "2758106.3790000007",
    "N": "1257037.1380000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wienacht-Tobel",
    "PLZ": "9405",
    "Zusatzziffer": "0",
    "Gemeindename": "Lutzenberg",
    "BFS-Nr": "3033",
    "Kantonskürzel": "AR",
    "E": "2758354.848000001",
    "N": "1259308.432",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lutzenberg",
    "PLZ": "9426",
    "Zusatzziffer": "0",
    "Gemeindename": "Lutzenberg",
    "BFS-Nr": "3033",
    "Kantonskürzel": "AR",
    "E": "2761723.587000001",
    "N": "1258744.8449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rehetobel",
    "PLZ": "9038",
    "Zusatzziffer": "0",
    "Gemeindename": "Rehetobel",
    "BFS-Nr": "3034",
    "Kantonskürzel": "AR",
    "E": "2754197.778000001",
    "N": "1254834.052000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reute AR",
    "PLZ": "9411",
    "Zusatzziffer": "0",
    "Gemeindename": "Reute (AR)",
    "BFS-Nr": "3035",
    "Kantonskürzel": "AR",
    "E": "2761222.2809999995",
    "N": "1254337.4310000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schachen b. Reute",
    "PLZ": "9411",
    "Zusatzziffer": "1",
    "Gemeindename": "Reute (AR)",
    "BFS-Nr": "3035",
    "Kantonskürzel": "AR",
    "E": "2760059.414999999",
    "N": "1255077.0720000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wald AR",
    "PLZ": "9044",
    "Zusatzziffer": "0",
    "Gemeindename": "Wald (AR)",
    "BFS-Nr": "3036",
    "Kantonskürzel": "AR",
    "E": "2754724.5830000006",
    "N": "1253654.1909999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walzenhausen",
    "PLZ": "9428",
    "Zusatzziffer": "0",
    "Gemeindename": "Walzenhausen",
    "BFS-Nr": "3037",
    "Kantonskürzel": "AR",
    "E": "2763070.7410000004",
    "N": "1257885.0410000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wolfhalden",
    "PLZ": "9427",
    "Zusatzziffer": "0",
    "Gemeindename": "Wolfhalden",
    "BFS-Nr": "3038",
    "Kantonskürzel": "AR",
    "E": "2759259.859000001",
    "N": "1258060.864",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Appenzell",
    "PLZ": "9050",
    "Zusatzziffer": "0",
    "Gemeindename": "Appenzell",
    "BFS-Nr": "3101",
    "Kantonskürzel": "AI",
    "E": "2749002.0929999985",
    "N": "1244097.7080000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Appenzell Meistersrüte",
    "PLZ": "9050",
    "Zusatzziffer": "5",
    "Gemeindename": "Appenzell",
    "BFS-Nr": "3101",
    "Kantonskürzel": "AI",
    "E": "2750459.9349999987",
    "N": "1245703.4649999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gonten",
    "PLZ": "9108",
    "Zusatzziffer": "0",
    "Gemeindename": "Gonten",
    "BFS-Nr": "3102",
    "Kantonskürzel": "AI",
    "E": "2744215.1110000014",
    "N": "1243687.1860000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gontenbad",
    "PLZ": "9108",
    "Zusatzziffer": "2",
    "Gemeindename": "Gonten",
    "BFS-Nr": "3102",
    "Kantonskürzel": "AI",
    "E": "2746155.155000001",
    "N": "1244106.0100000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jakobsbad",
    "PLZ": "9108",
    "Zusatzziffer": "3",
    "Gemeindename": "Gonten",
    "BFS-Nr": "3102",
    "Kantonskürzel": "AI",
    "E": "2742846.6039999984",
    "N": "1242746.210000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Appenzell Eggerstanden",
    "PLZ": "9050",
    "Zusatzziffer": "2",
    "Gemeindename": "Rüte",
    "BFS-Nr": "3103",
    "Kantonskürzel": "AI",
    "E": "2753344.6270000003",
    "N": "1244209.0780000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Appenzell Steinegg",
    "PLZ": "9050",
    "Zusatzziffer": "6",
    "Gemeindename": "Rüte",
    "BFS-Nr": "3103",
    "Kantonskürzel": "AI",
    "E": "2750576.8060000017",
    "N": "1242982.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weissbad",
    "PLZ": "9057",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüte",
    "BFS-Nr": "3103",
    "Kantonskürzel": "AI",
    "E": "2751005.7250000015",
    "N": "1241886.0139999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brülisau",
    "PLZ": "9058",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüte",
    "BFS-Nr": "3103",
    "Kantonskürzel": "AI",
    "E": "2752597.335999999",
    "N": "1240460.7010000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Appenzell Enggenhütten",
    "PLZ": "9050",
    "Zusatzziffer": "3",
    "Gemeindename": "Schlatt-Haslen",
    "BFS-Nr": "3104",
    "Kantonskürzel": "AI",
    "E": "2745331.125",
    "N": "1246470.6259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Appenzell Schlatt",
    "PLZ": "9050",
    "Zusatzziffer": "7",
    "Gemeindename": "Schlatt-Haslen",
    "BFS-Nr": "3104",
    "Kantonskürzel": "AI",
    "E": "2747863.653000001",
    "N": "1246908.5249999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Haslen AI",
    "PLZ": "9054",
    "Zusatzziffer": "0",
    "Gemeindename": "Schlatt-Haslen",
    "BFS-Nr": "3104",
    "Kantonskürzel": "AI",
    "E": "2745637.1970000006",
    "N": "1248322.3979999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwende",
    "PLZ": "9057",
    "Zusatzziffer": "2",
    "Gemeindename": "Schwende",
    "BFS-Nr": "3105",
    "Kantonskürzel": "AI",
    "E": "2750910.015999999",
    "N": "1240791.2710000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wasserauen",
    "PLZ": "9057",
    "Zusatzziffer": "3",
    "Gemeindename": "Schwende",
    "BFS-Nr": "3105",
    "Kantonskürzel": "AI",
    "E": "2750509.767999999",
    "N": "1239060.881000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberegg",
    "PLZ": "9413",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberegg",
    "BFS-Nr": "3111",
    "Kantonskürzel": "AI",
    "E": "2759565.0489999987",
    "N": "1254461.0780000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberegg",
    "PLZ": "9413",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberegg",
    "BFS-Nr": "3111",
    "Kantonskürzel": "AI",
    "E": "2761805.482999999",
    "N": "1254726.5560000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büriswilen",
    "PLZ": "9442",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberegg",
    "BFS-Nr": "3111",
    "Kantonskürzel": "AI",
    "E": "2763798.5529999994",
    "N": "1255987.2349999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lömmenschwil",
    "PLZ": "9308",
    "Zusatzziffer": "0",
    "Gemeindename": "Häggenschwil",
    "BFS-Nr": "3201",
    "Kantonskürzel": "SG",
    "E": "2744322.1279999986",
    "N": "1262409.3079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Häggenschwil",
    "PLZ": "9312",
    "Zusatzziffer": "0",
    "Gemeindename": "Häggenschwil",
    "BFS-Nr": "3201",
    "Kantonskürzel": "SG",
    "E": "2743556.1719999984",
    "N": "1262128.9050000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Muolen",
    "PLZ": "9313",
    "Zusatzziffer": "0",
    "Gemeindename": "Muolen",
    "BFS-Nr": "3202",
    "Kantonskürzel": "SG",
    "E": "2741971.8429999985",
    "N": "1265113.5219999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9000",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2745132.9015198406",
    "N": "1254315.432",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9008",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2747317.028999999",
    "N": "1256391.4376011596",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9010",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2745797.104214535",
    "N": "1256400.8935000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9011",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2747955.125500001",
    "N": "1253398.615233061",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9012",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2745062.8561744764",
    "N": "1252828.9065000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9014",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2743153.547777896",
    "N": "1252668.2705000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9015",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2741252.3835000005",
    "N": "1252232.0356942774",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallen",
    "PLZ": "9016",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Gallen",
    "BFS-Nr": "3203",
    "Kantonskürzel": "SG",
    "E": "2748993.0145076625",
    "N": "1256398.578499999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wittenbach",
    "PLZ": "9300",
    "Zusatzziffer": "0",
    "Gemeindename": "Wittenbach",
    "BFS-Nr": "3204",
    "Kantonskürzel": "SG",
    "E": "2746297.3740000017",
    "N": "1258761.2060000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berg SG",
    "PLZ": "9305",
    "Zusatzziffer": "0",
    "Gemeindename": "Berg (SG)",
    "BFS-Nr": "3211",
    "Kantonskürzel": "SG",
    "E": "2748395.84",
    "N": "1261395.6840000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eggersriet",
    "PLZ": "9034",
    "Zusatzziffer": "0",
    "Gemeindename": "Eggersriet",
    "BFS-Nr": "3212",
    "Kantonskürzel": "SG",
    "E": "2753346.745000001",
    "N": "1256604.811999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grub SG",
    "PLZ": "9036",
    "Zusatzziffer": "0",
    "Gemeindename": "Eggersriet",
    "BFS-Nr": "3212",
    "Kantonskürzel": "SG",
    "E": "2756401.5500000007",
    "N": "1257760.3469999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Goldach",
    "PLZ": "9403",
    "Zusatzziffer": "0",
    "Gemeindename": "Goldach",
    "BFS-Nr": "3213",
    "Kantonskürzel": "SG",
    "E": "2752726.5210000016",
    "N": "1260037.5309999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mörschwil",
    "PLZ": "9402",
    "Zusatzziffer": "0",
    "Gemeindename": "Mörschwil",
    "BFS-Nr": "3214",
    "Kantonskürzel": "SG",
    "E": "2749701.6209999993",
    "N": "1259383.4090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rorschach",
    "PLZ": "9400",
    "Zusatzziffer": "0",
    "Gemeindename": "Rorschach",
    "BFS-Nr": "3215",
    "Kantonskürzel": "SG",
    "E": "2754725.0040000007",
    "N": "1260211.3550000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rorschacherberg",
    "PLZ": "9404",
    "Zusatzziffer": "0",
    "Gemeindename": "Rorschacherberg",
    "BFS-Nr": "3216",
    "Kantonskürzel": "SG",
    "E": "2755560.9299999997",
    "N": "1259323.6149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinach",
    "PLZ": "9323",
    "Zusatzziffer": "0",
    "Gemeindename": "Steinach",
    "BFS-Nr": "3217",
    "Kantonskürzel": "SG",
    "E": "2750792.4880000018",
    "N": "1263243.6999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tübach",
    "PLZ": "9327",
    "Zusatzziffer": "0",
    "Gemeindename": "Tübach",
    "BFS-Nr": "3218",
    "Kantonskürzel": "SG",
    "E": "2751865.0639999993",
    "N": "1261280.9219999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Untereggen",
    "PLZ": "9033",
    "Zusatzziffer": "0",
    "Gemeindename": "Untereggen",
    "BFS-Nr": "3219",
    "Kantonskürzel": "SG",
    "E": "2751878.2309999987",
    "N": "1257964.739",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Au SG",
    "PLZ": "9434",
    "Zusatzziffer": "0",
    "Gemeindename": "Au (SG)",
    "BFS-Nr": "3231",
    "Kantonskürzel": "SG",
    "E": "2765585.7360000014",
    "N": "1255940.109000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heerbrugg",
    "PLZ": "9435",
    "Zusatzziffer": "0",
    "Gemeindename": "Au (SG)",
    "BFS-Nr": "3231",
    "Kantonskürzel": "SG",
    "E": "2765129.142999999",
    "N": "1253641.550999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Balgach",
    "PLZ": "9436",
    "Zusatzziffer": "0",
    "Gemeindename": "Balgach",
    "BFS-Nr": "3232",
    "Kantonskürzel": "SG",
    "E": "2763593.9930000007",
    "N": "1252988.318",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reute AR",
    "PLZ": "9411",
    "Zusatzziffer": "0",
    "Gemeindename": "Berneck",
    "BFS-Nr": "3233",
    "Kantonskürzel": "SG",
    "E": "2762645.772999998",
    "N": "1254395.9640000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berneck",
    "PLZ": "9442",
    "Zusatzziffer": "0",
    "Gemeindename": "Berneck",
    "BFS-Nr": "3233",
    "Kantonskürzel": "SG",
    "E": "2763773.8630000018",
    "N": "1254905.3960000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diepoldsau",
    "PLZ": "9444",
    "Zusatzziffer": "0",
    "Gemeindename": "Diepoldsau",
    "BFS-Nr": "3234",
    "Kantonskürzel": "SG",
    "E": "2767535.045000002",
    "N": "1250654.8900000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rheineck",
    "PLZ": "9424",
    "Zusatzziffer": "0",
    "Gemeindename": "Rheineck",
    "BFS-Nr": "3235",
    "Kantonskürzel": "SG",
    "E": "2762188.2989999987",
    "N": "1259448.1739999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Margrethen SG",
    "PLZ": "9430",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Margrethen",
    "BFS-Nr": "3236",
    "Kantonskürzel": "SG",
    "E": "2764927.9409999996",
    "N": "1258205.1290000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Staad SG",
    "PLZ": "9422",
    "Zusatzziffer": "0",
    "Gemeindename": "Thal",
    "BFS-Nr": "3237",
    "Kantonskürzel": "SG",
    "E": "2758540.3790000007",
    "N": "1260993.4389999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altenrhein",
    "PLZ": "9423",
    "Zusatzziffer": "0",
    "Gemeindename": "Thal",
    "BFS-Nr": "3237",
    "Kantonskürzel": "SG",
    "E": "2759206.300999999",
    "N": "1262158.120000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thal",
    "PLZ": "9425",
    "Zusatzziffer": "0",
    "Gemeindename": "Thal",
    "BFS-Nr": "3237",
    "Kantonskürzel": "SG",
    "E": "2760638.6000000015",
    "N": "1259320.8509999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Widnau",
    "PLZ": "9443",
    "Zusatzziffer": "0",
    "Gemeindename": "Widnau",
    "BFS-Nr": "3238",
    "Kantonskürzel": "SG",
    "E": "2766119.2140000015",
    "N": "1252698.4849999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altstätten SG",
    "PLZ": "9450",
    "Zusatzziffer": "0",
    "Gemeindename": "Altstätten",
    "BFS-Nr": "3251",
    "Kantonskürzel": "SG",
    "E": "2758789.7360000014",
    "N": "1249593.3280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lüchingen",
    "PLZ": "9450",
    "Zusatzziffer": "2",
    "Gemeindename": "Altstätten",
    "BFS-Nr": "3251",
    "Kantonskürzel": "SG",
    "E": "2759557.8189999983",
    "N": "1250663.1530000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hinterforst",
    "PLZ": "9452",
    "Zusatzziffer": "0",
    "Gemeindename": "Altstätten",
    "BFS-Nr": "3251",
    "Kantonskürzel": "SG",
    "E": "2758354.7430000007",
    "N": "1247622.1460000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lienz",
    "PLZ": "9464",
    "Zusatzziffer": "2",
    "Gemeindename": "Altstätten",
    "BFS-Nr": "3251",
    "Kantonskürzel": "SG",
    "E": "2757169.7129999995",
    "N": "1238476.447999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eichberg",
    "PLZ": "9453",
    "Zusatzziffer": "0",
    "Gemeindename": "Eichberg",
    "BFS-Nr": "3252",
    "Kantonskürzel": "SG",
    "E": "2757663.471999999",
    "N": "1246063.164999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Marbach SG",
    "PLZ": "9437",
    "Zusatzziffer": "0",
    "Gemeindename": "Marbach (SG)",
    "BFS-Nr": "3253",
    "Kantonskürzel": "SG",
    "E": "2760714.9299999997",
    "N": "1251169.318",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kriessern",
    "PLZ": "9451",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberriet (SG)",
    "BFS-Nr": "3254",
    "Kantonskürzel": "SG",
    "E": "2763763.085999999",
    "N": "1248329.120000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Montlingen",
    "PLZ": "9462",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberriet (SG)",
    "BFS-Nr": "3254",
    "Kantonskürzel": "SG",
    "E": "2762622.9930000007",
    "N": "1244897.6000000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberriet SG",
    "PLZ": "9463",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberriet (SG)",
    "BFS-Nr": "3254",
    "Kantonskürzel": "SG",
    "E": "2761166.0549999997",
    "N": "1243881.2989999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rebstein",
    "PLZ": "9445",
    "Zusatzziffer": "0",
    "Gemeindename": "Rebstein",
    "BFS-Nr": "3255",
    "Kantonskürzel": "SG",
    "E": "2761946.1259999983",
    "N": "1252067.1000000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüthi (Rheintal)",
    "PLZ": "9464",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüthi (SG)",
    "BFS-Nr": "3256",
    "Kantonskürzel": "SG",
    "E": "2758823.818",
    "N": "1240319.7159999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchs SG",
    "PLZ": "9470",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchs (SG)",
    "BFS-Nr": "3271",
    "Kantonskürzel": "SG",
    "E": "2753853.4290000014",
    "N": "1225779.1330000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gams",
    "PLZ": "9473",
    "Zusatzziffer": "0",
    "Gemeindename": "Gams",
    "BFS-Nr": "3272",
    "Kantonskürzel": "SG",
    "E": "2751650.1099999994",
    "N": "1230024.5080000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Werdenberg",
    "PLZ": "9470",
    "Zusatzziffer": "5",
    "Gemeindename": "Grabs",
    "BFS-Nr": "3273",
    "Kantonskürzel": "SG",
    "E": "2753341.7479999997",
    "N": "1226156.636",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grabs",
    "PLZ": "9472",
    "Zusatzziffer": "0",
    "Gemeindename": "Grabs",
    "BFS-Nr": "3273",
    "Kantonskürzel": "SG",
    "E": "2752223.9910000004",
    "N": "1227523.449000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grabserberg",
    "PLZ": "9472",
    "Zusatzziffer": "2",
    "Gemeindename": "Grabs",
    "BFS-Nr": "3273",
    "Kantonskürzel": "SG",
    "E": "2750568.4899999984",
    "N": "1227529.0219999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Salez",
    "PLZ": "9465",
    "Zusatzziffer": "0",
    "Gemeindename": "Sennwald",
    "BFS-Nr": "3274",
    "Kantonskürzel": "SG",
    "E": "2756265.471000001",
    "N": "1233896.1110000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sennwald",
    "PLZ": "9466",
    "Zusatzziffer": "0",
    "Gemeindename": "Sennwald",
    "BFS-Nr": "3274",
    "Kantonskürzel": "SG",
    "E": "2756585.3940000013",
    "N": "1237032.6790000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frümsen",
    "PLZ": "9467",
    "Zusatzziffer": "0",
    "Gemeindename": "Sennwald",
    "BFS-Nr": "3274",
    "Kantonskürzel": "SG",
    "E": "2753955.289999999",
    "N": "1234501.2580000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sax",
    "PLZ": "9468",
    "Zusatzziffer": "0",
    "Gemeindename": "Sennwald",
    "BFS-Nr": "3274",
    "Kantonskürzel": "SG",
    "E": "2753000.3940000013",
    "N": "1233209.1660000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Haag (Rheintal)",
    "PLZ": "9469",
    "Zusatzziffer": "0",
    "Gemeindename": "Sennwald",
    "BFS-Nr": "3274",
    "Kantonskürzel": "SG",
    "E": "2755541.1510000005",
    "N": "1231060.364",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sevelen",
    "PLZ": "9475",
    "Zusatzziffer": "0",
    "Gemeindename": "Sevelen",
    "BFS-Nr": "3275",
    "Kantonskürzel": "SG",
    "E": "2755352.0249999985",
    "N": "1220971.6700000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weite",
    "PLZ": "9476",
    "Zusatzziffer": "0",
    "Gemeindename": "Wartau",
    "BFS-Nr": "3276",
    "Kantonskürzel": "SG",
    "E": "2756262.7060000002",
    "N": "1217770.4389999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fontnas",
    "PLZ": "9476",
    "Zusatzziffer": "2",
    "Gemeindename": "Wartau",
    "BFS-Nr": "3276",
    "Kantonskürzel": "SG",
    "E": "2755693.1350000016",
    "N": "1217503.8429999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trübbach",
    "PLZ": "9477",
    "Zusatzziffer": "0",
    "Gemeindename": "Wartau",
    "BFS-Nr": "3276",
    "Kantonskürzel": "SG",
    "E": "2754931.914000001",
    "N": "1215324.7919999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Azmoos",
    "PLZ": "9478",
    "Zusatzziffer": "0",
    "Gemeindename": "Wartau",
    "BFS-Nr": "3276",
    "Kantonskürzel": "SG",
    "E": "2754693.2529999986",
    "N": "1216355.3099999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberschan",
    "PLZ": "9479",
    "Zusatzziffer": "0",
    "Gemeindename": "Wartau",
    "BFS-Nr": "3276",
    "Kantonskürzel": "SG",
    "E": "2754519.2859999985",
    "N": "1218430.9420000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Malans SG",
    "PLZ": "9479",
    "Zusatzziffer": "1",
    "Gemeindename": "Wartau",
    "BFS-Nr": "3276",
    "Kantonskürzel": "SG",
    "E": "2754766.9070000015",
    "N": "1217585.6259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gretschins",
    "PLZ": "9479",
    "Zusatzziffer": "2",
    "Gemeindename": "Wartau",
    "BFS-Nr": "3276",
    "Kantonskürzel": "SG",
    "E": "2755435.7430000007",
    "N": "1218273.8359999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bad Ragaz",
    "PLZ": "7310",
    "Zusatzziffer": "0",
    "Gemeindename": "Bad Ragaz",
    "BFS-Nr": "3291",
    "Kantonskürzel": "SG",
    "E": "2756823.7399999984",
    "N": "1207945.245000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flums",
    "PLZ": "8890",
    "Zusatzziffer": "0",
    "Gemeindename": "Flums",
    "BFS-Nr": "3292",
    "Kantonskürzel": "SG",
    "E": "2744709.835999999",
    "N": "1217291.4789999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flums Hochwiese",
    "PLZ": "8893",
    "Zusatzziffer": "0",
    "Gemeindename": "Flums",
    "BFS-Nr": "3292",
    "Kantonskürzel": "SG",
    "E": "2746705.386",
    "N": "1216561.8570000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flumserberg Saxli",
    "PLZ": "8894",
    "Zusatzziffer": "0",
    "Gemeindename": "Flums",
    "BFS-Nr": "3292",
    "Kantonskürzel": "SG",
    "E": "2745147.3159999996",
    "N": "1215954.7129999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flumserberg Portels",
    "PLZ": "8895",
    "Zusatzziffer": "0",
    "Gemeindename": "Flums",
    "BFS-Nr": "3292",
    "Kantonskürzel": "SG",
    "E": "2744027.6900000013",
    "N": "1216440.1240000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flumserberg Bergheim",
    "PLZ": "8896",
    "Zusatzziffer": "0",
    "Gemeindename": "Flums",
    "BFS-Nr": "3292",
    "Kantonskürzel": "SG",
    "E": "2741737.7250000015",
    "N": "1217980.9649999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flumserberg Tannenheim",
    "PLZ": "8897",
    "Zusatzziffer": "0",
    "Gemeindename": "Flums",
    "BFS-Nr": "3292",
    "Kantonskürzel": "SG",
    "E": "2741624.6920000017",
    "N": "1217042.3480000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flumserberg Tannenbodenalp",
    "PLZ": "8898",
    "Zusatzziffer": "0",
    "Gemeindename": "Flums",
    "BFS-Nr": "3292",
    "Kantonskürzel": "SG",
    "E": "2740114.6840000004",
    "N": "1217497.4959999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwendi im Weisstannental",
    "PLZ": "7325",
    "Zusatzziffer": "0",
    "Gemeindename": "Mels",
    "BFS-Nr": "3293",
    "Kantonskürzel": "SG",
    "E": "2746360.7369999997",
    "N": "1208208.7930000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weisstannen",
    "PLZ": "7326",
    "Zusatzziffer": "0",
    "Gemeindename": "Mels",
    "BFS-Nr": "3293",
    "Kantonskürzel": "SG",
    "E": "2745045.1050000004",
    "N": "1206290.9100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mädris-Vermol",
    "PLZ": "8886",
    "Zusatzziffer": "0",
    "Gemeindename": "Mels",
    "BFS-Nr": "3293",
    "Kantonskürzel": "SG",
    "E": "2748470.340999998",
    "N": "1212956.3990000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mels",
    "PLZ": "8887",
    "Zusatzziffer": "0",
    "Gemeindename": "Mels",
    "BFS-Nr": "3293",
    "Kantonskürzel": "SG",
    "E": "2750739.1570000015",
    "N": "1212515.9699999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heiligkreuz (Mels)",
    "PLZ": "8888",
    "Zusatzziffer": "0",
    "Gemeindename": "Mels",
    "BFS-Nr": "3293",
    "Kantonskürzel": "SG",
    "E": "2749786.5480000004",
    "N": "1213858.390999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Plons",
    "PLZ": "8889",
    "Zusatzziffer": "0",
    "Gemeindename": "Mels",
    "BFS-Nr": "3293",
    "Kantonskürzel": "SG",
    "E": "2748998.0650000013",
    "N": "1213636.4699999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfäfers",
    "PLZ": "7312",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfäfers",
    "BFS-Nr": "3294",
    "Kantonskürzel": "SG",
    "E": "2756675.526999999",
    "N": "1206202.0819999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Margrethenberg",
    "PLZ": "7313",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfäfers",
    "BFS-Nr": "3294",
    "Kantonskürzel": "SG",
    "E": "2757641.6259999983",
    "N": "1205200.2800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vadura",
    "PLZ": "7314",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfäfers",
    "BFS-Nr": "3294",
    "Kantonskürzel": "SG",
    "E": "2755685.541000001",
    "N": "1202806.8420000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vättis",
    "PLZ": "7315",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfäfers",
    "BFS-Nr": "3294",
    "Kantonskürzel": "SG",
    "E": "2752554.800999999",
    "N": "1197437.2170000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Valens",
    "PLZ": "7317",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfäfers",
    "BFS-Nr": "3294",
    "Kantonskürzel": "SG",
    "E": "2755384.8709999993",
    "N": "1203969.2410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vasön",
    "PLZ": "7317",
    "Zusatzziffer": "3",
    "Gemeindename": "Pfäfers",
    "BFS-Nr": "3294",
    "Kantonskürzel": "SG",
    "E": "2755203.6669999994",
    "N": "1202185.5940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Murg",
    "PLZ": "8877",
    "Zusatzziffer": "0",
    "Gemeindename": "Quarten",
    "BFS-Nr": "3295",
    "Kantonskürzel": "SG",
    "E": "2734882.8040000014",
    "N": "1219514.1730000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Quinten",
    "PLZ": "8878",
    "Zusatzziffer": "0",
    "Gemeindename": "Quarten",
    "BFS-Nr": "3295",
    "Kantonskürzel": "SG",
    "E": "2734838.6449999996",
    "N": "1221328.7030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterterzen",
    "PLZ": "8882",
    "Zusatzziffer": "0",
    "Gemeindename": "Quarten",
    "BFS-Nr": "3295",
    "Kantonskürzel": "SG",
    "E": "2737873.284000002",
    "N": "1219717.1600000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Quarten",
    "PLZ": "8883",
    "Zusatzziffer": "0",
    "Gemeindename": "Quarten",
    "BFS-Nr": "3295",
    "Kantonskürzel": "SG",
    "E": "2736836.583999999",
    "N": "1219145.182",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberterzen",
    "PLZ": "8884",
    "Zusatzziffer": "0",
    "Gemeindename": "Quarten",
    "BFS-Nr": "3295",
    "Kantonskürzel": "SG",
    "E": "2737918.4990000017",
    "N": "1218464.1909999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mols",
    "PLZ": "8885",
    "Zusatzziffer": "0",
    "Gemeindename": "Quarten",
    "BFS-Nr": "3295",
    "Kantonskürzel": "SG",
    "E": "2739837.824000001",
    "N": "1219559.1669999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sargans",
    "PLZ": "7320",
    "Zusatzziffer": "0",
    "Gemeindename": "Sargans",
    "BFS-Nr": "3296",
    "Kantonskürzel": "SG",
    "E": "2751687.9120000005",
    "N": "1212963.7569999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wangs",
    "PLZ": "7323",
    "Zusatzziffer": "0",
    "Gemeindename": "Vilters-Wangs",
    "BFS-Nr": "3297",
    "Kantonskürzel": "SG",
    "E": "2754761.1149999984",
    "N": "1210914.8469999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wangs",
    "PLZ": "7323",
    "Zusatzziffer": "0",
    "Gemeindename": "Vilters-Wangs",
    "BFS-Nr": "3297",
    "Kantonskürzel": "SG",
    "E": "2751517.109000001",
    "N": "1210778.017000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vilters",
    "PLZ": "7324",
    "Zusatzziffer": "0",
    "Gemeindename": "Vilters-Wangs",
    "BFS-Nr": "3297",
    "Kantonskürzel": "SG",
    "E": "2752764.0549999997",
    "N": "1209964.4019999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walenstadt",
    "PLZ": "8880",
    "Zusatzziffer": "0",
    "Gemeindename": "Walenstadt",
    "BFS-Nr": "3298",
    "Kantonskürzel": "SG",
    "E": "2742353.7600000016",
    "N": "1220939.4979999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walenstadtberg",
    "PLZ": "8881",
    "Zusatzziffer": "1",
    "Gemeindename": "Walenstadt",
    "BFS-Nr": "3298",
    "Kantonskürzel": "SG",
    "E": "2740208.3770000003",
    "N": "1222224.460000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tscherlach",
    "PLZ": "8881",
    "Zusatzziffer": "6",
    "Gemeindename": "Walenstadt",
    "BFS-Nr": "3298",
    "Kantonskürzel": "SG",
    "E": "2743785.0029999986",
    "N": "1220344.368999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berschis",
    "PLZ": "8892",
    "Zusatzziffer": "0",
    "Gemeindename": "Walenstadt",
    "BFS-Nr": "3298",
    "Kantonskürzel": "SG",
    "E": "2744840.533",
    "N": "1218892.5769999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Amden",
    "PLZ": "8873",
    "Zusatzziffer": "0",
    "Gemeindename": "Amden",
    "BFS-Nr": "3311",
    "Kantonskürzel": "SG",
    "E": "2729468.0760000013",
    "N": "1223477.7380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Benken SG",
    "PLZ": "8717",
    "Zusatzziffer": "0",
    "Gemeindename": "Benken (SG)",
    "BFS-Nr": "3312",
    "Kantonskürzel": "SG",
    "E": "2718734.030000001",
    "N": "1228761.8740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaltbrunn",
    "PLZ": "8722",
    "Zusatzziffer": "0",
    "Gemeindename": "Kaltbrunn",
    "BFS-Nr": "3313",
    "Kantonskürzel": "SG",
    "E": "2720129.5190000013",
    "N": "1230436.9340000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schänis",
    "PLZ": "8718",
    "Zusatzziffer": "0",
    "Gemeindename": "Schänis",
    "BFS-Nr": "3315",
    "Kantonskürzel": "SG",
    "E": "2721833.4239999987",
    "N": "1224463.7239999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rufi",
    "PLZ": "8723",
    "Zusatzziffer": "0",
    "Gemeindename": "Schänis",
    "BFS-Nr": "3315",
    "Kantonskürzel": "SG",
    "E": "2722096.517000001",
    "N": "1226701.642000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Maseltrangen",
    "PLZ": "8723",
    "Zusatzziffer": "2",
    "Gemeindename": "Schänis",
    "BFS-Nr": "3315",
    "Kantonskürzel": "SG",
    "E": "2722071.4640000015",
    "N": "1228018.0830000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ziegelbrücke",
    "PLZ": "8866",
    "Zusatzziffer": "0",
    "Gemeindename": "Schänis",
    "BFS-Nr": "3315",
    "Kantonskürzel": "SG",
    "E": "2723013.9970000014",
    "N": "1221859.3040000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weesen",
    "PLZ": "8872",
    "Zusatzziffer": "0",
    "Gemeindename": "Weesen",
    "BFS-Nr": "3316",
    "Kantonskürzel": "SG",
    "E": "2725743.8220000006",
    "N": "1221809.2470000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schmerikon",
    "PLZ": "8716",
    "Zusatzziffer": "0",
    "Gemeindename": "Schmerikon",
    "BFS-Nr": "3338",
    "Kantonskürzel": "SG",
    "E": "2713769.3599999994",
    "N": "1231734.489",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uznach",
    "PLZ": "8730",
    "Zusatzziffer": "0",
    "Gemeindename": "Uznach",
    "BFS-Nr": "3339",
    "Kantonskürzel": "SG",
    "E": "2717210.2140000015",
    "N": "1231661.557",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rapperswil SG",
    "PLZ": "8640",
    "Zusatzziffer": "0",
    "Gemeindename": "Rapperswil-Jona",
    "BFS-Nr": "3340",
    "Kantonskürzel": "SG",
    "E": "2704353.875",
    "N": "1231656.8370000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jona",
    "PLZ": "8645",
    "Zusatzziffer": "0",
    "Gemeindename": "Rapperswil-Jona",
    "BFS-Nr": "3340",
    "Kantonskürzel": "SG",
    "E": "2705857.4409999996",
    "N": "1231849.6570000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wagen",
    "PLZ": "8646",
    "Zusatzziffer": "0",
    "Gemeindename": "Rapperswil-Jona",
    "BFS-Nr": "3340",
    "Kantonskürzel": "SG",
    "E": "2709604.9979999997",
    "N": "1232123.767000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bollingen",
    "PLZ": "8715",
    "Zusatzziffer": "0",
    "Gemeindename": "Rapperswil-Jona",
    "BFS-Nr": "3340",
    "Kantonskürzel": "SG",
    "E": "2710255.164000001",
    "N": "1230850.8969999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ernetschwil",
    "PLZ": "8725",
    "Zusatzziffer": "0",
    "Gemeindename": "Gommiswald",
    "BFS-Nr": "3341",
    "Kantonskürzel": "SG",
    "E": "2718408.8139999993",
    "N": "1232992.2419999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gebertingen",
    "PLZ": "8725",
    "Zusatzziffer": "2",
    "Gemeindename": "Gommiswald",
    "BFS-Nr": "3341",
    "Kantonskürzel": "SG",
    "E": "2718687.6939999983",
    "N": "1234648.511",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gommiswald",
    "PLZ": "8737",
    "Zusatzziffer": "0",
    "Gemeindename": "Gommiswald",
    "BFS-Nr": "3341",
    "Kantonskürzel": "SG",
    "E": "2719949.1900000013",
    "N": "1232349.9010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uetliburg SG",
    "PLZ": "8738",
    "Zusatzziffer": "0",
    "Gemeindename": "Gommiswald",
    "BFS-Nr": "3341",
    "Kantonskürzel": "SG",
    "E": "2720744.9400000013",
    "N": "1233026.2870000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rieden SG",
    "PLZ": "8739",
    "Zusatzziffer": "0",
    "Gemeindename": "Gommiswald",
    "BFS-Nr": "3341",
    "Kantonskürzel": "SG",
    "E": "2722086.6970000006",
    "N": "1231133.4420000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Goldingen",
    "PLZ": "8638",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenbach (SG)",
    "BFS-Nr": "3342",
    "Kantonskürzel": "SG",
    "E": "2715661.1050000004",
    "N": "1235779.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walde SG",
    "PLZ": "8727",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenbach (SG)",
    "BFS-Nr": "3342",
    "Kantonskürzel": "SG",
    "E": "2718951.039000001",
    "N": "1236677.7360000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuhaus SG",
    "PLZ": "8732",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenbach (SG)",
    "BFS-Nr": "3342",
    "Kantonskürzel": "SG",
    "E": "2714210.289999999",
    "N": "1233478.7410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eschenbach SG",
    "PLZ": "8733",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenbach (SG)",
    "BFS-Nr": "3342",
    "Kantonskürzel": "SG",
    "E": "2712150.0419999994",
    "N": "1233132.4030000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ermenswil",
    "PLZ": "8734",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenbach (SG)",
    "BFS-Nr": "3342",
    "Kantonskürzel": "SG",
    "E": "2709429.8139999993",
    "N": "1233676.8489999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Gallenkappel",
    "PLZ": "8735",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenbach (SG)",
    "BFS-Nr": "3342",
    "Kantonskürzel": "SG",
    "E": "2715847.550999999",
    "N": "1233849.7219999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüeterswil",
    "PLZ": "8735",
    "Zusatzziffer": "2",
    "Gemeindename": "Eschenbach (SG)",
    "BFS-Nr": "3342",
    "Kantonskürzel": "SG",
    "E": "2717369.6900000013",
    "N": "1235399.1609999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ebnat-Kappel",
    "PLZ": "9642",
    "Zusatzziffer": "0",
    "Gemeindename": "Ebnat-Kappel",
    "BFS-Nr": "3352",
    "Kantonskürzel": "SG",
    "E": "2727281.7870000005",
    "N": "1236159.0909999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alt St. Johann",
    "PLZ": "9656",
    "Zusatzziffer": "0",
    "Gemeindename": "Wildhaus-Alt St. Johann",
    "BFS-Nr": "3359",
    "Kantonskürzel": "SG",
    "E": "2739985.603",
    "N": "1228681.732999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterwasser",
    "PLZ": "9657",
    "Zusatzziffer": "0",
    "Gemeindename": "Wildhaus-Alt St. Johann",
    "BFS-Nr": "3359",
    "Kantonskürzel": "SG",
    "E": "2741690.9409999996",
    "N": "1229037.2809999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wildhaus",
    "PLZ": "9658",
    "Zusatzziffer": "0",
    "Gemeindename": "Wildhaus-Alt St. Johann",
    "BFS-Nr": "3359",
    "Kantonskürzel": "SG",
    "E": "2744862.011",
    "N": "1229854.2109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Krummenau",
    "PLZ": "9643",
    "Zusatzziffer": "0",
    "Gemeindename": "Nesslau",
    "BFS-Nr": "3360",
    "Kantonskürzel": "SG",
    "E": "2731315.2969999984",
    "N": "1234332.5610000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nesslau",
    "PLZ": "9650",
    "Zusatzziffer": "0",
    "Gemeindename": "Nesslau",
    "BFS-Nr": "3360",
    "Kantonskürzel": "SG",
    "E": "2733404.6970000006",
    "N": "1231828.9180000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ennetbühl",
    "PLZ": "9651",
    "Zusatzziffer": "0",
    "Gemeindename": "Nesslau",
    "BFS-Nr": "3360",
    "Kantonskürzel": "SG",
    "E": "2734248.348000001",
    "N": "1233730.7159999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neu St. Johann",
    "PLZ": "9652",
    "Zusatzziffer": "0",
    "Gemeindename": "Nesslau",
    "BFS-Nr": "3360",
    "Kantonskürzel": "SG",
    "E": "2732914.5100000016",
    "N": "1232394.9400000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stein SG",
    "PLZ": "9655",
    "Zusatzziffer": "0",
    "Gemeindename": "Nesslau",
    "BFS-Nr": "3360",
    "Kantonskürzel": "SG",
    "E": "2735586.6559999995",
    "N": "1229154.4640000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hemberg",
    "PLZ": "9633",
    "Zusatzziffer": "0",
    "Gemeindename": "Hemberg",
    "BFS-Nr": "3372",
    "Kantonskürzel": "SG",
    "E": "2731381.0309999995",
    "N": "1240317.4880000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bächli (Hemberg)",
    "PLZ": "9633",
    "Zusatzziffer": "2",
    "Gemeindename": "Hemberg",
    "BFS-Nr": "3372",
    "Kantonskürzel": "SG",
    "E": "2732809.572999999",
    "N": "1240882.9860000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lichtensteig",
    "PLZ": "9620",
    "Zusatzziffer": "0",
    "Gemeindename": "Lichtensteig",
    "BFS-Nr": "3374",
    "Kantonskürzel": "SG",
    "E": "2724748.6669999994",
    "N": "1242604.7309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhelfenschwil",
    "PLZ": "9621",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberhelfenschwil",
    "BFS-Nr": "3375",
    "Kantonskürzel": "SG",
    "E": "2726421.101",
    "N": "1246385.2270000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hoffeld",
    "PLZ": "9114",
    "Zusatzziffer": "0",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2731039.0549999997",
    "N": "1247149.2149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dicken",
    "PLZ": "9115",
    "Zusatzziffer": "0",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2732390.6950000003",
    "N": "1244391.039999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mogelsberg",
    "PLZ": "9122",
    "Zusatzziffer": "0",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2728392.649",
    "N": "1247021.5659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ebersol",
    "PLZ": "9122",
    "Zusatzziffer": "2",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2729398.726",
    "N": "1245783.9750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nassen",
    "PLZ": "9123",
    "Zusatzziffer": "0",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2728192.859000001",
    "N": "1248289.2300000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brunnadern",
    "PLZ": "9125",
    "Zusatzziffer": "0",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2727943.796",
    "N": "1244012.2109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Necker",
    "PLZ": "9126",
    "Zusatzziffer": "0",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2727811.162999999",
    "N": "1245513.068",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Peterzell",
    "PLZ": "9127",
    "Zusatzziffer": "0",
    "Gemeindename": "Neckertal",
    "BFS-Nr": "3378",
    "Kantonskürzel": "SG",
    "E": "2731184.8599999994",
    "N": "1242245.9230000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ricken SG",
    "PLZ": "8726",
    "Zusatzziffer": "0",
    "Gemeindename": "Wattwil",
    "BFS-Nr": "3379",
    "Kantonskürzel": "SG",
    "E": "2721665.594999999",
    "N": "1236169.0430000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Krinau",
    "PLZ": "9622",
    "Zusatzziffer": "0",
    "Gemeindename": "Wattwil",
    "BFS-Nr": "3379",
    "Kantonskürzel": "SG",
    "E": "2722004.785",
    "N": "1241965.1149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wattwil",
    "PLZ": "9630",
    "Zusatzziffer": "0",
    "Gemeindename": "Wattwil",
    "BFS-Nr": "3379",
    "Kantonskürzel": "SG",
    "E": "2724767.6209999993",
    "N": "1240580.6559999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ulisbach",
    "PLZ": "9631",
    "Zusatzziffer": "0",
    "Gemeindename": "Wattwil",
    "BFS-Nr": "3379",
    "Kantonskürzel": "SG",
    "E": "2725744.879999999",
    "N": "1238593.011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wil SG",
    "PLZ": "9500",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchberg (SG)",
    "BFS-Nr": "3392",
    "Kantonskürzel": "SG",
    "E": "2721813.631000001",
    "N": "1255725.5379999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kirchberg SG",
    "PLZ": "9533",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchberg (SG)",
    "BFS-Nr": "3392",
    "Kantonskürzel": "SG",
    "E": "2720899.4180000015",
    "N": "1252455.2069999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dietschwil",
    "PLZ": "9533",
    "Zusatzziffer": "1",
    "Gemeindename": "Kirchberg (SG)",
    "BFS-Nr": "3392",
    "Kantonskürzel": "SG",
    "E": "2719121.34",
    "N": "1253413.3550000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gähwil",
    "PLZ": "9534",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchberg (SG)",
    "BFS-Nr": "3392",
    "Kantonskürzel": "SG",
    "E": "2718011.995000001",
    "N": "1250894.164999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bazenheid",
    "PLZ": "9602",
    "Zusatzziffer": "0",
    "Gemeindename": "Kirchberg (SG)",
    "BFS-Nr": "3392",
    "Kantonskürzel": "SG",
    "E": "2723016.2919999994",
    "N": "1252273.4699999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Müselbach",
    "PLZ": "9602",
    "Zusatzziffer": "2",
    "Gemeindename": "Kirchberg (SG)",
    "BFS-Nr": "3392",
    "Kantonskürzel": "SG",
    "E": "2721009.5769999996",
    "N": "1250096.1229999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lütisburg Station",
    "PLZ": "9601",
    "Zusatzziffer": "0",
    "Gemeindename": "Lütisburg",
    "BFS-Nr": "3393",
    "Kantonskürzel": "SG",
    "E": "2723263.234000001",
    "N": "1249510.4800000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lütisburg",
    "PLZ": "9604",
    "Zusatzziffer": "0",
    "Gemeindename": "Lütisburg",
    "BFS-Nr": "3393",
    "Kantonskürzel": "SG",
    "E": "2723869.629999999",
    "N": "1250652.2950000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterrindal",
    "PLZ": "9604",
    "Zusatzziffer": "2",
    "Gemeindename": "Lütisburg",
    "BFS-Nr": "3393",
    "Kantonskürzel": "SG",
    "E": "2724639.640999999",
    "N": "1252229.6640000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mosnang",
    "PLZ": "9607",
    "Zusatzziffer": "0",
    "Gemeindename": "Mosnang",
    "BFS-Nr": "3394",
    "Kantonskürzel": "SG",
    "E": "2720946.9809999987",
    "N": "1246969.5089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dreien",
    "PLZ": "9612",
    "Zusatzziffer": "0",
    "Gemeindename": "Mosnang",
    "BFS-Nr": "3394",
    "Kantonskürzel": "SG",
    "E": "2719194.743999999",
    "N": "1247976.6319999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühlrüti",
    "PLZ": "9613",
    "Zusatzziffer": "0",
    "Gemeindename": "Mosnang",
    "BFS-Nr": "3394",
    "Kantonskürzel": "SG",
    "E": "2716857.6939999983",
    "N": "1247796.2270000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Libingen",
    "PLZ": "9614",
    "Zusatzziffer": "0",
    "Gemeindename": "Mosnang",
    "BFS-Nr": "3394",
    "Kantonskürzel": "SG",
    "E": "2719702.454999998",
    "N": "1243210.5280000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bütschwil",
    "PLZ": "9606",
    "Zusatzziffer": "0",
    "Gemeindename": "Bütschwil-Ganterschwil",
    "BFS-Nr": "3395",
    "Kantonskürzel": "SG",
    "E": "2723123.3110000007",
    "N": "1246626.4010000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ganterschwil",
    "PLZ": "9608",
    "Zusatzziffer": "0",
    "Gemeindename": "Bütschwil-Ganterschwil",
    "BFS-Nr": "3395",
    "Kantonskürzel": "SG",
    "E": "2724712.890999999",
    "N": "1249120.8669999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dietfurt",
    "PLZ": "9615",
    "Zusatzziffer": "0",
    "Gemeindename": "Bütschwil-Ganterschwil",
    "BFS-Nr": "3395",
    "Kantonskürzel": "SG",
    "E": "2723937.5929999985",
    "N": "1245300.4090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Degersheim",
    "PLZ": "9113",
    "Zusatzziffer": "0",
    "Gemeindename": "Degersheim",
    "BFS-Nr": "3401",
    "Kantonskürzel": "SG",
    "E": "2732787.9809999987",
    "N": "1248437.5020000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wolfertswil",
    "PLZ": "9116",
    "Zusatzziffer": "0",
    "Gemeindename": "Degersheim",
    "BFS-Nr": "3401",
    "Kantonskürzel": "SG",
    "E": "2731729.482999999",
    "N": "1250766.1039999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flawil",
    "PLZ": "9230",
    "Zusatzziffer": "0",
    "Gemeindename": "Flawil",
    "BFS-Nr": "3402",
    "Kantonskürzel": "SG",
    "E": "2731685.1380000003",
    "N": "1253259.0390000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Egg (Flawil)",
    "PLZ": "9231",
    "Zusatzziffer": "0",
    "Gemeindename": "Flawil",
    "BFS-Nr": "3402",
    "Kantonskürzel": "SG",
    "E": "2734966.9360000007",
    "N": "1250519.4990000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jonschwil",
    "PLZ": "9243",
    "Zusatzziffer": "0",
    "Gemeindename": "Jonschwil",
    "BFS-Nr": "3405",
    "Kantonskürzel": "SG",
    "E": "2724466.598000001",
    "N": "1253896.3220000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach b. Wil",
    "PLZ": "9532",
    "Zusatzziffer": "0",
    "Gemeindename": "Jonschwil",
    "BFS-Nr": "3405",
    "Kantonskürzel": "SG",
    "E": "2722540.5",
    "N": "1255019.6959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwarzenbach SG",
    "PLZ": "9536",
    "Zusatzziffer": "0",
    "Gemeindename": "Jonschwil",
    "BFS-Nr": "3405",
    "Kantonskürzel": "SG",
    "E": "2723526.3839999996",
    "N": "1255767.4200000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberrindal",
    "PLZ": "9604",
    "Zusatzziffer": "1",
    "Gemeindename": "Jonschwil",
    "BFS-Nr": "3405",
    "Kantonskürzel": "SG",
    "E": "2726820.745000001",
    "N": "1252139.6779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederglatt SG",
    "PLZ": "9240",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberuzwil",
    "BFS-Nr": "3407",
    "Kantonskürzel": "SG",
    "E": "2730733.9250000007",
    "N": "1254800.7399999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberuzwil",
    "PLZ": "9242",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberuzwil",
    "BFS-Nr": "3407",
    "Kantonskürzel": "SG",
    "E": "2726887.5469999984",
    "N": "1254734.5709999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bichwil",
    "PLZ": "9248",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberuzwil",
    "BFS-Nr": "3407",
    "Kantonskürzel": "SG",
    "E": "2728145.579999998",
    "N": "1253399.5960000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uzwil",
    "PLZ": "9240",
    "Zusatzziffer": "0",
    "Gemeindename": "Uzwil",
    "BFS-Nr": "3408",
    "Kantonskürzel": "SG",
    "E": "2727676.0549999997",
    "N": "1255362.8669999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederuzwil",
    "PLZ": "9244",
    "Zusatzziffer": "0",
    "Gemeindename": "Uzwil",
    "BFS-Nr": "3408",
    "Kantonskürzel": "SG",
    "E": "2728488.8759999983",
    "N": "1256266.4200000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Henau",
    "PLZ": "9247",
    "Zusatzziffer": "0",
    "Gemeindename": "Uzwil",
    "BFS-Nr": "3408",
    "Kantonskürzel": "SG",
    "E": "2726752.8630000018",
    "N": "1257059.1900000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Algetshausen",
    "PLZ": "9249",
    "Zusatzziffer": "0",
    "Gemeindename": "Uzwil",
    "BFS-Nr": "3408",
    "Kantonskürzel": "SG",
    "E": "2725828.2190000005",
    "N": "1256557.4059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederstetten",
    "PLZ": "9249",
    "Zusatzziffer": "1",
    "Gemeindename": "Uzwil",
    "BFS-Nr": "3408",
    "Kantonskürzel": "SG",
    "E": "2724027.414000001",
    "N": "1256911.3489999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberstetten",
    "PLZ": "9249",
    "Zusatzziffer": "2",
    "Gemeindename": "Uzwil",
    "BFS-Nr": "3408",
    "Kantonskürzel": "SG",
    "E": "2724488.3559999987",
    "N": "1256614.9869999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederbüren",
    "PLZ": "9246",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederbüren",
    "BFS-Nr": "3422",
    "Kantonskürzel": "SG",
    "E": "2733378.4739999995",
    "N": "1258649.232999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lenggenwil",
    "PLZ": "9525",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederhelfenschwil",
    "BFS-Nr": "3423",
    "Kantonskürzel": "SG",
    "E": "2728965.6240000017",
    "N": "1259682.745000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zuckenriet",
    "PLZ": "9526",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederhelfenschwil",
    "BFS-Nr": "3423",
    "Kantonskürzel": "SG",
    "E": "2729929.4290000014",
    "N": "1260712.425999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederhelfenschwil",
    "PLZ": "9527",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederhelfenschwil",
    "BFS-Nr": "3423",
    "Kantonskürzel": "SG",
    "E": "2731791.857000001",
    "N": "1259845.0130000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederwil SG",
    "PLZ": "9203",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberbüren",
    "BFS-Nr": "3424",
    "Kantonskürzel": "SG",
    "E": "2732921.0029999986",
    "N": "1255773.1180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberbüren",
    "PLZ": "9245",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberbüren",
    "BFS-Nr": "3424",
    "Kantonskürzel": "SG",
    "E": "2729924.1510000005",
    "N": "1257023.085000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sonnental",
    "PLZ": "9245",
    "Zusatzziffer": "2",
    "Gemeindename": "Oberbüren",
    "BFS-Nr": "3424",
    "Kantonskürzel": "SG",
    "E": "2728020.030000001",
    "N": "1257984.3269999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Züberwangen",
    "PLZ": "9523",
    "Zusatzziffer": "0",
    "Gemeindename": "Zuzwil (SG)",
    "BFS-Nr": "3426",
    "Kantonskürzel": "SG",
    "E": "2724033.6909999996",
    "N": "1258544.9079999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zuzwil SG",
    "PLZ": "9524",
    "Zusatzziffer": "0",
    "Gemeindename": "Zuzwil (SG)",
    "BFS-Nr": "3426",
    "Kantonskürzel": "SG",
    "E": "2725995.370000001",
    "N": "1259665.4649999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wil SG",
    "PLZ": "9500",
    "Zusatzziffer": "0",
    "Gemeindename": "Wil (SG)",
    "BFS-Nr": "3427",
    "Kantonskürzel": "SG",
    "E": "2721438.5430000015",
    "N": "1258543.7479999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rossrüti",
    "PLZ": "9512",
    "Zusatzziffer": "0",
    "Gemeindename": "Wil (SG)",
    "BFS-Nr": "3427",
    "Kantonskürzel": "SG",
    "E": "2722265.8680000007",
    "N": "1259414.8189999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bronschhofen",
    "PLZ": "9552",
    "Zusatzziffer": "0",
    "Gemeindename": "Wil (SG)",
    "BFS-Nr": "3427",
    "Kantonskürzel": "SG",
    "E": "2720274.721000001",
    "N": "1259564.0760000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Andwil SG",
    "PLZ": "9204",
    "Zusatzziffer": "0",
    "Gemeindename": "Andwil (SG)",
    "BFS-Nr": "3441",
    "Kantonskürzel": "SG",
    "E": "2738125.3759999983",
    "N": "1255900.3299999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Abtwil SG",
    "PLZ": "9030",
    "Zusatzziffer": "0",
    "Gemeindename": "Gaiserwald",
    "BFS-Nr": "3442",
    "Kantonskürzel": "SG",
    "E": "2742274.052000001",
    "N": "1254032.2650000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Josefen",
    "PLZ": "9030",
    "Zusatzziffer": "3",
    "Gemeindename": "Gaiserwald",
    "BFS-Nr": "3442",
    "Kantonskürzel": "SG",
    "E": "2743160.5419999994",
    "N": "1254384.4580000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Engelburg",
    "PLZ": "9032",
    "Zusatzziffer": "0",
    "Gemeindename": "Gaiserwald",
    "BFS-Nr": "3442",
    "Kantonskürzel": "SG",
    "E": "2743494.5540000014",
    "N": "1256510.4789999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gossau SG",
    "PLZ": "9200",
    "Zusatzziffer": "0",
    "Gemeindename": "Gossau (SG)",
    "BFS-Nr": "3443",
    "Kantonskürzel": "SG",
    "E": "2736659.3310000002",
    "N": "1253195.436999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arnegg",
    "PLZ": "9212",
    "Zusatzziffer": "0",
    "Gemeindename": "Gossau (SG)",
    "BFS-Nr": "3443",
    "Kantonskürzel": "SG",
    "E": "2736773.579",
    "N": "1256158.5670000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Waldkirch",
    "PLZ": "9205",
    "Zusatzziffer": "0",
    "Gemeindename": "Waldkirch",
    "BFS-Nr": "3444",
    "Kantonskürzel": "SG",
    "E": "2739183.8269999996",
    "N": "1259277.495000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bernhardzell",
    "PLZ": "9304",
    "Zusatzziffer": "0",
    "Gemeindename": "Waldkirch",
    "BFS-Nr": "3444",
    "Kantonskürzel": "SG",
    "E": "2743061.4400000013",
    "N": "1259841.9690000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Valbella",
    "PLZ": "7077",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaz/Obervaz",
    "BFS-Nr": "3506",
    "Kantonskürzel": "GR",
    "E": "2761602.710000001",
    "N": "1179546.5359999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Lenzerheide/Lai",
    "PLZ": "7078",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaz/Obervaz",
    "BFS-Nr": "3506",
    "Kantonskürzel": "GR",
    "E": "2761811.215999998",
    "N": "1177572.2490000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Vaz/Obervaz",
    "PLZ": "7082",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaz/Obervaz",
    "BFS-Nr": "3506",
    "Kantonskürzel": "GR",
    "E": "2760063.7399999984",
    "N": "1173769.6460000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tiefencastel",
    "PLZ": "7450",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaz/Obervaz",
    "BFS-Nr": "3506",
    "Kantonskürzel": "GR",
    "E": "2758802.6909999996",
    "N": "1172183.2749999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Lantsch/Lenz",
    "PLZ": "7083",
    "Zusatzziffer": "0",
    "Gemeindename": "Lantsch/Lenz",
    "BFS-Nr": "3513",
    "Kantonskürzel": "GR",
    "E": "2762461.265999999",
    "N": "1172406.7749999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Schmitten (Albula)",
    "PLZ": "7493",
    "Zusatzziffer": "0",
    "Gemeindename": "Schmitten (GR)",
    "BFS-Nr": "3514",
    "Kantonskürzel": "GR",
    "E": "2771235.875",
    "N": "1173050.7049999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brienz/Brinzauls GR",
    "PLZ": "7084",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2764988.9189999998",
    "N": "1170863.4409999996",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tiefencastel",
    "PLZ": "7450",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2763489.9059999995",
    "N": "1170044.9349999987",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Alvaschein",
    "PLZ": "7451",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2761716.5029999986",
    "N": "1171428.6939999983",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Mon",
    "PLZ": "7458",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2762514.4400000013",
    "N": "1168780.2710000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Stierva",
    "PLZ": "7459",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2760905.094999999",
    "N": "1170256.5749999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Surava",
    "PLZ": "7472",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2766283.030000001",
    "N": "1170537.4160000011",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Alvaneu Bad",
    "PLZ": "7473",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2768974.0929999985",
    "N": "1171003.210000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Alvaneu Dorf",
    "PLZ": "7492",
    "Zusatzziffer": "0",
    "Gemeindename": "Albula/Alvra",
    "BFS-Nr": "3542",
    "Kantonskürzel": "GR",
    "E": "2768828.8539999984",
    "N": "1172187.7850000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Cunter",
    "PLZ": "7452",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2765236.039999999",
    "N": "1164243.3929999992",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tinizong",
    "PLZ": "7453",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2767131.004999999",
    "N": "1161470.4990000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rona",
    "PLZ": "7454",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2767426.976",
    "N": "1158995.2199999988",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Mulegns",
    "PLZ": "7455",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2767462.469999999",
    "N": "1154972.427000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sur",
    "PLZ": "7456",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2768245.0670000017",
    "N": "1154813.4050000012",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Marmorera",
    "PLZ": "7456",
    "Zusatzziffer": "2",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2769222.421",
    "N": "1151920.1099999994",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Bivio",
    "PLZ": "7457",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2769876.186999999",
    "N": "1148700.8139999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Savognin",
    "PLZ": "7460",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2765807.6059999987",
    "N": "1162771.8159999996",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Salouf",
    "PLZ": "7462",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2763424.1810000017",
    "N": "1165895.447999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Riom",
    "PLZ": "7463",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2764956.75",
    "N": "1166935.875",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Riom",
    "PLZ": "7463",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2764250.8539999984",
    "N": "1164067.0639999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Parsonz",
    "PLZ": "7464",
    "Zusatzziffer": "0",
    "Gemeindename": "Surses",
    "BFS-Nr": "3543",
    "Kantonskürzel": "GR",
    "E": "2763237.8390000015",
    "N": "1164158.8029999994",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Filisur",
    "PLZ": "7477",
    "Zusatzziffer": "0",
    "Gemeindename": "Bergün Filisur",
    "BFS-Nr": "3544",
    "Kantonskürzel": "GR",
    "E": "2772145.853",
    "N": "1171699.9549999982",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Bergün/Bravuogn",
    "PLZ": "7482",
    "Zusatzziffer": "0",
    "Gemeindename": "Bergün Filisur",
    "BFS-Nr": "3544",
    "Kantonskürzel": "GR",
    "E": "2776594.1959999986",
    "N": "1166810.9400000013",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Preda",
    "PLZ": "7482",
    "Zusatzziffer": "1",
    "Gemeindename": "Bergün Filisur",
    "BFS-Nr": "3544",
    "Kantonskürzel": "GR",
    "E": "2779191.3660000004",
    "N": "1162574.824000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Stugl/Stuls",
    "PLZ": "7482",
    "Zusatzziffer": "2",
    "Gemeindename": "Bergün Filisur",
    "BFS-Nr": "3544",
    "Kantonskürzel": "GR",
    "E": "2775606.9979999997",
    "N": "1169096.4979999997",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Latsch",
    "PLZ": "7484",
    "Zusatzziffer": "0",
    "Gemeindename": "Bergün Filisur",
    "BFS-Nr": "3544",
    "Kantonskürzel": "GR",
    "E": "2777225.3649999984",
    "N": "1167498.800999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Brusio",
    "PLZ": "7743",
    "Zusatzziffer": "0",
    "Gemeindename": "Brusio",
    "BFS-Nr": "3551",
    "Kantonskürzel": "GR",
    "E": "2807046.833999999",
    "N": "1126556.0390000008",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Campocologno",
    "PLZ": "7744",
    "Zusatzziffer": "0",
    "Gemeindename": "Brusio",
    "BFS-Nr": "3551",
    "Kantonskürzel": "GR",
    "E": "2808496.25",
    "N": "1123800.438000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Viano",
    "PLZ": "7747",
    "Zusatzziffer": "0",
    "Gemeindename": "Brusio",
    "BFS-Nr": "3551",
    "Kantonskürzel": "GR",
    "E": "2808259.789999999",
    "N": "1126062.1229999997",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Campascio",
    "PLZ": "7748",
    "Zusatzziffer": "0",
    "Gemeindename": "Brusio",
    "BFS-Nr": "3551",
    "Kantonskürzel": "GR",
    "E": "2807520.8390000015",
    "N": "1125091.960000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ospizio Bernina",
    "PLZ": "7710",
    "Zusatzziffer": "0",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2798602.3960000016",
    "N": "1143192.2950000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Alp Grüm",
    "PLZ": "7710",
    "Zusatzziffer": "2",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2799570.375",
    "N": "1139232.125",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Carlo (Poschiavo)",
    "PLZ": "7741",
    "Zusatzziffer": "0",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2802101.653000001",
    "N": "1135868.9030000009",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Poschiavo",
    "PLZ": "7742",
    "Zusatzziffer": "0",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2801994.7349999994",
    "N": "1133836.9849999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sfazù",
    "PLZ": "7742",
    "Zusatzziffer": "2",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2803223.8390000015",
    "N": "1140939.9349999987",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "La Rösa",
    "PLZ": "7742",
    "Zusatzziffer": "3",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2802237.905000001",
    "N": "1142272.2479999997",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Miralago",
    "PLZ": "7743",
    "Zusatzziffer": "2",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2805172.125",
    "N": "1128109",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Li Curt",
    "PLZ": "7745",
    "Zusatzziffer": "0",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2802101.879999999",
    "N": "1132190.5040000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Le Prese",
    "PLZ": "7746",
    "Zusatzziffer": "0",
    "Gemeindename": "Poschiavo",
    "BFS-Nr": "3561",
    "Kantonskürzel": "GR",
    "E": "2803541.6779999994",
    "N": "1130289.2529999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Falera",
    "PLZ": "7153",
    "Zusatzziffer": "0",
    "Gemeindename": "Falera",
    "BFS-Nr": "3572",
    "Kantonskürzel": "GR",
    "E": "2736863.6849999987",
    "N": "1184797.2719999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Laax GR",
    "PLZ": "7031",
    "Zusatzziffer": "0",
    "Gemeindename": "Laax",
    "BFS-Nr": "3575",
    "Kantonskürzel": "GR",
    "E": "2738529.9560000002",
    "N": "1185263.6860000007",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Laax GR 2",
    "PLZ": "7032",
    "Zusatzziffer": "0",
    "Gemeindename": "Laax",
    "BFS-Nr": "3575",
    "Kantonskürzel": "GR",
    "E": "2739287.0810000002",
    "N": "1187268.2950000018",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sagogn",
    "PLZ": "7152",
    "Zusatzziffer": "0",
    "Gemeindename": "Sagogn",
    "BFS-Nr": "3581",
    "Kantonskürzel": "GR",
    "E": "2738602.2980000004",
    "N": "1183586.539999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Schluein",
    "PLZ": "7151",
    "Zusatzziffer": "0",
    "Gemeindename": "Schluein",
    "BFS-Nr": "3582",
    "Kantonskürzel": "GR",
    "E": "2736641.162999999",
    "N": "1183519.1860000007",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "St. Martin (Lugnez)",
    "PLZ": "7116",
    "Zusatzziffer": "2",
    "Gemeindename": "Vals",
    "BFS-Nr": "3603",
    "Kantonskürzel": "GR",
    "E": "2733512.0599999987",
    "N": "1170646.4710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vals",
    "PLZ": "7132",
    "Zusatzziffer": "0",
    "Gemeindename": "Vals",
    "BFS-Nr": "3603",
    "Kantonskürzel": "GR",
    "E": "2733223.4439999983",
    "N": "1164508.9899999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Peiden",
    "PLZ": "7110",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2734348.0590000004",
    "N": "1175817.4849999994",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Camuns",
    "PLZ": "7113",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2734489.9109999985",
    "N": "1173993.3550000004",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Uors (Lumnezia)",
    "PLZ": "7114",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2733408.4990000017",
    "N": "1173710.6039999984",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Surcasti",
    "PLZ": "7115",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2732938.449000001",
    "N": "1173202.2540000007",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tersnaus",
    "PLZ": "7116",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2733552.482999999",
    "N": "1172814.7859999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Cumbel",
    "PLZ": "7142",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2734020.3550000004",
    "N": "1176478.9780000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Morissen",
    "PLZ": "7143",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2733157.9560000002",
    "N": "1176884.1400000006",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Vella",
    "PLZ": "7144",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2732637.859000001",
    "N": "1175543.9389999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Degen",
    "PLZ": "7145",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2732240.1229999997",
    "N": "1174199.1689999998",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Vattiz",
    "PLZ": "7146",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2731641.0080000013",
    "N": "1174273.6550000012",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Vignogn",
    "PLZ": "7147",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2731373.710999999",
    "N": "1173445.4899999984",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Lumbrein",
    "PLZ": "7148",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2729843.3999999985",
    "N": "1171655.8060000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Vrin",
    "PLZ": "7149",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumnezia",
    "BFS-Nr": "3618",
    "Kantonskürzel": "GR",
    "E": "2727106.739",
    "N": "1168446.550999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Pitasch",
    "PLZ": "7111",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2735916.579999998",
    "N": "1177056.0960000008",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Duvin",
    "PLZ": "7112",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2735524.3779999986",
    "N": "1175306.7010000013",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Castrisch",
    "PLZ": "7126",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2736913.0309999995",
    "N": "1182218.9910000004",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sevgein",
    "PLZ": "7127",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2736166.109000001",
    "N": "1180897.6739999987",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Riein",
    "PLZ": "7128",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2737120.9439999983",
    "N": "1178581.2250000015",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ilanz",
    "PLZ": "7130",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2734759.795000002",
    "N": "1181743.6849999987",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Schnaus",
    "PLZ": "7130",
    "Zusatzziffer": "2",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2732962.75",
    "N": "1187183.688000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Schnaus",
    "PLZ": "7130",
    "Zusatzziffer": "2",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2733028.2630000003",
    "N": "1182135.254999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Luven",
    "PLZ": "7141",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2734458.458999999",
    "N": "1180531.6380000003",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ruschein",
    "PLZ": "7154",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2733865.5590000004",
    "N": "1183044.2509999983",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ladir",
    "PLZ": "7155",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2734828.8940000013",
    "N": "1183563.5709999986",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ladir",
    "PLZ": "7155",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2732439.75",
    "N": "1189009.5",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rueun",
    "PLZ": "7156",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2730842.219999999",
    "N": "1182241.4079999998",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Pigniu",
    "PLZ": "7156",
    "Zusatzziffer": "1",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2728080.6050000004",
    "N": "1185558.1959999986",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Siat",
    "PLZ": "7157",
    "Zusatzziffer": "0",
    "Gemeindename": "Ilanz/Glion",
    "BFS-Nr": "3619",
    "Kantonskürzel": "GR",
    "E": "2731687.2010000013",
    "N": "1183638.5599999987",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Fürstenaubruck",
    "PLZ": "7413",
    "Zusatzziffer": "0",
    "Gemeindename": "Fürstenau",
    "BFS-Nr": "3633",
    "Kantonskürzel": "GR",
    "E": "2753801.5",
    "N": "1175595.8850000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Fürstenau",
    "PLZ": "7414",
    "Zusatzziffer": "0",
    "Gemeindename": "Fürstenau",
    "BFS-Nr": "3633",
    "Kantonskürzel": "GR",
    "E": "2753659.9789999984",
    "N": "1176327.3740000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rothenbrunnen",
    "PLZ": "7405",
    "Zusatzziffer": "0",
    "Gemeindename": "Rothenbrunnen",
    "BFS-Nr": "3637",
    "Kantonskürzel": "GR",
    "E": "2751846.659000002",
    "N": "1181744.2529999986",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Scharans",
    "PLZ": "7412",
    "Zusatzziffer": "0",
    "Gemeindename": "Scharans",
    "BFS-Nr": "3638",
    "Kantonskürzel": "GR",
    "E": "2754486.1510000005",
    "N": "1176078.011",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sils im Domleschg",
    "PLZ": "7411",
    "Zusatzziffer": "0",
    "Gemeindename": "Sils im Domleschg",
    "BFS-Nr": "3640",
    "Kantonskürzel": "GR",
    "E": "2754159.090999998",
    "N": "1174050.1279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Cazis",
    "PLZ": "7408",
    "Zusatzziffer": "0",
    "Gemeindename": "Cazis",
    "BFS-Nr": "3661",
    "Kantonskürzel": "GR",
    "E": "2752205.2179999985",
    "N": "1176371.5929999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Summaprada",
    "PLZ": "7421",
    "Zusatzziffer": "0",
    "Gemeindename": "Cazis",
    "BFS-Nr": "3661",
    "Kantonskürzel": "GR",
    "E": "2752523.284000002",
    "N": "1175595.3420000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tartar",
    "PLZ": "7422",
    "Zusatzziffer": "0",
    "Gemeindename": "Cazis",
    "BFS-Nr": "3661",
    "Kantonskürzel": "GR",
    "E": "2751332.721000001",
    "N": "1176078.5249999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sarn",
    "PLZ": "7423",
    "Zusatzziffer": "0",
    "Gemeindename": "Cazis",
    "BFS-Nr": "3661",
    "Kantonskürzel": "GR",
    "E": "2750693.6499999985",
    "N": "1176166.5879999995",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Portein",
    "PLZ": "7423",
    "Zusatzziffer": "2",
    "Gemeindename": "Cazis",
    "BFS-Nr": "3661",
    "Kantonskürzel": "GR",
    "E": "2750499.3539999984",
    "N": "1175312.199000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Präz",
    "PLZ": "7424",
    "Zusatzziffer": "0",
    "Gemeindename": "Cazis",
    "BFS-Nr": "3661",
    "Kantonskürzel": "GR",
    "E": "2750334.5",
    "N": "1178615.3720000014",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Dalin",
    "PLZ": "7424",
    "Zusatzziffer": "1",
    "Gemeindename": "Cazis",
    "BFS-Nr": "3661",
    "Kantonskürzel": "GR",
    "E": "2750227.4169999994",
    "N": "1177871.9530000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Flerden",
    "PLZ": "7426",
    "Zusatzziffer": "0",
    "Gemeindename": "Flerden",
    "BFS-Nr": "3662",
    "Kantonskürzel": "GR",
    "E": "2749768.4690000005",
    "N": "1171016.4800000004",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Flerden",
    "PLZ": "7426",
    "Zusatzziffer": "0",
    "Gemeindename": "Flerden",
    "BFS-Nr": "3662",
    "Kantonskürzel": "GR",
    "E": "2750379.3779999986",
    "N": "1174383.9860000014",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Masein",
    "PLZ": "7425",
    "Zusatzziffer": "0",
    "Gemeindename": "Masein",
    "BFS-Nr": "3663",
    "Kantonskürzel": "GR",
    "E": "2752030.767999999",
    "N": "1174567.7980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thusis",
    "PLZ": "7430",
    "Zusatzziffer": "0",
    "Gemeindename": "Thusis",
    "BFS-Nr": "3668",
    "Kantonskürzel": "GR",
    "E": "2752974.846000001",
    "N": "1173412.4919999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mutten",
    "PLZ": "7431",
    "Zusatzziffer": "1",
    "Gemeindename": "Thusis",
    "BFS-Nr": "3668",
    "Kantonskürzel": "GR",
    "E": "2757518.864",
    "N": "1171841.5839999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obermutten",
    "PLZ": "7431",
    "Zusatzziffer": "2",
    "Gemeindename": "Thusis",
    "BFS-Nr": "3668",
    "Kantonskürzel": "GR",
    "E": "2756676.3330000006",
    "N": "1170896.5639999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tschappina",
    "PLZ": "7428",
    "Zusatzziffer": "0",
    "Gemeindename": "Tschappina",
    "BFS-Nr": "3669",
    "Kantonskürzel": "GR",
    "E": "2747905.620000001",
    "N": "1172431.9129999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Urmein",
    "PLZ": "7427",
    "Zusatzziffer": "0",
    "Gemeindename": "Urmein",
    "BFS-Nr": "3670",
    "Kantonskürzel": "GR",
    "E": "2750072.526999999",
    "N": "1173049.8399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Versam",
    "PLZ": "7104",
    "Zusatzziffer": "0",
    "Gemeindename": "Safiental",
    "BFS-Nr": "3672",
    "Kantonskürzel": "GR",
    "E": "2744873.6680000015",
    "N": "1184316.4739999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tenna",
    "PLZ": "7106",
    "Zusatzziffer": "0",
    "Gemeindename": "Safiental",
    "BFS-Nr": "3672",
    "Kantonskürzel": "GR",
    "E": "2744986.1149999984",
    "N": "1179117.9690000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Safien Platz",
    "PLZ": "7107",
    "Zusatzziffer": "0",
    "Gemeindename": "Safiental",
    "BFS-Nr": "3672",
    "Kantonskürzel": "GR",
    "E": "2743555.3489999995",
    "N": "1171930.3559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thalkirch",
    "PLZ": "7109",
    "Zusatzziffer": "0",
    "Gemeindename": "Safiental",
    "BFS-Nr": "3672",
    "Kantonskürzel": "GR",
    "E": "2741018.8049999997",
    "N": "1167019.3139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Valendas",
    "PLZ": "7122",
    "Zusatzziffer": "0",
    "Gemeindename": "Safiental",
    "BFS-Nr": "3672",
    "Kantonskürzel": "GR",
    "E": "2740739.8489999995",
    "N": "1183576.4189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Feldis/Veulden",
    "PLZ": "7404",
    "Zusatzziffer": "0",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2752227.041000001",
    "N": "1184316.1240000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Trans",
    "PLZ": "7407",
    "Zusatzziffer": "0",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2754673.6950000003",
    "N": "1181190.438000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rodels",
    "PLZ": "7415",
    "Zusatzziffer": "0",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2753340.425999999",
    "N": "1177987.4739999995",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Pratval",
    "PLZ": "7415",
    "Zusatzziffer": "2",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2753404.3079999983",
    "N": "1177585.9519999996",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Almens",
    "PLZ": "7416",
    "Zusatzziffer": "0",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2754407.892999999",
    "N": "1178306.989",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Paspels",
    "PLZ": "7417",
    "Zusatzziffer": "0",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2753256.8729999997",
    "N": "1179651.0210000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tumegl/Tomils",
    "PLZ": "7418",
    "Zusatzziffer": "0",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2752873.416000001",
    "N": "1180851.2490000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Scheid",
    "PLZ": "7419",
    "Zusatzziffer": "0",
    "Gemeindename": "Domleschg",
    "BFS-Nr": "3673",
    "Kantonskürzel": "GR",
    "E": "2753673.4979999997",
    "N": "1182734.164999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Campsut-Cröt",
    "PLZ": "7446",
    "Zusatzziffer": "0",
    "Gemeindename": "Avers",
    "BFS-Nr": "3681",
    "Kantonskürzel": "GR",
    "E": "2756779.3740000017",
    "N": "1149887.0040000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Cresta (Avers)",
    "PLZ": "7447",
    "Zusatzziffer": "0",
    "Gemeindename": "Avers",
    "BFS-Nr": "3681",
    "Kantonskürzel": "GR",
    "E": "2759371.9239999987",
    "N": "1148977.699000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Am Bach (Avers)",
    "PLZ": "7447",
    "Zusatzziffer": "1",
    "Gemeindename": "Avers",
    "BFS-Nr": "3681",
    "Kantonskürzel": "GR",
    "E": "2761890.0430000015",
    "N": "1146737.1939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Juf",
    "PLZ": "7448",
    "Zusatzziffer": "0",
    "Gemeindename": "Avers",
    "BFS-Nr": "3681",
    "Kantonskürzel": "GR",
    "E": "2764518.039999999",
    "N": "1146019.2740000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sufers",
    "PLZ": "7434",
    "Zusatzziffer": "0",
    "Gemeindename": "Sufers",
    "BFS-Nr": "3695",
    "Kantonskürzel": "GR",
    "E": "2747819.8090000004",
    "N": "1159696.4409999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Andeer",
    "PLZ": "7440",
    "Zusatzziffer": "0",
    "Gemeindename": "Andeer",
    "BFS-Nr": "3701",
    "Kantonskürzel": "GR",
    "E": "2752165.7509999983",
    "N": "1163082.193",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Clugin",
    "PLZ": "7442",
    "Zusatzziffer": "0",
    "Gemeindename": "Andeer",
    "BFS-Nr": "3701",
    "Kantonskürzel": "GR",
    "E": "2752163.9510000013",
    "N": "1164708.8000000007",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Pignia",
    "PLZ": "7443",
    "Zusatzziffer": "0",
    "Gemeindename": "Andeer",
    "BFS-Nr": "3701",
    "Kantonskürzel": "GR",
    "E": "2753390.296",
    "N": "1164436.0529999994",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rongellen",
    "PLZ": "7430",
    "Zusatzziffer": "2",
    "Gemeindename": "Rongellen",
    "BFS-Nr": "3711",
    "Kantonskürzel": "GR",
    "E": "2753380.658",
    "N": "1171103.761",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Zillis",
    "PLZ": "7432",
    "Zusatzziffer": "0",
    "Gemeindename": "Zillis-Reischen",
    "BFS-Nr": "3712",
    "Kantonskürzel": "GR",
    "E": "2753485.7129999995",
    "N": "1166893.1350000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ausserferrera",
    "PLZ": "7444",
    "Zusatzziffer": "0",
    "Gemeindename": "Ferrera",
    "BFS-Nr": "3713",
    "Kantonskürzel": "GR",
    "E": "2753581.6840000004",
    "N": "1158186.9800000004",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Innerferrera",
    "PLZ": "7445",
    "Zusatzziffer": "0",
    "Gemeindename": "Ferrera",
    "BFS-Nr": "3713",
    "Kantonskürzel": "GR",
    "E": "2754861",
    "N": "1149888.875",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Innerferrera",
    "PLZ": "7445",
    "Zusatzziffer": "0",
    "Gemeindename": "Ferrera",
    "BFS-Nr": "3713",
    "Kantonskürzel": "GR",
    "E": "2753305.629999999",
    "N": "1154276.004999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Splügen",
    "PLZ": "7435",
    "Zusatzziffer": "0",
    "Gemeindename": "Rheinwald",
    "BFS-Nr": "3714",
    "Kantonskürzel": "GR",
    "E": "2744567.4959999993",
    "N": "1157602.6889999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Medels im Rheinwald",
    "PLZ": "7436",
    "Zusatzziffer": "0",
    "Gemeindename": "Rheinwald",
    "BFS-Nr": "3714",
    "Kantonskürzel": "GR",
    "E": "2742268.3900000006",
    "N": "1156683.313000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nufenen",
    "PLZ": "7437",
    "Zusatzziffer": "0",
    "Gemeindename": "Rheinwald",
    "BFS-Nr": "3714",
    "Kantonskürzel": "GR",
    "E": "2738583.3649999984",
    "N": "1155980.0610000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hinterrhein",
    "PLZ": "7438",
    "Zusatzziffer": "0",
    "Gemeindename": "Rheinwald",
    "BFS-Nr": "3714",
    "Kantonskürzel": "GR",
    "E": "2734893.2980000004",
    "N": "1154928.0350000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Donat",
    "PLZ": "7433",
    "Zusatzziffer": "0",
    "Gemeindename": "Muntogna da Schons",
    "BFS-Nr": "3715",
    "Kantonskürzel": "GR",
    "E": "2752514.6620000005",
    "N": "1166110.1059999987",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Lohn GR",
    "PLZ": "7433",
    "Zusatzziffer": "3",
    "Gemeindename": "Muntogna da Schons",
    "BFS-Nr": "3715",
    "Kantonskürzel": "GR",
    "E": "2752293.344999999",
    "N": "1168786.0309999995",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Mathon",
    "PLZ": "7433",
    "Zusatzziffer": "4",
    "Gemeindename": "Muntogna da Schons",
    "BFS-Nr": "3715",
    "Kantonskürzel": "GR",
    "E": "2751076.829999998",
    "N": "1166900.074000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Wergenstein",
    "PLZ": "7433",
    "Zusatzziffer": "5",
    "Gemeindename": "Muntogna da Schons",
    "BFS-Nr": "3715",
    "Kantonskürzel": "GR",
    "E": "2750560.1339999996",
    "N": "1165708.8280000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Bonaduz",
    "PLZ": "7402",
    "Zusatzziffer": "0",
    "Gemeindename": "Bonaduz",
    "BFS-Nr": "3721",
    "Kantonskürzel": "GR",
    "E": "2749868.614",
    "N": "1186395.2540000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Domat/Ems",
    "PLZ": "7013",
    "Zusatzziffer": "0",
    "Gemeindename": "Domat/Ems",
    "BFS-Nr": "3722",
    "Kantonskürzel": "GR",
    "E": "2753531.432",
    "N": "1189190.2490000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rhäzüns",
    "PLZ": "7403",
    "Zusatzziffer": "0",
    "Gemeindename": "Rhäzüns",
    "BFS-Nr": "3723",
    "Kantonskürzel": "GR",
    "E": "2749173.7080000006",
    "N": "1184723.9400000013",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Felsberg",
    "PLZ": "7012",
    "Zusatzziffer": "0",
    "Gemeindename": "Felsberg",
    "BFS-Nr": "3731",
    "Kantonskürzel": "GR",
    "E": "2754971.090999998",
    "N": "1190260.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flims Dorf",
    "PLZ": "7017",
    "Zusatzziffer": "0",
    "Gemeindename": "Flims",
    "BFS-Nr": "3732",
    "Kantonskürzel": "GR",
    "E": "2740788.421",
    "N": "1189045.2710000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Flims Waldhaus",
    "PLZ": "7018",
    "Zusatzziffer": "0",
    "Gemeindename": "Flims",
    "BFS-Nr": "3732",
    "Kantonskürzel": "GR",
    "E": "2741355.3090000004",
    "N": "1188118.8429999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Fidaz",
    "PLZ": "7019",
    "Zusatzziffer": "0",
    "Gemeindename": "Flims",
    "BFS-Nr": "3732",
    "Kantonskürzel": "GR",
    "E": "2742688.4239999987",
    "N": "1189438.9699999988",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tamins",
    "PLZ": "7015",
    "Zusatzziffer": "0",
    "Gemeindename": "Tamins",
    "BFS-Nr": "3733",
    "Kantonskürzel": "GR",
    "E": "2750243.1339999996",
    "N": "1188337.5760000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trin",
    "PLZ": "7014",
    "Zusatzziffer": "0",
    "Gemeindename": "Trin",
    "BFS-Nr": "3734",
    "Kantonskürzel": "GR",
    "E": "2746855.9230000004",
    "N": "1188388.6350000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Trin Mulin",
    "PLZ": "7016",
    "Zusatzziffer": "0",
    "Gemeindename": "Trin",
    "BFS-Nr": "3734",
    "Kantonskürzel": "GR",
    "E": "2744774.125",
    "N": "1188426.6689999998",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Brail",
    "PLZ": "7527",
    "Zusatzziffer": "0",
    "Gemeindename": "Zernez",
    "BFS-Nr": "3746",
    "Kantonskürzel": "GR",
    "E": "2798815.5659999996",
    "N": "1170530.1209999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Zernez",
    "PLZ": "7530",
    "Zusatzziffer": "0",
    "Gemeindename": "Zernez",
    "BFS-Nr": "3746",
    "Kantonskürzel": "GR",
    "E": "2803145.864",
    "N": "1175446.0689999983",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Susch",
    "PLZ": "7542",
    "Zusatzziffer": "0",
    "Gemeindename": "Zernez",
    "BFS-Nr": "3746",
    "Kantonskürzel": "GR",
    "E": "2801569.0689999983",
    "N": "1180936.5390000008",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Lavin",
    "PLZ": "7543",
    "Zusatzziffer": "0",
    "Gemeindename": "Zernez",
    "BFS-Nr": "3746",
    "Kantonskürzel": "GR",
    "E": "2804253.283",
    "N": "1183368.0590000004",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Samnaun-Compatsch",
    "PLZ": "7562",
    "Zusatzziffer": "0",
    "Gemeindename": "Samnaun",
    "BFS-Nr": "3752",
    "Kantonskürzel": "GR",
    "E": "2825358.1149999984",
    "N": "1205384.3850000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Samnaun Dorf",
    "PLZ": "7563",
    "Zusatzziffer": "0",
    "Gemeindename": "Samnaun",
    "BFS-Nr": "3752",
    "Kantonskürzel": "GR",
    "E": "2822491.8440000005",
    "N": "1203042.3530000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guarda",
    "PLZ": "7545",
    "Zusatzziffer": "0",
    "Gemeindename": "Scuol",
    "BFS-Nr": "3762",
    "Kantonskürzel": "GR",
    "E": "2807201.726",
    "N": "1184021.6799999997",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ardez",
    "PLZ": "7546",
    "Zusatzziffer": "0",
    "Gemeindename": "Scuol",
    "BFS-Nr": "3762",
    "Kantonskürzel": "GR",
    "E": "2811011.403000001",
    "N": "1184191.5439999998",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Scuol",
    "PLZ": "7550",
    "Zusatzziffer": "0",
    "Gemeindename": "Scuol",
    "BFS-Nr": "3762",
    "Kantonskürzel": "GR",
    "E": "2818758.4329999983",
    "N": "1186521.8260000013",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ftan",
    "PLZ": "7551",
    "Zusatzziffer": "0",
    "Gemeindename": "Scuol",
    "BFS-Nr": "3762",
    "Kantonskürzel": "GR",
    "E": "2814294.8680000007",
    "N": "1186606.1290000007",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Vulpera",
    "PLZ": "7552",
    "Zusatzziffer": "0",
    "Gemeindename": "Scuol",
    "BFS-Nr": "3762",
    "Kantonskürzel": "GR",
    "E": "2817387.625",
    "N": "1185731.25",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tarasp",
    "PLZ": "7553",
    "Zusatzziffer": "0",
    "Gemeindename": "Scuol",
    "BFS-Nr": "3762",
    "Kantonskürzel": "GR",
    "E": "2815793.4290000014",
    "N": "1184394.3429999985",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sent",
    "PLZ": "7554",
    "Zusatzziffer": "0",
    "Gemeindename": "Scuol",
    "BFS-Nr": "3762",
    "Kantonskürzel": "GR",
    "E": "2821131.159000002",
    "N": "1189050.5379999988",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ramosch",
    "PLZ": "7556",
    "Zusatzziffer": "0",
    "Gemeindename": "Valsot",
    "BFS-Nr": "3764",
    "Kantonskürzel": "GR",
    "E": "2824520.1719999984",
    "N": "1191229.7600000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Ramosch",
    "PLZ": "7556",
    "Zusatzziffer": "0",
    "Gemeindename": "Valsot",
    "BFS-Nr": "3764",
    "Kantonskürzel": "GR",
    "E": "2813756.75",
    "N": "1198278.688000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Vnà",
    "PLZ": "7557",
    "Zusatzziffer": "0",
    "Gemeindename": "Valsot",
    "BFS-Nr": "3764",
    "Kantonskürzel": "GR",
    "E": "2823056.340999998",
    "N": "1192148.9530000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Strada",
    "PLZ": "7558",
    "Zusatzziffer": "0",
    "Gemeindename": "Valsot",
    "BFS-Nr": "3764",
    "Kantonskürzel": "GR",
    "E": "2828418.789000001",
    "N": "1194526.131000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tschlin",
    "PLZ": "7559",
    "Zusatzziffer": "0",
    "Gemeindename": "Valsot",
    "BFS-Nr": "3764",
    "Kantonskürzel": "GR",
    "E": "2827465.272999998",
    "N": "1195407.7109999992",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Martina",
    "PLZ": "7560",
    "Zusatzziffer": "0",
    "Gemeindename": "Valsot",
    "BFS-Nr": "3764",
    "Kantonskürzel": "GR",
    "E": "2830503.2490000017",
    "N": "1197024.6909999996",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Bever",
    "PLZ": "7502",
    "Zusatzziffer": "0",
    "Gemeindename": "Bever",
    "BFS-Nr": "3781",
    "Kantonskürzel": "GR",
    "E": "2787911.4880000018",
    "N": "1158608.4400000013",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Bever",
    "PLZ": "7502",
    "Zusatzziffer": "0",
    "Gemeindename": "Bever",
    "BFS-Nr": "3781",
    "Kantonskürzel": "GR",
    "E": "2775743.0100000016",
    "N": "1155595.300999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Celerina/Schlarigna",
    "PLZ": "7505",
    "Zusatzziffer": "0",
    "Gemeindename": "Celerina/Schlarigna",
    "BFS-Nr": "3782",
    "Kantonskürzel": "GR",
    "E": "2786006.9580000006",
    "N": "1154092.4090000018",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Madulain",
    "PLZ": "7523",
    "Zusatzziffer": "0",
    "Gemeindename": "Madulain",
    "BFS-Nr": "3783",
    "Kantonskürzel": "GR",
    "E": "2791446.125",
    "N": "1162405.9730000012",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Madulain",
    "PLZ": "7523",
    "Zusatzziffer": "0",
    "Gemeindename": "Madulain",
    "BFS-Nr": "3783",
    "Kantonskürzel": "GR",
    "E": "2796468.2490000017",
    "N": "1152753",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Pontresina",
    "PLZ": "7504",
    "Zusatzziffer": "0",
    "Gemeindename": "Pontresina",
    "BFS-Nr": "3784",
    "Kantonskürzel": "GR",
    "E": "2789128.9979999997",
    "N": "1152101.5089999996",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "La Punt Chamues-ch",
    "PLZ": "7522",
    "Zusatzziffer": "0",
    "Gemeindename": "La Punt Chamues-ch",
    "BFS-Nr": "3785",
    "Kantonskürzel": "GR",
    "E": "2790843.66",
    "N": "1161502.5659999996",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Samedan",
    "PLZ": "7503",
    "Zusatzziffer": "0",
    "Gemeindename": "Samedan",
    "BFS-Nr": "3786",
    "Kantonskürzel": "GR",
    "E": "2786540.3630000018",
    "N": "1156513.4990000017",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "St. Moritz",
    "PLZ": "7500",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Moritz",
    "BFS-Nr": "3787",
    "Kantonskürzel": "GR",
    "E": "2784317.0080000013",
    "N": "1152498.2769999988",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "S-chanf",
    "PLZ": "7525",
    "Zusatzziffer": "0",
    "Gemeindename": "S-chanf",
    "BFS-Nr": "3788",
    "Kantonskürzel": "GR",
    "E": "2795032.4930000007",
    "N": "1165679.568",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Cinuos-chel",
    "PLZ": "7526",
    "Zusatzziffer": "0",
    "Gemeindename": "S-chanf",
    "BFS-Nr": "3788",
    "Kantonskürzel": "GR",
    "E": "2797846.4010000005",
    "N": "1168851.7159999982",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Chapella",
    "PLZ": "7526",
    "Zusatzziffer": "1",
    "Gemeindename": "S-chanf",
    "BFS-Nr": "3788",
    "Kantonskürzel": "GR",
    "E": "2796908.188000001",
    "N": "1167894.3480000012",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sils/Segl Maria",
    "PLZ": "7514",
    "Zusatzziffer": "0",
    "Gemeindename": "Sils im Engadin/Segl",
    "BFS-Nr": "3789",
    "Kantonskürzel": "GR",
    "E": "2778689.1449999996",
    "N": "1144611.232999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Fex",
    "PLZ": "7514",
    "Zusatzziffer": "2",
    "Gemeindename": "Sils im Engadin/Segl",
    "BFS-Nr": "3789",
    "Kantonskürzel": "GR",
    "E": "2778507.085999999",
    "N": "1143488.6220000014",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sils/Segl Baselgia",
    "PLZ": "7515",
    "Zusatzziffer": "0",
    "Gemeindename": "Sils im Engadin/Segl",
    "BFS-Nr": "3789",
    "Kantonskürzel": "GR",
    "E": "2778011.5150000006",
    "N": "1145222.0390000008",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Plaun da Lej",
    "PLZ": "7517",
    "Zusatzziffer": "0",
    "Gemeindename": "Sils im Engadin/Segl",
    "BFS-Nr": "3789",
    "Kantonskürzel": "GR",
    "E": "2775701.5439999998",
    "N": "1143723.2060000002",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Champfèr",
    "PLZ": "7512",
    "Zusatzziffer": "0",
    "Gemeindename": "Silvaplana",
    "BFS-Nr": "3790",
    "Kantonskürzel": "GR",
    "E": "2782121.7650000006",
    "N": "1150175.8869999982",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Silvaplana",
    "PLZ": "7513",
    "Zusatzziffer": "0",
    "Gemeindename": "Silvaplana",
    "BFS-Nr": "3790",
    "Kantonskürzel": "GR",
    "E": "2781007.7349999994",
    "N": "1148063.6559999995",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Silvaplana-Surlej",
    "PLZ": "7513",
    "Zusatzziffer": "2",
    "Gemeindename": "Silvaplana",
    "BFS-Nr": "3790",
    "Kantonskürzel": "GR",
    "E": "2782452.4560000002",
    "N": "1148107.2950000018",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Zuoz",
    "PLZ": "7524",
    "Zusatzziffer": "0",
    "Gemeindename": "Zuoz",
    "BFS-Nr": "3791",
    "Kantonskürzel": "GR",
    "E": "2792875.4849999994",
    "N": "1164219.5350000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Zuoz",
    "PLZ": "7524",
    "Zusatzziffer": "0",
    "Gemeindename": "Zuoz",
    "BFS-Nr": "3791",
    "Kantonskürzel": "GR",
    "E": "2787505.5",
    "N": "1165190.125",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Maloja",
    "PLZ": "7516",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2773587.061999999",
    "N": "1141497.199000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Casaccia",
    "PLZ": "7602",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2771294.0150000006",
    "N": "1140159.1810000017",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vicosoprano",
    "PLZ": "7603",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2768060.9189999998",
    "N": "1135614.3790000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Borgonovo",
    "PLZ": "7604",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2766660.295000002",
    "N": "1135237.995000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Stampa",
    "PLZ": "7605",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2765656.704999998",
    "N": "1134718.438000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Promontogno",
    "PLZ": "7606",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2763184.609000001",
    "N": "1133995.8960000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bondo",
    "PLZ": "7606",
    "Zusatzziffer": "1",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2762891.5469999984",
    "N": "1133638.3649999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Castasegna",
    "PLZ": "7608",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2759953.6719999984",
    "N": "1133508.796",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Soglio",
    "PLZ": "7610",
    "Zusatzziffer": "0",
    "Gemeindename": "Bregaglia",
    "BFS-Nr": "3792",
    "Kantonskürzel": "GR",
    "E": "2761708.5810000002",
    "N": "1134353.5229999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Buseno",
    "PLZ": "6542",
    "Zusatzziffer": "0",
    "Gemeindename": "Buseno",
    "BFS-Nr": "3804",
    "Kantonskürzel": "GR",
    "E": "2728626.6240000017",
    "N": "1125960.3740000017",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Castaneda",
    "PLZ": "6540",
    "Zusatzziffer": "0",
    "Gemeindename": "Castaneda",
    "BFS-Nr": "3805",
    "Kantonskürzel": "GR",
    "E": "2731219.6559999995",
    "N": "1124264.6400000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Rossa",
    "PLZ": "6548",
    "Zusatzziffer": "0",
    "Gemeindename": "Rossa",
    "BFS-Nr": "3808",
    "Kantonskürzel": "GR",
    "E": "2729909.4979999997",
    "N": "1137114.618999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Augio",
    "PLZ": "6548",
    "Zusatzziffer": "1",
    "Gemeindename": "Rossa",
    "BFS-Nr": "3808",
    "Kantonskürzel": "GR",
    "E": "2729899.1799999997",
    "N": "1136210.756000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sta. Domenica",
    "PLZ": "6548",
    "Zusatzziffer": "2",
    "Gemeindename": "Rossa",
    "BFS-Nr": "3808",
    "Kantonskürzel": "GR",
    "E": "2729632.594999999",
    "N": "1134772.9899999984",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sta. Maria in Calanca",
    "PLZ": "6541",
    "Zusatzziffer": "0",
    "Gemeindename": "Santa Maria in Calanca",
    "BFS-Nr": "3810",
    "Kantonskürzel": "GR",
    "E": "2731549.083999999",
    "N": "1124939.5969999991",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lostallo",
    "PLZ": "6558",
    "Zusatzziffer": "0",
    "Gemeindename": "Lostallo",
    "BFS-Nr": "3821",
    "Kantonskürzel": "GR",
    "E": "2735239.495000001",
    "N": "1130534.7380000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mesocco",
    "PLZ": "6563",
    "Zusatzziffer": "0",
    "Gemeindename": "Mesocco",
    "BFS-Nr": "3822",
    "Kantonskürzel": "GR",
    "E": "2737833.164999999",
    "N": "1139508.8770000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Bernardino",
    "PLZ": "6565",
    "Zusatzziffer": "0",
    "Gemeindename": "Mesocco",
    "BFS-Nr": "3822",
    "Kantonskürzel": "GR",
    "E": "2734708.460000001",
    "N": "1147227.5929999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Soazza",
    "PLZ": "6562",
    "Zusatzziffer": "0",
    "Gemeindename": "Soazza",
    "BFS-Nr": "3823",
    "Kantonskürzel": "GR",
    "E": "2737296.175999999",
    "N": "1136620.3790000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cama",
    "PLZ": "6557",
    "Zusatzziffer": "0",
    "Gemeindename": "Cama",
    "BFS-Nr": "3831",
    "Kantonskürzel": "GR",
    "E": "2733701.5260000005",
    "N": "1125999.2109999992",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Grono",
    "PLZ": "6537",
    "Zusatzziffer": "0",
    "Gemeindename": "Grono",
    "BFS-Nr": "3832",
    "Kantonskürzel": "GR",
    "E": "2731589.6000000015",
    "N": "1123220.6469999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Verdabbio",
    "PLZ": "6538",
    "Zusatzziffer": "0",
    "Gemeindename": "Grono",
    "BFS-Nr": "3832",
    "Kantonskürzel": "GR",
    "E": "2738426.625",
    "N": "1125249.875",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Verdabbio",
    "PLZ": "6538",
    "Zusatzziffer": "0",
    "Gemeindename": "Grono",
    "BFS-Nr": "3832",
    "Kantonskürzel": "GR",
    "E": "2732530.4679999985",
    "N": "1125539.5980000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Leggia",
    "PLZ": "6556",
    "Zusatzziffer": "0",
    "Gemeindename": "Grono",
    "BFS-Nr": "3832",
    "Kantonskürzel": "GR",
    "E": "2733057.6209999993",
    "N": "1124742.8680000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Roveredo GR",
    "PLZ": "6535",
    "Zusatzziffer": "0",
    "Gemeindename": "Roveredo (GR)",
    "BFS-Nr": "3834",
    "Kantonskürzel": "GR",
    "E": "2729696.6680000015",
    "N": "1121573.7890000008",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Laura",
    "PLZ": "6549",
    "Zusatzziffer": "0",
    "Gemeindename": "Roveredo (GR)",
    "BFS-Nr": "3834",
    "Kantonskürzel": "GR",
    "E": "2728600.4899999984",
    "N": "1119353.8539999984",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Vittore",
    "PLZ": "6534",
    "Zusatzziffer": "0",
    "Gemeindename": "San Vittore",
    "BFS-Nr": "3835",
    "Kantonskürzel": "GR",
    "E": "2728922.1750000007",
    "N": "1122149.3610000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Vittore",
    "PLZ": "6534",
    "Zusatzziffer": "0",
    "Gemeindename": "San Vittore",
    "BFS-Nr": "3835",
    "Kantonskürzel": "GR",
    "E": "2733097.3599999994",
    "N": "1116434.607999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Arvigo",
    "PLZ": "6543",
    "Zusatzziffer": "0",
    "Gemeindename": "Calanca",
    "BFS-Nr": "3837",
    "Kantonskürzel": "GR",
    "E": "2729022.6279999986",
    "N": "1129247.9880000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Braggio",
    "PLZ": "6544",
    "Zusatzziffer": "0",
    "Gemeindename": "Calanca",
    "BFS-Nr": "3837",
    "Kantonskürzel": "GR",
    "E": "2729801.5659999996",
    "N": "1129377.6519999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Selma",
    "PLZ": "6545",
    "Zusatzziffer": "0",
    "Gemeindename": "Calanca",
    "BFS-Nr": "3837",
    "Kantonskürzel": "GR",
    "E": "2729619.785",
    "N": "1131150.5150000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cauco",
    "PLZ": "6546",
    "Zusatzziffer": "0",
    "Gemeindename": "Calanca",
    "BFS-Nr": "3837",
    "Kantonskürzel": "GR",
    "E": "2729556.7630000003",
    "N": "1132907.9510000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Tschierv",
    "PLZ": "7532",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Müstair",
    "BFS-Nr": "3847",
    "Kantonskürzel": "GR",
    "E": "2822219.9459999986",
    "N": "1167799.6889999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Fuldera",
    "PLZ": "7533",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Müstair",
    "BFS-Nr": "3847",
    "Kantonskürzel": "GR",
    "E": "2824630.9990000017",
    "N": "1166130.0590000004",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Lü",
    "PLZ": "7534",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Müstair",
    "BFS-Nr": "3847",
    "Kantonskürzel": "GR",
    "E": "2824402.9739999995",
    "N": "1167657.050999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Valchava",
    "PLZ": "7535",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Müstair",
    "BFS-Nr": "3847",
    "Kantonskürzel": "GR",
    "E": "2827511.772999998",
    "N": "1165298.9389999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sta. Maria Val Müstair",
    "PLZ": "7536",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Müstair",
    "BFS-Nr": "3847",
    "Kantonskürzel": "GR",
    "E": "2828599.563000001",
    "N": "1165466.607999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Müstair",
    "PLZ": "7537",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Müstair",
    "BFS-Nr": "3847",
    "Kantonskürzel": "GR",
    "E": "2830225.2699999996",
    "N": "1167796.7780000009",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Davos Dorf",
    "PLZ": "7260",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2783247.5760000013",
    "N": "1187016.8680000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Davos Wolfgang",
    "PLZ": "7265",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2784295.868999999",
    "N": "1189600.6279999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Davos Platz",
    "PLZ": "7270",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2781951.614",
    "N": "1185220.6510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Davos Clavadel",
    "PLZ": "7272",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2781590.7760000005",
    "N": "1182300.864",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Davos Frauenkirch",
    "PLZ": "7276",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2780242.7710000016",
    "N": "1182443.1519999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Davos Glaris",
    "PLZ": "7277",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2778648.833999999",
    "N": "1179308.25",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Davos Monstein",
    "PLZ": "7278",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2778313.653999999",
    "N": "1176070.4919999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Davos Wiesen",
    "PLZ": "7494",
    "Zusatzziffer": "0",
    "Gemeindename": "Davos",
    "BFS-Nr": "3851",
    "Kantonskürzel": "GR",
    "E": "2774026.6149999984",
    "N": "1175025.2470000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fideris",
    "PLZ": "7235",
    "Zusatzziffer": "0",
    "Gemeindename": "Fideris",
    "BFS-Nr": "3861",
    "Kantonskürzel": "GR",
    "E": "2775315.9180000015",
    "N": "1198449.0590000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Furna",
    "PLZ": "7232",
    "Zusatzziffer": "0",
    "Gemeindename": "Furna",
    "BFS-Nr": "3862",
    "Kantonskürzel": "GR",
    "E": "2770569.3869999982",
    "N": "1200864.756000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pragg-Jenaz",
    "PLZ": "7231",
    "Zusatzziffer": "0",
    "Gemeindename": "Jenaz",
    "BFS-Nr": "3863",
    "Kantonskürzel": "GR",
    "E": "2772434.1339999996",
    "N": "1201089.2149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jenaz",
    "PLZ": "7233",
    "Zusatzziffer": "0",
    "Gemeindename": "Jenaz",
    "BFS-Nr": "3863",
    "Kantonskürzel": "GR",
    "E": "2773475.7639999986",
    "N": "1199778.1429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saas im Prättigau",
    "PLZ": "7247",
    "Zusatzziffer": "0",
    "Gemeindename": "Klosters",
    "BFS-Nr": "3871",
    "Kantonskürzel": "GR",
    "E": "2780273.164999999",
    "N": "1198247.7089999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Serneus",
    "PLZ": "7249",
    "Zusatzziffer": "0",
    "Gemeindename": "Klosters",
    "BFS-Nr": "3871",
    "Kantonskürzel": "GR",
    "E": "2783001.0540000014",
    "N": "1196086.2540000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Klosters",
    "PLZ": "7250",
    "Zusatzziffer": "0",
    "Gemeindename": "Klosters",
    "BFS-Nr": "3871",
    "Kantonskürzel": "GR",
    "E": "2786260.890999999",
    "N": "1193926.6840000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Klosters Dorf",
    "PLZ": "7252",
    "Zusatzziffer": "0",
    "Gemeindename": "Klosters",
    "BFS-Nr": "3871",
    "Kantonskürzel": "GR",
    "E": "2785684.7580000013",
    "N": "1195383.2780000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Conters im Prättigau",
    "PLZ": "7241",
    "Zusatzziffer": "0",
    "Gemeindename": "Conters im Prättigau",
    "BFS-Nr": "3881",
    "Kantonskürzel": "GR",
    "E": "2779821.557",
    "N": "1197383.6389999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Küblis",
    "PLZ": "7240",
    "Zusatzziffer": "0",
    "Gemeindename": "Küblis",
    "BFS-Nr": "3882",
    "Kantonskürzel": "GR",
    "E": "2778109.482999999",
    "N": "1198556.6099999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchen im Prättigau",
    "PLZ": "7223",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzein",
    "BFS-Nr": "3891",
    "Kantonskürzel": "GR",
    "E": "2773893.3539999984",
    "N": "1201255.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Putz",
    "PLZ": "7224",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzein",
    "BFS-Nr": "3891",
    "Kantonskürzel": "GR",
    "E": "2775430.8960000016",
    "N": "1199799.25",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Luzein",
    "PLZ": "7242",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzein",
    "BFS-Nr": "3891",
    "Kantonskürzel": "GR",
    "E": "2777009.8539999984",
    "N": "1199167.5100000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pany",
    "PLZ": "7243",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzein",
    "BFS-Nr": "3891",
    "Kantonskürzel": "GR",
    "E": "2777467.6039999984",
    "N": "1200376.5689999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gadenstätt",
    "PLZ": "7244",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzein",
    "BFS-Nr": "3891",
    "Kantonskürzel": "GR",
    "E": "2779229.188000001",
    "N": "1201952.8440000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ascharina",
    "PLZ": "7245",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzein",
    "BFS-Nr": "3891",
    "Kantonskürzel": "GR",
    "E": "2780251.3099999987",
    "N": "1203448.4710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Antönien",
    "PLZ": "7246",
    "Zusatzziffer": "0",
    "Gemeindename": "Luzein",
    "BFS-Nr": "3891",
    "Kantonskürzel": "GR",
    "E": "2780739.0599999987",
    "N": "1204780.414999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Chur",
    "PLZ": "7000",
    "Zusatzziffer": "0",
    "Gemeindename": "Chur",
    "BFS-Nr": "3901",
    "Kantonskürzel": "GR",
    "E": "2759706.9499999993",
    "N": "1190690.995000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Haldenstein",
    "PLZ": "7023",
    "Zusatzziffer": "0",
    "Gemeindename": "Chur",
    "BFS-Nr": "3901",
    "Kantonskürzel": "GR",
    "E": "2758787.5760000013",
    "N": "1194116.5309999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Maladers",
    "PLZ": "7026",
    "Zusatzziffer": "0",
    "Gemeindename": "Chur",
    "BFS-Nr": "3901",
    "Kantonskürzel": "GR",
    "E": "2761864.3500000015",
    "N": "1189472.2980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Passugg",
    "PLZ": "7062",
    "Zusatzziffer": "0",
    "Gemeindename": "Churwalden",
    "BFS-Nr": "3911",
    "Kantonskürzel": "GR",
    "E": "2760841.313000001",
    "N": "1188727.6920000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Malix",
    "PLZ": "7074",
    "Zusatzziffer": "0",
    "Gemeindename": "Churwalden",
    "BFS-Nr": "3911",
    "Kantonskürzel": "GR",
    "E": "2759672.085000001",
    "N": "1186872.6099999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Churwalden",
    "PLZ": "7075",
    "Zusatzziffer": "0",
    "Gemeindename": "Churwalden",
    "BFS-Nr": "3911",
    "Kantonskürzel": "GR",
    "E": "2760798.287999999",
    "N": "1183216.7710000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Parpan",
    "PLZ": "7076",
    "Zusatzziffer": "0",
    "Gemeindename": "Churwalden",
    "BFS-Nr": "3911",
    "Kantonskürzel": "GR",
    "E": "2761861.5720000006",
    "N": "1181060.8280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Castiel",
    "PLZ": "7027",
    "Zusatzziffer": "0",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2764999.9629999995",
    "N": "1189753.2879999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lüen",
    "PLZ": "7027",
    "Zusatzziffer": "1",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2765874.454999998",
    "N": "1189159.245000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Calfreisen",
    "PLZ": "7027",
    "Zusatzziffer": "3",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2764474.3819999993",
    "N": "1190061.1460000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Peter",
    "PLZ": "7028",
    "Zusatzziffer": "0",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2768218.9809999987",
    "N": "1189384.4910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pagig",
    "PLZ": "7028",
    "Zusatzziffer": "1",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2767662.563000001",
    "N": "1189677.1339999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Peist",
    "PLZ": "7029",
    "Zusatzziffer": "0",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2770510.739",
    "N": "1189301.0020000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arosa",
    "PLZ": "7050",
    "Zusatzziffer": "0",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2770819.0260000005",
    "N": "1183115.3570000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Molinis",
    "PLZ": "7056",
    "Zusatzziffer": "0",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2769103.0150000006",
    "N": "1188626.0379999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langwies",
    "PLZ": "7057",
    "Zusatzziffer": "0",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2773550.1860000007",
    "N": "1187989.1169999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Litzirüti",
    "PLZ": "7058",
    "Zusatzziffer": "0",
    "Gemeindename": "Arosa",
    "BFS-Nr": "3921",
    "Kantonskürzel": "GR",
    "E": "2772817.772999998",
    "N": "1185474.1810000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Praden",
    "PLZ": "7063",
    "Zusatzziffer": "0",
    "Gemeindename": "Tschiertschen-Praden",
    "BFS-Nr": "3932",
    "Kantonskürzel": "GR",
    "E": "2763501.2430000007",
    "N": "1188224.4759999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tschiertschen",
    "PLZ": "7064",
    "Zusatzziffer": "0",
    "Gemeindename": "Tschiertschen-Praden",
    "BFS-Nr": "3932",
    "Kantonskürzel": "GR",
    "E": "2765455.3559999987",
    "N": "1187474.8929999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Says",
    "PLZ": "7202",
    "Zusatzziffer": "0",
    "Gemeindename": "Trimmis",
    "BFS-Nr": "3945",
    "Kantonskürzel": "GR",
    "E": "2762769.5760000013",
    "N": "1196934.7699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Trimmis",
    "PLZ": "7203",
    "Zusatzziffer": "0",
    "Gemeindename": "Trimmis",
    "BFS-Nr": "3945",
    "Kantonskürzel": "GR",
    "E": "2761936.947999999",
    "N": "1196072.188000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Untervaz",
    "PLZ": "7204",
    "Zusatzziffer": "0",
    "Gemeindename": "Untervaz",
    "BFS-Nr": "3946",
    "Kantonskürzel": "GR",
    "E": "2759646.3319999985",
    "N": "1199479.5219999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zizers",
    "PLZ": "7205",
    "Zusatzziffer": "0",
    "Gemeindename": "Zizers",
    "BFS-Nr": "3947",
    "Kantonskürzel": "GR",
    "E": "2761973.5249999985",
    "N": "1200590.7289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fläsch",
    "PLZ": "7306",
    "Zusatzziffer": "0",
    "Gemeindename": "Fläsch",
    "BFS-Nr": "3951",
    "Kantonskürzel": "GR",
    "E": "2757743.579999998",
    "N": "1210384.1799999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jenins",
    "PLZ": "7307",
    "Zusatzziffer": "0",
    "Gemeindename": "Jenins",
    "BFS-Nr": "3952",
    "Kantonskürzel": "GR",
    "E": "2761019.125",
    "N": "1207729.5529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Maienfeld",
    "PLZ": "7304",
    "Zusatzziffer": "0",
    "Gemeindename": "Maienfeld",
    "BFS-Nr": "3953",
    "Kantonskürzel": "GR",
    "E": "2759132.449000001",
    "N": "1208448.3880000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Malans GR",
    "PLZ": "7208",
    "Zusatzziffer": "0",
    "Gemeindename": "Malans",
    "BFS-Nr": "3954",
    "Kantonskürzel": "GR",
    "E": "2762468.9679999985",
    "N": "1205628.7030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Igis",
    "PLZ": "7206",
    "Zusatzziffer": "0",
    "Gemeindename": "Landquart",
    "BFS-Nr": "3955",
    "Kantonskürzel": "GR",
    "E": "2762381.7190000005",
    "N": "1201561.5040000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Landquart",
    "PLZ": "7302",
    "Zusatzziffer": "0",
    "Gemeindename": "Landquart",
    "BFS-Nr": "3955",
    "Kantonskürzel": "GR",
    "E": "2761328.66",
    "N": "1203567.3429999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mastrils",
    "PLZ": "7303",
    "Zusatzziffer": "0",
    "Gemeindename": "Landquart",
    "BFS-Nr": "3955",
    "Kantonskürzel": "GR",
    "E": "2760201.272999998",
    "N": "1204023.9910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Valzeina",
    "PLZ": "7213",
    "Zusatzziffer": "0",
    "Gemeindename": "Grüsch",
    "BFS-Nr": "3961",
    "Kantonskürzel": "GR",
    "E": "2764757.079999998",
    "N": "1202261.8049999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grüsch",
    "PLZ": "7214",
    "Zusatzziffer": "0",
    "Gemeindename": "Grüsch",
    "BFS-Nr": "3961",
    "Kantonskürzel": "GR",
    "E": "2767996.414999999",
    "N": "1205851.318",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fanas",
    "PLZ": "7215",
    "Zusatzziffer": "0",
    "Gemeindename": "Grüsch",
    "BFS-Nr": "3961",
    "Kantonskürzel": "GR",
    "E": "2769480.5650000013",
    "N": "1206116.2459999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schiers",
    "PLZ": "7220",
    "Zusatzziffer": "0",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2771179.318",
    "N": "1204581.4239999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schiers",
    "PLZ": "7220",
    "Zusatzziffer": "0",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2777310.5",
    "N": "1207552.063000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lunden",
    "PLZ": "7222",
    "Zusatzziffer": "0",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2773037.0749999993",
    "N": "1202196.9129999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stels",
    "PLZ": "7226",
    "Zusatzziffer": "0",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2773279.2349999994",
    "N": "1204116.6600000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stels",
    "PLZ": "7226",
    "Zusatzziffer": "0",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2779410.5",
    "N": "1209283.813000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fajauna",
    "PLZ": "7226",
    "Zusatzziffer": "2",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2772114.522",
    "N": "1204546.2410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schuders",
    "PLZ": "7228",
    "Zusatzziffer": "0",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2774918.199000001",
    "N": "1207011.7989999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pusserein",
    "PLZ": "7228",
    "Zusatzziffer": "1",
    "Gemeindename": "Schiers",
    "BFS-Nr": "3962",
    "Kantonskürzel": "GR",
    "E": "2772853.1559999995",
    "N": "1206111.0300000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seewis Dorf",
    "PLZ": "7212",
    "Zusatzziffer": "0",
    "Gemeindename": "Seewis im Prättigau",
    "BFS-Nr": "3972",
    "Kantonskürzel": "GR",
    "E": "2767422.0700000003",
    "N": "1206642.3669999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seewis-Pardisla",
    "PLZ": "7212",
    "Zusatzziffer": "1",
    "Gemeindename": "Seewis im Prättigau",
    "BFS-Nr": "3972",
    "Kantonskürzel": "GR",
    "E": "2766851.75",
    "N": "1205274.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seewis-Schmitten",
    "PLZ": "7212",
    "Zusatzziffer": "2",
    "Gemeindename": "Seewis im Prättigau",
    "BFS-Nr": "3972",
    "Kantonskürzel": "GR",
    "E": "2767519.75",
    "N": "1205605.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Waltensburg/Vuorz",
    "PLZ": "7158",
    "Zusatzziffer": "0",
    "Gemeindename": "Breil/Brigels",
    "BFS-Nr": "3981",
    "Kantonskürzel": "GR",
    "E": "2728217.1160000004",
    "N": "1182031.3819999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Andiast",
    "PLZ": "7159",
    "Zusatzziffer": "0",
    "Gemeindename": "Breil/Brigels",
    "BFS-Nr": "3981",
    "Kantonskürzel": "GR",
    "E": "2728219.280000001",
    "N": "1183132.068",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Tavanasa",
    "PLZ": "7162",
    "Zusatzziffer": "0",
    "Gemeindename": "Breil/Brigels",
    "BFS-Nr": "3981",
    "Kantonskürzel": "GR",
    "E": "2724077.789000001",
    "N": "1179464.4959999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Danis",
    "PLZ": "7163",
    "Zusatzziffer": "0",
    "Gemeindename": "Breil/Brigels",
    "BFS-Nr": "3981",
    "Kantonskürzel": "GR",
    "E": "2723656.8630000018",
    "N": "1179445.3249999993",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Dardin",
    "PLZ": "7164",
    "Zusatzziffer": "0",
    "Gemeindename": "Breil/Brigels",
    "BFS-Nr": "3981",
    "Kantonskürzel": "GR",
    "E": "2722624.6339999996",
    "N": "1179667.6750000007",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Breil/Brigels",
    "PLZ": "7165",
    "Zusatzziffer": "0",
    "Gemeindename": "Breil/Brigels",
    "BFS-Nr": "3981",
    "Kantonskürzel": "GR",
    "E": "2723951.221999999",
    "N": "1180947.2639999986",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Disentis/Mustér",
    "PLZ": "7180",
    "Zusatzziffer": "0",
    "Gemeindename": "Disentis/Mustér",
    "BFS-Nr": "3982",
    "Kantonskürzel": "GR",
    "E": "2708043.3440000005",
    "N": "1173436.436999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Cavardiras",
    "PLZ": "7182",
    "Zusatzziffer": "0",
    "Gemeindename": "Disentis/Mustér",
    "BFS-Nr": "3982",
    "Kantonskürzel": "GR",
    "E": "2710582.4990000017",
    "N": "1174275.614",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Mumpé Medel",
    "PLZ": "7183",
    "Zusatzziffer": "0",
    "Gemeindename": "Disentis/Mustér",
    "BFS-Nr": "3982",
    "Kantonskürzel": "GR",
    "E": "2707811.8249999993",
    "N": "1171890.4510000013",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Segnas",
    "PLZ": "7186",
    "Zusatzziffer": "0",
    "Gemeindename": "Disentis/Mustér",
    "BFS-Nr": "3982",
    "Kantonskürzel": "GR",
    "E": "2706267.3819999993",
    "N": "1172465.4609999992",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Curaglia",
    "PLZ": "7184",
    "Zusatzziffer": "0",
    "Gemeindename": "Medel (Lucmagn)",
    "BFS-Nr": "3983",
    "Kantonskürzel": "GR",
    "E": "2708657.4059999995",
    "N": "1170132.2910000011",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Platta",
    "PLZ": "7185",
    "Zusatzziffer": "0",
    "Gemeindename": "Medel (Lucmagn)",
    "BFS-Nr": "3983",
    "Kantonskürzel": "GR",
    "E": "2708332.2710000016",
    "N": "1168422.7149999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rabius",
    "PLZ": "7172",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumvitg",
    "BFS-Nr": "3985",
    "Kantonskürzel": "GR",
    "E": "2716159.3850000016",
    "N": "1177047.7800000012",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Surrein",
    "PLZ": "7173",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumvitg",
    "BFS-Nr": "3985",
    "Kantonskürzel": "GR",
    "E": "2715494.7989999987",
    "N": "1175721.414999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "S. Benedetg",
    "PLZ": "7174",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumvitg",
    "BFS-Nr": "3985",
    "Kantonskürzel": "GR",
    "E": "2714683.3440000005",
    "N": "1177042.5210000016",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sumvitg",
    "PLZ": "7175",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumvitg",
    "BFS-Nr": "3985",
    "Kantonskürzel": "GR",
    "E": "2714691.079999998",
    "N": "1176316.8420000002",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Cumpadials",
    "PLZ": "7176",
    "Zusatzziffer": "0",
    "Gemeindename": "Sumvitg",
    "BFS-Nr": "3985",
    "Kantonskürzel": "GR",
    "E": "2713308.4059999995",
    "N": "1175686.9719999991",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Camischolas",
    "PLZ": "7187",
    "Zusatzziffer": "0",
    "Gemeindename": "Tujetsch",
    "BFS-Nr": "3986",
    "Kantonskürzel": "GR",
    "E": "2701178.4059999995",
    "N": "1170980.0309999995",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Sedrun",
    "PLZ": "7188",
    "Zusatzziffer": "0",
    "Gemeindename": "Tujetsch",
    "BFS-Nr": "3986",
    "Kantonskürzel": "GR",
    "E": "2702254.283",
    "N": "1170691.7850000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Rueras",
    "PLZ": "7189",
    "Zusatzziffer": "0",
    "Gemeindename": "Tujetsch",
    "BFS-Nr": "3986",
    "Kantonskürzel": "GR",
    "E": "2700180.886",
    "N": "1170156.2250000015",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Trun",
    "PLZ": "7166",
    "Zusatzziffer": "0",
    "Gemeindename": "Trun",
    "BFS-Nr": "3987",
    "Kantonskürzel": "GR",
    "E": "2718153.2479999997",
    "N": "1177916.688000001",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Zignau",
    "PLZ": "7167",
    "Zusatzziffer": "0",
    "Gemeindename": "Trun",
    "BFS-Nr": "3987",
    "Kantonskürzel": "GR",
    "E": "2719792.2140000015",
    "N": "1177835.4580000006",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Schlans",
    "PLZ": "7168",
    "Zusatzziffer": "0",
    "Gemeindename": "Trun",
    "BFS-Nr": "3987",
    "Kantonskürzel": "GR",
    "E": "2720437.403000001",
    "N": "1179210.697999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Obersaxen",
    "PLZ": "7134",
    "Zusatzziffer": "0",
    "Gemeindename": "Obersaxen Mundaun",
    "BFS-Nr": "3988",
    "Kantonskürzel": "GR",
    "E": "2726958.129999999",
    "N": "1178826.1259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Flond",
    "PLZ": "7137",
    "Zusatzziffer": "0",
    "Gemeindename": "Obersaxen Mundaun",
    "BFS-Nr": "3988",
    "Kantonskürzel": "GR",
    "E": "2731555.8649999984",
    "N": "1181044.572999999",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Surcuolm",
    "PLZ": "7138",
    "Zusatzziffer": "0",
    "Gemeindename": "Obersaxen Mundaun",
    "BFS-Nr": "3988",
    "Kantonskürzel": "GR",
    "E": "2730286.3310000002",
    "N": "1179929.4140000008",
    "Sprache": "rm"
  },
  {
    "Ortschaftsname": "Aarau",
    "PLZ": "5000",
    "Zusatzziffer": "0",
    "Gemeindename": "Aarau",
    "BFS-Nr": "4001",
    "Kantonskürzel": "AG",
    "E": "2646060.8375000004",
    "N": "1248866.143330487",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aarau",
    "PLZ": "5004",
    "Zusatzziffer": "0",
    "Gemeindename": "Aarau",
    "BFS-Nr": "4001",
    "Kantonskürzel": "AG",
    "E": "2646950.666000001",
    "N": "1250196.9963417056",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aarau Rohr",
    "PLZ": "5032",
    "Zusatzziffer": "0",
    "Gemeindename": "Aarau",
    "BFS-Nr": "4001",
    "Kantonskürzel": "AG",
    "E": "2648813.664999999",
    "N": "1250332.9120000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biberstein",
    "PLZ": "5023",
    "Zusatzziffer": "0",
    "Gemeindename": "Biberstein",
    "BFS-Nr": "4002",
    "Kantonskürzel": "AG",
    "E": "2648495.9739999995",
    "N": "1251881.0340000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchs AG",
    "PLZ": "5033",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchs (AG)",
    "BFS-Nr": "4003",
    "Kantonskürzel": "AG",
    "E": "2647772.460999999",
    "N": "1248902.3740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Asp",
    "PLZ": "5025",
    "Zusatzziffer": "0",
    "Gemeindename": "Densbüren",
    "BFS-Nr": "4004",
    "Kantonskürzel": "AG",
    "E": "2646021.129999999",
    "N": "1254853.9030000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Densbüren",
    "PLZ": "5026",
    "Zusatzziffer": "0",
    "Gemeindename": "Densbüren",
    "BFS-Nr": "4004",
    "Kantonskürzel": "AG",
    "E": "2646472.9059999995",
    "N": "1255968.0700000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Barmelweid",
    "PLZ": "5017",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlinsbach (AG)",
    "BFS-Nr": "4005",
    "Kantonskürzel": "AG",
    "E": "2640092.101",
    "N": "1252474.5139999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erlinsbach",
    "PLZ": "5018",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlinsbach (AG)",
    "BFS-Nr": "4005",
    "Kantonskürzel": "AG",
    "E": "2643101.899",
    "N": "1250895.7030000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gränichen",
    "PLZ": "5722",
    "Zusatzziffer": "0",
    "Gemeindename": "Gränichen",
    "BFS-Nr": "4006",
    "Kantonskürzel": "AG",
    "E": "2650127.6339999996",
    "N": "1245284.5529999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hirschthal",
    "PLZ": "5042",
    "Zusatzziffer": "0",
    "Gemeindename": "Hirschthal",
    "BFS-Nr": "4007",
    "Kantonskürzel": "AG",
    "E": "2646705.596000001",
    "N": "1241054.1149999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rombach",
    "PLZ": "5022",
    "Zusatzziffer": "0",
    "Gemeindename": "Küttigen",
    "BFS-Nr": "4008",
    "Kantonskürzel": "AG",
    "E": "2645905.515999999",
    "N": "1250691.7829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Küttigen",
    "PLZ": "5024",
    "Zusatzziffer": "0",
    "Gemeindename": "Küttigen",
    "BFS-Nr": "4008",
    "Kantonskürzel": "AG",
    "E": "2645974.920000002",
    "N": "1251535.765999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Muhen",
    "PLZ": "5037",
    "Zusatzziffer": "0",
    "Gemeindename": "Muhen",
    "BFS-Nr": "4009",
    "Kantonskürzel": "AG",
    "E": "2646310.636",
    "N": "1242865.7800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberentfelden",
    "PLZ": "5036",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberentfelden",
    "BFS-Nr": "4010",
    "Kantonskürzel": "AG",
    "E": "2644962.4310000017",
    "N": "1245409.8209999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Suhr",
    "PLZ": "5034",
    "Zusatzziffer": "0",
    "Gemeindename": "Suhr",
    "BFS-Nr": "4012",
    "Kantonskürzel": "AG",
    "E": "2648142.5599999987",
    "N": "1247124.265999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterentfelden",
    "PLZ": "5035",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterentfelden",
    "BFS-Nr": "4013",
    "Kantonskürzel": "AG",
    "E": "2645298.1460000016",
    "N": "1246711.1660000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Baden",
    "PLZ": "5400",
    "Zusatzziffer": "0",
    "Gemeindename": "Baden",
    "BFS-Nr": "4021",
    "Kantonskürzel": "AG",
    "E": "2665611.8559999987",
    "N": "1258347.2289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Baden",
    "PLZ": "5404",
    "Zusatzziffer": "0",
    "Gemeindename": "Baden",
    "BFS-Nr": "4021",
    "Kantonskürzel": "AG",
    "E": "2663319.484000001",
    "N": "1256307.4899999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dättwil AG",
    "PLZ": "5405",
    "Zusatzziffer": "2",
    "Gemeindename": "Baden",
    "BFS-Nr": "4021",
    "Kantonskürzel": "AG",
    "E": "2663894.6939999983",
    "N": "1256307.1600000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rütihof",
    "PLZ": "5406",
    "Zusatzziffer": "2",
    "Gemeindename": "Baden",
    "BFS-Nr": "4021",
    "Kantonskürzel": "AG",
    "E": "2662587.3790000007",
    "N": "1254696.4230000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bellikon",
    "PLZ": "5454",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellikon",
    "BFS-Nr": "4022",
    "Kantonskürzel": "AG",
    "E": "2668463.8869999982",
    "N": "1249326.1220000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bergdietikon",
    "PLZ": "8962",
    "Zusatzziffer": "0",
    "Gemeindename": "Bergdietikon",
    "BFS-Nr": "4023",
    "Kantonskürzel": "AG",
    "E": "2671102.458999999",
    "N": "1249307.4239999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birmenstorf AG",
    "PLZ": "5413",
    "Zusatzziffer": "0",
    "Gemeindename": "Birmenstorf (AG)",
    "BFS-Nr": "4024",
    "Kantonskürzel": "AG",
    "E": "2661080.796",
    "N": "1257102.4849999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ennetbaden",
    "PLZ": "5408",
    "Zusatzziffer": "0",
    "Gemeindename": "Ennetbaden",
    "BFS-Nr": "4026",
    "Kantonskürzel": "AG",
    "E": "2666733.796",
    "N": "1259241.4439999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fislisbach",
    "PLZ": "5442",
    "Zusatzziffer": "0",
    "Gemeindename": "Fislisbach",
    "BFS-Nr": "4027",
    "Kantonskürzel": "AG",
    "E": "2664740.469999999",
    "N": "1254603.3489999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Freienwil",
    "PLZ": "5423",
    "Zusatzziffer": "0",
    "Gemeindename": "Freienwil",
    "BFS-Nr": "4028",
    "Kantonskürzel": "AG",
    "E": "2666890.0819999985",
    "N": "1261698.3200000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gebenstorf",
    "PLZ": "5412",
    "Zusatzziffer": "0",
    "Gemeindename": "Gebenstorf",
    "BFS-Nr": "4029",
    "Kantonskürzel": "AG",
    "E": "2660415.295000002",
    "N": "1259184.6829999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vogelsang AG",
    "PLZ": "5412",
    "Zusatzziffer": "1",
    "Gemeindename": "Gebenstorf",
    "BFS-Nr": "4029",
    "Kantonskürzel": "AG",
    "E": "2660394.136",
    "N": "1260792.0419999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Killwangen",
    "PLZ": "8956",
    "Zusatzziffer": "0",
    "Gemeindename": "Killwangen",
    "BFS-Nr": "4030",
    "Kantonskürzel": "AG",
    "E": "2668658.7930000015",
    "N": "1253943.4290000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Künten",
    "PLZ": "5444",
    "Zusatzziffer": "0",
    "Gemeindename": "Künten",
    "BFS-Nr": "4031",
    "Kantonskürzel": "AG",
    "E": "2667065.787999999",
    "N": "1249207.2289999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mägenwil",
    "PLZ": "5506",
    "Zusatzziffer": "0",
    "Gemeindename": "Mägenwil",
    "BFS-Nr": "4032",
    "Kantonskürzel": "AG",
    "E": "2660005.5489999987",
    "N": "1251294.4710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mellingen",
    "PLZ": "5507",
    "Zusatzziffer": "0",
    "Gemeindename": "Mellingen",
    "BFS-Nr": "4033",
    "Kantonskürzel": "AG",
    "E": "2663597.7529999986",
    "N": "1252273.447999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuenhof",
    "PLZ": "5432",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuenhof",
    "BFS-Nr": "4034",
    "Kantonskürzel": "AG",
    "E": "2666753.2749999985",
    "N": "1255367.0340000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederrohrdorf",
    "PLZ": "5443",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederrohrdorf",
    "BFS-Nr": "4035",
    "Kantonskürzel": "AG",
    "E": "2664822.1499999985",
    "N": "1252911.8999999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberrohrdorf",
    "PLZ": "5452",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberrohrdorf",
    "BFS-Nr": "4037",
    "Kantonskürzel": "AG",
    "E": "2666654.6229999997",
    "N": "1252811.256000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nussbaumen AG",
    "PLZ": "5415",
    "Zusatzziffer": "0",
    "Gemeindename": "Obersiggenthal",
    "BFS-Nr": "4038",
    "Kantonskürzel": "AG",
    "E": "2664124.8099999987",
    "N": "1260165.671",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hertenstein AG",
    "PLZ": "5415",
    "Zusatzziffer": "1",
    "Gemeindename": "Obersiggenthal",
    "BFS-Nr": "4038",
    "Kantonskürzel": "AG",
    "E": "2665875.993999999",
    "N": "1260004.1900000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rieden AG",
    "PLZ": "5415",
    "Zusatzziffer": "2",
    "Gemeindename": "Obersiggenthal",
    "BFS-Nr": "4038",
    "Kantonskürzel": "AG",
    "E": "2665226.313000001",
    "N": "1259603.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kirchdorf AG",
    "PLZ": "5416",
    "Zusatzziffer": "0",
    "Gemeindename": "Obersiggenthal",
    "BFS-Nr": "4038",
    "Kantonskürzel": "AG",
    "E": "2662953.6829999983",
    "N": "1261016.2800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Remetschwil",
    "PLZ": "5453",
    "Zusatzziffer": "0",
    "Gemeindename": "Remetschwil",
    "BFS-Nr": "4039",
    "Kantonskürzel": "AG",
    "E": "2667028.717",
    "N": "1251212.5329999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Spreitenbach",
    "PLZ": "8957",
    "Zusatzziffer": "0",
    "Gemeindename": "Spreitenbach",
    "BFS-Nr": "4040",
    "Kantonskürzel": "AG",
    "E": "2669953.1290000007",
    "N": "1252195.5119999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stetten AG",
    "PLZ": "5608",
    "Zusatzziffer": "0",
    "Gemeindename": "Stetten (AG)",
    "BFS-Nr": "4041",
    "Kantonskürzel": "AG",
    "E": "2665472.925999999",
    "N": "1250222.9869999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Turgi",
    "PLZ": "5300",
    "Zusatzziffer": "0",
    "Gemeindename": "Turgi",
    "BFS-Nr": "4042",
    "Kantonskürzel": "AG",
    "E": "2661423.368999999",
    "N": "1260315.0450000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Siggenthal Station",
    "PLZ": "5301",
    "Zusatzziffer": "0",
    "Gemeindename": "Untersiggenthal",
    "BFS-Nr": "4044",
    "Kantonskürzel": "AG",
    "E": "2660210.3440000005",
    "N": "1263026.4439999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Untersiggenthal",
    "PLZ": "5417",
    "Zusatzziffer": "0",
    "Gemeindename": "Untersiggenthal",
    "BFS-Nr": "4044",
    "Kantonskürzel": "AG",
    "E": "2661873.1680000015",
    "N": "1261938.8779999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wettingen",
    "PLZ": "5430",
    "Zusatzziffer": "0",
    "Gemeindename": "Wettingen",
    "BFS-Nr": "4045",
    "Kantonskürzel": "AG",
    "E": "2667237.462000001",
    "N": "1257830.1130000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wohlenschwil",
    "PLZ": "5512",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlenschwil",
    "BFS-Nr": "4046",
    "Kantonskürzel": "AG",
    "E": "2661741.226",
    "N": "1251607.4800000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Würenlingen",
    "PLZ": "5303",
    "Zusatzziffer": "0",
    "Gemeindename": "Würenlingen",
    "BFS-Nr": "4047",
    "Kantonskürzel": "AG",
    "E": "2661749.517999999",
    "N": "1264711.3630000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Würenlos",
    "PLZ": "5436",
    "Zusatzziffer": "0",
    "Gemeindename": "Würenlos",
    "BFS-Nr": "4048",
    "Kantonskürzel": "AG",
    "E": "2669864.2179999985",
    "N": "1255078.0260000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kloster Fahr",
    "PLZ": "8109",
    "Zusatzziffer": "0",
    "Gemeindename": "Würenlos",
    "BFS-Nr": "4048",
    "Kantonskürzel": "AG",
    "E": "2675508.9640000015",
    "N": "1251309.890999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ehrendingen",
    "PLZ": "5420",
    "Zusatzziffer": "0",
    "Gemeindename": "Ehrendingen",
    "BFS-Nr": "4049",
    "Kantonskürzel": "AG",
    "E": "2668079.585999999",
    "N": "1261120.3830000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arni AG",
    "PLZ": "8905",
    "Zusatzziffer": "3",
    "Gemeindename": "Arni (AG)",
    "BFS-Nr": "4061",
    "Kantonskürzel": "AG",
    "E": "2674218.158",
    "N": "1241316.5839999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berikon",
    "PLZ": "8965",
    "Zusatzziffer": "0",
    "Gemeindename": "Berikon",
    "BFS-Nr": "4062",
    "Kantonskürzel": "AG",
    "E": "2670599.482000001",
    "N": "1244747.9189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bremgarten AG",
    "PLZ": "5620",
    "Zusatzziffer": "0",
    "Gemeindename": "Bremgarten (AG)",
    "BFS-Nr": "4063",
    "Kantonskürzel": "AG",
    "E": "2668123.1149999984",
    "N": "1244947.2410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hermetschwil-Staffeln",
    "PLZ": "5626",
    "Zusatzziffer": "0",
    "Gemeindename": "Bremgarten (AG)",
    "BFS-Nr": "4063",
    "Kantonskürzel": "AG",
    "E": "2668248.0579999983",
    "N": "1242552.289999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Büttikon AG",
    "PLZ": "5619",
    "Zusatzziffer": "0",
    "Gemeindename": "Büttikon",
    "BFS-Nr": "4064",
    "Kantonskürzel": "AG",
    "E": "2662803.030000001",
    "N": "1242298.0280000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dottikon",
    "PLZ": "5605",
    "Zusatzziffer": "0",
    "Gemeindename": "Dottikon",
    "BFS-Nr": "4065",
    "Kantonskürzel": "AG",
    "E": "2660588.5989999995",
    "N": "1248401.9290000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eggenwil",
    "PLZ": "5445",
    "Zusatzziffer": "0",
    "Gemeindename": "Eggenwil",
    "BFS-Nr": "4066",
    "Kantonskürzel": "AG",
    "E": "2668021.175999999",
    "N": "1246648.3460000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fischbach-Göslikon",
    "PLZ": "5525",
    "Zusatzziffer": "0",
    "Gemeindename": "Fischbach-Göslikon",
    "BFS-Nr": "4067",
    "Kantonskürzel": "AG",
    "E": "2665899.4959999993",
    "N": "1246841.4849999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hägglingen",
    "PLZ": "5607",
    "Zusatzziffer": "0",
    "Gemeindename": "Hägglingen",
    "BFS-Nr": "4068",
    "Kantonskürzel": "AG",
    "E": "2661571.2939999998",
    "N": "1248840.870000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jonen",
    "PLZ": "8916",
    "Zusatzziffer": "0",
    "Gemeindename": "Jonen",
    "BFS-Nr": "4071",
    "Kantonskürzel": "AG",
    "E": "2672371.7630000003",
    "N": "1238764.6490000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederwil AG",
    "PLZ": "5524",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederwil (AG)",
    "BFS-Nr": "4072",
    "Kantonskürzel": "AG",
    "E": "2664622.853",
    "N": "1247897.3680000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nesselnbach",
    "PLZ": "5524",
    "Zusatzziffer": "2",
    "Gemeindename": "Niederwil (AG)",
    "BFS-Nr": "4072",
    "Kantonskürzel": "AG",
    "E": "2664710.958999999",
    "N": "1249190.631000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberlunkhofen",
    "PLZ": "8917",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberlunkhofen",
    "BFS-Nr": "4073",
    "Kantonskürzel": "AG",
    "E": "2671932.539000001",
    "N": "1240605.2939999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwil-Lieli",
    "PLZ": "8966",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberwil-Lieli",
    "BFS-Nr": "4074",
    "Kantonskürzel": "AG",
    "E": "2671571.4750000015",
    "N": "1243193.3729999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rudolfstetten",
    "PLZ": "8964",
    "Zusatzziffer": "0",
    "Gemeindename": "Rudolfstetten-Friedlisberg",
    "BFS-Nr": "4075",
    "Kantonskürzel": "AG",
    "E": "2671043.006000001",
    "N": "1246808.767000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sarmenstorf",
    "PLZ": "5614",
    "Zusatzziffer": "0",
    "Gemeindename": "Sarmenstorf",
    "BFS-Nr": "4076",
    "Kantonskürzel": "AG",
    "E": "2661494.4470000006",
    "N": "1240592.9580000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tägerig",
    "PLZ": "5522",
    "Zusatzziffer": "0",
    "Gemeindename": "Tägerig",
    "BFS-Nr": "4077",
    "Kantonskürzel": "AG",
    "E": "2663614.9510000013",
    "N": "1250520.2479999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uezwil",
    "PLZ": "5619",
    "Zusatzziffer": "2",
    "Gemeindename": "Uezwil",
    "BFS-Nr": "4078",
    "Kantonskürzel": "AG",
    "E": "2663274.829999998",
    "N": "1240853.4659999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterlunkhofen",
    "PLZ": "8918",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterlunkhofen",
    "BFS-Nr": "4079",
    "Kantonskürzel": "AG",
    "E": "2671228.704",
    "N": "1241582.7019999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Villmergen",
    "PLZ": "5612",
    "Zusatzziffer": "0",
    "Gemeindename": "Villmergen",
    "BFS-Nr": "4080",
    "Kantonskürzel": "AG",
    "E": "2660847.522999998",
    "N": "1244187.1959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hilfikon",
    "PLZ": "5613",
    "Zusatzziffer": "0",
    "Gemeindename": "Villmergen",
    "BFS-Nr": "4080",
    "Kantonskürzel": "AG",
    "E": "2661394.0139999986",
    "N": "1242802.9990000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Widen",
    "PLZ": "8967",
    "Zusatzziffer": "0",
    "Gemeindename": "Widen",
    "BFS-Nr": "4081",
    "Kantonskürzel": "AG",
    "E": "2669418.5830000006",
    "N": "1246721.3449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wohlen AG",
    "PLZ": "5610",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlen (AG)",
    "BFS-Nr": "4082",
    "Kantonskürzel": "AG",
    "E": "2663435.3040000014",
    "N": "1244821.6059999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Anglikon",
    "PLZ": "5611",
    "Zusatzziffer": "0",
    "Gemeindename": "Wohlen (AG)",
    "BFS-Nr": "4082",
    "Kantonskürzel": "AG",
    "E": "2662255.739",
    "N": "1246384.197999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zufikon",
    "PLZ": "5621",
    "Zusatzziffer": "0",
    "Gemeindename": "Zufikon",
    "BFS-Nr": "4083",
    "Kantonskürzel": "AG",
    "E": "2669693.528000001",
    "N": "1244149.9699999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Islisberg",
    "PLZ": "8905",
    "Zusatzziffer": "2",
    "Gemeindename": "Islisberg",
    "BFS-Nr": "4084",
    "Kantonskürzel": "AG",
    "E": "2675645.717",
    "N": "1241777.1550000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Auenstein",
    "PLZ": "5105",
    "Zusatzziffer": "0",
    "Gemeindename": "Auenstein",
    "BFS-Nr": "4091",
    "Kantonskürzel": "AG",
    "E": "2652983.0370000005",
    "N": "1251923.7529999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birr",
    "PLZ": "5242",
    "Zusatzziffer": "1",
    "Gemeindename": "Birr",
    "BFS-Nr": "4092",
    "Kantonskürzel": "AG",
    "E": "2657527.2080000006",
    "N": "1254289.4389999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birrhard",
    "PLZ": "5244",
    "Zusatzziffer": "0",
    "Gemeindename": "Birrhard",
    "BFS-Nr": "4093",
    "Kantonskürzel": "AG",
    "E": "2660879.5190000013",
    "N": "1254021.2950000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bözen",
    "PLZ": "5076",
    "Zusatzziffer": "0",
    "Gemeindename": "Bözen",
    "BFS-Nr": "4094",
    "Kantonskürzel": "AG",
    "E": "2648726.8669999987",
    "N": "1260725.0130000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schinznach Bad",
    "PLZ": "5116",
    "Zusatzziffer": "0",
    "Gemeindename": "Brugg",
    "BFS-Nr": "4095",
    "Kantonskürzel": "AG",
    "E": "2655057.8000000007",
    "N": "1256151.2380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brugg AG",
    "PLZ": "5200",
    "Zusatzziffer": "0",
    "Gemeindename": "Brugg",
    "BFS-Nr": "4095",
    "Kantonskürzel": "AG",
    "E": "2657899.8040000014",
    "N": "1259654.1259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Umiken",
    "PLZ": "5222",
    "Zusatzziffer": "0",
    "Gemeindename": "Brugg",
    "BFS-Nr": "4095",
    "Kantonskürzel": "AG",
    "E": "2656613.8220000006",
    "N": "1259301.642000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Effingen",
    "PLZ": "5078",
    "Zusatzziffer": "0",
    "Gemeindename": "Effingen",
    "BFS-Nr": "4096",
    "Kantonskürzel": "AG",
    "E": "2650135.7980000004",
    "N": "1259997.017000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Elfingen",
    "PLZ": "5077",
    "Zusatzziffer": "0",
    "Gemeindename": "Elfingen",
    "BFS-Nr": "4097",
    "Kantonskürzel": "AG",
    "E": "2649740.3729999997",
    "N": "1261993.0870000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Habsburg",
    "PLZ": "5245",
    "Zusatzziffer": "0",
    "Gemeindename": "Habsburg",
    "BFS-Nr": "4099",
    "Kantonskürzel": "AG",
    "E": "2656352.0760000013",
    "N": "1257006.4589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hausen AG",
    "PLZ": "5212",
    "Zusatzziffer": "0",
    "Gemeindename": "Hausen (AG)",
    "BFS-Nr": "4100",
    "Kantonskürzel": "AG",
    "E": "2658278.8630000018",
    "N": "1257100.1180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lupfig",
    "PLZ": "5242",
    "Zusatzziffer": "2",
    "Gemeindename": "Lupfig",
    "BFS-Nr": "4104",
    "Kantonskürzel": "AG",
    "E": "2657926.971999999",
    "N": "1254578.7690000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Scherz",
    "PLZ": "5246",
    "Zusatzziffer": "0",
    "Gemeindename": "Lupfig",
    "BFS-Nr": "4104",
    "Kantonskürzel": "AG",
    "E": "2656232.4679999985",
    "N": "1255388.675999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mandach",
    "PLZ": "5318",
    "Zusatzziffer": "0",
    "Gemeindename": "Mandach",
    "BFS-Nr": "4105",
    "Kantonskürzel": "AG",
    "E": "2656274.1499999985",
    "N": "1266498.5540000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mönthal",
    "PLZ": "5237",
    "Zusatzziffer": "0",
    "Gemeindename": "Mönthal",
    "BFS-Nr": "4106",
    "Kantonskürzel": "AG",
    "E": "2653079.574000001",
    "N": "1263253.8509999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mülligen",
    "PLZ": "5243",
    "Zusatzziffer": "0",
    "Gemeindename": "Mülligen",
    "BFS-Nr": "4107",
    "Kantonskürzel": "AG",
    "E": "2660385.2600000016",
    "N": "1256646.7859999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Remigen",
    "PLZ": "5236",
    "Zusatzziffer": "0",
    "Gemeindename": "Remigen",
    "BFS-Nr": "4110",
    "Kantonskürzel": "AG",
    "E": "2656364.3960000016",
    "N": "1263080.561999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riniken",
    "PLZ": "5223",
    "Zusatzziffer": "0",
    "Gemeindename": "Riniken",
    "BFS-Nr": "4111",
    "Kantonskürzel": "AG",
    "E": "2656618.473000001",
    "N": "1260512.6169999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rüfenach AG",
    "PLZ": "5235",
    "Zusatzziffer": "0",
    "Gemeindename": "Rüfenach",
    "BFS-Nr": "4112",
    "Kantonskürzel": "AG",
    "E": "2658041.368999999",
    "N": "1262104.3280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thalheim AG",
    "PLZ": "5112",
    "Zusatzziffer": "0",
    "Gemeindename": "Thalheim (AG)",
    "BFS-Nr": "4117",
    "Kantonskürzel": "AG",
    "E": "2650105.3379999995",
    "N": "1254122.454",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Veltheim AG",
    "PLZ": "5106",
    "Zusatzziffer": "0",
    "Gemeindename": "Veltheim (AG)",
    "BFS-Nr": "4120",
    "Kantonskürzel": "AG",
    "E": "2653459.403999999",
    "N": "1254407.6629999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stilli",
    "PLZ": "5233",
    "Zusatzziffer": "0",
    "Gemeindename": "Villigen",
    "BFS-Nr": "4121",
    "Kantonskürzel": "AG",
    "E": "2659721.4389999993",
    "N": "1263162.3299999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Villigen",
    "PLZ": "5234",
    "Zusatzziffer": "0",
    "Gemeindename": "Villigen",
    "BFS-Nr": "4121",
    "Kantonskürzel": "AG",
    "E": "2658316.3779999986",
    "N": "1264331.3359999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Villnachern",
    "PLZ": "5213",
    "Zusatzziffer": "0",
    "Gemeindename": "Villnachern",
    "BFS-Nr": "4122",
    "Kantonskürzel": "AG",
    "E": "2654125.7630000003",
    "N": "1257867.2490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Windisch",
    "PLZ": "5210",
    "Zusatzziffer": "0",
    "Gemeindename": "Windisch",
    "BFS-Nr": "4123",
    "Kantonskürzel": "AG",
    "E": "2658398.6849999987",
    "N": "1258614.925999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bözberg",
    "PLZ": "5225",
    "Zusatzziffer": "1",
    "Gemeindename": "Bözberg",
    "BFS-Nr": "4124",
    "Kantonskürzel": "AG",
    "E": "2654468.670000002",
    "N": "1260307.4409999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schinznach Dorf",
    "PLZ": "5107",
    "Zusatzziffer": "0",
    "Gemeindename": "Schinznach",
    "BFS-Nr": "4125",
    "Kantonskürzel": "AG",
    "E": "2653092.4169999994",
    "N": "1255302.7699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberflachs",
    "PLZ": "5108",
    "Zusatzziffer": "0",
    "Gemeindename": "Schinznach",
    "BFS-Nr": "4125",
    "Kantonskürzel": "AG",
    "E": "2652193.024",
    "N": "1254684.2129999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beinwil am See",
    "PLZ": "5712",
    "Zusatzziffer": "0",
    "Gemeindename": "Beinwil am See",
    "BFS-Nr": "4131",
    "Kantonskürzel": "AG",
    "E": "2658257.017999999",
    "N": "1235183.7989999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birrwil",
    "PLZ": "5708",
    "Zusatzziffer": "0",
    "Gemeindename": "Birrwil",
    "BFS-Nr": "4132",
    "Kantonskürzel": "AG",
    "E": "2657421.870000001",
    "N": "1237973.2010000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Burg AG",
    "PLZ": "5736",
    "Zusatzziffer": "0",
    "Gemeindename": "Burg (AG)",
    "BFS-Nr": "4133",
    "Kantonskürzel": "AG",
    "E": "2655987.2430000007",
    "N": "1231704.5",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dürrenäsch",
    "PLZ": "5724",
    "Zusatzziffer": "0",
    "Gemeindename": "Dürrenäsch",
    "BFS-Nr": "4134",
    "Kantonskürzel": "AG",
    "E": "2654344.897999998",
    "N": "1241409.3339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gontenschwil",
    "PLZ": "5728",
    "Zusatzziffer": "0",
    "Gemeindename": "Gontenschwil",
    "BFS-Nr": "4135",
    "Kantonskürzel": "AG",
    "E": "2653464.954",
    "N": "1235892.7589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Holziken",
    "PLZ": "5043",
    "Zusatzziffer": "0",
    "Gemeindename": "Holziken",
    "BFS-Nr": "4136",
    "Kantonskürzel": "AG",
    "E": "2645035.3280000016",
    "N": "1241130.818",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leimbach AG",
    "PLZ": "5733",
    "Zusatzziffer": "0",
    "Gemeindename": "Leimbach (AG)",
    "BFS-Nr": "4137",
    "Kantonskürzel": "AG",
    "E": "2655344.721000001",
    "N": "1236254.3189999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leutwil",
    "PLZ": "5725",
    "Zusatzziffer": "0",
    "Gemeindename": "Leutwil",
    "BFS-Nr": "4138",
    "Kantonskürzel": "AG",
    "E": "2655607.640999999",
    "N": "1240104.4050000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Menziken",
    "PLZ": "5737",
    "Zusatzziffer": "0",
    "Gemeindename": "Menziken",
    "BFS-Nr": "4139",
    "Kantonskürzel": "AG",
    "E": "2656616.2430000007",
    "N": "1232335.2109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberkulm",
    "PLZ": "5727",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberkulm",
    "BFS-Nr": "4140",
    "Kantonskürzel": "AG",
    "E": "2651841.5370000005",
    "N": "1238902.9910000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reinach AG",
    "PLZ": "5734",
    "Zusatzziffer": "0",
    "Gemeindename": "Reinach (AG)",
    "BFS-Nr": "4141",
    "Kantonskürzel": "AG",
    "E": "2656754.886",
    "N": "1234194.0879999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schlossrued",
    "PLZ": "5044",
    "Zusatzziffer": "0",
    "Gemeindename": "Schlossrued",
    "BFS-Nr": "4142",
    "Kantonskürzel": "AG",
    "E": "2649199.3489999995",
    "N": "1238186.2600000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schmiedrued",
    "PLZ": "5046",
    "Zusatzziffer": "1",
    "Gemeindename": "Schmiedrued",
    "BFS-Nr": "4143",
    "Kantonskürzel": "AG",
    "E": "2650697.2349999994",
    "N": "1235780.4189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Walde AG",
    "PLZ": "5046",
    "Zusatzziffer": "2",
    "Gemeindename": "Schmiedrued",
    "BFS-Nr": "4143",
    "Kantonskürzel": "AG",
    "E": "2650978.6149999984",
    "N": "1234428.4930000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schöftland",
    "PLZ": "5040",
    "Zusatzziffer": "0",
    "Gemeindename": "Schöftland",
    "BFS-Nr": "4144",
    "Kantonskürzel": "AG",
    "E": "2646284.215",
    "N": "1239505.2280000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Teufenthal AG",
    "PLZ": "5723",
    "Zusatzziffer": "0",
    "Gemeindename": "Teufenthal (AG)",
    "BFS-Nr": "4145",
    "Kantonskürzel": "AG",
    "E": "2651945.3310000002",
    "N": "1242143.232999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterkulm",
    "PLZ": "5726",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterkulm",
    "BFS-Nr": "4146",
    "Kantonskürzel": "AG",
    "E": "2651184.390999999",
    "N": "1240178.6130000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zetzwil",
    "PLZ": "5732",
    "Zusatzziffer": "0",
    "Gemeindename": "Zetzwil",
    "BFS-Nr": "4147",
    "Kantonskürzel": "AG",
    "E": "2653885.9329999983",
    "N": "1237727.9239999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eiken",
    "PLZ": "5074",
    "Zusatzziffer": "0",
    "Gemeindename": "Eiken",
    "BFS-Nr": "4161",
    "Kantonskürzel": "AG",
    "E": "2641445.409000002",
    "N": "1264726.4109999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frick",
    "PLZ": "5070",
    "Zusatzziffer": "0",
    "Gemeindename": "Frick",
    "BFS-Nr": "4163",
    "Kantonskürzel": "AG",
    "E": "2643984.7030000016",
    "N": "1261682.4100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gansingen",
    "PLZ": "5272",
    "Zusatzziffer": "0",
    "Gemeindename": "Gansingen",
    "BFS-Nr": "4164",
    "Kantonskürzel": "AG",
    "E": "2652805.1849999987",
    "N": "1266031.7049999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gipf-Oberfrick",
    "PLZ": "5073",
    "Zusatzziffer": "0",
    "Gemeindename": "Gipf-Oberfrick",
    "BFS-Nr": "4165",
    "Kantonskürzel": "AG",
    "E": "2642556.0040000007",
    "N": "1260877.2870000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herznach",
    "PLZ": "5027",
    "Zusatzziffer": "0",
    "Gemeindename": "Herznach",
    "BFS-Nr": "4166",
    "Kantonskürzel": "AG",
    "E": "2645902.614",
    "N": "1258034.699000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hornussen",
    "PLZ": "5075",
    "Zusatzziffer": "0",
    "Gemeindename": "Hornussen",
    "BFS-Nr": "4167",
    "Kantonskürzel": "AG",
    "E": "2647043.2459999993",
    "N": "1261076.1620000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaisten",
    "PLZ": "5082",
    "Zusatzziffer": "0",
    "Gemeindename": "Kaisten",
    "BFS-Nr": "4169",
    "Kantonskürzel": "AG",
    "E": "2645721.6339999996",
    "N": "1265665.1999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ittenthal",
    "PLZ": "5083",
    "Zusatzziffer": "0",
    "Gemeindename": "Kaisten",
    "BFS-Nr": "4169",
    "Kantonskürzel": "AG",
    "E": "2646686.166000001",
    "N": "1263163.3850000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Laufenburg",
    "PLZ": "5080",
    "Zusatzziffer": "0",
    "Gemeindename": "Laufenburg",
    "BFS-Nr": "4170",
    "Kantonskürzel": "AG",
    "E": "2646877.011",
    "N": "1268105.5469999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rheinsulz",
    "PLZ": "5084",
    "Zusatzziffer": "0",
    "Gemeindename": "Laufenburg",
    "BFS-Nr": "4170",
    "Kantonskürzel": "AG",
    "E": "2649029.493999999",
    "N": "1267425.0410000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sulz AG",
    "PLZ": "5085",
    "Zusatzziffer": "0",
    "Gemeindename": "Laufenburg",
    "BFS-Nr": "4170",
    "Kantonskürzel": "AG",
    "E": "2649617.2430000007",
    "N": "1265050.5350000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münchwilen AG",
    "PLZ": "4333",
    "Zusatzziffer": "0",
    "Gemeindename": "Münchwilen (AG)",
    "BFS-Nr": "4172",
    "Kantonskürzel": "AG",
    "E": "2639507.8049999997",
    "N": "1265260.039999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhof",
    "PLZ": "5062",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberhof",
    "BFS-Nr": "4173",
    "Kantonskürzel": "AG",
    "E": "2642545.272999998",
    "N": "1255480.8040000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oeschgen",
    "PLZ": "5072",
    "Zusatzziffer": "0",
    "Gemeindename": "Oeschgen",
    "BFS-Nr": "4175",
    "Kantonskürzel": "AG",
    "E": "2643710.8960000016",
    "N": "1263754.1050000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schwaderloch",
    "PLZ": "5326",
    "Zusatzziffer": "0",
    "Gemeindename": "Schwaderloch",
    "BFS-Nr": "4176",
    "Kantonskürzel": "AG",
    "E": "2653096.403999999",
    "N": "1270788.9100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sisseln AG",
    "PLZ": "4334",
    "Zusatzziffer": "0",
    "Gemeindename": "Sisseln",
    "BFS-Nr": "4177",
    "Kantonskürzel": "AG",
    "E": "2641154.136",
    "N": "1266970.3000000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ueken",
    "PLZ": "5028",
    "Zusatzziffer": "0",
    "Gemeindename": "Ueken",
    "BFS-Nr": "4179",
    "Kantonskürzel": "AG",
    "E": "2645587.7580000013",
    "N": "1259679.4860000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wittnau",
    "PLZ": "5064",
    "Zusatzziffer": "0",
    "Gemeindename": "Wittnau",
    "BFS-Nr": "4181",
    "Kantonskürzel": "AG",
    "E": "2640415.4910000004",
    "N": "1259034.892000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wölflinswil",
    "PLZ": "5063",
    "Zusatzziffer": "0",
    "Gemeindename": "Wölflinswil",
    "BFS-Nr": "4182",
    "Kantonskürzel": "AG",
    "E": "2642000.528000001",
    "N": "1256933.8610000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zeihen",
    "PLZ": "5079",
    "Zusatzziffer": "0",
    "Gemeindename": "Zeihen",
    "BFS-Nr": "4183",
    "Kantonskürzel": "AG",
    "E": "2648922.2630000003",
    "N": "1258487.2980000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhofen AG",
    "PLZ": "5273",
    "Zusatzziffer": "0",
    "Gemeindename": "Mettauertal",
    "BFS-Nr": "4184",
    "Kantonskürzel": "AG",
    "E": "2652292.482999999",
    "N": "1267321.193",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mettau",
    "PLZ": "5274",
    "Zusatzziffer": "0",
    "Gemeindename": "Mettauertal",
    "BFS-Nr": "4184",
    "Kantonskürzel": "AG",
    "E": "2651744.1110000014",
    "N": "1268404.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Etzgen",
    "PLZ": "5275",
    "Zusatzziffer": "0",
    "Gemeindename": "Mettauertal",
    "BFS-Nr": "4184",
    "Kantonskürzel": "AG",
    "E": "2650176.8489999995",
    "N": "1269400.7190000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wil AG",
    "PLZ": "5276",
    "Zusatzziffer": "0",
    "Gemeindename": "Mettauertal",
    "BFS-Nr": "4184",
    "Kantonskürzel": "AG",
    "E": "2653767.5100000016",
    "N": "1268004.1440000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hottwil",
    "PLZ": "5277",
    "Zusatzziffer": "0",
    "Gemeindename": "Mettauertal",
    "BFS-Nr": "4184",
    "Kantonskürzel": "AG",
    "E": "2654398.3999999985",
    "N": "1266597.129999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ammerswil AG",
    "PLZ": "5600",
    "Zusatzziffer": "3",
    "Gemeindename": "Ammerswil",
    "BFS-Nr": "4191",
    "Kantonskürzel": "AG",
    "E": "2658200.572999999",
    "N": "1247006.039999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Boniswil",
    "PLZ": "5706",
    "Zusatzziffer": "0",
    "Gemeindename": "Boniswil",
    "BFS-Nr": "4192",
    "Kantonskürzel": "AG",
    "E": "2656389.4439999983",
    "N": "1240747.7250000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brunegg",
    "PLZ": "5505",
    "Zusatzziffer": "0",
    "Gemeindename": "Brunegg",
    "BFS-Nr": "4193",
    "Kantonskürzel": "AG",
    "E": "2658731.41",
    "N": "1252306.4530000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dintikon",
    "PLZ": "5606",
    "Zusatzziffer": "0",
    "Gemeindename": "Dintikon",
    "BFS-Nr": "4194",
    "Kantonskürzel": "AG",
    "E": "2659542.5940000005",
    "N": "1245967.0799999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Egliswil",
    "PLZ": "5704",
    "Zusatzziffer": "0",
    "Gemeindename": "Egliswil",
    "BFS-Nr": "4195",
    "Kantonskürzel": "AG",
    "E": "2656443.5500000007",
    "N": "1244589.1510000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fahrwangen",
    "PLZ": "5615",
    "Zusatzziffer": "0",
    "Gemeindename": "Fahrwangen",
    "BFS-Nr": "4196",
    "Kantonskürzel": "AG",
    "E": "2660785.8630000018",
    "N": "1238528.5040000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hallwil",
    "PLZ": "5705",
    "Zusatzziffer": "0",
    "Gemeindename": "Hallwil",
    "BFS-Nr": "4197",
    "Kantonskürzel": "AG",
    "E": "2655732.0029999986",
    "N": "1242241.6829999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hendschiken",
    "PLZ": "5604",
    "Zusatzziffer": "0",
    "Gemeindename": "Hendschiken",
    "BFS-Nr": "4198",
    "Kantonskürzel": "AG",
    "E": "2658772.598000001",
    "N": "1248635.5540000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Holderbank AG",
    "PLZ": "5113",
    "Zusatzziffer": "0",
    "Gemeindename": "Holderbank (AG)",
    "BFS-Nr": "4199",
    "Kantonskürzel": "AG",
    "E": "2655228.5810000002",
    "N": "1253605.9329999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hunzenschwil",
    "PLZ": "5502",
    "Zusatzziffer": "0",
    "Gemeindename": "Hunzenschwil",
    "BFS-Nr": "4200",
    "Kantonskürzel": "AG",
    "E": "2651681.2569999993",
    "N": "1248355.171",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lenzburg",
    "PLZ": "5600",
    "Zusatzziffer": "0",
    "Gemeindename": "Lenzburg",
    "BFS-Nr": "4201",
    "Kantonskürzel": "AG",
    "E": "2655924.978",
    "N": "1248846.3339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meisterschwanden",
    "PLZ": "5616",
    "Zusatzziffer": "0",
    "Gemeindename": "Meisterschwanden",
    "BFS-Nr": "4202",
    "Kantonskürzel": "AG",
    "E": "2660193.631000001",
    "N": "1238554.7850000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tennwil",
    "PLZ": "5617",
    "Zusatzziffer": "0",
    "Gemeindename": "Meisterschwanden",
    "BFS-Nr": "4202",
    "Kantonskürzel": "AG",
    "E": "2659153.8630000018",
    "N": "1239835.7699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wildegg",
    "PLZ": "5103",
    "Zusatzziffer": "0",
    "Gemeindename": "Möriken-Wildegg",
    "BFS-Nr": "4203",
    "Kantonskürzel": "AG",
    "E": "2654827.484000001",
    "N": "1251927.835000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Möriken AG",
    "PLZ": "5103",
    "Zusatzziffer": "2",
    "Gemeindename": "Möriken-Wildegg",
    "BFS-Nr": "4203",
    "Kantonskürzel": "AG",
    "E": "2656775.870000001",
    "N": "1252012.2650000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederlenz",
    "PLZ": "5702",
    "Zusatzziffer": "0",
    "Gemeindename": "Niederlenz",
    "BFS-Nr": "4204",
    "Kantonskürzel": "AG",
    "E": "2655234.3709999993",
    "N": "1250466.1849999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Othmarsingen",
    "PLZ": "5504",
    "Zusatzziffer": "0",
    "Gemeindename": "Othmarsingen",
    "BFS-Nr": "4205",
    "Kantonskürzel": "AG",
    "E": "2658238.517999999",
    "N": "1250429.7950000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rupperswil",
    "PLZ": "5102",
    "Zusatzziffer": "0",
    "Gemeindename": "Rupperswil",
    "BFS-Nr": "4206",
    "Kantonskürzel": "AG",
    "E": "2652533.6389999986",
    "N": "1250449.0190000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schafisheim",
    "PLZ": "5503",
    "Zusatzziffer": "0",
    "Gemeindename": "Schafisheim",
    "BFS-Nr": "4207",
    "Kantonskürzel": "AG",
    "E": "2653138.916000001",
    "N": "1247721.5540000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seengen",
    "PLZ": "5707",
    "Zusatzziffer": "0",
    "Gemeindename": "Seengen",
    "BFS-Nr": "4208",
    "Kantonskürzel": "AG",
    "E": "2657785.302000001",
    "N": "1242006.4549999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Seon",
    "PLZ": "5703",
    "Zusatzziffer": "0",
    "Gemeindename": "Seon",
    "BFS-Nr": "4209",
    "Kantonskürzel": "AG",
    "E": "2654264.7699999996",
    "N": "1244173.9690000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Staufen",
    "PLZ": "5603",
    "Zusatzziffer": "0",
    "Gemeindename": "Staufen",
    "BFS-Nr": "4210",
    "Kantonskürzel": "AG",
    "E": "2654692.1499999985",
    "N": "1248020.2760000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Abtwil AG",
    "PLZ": "5646",
    "Zusatzziffer": "0",
    "Gemeindename": "Abtwil",
    "BFS-Nr": "4221",
    "Kantonskürzel": "AG",
    "E": "2669471.2939999998",
    "N": "1225299.0040000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aristau",
    "PLZ": "5628",
    "Zusatzziffer": "0",
    "Gemeindename": "Aristau",
    "BFS-Nr": "4222",
    "Kantonskürzel": "AG",
    "E": "2669772.908",
    "N": "1237748.2430000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Auw",
    "PLZ": "5644",
    "Zusatzziffer": "0",
    "Gemeindename": "Auw",
    "BFS-Nr": "4223",
    "Kantonskürzel": "AG",
    "E": "2670334.708999999",
    "N": "1229210.563000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Beinwil (Freiamt)",
    "PLZ": "5637",
    "Zusatzziffer": "0",
    "Gemeindename": "Beinwil (Freiamt)",
    "BFS-Nr": "4224",
    "Kantonskürzel": "AG",
    "E": "2668747.232000001",
    "N": "1231431.8649999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Besenbüren",
    "PLZ": "5627",
    "Zusatzziffer": "0",
    "Gemeindename": "Besenbüren",
    "BFS-Nr": "4226",
    "Kantonskürzel": "AG",
    "E": "2668461.7809999995",
    "N": "1240517",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bettwil",
    "PLZ": "5618",
    "Zusatzziffer": "0",
    "Gemeindename": "Bettwil",
    "BFS-Nr": "4227",
    "Kantonskürzel": "AG",
    "E": "2662625.094999999",
    "N": "1238081.8159999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Boswil",
    "PLZ": "5623",
    "Zusatzziffer": "0",
    "Gemeindename": "Boswil",
    "BFS-Nr": "4228",
    "Kantonskürzel": "AG",
    "E": "2665808.0780000016",
    "N": "1239579.120000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bünzen",
    "PLZ": "5624",
    "Zusatzziffer": "0",
    "Gemeindename": "Bünzen",
    "BFS-Nr": "4229",
    "Kantonskürzel": "AG",
    "E": "2667146.6160000004",
    "N": "1240227.4160000011",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Waldhäusern AG",
    "PLZ": "5624",
    "Zusatzziffer": "2",
    "Gemeindename": "Bünzen",
    "BFS-Nr": "4229",
    "Kantonskürzel": "AG",
    "E": "2666259.0659999996",
    "N": "1242148.2639999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buttwil",
    "PLZ": "5632",
    "Zusatzziffer": "0",
    "Gemeindename": "Buttwil",
    "BFS-Nr": "4230",
    "Kantonskürzel": "AG",
    "E": "2666227.2989999987",
    "N": "1235772.3969999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dietwil",
    "PLZ": "6042",
    "Zusatzziffer": "0",
    "Gemeindename": "Dietwil",
    "BFS-Nr": "4231",
    "Kantonskürzel": "AG",
    "E": "2672382.4679999985",
    "N": "1222292.0469999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Geltwil",
    "PLZ": "5637",
    "Zusatzziffer": "2",
    "Gemeindename": "Geltwil",
    "BFS-Nr": "4232",
    "Kantonskürzel": "AG",
    "E": "2667174.818",
    "N": "1233500.6429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kallern",
    "PLZ": "5625",
    "Zusatzziffer": "0",
    "Gemeindename": "Kallern",
    "BFS-Nr": "4233",
    "Kantonskürzel": "AG",
    "E": "2664623.465",
    "N": "1240606.4459999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Merenschwand",
    "PLZ": "5634",
    "Zusatzziffer": "0",
    "Gemeindename": "Merenschwand",
    "BFS-Nr": "4234",
    "Kantonskürzel": "AG",
    "E": "2670927.870000001",
    "N": "1234793.5329999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Benzenschwil",
    "PLZ": "5636",
    "Zusatzziffer": "0",
    "Gemeindename": "Merenschwand",
    "BFS-Nr": "4234",
    "Kantonskürzel": "AG",
    "E": "2670036.0529999994",
    "N": "1233254.7840000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühlau",
    "PLZ": "5642",
    "Zusatzziffer": "0",
    "Gemeindename": "Mühlau",
    "BFS-Nr": "4235",
    "Kantonskürzel": "AG",
    "E": "2672074.033",
    "N": "1231440.5480000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Muri AG",
    "PLZ": "5630",
    "Zusatzziffer": "0",
    "Gemeindename": "Muri (AG)",
    "BFS-Nr": "4236",
    "Kantonskürzel": "AG",
    "E": "2668106.2080000006",
    "N": "1236104.5960000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberrüti",
    "PLZ": "5647",
    "Zusatzziffer": "0",
    "Gemeindename": "Oberrüti",
    "BFS-Nr": "4237",
    "Kantonskürzel": "AG",
    "E": "2672595.153999999",
    "N": "1224298.1590000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rottenschwil",
    "PLZ": "8919",
    "Zusatzziffer": "0",
    "Gemeindename": "Rottenschwil",
    "BFS-Nr": "4238",
    "Kantonskürzel": "AG",
    "E": "2670113.8099999987",
    "N": "1240808.6240000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sins",
    "PLZ": "5643",
    "Zusatzziffer": "0",
    "Gemeindename": "Sins",
    "BFS-Nr": "4239",
    "Kantonskürzel": "AG",
    "E": "2672506.7399999984",
    "N": "1227517.8359999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sins",
    "PLZ": "5643",
    "Zusatzziffer": "0",
    "Gemeindename": "Sins",
    "BFS-Nr": "4239",
    "Kantonskürzel": "AG",
    "E": "2668800.438000001",
    "N": "1226651.8440000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alikon",
    "PLZ": "5643",
    "Zusatzziffer": "2",
    "Gemeindename": "Sins",
    "BFS-Nr": "4239",
    "Kantonskürzel": "AG",
    "E": "2669795.8999999985",
    "N": "1228040.506000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Meienberg",
    "PLZ": "5643",
    "Zusatzziffer": "3",
    "Gemeindename": "Sins",
    "BFS-Nr": "4239",
    "Kantonskürzel": "AG",
    "E": "2671047.2250000015",
    "N": "1227854.3630000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aettenschwil",
    "PLZ": "5645",
    "Zusatzziffer": "0",
    "Gemeindename": "Sins",
    "BFS-Nr": "4239",
    "Kantonskürzel": "AG",
    "E": "2670592.1779999994",
    "N": "1226562.914999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fenkrieden",
    "PLZ": "5645",
    "Zusatzziffer": "2",
    "Gemeindename": "Sins",
    "BFS-Nr": "4239",
    "Kantonskürzel": "AG",
    "E": "2670602.8139999993",
    "N": "1223777.9530000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Waltenschwil",
    "PLZ": "5622",
    "Zusatzziffer": "0",
    "Gemeindename": "Waltenschwil",
    "BFS-Nr": "4240",
    "Kantonskürzel": "AG",
    "E": "2665262.287999999",
    "N": "1242916.9649999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hellikon",
    "PLZ": "4316",
    "Zusatzziffer": "0",
    "Gemeindename": "Hellikon",
    "BFS-Nr": "4251",
    "Kantonskürzel": "AG",
    "E": "2636502.5529999994",
    "N": "1261993.9629999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaiseraugst",
    "PLZ": "4303",
    "Zusatzziffer": "0",
    "Gemeindename": "Kaiseraugst",
    "BFS-Nr": "4252",
    "Kantonskürzel": "AG",
    "E": "2621556.726",
    "N": "1265470.2580000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Magden",
    "PLZ": "4312",
    "Zusatzziffer": "0",
    "Gemeindename": "Magden",
    "BFS-Nr": "4253",
    "Kantonskürzel": "AG",
    "E": "2628032.75",
    "N": "1264531.982999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Möhlin",
    "PLZ": "4313",
    "Zusatzziffer": "0",
    "Gemeindename": "Möhlin",
    "BFS-Nr": "4254",
    "Kantonskürzel": "AG",
    "E": "2630463.870000001",
    "N": "1267968.3280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mumpf",
    "PLZ": "4322",
    "Zusatzziffer": "0",
    "Gemeindename": "Mumpf",
    "BFS-Nr": "4255",
    "Kantonskürzel": "AG",
    "E": "2636448.4750000015",
    "N": "1266226.7199999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obermumpf",
    "PLZ": "4324",
    "Zusatzziffer": "0",
    "Gemeindename": "Obermumpf",
    "BFS-Nr": "4256",
    "Kantonskürzel": "AG",
    "E": "2637270.6689999998",
    "N": "1264514.6920000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Olsberg",
    "PLZ": "4305",
    "Zusatzziffer": "0",
    "Gemeindename": "Olsberg",
    "BFS-Nr": "4257",
    "Kantonskürzel": "AG",
    "E": "2625965.368999999",
    "N": "1263402.6790000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rheinfelden",
    "PLZ": "4310",
    "Zusatzziffer": "0",
    "Gemeindename": "Rheinfelden",
    "BFS-Nr": "4258",
    "Kantonskürzel": "AG",
    "E": "2626654.3630000018",
    "N": "1267108.2540000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schupfart",
    "PLZ": "4325",
    "Zusatzziffer": "0",
    "Gemeindename": "Schupfart",
    "BFS-Nr": "4259",
    "Kantonskürzel": "AG",
    "E": "2639773.5650000013",
    "N": "1262642.232999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stein AG",
    "PLZ": "4332",
    "Zusatzziffer": "0",
    "Gemeindename": "Stein (AG)",
    "BFS-Nr": "4260",
    "Kantonskürzel": "AG",
    "E": "2638601.6050000004",
    "N": "1265980.335000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wallbach",
    "PLZ": "4323",
    "Zusatzziffer": "0",
    "Gemeindename": "Wallbach",
    "BFS-Nr": "4261",
    "Kantonskürzel": "AG",
    "E": "2634753.6389999986",
    "N": "1268089.9690000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wegenstetten",
    "PLZ": "4317",
    "Zusatzziffer": "0",
    "Gemeindename": "Wegenstetten",
    "BFS-Nr": "4262",
    "Kantonskürzel": "AG",
    "E": "2637426.397999998",
    "N": "1261001.4519999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zeiningen",
    "PLZ": "4314",
    "Zusatzziffer": "0",
    "Gemeindename": "Zeiningen",
    "BFS-Nr": "4263",
    "Kantonskürzel": "AG",
    "E": "2632474.0309999995",
    "N": "1265799.568",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zuzgen",
    "PLZ": "4315",
    "Zusatzziffer": "0",
    "Gemeindename": "Zuzgen",
    "BFS-Nr": "4264",
    "Kantonskürzel": "AG",
    "E": "2634525.0210000016",
    "N": "1263867.7639999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aarburg",
    "PLZ": "4663",
    "Zusatzziffer": "0",
    "Gemeindename": "Aarburg",
    "BFS-Nr": "4271",
    "Kantonskürzel": "AG",
    "E": "2634825.715",
    "N": "1241201.3060000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bottenwil",
    "PLZ": "4814",
    "Zusatzziffer": "0",
    "Gemeindename": "Bottenwil",
    "BFS-Nr": "4273",
    "Kantonskürzel": "AG",
    "E": "2642848.2399999984",
    "N": "1237249.3060000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brittnau",
    "PLZ": "4805",
    "Zusatzziffer": "0",
    "Gemeindename": "Brittnau",
    "BFS-Nr": "4274",
    "Kantonskürzel": "AG",
    "E": "2638578.84",
    "N": "1234347.9510000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kirchleerau",
    "PLZ": "5054",
    "Zusatzziffer": "1",
    "Gemeindename": "Kirchleerau",
    "BFS-Nr": "4275",
    "Kantonskürzel": "AG",
    "E": "2647639.6959999986",
    "N": "1236356.7309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kölliken",
    "PLZ": "5742",
    "Zusatzziffer": "0",
    "Gemeindename": "Kölliken",
    "BFS-Nr": "4276",
    "Kantonskürzel": "AG",
    "E": "2644647.2749999985",
    "N": "1243183.890999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Moosleerau",
    "PLZ": "5054",
    "Zusatzziffer": "2",
    "Gemeindename": "Moosleerau",
    "BFS-Nr": "4277",
    "Kantonskürzel": "AG",
    "E": "2647368.478",
    "N": "1235544.4290000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Murgenthal",
    "PLZ": "4853",
    "Zusatzziffer": "0",
    "Gemeindename": "Murgenthal",
    "BFS-Nr": "4279",
    "Kantonskürzel": "AG",
    "E": "2629790.8999999985",
    "N": "1235020.8280000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riken AG",
    "PLZ": "4853",
    "Zusatzziffer": "2",
    "Gemeindename": "Murgenthal",
    "BFS-Nr": "4279",
    "Kantonskürzel": "AG",
    "E": "2631094.1939999983",
    "N": "1236228.7250000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Glashütten",
    "PLZ": "4856",
    "Zusatzziffer": "0",
    "Gemeindename": "Murgenthal",
    "BFS-Nr": "4279",
    "Kantonskürzel": "AG",
    "E": "2630795.658",
    "N": "1234274.6429999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oftringen",
    "PLZ": "4665",
    "Zusatzziffer": "0",
    "Gemeindename": "Oftringen",
    "BFS-Nr": "4280",
    "Kantonskürzel": "AG",
    "E": "2637192.8330000006",
    "N": "1240055.5370000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Attelwil",
    "PLZ": "5056",
    "Zusatzziffer": "0",
    "Gemeindename": "Reitnau",
    "BFS-Nr": "4281",
    "Kantonskürzel": "AG",
    "E": "2645870.028999999",
    "N": "1234768.6009999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reitnau",
    "PLZ": "5057",
    "Zusatzziffer": "0",
    "Gemeindename": "Reitnau",
    "BFS-Nr": "4281",
    "Kantonskürzel": "AG",
    "E": "2646197.719999999",
    "N": "1233524.859000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rothrist",
    "PLZ": "4852",
    "Zusatzziffer": "0",
    "Gemeindename": "Rothrist",
    "BFS-Nr": "4282",
    "Kantonskürzel": "AG",
    "E": "2633234.659000002",
    "N": "1239572.642000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Safenwil",
    "PLZ": "5745",
    "Zusatzziffer": "0",
    "Gemeindename": "Safenwil",
    "BFS-Nr": "4283",
    "Kantonskürzel": "AG",
    "E": "2641093.5190000013",
    "N": "1240886.4100000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Staffelbach",
    "PLZ": "5053",
    "Zusatzziffer": "0",
    "Gemeindename": "Staffelbach",
    "BFS-Nr": "4284",
    "Kantonskürzel": "AG",
    "E": "2645672.8790000007",
    "N": "1237156.675999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wittwil",
    "PLZ": "5053",
    "Zusatzziffer": "2",
    "Gemeindename": "Staffelbach",
    "BFS-Nr": "4284",
    "Kantonskürzel": "AG",
    "E": "2645459.965",
    "N": "1237913.796",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Strengelbach",
    "PLZ": "4802",
    "Zusatzziffer": "0",
    "Gemeindename": "Strengelbach",
    "BFS-Nr": "4285",
    "Kantonskürzel": "AG",
    "E": "2636991.4450000003",
    "N": "1236670.4230000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uerkheim",
    "PLZ": "4813",
    "Zusatzziffer": "0",
    "Gemeindename": "Uerkheim",
    "BFS-Nr": "4286",
    "Kantonskürzel": "AG",
    "E": "2644333.030000001",
    "N": "1239642.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Vordemwald",
    "PLZ": "4803",
    "Zusatzziffer": "0",
    "Gemeindename": "Vordemwald",
    "BFS-Nr": "4287",
    "Kantonskürzel": "AG",
    "E": "2634619.3940000013",
    "N": "1235842.7250000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiliberg",
    "PLZ": "5058",
    "Zusatzziffer": "0",
    "Gemeindename": "Wiliberg",
    "BFS-Nr": "4288",
    "Kantonskürzel": "AG",
    "E": "2644175.6350000016",
    "N": "1235355.3949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zofingen",
    "PLZ": "4800",
    "Zusatzziffer": "0",
    "Gemeindename": "Zofingen",
    "BFS-Nr": "4289",
    "Kantonskürzel": "AG",
    "E": "2638310.8079999983",
    "N": "1237670.6779999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühlethal",
    "PLZ": "4812",
    "Zusatzziffer": "0",
    "Gemeindename": "Zofingen",
    "BFS-Nr": "4289",
    "Kantonskürzel": "AG",
    "E": "2640800.9239999987",
    "N": "1239084.7490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Baldingen",
    "PLZ": "5333",
    "Zusatzziffer": "0",
    "Gemeindename": "Baldingen",
    "BFS-Nr": "4301",
    "Kantonskürzel": "AG",
    "E": "2666399.4919999987",
    "N": "1267596.5700000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Böbikon",
    "PLZ": "5334",
    "Zusatzziffer": "0",
    "Gemeindename": "Böbikon",
    "BFS-Nr": "4302",
    "Kantonskürzel": "AG",
    "E": "2667325.0670000017",
    "N": "1267351.7129999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kleindöttingen",
    "PLZ": "5314",
    "Zusatzziffer": "0",
    "Gemeindename": "Böttstein",
    "BFS-Nr": "4303",
    "Kantonskürzel": "AG",
    "E": "2660789.223000001",
    "N": "1269029.6240000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Böttstein",
    "PLZ": "5315",
    "Zusatzziffer": "0",
    "Gemeindename": "Böttstein",
    "BFS-Nr": "4303",
    "Kantonskürzel": "AG",
    "E": "2659060.0819999985",
    "N": "1267497.4670000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Döttingen",
    "PLZ": "5312",
    "Zusatzziffer": "0",
    "Gemeindename": "Döttingen",
    "BFS-Nr": "4304",
    "Kantonskürzel": "AG",
    "E": "2661707.851",
    "N": "1269139.2149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Endingen",
    "PLZ": "5304",
    "Zusatzziffer": "0",
    "Gemeindename": "Endingen",
    "BFS-Nr": "4305",
    "Kantonskürzel": "AG",
    "E": "2664093.1110000014",
    "N": "1265455.6000000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterendingen",
    "PLZ": "5305",
    "Zusatzziffer": "0",
    "Gemeindename": "Endingen",
    "BFS-Nr": "4305",
    "Kantonskürzel": "AG",
    "E": "2664193.4519999996",
    "N": "1266662.8559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fisibach",
    "PLZ": "5467",
    "Zusatzziffer": "0",
    "Gemeindename": "Fisibach",
    "BFS-Nr": "4306",
    "Kantonskürzel": "AG",
    "E": "2672945.9329999983",
    "N": "1268385.5579999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Full-Reuenthal",
    "PLZ": "5324",
    "Zusatzziffer": "0",
    "Gemeindename": "Full-Reuenthal",
    "BFS-Nr": "4307",
    "Kantonskürzel": "AG",
    "E": "2657457.8189999983",
    "N": "1273399.3150000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaiserstuhl AG",
    "PLZ": "5466",
    "Zusatzziffer": "0",
    "Gemeindename": "Kaiserstuhl",
    "BFS-Nr": "4308",
    "Kantonskürzel": "AG",
    "E": "2673861.666000001",
    "N": "1268967.745000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Klingnau",
    "PLZ": "5313",
    "Zusatzziffer": "0",
    "Gemeindename": "Klingnau",
    "BFS-Nr": "4309",
    "Kantonskürzel": "AG",
    "E": "2661027.2919999994",
    "N": "1270334.5859999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Koblenz",
    "PLZ": "5322",
    "Zusatzziffer": "0",
    "Gemeindename": "Koblenz",
    "BFS-Nr": "4310",
    "Kantonskürzel": "AG",
    "E": "2660129.6730000004",
    "N": "1273325.4569999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leibstadt",
    "PLZ": "5325",
    "Zusatzziffer": "0",
    "Gemeindename": "Leibstadt",
    "BFS-Nr": "4311",
    "Kantonskürzel": "AG",
    "E": "2655785.284000002",
    "N": "1271485.9290000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lengnau AG",
    "PLZ": "5426",
    "Zusatzziffer": "0",
    "Gemeindename": "Lengnau (AG)",
    "BFS-Nr": "4312",
    "Kantonskürzel": "AG",
    "E": "2667064.0659999996",
    "N": "1263630.7930000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leuggern",
    "PLZ": "5316",
    "Zusatzziffer": "0",
    "Gemeindename": "Leuggern",
    "BFS-Nr": "4313",
    "Kantonskürzel": "AG",
    "E": "2658548.096000001",
    "N": "1270214.004999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hettenschwil",
    "PLZ": "5317",
    "Zusatzziffer": "0",
    "Gemeindename": "Leuggern",
    "BFS-Nr": "4313",
    "Kantonskürzel": "AG",
    "E": "2657025.283",
    "N": "1269724.9180000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mellikon",
    "PLZ": "5465",
    "Zusatzziffer": "0",
    "Gemeindename": "Mellikon",
    "BFS-Nr": "4314",
    "Kantonskürzel": "AG",
    "E": "2668401.8779999986",
    "N": "1268882.9499999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rekingen AG",
    "PLZ": "5332",
    "Zusatzziffer": "0",
    "Gemeindename": "Rekingen (AG)",
    "BFS-Nr": "4315",
    "Kantonskürzel": "AG",
    "E": "2666885.9739999995",
    "N": "1269181.8159999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rietheim",
    "PLZ": "5323",
    "Zusatzziffer": "0",
    "Gemeindename": "Rietheim",
    "BFS-Nr": "4316",
    "Kantonskürzel": "AG",
    "E": "2663323.4739999995",
    "N": "1272712.6539999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rümikon AG",
    "PLZ": "5464",
    "Zusatzziffer": "0",
    "Gemeindename": "Rümikon",
    "BFS-Nr": "4317",
    "Kantonskürzel": "AG",
    "E": "2670651.7540000007",
    "N": "1268535.085000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schneisingen",
    "PLZ": "5425",
    "Zusatzziffer": "0",
    "Gemeindename": "Schneisingen",
    "BFS-Nr": "4318",
    "Kantonskürzel": "AG",
    "E": "2669894.8680000007",
    "N": "1263700.4329999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Siglistorf",
    "PLZ": "5462",
    "Zusatzziffer": "0",
    "Gemeindename": "Siglistorf",
    "BFS-Nr": "4319",
    "Kantonskürzel": "AG",
    "E": "2670918.028000001",
    "N": "1266545.8170000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tegerfelden",
    "PLZ": "5306",
    "Zusatzziffer": "0",
    "Gemeindename": "Tegerfelden",
    "BFS-Nr": "4320",
    "Kantonskürzel": "AG",
    "E": "2664054.5370000005",
    "N": "1267714.6059999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wislikofen",
    "PLZ": "5463",
    "Zusatzziffer": "0",
    "Gemeindename": "Wislikofen",
    "BFS-Nr": "4322",
    "Kantonskürzel": "AG",
    "E": "2669555.1350000016",
    "N": "1267825.9750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bad Zurzach",
    "PLZ": "5330",
    "Zusatzziffer": "0",
    "Gemeindename": "Bad Zurzach",
    "BFS-Nr": "4323",
    "Kantonskürzel": "AG",
    "E": "2664447.892999999",
    "N": "1270505.2789999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arbon",
    "PLZ": "9320",
    "Zusatzziffer": "0",
    "Gemeindename": "Arbon",
    "BFS-Nr": "4401",
    "Kantonskürzel": "TG",
    "E": "2750505.5749999993",
    "N": "1264637.449000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frasnacht",
    "PLZ": "9320",
    "Zusatzziffer": "2",
    "Gemeindename": "Arbon",
    "BFS-Nr": "4401",
    "Kantonskürzel": "TG",
    "E": "2748130.7080000006",
    "N": "1265922.324000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stachen",
    "PLZ": "9320",
    "Zusatzziffer": "3",
    "Gemeindename": "Arbon",
    "BFS-Nr": "4401",
    "Kantonskürzel": "TG",
    "E": "2748498.1609999985",
    "N": "1263633.7019999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dozwil",
    "PLZ": "8582",
    "Zusatzziffer": "0",
    "Gemeindename": "Dozwil",
    "BFS-Nr": "4406",
    "Kantonskürzel": "TG",
    "E": "2741541.0080000013",
    "N": "1271179.3150000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinebrunn",
    "PLZ": "9314",
    "Zusatzziffer": "0",
    "Gemeindename": "Egnach",
    "BFS-Nr": "4411",
    "Kantonskürzel": "TG",
    "E": "2743410.9679999985",
    "N": "1266582.989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neukirch (Egnach)",
    "PLZ": "9315",
    "Zusatzziffer": "0",
    "Gemeindename": "Egnach",
    "BFS-Nr": "4411",
    "Kantonskürzel": "TG",
    "E": "2745393.561999999",
    "N": "1266007.1339999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Winden",
    "PLZ": "9315",
    "Zusatzziffer": "2",
    "Gemeindename": "Egnach",
    "BFS-Nr": "4411",
    "Kantonskürzel": "TG",
    "E": "2744492.416000001",
    "N": "1263464.0439999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Egnach",
    "PLZ": "9322",
    "Zusatzziffer": "0",
    "Gemeindename": "Egnach",
    "BFS-Nr": "4411",
    "Kantonskürzel": "TG",
    "E": "2746355.5069999993",
    "N": "1267764.5350000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hefenhofen",
    "PLZ": "8580",
    "Zusatzziffer": "7",
    "Gemeindename": "Hefenhofen",
    "BFS-Nr": "4416",
    "Kantonskürzel": "TG",
    "E": "2739986.9930000007",
    "N": "1269889.9510000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Horn",
    "PLZ": "9326",
    "Zusatzziffer": "0",
    "Gemeindename": "Horn",
    "BFS-Nr": "4421",
    "Kantonskürzel": "TG",
    "E": "2752540.9169999994",
    "N": "1262310.1039999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kesswil",
    "PLZ": "8593",
    "Zusatzziffer": "0",
    "Gemeindename": "Kesswil",
    "BFS-Nr": "4426",
    "Kantonskürzel": "TG",
    "E": "2741255.329",
    "N": "1273102.0890000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Freidorf TG",
    "PLZ": "9306",
    "Zusatzziffer": "0",
    "Gemeindename": "Roggwil (TG)",
    "BFS-Nr": "4431",
    "Kantonskürzel": "TG",
    "E": "2747604.232000001",
    "N": "1261345.8740000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Roggwil TG",
    "PLZ": "9325",
    "Zusatzziffer": "0",
    "Gemeindename": "Roggwil (TG)",
    "BFS-Nr": "4431",
    "Kantonskürzel": "TG",
    "E": "2747492.1900000013",
    "N": "1262886.493999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Romanshorn",
    "PLZ": "8590",
    "Zusatzziffer": "0",
    "Gemeindename": "Romanshorn",
    "BFS-Nr": "4436",
    "Kantonskürzel": "TG",
    "E": "2745288.390999999",
    "N": "1270027.335000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Salmsach",
    "PLZ": "8599",
    "Zusatzziffer": "0",
    "Gemeindename": "Salmsach",
    "BFS-Nr": "4441",
    "Kantonskürzel": "TG",
    "E": "2745719.8900000006",
    "N": "1269016.0139999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sommeri",
    "PLZ": "8580",
    "Zusatzziffer": "3",
    "Gemeindename": "Sommeri",
    "BFS-Nr": "4446",
    "Kantonskürzel": "TG",
    "E": "2739318.004999999",
    "N": "1270141.7250000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uttwil",
    "PLZ": "8592",
    "Zusatzziffer": "0",
    "Gemeindename": "Uttwil",
    "BFS-Nr": "4451",
    "Kantonskürzel": "TG",
    "E": "2743101.3880000003",
    "N": "1272055.4189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Amriswil",
    "PLZ": "8580",
    "Zusatzziffer": "0",
    "Gemeindename": "Amriswil",
    "BFS-Nr": "4461",
    "Kantonskürzel": "TG",
    "E": "2739929.6209999993",
    "N": "1267685.3299999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hagenwil b. Amriswil",
    "PLZ": "8580",
    "Zusatzziffer": "5",
    "Gemeindename": "Amriswil",
    "BFS-Nr": "4461",
    "Kantonskürzel": "TG",
    "E": "2740698.530000001",
    "N": "1265931.0089999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biessenhofen",
    "PLZ": "8580",
    "Zusatzziffer": "8",
    "Gemeindename": "Amriswil",
    "BFS-Nr": "4461",
    "Kantonskürzel": "TG",
    "E": "2737134.1849999987",
    "N": "1267791.2149999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schocherswil",
    "PLZ": "8581",
    "Zusatzziffer": "0",
    "Gemeindename": "Amriswil",
    "BFS-Nr": "4461",
    "Kantonskürzel": "TG",
    "E": "2737604.5100000016",
    "N": "1266738.232999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberaach",
    "PLZ": "8587",
    "Zusatzziffer": "0",
    "Gemeindename": "Amriswil",
    "BFS-Nr": "4461",
    "Kantonskürzel": "TG",
    "E": "2737594.0359999985",
    "N": "1269185.1739999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bischofszell",
    "PLZ": "9220",
    "Zusatzziffer": "0",
    "Gemeindename": "Bischofszell",
    "BFS-Nr": "4471",
    "Kantonskürzel": "TG",
    "E": "2735652.8680000007",
    "N": "1261917.5450000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schweizersholz",
    "PLZ": "9223",
    "Zusatzziffer": "0",
    "Gemeindename": "Bischofszell",
    "BFS-Nr": "4471",
    "Kantonskürzel": "TG",
    "E": "2732766.8139999993",
    "N": "1262703.2650000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Halden",
    "PLZ": "9223",
    "Zusatzziffer": "1",
    "Gemeindename": "Bischofszell",
    "BFS-Nr": "4471",
    "Kantonskürzel": "TG",
    "E": "2733400.2820000015",
    "N": "1263006.1629999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erlen",
    "PLZ": "8586",
    "Zusatzziffer": "0",
    "Gemeindename": "Erlen",
    "BFS-Nr": "4476",
    "Kantonskürzel": "TG",
    "E": "2735178.2459999993",
    "N": "1267915.8660000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kümmertshausen",
    "PLZ": "8586",
    "Zusatzziffer": "3",
    "Gemeindename": "Erlen",
    "BFS-Nr": "4476",
    "Kantonskürzel": "TG",
    "E": "2735884.697999999",
    "N": "1269856.947999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riedt b. Erlen",
    "PLZ": "8586",
    "Zusatzziffer": "5",
    "Gemeindename": "Erlen",
    "BFS-Nr": "4476",
    "Kantonskürzel": "TG",
    "E": "2733806.5249999985",
    "N": "1267612.3759999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buchackern",
    "PLZ": "8586",
    "Zusatzziffer": "7",
    "Gemeindename": "Erlen",
    "BFS-Nr": "4476",
    "Kantonskürzel": "TG",
    "E": "2735057.629999999",
    "N": "1266862.489",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Engishofen",
    "PLZ": "8586",
    "Zusatzziffer": "8",
    "Gemeindename": "Erlen",
    "BFS-Nr": "4476",
    "Kantonskürzel": "TG",
    "E": "2736288.75",
    "N": "1268875.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ennetaach",
    "PLZ": "8586",
    "Zusatzziffer": "9",
    "Gemeindename": "Erlen",
    "BFS-Nr": "4476",
    "Kantonskürzel": "TG",
    "E": "2733781.9849999994",
    "N": "1268008.8139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hauptwil",
    "PLZ": "9213",
    "Zusatzziffer": "0",
    "Gemeindename": "Hauptwil-Gottshaus",
    "BFS-Nr": "4486",
    "Kantonskürzel": "TG",
    "E": "2736755.4979999997",
    "N": "1260454.1290000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilen (Gottshaus)",
    "PLZ": "9225",
    "Zusatzziffer": "0",
    "Gemeindename": "Hauptwil-Gottshaus",
    "BFS-Nr": "4486",
    "Kantonskürzel": "TG",
    "E": "2738974.609000001",
    "N": "1261974.0929999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Pelagiberg",
    "PLZ": "9225",
    "Zusatzziffer": "1",
    "Gemeindename": "Hauptwil-Gottshaus",
    "BFS-Nr": "4486",
    "Kantonskürzel": "TG",
    "E": "2740454.3330000006",
    "N": "1261489.5940000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Heldswil",
    "PLZ": "9216",
    "Zusatzziffer": "0",
    "Gemeindename": "Hohentannen",
    "BFS-Nr": "4495",
    "Kantonskürzel": "TG",
    "E": "2734218.4010000005",
    "N": "1265892.857999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hohentannen",
    "PLZ": "9216",
    "Zusatzziffer": "2",
    "Gemeindename": "Hohentannen",
    "BFS-Nr": "4495",
    "Kantonskürzel": "TG",
    "E": "2734461.8249999993",
    "N": "1263658.6000000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kradolf",
    "PLZ": "9214",
    "Zusatzziffer": "2",
    "Gemeindename": "Kradolf-Schönenberg",
    "BFS-Nr": "4501",
    "Kantonskürzel": "TG",
    "E": "2732741.601",
    "N": "1265313.686999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönenberg an der Thur",
    "PLZ": "9215",
    "Zusatzziffer": "0",
    "Gemeindename": "Kradolf-Schönenberg",
    "BFS-Nr": "4501",
    "Kantonskürzel": "TG",
    "E": "2732559.824000001",
    "N": "1264885.1369999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buhwil",
    "PLZ": "9215",
    "Zusatzziffer": "2",
    "Gemeindename": "Kradolf-Schönenberg",
    "BFS-Nr": "4501",
    "Kantonskürzel": "TG",
    "E": "2730094.7760000005",
    "N": "1265475.5659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neukirch an der Thur",
    "PLZ": "9217",
    "Zusatzziffer": "0",
    "Gemeindename": "Kradolf-Schönenberg",
    "BFS-Nr": "4501",
    "Kantonskürzel": "TG",
    "E": "2730486.833999999",
    "N": "1263624.511",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sulgen",
    "PLZ": "8583",
    "Zusatzziffer": "0",
    "Gemeindename": "Sulgen",
    "BFS-Nr": "4506",
    "Kantonskürzel": "TG",
    "E": "2731379.7580000013",
    "N": "1267274.9189999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Götighofen",
    "PLZ": "8583",
    "Zusatzziffer": "1",
    "Gemeindename": "Sulgen",
    "BFS-Nr": "4506",
    "Kantonskürzel": "TG",
    "E": "2733817.3170000017",
    "N": "1266446.4750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Donzhausen",
    "PLZ": "8583",
    "Zusatzziffer": "2",
    "Gemeindename": "Sulgen",
    "BFS-Nr": "4506",
    "Kantonskürzel": "TG",
    "E": "2732281.2250000015",
    "N": "1268533.6369999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zihlschlacht",
    "PLZ": "8588",
    "Zusatzziffer": "0",
    "Gemeindename": "Zihlschlacht-Sitterdorf",
    "BFS-Nr": "4511",
    "Kantonskürzel": "TG",
    "E": "2736998.079999998",
    "N": "1264979.4730000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sitterdorf",
    "PLZ": "8589",
    "Zusatzziffer": "0",
    "Gemeindename": "Zihlschlacht-Sitterdorf",
    "BFS-Nr": "4511",
    "Kantonskürzel": "TG",
    "E": "2736339.715999998",
    "N": "1263133.438000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Basadingen",
    "PLZ": "8254",
    "Zusatzziffer": "0",
    "Gemeindename": "Basadingen-Schlattingen",
    "BFS-Nr": "4536",
    "Kantonskürzel": "TG",
    "E": "2697989.833999999",
    "N": "1280923.6799999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schlattingen",
    "PLZ": "8255",
    "Zusatzziffer": "0",
    "Gemeindename": "Basadingen-Schlattingen",
    "BFS-Nr": "4536",
    "Kantonskürzel": "TG",
    "E": "2700014.3139999993",
    "N": "1280428.9640000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Diessenhofen",
    "PLZ": "8253",
    "Zusatzziffer": "0",
    "Gemeindename": "Diessenhofen",
    "BFS-Nr": "4545",
    "Kantonskürzel": "TG",
    "E": "2698354.677000001",
    "N": "1282975.993999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Willisdorf",
    "PLZ": "8253",
    "Zusatzziffer": "2",
    "Gemeindename": "Diessenhofen",
    "BFS-Nr": "4545",
    "Kantonskürzel": "TG",
    "E": "2697583.7710000016",
    "N": "1281693.9690000005",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schlatt TG",
    "PLZ": "8252",
    "Zusatzziffer": "0",
    "Gemeindename": "Schlatt (TG)",
    "BFS-Nr": "4546",
    "Kantonskürzel": "TG",
    "E": "2694961.408",
    "N": "1279756.8429999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aadorf",
    "PLZ": "8355",
    "Zusatzziffer": "0",
    "Gemeindename": "Aadorf",
    "BFS-Nr": "4551",
    "Kantonskürzel": "TG",
    "E": "2709949.403999999",
    "N": "1261088.4580000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ettenhausen TG",
    "PLZ": "8356",
    "Zusatzziffer": "0",
    "Gemeindename": "Aadorf",
    "BFS-Nr": "4551",
    "Kantonskürzel": "TG",
    "E": "2710021.800999999",
    "N": "1259647.0280000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guntershausen b. Aadorf",
    "PLZ": "8357",
    "Zusatzziffer": "0",
    "Gemeindename": "Aadorf",
    "BFS-Nr": "4551",
    "Kantonskürzel": "TG",
    "E": "2711429.368999999",
    "N": "1259214.7490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Häuslenen",
    "PLZ": "8522",
    "Zusatzziffer": "0",
    "Gemeindename": "Aadorf",
    "BFS-Nr": "4551",
    "Kantonskürzel": "TG",
    "E": "2710301.6389999986",
    "N": "1265047.0610000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Aawangen",
    "PLZ": "8522",
    "Zusatzziffer": "2",
    "Gemeindename": "Aadorf",
    "BFS-Nr": "4551",
    "Kantonskürzel": "TG",
    "E": "2710074.8869999982",
    "N": "1263407.199000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wittenwil",
    "PLZ": "9547",
    "Zusatzziffer": "0",
    "Gemeindename": "Aadorf",
    "BFS-Nr": "4551",
    "Kantonskürzel": "TG",
    "E": "2712002.4789999984",
    "N": "1262572.063000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Felben-Wellhausen",
    "PLZ": "8552",
    "Zusatzziffer": "0",
    "Gemeindename": "Felben-Wellhausen",
    "BFS-Nr": "4561",
    "Kantonskürzel": "TG",
    "E": "2713154.0639999993",
    "N": "1270662.7809999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Frauenfeld",
    "PLZ": "8500",
    "Zusatzziffer": "0",
    "Gemeindename": "Frauenfeld",
    "BFS-Nr": "4566",
    "Kantonskürzel": "TG",
    "E": "2709858.1739999987",
    "N": "1268274.6479999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gerlikon",
    "PLZ": "8500",
    "Zusatzziffer": "4",
    "Gemeindename": "Frauenfeld",
    "BFS-Nr": "4566",
    "Kantonskürzel": "TG",
    "E": "2708404.5650000013",
    "N": "1266138.4090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Islikon",
    "PLZ": "8546",
    "Zusatzziffer": "0",
    "Gemeindename": "Gachnang",
    "BFS-Nr": "4571",
    "Kantonskürzel": "TG",
    "E": "2705916.416000001",
    "N": "1267286.6559999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kefikon TG",
    "PLZ": "8546",
    "Zusatzziffer": "2",
    "Gemeindename": "Gachnang",
    "BFS-Nr": "4571",
    "Kantonskürzel": "TG",
    "E": "2705012.1680000015",
    "N": "1267553.9719999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gachnang",
    "PLZ": "8547",
    "Zusatzziffer": "0",
    "Gemeindename": "Gachnang",
    "BFS-Nr": "4571",
    "Kantonskürzel": "TG",
    "E": "2706345.8550000004",
    "N": "1266192.572999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Harenwilen",
    "PLZ": "8553",
    "Zusatzziffer": "2",
    "Gemeindename": "Hüttlingen",
    "BFS-Nr": "4590",
    "Kantonskürzel": "TG",
    "E": "2717514.6039999984",
    "N": "1270102.7690000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mettendorf TG",
    "PLZ": "8553",
    "Zusatzziffer": "3",
    "Gemeindename": "Hüttlingen",
    "BFS-Nr": "4590",
    "Kantonskürzel": "TG",
    "E": "2715197.8630000018",
    "N": "1270756.7749999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hüttlingen",
    "PLZ": "8553",
    "Zusatzziffer": "4",
    "Gemeindename": "Hüttlingen",
    "BFS-Nr": "4590",
    "Kantonskürzel": "TG",
    "E": "2716057.5549999997",
    "N": "1270694.039999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eschikofen",
    "PLZ": "8553",
    "Zusatzziffer": "5",
    "Gemeindename": "Hüttlingen",
    "BFS-Nr": "4590",
    "Kantonskürzel": "TG",
    "E": "2717821.5",
    "N": "1271074.625",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Matzingen",
    "PLZ": "9548",
    "Zusatzziffer": "0",
    "Gemeindename": "Matzingen",
    "BFS-Nr": "4591",
    "Kantonskürzel": "TG",
    "E": "2712515.329",
    "N": "1264333.9569999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederneunforn",
    "PLZ": "8525",
    "Zusatzziffer": "0",
    "Gemeindename": "Neunforn",
    "BFS-Nr": "4601",
    "Kantonskürzel": "TG",
    "E": "2700737.454",
    "N": "1272474.4050000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberneunforn",
    "PLZ": "8526",
    "Zusatzziffer": "0",
    "Gemeindename": "Neunforn",
    "BFS-Nr": "4601",
    "Kantonskürzel": "TG",
    "E": "2699870.973000001",
    "N": "1273652.1959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stettfurt",
    "PLZ": "9507",
    "Zusatzziffer": "0",
    "Gemeindename": "Stettfurt",
    "BFS-Nr": "4606",
    "Kantonskürzel": "TG",
    "E": "2714117.0819999985",
    "N": "1264860.9299999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thundorf",
    "PLZ": "8512",
    "Zusatzziffer": "0",
    "Gemeindename": "Thundorf",
    "BFS-Nr": "4611",
    "Kantonskürzel": "TG",
    "E": "2714787.4690000005",
    "N": "1267224.0830000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lustdorf",
    "PLZ": "8512",
    "Zusatzziffer": "2",
    "Gemeindename": "Thundorf",
    "BFS-Nr": "4611",
    "Kantonskürzel": "TG",
    "E": "2716495.1570000015",
    "N": "1267782.2710000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wetzikon TG",
    "PLZ": "8512",
    "Zusatzziffer": "3",
    "Gemeindename": "Thundorf",
    "BFS-Nr": "4611",
    "Kantonskürzel": "TG",
    "E": "2717640.539999999",
    "N": "1266412.789999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uesslingen",
    "PLZ": "8524",
    "Zusatzziffer": "0",
    "Gemeindename": "Uesslingen-Buch",
    "BFS-Nr": "4616",
    "Kantonskürzel": "TG",
    "E": "2705172.1680000015",
    "N": "1270911.0309999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buch b. Frauenfeld",
    "PLZ": "8524",
    "Zusatzziffer": "2",
    "Gemeindename": "Uesslingen-Buch",
    "BFS-Nr": "4616",
    "Kantonskürzel": "TG",
    "E": "2705228.550999999",
    "N": "1273020.8099999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Warth",
    "PLZ": "8532",
    "Zusatzziffer": "0",
    "Gemeindename": "Warth-Weiningen",
    "BFS-Nr": "4621",
    "Kantonskürzel": "TG",
    "E": "2707958.4789999984",
    "N": "1271378.072999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weiningen TG",
    "PLZ": "8532",
    "Zusatzziffer": "2",
    "Gemeindename": "Warth-Weiningen",
    "BFS-Nr": "4621",
    "Kantonskürzel": "TG",
    "E": "2709263.6339999996",
    "N": "1271895.0190000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altnau",
    "PLZ": "8595",
    "Zusatzziffer": "0",
    "Gemeindename": "Altnau",
    "BFS-Nr": "4641",
    "Kantonskürzel": "TG",
    "E": "2736770.8040000014",
    "N": "1274623.7840000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bottighofen",
    "PLZ": "8598",
    "Zusatzziffer": "0",
    "Gemeindename": "Bottighofen",
    "BFS-Nr": "4643",
    "Kantonskürzel": "TG",
    "E": "2733352.208999999",
    "N": "1278190.4800000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ermatingen",
    "PLZ": "8272",
    "Zusatzziffer": "0",
    "Gemeindename": "Ermatingen",
    "BFS-Nr": "4646",
    "Kantonskürzel": "TG",
    "E": "2723302.563000001",
    "N": "1281204.5859999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Triboltingen",
    "PLZ": "8273",
    "Zusatzziffer": "0",
    "Gemeindename": "Ermatingen",
    "BFS-Nr": "4646",
    "Kantonskürzel": "TG",
    "E": "2725376.0529999994",
    "N": "1280372.136",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gottlieben",
    "PLZ": "8274",
    "Zusatzziffer": "2",
    "Gemeindename": "Gottlieben",
    "BFS-Nr": "4651",
    "Kantonskürzel": "TG",
    "E": "2727308.3960000016",
    "N": "1280615.3339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Güttingen",
    "PLZ": "8594",
    "Zusatzziffer": "0",
    "Gemeindename": "Güttingen",
    "BFS-Nr": "4656",
    "Kantonskürzel": "TG",
    "E": "2738941.1279999986",
    "N": "1274074.0229999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hugelshofen",
    "PLZ": "8565",
    "Zusatzziffer": "0",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2726073.712000001",
    "N": "1273354.3610000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Neuwilen",
    "PLZ": "8566",
    "Zusatzziffer": "0",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2727465.6669999994",
    "N": "1275745.3000000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dotnacht",
    "PLZ": "8566",
    "Zusatzziffer": "2",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2728031.9070000015",
    "N": "1273557.7800000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ellighausen",
    "PLZ": "8566",
    "Zusatzziffer": "4",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2727581.631000001",
    "N": "1275042.427000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lippoldswilen",
    "PLZ": "8566",
    "Zusatzziffer": "5",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2725768.125",
    "N": "1274696.2710000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Siegershausen",
    "PLZ": "8573",
    "Zusatzziffer": "0",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2730055.780000001",
    "N": "1274834.7010000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Alterswilen",
    "PLZ": "8573",
    "Zusatzziffer": "2",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2729046.3680000007",
    "N": "1274629.9200000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Altishausen",
    "PLZ": "8573",
    "Zusatzziffer": "3",
    "Gemeindename": "Kemmental",
    "BFS-Nr": "4666",
    "Kantonskürzel": "TG",
    "E": "2730250.6739999987",
    "N": "1273723.6220000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kreuzlingen",
    "PLZ": "8280",
    "Zusatzziffer": "0",
    "Gemeindename": "Kreuzlingen",
    "BFS-Nr": "4671",
    "Kantonskürzel": "TG",
    "E": "2730330.166000001",
    "N": "1278554.2300000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Langrickenbach",
    "PLZ": "8585",
    "Zusatzziffer": "4",
    "Gemeindename": "Langrickenbach",
    "BFS-Nr": "4681",
    "Kantonskürzel": "TG",
    "E": "2736057.25",
    "N": "1271854.875",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zuben",
    "PLZ": "8585",
    "Zusatzziffer": "5",
    "Gemeindename": "Langrickenbach",
    "BFS-Nr": "4681",
    "Kantonskürzel": "TG",
    "E": "2735189.9010000005",
    "N": "1274440.0760000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönenbaumgarten",
    "PLZ": "8585",
    "Zusatzziffer": "7",
    "Gemeindename": "Langrickenbach",
    "BFS-Nr": "4681",
    "Kantonskürzel": "TG",
    "E": "2734734.0749999993",
    "N": "1275285.802000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herrenhof",
    "PLZ": "8585",
    "Zusatzziffer": "8",
    "Gemeindename": "Langrickenbach",
    "BFS-Nr": "4681",
    "Kantonskürzel": "TG",
    "E": "2735727.625",
    "N": "1273470.75",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Illighausen",
    "PLZ": "8574",
    "Zusatzziffer": "1",
    "Gemeindename": "Lengwil",
    "BFS-Nr": "4683",
    "Kantonskürzel": "TG",
    "E": "2732961.778000001",
    "N": "1274057.9780000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberhofen TG",
    "PLZ": "8574",
    "Zusatzziffer": "2",
    "Gemeindename": "Lengwil",
    "BFS-Nr": "4683",
    "Kantonskürzel": "TG",
    "E": "2732026.3310000002",
    "N": "1275699.7939999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lengwil",
    "PLZ": "8574",
    "Zusatzziffer": "3",
    "Gemeindename": "Lengwil",
    "BFS-Nr": "4683",
    "Kantonskürzel": "TG",
    "E": "2732939.8200000003",
    "N": "1275365.631000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lengwil",
    "PLZ": "8574",
    "Zusatzziffer": "3",
    "Gemeindename": "Lengwil",
    "BFS-Nr": "4683",
    "Kantonskürzel": "TG",
    "E": "2731641.208999999",
    "N": "1276491.0190000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dettighofen (Lengwil)",
    "PLZ": "8574",
    "Zusatzziffer": "4",
    "Gemeindename": "Lengwil",
    "BFS-Nr": "4683",
    "Kantonskürzel": "TG",
    "E": "2732446.3729999997",
    "N": "1275931.8399999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Scherzingen",
    "PLZ": "8596",
    "Zusatzziffer": "0",
    "Gemeindename": "Münsterlingen",
    "BFS-Nr": "4691",
    "Kantonskürzel": "TG",
    "E": "2734116.6790000014",
    "N": "1277227.818",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münsterlingen",
    "PLZ": "8596",
    "Zusatzziffer": "1",
    "Gemeindename": "Münsterlingen",
    "BFS-Nr": "4691",
    "Kantonskürzel": "TG",
    "E": "2735006.3429999985",
    "N": "1277186.879999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Landschlacht",
    "PLZ": "8597",
    "Zusatzziffer": "0",
    "Gemeindename": "Münsterlingen",
    "BFS-Nr": "4691",
    "Kantonskürzel": "TG",
    "E": "2735744.098000001",
    "N": "1276555.3189999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tägerwilen",
    "PLZ": "8274",
    "Zusatzziffer": "0",
    "Gemeindename": "Tägerwilen",
    "BFS-Nr": "4696",
    "Kantonskürzel": "TG",
    "E": "2727560.177000001",
    "N": "1279627.3559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hefenhausen",
    "PLZ": "8564",
    "Zusatzziffer": "0",
    "Gemeindename": "Wäldi",
    "BFS-Nr": "4701",
    "Kantonskürzel": "TG",
    "E": "2722210.574000001",
    "N": "1275186.2919999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Engwilen",
    "PLZ": "8564",
    "Zusatzziffer": "2",
    "Gemeindename": "Wäldi",
    "BFS-Nr": "4701",
    "Kantonskürzel": "TG",
    "E": "2724566.033",
    "N": "1275366.3999999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sonterswil",
    "PLZ": "8564",
    "Zusatzziffer": "3",
    "Gemeindename": "Wäldi",
    "BFS-Nr": "4701",
    "Kantonskürzel": "TG",
    "E": "2723242.7129999995",
    "N": "1275754.8379999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wäldi",
    "PLZ": "8564",
    "Zusatzziffer": "4",
    "Gemeindename": "Wäldi",
    "BFS-Nr": "4701",
    "Kantonskürzel": "TG",
    "E": "2724420.0249999985",
    "N": "1277249.6999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lipperswil",
    "PLZ": "8564",
    "Zusatzziffer": "5",
    "Gemeindename": "Wäldi",
    "BFS-Nr": "4701",
    "Kantonskürzel": "TG",
    "E": "2721371.3660000004",
    "N": "1275291.5329999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hattenhausen",
    "PLZ": "8564",
    "Zusatzziffer": "7",
    "Gemeindename": "Wäldi",
    "BFS-Nr": "4701",
    "Kantonskürzel": "TG",
    "E": "2722376.6970000006",
    "N": "1276049.6970000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gunterswilen",
    "PLZ": "8564",
    "Zusatzziffer": "8",
    "Gemeindename": "Wäldi",
    "BFS-Nr": "4701",
    "Kantonskürzel": "TG",
    "E": "2723087.6609999985",
    "N": "1277388.5659999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Affeltrangen",
    "PLZ": "9556",
    "Zusatzziffer": "0",
    "Gemeindename": "Affeltrangen",
    "BFS-Nr": "4711",
    "Kantonskürzel": "TG",
    "E": "2719841.765999999",
    "N": "1265131.9169999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zezikon",
    "PLZ": "9556",
    "Zusatzziffer": "2",
    "Gemeindename": "Affeltrangen",
    "BFS-Nr": "4711",
    "Kantonskürzel": "TG",
    "E": "2719142.6220000014",
    "N": "1266101.9670000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Märwil",
    "PLZ": "9562",
    "Zusatzziffer": "0",
    "Gemeindename": "Affeltrangen",
    "BFS-Nr": "4711",
    "Kantonskürzel": "TG",
    "E": "2723175.1059999987",
    "N": "1265918.449000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buch b. Märwil",
    "PLZ": "9562",
    "Zusatzziffer": "2",
    "Gemeindename": "Affeltrangen",
    "BFS-Nr": "4711",
    "Kantonskürzel": "TG",
    "E": "2721905.625",
    "N": "1266471.75",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bettwiesen",
    "PLZ": "9553",
    "Zusatzziffer": "0",
    "Gemeindename": "Bettwiesen",
    "BFS-Nr": "4716",
    "Kantonskürzel": "TG",
    "E": "2719665.511",
    "N": "1261570.6490000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Balterswil",
    "PLZ": "8362",
    "Zusatzziffer": "0",
    "Gemeindename": "Bichelsee-Balterswil",
    "BFS-Nr": "4721",
    "Kantonskürzel": "TG",
    "E": "2713038.5549999997",
    "N": "1256898.4869999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bichelsee",
    "PLZ": "8363",
    "Zusatzziffer": "0",
    "Gemeindename": "Bichelsee-Balterswil",
    "BFS-Nr": "4721",
    "Kantonskürzel": "TG",
    "E": "2711814.063000001",
    "N": "1256638.7199999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Braunau",
    "PLZ": "9502",
    "Zusatzziffer": "0",
    "Gemeindename": "Braunau",
    "BFS-Nr": "4723",
    "Kantonskürzel": "TG",
    "E": "2722995.0210000016",
    "N": "1262716.5830000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eschlikon TG",
    "PLZ": "8360",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschlikon",
    "BFS-Nr": "4724",
    "Kantonskürzel": "TG",
    "E": "2715018.0830000006",
    "N": "1258316.7930000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wallenwil",
    "PLZ": "8360",
    "Zusatzziffer": "2",
    "Gemeindename": "Eschlikon",
    "BFS-Nr": "4724",
    "Kantonskürzel": "TG",
    "E": "2714380.136",
    "N": "1257092.5069999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dussnang",
    "PLZ": "8374",
    "Zusatzziffer": "0",
    "Gemeindename": "Fischingen",
    "BFS-Nr": "4726",
    "Kantonskürzel": "TG",
    "E": "2714954.9340000004",
    "N": "1254389.3770000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwangen TG",
    "PLZ": "8374",
    "Zusatzziffer": "2",
    "Gemeindename": "Fischingen",
    "BFS-Nr": "4726",
    "Kantonskürzel": "TG",
    "E": "2715776.8999999985",
    "N": "1254358.7380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fischingen",
    "PLZ": "8376",
    "Zusatzziffer": "0",
    "Gemeindename": "Fischingen",
    "BFS-Nr": "4726",
    "Kantonskürzel": "TG",
    "E": "2715436.039000001",
    "N": "1252413.579",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Au TG",
    "PLZ": "8376",
    "Zusatzziffer": "2",
    "Gemeindename": "Fischingen",
    "BFS-Nr": "4726",
    "Kantonskürzel": "TG",
    "E": "2714362.4750000015",
    "N": "1250761.2360000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lommis",
    "PLZ": "9506",
    "Zusatzziffer": "0",
    "Gemeindename": "Lommis",
    "BFS-Nr": "4741",
    "Kantonskürzel": "TG",
    "E": "2717446.4360000007",
    "N": "1264081.4809999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weingarten-Kalthäusern",
    "PLZ": "9508",
    "Zusatzziffer": "0",
    "Gemeindename": "Lommis",
    "BFS-Nr": "4741",
    "Kantonskürzel": "TG",
    "E": "2716003.3830000013",
    "N": "1264740.811999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münchwilen TG",
    "PLZ": "9542",
    "Zusatzziffer": "0",
    "Gemeindename": "Münchwilen (TG)",
    "BFS-Nr": "4746",
    "Kantonskürzel": "TG",
    "E": "2717610.583999999",
    "N": "1259941.75",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Margarethen TG",
    "PLZ": "9543",
    "Zusatzziffer": "0",
    "Gemeindename": "Münchwilen (TG)",
    "BFS-Nr": "4746",
    "Kantonskürzel": "TG",
    "E": "2717754.7569999993",
    "N": "1260699.6180000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rickenbach b. Wil",
    "PLZ": "9532",
    "Zusatzziffer": "0",
    "Gemeindename": "Rickenbach (TG)",
    "BFS-Nr": "4751",
    "Kantonskürzel": "TG",
    "E": "2721683.583999999",
    "N": "1256577.2049999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schönholzerswilen",
    "PLZ": "8577",
    "Zusatzziffer": "0",
    "Gemeindename": "Schönholzerswilen",
    "BFS-Nr": "4756",
    "Kantonskürzel": "TG",
    "E": "2728213.0020000003",
    "N": "1264307.1290000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Sirnach",
    "PLZ": "8370",
    "Zusatzziffer": "0",
    "Gemeindename": "Sirnach",
    "BFS-Nr": "4761",
    "Kantonskürzel": "TG",
    "E": "2717534.3610000014",
    "N": "1257972.829",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Busswil TG",
    "PLZ": "8371",
    "Zusatzziffer": "0",
    "Gemeindename": "Sirnach",
    "BFS-Nr": "4761",
    "Kantonskürzel": "TG",
    "E": "2718929.631000001",
    "N": "1257039.0839999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiezikon b. Sirnach",
    "PLZ": "8372",
    "Zusatzziffer": "0",
    "Gemeindename": "Sirnach",
    "BFS-Nr": "4761",
    "Kantonskürzel": "TG",
    "E": "2716733.579",
    "N": "1256660.6119999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Littenheid",
    "PLZ": "9573",
    "Zusatzziffer": "0",
    "Gemeindename": "Sirnach",
    "BFS-Nr": "4761",
    "Kantonskürzel": "TG",
    "E": "2718428.5100000016",
    "N": "1255621.9059999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tägerschen",
    "PLZ": "9554",
    "Zusatzziffer": "0",
    "Gemeindename": "Tobel-Tägerschen",
    "BFS-Nr": "4776",
    "Kantonskürzel": "TG",
    "E": "2719845.096000001",
    "N": "1263014.1600000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tobel",
    "PLZ": "9555",
    "Zusatzziffer": "0",
    "Gemeindename": "Tobel-Tägerschen",
    "BFS-Nr": "4776",
    "Kantonskürzel": "TG",
    "E": "2720355.1499999985",
    "N": "1263898.557",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wängi",
    "PLZ": "9545",
    "Zusatzziffer": "0",
    "Gemeindename": "Wängi",
    "BFS-Nr": "4781",
    "Kantonskürzel": "TG",
    "E": "2714203.1750000007",
    "N": "1262220.3420000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Tuttwil",
    "PLZ": "9546",
    "Zusatzziffer": "0",
    "Gemeindename": "Wängi",
    "BFS-Nr": "4781",
    "Kantonskürzel": "TG",
    "E": "2713033.061999999",
    "N": "1260246.8260000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wilen b. Wil",
    "PLZ": "9535",
    "Zusatzziffer": "0",
    "Gemeindename": "Wilen (TG)",
    "BFS-Nr": "4786",
    "Kantonskürzel": "TG",
    "E": "2720336.6950000003",
    "N": "1256866.6110000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wuppenau",
    "PLZ": "9514",
    "Zusatzziffer": "0",
    "Gemeindename": "Wuppenau",
    "BFS-Nr": "4791",
    "Kantonskürzel": "TG",
    "E": "2725443.5929999985",
    "N": "1261835.5540000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hosenruck",
    "PLZ": "9515",
    "Zusatzziffer": "0",
    "Gemeindename": "Wuppenau",
    "BFS-Nr": "4791",
    "Kantonskürzel": "TG",
    "E": "2726890.8209999986",
    "N": "1261316.0199999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berlingen",
    "PLZ": "8267",
    "Zusatzziffer": "0",
    "Gemeindename": "Berlingen",
    "BFS-Nr": "4801",
    "Kantonskürzel": "TG",
    "E": "2718478.3940000013",
    "N": "1281616.7309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eschenz",
    "PLZ": "8264",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschenz",
    "BFS-Nr": "4806",
    "Kantonskürzel": "TG",
    "E": "2707924.289999999",
    "N": "1278309.2410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lanzenneunforn",
    "PLZ": "8506",
    "Zusatzziffer": "0",
    "Gemeindename": "Herdern",
    "BFS-Nr": "4811",
    "Kantonskürzel": "TG",
    "E": "2712699.6039999984",
    "N": "1275813.1530000009",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herdern",
    "PLZ": "8535",
    "Zusatzziffer": "0",
    "Gemeindename": "Herdern",
    "BFS-Nr": "4811",
    "Kantonskürzel": "TG",
    "E": "2710605.552000001",
    "N": "1273717.3469999991",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hörhausen",
    "PLZ": "8507",
    "Zusatzziffer": "0",
    "Gemeindename": "Homburg",
    "BFS-Nr": "4816",
    "Kantonskürzel": "TG",
    "E": "2715082.2289999984",
    "N": "1276842.484000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Homburg",
    "PLZ": "8508",
    "Zusatzziffer": "0",
    "Gemeindename": "Homburg",
    "BFS-Nr": "4816",
    "Kantonskürzel": "TG",
    "E": "2717972.954",
    "N": "1277233.2950000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hüttwilen",
    "PLZ": "8536",
    "Zusatzziffer": "0",
    "Gemeindename": "Hüttwilen",
    "BFS-Nr": "4821",
    "Kantonskürzel": "TG",
    "E": "2707866.1559999995",
    "N": "1274122.3759999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nussbaumen TG",
    "PLZ": "8537",
    "Zusatzziffer": "0",
    "Gemeindename": "Hüttwilen",
    "BFS-Nr": "4821",
    "Kantonskürzel": "TG",
    "E": "2704545.802000001",
    "N": "1276083.8940000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Uerschhausen",
    "PLZ": "8537",
    "Zusatzziffer": "2",
    "Gemeindename": "Hüttwilen",
    "BFS-Nr": "4821",
    "Kantonskürzel": "TG",
    "E": "2703693.427000001",
    "N": "1274133.7930000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mammern",
    "PLZ": "8265",
    "Zusatzziffer": "0",
    "Gemeindename": "Mammern",
    "BFS-Nr": "4826",
    "Kantonskürzel": "TG",
    "E": "2711075.2080000006",
    "N": "1278287.2080000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Müllheim Dorf",
    "PLZ": "8555",
    "Zusatzziffer": "0",
    "Gemeindename": "Müllheim",
    "BFS-Nr": "4831",
    "Kantonskürzel": "TG",
    "E": "2718195.9899999984",
    "N": "1273681.9589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Pfyn",
    "PLZ": "8505",
    "Zusatzziffer": "0",
    "Gemeindename": "Pfyn",
    "BFS-Nr": "4841",
    "Kantonskürzel": "TG",
    "E": "2714523.0590000004",
    "N": "1272631.6579999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Dettighofen",
    "PLZ": "8505",
    "Zusatzziffer": "2",
    "Gemeindename": "Pfyn",
    "BFS-Nr": "4841",
    "Kantonskürzel": "TG",
    "E": "2713563.936999999",
    "N": "1275100.677000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Raperswilen",
    "PLZ": "8558",
    "Zusatzziffer": "0",
    "Gemeindename": "Raperswilen",
    "BFS-Nr": "4846",
    "Kantonskürzel": "TG",
    "E": "2720530.5480000004",
    "N": "1276963.3550000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mannenbach-Salenstein",
    "PLZ": "8268",
    "Zusatzziffer": "0",
    "Gemeindename": "Salenstein",
    "BFS-Nr": "4851",
    "Kantonskürzel": "TG",
    "E": "2721220",
    "N": "1281582.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Salenstein",
    "PLZ": "8268",
    "Zusatzziffer": "2",
    "Gemeindename": "Salenstein",
    "BFS-Nr": "4851",
    "Kantonskürzel": "TG",
    "E": "2721669.7010000013",
    "N": "1281065.7859999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fruthwilen",
    "PLZ": "8269",
    "Zusatzziffer": "0",
    "Gemeindename": "Salenstein",
    "BFS-Nr": "4851",
    "Kantonskürzel": "TG",
    "E": "2722266.5670000017",
    "N": "1280176.9169999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steckborn",
    "PLZ": "8266",
    "Zusatzziffer": "0",
    "Gemeindename": "Steckborn",
    "BFS-Nr": "4864",
    "Kantonskürzel": "TG",
    "E": "2716022.976",
    "N": "1280709.6259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kaltenbach",
    "PLZ": "8259",
    "Zusatzziffer": "0",
    "Gemeindename": "Wagenhausen",
    "BFS-Nr": "4871",
    "Kantonskürzel": "TG",
    "E": "2705489.039000001",
    "N": "1278996.0689999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Etzwilen",
    "PLZ": "8259",
    "Zusatzziffer": "2",
    "Gemeindename": "Wagenhausen",
    "BFS-Nr": "4871",
    "Kantonskürzel": "TG",
    "E": "2703628.717",
    "N": "1279866.296",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rheinklingen",
    "PLZ": "8259",
    "Zusatzziffer": "3",
    "Gemeindename": "Wagenhausen",
    "BFS-Nr": "4871",
    "Kantonskürzel": "TG",
    "E": "2702784.8960000016",
    "N": "1281385.2679999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wagenhausen",
    "PLZ": "8259",
    "Zusatzziffer": "4",
    "Gemeindename": "Wagenhausen",
    "BFS-Nr": "4871",
    "Kantonskürzel": "TG",
    "E": "2705877.335000001",
    "N": "1279951.4279999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Amlikon-Bissegg",
    "PLZ": "8514",
    "Zusatzziffer": "0",
    "Gemeindename": "Amlikon-Bissegg",
    "BFS-Nr": "4881",
    "Kantonskürzel": "TG",
    "E": "2721147.5130000003",
    "N": "1269598.9710000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berg TG",
    "PLZ": "8572",
    "Zusatzziffer": "0",
    "Gemeindename": "Berg (TG)",
    "BFS-Nr": "4891",
    "Kantonskürzel": "TG",
    "E": "2729893.813000001",
    "N": "1271146.8370000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Berg TG",
    "PLZ": "8572",
    "Zusatzziffer": "0",
    "Gemeindename": "Berg (TG)",
    "BFS-Nr": "4891",
    "Kantonskürzel": "TG",
    "E": "2732230.1559999995",
    "N": "1273339",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Andhausen",
    "PLZ": "8572",
    "Zusatzziffer": "2",
    "Gemeindename": "Berg (TG)",
    "BFS-Nr": "4891",
    "Kantonskürzel": "TG",
    "E": "2730980.9750000015",
    "N": "1271154.3260000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Graltshausen",
    "PLZ": "8572",
    "Zusatzziffer": "3",
    "Gemeindename": "Berg (TG)",
    "BFS-Nr": "4891",
    "Kantonskürzel": "TG",
    "E": "2731140.1099999994",
    "N": "1273144.4990000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guntershausen b. Berg",
    "PLZ": "8572",
    "Zusatzziffer": "4",
    "Gemeindename": "Berg (TG)",
    "BFS-Nr": "4891",
    "Kantonskürzel": "TG",
    "E": "2731665.4959999993",
    "N": "1270523.2320000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mauren TG",
    "PLZ": "8576",
    "Zusatzziffer": "0",
    "Gemeindename": "Berg (TG)",
    "BFS-Nr": "4891",
    "Kantonskürzel": "TG",
    "E": "2728910.1559999995",
    "N": "1269832.063000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mattwil",
    "PLZ": "8585",
    "Zusatzziffer": "1",
    "Gemeindename": "Birwinken",
    "BFS-Nr": "4901",
    "Kantonskürzel": "TG",
    "E": "2732989.8310000002",
    "N": "1271161.1590000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Happerswil",
    "PLZ": "8585",
    "Zusatzziffer": "2",
    "Gemeindename": "Birwinken",
    "BFS-Nr": "4901",
    "Kantonskürzel": "TG",
    "E": "2734223.6719999984",
    "N": "1271203.7699999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birwinken",
    "PLZ": "8585",
    "Zusatzziffer": "9",
    "Gemeindename": "Birwinken",
    "BFS-Nr": "4901",
    "Kantonskürzel": "TG",
    "E": "2732297.5210000016",
    "N": "1271676.688000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Klarsreuti",
    "PLZ": "8585",
    "Zusatzziffer": "10",
    "Gemeindename": "Birwinken",
    "BFS-Nr": "4901",
    "Kantonskürzel": "TG",
    "E": "2733471.25",
    "N": "1272290.25",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Andwil TG",
    "PLZ": "8586",
    "Zusatzziffer": "2",
    "Gemeindename": "Birwinken",
    "BFS-Nr": "4901",
    "Kantonskürzel": "TG",
    "E": "2733726.1689999998",
    "N": "1269849.8339999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Buch b. Kümmertshausen",
    "PLZ": "8586",
    "Zusatzziffer": "10",
    "Gemeindename": "Birwinken",
    "BFS-Nr": "4901",
    "Kantonskürzel": "TG",
    "E": "2735163.5",
    "N": "1270476.625",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bürglen TG",
    "PLZ": "8575",
    "Zusatzziffer": "0",
    "Gemeindename": "Bürglen (TG)",
    "BFS-Nr": "4911",
    "Kantonskürzel": "TG",
    "E": "2729140.4329999983",
    "N": "1267688.574000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Istighofen",
    "PLZ": "8575",
    "Zusatzziffer": "1",
    "Gemeindename": "Bürglen (TG)",
    "BFS-Nr": "4911",
    "Kantonskürzel": "TG",
    "E": "2728732.9389999993",
    "N": "1266983.9169999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leimbach TG",
    "PLZ": "8584",
    "Zusatzziffer": "0",
    "Gemeindename": "Bürglen (TG)",
    "BFS-Nr": "4911",
    "Kantonskürzel": "TG",
    "E": "2731646.351",
    "N": "1269287.3559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Opfershofen TG",
    "PLZ": "8584",
    "Zusatzziffer": "1",
    "Gemeindename": "Bürglen (TG)",
    "BFS-Nr": "4911",
    "Kantonskürzel": "TG",
    "E": "2730433.550999999",
    "N": "1269186.0100000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stehrenberg",
    "PLZ": "9503",
    "Zusatzziffer": "0",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2724243.916000001",
    "N": "1264915.3409999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lanterswil",
    "PLZ": "9503",
    "Zusatzziffer": "2",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2724862.3280000016",
    "N": "1264655.859000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Friltschen",
    "PLZ": "9504",
    "Zusatzziffer": "0",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2723966.5720000006",
    "N": "1266622.4079999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mettlen",
    "PLZ": "9517",
    "Zusatzziffer": "0",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2726624.688000001",
    "N": "1266046.3869999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bussnang",
    "PLZ": "9565",
    "Zusatzziffer": "0",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2723676.7239999995",
    "N": "1268751.9789999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberbussnang",
    "PLZ": "9565",
    "Zusatzziffer": "2",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2724093.8330000006",
    "N": "1267696.886",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oppikon",
    "PLZ": "9565",
    "Zusatzziffer": "3",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2722117.083999999",
    "N": "1267927.879999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schmidshof",
    "PLZ": "9565",
    "Zusatzziffer": "4",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2721639.1039999984",
    "N": "1267372.8390000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rothenhausen",
    "PLZ": "9565",
    "Zusatzziffer": "5",
    "Gemeindename": "Bussnang",
    "BFS-Nr": "4921",
    "Kantonskürzel": "TG",
    "E": "2725268.416000001",
    "N": "1267838",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Märstetten",
    "PLZ": "8560",
    "Zusatzziffer": "0",
    "Gemeindename": "Märstetten",
    "BFS-Nr": "4941",
    "Kantonskürzel": "TG",
    "E": "2722639.932",
    "N": "1272744.5830000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ottoberg",
    "PLZ": "8561",
    "Zusatzziffer": "0",
    "Gemeindename": "Märstetten",
    "BFS-Nr": "4941",
    "Kantonskürzel": "TG",
    "E": "2723875.9629999995",
    "N": "1272037.4389999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Weinfelden",
    "PLZ": "8570",
    "Zusatzziffer": "0",
    "Gemeindename": "Weinfelden",
    "BFS-Nr": "4946",
    "Kantonskürzel": "TG",
    "E": "2725859.1669999994",
    "N": "1269936.9589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Müllheim-Wigoltingen",
    "PLZ": "8554",
    "Zusatzziffer": "0",
    "Gemeindename": "Wigoltingen",
    "BFS-Nr": "4951",
    "Kantonskürzel": "TG",
    "E": "2718612.7289999984",
    "N": "1272018.427000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bonau",
    "PLZ": "8554",
    "Zusatzziffer": "3",
    "Gemeindename": "Wigoltingen",
    "BFS-Nr": "4951",
    "Kantonskürzel": "TG",
    "E": "2720791.7639999986",
    "N": "1271448.3379999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wigoltingen",
    "PLZ": "8556",
    "Zusatzziffer": "0",
    "Gemeindename": "Wigoltingen",
    "BFS-Nr": "4951",
    "Kantonskürzel": "TG",
    "E": "2719754.842",
    "N": "1272886.640999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Engwang",
    "PLZ": "8556",
    "Zusatzziffer": "2",
    "Gemeindename": "Wigoltingen",
    "BFS-Nr": "4951",
    "Kantonskürzel": "TG",
    "E": "2721382.8170000017",
    "N": "1273393.1999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Illhart",
    "PLZ": "8556",
    "Zusatzziffer": "3",
    "Gemeindename": "Wigoltingen",
    "BFS-Nr": "4951",
    "Kantonskürzel": "TG",
    "E": "2720246.8539999984",
    "N": "1275373.2600000016",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lamperswil TG",
    "PLZ": "8556",
    "Zusatzziffer": "4",
    "Gemeindename": "Wigoltingen",
    "BFS-Nr": "4951",
    "Kantonskürzel": "TG",
    "E": "2720306.9499999993",
    "N": "1274690.4420000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wagerswil",
    "PLZ": "8564",
    "Zusatzziffer": "6",
    "Gemeindename": "Wigoltingen",
    "BFS-Nr": "4951",
    "Kantonskürzel": "TG",
    "E": "2722229.313000001",
    "N": "1274280.6039999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Arbedo",
    "PLZ": "6517",
    "Zusatzziffer": "0",
    "Gemeindename": "Arbedo-Castione",
    "BFS-Nr": "5001",
    "Kantonskürzel": "TI",
    "E": "2724070.3379999995",
    "N": "1119307.8709999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Castione",
    "PLZ": "6532",
    "Zusatzziffer": "0",
    "Gemeindename": "Arbedo-Castione",
    "BFS-Nr": "5001",
    "Kantonskürzel": "TI",
    "E": "2724110.9580000006",
    "N": "1120800.0300000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bellinzona",
    "PLZ": "6500",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2723262.9770654254",
    "N": "1117423.982999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bellinzona",
    "PLZ": "6503",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2721042.227758469",
    "N": "1118669.7650000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Giubiasco",
    "PLZ": "6512",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2720834.4250000007",
    "N": "1114811.954",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Monte Carasso",
    "PLZ": "6513",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2720317.408",
    "N": "1116263.9450000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sementina",
    "PLZ": "6514",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2719531.3779999986",
    "N": "1115799.5639999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gudo",
    "PLZ": "6515",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2716790.1810000017",
    "N": "1114889.2890000008",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gorduno",
    "PLZ": "6518",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2722976.936999999",
    "N": "1119627.3940000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Preonzo",
    "PLZ": "6523",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2720529.2430000007",
    "N": "1124763.546",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Moleno",
    "PLZ": "6524",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2719858.8139999993",
    "N": "1125317.438000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gnosca",
    "PLZ": "6525",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2722017.649",
    "N": "1121502.7280000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Camorino",
    "PLZ": "6528",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2721088.6920000017",
    "N": "1113674.427000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pianezzo",
    "PLZ": "6582",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2722878.8960000016",
    "N": "1114375.8949999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Antonio (Val Morobbia)",
    "PLZ": "6583",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2725318.087000001",
    "N": "1114381.0960000008",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Carena",
    "PLZ": "6584",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2727167.5",
    "N": "1114369.2859999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Claro",
    "PLZ": "6702",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellinzona",
    "BFS-Nr": "5002",
    "Kantonskürzel": "TI",
    "E": "2722260.1039999984",
    "N": "1123678.699000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cadenazzo",
    "PLZ": "6593",
    "Zusatzziffer": "0",
    "Gemeindename": "Cadenazzo",
    "BFS-Nr": "5003",
    "Kantonskürzel": "TI",
    "E": "2716922.6900000013",
    "N": "1111964.7080000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Robasacco",
    "PLZ": "6599",
    "Zusatzziffer": "0",
    "Gemeindename": "Cadenazzo",
    "BFS-Nr": "5003",
    "Kantonskürzel": "TI",
    "E": "2716050.653000001",
    "N": "1111245.5830000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Isone",
    "PLZ": "6810",
    "Zusatzziffer": "0",
    "Gemeindename": "Isone",
    "BFS-Nr": "5009",
    "Kantonskürzel": "TI",
    "E": "2719531.9329999983",
    "N": "1109779.9640000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lumino",
    "PLZ": "6533",
    "Zusatzziffer": "0",
    "Gemeindename": "Lumino",
    "BFS-Nr": "5010",
    "Kantonskürzel": "TI",
    "E": "2725537.028999999",
    "N": "1121183.3790000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Antonino",
    "PLZ": "6592",
    "Zusatzziffer": "0",
    "Gemeindename": "Sant'Antonino",
    "BFS-Nr": "5017",
    "Kantonskürzel": "TI",
    "E": "2718971.460000001",
    "N": "1112493.921",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Dongio",
    "PLZ": "6715",
    "Zusatzziffer": "0",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2716560.726",
    "N": "1143979.2729999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Acquarossa",
    "PLZ": "6716",
    "Zusatzziffer": "0",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2715114.159000002",
    "N": "1146054.3949999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Leontica",
    "PLZ": "6716",
    "Zusatzziffer": "2",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2713984.0709999986",
    "N": "1146397.493999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lottigna",
    "PLZ": "6716",
    "Zusatzziffer": "3",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2715560.2529999986",
    "N": "1147590.4079999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Motto (Blenio)",
    "PLZ": "6721",
    "Zusatzziffer": "2",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2717801.710999999",
    "N": "1143078.947999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Corzoneso",
    "PLZ": "6722",
    "Zusatzziffer": "0",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2714917.625",
    "N": "1144956.5379999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Prugiasco",
    "PLZ": "6723",
    "Zusatzziffer": "0",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2714698.3900000006",
    "N": "1146669.2360000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Castro",
    "PLZ": "6723",
    "Zusatzziffer": "2",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2714319.4239999987",
    "N": "1147775.5410000011",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Marolta",
    "PLZ": "6723",
    "Zusatzziffer": "3",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2713818.8680000007",
    "N": "1148588.0249999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ponto Valentino",
    "PLZ": "6724",
    "Zusatzziffer": "0",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2714935.2809999995",
    "N": "1149062.9279999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Largario",
    "PLZ": "6724",
    "Zusatzziffer": "2",
    "Gemeindename": "Acquarossa",
    "BFS-Nr": "5048",
    "Kantonskürzel": "TI",
    "E": "2715356.2580000013",
    "N": "1150343.210000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Dangio",
    "PLZ": "6717",
    "Zusatzziffer": "0",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2716255.2030000016",
    "N": "1150328.8099999987",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Torre",
    "PLZ": "6717",
    "Zusatzziffer": "1",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2716339.039000001",
    "N": "1149561.511",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Olivone",
    "PLZ": "6718",
    "Zusatzziffer": "0",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2715295.754999999",
    "N": "1154263.1590000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Camperio",
    "PLZ": "6718",
    "Zusatzziffer": "1",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2712642.8440000005",
    "N": "1153637.8779999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Aquila",
    "PLZ": "6719",
    "Zusatzziffer": "0",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2715867.91",
    "N": "1151401.6750000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Aquila",
    "PLZ": "6719",
    "Zusatzziffer": "0",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2720436.3139999993",
    "N": "1160157.7410000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Campo (Blenio)",
    "PLZ": "6720",
    "Zusatzziffer": "0",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2714863.045000002",
    "N": "1157143.0199999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ghirone",
    "PLZ": "6720",
    "Zusatzziffer": "2",
    "Gemeindename": "Blenio",
    "BFS-Nr": "5049",
    "Kantonskürzel": "TI",
    "E": "2715480.568",
    "N": "1157717.5540000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Malvaglia",
    "PLZ": "6713",
    "Zusatzziffer": "0",
    "Gemeindename": "Serravalle",
    "BFS-Nr": "5050",
    "Kantonskürzel": "TI",
    "E": "2718800.4230000004",
    "N": "1140311.1939999983",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Semione",
    "PLZ": "6714",
    "Zusatzziffer": "0",
    "Gemeindename": "Serravalle",
    "BFS-Nr": "5050",
    "Kantonskürzel": "TI",
    "E": "2717880.465999998",
    "N": "1140746.0150000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ludiano",
    "PLZ": "6721",
    "Zusatzziffer": "0",
    "Gemeindename": "Serravalle",
    "BFS-Nr": "5050",
    "Kantonskürzel": "TI",
    "E": "2717668.7380000018",
    "N": "1142107.9180000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Airolo",
    "PLZ": "6780",
    "Zusatzziffer": "0",
    "Gemeindename": "Airolo",
    "BFS-Nr": "5061",
    "Kantonskürzel": "TI",
    "E": "2689808.3429999985",
    "N": "1153758.9690000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Madrano",
    "PLZ": "6780",
    "Zusatzziffer": "2",
    "Gemeindename": "Airolo",
    "BFS-Nr": "5061",
    "Kantonskürzel": "TI",
    "E": "2691375.0830000006",
    "N": "1153564.9160000011",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Villa Bedretto",
    "PLZ": "6781",
    "Zusatzziffer": "1",
    "Gemeindename": "Bedretto",
    "BFS-Nr": "5063",
    "Kantonskürzel": "TI",
    "E": "2683393.4310000017",
    "N": "1151540.1959999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bedretto",
    "PLZ": "6781",
    "Zusatzziffer": "4",
    "Gemeindename": "Bedretto",
    "BFS-Nr": "5063",
    "Kantonskürzel": "TI",
    "E": "2682361.583999999",
    "N": "1151095.425999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bodio TI",
    "PLZ": "6743",
    "Zusatzziffer": "0",
    "Gemeindename": "Bodio",
    "BFS-Nr": "5064",
    "Kantonskürzel": "TI",
    "E": "2713333.4279999994",
    "N": "1137475.0439999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Dalpe",
    "PLZ": "6774",
    "Zusatzziffer": "0",
    "Gemeindename": "Dalpe",
    "BFS-Nr": "5071",
    "Kantonskürzel": "TI",
    "E": "2702639.313000001",
    "N": "1147728.2179999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lavorgo",
    "PLZ": "6746",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2707754.438000001",
    "N": "1144340.188000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Calonico",
    "PLZ": "6746",
    "Zusatzziffer": "1",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2707754.818",
    "N": "1145339.9059999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Nivo",
    "PLZ": "6746",
    "Zusatzziffer": "2",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2707874.625",
    "N": "1143722.4059999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Chironico",
    "PLZ": "6747",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2708016.333999999",
    "N": "1142272.25",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Anzonico",
    "PLZ": "6748",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2709456.6790000014",
    "N": "1143244.3409999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sobrio",
    "PLZ": "6749",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2712510.973000001",
    "N": "1139783.0540000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cavagnago",
    "PLZ": "6749",
    "Zusatzziffer": "1",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2710916.4109999985",
    "N": "1141258.414999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Faido",
    "PLZ": "6760",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2704375.8830000013",
    "N": "1148401.671",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Molare",
    "PLZ": "6760",
    "Zusatzziffer": "1",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2706856.4800000004",
    "N": "1149260.9129999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Calpiogna",
    "PLZ": "6760",
    "Zusatzziffer": "2",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2704940.579999998",
    "N": "1149316.813000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Campello",
    "PLZ": "6760",
    "Zusatzziffer": "3",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2705956.478",
    "N": "1149330.2380000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Carì",
    "PLZ": "6760",
    "Zusatzziffer": "4",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2706161.158",
    "N": "1150141.6330000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Rossura",
    "PLZ": "6760",
    "Zusatzziffer": "5",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2706471.7589999996",
    "N": "1148054.5590000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Osco",
    "PLZ": "6763",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2703068.5890000015",
    "N": "1150007.664999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mairengo",
    "PLZ": "6763",
    "Zusatzziffer": "1",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2703585.791000001",
    "N": "1149365.5879999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Chiggiogna",
    "PLZ": "6764",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2707096.75",
    "N": "1144672.625",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Chiggiogna",
    "PLZ": "6764",
    "Zusatzziffer": "0",
    "Gemeindename": "Faido",
    "BFS-Nr": "5072",
    "Kantonskürzel": "TI",
    "E": "2706278.9959999993",
    "N": "1147186.835000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Giornico",
    "PLZ": "6745",
    "Zusatzziffer": "0",
    "Gemeindename": "Giornico",
    "BFS-Nr": "5073",
    "Kantonskürzel": "TI",
    "E": "2710463.969999999",
    "N": "1139726.9290000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Personico",
    "PLZ": "6744",
    "Zusatzziffer": "0",
    "Gemeindename": "Personico",
    "BFS-Nr": "5076",
    "Kantonskürzel": "TI",
    "E": "2713936.550999999",
    "N": "1136527.3559999987",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pollegio",
    "PLZ": "6742",
    "Zusatzziffer": "0",
    "Gemeindename": "Pollegio",
    "BFS-Nr": "5077",
    "Kantonskürzel": "TI",
    "E": "2715645.960999999",
    "N": "1136000.8000000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Rodi-Fiesso",
    "PLZ": "6772",
    "Zusatzziffer": "0",
    "Gemeindename": "Prato (Leventina)",
    "BFS-Nr": "5078",
    "Kantonskürzel": "TI",
    "E": "2699609.8709999993",
    "N": "1149775.5350000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Prato (Leventina)",
    "PLZ": "6773",
    "Zusatzziffer": "0",
    "Gemeindename": "Prato (Leventina)",
    "BFS-Nr": "5078",
    "Kantonskürzel": "TI",
    "E": "2701197.631000001",
    "N": "1148794.4990000017",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ambrì",
    "PLZ": "6775",
    "Zusatzziffer": "0",
    "Gemeindename": "Quinto",
    "BFS-Nr": "5079",
    "Kantonskürzel": "TI",
    "E": "2696427.9789999984",
    "N": "1151640.335000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Piotta",
    "PLZ": "6776",
    "Zusatzziffer": "0",
    "Gemeindename": "Quinto",
    "BFS-Nr": "5079",
    "Kantonskürzel": "TI",
    "E": "2694592.1750000007",
    "N": "1152155.4189999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Quinto",
    "PLZ": "6777",
    "Zusatzziffer": "0",
    "Gemeindename": "Quinto",
    "BFS-Nr": "5079",
    "Kantonskürzel": "TI",
    "E": "2697679.34",
    "N": "1151867.8990000002",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Varenzo",
    "PLZ": "6777",
    "Zusatzziffer": "1",
    "Gemeindename": "Quinto",
    "BFS-Nr": "5079",
    "Kantonskürzel": "TI",
    "E": "2698687.954",
    "N": "1151004.2710000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ascona",
    "PLZ": "6612",
    "Zusatzziffer": "0",
    "Gemeindename": "Ascona",
    "BFS-Nr": "5091",
    "Kantonskürzel": "TI",
    "E": "2702690.171",
    "N": "1112364.261",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Brione sopra Minusio",
    "PLZ": "6645",
    "Zusatzziffer": "0",
    "Gemeindename": "Brione sopra Minusio",
    "BFS-Nr": "5096",
    "Kantonskürzel": "TI",
    "E": "2706228.5309999995",
    "N": "1115548.3839999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Brissago",
    "PLZ": "6614",
    "Zusatzziffer": "0",
    "Gemeindename": "Brissago",
    "BFS-Nr": "5097",
    "Kantonskürzel": "TI",
    "E": "2698342.908",
    "N": "1108254.9250000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Isole di Brissago",
    "PLZ": "6614",
    "Zusatzziffer": "2",
    "Gemeindename": "Brissago",
    "BFS-Nr": "5097",
    "Kantonskürzel": "TI",
    "E": "2700202.25",
    "N": "1109802.125",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gordola",
    "PLZ": "6596",
    "Zusatzziffer": "0",
    "Gemeindename": "Gordola",
    "BFS-Nr": "5108",
    "Kantonskürzel": "TI",
    "E": "2710157.296",
    "N": "1115195.5489999987",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Riazzino",
    "PLZ": "6595",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavertezzo",
    "BFS-Nr": "5112",
    "Kantonskürzel": "TI",
    "E": "2711916.541000001",
    "N": "1115131.1350000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Locarno",
    "PLZ": "6600",
    "Zusatzziffer": "0",
    "Gemeindename": "Locarno",
    "BFS-Nr": "5113",
    "Kantonskürzel": "TI",
    "E": "2703692.7581410296",
    "N": "1116372.2249999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Locarno",
    "PLZ": "6600",
    "Zusatzziffer": "0",
    "Gemeindename": "Locarno",
    "BFS-Nr": "5113",
    "Kantonskürzel": "TI",
    "E": "2705543.0965563487",
    "N": "1113004.7529999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Solduno",
    "PLZ": "6600",
    "Zusatzziffer": "4",
    "Gemeindename": "Locarno",
    "BFS-Nr": "5113",
    "Kantonskürzel": "TI",
    "E": "2703489.158",
    "N": "1114169.5300000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Locarno",
    "PLZ": "6605",
    "Zusatzziffer": "0",
    "Gemeindename": "Locarno",
    "BFS-Nr": "5113",
    "Kantonskürzel": "TI",
    "E": "2703393.8595000003",
    "N": "1115726.4512644527",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Losone",
    "PLZ": "6616",
    "Zusatzziffer": "0",
    "Gemeindename": "Losone",
    "BFS-Nr": "5115",
    "Kantonskürzel": "TI",
    "E": "2702024.0500000007",
    "N": "1113954.8949999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Arcegno",
    "PLZ": "6618",
    "Zusatzziffer": "0",
    "Gemeindename": "Losone",
    "BFS-Nr": "5115",
    "Kantonskürzel": "TI",
    "E": "2700737.3940000013",
    "N": "1113161.5540000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mergoscia",
    "PLZ": "6647",
    "Zusatzziffer": "0",
    "Gemeindename": "Mergoscia",
    "BFS-Nr": "5117",
    "Kantonskürzel": "TI",
    "E": "2708780.1000000015",
    "N": "1118666.2529999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Minusio",
    "PLZ": "6648",
    "Zusatzziffer": "0",
    "Gemeindename": "Minusio",
    "BFS-Nr": "5118",
    "Kantonskürzel": "TI",
    "E": "2706129.9739999995",
    "N": "1114845.8000000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Muralto",
    "PLZ": "6600",
    "Zusatzziffer": "2",
    "Gemeindename": "Muralto",
    "BFS-Nr": "5120",
    "Kantonskürzel": "TI",
    "E": "2705538.6750000007",
    "N": "1114387.6660000011",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Orselina",
    "PLZ": "6644",
    "Zusatzziffer": "0",
    "Gemeindename": "Orselina",
    "BFS-Nr": "5121",
    "Kantonskürzel": "TI",
    "E": "2705132.537999999",
    "N": "1115204.4499999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Porto Ronco",
    "PLZ": "6613",
    "Zusatzziffer": "0",
    "Gemeindename": "Ronco sopra Ascona",
    "BFS-Nr": "5125",
    "Kantonskürzel": "TI",
    "E": "2699496.8759999983",
    "N": "1110682.989",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ronco sopra Ascona",
    "PLZ": "6622",
    "Zusatzziffer": "0",
    "Gemeindename": "Ronco sopra Ascona",
    "BFS-Nr": "5125",
    "Kantonskürzel": "TI",
    "E": "2699506.041000001",
    "N": "1111105.6220000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Tenero",
    "PLZ": "6598",
    "Zusatzziffer": "0",
    "Gemeindename": "Tenero-Contra",
    "BFS-Nr": "5131",
    "Kantonskürzel": "TI",
    "E": "2708858.7479999997",
    "N": "1115398.699000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Contra",
    "PLZ": "6646",
    "Zusatzziffer": "0",
    "Gemeindename": "Tenero-Contra",
    "BFS-Nr": "5131",
    "Kantonskürzel": "TI",
    "E": "2708207.476",
    "N": "1116191.0260000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mosogno",
    "PLZ": "6611",
    "Zusatzziffer": "13",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2692661.1840000004",
    "N": "1117083.2710000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gresso",
    "PLZ": "6611",
    "Zusatzziffer": "16",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2690838.0089999996",
    "N": "1119958.1739999987",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Crana",
    "PLZ": "6611",
    "Zusatzziffer": "17",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2690550.039999999",
    "N": "1117574.629999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Loco",
    "PLZ": "6661",
    "Zusatzziffer": "0",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2695182.8759999983",
    "N": "1117524.7939999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Auressio",
    "PLZ": "6661",
    "Zusatzziffer": "2",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2696160.533",
    "N": "1117444.4959999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Berzona",
    "PLZ": "6661",
    "Zusatzziffer": "3",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2694438.5689999983",
    "N": "1117773.4789999984",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Russo",
    "PLZ": "6662",
    "Zusatzziffer": "0",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2691356.159000002",
    "N": "1117429.7540000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Comologno",
    "PLZ": "6663",
    "Zusatzziffer": "0",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2687850.193",
    "N": "1117546.5909999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Spruga",
    "PLZ": "6663",
    "Zusatzziffer": "2",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2687219.739",
    "N": "1117262.7109999992",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vergeletto",
    "PLZ": "6664",
    "Zusatzziffer": "0",
    "Gemeindename": "Onsernone",
    "BFS-Nr": "5136",
    "Kantonskürzel": "TI",
    "E": "2689770.4789999984",
    "N": "1120163.4050000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cugnasco",
    "PLZ": "6516",
    "Zusatzziffer": "0",
    "Gemeindename": "Cugnasco-Gerra",
    "BFS-Nr": "5138",
    "Kantonskürzel": "TI",
    "E": "2714201.3379999995",
    "N": "1114711.164999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Agarone",
    "PLZ": "6597",
    "Zusatzziffer": "0",
    "Gemeindename": "Cugnasco-Gerra",
    "BFS-Nr": "5138",
    "Kantonskürzel": "TI",
    "E": "2713293.642000001",
    "N": "1115166.6209999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Agno",
    "PLZ": "6982",
    "Zusatzziffer": "0",
    "Gemeindename": "Agno",
    "BFS-Nr": "5141",
    "Kantonskürzel": "TI",
    "E": "2713038.045000002",
    "N": "1095062.0359999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cassina d'Agno",
    "PLZ": "6990",
    "Zusatzziffer": "0",
    "Gemeindename": "Agno",
    "BFS-Nr": "5141",
    "Kantonskürzel": "TI",
    "E": "2712691.164999999",
    "N": "1094334.1009999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Aranno",
    "PLZ": "6994",
    "Zusatzziffer": "0",
    "Gemeindename": "Aranno",
    "BFS-Nr": "5143",
    "Kantonskürzel": "TI",
    "E": "2711089.25",
    "N": "1097277.313000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Arogno",
    "PLZ": "6822",
    "Zusatzziffer": "0",
    "Gemeindename": "Arogno",
    "BFS-Nr": "5144",
    "Kantonskürzel": "TI",
    "E": "2720160.098000001",
    "N": "1090965.2980000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pugerna",
    "PLZ": "6823",
    "Zusatzziffer": "0",
    "Gemeindename": "Arogno",
    "BFS-Nr": "5144",
    "Kantonskürzel": "TI",
    "E": "2719358.204999998",
    "N": "1093085.4990000017",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Astano",
    "PLZ": "6999",
    "Zusatzziffer": "0",
    "Gemeindename": "Astano",
    "BFS-Nr": "5146",
    "Kantonskürzel": "TI",
    "E": "2706696.899",
    "N": "1096619.432",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bedano",
    "PLZ": "6930",
    "Zusatzziffer": "0",
    "Gemeindename": "Bedano",
    "BFS-Nr": "5148",
    "Kantonskürzel": "TI",
    "E": "2714602.784000002",
    "N": "1101061.4690000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bedigliora",
    "PLZ": "6981",
    "Zusatzziffer": "11",
    "Gemeindename": "Bedigliora",
    "BFS-Nr": "5149",
    "Kantonskürzel": "TI",
    "E": "2708746.215",
    "N": "1095528.243999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bedigliora",
    "PLZ": "6981",
    "Zusatzziffer": "11",
    "Gemeindename": "Bedigliora",
    "BFS-Nr": "5149",
    "Kantonskürzel": "TI",
    "E": "2707642.75",
    "N": "1097641.563000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Banco",
    "PLZ": "6981",
    "Zusatzziffer": "16",
    "Gemeindename": "Bedigliora",
    "BFS-Nr": "5149",
    "Kantonskürzel": "TI",
    "E": "2708797.164999999",
    "N": "1096418.3850000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Beride di Bedigliora",
    "PLZ": "6981",
    "Zusatzziffer": "17",
    "Gemeindename": "Bedigliora",
    "BFS-Nr": "5149",
    "Kantonskürzel": "TI",
    "E": "2708019.8090000004",
    "N": "1095462.9609999992",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bioggio",
    "PLZ": "6934",
    "Zusatzziffer": "0",
    "Gemeindename": "Bioggio",
    "BFS-Nr": "5151",
    "Kantonskürzel": "TI",
    "E": "2713852.666000001",
    "N": "1096945.6750000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bosco Luganese",
    "PLZ": "6935",
    "Zusatzziffer": "0",
    "Gemeindename": "Bioggio",
    "BFS-Nr": "5151",
    "Kantonskürzel": "TI",
    "E": "2713844.140999999",
    "N": "1098270.3079999983",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cimo",
    "PLZ": "6992",
    "Zusatzziffer": "1",
    "Gemeindename": "Bioggio",
    "BFS-Nr": "5151",
    "Kantonskürzel": "TI",
    "E": "2712419.6259999983",
    "N": "1095546",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Iseo",
    "PLZ": "6993",
    "Zusatzziffer": "0",
    "Gemeindename": "Bioggio",
    "BFS-Nr": "5151",
    "Kantonskürzel": "TI",
    "E": "2711829.068",
    "N": "1095814.210000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bissone",
    "PLZ": "6816",
    "Zusatzziffer": "0",
    "Gemeindename": "Bissone",
    "BFS-Nr": "5154",
    "Kantonskürzel": "TI",
    "E": "2718301.2239999995",
    "N": "1090005.8940000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Brusino Arsizio",
    "PLZ": "6827",
    "Zusatzziffer": "0",
    "Gemeindename": "Brusino Arsizio",
    "BFS-Nr": "5160",
    "Kantonskürzel": "TI",
    "E": "2716284.7380000018",
    "N": "1087471.4400000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Serpiano",
    "PLZ": "6867",
    "Zusatzziffer": "0",
    "Gemeindename": "Brusino Arsizio",
    "BFS-Nr": "5160",
    "Kantonskürzel": "TI",
    "E": "2715637.2140000015",
    "N": "1085567.7490000017",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cademario",
    "PLZ": "6936",
    "Zusatzziffer": "0",
    "Gemeindename": "Cademario",
    "BFS-Nr": "5161",
    "Kantonskürzel": "TI",
    "E": "2712571.447999999",
    "N": "1097837.9160000011",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cadempino",
    "PLZ": "6814",
    "Zusatzziffer": "3",
    "Gemeindename": "Cadempino",
    "BFS-Nr": "5162",
    "Kantonskürzel": "TI",
    "E": "2715813.0480000004",
    "N": "1099124.8889999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Canobbio",
    "PLZ": "6952",
    "Zusatzziffer": "0",
    "Gemeindename": "Canobbio",
    "BFS-Nr": "5167",
    "Kantonskürzel": "TI",
    "E": "2718455.1440000013",
    "N": "1099167.6400000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Caslano",
    "PLZ": "6987",
    "Zusatzziffer": "0",
    "Gemeindename": "Caslano",
    "BFS-Nr": "5171",
    "Kantonskürzel": "TI",
    "E": "2711986.958999999",
    "N": "1092184.2809999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Comano",
    "PLZ": "6949",
    "Zusatzziffer": "0",
    "Gemeindename": "Comano",
    "BFS-Nr": "5176",
    "Kantonskürzel": "TI",
    "E": "2717398.609000001",
    "N": "1099406.3599999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cureglia",
    "PLZ": "6944",
    "Zusatzziffer": "0",
    "Gemeindename": "Cureglia",
    "BFS-Nr": "5180",
    "Kantonskürzel": "TI",
    "E": "2716581.8079999983",
    "N": "1099508.1380000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bombinasco",
    "PLZ": "6981",
    "Zusatzziffer": "15",
    "Gemeindename": "Curio",
    "BFS-Nr": "5181",
    "Kantonskürzel": "TI",
    "E": "2708026.2809999995",
    "N": "1096792.875",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Curio",
    "PLZ": "6986",
    "Zusatzziffer": "3",
    "Gemeindename": "Curio",
    "BFS-Nr": "5181",
    "Kantonskürzel": "TI",
    "E": "2710339.4059999995",
    "N": "1095442.432",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Grancia",
    "PLZ": "6916",
    "Zusatzziffer": "0",
    "Gemeindename": "Grancia",
    "BFS-Nr": "5186",
    "Kantonskürzel": "TI",
    "E": "2715517",
    "N": "1091697.989",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gravesano",
    "PLZ": "6929",
    "Zusatzziffer": "0",
    "Gemeindename": "Gravesano",
    "BFS-Nr": "5187",
    "Kantonskürzel": "TI",
    "E": "2714414.0199999996",
    "N": "1100100.0989999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lamone",
    "PLZ": "6814",
    "Zusatzziffer": "2",
    "Gemeindename": "Lamone",
    "BFS-Nr": "5189",
    "Kantonskürzel": "TI",
    "E": "2715538.7430000007",
    "N": "1100300.5199999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lugano",
    "PLZ": "6900",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2716805.8900000006",
    "N": "1095994.2159999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pazzallo",
    "PLZ": "6912",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2716579.1900000013",
    "N": "1093688.546",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Carabbia",
    "PLZ": "6913",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2716074.0810000002",
    "N": "1092324.3850000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Carona",
    "PLZ": "6914",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2716043.4459999986",
    "N": "1090681.9719999991",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pambio-Noranco",
    "PLZ": "6915",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2715642.9010000005",
    "N": "1093867.1559999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Barbengo",
    "PLZ": "6917",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2714352.822999999",
    "N": "1090704.8299999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Figino",
    "PLZ": "6918",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2713773.958999999",
    "N": "1089853.2280000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Breganzona",
    "PLZ": "6932",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2715219.6099999994",
    "N": "1096312.2349999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Insone",
    "PLZ": "6951",
    "Zusatzziffer": "8",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2723060.3599999994",
    "N": "1105086.421",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Scareglia",
    "PLZ": "6951",
    "Zusatzziffer": "17",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2723521.7639999986",
    "N": "1105555.6110000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Colla",
    "PLZ": "6951",
    "Zusatzziffer": "18",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2724947.640999999",
    "N": "1105797.7580000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bogno",
    "PLZ": "6951",
    "Zusatzziffer": "19",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2725603.022999998",
    "N": "1105513.5830000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cozzo",
    "PLZ": "6951",
    "Zusatzziffer": "20",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2725534.5",
    "N": "1106372.063000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Signôra",
    "PLZ": "6951",
    "Zusatzziffer": "23",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2724353.784000002",
    "N": "1105814.8489999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Maglio di Colla",
    "PLZ": "6959",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2724529.414000001",
    "N": "1105401.5839999989",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Certara",
    "PLZ": "6959",
    "Zusatzziffer": "2",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2725319.824000001",
    "N": "1105030.7850000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Curtina",
    "PLZ": "6959",
    "Zusatzziffer": "3",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2722605.914000001",
    "N": "1104596.5599999987",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cimadera",
    "PLZ": "6959",
    "Zusatzziffer": "4",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2724329.6559999995",
    "N": "1104310.4759999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Piandera Paese",
    "PLZ": "6959",
    "Zusatzziffer": "5",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2723227.625",
    "N": "1104340",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Piandera Paese",
    "PLZ": "6959",
    "Zusatzziffer": "5",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2723855.300999999",
    "N": "1104741.5260000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Viganello",
    "PLZ": "6962",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2718386.125",
    "N": "1096651.0249999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pregassona",
    "PLZ": "6963",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2718702.5830000006",
    "N": "1097838.4789999984",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cureggia",
    "PLZ": "6963",
    "Zusatzziffer": "2",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2719713.25",
    "N": "1097760.063000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Davesco-Soragno",
    "PLZ": "6964",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2719362.7289999984",
    "N": "1099362.1999999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cadro",
    "PLZ": "6965",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2720072.4360000007",
    "N": "1100426.3139999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Villa Luganese",
    "PLZ": "6966",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2720565.8429999985",
    "N": "1101477.8449999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Dino",
    "PLZ": "6967",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2719620.0359999985",
    "N": "1101355.4759999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sonvico",
    "PLZ": "6968",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2720112.1160000004",
    "N": "1101892.1550000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Aldesago",
    "PLZ": "6974",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2719302.2080000006",
    "N": "1096412.9160000011",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Castagnola",
    "PLZ": "6976",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2719246.0830000006",
    "N": "1095698.0929999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ruvigliana",
    "PLZ": "6977",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2719095.162999999",
    "N": "1095889.4880000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gandria",
    "PLZ": "6978",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2721131.2809999995",
    "N": "1096081.3330000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Brè sopra Lugano",
    "PLZ": "6979",
    "Zusatzziffer": "0",
    "Gemeindename": "Lugano",
    "BFS-Nr": "5192",
    "Kantonskürzel": "TI",
    "E": "2720802.8850000016",
    "N": "1096905.7289999984",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Magliaso",
    "PLZ": "6983",
    "Zusatzziffer": "0",
    "Gemeindename": "Magliaso",
    "BFS-Nr": "5193",
    "Kantonskürzel": "TI",
    "E": "2712052.186999999",
    "N": "1093273.4279999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Manno",
    "PLZ": "6928",
    "Zusatzziffer": "0",
    "Gemeindename": "Manno",
    "BFS-Nr": "5194",
    "Kantonskürzel": "TI",
    "E": "2714585.022999998",
    "N": "1099181.7129999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Maroggia",
    "PLZ": "6817",
    "Zusatzziffer": "0",
    "Gemeindename": "Maroggia",
    "BFS-Nr": "5195",
    "Kantonskürzel": "TI",
    "E": "2718672.1559999995",
    "N": "1088271.2809999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Massagno",
    "PLZ": "6900",
    "Zusatzziffer": "5",
    "Gemeindename": "Massagno",
    "BFS-Nr": "5196",
    "Kantonskürzel": "TI",
    "E": "2716528.375",
    "N": "1096801.5",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Melano",
    "PLZ": "6818",
    "Zusatzziffer": "0",
    "Gemeindename": "Melano",
    "BFS-Nr": "5197",
    "Kantonskürzel": "TI",
    "E": "2720081.4690000005",
    "N": "1086459.2140000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Melide",
    "PLZ": "6815",
    "Zusatzziffer": "0",
    "Gemeindename": "Melide",
    "BFS-Nr": "5198",
    "Kantonskürzel": "TI",
    "E": "2717108.8249999993",
    "N": "1090367.6810000017",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mezzovico",
    "PLZ": "6805",
    "Zusatzziffer": "0",
    "Gemeindename": "Mezzovico-Vira",
    "BFS-Nr": "5199",
    "Kantonskürzel": "TI",
    "E": "2710961.375",
    "N": "1104880.25",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mezzovico",
    "PLZ": "6805",
    "Zusatzziffer": "0",
    "Gemeindename": "Mezzovico-Vira",
    "BFS-Nr": "5199",
    "Kantonskürzel": "TI",
    "E": "2714182.631000001",
    "N": "1105324.0450000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Miglieglia",
    "PLZ": "6986",
    "Zusatzziffer": "2",
    "Gemeindename": "Miglieglia",
    "BFS-Nr": "5200",
    "Kantonskürzel": "TI",
    "E": "2709851.7190000005",
    "N": "1098088.7580000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Morcote",
    "PLZ": "6922",
    "Zusatzziffer": "0",
    "Gemeindename": "Morcote",
    "BFS-Nr": "5203",
    "Kantonskürzel": "TI",
    "E": "2714476.3260000013",
    "N": "1086804.6460000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Muzzano",
    "PLZ": "6933",
    "Zusatzziffer": "0",
    "Gemeindename": "Muzzano",
    "BFS-Nr": "5205",
    "Kantonskürzel": "TI",
    "E": "2714943.2600000016",
    "N": "1095175.4690000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Neggio",
    "PLZ": "6991",
    "Zusatzziffer": "0",
    "Gemeindename": "Neggio",
    "BFS-Nr": "5206",
    "Kantonskürzel": "TI",
    "E": "2711642.4450000003",
    "N": "1093875.3990000002",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Novaggio",
    "PLZ": "6986",
    "Zusatzziffer": "0",
    "Gemeindename": "Novaggio",
    "BFS-Nr": "5207",
    "Kantonskürzel": "TI",
    "E": "2709779.0009999983",
    "N": "1096396.5500000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Origlio",
    "PLZ": "6945",
    "Zusatzziffer": "0",
    "Gemeindename": "Origlio",
    "BFS-Nr": "5208",
    "Kantonskürzel": "TI",
    "E": "2716537.6330000013",
    "N": "1101425.7800000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Paradiso",
    "PLZ": "6900",
    "Zusatzziffer": "9",
    "Gemeindename": "Paradiso",
    "BFS-Nr": "5210",
    "Kantonskürzel": "TI",
    "E": "2716827.4439999983",
    "N": "1094194.4530000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ponte Capriasca",
    "PLZ": "6946",
    "Zusatzziffer": "0",
    "Gemeindename": "Ponte Capriasca",
    "BFS-Nr": "5212",
    "Kantonskürzel": "TI",
    "E": "2725768.25",
    "N": "1109369",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ponte Capriasca",
    "PLZ": "6946",
    "Zusatzziffer": "0",
    "Gemeindename": "Ponte Capriasca",
    "BFS-Nr": "5212",
    "Kantonskürzel": "TI",
    "E": "2716903.541000001",
    "N": "1102298.7179999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Porza",
    "PLZ": "6948",
    "Zusatzziffer": "0",
    "Gemeindename": "Porza",
    "BFS-Nr": "5214",
    "Kantonskürzel": "TI",
    "E": "2717377.234000001",
    "N": "1098438.0590000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pura",
    "PLZ": "6984",
    "Zusatzziffer": "0",
    "Gemeindename": "Pura",
    "BFS-Nr": "5216",
    "Kantonskürzel": "TI",
    "E": "2710825.129999999",
    "N": "1093730.193",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Rovio",
    "PLZ": "6821",
    "Zusatzziffer": "0",
    "Gemeindename": "Rovio",
    "BFS-Nr": "5219",
    "Kantonskürzel": "TI",
    "E": "2720111.9690000005",
    "N": "1088001.0450000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Capolago",
    "PLZ": "6825",
    "Zusatzziffer": "0",
    "Gemeindename": "Rovio",
    "BFS-Nr": "5219",
    "Kantonskürzel": "TI",
    "E": "2722548.5",
    "N": "1087498.688000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Savosa",
    "PLZ": "6942",
    "Zusatzziffer": "0",
    "Gemeindename": "Savosa",
    "BFS-Nr": "5221",
    "Kantonskürzel": "TI",
    "E": "2717145.5309999995",
    "N": "1098042.8999999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sorengo",
    "PLZ": "6924",
    "Zusatzziffer": "0",
    "Gemeindename": "Sorengo",
    "BFS-Nr": "5225",
    "Kantonskürzel": "TI",
    "E": "2715905.0500000007",
    "N": "1095414.1119999997",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Taverne",
    "PLZ": "6807",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2715788.5579999983",
    "N": "1103439.8200000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vaglio",
    "PLZ": "6947",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2717462.09",
    "N": "1102276.131000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Tesserete",
    "PLZ": "6950",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2718127.405000001",
    "N": "1102938.9800000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lugaggia",
    "PLZ": "6953",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2718647.973000001",
    "N": "1102366.4560000002",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sala Capriasca",
    "PLZ": "6954",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2717471.4439999983",
    "N": "1102666.9100000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bigorio",
    "PLZ": "6954",
    "Zusatzziffer": "1",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2717541.3440000005",
    "N": "1103247.438000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cagiallo",
    "PLZ": "6955",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2718735.171",
    "N": "1102807.6229999997",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Oggio",
    "PLZ": "6955",
    "Zusatzziffer": "1",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2719715.25",
    "N": "1103376.125",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lopagno",
    "PLZ": "6956",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2719015.789999999",
    "N": "1103087.1700000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Roveredo TI",
    "PLZ": "6957",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2719502.098000001",
    "N": "1103642.7630000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bidogno",
    "PLZ": "6958",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2720763.1400000006",
    "N": "1104186.5130000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Corticiasca",
    "PLZ": "6958",
    "Zusatzziffer": "2",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2722032.25",
    "N": "1105171.063000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Corticiasca",
    "PLZ": "6958",
    "Zusatzziffer": "2",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2721833.6939999983",
    "N": "1104340.3060000017",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Odogno",
    "PLZ": "6960",
    "Zusatzziffer": "0",
    "Gemeindename": "Capriasca",
    "BFS-Nr": "5226",
    "Kantonskürzel": "TI",
    "E": "2718432.550999999",
    "N": "1104576.8000000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Taverne",
    "PLZ": "6807",
    "Zusatzziffer": "0",
    "Gemeindename": "Torricella-Taverne",
    "BFS-Nr": "5227",
    "Kantonskürzel": "TI",
    "E": "2715733.9230000004",
    "N": "1102792.8110000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Torricella",
    "PLZ": "6808",
    "Zusatzziffer": "0",
    "Gemeindename": "Torricella-Taverne",
    "BFS-Nr": "5227",
    "Kantonskürzel": "TI",
    "E": "2714790.2650000006",
    "N": "1102776.4979999997",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vernate",
    "PLZ": "6992",
    "Zusatzziffer": "0",
    "Gemeindename": "Vernate",
    "BFS-Nr": "5230",
    "Kantonskürzel": "TI",
    "E": "2712194.155000001",
    "N": "1094725.5340000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vezia",
    "PLZ": "6943",
    "Zusatzziffer": "0",
    "Gemeindename": "Vezia",
    "BFS-Nr": "5231",
    "Kantonskürzel": "TI",
    "E": "2716028.822999999",
    "N": "1097988.2349999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vico Morcote",
    "PLZ": "6921",
    "Zusatzziffer": "0",
    "Gemeindename": "Vico Morcote",
    "BFS-Nr": "5233",
    "Kantonskürzel": "TI",
    "E": "2715095.460999999",
    "N": "1087618.243999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Carabietta",
    "PLZ": "6919",
    "Zusatzziffer": "0",
    "Gemeindename": "Collina d'Oro",
    "BFS-Nr": "5236",
    "Kantonskürzel": "TI",
    "E": "2713307.0650000013",
    "N": "1091819.3440000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gentilino",
    "PLZ": "6925",
    "Zusatzziffer": "0",
    "Gemeindename": "Collina d'Oro",
    "BFS-Nr": "5236",
    "Kantonskürzel": "TI",
    "E": "2715770.1840000004",
    "N": "1094376.493999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Montagnola",
    "PLZ": "6926",
    "Zusatzziffer": "0",
    "Gemeindename": "Collina d'Oro",
    "BFS-Nr": "5236",
    "Kantonskürzel": "TI",
    "E": "2714580.094999999",
    "N": "1093365.8209999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Agra",
    "PLZ": "6927",
    "Zusatzziffer": "0",
    "Gemeindename": "Collina d'Oro",
    "BFS-Nr": "5236",
    "Kantonskürzel": "TI",
    "E": "2714146.1350000016",
    "N": "1091788.6609999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Breno",
    "PLZ": "6937",
    "Zusatzziffer": "0",
    "Gemeindename": "Alto Malcantone",
    "BFS-Nr": "5237",
    "Kantonskürzel": "TI",
    "E": "2710847.0610000007",
    "N": "1098963.6999999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vezio",
    "PLZ": "6938",
    "Zusatzziffer": "0",
    "Gemeindename": "Alto Malcantone",
    "BFS-Nr": "5237",
    "Kantonskürzel": "TI",
    "E": "2711753.5810000002",
    "N": "1100408.824000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Fescoggia",
    "PLZ": "6938",
    "Zusatzziffer": "1",
    "Gemeindename": "Alto Malcantone",
    "BFS-Nr": "5237",
    "Kantonskürzel": "TI",
    "E": "2711353.414999999",
    "N": "1099793.232999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mugena",
    "PLZ": "6939",
    "Zusatzziffer": "1",
    "Gemeindename": "Alto Malcantone",
    "BFS-Nr": "5237",
    "Kantonskürzel": "TI",
    "E": "2712358.4530000016",
    "N": "1100727.7939999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Arosio",
    "PLZ": "6939",
    "Zusatzziffer": "2",
    "Gemeindename": "Alto Malcantone",
    "BFS-Nr": "5237",
    "Kantonskürzel": "TI",
    "E": "2713126.370000001",
    "N": "1100635.6629999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Rivera",
    "PLZ": "6802",
    "Zusatzziffer": "0",
    "Gemeindename": "Monteceneri",
    "BFS-Nr": "5238",
    "Kantonskürzel": "TI",
    "E": "2714935.096999999",
    "N": "1109092.4580000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Camignolo",
    "PLZ": "6803",
    "Zusatzziffer": "0",
    "Gemeindename": "Monteceneri",
    "BFS-Nr": "5238",
    "Kantonskürzel": "TI",
    "E": "2715954.9290000014",
    "N": "1107172.6590000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bironico",
    "PLZ": "6804",
    "Zusatzziffer": "0",
    "Gemeindename": "Monteceneri",
    "BFS-Nr": "5238",
    "Kantonskürzel": "TI",
    "E": "2715521.2129999995",
    "N": "1108003.7639999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sigirino",
    "PLZ": "6806",
    "Zusatzziffer": "0",
    "Gemeindename": "Monteceneri",
    "BFS-Nr": "5238",
    "Kantonskürzel": "TI",
    "E": "2714143.585000001",
    "N": "1104298.0940000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Medeglia",
    "PLZ": "6809",
    "Zusatzziffer": "0",
    "Gemeindename": "Monteceneri",
    "BFS-Nr": "5238",
    "Kantonskürzel": "TI",
    "E": "2718334.8489999995",
    "N": "1108265.7809999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Castelrotto",
    "PLZ": "6980",
    "Zusatzziffer": "0",
    "Gemeindename": "Tresa",
    "BFS-Nr": "5239",
    "Kantonskürzel": "TI",
    "E": "2708395.408",
    "N": "1094442.732999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ponte Tresa",
    "PLZ": "6988",
    "Zusatzziffer": "0",
    "Gemeindename": "Tresa",
    "BFS-Nr": "5239",
    "Kantonskürzel": "TI",
    "E": "2710045.3949999996",
    "N": "1091889.449000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Purasca",
    "PLZ": "6989",
    "Zusatzziffer": "0",
    "Gemeindename": "Tresa",
    "BFS-Nr": "5239",
    "Kantonskürzel": "TI",
    "E": "2709355.5040000007",
    "N": "1093110.2239999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Madonna del Piano",
    "PLZ": "6995",
    "Zusatzziffer": "2",
    "Gemeindename": "Tresa",
    "BFS-Nr": "5239",
    "Kantonskürzel": "TI",
    "E": "2708106.3379999995",
    "N": "1093898.813000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sessa",
    "PLZ": "6997",
    "Zusatzziffer": "0",
    "Gemeindename": "Tresa",
    "BFS-Nr": "5239",
    "Kantonskürzel": "TI",
    "E": "2706922.66",
    "N": "1095093.8029999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Monteggio",
    "PLZ": "6998",
    "Zusatzziffer": "1",
    "Gemeindename": "Tresa",
    "BFS-Nr": "5239",
    "Kantonskürzel": "TI",
    "E": "2706538.9750000015",
    "N": "1094766.243999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Balerna",
    "PLZ": "6828",
    "Zusatzziffer": "0",
    "Gemeindename": "Balerna",
    "BFS-Nr": "5242",
    "Kantonskürzel": "TI",
    "E": "2721915.9679999985",
    "N": "1078768.0229999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Corteglia",
    "PLZ": "6873",
    "Zusatzziffer": "0",
    "Gemeindename": "Castel San Pietro",
    "BFS-Nr": "5249",
    "Kantonskürzel": "TI",
    "E": "2720778.284000002",
    "N": "1080066.5300000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Castel San Pietro",
    "PLZ": "6874",
    "Zusatzziffer": "0",
    "Gemeindename": "Castel San Pietro",
    "BFS-Nr": "5249",
    "Kantonskürzel": "TI",
    "E": "2722033.1689999998",
    "N": "1079894.4849999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Monte",
    "PLZ": "6875",
    "Zusatzziffer": "0",
    "Gemeindename": "Castel San Pietro",
    "BFS-Nr": "5249",
    "Kantonskürzel": "TI",
    "E": "2723395.778999999",
    "N": "1082366.0130000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Casima",
    "PLZ": "6875",
    "Zusatzziffer": "2",
    "Gemeindename": "Castel San Pietro",
    "BFS-Nr": "5249",
    "Kantonskürzel": "TI",
    "E": "2724079.5439999998",
    "N": "1083373.9189999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Campora",
    "PLZ": "6875",
    "Zusatzziffer": "3",
    "Gemeindename": "Castel San Pietro",
    "BFS-Nr": "5249",
    "Kantonskürzel": "TI",
    "E": "2723035.881000001",
    "N": "1081601.5029999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Chiasso",
    "PLZ": "6830",
    "Zusatzziffer": "0",
    "Gemeindename": "Chiasso",
    "BFS-Nr": "5250",
    "Kantonskürzel": "TI",
    "E": "2723408.739",
    "N": "1077163.8449999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Pedrinate",
    "PLZ": "6832",
    "Zusatzziffer": "0",
    "Gemeindename": "Chiasso",
    "BFS-Nr": "5250",
    "Kantonskürzel": "TI",
    "E": "2722198.8319999985",
    "N": "1076212.129999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Seseglio",
    "PLZ": "6832",
    "Zusatzziffer": "1",
    "Gemeindename": "Chiasso",
    "BFS-Nr": "5250",
    "Kantonskürzel": "TI",
    "E": "2721284.8319999985",
    "N": "1076227.3260000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Coldrerio",
    "PLZ": "6877",
    "Zusatzziffer": "0",
    "Gemeindename": "Coldrerio",
    "BFS-Nr": "5251",
    "Kantonskürzel": "TI",
    "E": "2720184",
    "N": "1079169.75",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Capolago",
    "PLZ": "6825",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2719678.159000002",
    "N": "1084832.8000000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Mendrisio",
    "PLZ": "6850",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2720404.5190000013",
    "N": "1081093.8420000002",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Genestrerio",
    "PLZ": "6852",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2718207.3559999987",
    "N": "1079396.3040000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ligornetto",
    "PLZ": "6853",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2717639.166000001",
    "N": "1080151.4530000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Rancate",
    "PLZ": "6862",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2718840.6059999987",
    "N": "1081129.2639999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Besazio",
    "PLZ": "6863",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2717499.3390000015",
    "N": "1081310.6860000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Arzo",
    "PLZ": "6864",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2716669.2239999995",
    "N": "1081616.296",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Tremona",
    "PLZ": "6865",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2718005.664999999",
    "N": "1082172.4340000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Meride",
    "PLZ": "6866",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2717523.443",
    "N": "1083214.6099999994",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Salorino",
    "PLZ": "6872",
    "Zusatzziffer": "0",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2720661.026999999",
    "N": "1081413.868999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Somazzo",
    "PLZ": "6872",
    "Zusatzziffer": "1",
    "Gemeindename": "Mendrisio",
    "BFS-Nr": "5254",
    "Kantonskürzel": "TI",
    "E": "2720681.2399999984",
    "N": "1081923.4580000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Morbio Inferiore",
    "PLZ": "6834",
    "Zusatzziffer": "0",
    "Gemeindename": "Morbio Inferiore",
    "BFS-Nr": "5257",
    "Kantonskürzel": "TI",
    "E": "2722590.3099999987",
    "N": "1079231.0819999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Novazzano",
    "PLZ": "6883",
    "Zusatzziffer": "0",
    "Gemeindename": "Novazzano",
    "BFS-Nr": "5260",
    "Kantonskürzel": "TI",
    "E": "2719857.166000001",
    "N": "1077837.460000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Riva San Vitale",
    "PLZ": "6826",
    "Zusatzziffer": "0",
    "Gemeindename": "Riva San Vitale",
    "BFS-Nr": "5263",
    "Kantonskürzel": "TI",
    "E": "2718809.3759999983",
    "N": "1085071.9299999997",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Pietro",
    "PLZ": "6854",
    "Zusatzziffer": "0",
    "Gemeindename": "Stabio",
    "BFS-Nr": "5266",
    "Kantonskürzel": "TI",
    "E": "2716276.8310000002",
    "N": "1079732.234000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Stabio",
    "PLZ": "6855",
    "Zusatzziffer": "0",
    "Gemeindename": "Stabio",
    "BFS-Nr": "5266",
    "Kantonskürzel": "TI",
    "E": "2716513.995000001",
    "N": "1078790.3550000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vacallo",
    "PLZ": "6833",
    "Zusatzziffer": "0",
    "Gemeindename": "Vacallo",
    "BFS-Nr": "5268",
    "Kantonskürzel": "TI",
    "E": "2724008.840999998",
    "N": "1078595.890999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Morbio Superiore",
    "PLZ": "6835",
    "Zusatzziffer": "0",
    "Gemeindename": "Breggia",
    "BFS-Nr": "5269",
    "Kantonskürzel": "TI",
    "E": "2722873.162999999",
    "N": "1080108.265999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Caneggio",
    "PLZ": "6837",
    "Zusatzziffer": "0",
    "Gemeindename": "Breggia",
    "BFS-Nr": "5269",
    "Kantonskürzel": "TI",
    "E": "2723707.524",
    "N": "1081364.2890000008",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bruzella",
    "PLZ": "6837",
    "Zusatzziffer": "2",
    "Gemeindename": "Breggia",
    "BFS-Nr": "5269",
    "Kantonskürzel": "TI",
    "E": "2724169.890999999",
    "N": "1082466.3709999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Muggio",
    "PLZ": "6838",
    "Zusatzziffer": "0",
    "Gemeindename": "Breggia",
    "BFS-Nr": "5269",
    "Kantonskürzel": "TI",
    "E": "2724518.370000001",
    "N": "1084677.3040000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cabbio",
    "PLZ": "6838",
    "Zusatzziffer": "2",
    "Gemeindename": "Breggia",
    "BFS-Nr": "5269",
    "Kantonskürzel": "TI",
    "E": "2724809.3889999986",
    "N": "1084202.0450000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sagno",
    "PLZ": "6839",
    "Zusatzziffer": "0",
    "Gemeindename": "Breggia",
    "BFS-Nr": "5269",
    "Kantonskürzel": "TI",
    "E": "2724466.6609999985",
    "N": "1079657.7080000006",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Biasca",
    "PLZ": "6710",
    "Zusatzziffer": "0",
    "Gemeindename": "Biasca",
    "BFS-Nr": "5281",
    "Kantonskürzel": "TI",
    "E": "2718022.868999999",
    "N": "1135456.5179999992",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Prosito",
    "PLZ": "6526",
    "Zusatzziffer": "0",
    "Gemeindename": "Riviera",
    "BFS-Nr": "5287",
    "Kantonskürzel": "TI",
    "E": "2719067.287999999",
    "N": "1126527.1959999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lodrino",
    "PLZ": "6527",
    "Zusatzziffer": "0",
    "Gemeindename": "Riviera",
    "BFS-Nr": "5287",
    "Kantonskürzel": "TI",
    "E": "2718582.756000001",
    "N": "1128957.1999999993",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Osogna",
    "PLZ": "6703",
    "Zusatzziffer": "0",
    "Gemeindename": "Riviera",
    "BFS-Nr": "5287",
    "Kantonskürzel": "TI",
    "E": "2719272.405000001",
    "N": "1130272.9549999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cresciano",
    "PLZ": "6705",
    "Zusatzziffer": "0",
    "Gemeindename": "Riviera",
    "BFS-Nr": "5287",
    "Kantonskürzel": "TI",
    "E": "2720682.0430000015",
    "N": "1126688.4699999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Iragna",
    "PLZ": "6707",
    "Zusatzziffer": "0",
    "Gemeindename": "Riviera",
    "BFS-Nr": "5287",
    "Kantonskürzel": "TI",
    "E": "2717677.9299999997",
    "N": "1132094.3559999987",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bosco/Gurin",
    "PLZ": "6685",
    "Zusatzziffer": "0",
    "Gemeindename": "Bosco/Gurin",
    "BFS-Nr": "5304",
    "Kantonskürzel": "TI",
    "E": "2681098.908",
    "N": "1130027.4090000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niva (Vallemaggia)",
    "PLZ": "6683",
    "Zusatzziffer": "2",
    "Gemeindename": "Campo (Vallemaggia)",
    "BFS-Nr": "5307",
    "Kantonskürzel": "TI",
    "E": "2684179.1689999998",
    "N": "1127222.2289999984",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Campo (Vallemaggia)",
    "PLZ": "6684",
    "Zusatzziffer": "0",
    "Gemeindename": "Campo (Vallemaggia)",
    "BFS-Nr": "5307",
    "Kantonskürzel": "TI",
    "E": "2681519.3330000006",
    "N": "1126948.857999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cimalmotto",
    "PLZ": "6684",
    "Zusatzziffer": "1",
    "Gemeindename": "Campo (Vallemaggia)",
    "BFS-Nr": "5307",
    "Kantonskürzel": "TI",
    "E": "2680953.539000001",
    "N": "1126313.125",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cerentino",
    "PLZ": "6683",
    "Zusatzziffer": "0",
    "Gemeindename": "Cerentino",
    "BFS-Nr": "5309",
    "Kantonskürzel": "TI",
    "E": "2685435.170000002",
    "N": "1128852.1909999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cevio",
    "PLZ": "6675",
    "Zusatzziffer": "0",
    "Gemeindename": "Cevio",
    "BFS-Nr": "5310",
    "Kantonskürzel": "TI",
    "E": "2689586.0940000005",
    "N": "1130114.7280000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Bignasco",
    "PLZ": "6676",
    "Zusatzziffer": "0",
    "Gemeindename": "Cevio",
    "BFS-Nr": "5310",
    "Kantonskürzel": "TI",
    "E": "2690117.6460000016",
    "N": "1132578.3680000007",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cavergno",
    "PLZ": "6690",
    "Zusatzziffer": "0",
    "Gemeindename": "Cevio",
    "BFS-Nr": "5310",
    "Kantonskürzel": "TI",
    "E": "2689956.4510000013",
    "N": "1133256.0430000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Carlo (Val Bavona)",
    "PLZ": "6690",
    "Zusatzziffer": "1",
    "Gemeindename": "Cevio",
    "BFS-Nr": "5310",
    "Kantonskürzel": "TI",
    "E": "2683743.9299999997",
    "N": "1140295.8960000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Linescio",
    "PLZ": "6682",
    "Zusatzziffer": "0",
    "Gemeindename": "Linescio",
    "BFS-Nr": "5315",
    "Kantonskürzel": "TI",
    "E": "2688163.079999998",
    "N": "1129171.460000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Maggia",
    "PLZ": "6673",
    "Zusatzziffer": "0",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2697917.539999999",
    "N": "1122495.9759999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Someo",
    "PLZ": "6674",
    "Zusatzziffer": "0",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2694591.2129999995",
    "N": "1126831.6490000002",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Riveo",
    "PLZ": "6674",
    "Zusatzziffer": "2",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2692232.0100000016",
    "N": "1127933.0879999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Aurigeno",
    "PLZ": "6677",
    "Zusatzziffer": "1",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2698281.844999999",
    "N": "1120721.6700000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Moghegno",
    "PLZ": "6677",
    "Zusatzziffer": "2",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2697712.1909999996",
    "N": "1121748.107999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Giumaglio",
    "PLZ": "6678",
    "Zusatzziffer": "0",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2695863.0830000006",
    "N": "1125512.739",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lodano",
    "PLZ": "6678",
    "Zusatzziffer": "2",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2696079.745000001",
    "N": "1123669.3759999983",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Coglio",
    "PLZ": "6678",
    "Zusatzziffer": "3",
    "Gemeindename": "Maggia",
    "BFS-Nr": "5317",
    "Kantonskürzel": "TI",
    "E": "2696015.033",
    "N": "1124942.6909999996",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Menzonio",
    "PLZ": "6692",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavizzara",
    "BFS-Nr": "5323",
    "Kantonskürzel": "TI",
    "E": "2692527.528999999",
    "N": "1135132.8850000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Brontallo",
    "PLZ": "6692",
    "Zusatzziffer": "1",
    "Gemeindename": "Lavizzara",
    "BFS-Nr": "5323",
    "Kantonskürzel": "TI",
    "E": "2691579.5130000003",
    "N": "1134488.4690000005",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Broglio",
    "PLZ": "6693",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavizzara",
    "BFS-Nr": "5323",
    "Kantonskürzel": "TI",
    "E": "2694050.7989999987",
    "N": "1136911.4079999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Prato-Sornico",
    "PLZ": "6694",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavizzara",
    "BFS-Nr": "5323",
    "Kantonskürzel": "TI",
    "E": "2693489.101",
    "N": "1139072.210000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Peccia",
    "PLZ": "6695",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavizzara",
    "BFS-Nr": "5323",
    "Kantonskürzel": "TI",
    "E": "2692995.976",
    "N": "1140258.9290000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Piano di Peccia",
    "PLZ": "6695",
    "Zusatzziffer": "1",
    "Gemeindename": "Lavizzara",
    "BFS-Nr": "5323",
    "Kantonskürzel": "TI",
    "E": "2689927.5430000015",
    "N": "1140942.8310000002",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Fusio",
    "PLZ": "6696",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavizzara",
    "BFS-Nr": "5323",
    "Kantonskürzel": "TI",
    "E": "2693855.6680000015",
    "N": "1144489.9050000012",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Avegno",
    "PLZ": "6670",
    "Zusatzziffer": "0",
    "Gemeindename": "Avegno Gordevio",
    "BFS-Nr": "5324",
    "Kantonskürzel": "TI",
    "E": "2700892.4959999993",
    "N": "1118054.2300000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gordevio",
    "PLZ": "6672",
    "Zusatzziffer": "0",
    "Gemeindename": "Avegno Gordevio",
    "BFS-Nr": "5324",
    "Kantonskürzel": "TI",
    "E": "2700261.2250000015",
    "N": "1120169.7690000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Medeglia",
    "PLZ": "6809",
    "Zusatzziffer": "0",
    "Gemeindename": "Comunanza Cadenazzo/Monteceneri",
    "BFS-Nr": "5391",
    "Kantonskürzel": "TI",
    "E": "2725167.75",
    "N": "1111201.625",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Tegna",
    "PLZ": "6652",
    "Zusatzziffer": "0",
    "Gemeindename": "Terre di Pedemonte",
    "BFS-Nr": "5396",
    "Kantonskürzel": "TI",
    "E": "2700807.756000001",
    "N": "1115804.1900000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Verscio",
    "PLZ": "6653",
    "Zusatzziffer": "0",
    "Gemeindename": "Terre di Pedemonte",
    "BFS-Nr": "5396",
    "Kantonskürzel": "TI",
    "E": "2699864.063000001",
    "N": "1115619.3390000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Cavigliano",
    "PLZ": "6654",
    "Zusatzziffer": "0",
    "Gemeindename": "Terre di Pedemonte",
    "BFS-Nr": "5396",
    "Kantonskürzel": "TI",
    "E": "2698858.6499999985",
    "N": "1115636.5179999992",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Intragna",
    "PLZ": "6655",
    "Zusatzziffer": "0",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2697409.539000001",
    "N": "1114770.2300000004",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Verdasio",
    "PLZ": "6655",
    "Zusatzziffer": "2",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2692490.7690000013",
    "N": "1113293.2280000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Rasa",
    "PLZ": "6655",
    "Zusatzziffer": "3",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2693930.688000001",
    "N": "1112347.6790000014",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Golino",
    "PLZ": "6656",
    "Zusatzziffer": "0",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2698486.7690000013",
    "N": "1114991.368999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Palagnedra",
    "PLZ": "6657",
    "Zusatzziffer": "0",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2692206.2580000013",
    "N": "1112110.3999999985",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Borgnone",
    "PLZ": "6658",
    "Zusatzziffer": "0",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2690949.7459999993",
    "N": "1112733.8940000013",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Camedo",
    "PLZ": "6659",
    "Zusatzziffer": "0",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2690373.5749999993",
    "N": "1112256.2159999982",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Moneto",
    "PLZ": "6659",
    "Zusatzziffer": "9",
    "Gemeindename": "Centovalli",
    "BFS-Nr": "5397",
    "Kantonskürzel": "TI",
    "E": "2691329.4789999984",
    "N": "1111688.7840000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Indemini",
    "PLZ": "6571",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2707304.602000002",
    "N": "1105720.0020000003",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Quartino",
    "PLZ": "6572",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2712516.625",
    "N": "1112300.015999999",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Magadino",
    "PLZ": "6573",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2709565.9860000014",
    "N": "1111670.8850000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vira (Gambarogno)",
    "PLZ": "6574",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2708355.193",
    "N": "1111301.5139999986",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "S. Nazzaro",
    "PLZ": "6575",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2705486.8060000017",
    "N": "1109886.8379999995",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vairano",
    "PLZ": "6575",
    "Zusatzziffer": "1",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2706015.8649999984",
    "N": "1109881.6009999998",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gerra (Gambarogno)",
    "PLZ": "6576",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2704089.2080000006",
    "N": "1108844.6829999983",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Ranzo",
    "PLZ": "6577",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2703419.170000002",
    "N": "1108164.4090000018",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Caviano",
    "PLZ": "6578",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2702657.2540000007",
    "N": "1107053.006000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Piazzogna",
    "PLZ": "6579",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2707130.3099999987",
    "N": "1110238.3460000008",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Contone",
    "PLZ": "6594",
    "Zusatzziffer": "0",
    "Gemeindename": "Gambarogno",
    "BFS-Nr": "5398",
    "Kantonskürzel": "TI",
    "E": "2715119.920000002",
    "N": "1111824.2140000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Corippo",
    "PLZ": "6631",
    "Zusatzziffer": "0",
    "Gemeindename": "Verzasca",
    "BFS-Nr": "5399",
    "Kantonskürzel": "TI",
    "E": "2708166.579",
    "N": "1121446.7250000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Vogorno",
    "PLZ": "6632",
    "Zusatzziffer": "0",
    "Gemeindename": "Verzasca",
    "BFS-Nr": "5399",
    "Kantonskürzel": "TI",
    "E": "2709559.1849999987",
    "N": "1120010.7879999988",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Lavertezzo",
    "PLZ": "6633",
    "Zusatzziffer": "0",
    "Gemeindename": "Verzasca",
    "BFS-Nr": "5399",
    "Kantonskürzel": "TI",
    "E": "2707743.454999998",
    "N": "1123823.2030000016",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Brione (Verzasca)",
    "PLZ": "6634",
    "Zusatzziffer": "0",
    "Gemeindename": "Verzasca",
    "BFS-Nr": "5399",
    "Kantonskürzel": "TI",
    "E": "2704145.1609999985",
    "N": "1128158.506000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Gerra (Verzasca)",
    "PLZ": "6635",
    "Zusatzziffer": "0",
    "Gemeindename": "Verzasca",
    "BFS-Nr": "5399",
    "Kantonskürzel": "TI",
    "E": "2704728.0929999985",
    "N": "1130630.0430000015",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Frasco",
    "PLZ": "6636",
    "Zusatzziffer": "0",
    "Gemeindename": "Verzasca",
    "BFS-Nr": "5399",
    "Kantonskürzel": "TI",
    "E": "2704970.4499999993",
    "N": "1133004.818",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Sonogno",
    "PLZ": "6637",
    "Zusatzziffer": "0",
    "Gemeindename": "Verzasca",
    "BFS-Nr": "5399",
    "Kantonskürzel": "TI",
    "E": "2703724.340999998",
    "N": "1134050.2850000001",
    "Sprache": "it"
  },
  {
    "Ortschaftsname": "Aigle",
    "PLZ": "1860",
    "Zusatzziffer": "0",
    "Gemeindename": "Aigle",
    "BFS-Nr": "5401",
    "Kantonskürzel": "VD",
    "E": "2563719.653999999",
    "N": "1129651.7360000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bex",
    "PLZ": "1880",
    "Zusatzziffer": "0",
    "Gemeindename": "Bex",
    "BFS-Nr": "5402",
    "Kantonskürzel": "VD",
    "E": "2567131.7309999987",
    "N": "1122287.9629999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Frenières-sur-Bex",
    "PLZ": "1880",
    "Zusatzziffer": "2",
    "Gemeindename": "Bex",
    "BFS-Nr": "5402",
    "Kantonskürzel": "VD",
    "E": "2571686.6400000006",
    "N": "1123407.9979999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fenalet-sur-Bex",
    "PLZ": "1880",
    "Zusatzziffer": "3",
    "Gemeindename": "Bex",
    "BFS-Nr": "5402",
    "Kantonskürzel": "VD",
    "E": "2568325.4899999984",
    "N": "1124589.5749999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Plans-sur-Bex",
    "PLZ": "1880",
    "Zusatzziffer": "4",
    "Gemeindename": "Bex",
    "BFS-Nr": "5402",
    "Kantonskürzel": "VD",
    "E": "2573369",
    "N": "1122883.204",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Posses-sur-Bex",
    "PLZ": "1880",
    "Zusatzziffer": "5",
    "Gemeindename": "Bex",
    "BFS-Nr": "5402",
    "Kantonskürzel": "VD",
    "E": "2569886.6889999993",
    "N": "1124426.7760000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chessel",
    "PLZ": "1846",
    "Zusatzziffer": "0",
    "Gemeindename": "Chessel",
    "BFS-Nr": "5403",
    "Kantonskürzel": "VD",
    "E": "2558094.4439999983",
    "N": "1133279.5610000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corbeyrier",
    "PLZ": "1856",
    "Zusatzziffer": "0",
    "Gemeindename": "Corbeyrier",
    "BFS-Nr": "5404",
    "Kantonskürzel": "VD",
    "E": "2563345.162999999",
    "N": "1133318.482999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gryon",
    "PLZ": "1882",
    "Zusatzziffer": "0",
    "Gemeindename": "Gryon",
    "BFS-Nr": "5405",
    "Kantonskürzel": "VD",
    "E": "2571029.7859999985",
    "N": "1124743.8990000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lavey-Village",
    "PLZ": "1892",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavey-Morcles",
    "BFS-Nr": "5406",
    "Kantonskürzel": "VD",
    "E": "2567636.3539999984",
    "N": "1118753.6629999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lavey-les-Bains",
    "PLZ": "1892",
    "Zusatzziffer": "1",
    "Gemeindename": "Lavey-Morcles",
    "BFS-Nr": "5406",
    "Kantonskürzel": "VD",
    "E": "2567536.3049999997",
    "N": "1117097.4629999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Morcles",
    "PLZ": "1892",
    "Zusatzziffer": "2",
    "Gemeindename": "Lavey-Morcles",
    "BFS-Nr": "5406",
    "Kantonskürzel": "VD",
    "E": "2568967.226",
    "N": "1117584.3790000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Leysin",
    "PLZ": "1854",
    "Zusatzziffer": "0",
    "Gemeindename": "Leysin",
    "BFS-Nr": "5407",
    "Kantonskürzel": "VD",
    "E": "2567194.034000002",
    "N": "1132339.3049999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Noville",
    "PLZ": "1845",
    "Zusatzziffer": "0",
    "Gemeindename": "Noville",
    "BFS-Nr": "5408",
    "Kantonskürzel": "VD",
    "E": "2558451.9250000007",
    "N": "1136760.1330000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ollon VD",
    "PLZ": "1867",
    "Zusatzziffer": "0",
    "Gemeindename": "Ollon",
    "BFS-Nr": "5409",
    "Kantonskürzel": "VD",
    "E": "2565778.449000001",
    "N": "1127399.8630000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Triphon",
    "PLZ": "1867",
    "Zusatzziffer": "2",
    "Gemeindename": "Ollon",
    "BFS-Nr": "5409",
    "Kantonskürzel": "VD",
    "E": "2564539.5100000016",
    "N": "1126912.9189999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Panex",
    "PLZ": "1867",
    "Zusatzziffer": "3",
    "Gemeindename": "Ollon",
    "BFS-Nr": "5409",
    "Kantonskürzel": "VD",
    "E": "2567308.914999999",
    "N": "1128957.9200000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-sur-Ollon",
    "PLZ": "1884",
    "Zusatzziffer": "0",
    "Gemeindename": "Ollon",
    "BFS-Nr": "5409",
    "Kantonskürzel": "VD",
    "E": "2570547.0210000016",
    "N": "1127427.1400000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arveyes",
    "PLZ": "1884",
    "Zusatzziffer": "2",
    "Gemeindename": "Ollon",
    "BFS-Nr": "5409",
    "Kantonskürzel": "VD",
    "E": "2571014.1889999993",
    "N": "1126448.625",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Huémoz",
    "PLZ": "1884",
    "Zusatzziffer": "3",
    "Gemeindename": "Ollon",
    "BFS-Nr": "5409",
    "Kantonskürzel": "VD",
    "E": "2568283.476",
    "N": "1126742.6009999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chesières",
    "PLZ": "1885",
    "Zusatzziffer": "0",
    "Gemeindename": "Ollon",
    "BFS-Nr": "5409",
    "Kantonskürzel": "VD",
    "E": "2569556.274",
    "N": "1127837.7399999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Mosses",
    "PLZ": "1862",
    "Zusatzziffer": "0",
    "Gemeindename": "Ormont-Dessous",
    "BFS-Nr": "5410",
    "Kantonskürzel": "VD",
    "E": "2574097.0689999983",
    "N": "1138578.370000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Comballaz",
    "PLZ": "1862",
    "Zusatzziffer": "2",
    "Gemeindename": "Ormont-Dessous",
    "BFS-Nr": "5410",
    "Kantonskürzel": "VD",
    "E": "2572201",
    "N": "1136265.188000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Sépey",
    "PLZ": "1863",
    "Zusatzziffer": "0",
    "Gemeindename": "Ormont-Dessous",
    "BFS-Nr": "5410",
    "Kantonskürzel": "VD",
    "E": "2570220.4279999994",
    "N": "1134561.0309999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Forclaz VD",
    "PLZ": "1866",
    "Zusatzziffer": "0",
    "Gemeindename": "Ormont-Dessous",
    "BFS-Nr": "5410",
    "Kantonskürzel": "VD",
    "E": "2571491.7809999995",
    "N": "1133418.732999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vers-l'Eglise",
    "PLZ": "1864",
    "Zusatzziffer": "0",
    "Gemeindename": "Ormont-Dessus",
    "BFS-Nr": "5411",
    "Kantonskürzel": "VD",
    "E": "2576371.0309999995",
    "N": "1133632.8440000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Diablerets",
    "PLZ": "1865",
    "Zusatzziffer": "0",
    "Gemeindename": "Ormont-Dessus",
    "BFS-Nr": "5411",
    "Kantonskürzel": "VD",
    "E": "2578332.039999999",
    "N": "1133191.0390000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rennaz",
    "PLZ": "1847",
    "Zusatzziffer": "0",
    "Gemeindename": "Rennaz",
    "BFS-Nr": "5412",
    "Kantonskürzel": "VD",
    "E": "2559989.618999999",
    "N": "1136192.1849999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Roche VD",
    "PLZ": "1852",
    "Zusatzziffer": "0",
    "Gemeindename": "Roche (VD)",
    "BFS-Nr": "5413",
    "Kantonskürzel": "VD",
    "E": "2560887.368999999",
    "N": "1134603.8409999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villeneuve VD",
    "PLZ": "1844",
    "Zusatzziffer": "0",
    "Gemeindename": "Villeneuve (VD)",
    "BFS-Nr": "5414",
    "Kantonskürzel": "VD",
    "E": "2560508.4880000018",
    "N": "1138451.3480000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Yvorne",
    "PLZ": "1853",
    "Zusatzziffer": "0",
    "Gemeindename": "Yvorne",
    "BFS-Nr": "5415",
    "Kantonskürzel": "VD",
    "E": "2563208.4409999996",
    "N": "1131168.4310000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aubonne",
    "PLZ": "1170",
    "Zusatzziffer": "0",
    "Gemeindename": "Aubonne",
    "BFS-Nr": "5422",
    "Kantonskürzel": "VD",
    "E": "2519662.903000001",
    "N": "1150026.697999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montherod",
    "PLZ": "1174",
    "Zusatzziffer": "0",
    "Gemeindename": "Aubonne",
    "BFS-Nr": "5422",
    "Kantonskürzel": "VD",
    "E": "2517642.2710000016",
    "N": "1150283.050999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pizy",
    "PLZ": "1174",
    "Zusatzziffer": "2",
    "Gemeindename": "Aubonne",
    "BFS-Nr": "5422",
    "Kantonskürzel": "VD",
    "E": "2516250.625",
    "N": "1149763.125",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ballens",
    "PLZ": "1144",
    "Zusatzziffer": "0",
    "Gemeindename": "Ballens",
    "BFS-Nr": "5423",
    "Kantonskürzel": "VD",
    "E": "2518363.5549999997",
    "N": "1156489.1849999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Berolle",
    "PLZ": "1149",
    "Zusatzziffer": "0",
    "Gemeindename": "Berolle",
    "BFS-Nr": "5424",
    "Kantonskürzel": "VD",
    "E": "2515496.028000001",
    "N": "1156921.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bière",
    "PLZ": "1145",
    "Zusatzziffer": "0",
    "Gemeindename": "Bière",
    "BFS-Nr": "5425",
    "Kantonskürzel": "VD",
    "E": "2515220.243999999",
    "N": "1154695.3110000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bougy-Villars",
    "PLZ": "1172",
    "Zusatzziffer": "0",
    "Gemeindename": "Bougy-Villars",
    "BFS-Nr": "5426",
    "Kantonskürzel": "VD",
    "E": "2516712.098000001",
    "N": "1148090.447999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Féchy",
    "PLZ": "1173",
    "Zusatzziffer": "0",
    "Gemeindename": "Féchy",
    "BFS-Nr": "5427",
    "Kantonskürzel": "VD",
    "E": "2517931.5549999997",
    "N": "1148321.4180000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gimel",
    "PLZ": "1188",
    "Zusatzziffer": "0",
    "Gemeindename": "Gimel",
    "BFS-Nr": "5428",
    "Kantonskürzel": "VD",
    "E": "2513356.210999999",
    "N": "1151384.0859999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Longirod",
    "PLZ": "1261",
    "Zusatzziffer": "35",
    "Gemeindename": "Longirod",
    "BFS-Nr": "5429",
    "Kantonskürzel": "VD",
    "E": "2509395.541000001",
    "N": "1149959.5229999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Marchissy",
    "PLZ": "1261",
    "Zusatzziffer": "32",
    "Gemeindename": "Marchissy",
    "BFS-Nr": "5430",
    "Kantonskürzel": "VD",
    "E": "2508529.285",
    "N": "1149129.796",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mollens VD",
    "PLZ": "1146",
    "Zusatzziffer": "0",
    "Gemeindename": "Mollens (VD)",
    "BFS-Nr": "5431",
    "Kantonskürzel": "VD",
    "E": "2517562.4809999987",
    "N": "1159081.4659999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-George",
    "PLZ": "1188",
    "Zusatzziffer": "2",
    "Gemeindename": "Saint-George",
    "BFS-Nr": "5434",
    "Kantonskürzel": "VD",
    "E": "2509743.5700000003",
    "N": "1152167.9109999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Livres",
    "PLZ": "1176",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Livres",
    "BFS-Nr": "5435",
    "Kantonskürzel": "VD",
    "E": "2519259.3500000015",
    "N": "1151215.1950000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Oyens",
    "PLZ": "1187",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Oyens",
    "BFS-Nr": "5436",
    "Kantonskürzel": "VD",
    "E": "2513291.960999999",
    "N": "1150348.9059999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saubraz",
    "PLZ": "1189",
    "Zusatzziffer": "0",
    "Gemeindename": "Saubraz",
    "BFS-Nr": "5437",
    "Kantonskürzel": "VD",
    "E": "2514946.5610000007",
    "N": "1152042.2699999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Avenches",
    "PLZ": "1580",
    "Zusatzziffer": "0",
    "Gemeindename": "Avenches",
    "BFS-Nr": "5451",
    "Kantonskürzel": "VD",
    "E": "2569659.9739999995",
    "N": "1192202.7109999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Oleyres",
    "PLZ": "1580",
    "Zusatzziffer": "2",
    "Gemeindename": "Avenches",
    "BFS-Nr": "5451",
    "Kantonskürzel": "VD",
    "E": "2569453.063000001",
    "N": "1189200.0899999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Donatyre",
    "PLZ": "1580",
    "Zusatzziffer": "3",
    "Gemeindename": "Avenches",
    "BFS-Nr": "5451",
    "Kantonskürzel": "VD",
    "E": "2570982.7699999996",
    "N": "1191796.5689999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cudrefin",
    "PLZ": "1588",
    "Zusatzziffer": "0",
    "Gemeindename": "Cudrefin",
    "BFS-Nr": "5456",
    "Kantonskürzel": "VD",
    "E": "2568105.5009999983",
    "N": "1200552.4629999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Faoug",
    "PLZ": "1595",
    "Zusatzziffer": "0",
    "Gemeindename": "Faoug",
    "BFS-Nr": "5458",
    "Kantonskürzel": "VD",
    "E": "2572479.164000001",
    "N": "1195358.0980000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-le-Grand",
    "PLZ": "1584",
    "Zusatzziffer": "0",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2565818.3040000014",
    "N": "1195030.8040000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Salavaux",
    "PLZ": "1585",
    "Zusatzziffer": "0",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2568334.4409999996",
    "N": "1196284.2129999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bellerive VD",
    "PLZ": "1585",
    "Zusatzziffer": "2",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2568365.7300000004",
    "N": "1197141.2589999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cotterd",
    "PLZ": "1585",
    "Zusatzziffer": "3",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2568552.6510000005",
    "N": "1196805.7140000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vallamand",
    "PLZ": "1586",
    "Zusatzziffer": "0",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2569542.1829999983",
    "N": "1197930.1590000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montmagny",
    "PLZ": "1587",
    "Zusatzziffer": "0",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2567308.5210000016",
    "N": "1197387.125",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Constantine",
    "PLZ": "1587",
    "Zusatzziffer": "1",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2567510.155000001",
    "N": "1196500.7280000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chabrey",
    "PLZ": "1589",
    "Zusatzziffer": "0",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2565178.973000001",
    "N": "1197427.482999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mur (Vully) VD",
    "PLZ": "1787",
    "Zusatzziffer": "2",
    "Gemeindename": "Vully-les-Lacs",
    "BFS-Nr": "5464",
    "Kantonskürzel": "VD",
    "E": "2571170.791000001",
    "N": "1199389.7540000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bettens",
    "PLZ": "1042",
    "Zusatzziffer": "4",
    "Gemeindename": "Bettens",
    "BFS-Nr": "5471",
    "Kantonskürzel": "VD",
    "E": "2534036.795000002",
    "N": "1164195.5749999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bournens",
    "PLZ": "1035",
    "Zusatzziffer": "0",
    "Gemeindename": "Bournens",
    "BFS-Nr": "5472",
    "Kantonskürzel": "VD",
    "E": "2532970.210999999",
    "N": "1161769.318",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Boussens",
    "PLZ": "1034",
    "Zusatzziffer": "0",
    "Gemeindename": "Boussens",
    "BFS-Nr": "5473",
    "Kantonskürzel": "VD",
    "E": "2534426.3489999995",
    "N": "1161745.9400000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Chaux (Cossonay)",
    "PLZ": "1308",
    "Zusatzziffer": "0",
    "Gemeindename": "La Chaux (Cossonay)",
    "BFS-Nr": "5474",
    "Kantonskürzel": "VD",
    "E": "2526027.833999999",
    "N": "1163428.943",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-le-Veyron",
    "PLZ": "1148",
    "Zusatzziffer": "7",
    "Gemeindename": "Chavannes-le-Veyron",
    "BFS-Nr": "5475",
    "Kantonskürzel": "VD",
    "E": "2524359.765999999",
    "N": "1162105.1640000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chevilly",
    "PLZ": "1316",
    "Zusatzziffer": "0",
    "Gemeindename": "Chevilly",
    "BFS-Nr": "5476",
    "Kantonskürzel": "VD",
    "E": "2526372.4510000013",
    "N": "1166130.8759999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cossonay-Ville",
    "PLZ": "1304",
    "Zusatzziffer": "0",
    "Gemeindename": "Cossonay",
    "BFS-Nr": "5477",
    "Kantonskürzel": "VD",
    "E": "2528821.4340000004",
    "N": "1162973.8260000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Allens",
    "PLZ": "1304",
    "Zusatzziffer": "3",
    "Gemeindename": "Cossonay",
    "BFS-Nr": "5477",
    "Kantonskürzel": "VD",
    "E": "2528831.5",
    "N": "1161406.1750000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cuarnens",
    "PLZ": "1148",
    "Zusatzziffer": "4",
    "Gemeindename": "Cuarnens",
    "BFS-Nr": "5479",
    "Kantonskürzel": "VD",
    "E": "2523393.0599999987",
    "N": "1164270.859000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Daillens",
    "PLZ": "1306",
    "Zusatzziffer": "0",
    "Gemeindename": "Daillens",
    "BFS-Nr": "5480",
    "Kantonskürzel": "VD",
    "E": "2531843.0130000003",
    "N": "1163637.1209999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dizy",
    "PLZ": "1304",
    "Zusatzziffer": "2",
    "Gemeindename": "Dizy",
    "BFS-Nr": "5481",
    "Kantonskürzel": "VD",
    "E": "2527842.3740000017",
    "N": "1165361.9699999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Eclépens",
    "PLZ": "1312",
    "Zusatzziffer": "0",
    "Gemeindename": "Eclépens",
    "BFS-Nr": "5482",
    "Kantonskürzel": "VD",
    "E": "2530116.7349999994",
    "N": "1167211.789999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ferreyres",
    "PLZ": "1313",
    "Zusatzziffer": "0",
    "Gemeindename": "Ferreyres",
    "BFS-Nr": "5483",
    "Kantonskürzel": "VD",
    "E": "2527045.3379999995",
    "N": "1167904.8940000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gollion",
    "PLZ": "1124",
    "Zusatzziffer": "0",
    "Gemeindename": "Gollion",
    "BFS-Nr": "5484",
    "Kantonskürzel": "VD",
    "E": "2528703.6229999997",
    "N": "1159753.881000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grancy",
    "PLZ": "1117",
    "Zusatzziffer": "0",
    "Gemeindename": "Grancy",
    "BFS-Nr": "5485",
    "Kantonskürzel": "VD",
    "E": "2525322.353",
    "N": "1160515.579",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "L'Isle",
    "PLZ": "1148",
    "Zusatzziffer": "0",
    "Gemeindename": "L'Isle",
    "BFS-Nr": "5486",
    "Kantonskürzel": "VD",
    "E": "2521454.267999999",
    "N": "1163474.7300000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-Bozon",
    "PLZ": "1148",
    "Zusatzziffer": "3",
    "Gemeindename": "L'Isle",
    "BFS-Nr": "5486",
    "Kantonskürzel": "VD",
    "E": "2520983.609000001",
    "N": "1162220.3060000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Coudre",
    "PLZ": "1148",
    "Zusatzziffer": "9",
    "Gemeindename": "L'Isle",
    "BFS-Nr": "5486",
    "Kantonskürzel": "VD",
    "E": "2520691.131000001",
    "N": "1165837.9739999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lussery-Villars",
    "PLZ": "1307",
    "Zusatzziffer": "0",
    "Gemeindename": "Lussery-Villars",
    "BFS-Nr": "5487",
    "Kantonskürzel": "VD",
    "E": "2530114.164999999",
    "N": "1165035.8489999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mauraz",
    "PLZ": "1148",
    "Zusatzziffer": "2",
    "Gemeindename": "Mauraz",
    "BFS-Nr": "5488",
    "Kantonskürzel": "VD",
    "E": "2522150.728",
    "N": "1162059.1999999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mex VD",
    "PLZ": "1031",
    "Zusatzziffer": "0",
    "Gemeindename": "Mex (VD)",
    "BFS-Nr": "5489",
    "Kantonskürzel": "VD",
    "E": "2532057.4560000002",
    "N": "1158854.234000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Moiry VD",
    "PLZ": "1148",
    "Zusatzziffer": "8",
    "Gemeindename": "Moiry",
    "BFS-Nr": "5490",
    "Kantonskürzel": "VD",
    "E": "2524596.1050000004",
    "N": "1166840.890999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mont-la-Ville",
    "PLZ": "1148",
    "Zusatzziffer": "5",
    "Gemeindename": "Mont-la-Ville",
    "BFS-Nr": "5491",
    "Kantonskürzel": "VD",
    "E": "2521165.899",
    "N": "1166592.1429999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montricher",
    "PLZ": "1147",
    "Zusatzziffer": "0",
    "Gemeindename": "Montricher",
    "BFS-Nr": "5492",
    "Kantonskürzel": "VD",
    "E": "2518651.447999999",
    "N": "1161650.5980000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Orny",
    "PLZ": "1317",
    "Zusatzziffer": "0",
    "Gemeindename": "Orny",
    "BFS-Nr": "5493",
    "Kantonskürzel": "VD",
    "E": "2530129.015999999",
    "N": "1168972.6130000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Penthalaz",
    "PLZ": "1305",
    "Zusatzziffer": "0",
    "Gemeindename": "Penthalaz",
    "BFS-Nr": "5495",
    "Kantonskürzel": "VD",
    "E": "2530343.8709999993",
    "N": "1162961.2250000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Penthaz",
    "PLZ": "1303",
    "Zusatzziffer": "0",
    "Gemeindename": "Penthaz",
    "BFS-Nr": "5496",
    "Kantonskürzel": "VD",
    "E": "2531056.1939999983",
    "N": "1161403.0410000011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pompaples",
    "PLZ": "1318",
    "Zusatzziffer": "0",
    "Gemeindename": "Pompaples",
    "BFS-Nr": "5497",
    "Kantonskürzel": "VD",
    "E": "2529027.284000002",
    "N": "1168759.5549999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Sarraz",
    "PLZ": "1315",
    "Zusatzziffer": "0",
    "Gemeindename": "La Sarraz",
    "BFS-Nr": "5498",
    "Kantonskürzel": "VD",
    "E": "2529266.6999999993",
    "N": "1168007.2199999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Senarclens",
    "PLZ": "1304",
    "Zusatzziffer": "4",
    "Gemeindename": "Senarclens",
    "BFS-Nr": "5499",
    "Kantonskürzel": "VD",
    "E": "2527237.409000002",
    "N": "1161501.8599999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sullens",
    "PLZ": "1036",
    "Zusatzziffer": "0",
    "Gemeindename": "Sullens",
    "BFS-Nr": "5501",
    "Kantonskürzel": "VD",
    "E": "2533209.9910000004",
    "N": "1160709.063000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vufflens-la-Ville",
    "PLZ": "1302",
    "Zusatzziffer": "0",
    "Gemeindename": "Vufflens-la-Ville",
    "BFS-Nr": "5503",
    "Kantonskürzel": "VD",
    "E": "2531028.965999998",
    "N": "1158806.6039999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Assens",
    "PLZ": "1042",
    "Zusatzziffer": "0",
    "Gemeindename": "Assens",
    "BFS-Nr": "5511",
    "Kantonskürzel": "VD",
    "E": "2537490.620000001",
    "N": "1162693.136",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bioley-Orjulaz",
    "PLZ": "1042",
    "Zusatzziffer": "3",
    "Gemeindename": "Assens",
    "BFS-Nr": "5511",
    "Kantonskürzel": "VD",
    "E": "2535567.6939999983",
    "N": "1163746.0659999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bercher",
    "PLZ": "1038",
    "Zusatzziffer": "0",
    "Gemeindename": "Bercher",
    "BFS-Nr": "5512",
    "Kantonskürzel": "VD",
    "E": "2544152.039999999",
    "N": "1171476.0709999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bottens",
    "PLZ": "1041",
    "Zusatzziffer": "33",
    "Gemeindename": "Bottens",
    "BFS-Nr": "5514",
    "Kantonskürzel": "VD",
    "E": "2540322.6799999997",
    "N": "1163555.8610000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bretigny-sur-Morrens",
    "PLZ": "1053",
    "Zusatzziffer": "2",
    "Gemeindename": "Bretigny-sur-Morrens",
    "BFS-Nr": "5515",
    "Kantonskürzel": "VD",
    "E": "2538997.090999998",
    "N": "1161035.5940000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cugy VD",
    "PLZ": "1053",
    "Zusatzziffer": "0",
    "Gemeindename": "Cugy (VD)",
    "BFS-Nr": "5516",
    "Kantonskürzel": "VD",
    "E": "2538877.484000001",
    "N": "1159530.960000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Echallens",
    "PLZ": "1040",
    "Zusatzziffer": "0",
    "Gemeindename": "Echallens",
    "BFS-Nr": "5518",
    "Kantonskürzel": "VD",
    "E": "2538398.6510000005",
    "N": "1165819.9640000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Essertines-sur-Yverdon",
    "PLZ": "1417",
    "Zusatzziffer": "0",
    "Gemeindename": "Essertines-sur-Yverdon",
    "BFS-Nr": "5520",
    "Kantonskürzel": "VD",
    "E": "2538786.1039999984",
    "N": "1174441.1950000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Epautheyres",
    "PLZ": "1417",
    "Zusatzziffer": "2",
    "Gemeindename": "Essertines-sur-Yverdon",
    "BFS-Nr": "5520",
    "Kantonskürzel": "VD",
    "E": "2539208.8889999986",
    "N": "1176788.8729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Etagnières",
    "PLZ": "1037",
    "Zusatzziffer": "0",
    "Gemeindename": "Etagnières",
    "BFS-Nr": "5521",
    "Kantonskürzel": "VD",
    "E": "2536657.0560000017",
    "N": "1161316.1959999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fey",
    "PLZ": "1044",
    "Zusatzziffer": "0",
    "Gemeindename": "Fey",
    "BFS-Nr": "5522",
    "Kantonskürzel": "VD",
    "E": "2542048.344999999",
    "N": "1169709.8530000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Froideville",
    "PLZ": "1055",
    "Zusatzziffer": "0",
    "Gemeindename": "Froideville",
    "BFS-Nr": "5523",
    "Kantonskürzel": "VD",
    "E": "2541929.909000002",
    "N": "1161449.6350000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Morrens VD",
    "PLZ": "1054",
    "Zusatzziffer": "0",
    "Gemeindename": "Morrens (VD)",
    "BFS-Nr": "5527",
    "Kantonskürzel": "VD",
    "E": "2537772.3660000004",
    "N": "1160314.1950000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Oulens-sous-Echallens",
    "PLZ": "1377",
    "Zusatzziffer": "0",
    "Gemeindename": "Oulens-sous-Echallens",
    "BFS-Nr": "5529",
    "Kantonskürzel": "VD",
    "E": "2534133.8680000007",
    "N": "1166177.125",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pailly",
    "PLZ": "1416",
    "Zusatzziffer": "0",
    "Gemeindename": "Pailly",
    "BFS-Nr": "5530",
    "Kantonskürzel": "VD",
    "E": "2541401.7380000018",
    "N": "1172298.2479999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Penthéréaz",
    "PLZ": "1375",
    "Zusatzziffer": "0",
    "Gemeindename": "Penthéréaz",
    "BFS-Nr": "5531",
    "Kantonskürzel": "VD",
    "E": "2536109.568",
    "N": "1170426.8559999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Poliez-Pittet",
    "PLZ": "1041",
    "Zusatzziffer": "32",
    "Gemeindename": "Poliez-Pittet",
    "BFS-Nr": "5533",
    "Kantonskürzel": "VD",
    "E": "2542092.7490000017",
    "N": "1164314.4910000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rueyres",
    "PLZ": "1046",
    "Zusatzziffer": "0",
    "Gemeindename": "Rueyres",
    "BFS-Nr": "5534",
    "Kantonskürzel": "VD",
    "E": "2542881.7060000002",
    "N": "1171631.9129999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Barthélemy VD",
    "PLZ": "1040",
    "Zusatzziffer": "3",
    "Gemeindename": "Saint-Barthélemy (VD)",
    "BFS-Nr": "5535",
    "Kantonskürzel": "VD",
    "E": "2535379.9439999983",
    "N": "1165105.1039999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-le-Terroir",
    "PLZ": "1040",
    "Zusatzziffer": "2",
    "Gemeindename": "Villars-le-Terroir",
    "BFS-Nr": "5537",
    "Kantonskürzel": "VD",
    "E": "2538799.5830000006",
    "N": "1167704.6909999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuarrens",
    "PLZ": "1418",
    "Zusatzziffer": "0",
    "Gemeindename": "Vuarrens",
    "BFS-Nr": "5539",
    "Kantonskürzel": "VD",
    "E": "2539535.908",
    "N": "1170974.6739999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dommartin",
    "PLZ": "1041",
    "Zusatzziffer": "21",
    "Gemeindename": "Montilliez",
    "BFS-Nr": "5540",
    "Kantonskürzel": "VD",
    "E": "2543664.7650000006",
    "N": "1166823.9549999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Naz",
    "PLZ": "1041",
    "Zusatzziffer": "26",
    "Gemeindename": "Montilliez",
    "BFS-Nr": "5540",
    "Kantonskürzel": "VD",
    "E": "2543112.1750000007",
    "N": "1167717.4780000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Poliez-le-Grand",
    "PLZ": "1041",
    "Zusatzziffer": "31",
    "Gemeindename": "Montilliez",
    "BFS-Nr": "5540",
    "Kantonskürzel": "VD",
    "E": "2540668.723000001",
    "N": "1165571.2859999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sugnens",
    "PLZ": "1043",
    "Zusatzziffer": "0",
    "Gemeindename": "Montilliez",
    "BFS-Nr": "5540",
    "Kantonskürzel": "VD",
    "E": "2541235.0100000016",
    "N": "1167423.8299999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Goumoens-la-Ville",
    "PLZ": "1376",
    "Zusatzziffer": "0",
    "Gemeindename": "Goumoëns",
    "BFS-Nr": "5541",
    "Kantonskürzel": "VD",
    "E": "2536046.840999998",
    "N": "1167771.438000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Eclagnens",
    "PLZ": "1376",
    "Zusatzziffer": "2",
    "Gemeindename": "Goumoëns",
    "BFS-Nr": "5541",
    "Kantonskürzel": "VD",
    "E": "2535145.447999999",
    "N": "1167045.5080000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Goumoens-le-Jux",
    "PLZ": "1376",
    "Zusatzziffer": "3",
    "Gemeindename": "Goumoëns",
    "BFS-Nr": "5541",
    "Kantonskürzel": "VD",
    "E": "2534862.0560000017",
    "N": "1168655.386",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bonvillars",
    "PLZ": "1427",
    "Zusatzziffer": "0",
    "Gemeindename": "Bonvillars",
    "BFS-Nr": "5551",
    "Kantonskürzel": "VD",
    "E": "2541550.6039999984",
    "N": "1187935.664999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Rasses",
    "PLZ": "1452",
    "Zusatzziffer": "0",
    "Gemeindename": "Bullet",
    "BFS-Nr": "5552",
    "Kantonskürzel": "VD",
    "E": "2531449.829",
    "N": "1186847.818",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bullet",
    "PLZ": "1453",
    "Zusatzziffer": "0",
    "Gemeindename": "Bullet",
    "BFS-Nr": "5552",
    "Kantonskürzel": "VD",
    "E": "2532569.3500000015",
    "N": "1186973.8990000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champagne",
    "PLZ": "1424",
    "Zusatzziffer": "0",
    "Gemeindename": "Champagne",
    "BFS-Nr": "5553",
    "Kantonskürzel": "VD",
    "E": "2540452.3880000003",
    "N": "1186891.568",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Concise",
    "PLZ": "1426",
    "Zusatzziffer": "0",
    "Gemeindename": "Concise",
    "BFS-Nr": "5554",
    "Kantonskürzel": "VD",
    "E": "2545204.278000001",
    "N": "1189004.1629999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corcelles-près-Concise",
    "PLZ": "1426",
    "Zusatzziffer": "1",
    "Gemeindename": "Corcelles-près-Concise",
    "BFS-Nr": "5555",
    "Kantonskürzel": "VD",
    "E": "2544234.6050000004",
    "N": "1188701.2989999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fiez",
    "PLZ": "1420",
    "Zusatzziffer": "0",
    "Gemeindename": "Fiez",
    "BFS-Nr": "5556",
    "Kantonskürzel": "VD",
    "E": "2537812.6609999985",
    "N": "1186454.5650000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fontaines-sur-Grandson",
    "PLZ": "1421",
    "Zusatzziffer": "0",
    "Gemeindename": "Fontaines-sur-Grandson",
    "BFS-Nr": "5557",
    "Kantonskürzel": "VD",
    "E": "2537495.541000001",
    "N": "1187459.6030000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Giez",
    "PLZ": "1429",
    "Zusatzziffer": "0",
    "Gemeindename": "Giez",
    "BFS-Nr": "5559",
    "Kantonskürzel": "VD",
    "E": "2537243.734000001",
    "N": "1184598.585000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandevent",
    "PLZ": "1421",
    "Zusatzziffer": "2",
    "Gemeindename": "Grandevent",
    "BFS-Nr": "5560",
    "Kantonskürzel": "VD",
    "E": "2536511.0309999995",
    "N": "1187817.3139999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandson",
    "PLZ": "1422",
    "Zusatzziffer": "0",
    "Gemeindename": "Grandson",
    "BFS-Nr": "5561",
    "Kantonskürzel": "VD",
    "E": "2539316.3489999995",
    "N": "1184438.3990000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mauborget",
    "PLZ": "1453",
    "Zusatzziffer": "2",
    "Gemeindename": "Mauborget",
    "BFS-Nr": "5562",
    "Kantonskürzel": "VD",
    "E": "2537327.9690000005",
    "N": "1189732.6860000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mutrux",
    "PLZ": "1428",
    "Zusatzziffer": "2",
    "Gemeindename": "Mutrux",
    "BFS-Nr": "5563",
    "Kantonskürzel": "VD",
    "E": "2545795.348000001",
    "N": "1192690.3729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Novalles",
    "PLZ": "1431",
    "Zusatzziffer": "2",
    "Gemeindename": "Novalles",
    "BFS-Nr": "5564",
    "Kantonskürzel": "VD",
    "E": "2535697.579",
    "N": "1186708.9580000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Onnens VD",
    "PLZ": "1425",
    "Zusatzziffer": "0",
    "Gemeindename": "Onnens (VD)",
    "BFS-Nr": "5565",
    "Kantonskürzel": "VD",
    "E": "2542595.9530000016",
    "N": "1187763.7809999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Provence",
    "PLZ": "1428",
    "Zusatzziffer": "1",
    "Gemeindename": "Provence",
    "BFS-Nr": "5566",
    "Kantonskürzel": "VD",
    "E": "2545709.329",
    "N": "1193456.7149999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ste-Croix",
    "PLZ": "1450",
    "Zusatzziffer": "0",
    "Gemeindename": "Sainte-Croix",
    "BFS-Nr": "5568",
    "Kantonskürzel": "VD",
    "E": "2528604.037999999",
    "N": "1186215.5700000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Sagne (Ste-Croix)",
    "PLZ": "1450",
    "Zusatzziffer": "2",
    "Gemeindename": "Sainte-Croix",
    "BFS-Nr": "5568",
    "Kantonskürzel": "VD",
    "E": "2528173.3390000015",
    "N": "1185288.3280000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Château-de-Ste-Croix",
    "PLZ": "1450",
    "Zusatzziffer": "3",
    "Gemeindename": "Sainte-Croix",
    "BFS-Nr": "5568",
    "Kantonskürzel": "VD",
    "E": "2530972.9580000006",
    "N": "1185630.2930000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "L'Auberson",
    "PLZ": "1454",
    "Zusatzziffer": "0",
    "Gemeindename": "Sainte-Croix",
    "BFS-Nr": "5568",
    "Kantonskürzel": "VD",
    "E": "2525547.7859999985",
    "N": "1185671.204",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Vraconnaz",
    "PLZ": "1454",
    "Zusatzziffer": "2",
    "Gemeindename": "Sainte-Croix",
    "BFS-Nr": "5568",
    "Kantonskürzel": "VD",
    "E": "2527074.3610000014",
    "N": "1188287.8500000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-Burquin",
    "PLZ": "1423",
    "Zusatzziffer": "0",
    "Gemeindename": "Tévenon",
    "BFS-Nr": "5571",
    "Kantonskürzel": "VD",
    "E": "2537962.6840000004",
    "N": "1188710.9129999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fontanezier",
    "PLZ": "1423",
    "Zusatzziffer": "2",
    "Gemeindename": "Tévenon",
    "BFS-Nr": "5571",
    "Kantonskürzel": "VD",
    "E": "2540113.7639999986",
    "N": "1189491.664999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Romairon",
    "PLZ": "1423",
    "Zusatzziffer": "3",
    "Gemeindename": "Tévenon",
    "BFS-Nr": "5571",
    "Kantonskürzel": "VD",
    "E": "2539238.6259999983",
    "N": "1189155.2890000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vaugondry",
    "PLZ": "1423",
    "Zusatzziffer": "4",
    "Gemeindename": "Tévenon",
    "BFS-Nr": "5571",
    "Kantonskürzel": "VD",
    "E": "2538845.9640000015",
    "N": "1188679.3839999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Belmont-sur-Lausanne",
    "PLZ": "1092",
    "Zusatzziffer": "0",
    "Gemeindename": "Belmont-sur-Lausanne",
    "BFS-Nr": "5581",
    "Kantonskürzel": "VD",
    "E": "2541817.390999999",
    "N": "1152372.5529999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cheseaux-sur-Lausanne",
    "PLZ": "1033",
    "Zusatzziffer": "0",
    "Gemeindename": "Cheseaux-sur-Lausanne",
    "BFS-Nr": "5582",
    "Kantonskürzel": "VD",
    "E": "2536109.3759999983",
    "N": "1159629.870000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Crissier",
    "PLZ": "1023",
    "Zusatzziffer": "0",
    "Gemeindename": "Crissier",
    "BFS-Nr": "5583",
    "Kantonskürzel": "VD",
    "E": "2534060.590999998",
    "N": "1156460.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Epalinges",
    "PLZ": "1066",
    "Zusatzziffer": "0",
    "Gemeindename": "Epalinges",
    "BFS-Nr": "5584",
    "Kantonskürzel": "VD",
    "E": "2541326.120000001",
    "N": "1155249.4090000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Jouxtens-Mézery",
    "PLZ": "1008",
    "Zusatzziffer": "2",
    "Gemeindename": "Jouxtens-Mézery",
    "BFS-Nr": "5585",
    "Kantonskürzel": "VD",
    "E": "2535508.6829999983",
    "N": "1156071.131000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne 25",
    "PLZ": "1000",
    "Zusatzziffer": "25",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2542094.5130000003",
    "N": "1157052.4109999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne 26",
    "PLZ": "1000",
    "Zusatzziffer": "26",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2543067.7490000017",
    "N": "1156403.5009999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne 27",
    "PLZ": "1000",
    "Zusatzziffer": "27",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2541920.75",
    "N": "1154775.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1003",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2537956.3654948957",
    "N": "1152398.7080000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1004",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2537089.399",
    "N": "1153350.0093872643",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1005",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2538907.34",
    "N": "1152372.7959724274",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1006",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2538483.524",
    "N": "1148574.263484513",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1007",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2536343.8125",
    "N": "1149062.2270760012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1010",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2540185.344968058",
    "N": "1154170.2624999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1011",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2538935.8724999987",
    "N": "1153097.696163603",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1012",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2540021.8805",
    "N": "1153074.6545385446",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1015",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2534340.766121664",
    "N": "1152132.5629999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lausanne",
    "PLZ": "1018",
    "Zusatzziffer": "0",
    "Gemeindename": "Lausanne",
    "BFS-Nr": "5586",
    "Kantonskürzel": "VD",
    "E": "2537864.87985753",
    "N": "1154485.0844999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Mont-sur-Lausanne",
    "PLZ": "1052",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Mont-sur-Lausanne",
    "BFS-Nr": "5587",
    "Kantonskürzel": "VD",
    "E": "2538459.7289999984",
    "N": "1156484.1130000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Paudex",
    "PLZ": "1094",
    "Zusatzziffer": "0",
    "Gemeindename": "Paudex",
    "BFS-Nr": "5588",
    "Kantonskürzel": "VD",
    "E": "2541182.647999998",
    "N": "1150804.7809999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prilly",
    "PLZ": "1008",
    "Zusatzziffer": "0",
    "Gemeindename": "Prilly",
    "BFS-Nr": "5589",
    "Kantonskürzel": "VD",
    "E": "2536258.7639999986",
    "N": "1154014.0379999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pully",
    "PLZ": "1009",
    "Zusatzziffer": "0",
    "Gemeindename": "Pully",
    "BFS-Nr": "5590",
    "Kantonskürzel": "VD",
    "E": "2540389.653000001",
    "N": "1151171.3099999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Monts-de-Pully",
    "PLZ": "1068",
    "Zusatzziffer": "0",
    "Gemeindename": "Pully",
    "BFS-Nr": "5590",
    "Kantonskürzel": "VD",
    "E": "2542059",
    "N": "1153932.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Renens VD",
    "PLZ": "1020",
    "Zusatzziffer": "0",
    "Gemeindename": "Renens (VD)",
    "BFS-Nr": "5591",
    "Kantonskürzel": "VD",
    "E": "2534951.2630000003",
    "N": "1154300.6609999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Romanel-sur-Lausanne",
    "PLZ": "1032",
    "Zusatzziffer": "0",
    "Gemeindename": "Romanel-sur-Lausanne",
    "BFS-Nr": "5592",
    "Kantonskürzel": "VD",
    "E": "2536168.8709999993",
    "N": "1157218.9050000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chexbres",
    "PLZ": "1071",
    "Zusatzziffer": "0",
    "Gemeindename": "Chexbres",
    "BFS-Nr": "5601",
    "Kantonskürzel": "VD",
    "E": "2549232.844999999",
    "N": "1148096.0760000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Forel (Lavaux)",
    "PLZ": "1072",
    "Zusatzziffer": "0",
    "Gemeindename": "Forel (Lavaux)",
    "BFS-Nr": "5604",
    "Kantonskürzel": "VD",
    "E": "2548699.037999999",
    "N": "1154649.546",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Croix (Lutry)",
    "PLZ": "1090",
    "Zusatzziffer": "0",
    "Gemeindename": "Lutry",
    "BFS-Nr": "5606",
    "Kantonskürzel": "VD",
    "E": "2543523.3079999983",
    "N": "1151972.4310000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Conversion",
    "PLZ": "1093",
    "Zusatzziffer": "0",
    "Gemeindename": "Lutry",
    "BFS-Nr": "5606",
    "Kantonskürzel": "VD",
    "E": "2542256.778000001",
    "N": "1151790.046",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lutry",
    "PLZ": "1095",
    "Zusatzziffer": "0",
    "Gemeindename": "Lutry",
    "BFS-Nr": "5606",
    "Kantonskürzel": "VD",
    "E": "2542256.649",
    "N": "1150429.7939999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Puidoux",
    "PLZ": "1070",
    "Zusatzziffer": "0",
    "Gemeindename": "Puidoux",
    "BFS-Nr": "5607",
    "Kantonskürzel": "VD",
    "E": "2548369.1680000015",
    "N": "1149394.2419999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rivaz",
    "PLZ": "1071",
    "Zusatzziffer": "1",
    "Gemeindename": "Rivaz",
    "BFS-Nr": "5609",
    "Kantonskürzel": "VD",
    "E": "2549303.6730000004",
    "N": "1147439.938000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Saphorin (Lavaux)",
    "PLZ": "1071",
    "Zusatzziffer": "2",
    "Gemeindename": "Saint-Saphorin (Lavaux)",
    "BFS-Nr": "5610",
    "Kantonskürzel": "VD",
    "E": "2550651.409000002",
    "N": "1147101.6739999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Savigny",
    "PLZ": "1073",
    "Zusatzziffer": "0",
    "Gemeindename": "Savigny",
    "BFS-Nr": "5611",
    "Kantonskürzel": "VD",
    "E": "2545873.9690000005",
    "N": "1154277.2600000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mollie-Margot",
    "PLZ": "1073",
    "Zusatzziffer": "2",
    "Gemeindename": "Savigny",
    "BFS-Nr": "5611",
    "Kantonskürzel": "VD",
    "E": "2547221.853",
    "N": "1156402.4030000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandvaux",
    "PLZ": "1091",
    "Zusatzziffer": "0",
    "Gemeindename": "Bourg-en-Lavaux",
    "BFS-Nr": "5613",
    "Kantonskürzel": "VD",
    "E": "2544539.6689999998",
    "N": "1149410.7199999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aran",
    "PLZ": "1091",
    "Zusatzziffer": "1",
    "Gemeindename": "Bourg-en-Lavaux",
    "BFS-Nr": "5613",
    "Kantonskürzel": "VD",
    "E": "2544090.1790000014",
    "N": "1149893.1490000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chenaux",
    "PLZ": "1091",
    "Zusatzziffer": "3",
    "Gemeindename": "Bourg-en-Lavaux",
    "BFS-Nr": "5613",
    "Kantonskürzel": "VD",
    "E": "2545501.7410000004",
    "N": "1149519.8139999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cully",
    "PLZ": "1096",
    "Zusatzziffer": "0",
    "Gemeindename": "Bourg-en-Lavaux",
    "BFS-Nr": "5613",
    "Kantonskürzel": "VD",
    "E": "2545544.348000001",
    "N": "1148848.4549999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villette (Lavaux)",
    "PLZ": "1096",
    "Zusatzziffer": "2",
    "Gemeindename": "Bourg-en-Lavaux",
    "BFS-Nr": "5613",
    "Kantonskürzel": "VD",
    "E": "2544101.405000001",
    "N": "1149415.484000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Riex",
    "PLZ": "1097",
    "Zusatzziffer": "0",
    "Gemeindename": "Bourg-en-Lavaux",
    "BFS-Nr": "5613",
    "Kantonskürzel": "VD",
    "E": "2546031.5830000006",
    "N": "1149353.6510000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Epesses",
    "PLZ": "1098",
    "Zusatzziffer": "0",
    "Gemeindename": "Bourg-en-Lavaux",
    "BFS-Nr": "5613",
    "Kantonskürzel": "VD",
    "E": "2546989.4910000004",
    "N": "1149101.6290000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aclens",
    "PLZ": "1123",
    "Zusatzziffer": "0",
    "Gemeindename": "Aclens",
    "BFS-Nr": "5621",
    "Kantonskürzel": "VD",
    "E": "2528791.2129999995",
    "N": "1157797.857999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bremblens",
    "PLZ": "1121",
    "Zusatzziffer": "0",
    "Gemeindename": "Bremblens",
    "BFS-Nr": "5622",
    "Kantonskürzel": "VD",
    "E": "2529366.662999999",
    "N": "1155460.1490000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Buchillon",
    "PLZ": "1164",
    "Zusatzziffer": "0",
    "Gemeindename": "Buchillon",
    "BFS-Nr": "5623",
    "Kantonskürzel": "VD",
    "E": "2521858.5040000007",
    "N": "1147045.5960000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bussigny",
    "PLZ": "1030",
    "Zusatzziffer": "0",
    "Gemeindename": "Bussigny",
    "BFS-Nr": "5624",
    "Kantonskürzel": "VD",
    "E": "2531932.868999999",
    "N": "1156150.454",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-près-Renens",
    "PLZ": "1022",
    "Zusatzziffer": "0",
    "Gemeindename": "Chavannes-près-Renens",
    "BFS-Nr": "5627",
    "Kantonskürzel": "VD",
    "E": "2533850.1130000018",
    "N": "1154203.0100000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chigny",
    "PLZ": "1134",
    "Zusatzziffer": "2",
    "Gemeindename": "Chigny",
    "BFS-Nr": "5628",
    "Kantonskürzel": "VD",
    "E": "2526211.1750000007",
    "N": "1152540.6999999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Clarmont",
    "PLZ": "1127",
    "Zusatzziffer": "0",
    "Gemeindename": "Clarmont",
    "BFS-Nr": "5629",
    "Kantonskürzel": "VD",
    "E": "2524196.9690000005",
    "N": "1155586.9409999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Denens",
    "PLZ": "1135",
    "Zusatzziffer": "0",
    "Gemeindename": "Denens",
    "BFS-Nr": "5631",
    "Kantonskürzel": "VD",
    "E": "2524657.2309999987",
    "N": "1152439.2800000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Denges",
    "PLZ": "1026",
    "Zusatzziffer": "2",
    "Gemeindename": "Denges",
    "BFS-Nr": "5632",
    "Kantonskürzel": "VD",
    "E": "2530967.1860000007",
    "N": "1152849.1750000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Echandens",
    "PLZ": "1026",
    "Zusatzziffer": "3",
    "Gemeindename": "Echandens",
    "BFS-Nr": "5633",
    "Kantonskürzel": "VD",
    "E": "2531243.6609999985",
    "N": "1153896.0650000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Echichens",
    "PLZ": "1112",
    "Zusatzziffer": "0",
    "Gemeindename": "Echichens",
    "BFS-Nr": "5634",
    "Kantonskürzel": "VD",
    "E": "2527876.978",
    "N": "1153179.6030000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Saphorin-sur-Morges",
    "PLZ": "1113",
    "Zusatzziffer": "0",
    "Gemeindename": "Echichens",
    "BFS-Nr": "5634",
    "Kantonskürzel": "VD",
    "E": "2527052.9899999984",
    "N": "1155364.9299999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Colombier VD",
    "PLZ": "1114",
    "Zusatzziffer": "0",
    "Gemeindename": "Echichens",
    "BFS-Nr": "5634",
    "Kantonskürzel": "VD",
    "E": "2526145.8000000007",
    "N": "1156596.3559999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Monnaz",
    "PLZ": "1125",
    "Zusatzziffer": "0",
    "Gemeindename": "Echichens",
    "BFS-Nr": "5634",
    "Kantonskürzel": "VD",
    "E": "2526370.0929999985",
    "N": "1153472.8029999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ecublens VD",
    "PLZ": "1024",
    "Zusatzziffer": "0",
    "Gemeindename": "Ecublens (VD)",
    "BFS-Nr": "5635",
    "Kantonskürzel": "VD",
    "E": "2532739.188000001",
    "N": "1153135.6579999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Etoy",
    "PLZ": "1163",
    "Zusatzziffer": "0",
    "Gemeindename": "Etoy",
    "BFS-Nr": "5636",
    "Kantonskürzel": "VD",
    "E": "2521829.905000001",
    "N": "1148820.8049999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lavigny",
    "PLZ": "1175",
    "Zusatzziffer": "0",
    "Gemeindename": "Lavigny",
    "BFS-Nr": "5637",
    "Kantonskürzel": "VD",
    "E": "2520628.7289999984",
    "N": "1150437.6840000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lonay",
    "PLZ": "1027",
    "Zusatzziffer": "0",
    "Gemeindename": "Lonay",
    "BFS-Nr": "5638",
    "Kantonskürzel": "VD",
    "E": "2529583.7699999996",
    "N": "1153210.620000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lully VD",
    "PLZ": "1132",
    "Zusatzziffer": "0",
    "Gemeindename": "Lully (VD)",
    "BFS-Nr": "5639",
    "Kantonskürzel": "VD",
    "E": "2525287.91",
    "N": "1150974.364",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lussy-sur-Morges",
    "PLZ": "1167",
    "Zusatzziffer": "0",
    "Gemeindename": "Lussy-sur-Morges",
    "BFS-Nr": "5640",
    "Kantonskürzel": "VD",
    "E": "2524162.1449999996",
    "N": "1150741.7890000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Morges",
    "PLZ": "1110",
    "Zusatzziffer": "0",
    "Gemeindename": "Morges",
    "BFS-Nr": "5642",
    "Kantonskürzel": "VD",
    "E": "2527960.129999999",
    "N": "1151479.3630000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Préverenges",
    "PLZ": "1028",
    "Zusatzziffer": "0",
    "Gemeindename": "Préverenges",
    "BFS-Nr": "5643",
    "Kantonskürzel": "VD",
    "E": "2530038.579999998",
    "N": "1152307.0379999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Romanel-sur-Morges",
    "PLZ": "1122",
    "Zusatzziffer": "0",
    "Gemeindename": "Romanel-sur-Morges",
    "BFS-Nr": "5645",
    "Kantonskürzel": "VD",
    "E": "2528913.6460000016",
    "N": "1156452.2080000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Prex",
    "PLZ": "1162",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Prex",
    "BFS-Nr": "5646",
    "Kantonskürzel": "VD",
    "E": "2524715.2699999996",
    "N": "1148250.993999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Sulpice VD",
    "PLZ": "1025",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Sulpice (VD)",
    "BFS-Nr": "5648",
    "Kantonskürzel": "VD",
    "E": "2532560.004999999",
    "N": "1151237.4079999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Tolochenaz",
    "PLZ": "1131",
    "Zusatzziffer": "0",
    "Gemeindename": "Tolochenaz",
    "BFS-Nr": "5649",
    "Kantonskürzel": "VD",
    "E": "2525949.0029999986",
    "N": "1150968.4189999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vaux-sur-Morges",
    "PLZ": "1126",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaux-sur-Morges",
    "BFS-Nr": "5650",
    "Kantonskürzel": "VD",
    "E": "2525412.3249999993",
    "N": "1154379.7210000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-Ste-Croix",
    "PLZ": "1029",
    "Zusatzziffer": "0",
    "Gemeindename": "Villars-Sainte-Croix",
    "BFS-Nr": "5651",
    "Kantonskürzel": "VD",
    "E": "2532683.285",
    "N": "1157622.9340000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-sous-Yens",
    "PLZ": "1168",
    "Zusatzziffer": "0",
    "Gemeindename": "Villars-sous-Yens",
    "BFS-Nr": "5652",
    "Kantonskürzel": "VD",
    "E": "2522481.4530000016",
    "N": "1151510.5430000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vufflens-le-Château",
    "PLZ": "1134",
    "Zusatzziffer": "0",
    "Gemeindename": "Vufflens-le-Château",
    "BFS-Nr": "5653",
    "Kantonskürzel": "VD",
    "E": "2525749.7399999984",
    "N": "1153294.4039999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vullierens",
    "PLZ": "1115",
    "Zusatzziffer": "0",
    "Gemeindename": "Vullierens",
    "BFS-Nr": "5654",
    "Kantonskürzel": "VD",
    "E": "2526605.7430000007",
    "N": "1158550.3029999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Yens",
    "PLZ": "1169",
    "Zusatzziffer": "0",
    "Gemeindename": "Yens",
    "BFS-Nr": "5655",
    "Kantonskürzel": "VD",
    "E": "2521614.4459999986",
    "N": "1152433.2650000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cottens VD",
    "PLZ": "1116",
    "Zusatzziffer": "0",
    "Gemeindename": "Hautemorges",
    "BFS-Nr": "5656",
    "Kantonskürzel": "VD",
    "E": "2524552.4499999993",
    "N": "1158398.4239999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Reverolle",
    "PLZ": "1128",
    "Zusatzziffer": "0",
    "Gemeindename": "Hautemorges",
    "BFS-Nr": "5656",
    "Kantonskürzel": "VD",
    "E": "2523378.5560000017",
    "N": "1155060.4789999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bussy-Chardonney",
    "PLZ": "1136",
    "Zusatzziffer": "0",
    "Gemeindename": "Hautemorges",
    "BFS-Nr": "5656",
    "Kantonskürzel": "VD",
    "E": "2523584.726",
    "N": "1153580.3310000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sévery",
    "PLZ": "1141",
    "Zusatzziffer": "0",
    "Gemeindename": "Hautemorges",
    "BFS-Nr": "5656",
    "Kantonskürzel": "VD",
    "E": "2523296.3880000003",
    "N": "1158781.824000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pampigny",
    "PLZ": "1142",
    "Zusatzziffer": "0",
    "Gemeindename": "Hautemorges",
    "BFS-Nr": "5656",
    "Kantonskürzel": "VD",
    "E": "2522606.9910000004",
    "N": "1159429.2809999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Apples",
    "PLZ": "1143",
    "Zusatzziffer": "0",
    "Gemeindename": "Hautemorges",
    "BFS-Nr": "5656",
    "Kantonskürzel": "VD",
    "E": "2522542.403999999",
    "N": "1156163.2300000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Boulens",
    "PLZ": "1063",
    "Zusatzziffer": "3",
    "Gemeindename": "Boulens",
    "BFS-Nr": "5661",
    "Kantonskürzel": "VD",
    "E": "2544943.300999999",
    "N": "1170326.2349999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bussy-sur-Moudon",
    "PLZ": "1514",
    "Zusatzziffer": "0",
    "Gemeindename": "Bussy-sur-Moudon",
    "BFS-Nr": "5663",
    "Kantonskürzel": "VD",
    "E": "2551995.625",
    "N": "1170806.4180000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-sur-Moudon",
    "PLZ": "1512",
    "Zusatzziffer": "0",
    "Gemeindename": "Chavannes-sur-Moudon",
    "BFS-Nr": "5665",
    "Kantonskürzel": "VD",
    "E": "2551757.2140000015",
    "N": "1167514.193",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Curtilles",
    "PLZ": "1521",
    "Zusatzziffer": "0",
    "Gemeindename": "Curtilles",
    "BFS-Nr": "5669",
    "Kantonskürzel": "VD",
    "E": "2554798.8040000014",
    "N": "1172556.1130000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dompierre VD",
    "PLZ": "1682",
    "Zusatzziffer": "3",
    "Gemeindename": "Dompierre (VD)",
    "BFS-Nr": "5671",
    "Kantonskürzel": "VD",
    "E": "2557475.3830000013",
    "N": "1173174.4990000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Hermenches",
    "PLZ": "1513",
    "Zusatzziffer": "0",
    "Gemeindename": "Hermenches",
    "BFS-Nr": "5673",
    "Kantonskürzel": "VD",
    "E": "2548056.4679999985",
    "N": "1165854.3110000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lovatens",
    "PLZ": "1682",
    "Zusatzziffer": "2",
    "Gemeindename": "Lovatens",
    "BFS-Nr": "5674",
    "Kantonskürzel": "VD",
    "E": "2556129.9860000014",
    "N": "1171510.7650000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lucens",
    "PLZ": "1522",
    "Zusatzziffer": "0",
    "Gemeindename": "Lucens",
    "BFS-Nr": "5675",
    "Kantonskürzel": "VD",
    "E": "2554070.6609999985",
    "N": "1173411.4649999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Oulens-sur-Lucens",
    "PLZ": "1522",
    "Zusatzziffer": "9",
    "Gemeindename": "Lucens",
    "BFS-Nr": "5675",
    "Kantonskürzel": "VD",
    "E": "2551781.390999999",
    "N": "1172771.5280000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Forel-sur-Lucens",
    "PLZ": "1526",
    "Zusatzziffer": "0",
    "Gemeindename": "Lucens",
    "BFS-Nr": "5675",
    "Kantonskürzel": "VD",
    "E": "2552939.75",
    "N": "1175491.1889999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cremin",
    "PLZ": "1526",
    "Zusatzziffer": "2",
    "Gemeindename": "Lucens",
    "BFS-Nr": "5675",
    "Kantonskürzel": "VD",
    "E": "2554427.215",
    "N": "1174945.4290000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brenles",
    "PLZ": "1683",
    "Zusatzziffer": "0",
    "Gemeindename": "Lucens",
    "BFS-Nr": "5675",
    "Kantonskürzel": "VD",
    "E": "2555277.4409999996",
    "N": "1169047.949000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chesalles-sur-Moudon",
    "PLZ": "1683",
    "Zusatzziffer": "2",
    "Gemeindename": "Lucens",
    "BFS-Nr": "5675",
    "Kantonskürzel": "VD",
    "E": "2553654.068",
    "N": "1169507.5190000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sarzens",
    "PLZ": "1683",
    "Zusatzziffer": "3",
    "Gemeindename": "Lucens",
    "BFS-Nr": "5675",
    "Kantonskürzel": "VD",
    "E": "2554973.6460000016",
    "N": "1170286.9589999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Moudon",
    "PLZ": "1510",
    "Zusatzziffer": "0",
    "Gemeindename": "Moudon",
    "BFS-Nr": "5678",
    "Kantonskürzel": "VD",
    "E": "2551060.6180000007",
    "N": "1168747.697999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ogens",
    "PLZ": "1045",
    "Zusatzziffer": "0",
    "Gemeindename": "Ogens",
    "BFS-Nr": "5680",
    "Kantonskürzel": "VD",
    "E": "2545202.9499999993",
    "N": "1173567.688000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prévonloup",
    "PLZ": "1682",
    "Zusatzziffer": "0",
    "Gemeindename": "Prévonloup",
    "BFS-Nr": "5683",
    "Kantonskürzel": "VD",
    "E": "2557391.818",
    "N": "1172214.954",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rossenges",
    "PLZ": "1513",
    "Zusatzziffer": "2",
    "Gemeindename": "Rossenges",
    "BFS-Nr": "5684",
    "Kantonskürzel": "VD",
    "E": "2549044.550999999",
    "N": "1167159.7630000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Syens",
    "PLZ": "1510",
    "Zusatzziffer": "2",
    "Gemeindename": "Syens",
    "BFS-Nr": "5688",
    "Kantonskürzel": "VD",
    "E": "2549481.469999999",
    "N": "1166301.1400000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-le-Comte",
    "PLZ": "1515",
    "Zusatzziffer": "0",
    "Gemeindename": "Villars-le-Comte",
    "BFS-Nr": "5690",
    "Kantonskürzel": "VD",
    "E": "2551136.9349999987",
    "N": "1173470.0340000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vucherens",
    "PLZ": "1509",
    "Zusatzziffer": "0",
    "Gemeindename": "Vucherens",
    "BFS-Nr": "5692",
    "Kantonskürzel": "VD",
    "E": "2549089.4360000007",
    "N": "1163691.8339999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chapelle-sur-Moudon",
    "PLZ": "1063",
    "Zusatzziffer": "0",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2546143.670000002",
    "N": "1168984.131000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Martherenges",
    "PLZ": "1063",
    "Zusatzziffer": "2",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2547541.4250000007",
    "N": "1167991.9880000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Peyres-Possens",
    "PLZ": "1063",
    "Zusatzziffer": "4",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2543997.75",
    "N": "1168273.5489999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chanéaz",
    "PLZ": "1409",
    "Zusatzziffer": "0",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2546806.7580000013",
    "N": "1175620.118999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Thierrens",
    "PLZ": "1410",
    "Zusatzziffer": "0",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2547811.574000001",
    "N": "1172652.3460000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Correvon",
    "PLZ": "1410",
    "Zusatzziffer": "2",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2546370.8660000004",
    "N": "1174094.1889999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Denezy",
    "PLZ": "1410",
    "Zusatzziffer": "3",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2550006.3940000013",
    "N": "1174492.4279999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Cierges",
    "PLZ": "1410",
    "Zusatzziffer": "5",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2545978.335999999",
    "N": "1171151.171",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Neyruz-sur-Moudon",
    "PLZ": "1515",
    "Zusatzziffer": "1",
    "Gemeindename": "Montanaire",
    "BFS-Nr": "5693",
    "Kantonskürzel": "VD",
    "E": "2550174.721000001",
    "N": "1172053.6380000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arnex-sur-Nyon",
    "PLZ": "1277",
    "Zusatzziffer": "1",
    "Gemeindename": "Arnex-sur-Nyon",
    "BFS-Nr": "5701",
    "Kantonskürzel": "VD",
    "E": "2504044.011",
    "N": "1136759.9759999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arzier-Le Muids",
    "PLZ": "1273",
    "Zusatzziffer": "0",
    "Gemeindename": "Arzier-Le Muids",
    "BFS-Nr": "5702",
    "Kantonskürzel": "VD",
    "E": "2505414.943",
    "N": "1146209.2179999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bassins",
    "PLZ": "1269",
    "Zusatzziffer": "0",
    "Gemeindename": "Bassins",
    "BFS-Nr": "5703",
    "Kantonskürzel": "VD",
    "E": "2507272.1209999993",
    "N": "1146360.0329999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Begnins",
    "PLZ": "1268",
    "Zusatzziffer": "0",
    "Gemeindename": "Begnins",
    "BFS-Nr": "5704",
    "Kantonskürzel": "VD",
    "E": "2508774.1689999998",
    "N": "1143964.1959999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bogis-Bossey",
    "PLZ": "1279",
    "Zusatzziffer": "2",
    "Gemeindename": "Bogis-Bossey",
    "BFS-Nr": "5705",
    "Kantonskürzel": "VD",
    "E": "2502065.8949999996",
    "N": "1134401.0249999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Borex",
    "PLZ": "1277",
    "Zusatzziffer": "0",
    "Gemeindename": "Borex",
    "BFS-Nr": "5706",
    "Kantonskürzel": "VD",
    "E": "2502983.2699999996",
    "N": "1137150.2210000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-de-Bogis",
    "PLZ": "1279",
    "Zusatzziffer": "0",
    "Gemeindename": "Chavannes-de-Bogis",
    "BFS-Nr": "5707",
    "Kantonskürzel": "VD",
    "E": "2501744.7509999983",
    "N": "1133354.8339999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-des-Bois",
    "PLZ": "1290",
    "Zusatzziffer": "2",
    "Gemeindename": "Chavannes-des-Bois",
    "BFS-Nr": "5708",
    "Kantonskürzel": "VD",
    "E": "2499494.5599999987",
    "N": "1130234.5240000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chéserex",
    "PLZ": "1275",
    "Zusatzziffer": "0",
    "Gemeindename": "Chéserex",
    "BFS-Nr": "5709",
    "Kantonskürzel": "VD",
    "E": "2502826.16",
    "N": "1139602.7380000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Coinsins",
    "PLZ": "1267",
    "Zusatzziffer": "2",
    "Gemeindename": "Coinsins",
    "BFS-Nr": "5710",
    "Kantonskürzel": "VD",
    "E": "2507812.478",
    "N": "1142068.800999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Commugny",
    "PLZ": "1291",
    "Zusatzziffer": "0",
    "Gemeindename": "Commugny",
    "BFS-Nr": "5711",
    "Kantonskürzel": "VD",
    "E": "2503093.3079999983",
    "N": "1130642.3460000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Coppet",
    "PLZ": "1296",
    "Zusatzziffer": "0",
    "Gemeindename": "Coppet",
    "BFS-Nr": "5712",
    "Kantonskürzel": "VD",
    "E": "2504063.699000001",
    "N": "1130149.3680000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Crans VD",
    "PLZ": "1299",
    "Zusatzziffer": "0",
    "Gemeindename": "Crans (VD)",
    "BFS-Nr": "5713",
    "Kantonskürzel": "VD",
    "E": "2505155.886",
    "N": "1134581.8740000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Crassier",
    "PLZ": "1263",
    "Zusatzziffer": "0",
    "Gemeindename": "Crassier",
    "BFS-Nr": "5714",
    "Kantonskürzel": "VD",
    "E": "2501912.2639999986",
    "N": "1136676.6389999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Duillier",
    "PLZ": "1266",
    "Zusatzziffer": "0",
    "Gemeindename": "Duillier",
    "BFS-Nr": "5715",
    "Kantonskürzel": "VD",
    "E": "2507299.3390000015",
    "N": "1140448.761",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Eysins",
    "PLZ": "1262",
    "Zusatzziffer": "0",
    "Gemeindename": "Eysins",
    "BFS-Nr": "5716",
    "Kantonskürzel": "VD",
    "E": "2505068.761",
    "N": "1137185.697999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Founex",
    "PLZ": "1297",
    "Zusatzziffer": "0",
    "Gemeindename": "Founex",
    "BFS-Nr": "5717",
    "Kantonskürzel": "VD",
    "E": "2504065.8310000002",
    "N": "1132044.9180000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genolier",
    "PLZ": "1272",
    "Zusatzziffer": "0",
    "Gemeindename": "Genolier",
    "BFS-Nr": "5718",
    "Kantonskürzel": "VD",
    "E": "2506181.870000001",
    "N": "1143322.1209999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gingins",
    "PLZ": "1276",
    "Zusatzziffer": "0",
    "Gemeindename": "Gingins",
    "BFS-Nr": "5719",
    "Kantonskürzel": "VD",
    "E": "2503199.3079999983",
    "N": "1140570.5340000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Givrins",
    "PLZ": "1271",
    "Zusatzziffer": "0",
    "Gemeindename": "Givrins",
    "BFS-Nr": "5720",
    "Kantonskürzel": "VD",
    "E": "2504957.774",
    "N": "1142741.3839999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gland",
    "PLZ": "1196",
    "Zusatzziffer": "0",
    "Gemeindename": "Gland",
    "BFS-Nr": "5721",
    "Kantonskürzel": "VD",
    "E": "2509797.8559999987",
    "N": "1142047.982999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grens",
    "PLZ": "1274",
    "Zusatzziffer": "3",
    "Gemeindename": "Grens",
    "BFS-Nr": "5722",
    "Kantonskürzel": "VD",
    "E": "2504124.8729999997",
    "N": "1138939.7789999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mies",
    "PLZ": "1295",
    "Zusatzziffer": "1",
    "Gemeindename": "Mies",
    "BFS-Nr": "5723",
    "Kantonskürzel": "VD",
    "E": "2502337.6889999993",
    "N": "1129012.5100000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Nyon",
    "PLZ": "1260",
    "Zusatzziffer": "0",
    "Gemeindename": "Nyon",
    "BFS-Nr": "5724",
    "Kantonskürzel": "VD",
    "E": "2507633.335000001",
    "N": "1137313.5830000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prangins",
    "PLZ": "1197",
    "Zusatzziffer": "0",
    "Gemeindename": "Prangins",
    "BFS-Nr": "5725",
    "Kantonskürzel": "VD",
    "E": "2508568.1829999983",
    "N": "1138823.8339999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Rippe",
    "PLZ": "1278",
    "Zusatzziffer": "0",
    "Gemeindename": "La Rippe",
    "BFS-Nr": "5726",
    "Kantonskürzel": "VD",
    "E": "2500885.3550000004",
    "N": "1137517.807",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Cergue",
    "PLZ": "1264",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Cergue",
    "BFS-Nr": "5727",
    "Kantonskürzel": "VD",
    "E": "2501661.9560000002",
    "N": "1144871.6479999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Cure",
    "PLZ": "1265",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Cergue",
    "BFS-Nr": "5727",
    "Kantonskürzel": "VD",
    "E": "2495288.9809999987",
    "N": "1146728.6889999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Signy",
    "PLZ": "1274",
    "Zusatzziffer": "1",
    "Gemeindename": "Signy-Avenex",
    "BFS-Nr": "5728",
    "Kantonskürzel": "VD",
    "E": "2504889",
    "N": "1138492.063000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Tannay",
    "PLZ": "1295",
    "Zusatzziffer": "2",
    "Gemeindename": "Tannay",
    "BFS-Nr": "5729",
    "Kantonskürzel": "VD",
    "E": "2502752.2639999986",
    "N": "1129385.6889999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Trélex",
    "PLZ": "1270",
    "Zusatzziffer": "0",
    "Gemeindename": "Trélex",
    "BFS-Nr": "5730",
    "Kantonskürzel": "VD",
    "E": "2505070.017999999",
    "N": "1140955.6840000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Vaud",
    "PLZ": "1261",
    "Zusatzziffer": "33",
    "Gemeindename": "Le Vaud",
    "BFS-Nr": "5731",
    "Kantonskürzel": "VD",
    "E": "2507535.2410000004",
    "N": "1148041.7989999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vich",
    "PLZ": "1267",
    "Zusatzziffer": "1",
    "Gemeindename": "Vich",
    "BFS-Nr": "5732",
    "Kantonskürzel": "VD",
    "E": "2508612.0439999998",
    "N": "1142692.5289999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "L'Abergement",
    "PLZ": "1355",
    "Zusatzziffer": "0",
    "Gemeindename": "L'Abergement",
    "BFS-Nr": "5741",
    "Kantonskürzel": "VD",
    "E": "2527476.4189999998",
    "N": "1178573.3889999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Agiez",
    "PLZ": "1352",
    "Zusatzziffer": "0",
    "Gemeindename": "Agiez",
    "BFS-Nr": "5742",
    "Kantonskürzel": "VD",
    "E": "2528756.897999998",
    "N": "1174540.8359999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arnex-sur-Orbe",
    "PLZ": "1321",
    "Zusatzziffer": "0",
    "Gemeindename": "Arnex-sur-Orbe",
    "BFS-Nr": "5743",
    "Kantonskürzel": "VD",
    "E": "2529672.101",
    "N": "1171749.732999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ballaigues",
    "PLZ": "1338",
    "Zusatzziffer": "0",
    "Gemeindename": "Ballaigues",
    "BFS-Nr": "5744",
    "Kantonskürzel": "VD",
    "E": "2521736.0890000015",
    "N": "1175850.693",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Baulmes",
    "PLZ": "1446",
    "Zusatzziffer": "0",
    "Gemeindename": "Baulmes",
    "BFS-Nr": "5745",
    "Kantonskürzel": "VD",
    "E": "2529728.6750000007",
    "N": "1182609.3460000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bavois",
    "PLZ": "1372",
    "Zusatzziffer": "0",
    "Gemeindename": "Bavois",
    "BFS-Nr": "5746",
    "Kantonskürzel": "VD",
    "E": "2533213.153999999",
    "N": "1170831.8759999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bofflens",
    "PLZ": "1353",
    "Zusatzziffer": "0",
    "Gemeindename": "Bofflens",
    "BFS-Nr": "5747",
    "Kantonskürzel": "VD",
    "E": "2528132.7360000014",
    "N": "1172863.5009999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bretonnières",
    "PLZ": "1329",
    "Zusatzziffer": "0",
    "Gemeindename": "Bretonnières",
    "BFS-Nr": "5748",
    "Kantonskürzel": "VD",
    "E": "2526332.754999999",
    "N": "1173889.3709999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavornay",
    "PLZ": "1373",
    "Zusatzziffer": "0",
    "Gemeindename": "Chavornay",
    "BFS-Nr": "5749",
    "Kantonskürzel": "VD",
    "E": "2533609.0309999995",
    "N": "1172615.4959999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corcelles-sur-Chavornay",
    "PLZ": "1374",
    "Zusatzziffer": "0",
    "Gemeindename": "Chavornay",
    "BFS-Nr": "5749",
    "Kantonskürzel": "VD",
    "E": "2535868.666000001",
    "N": "1172848.261",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Essert-Pittet",
    "PLZ": "1435",
    "Zusatzziffer": "0",
    "Gemeindename": "Chavornay",
    "BFS-Nr": "5749",
    "Kantonskürzel": "VD",
    "E": "2534660.1000000015",
    "N": "1175533.1229999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Clées",
    "PLZ": "1356",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Clées",
    "BFS-Nr": "5750",
    "Kantonskürzel": "VD",
    "E": "2525408.5500000007",
    "N": "1176058.9129999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Russille",
    "PLZ": "1356",
    "Zusatzziffer": "2",
    "Gemeindename": "Les Clées",
    "BFS-Nr": "5750",
    "Kantonskürzel": "VD",
    "E": "2526621.6279999986",
    "N": "1176542.2509999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Croy",
    "PLZ": "1322",
    "Zusatzziffer": "0",
    "Gemeindename": "Croy",
    "BFS-Nr": "5752",
    "Kantonskürzel": "VD",
    "E": "2526425.329999998",
    "N": "1171909.0260000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Juriens",
    "PLZ": "1326",
    "Zusatzziffer": "0",
    "Gemeindename": "Juriens",
    "BFS-Nr": "5754",
    "Kantonskürzel": "VD",
    "E": "2524294.1900000013",
    "N": "1171598.7850000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lignerolle",
    "PLZ": "1357",
    "Zusatzziffer": "0",
    "Gemeindename": "Lignerolle",
    "BFS-Nr": "5755",
    "Kantonskürzel": "VD",
    "E": "2524933.6999999993",
    "N": "1177016.1849999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montcherand",
    "PLZ": "1354",
    "Zusatzziffer": "0",
    "Gemeindename": "Montcherand",
    "BFS-Nr": "5756",
    "Kantonskürzel": "VD",
    "E": "2529068.9109999985",
    "N": "1176138.800999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Orbe",
    "PLZ": "1350",
    "Zusatzziffer": "0",
    "Gemeindename": "Orbe",
    "BFS-Nr": "5757",
    "Kantonskürzel": "VD",
    "E": "2530767.346000001",
    "N": "1175223.0799999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Praz",
    "PLZ": "1148",
    "Zusatzziffer": "6",
    "Gemeindename": "La Praz",
    "BFS-Nr": "5758",
    "Kantonskürzel": "VD",
    "E": "2522590.535",
    "N": "1168999.609000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Premier",
    "PLZ": "1324",
    "Zusatzziffer": "0",
    "Gemeindename": "Premier",
    "BFS-Nr": "5759",
    "Kantonskürzel": "VD",
    "E": "2524031.3559999987",
    "N": "1173066.4530000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rances",
    "PLZ": "1439",
    "Zusatzziffer": "0",
    "Gemeindename": "Rances",
    "BFS-Nr": "5760",
    "Kantonskürzel": "VD",
    "E": "2530657.090999998",
    "N": "1179292.8090000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Romainmôtier",
    "PLZ": "1323",
    "Zusatzziffer": "0",
    "Gemeindename": "Romainmôtier-Envy",
    "BFS-Nr": "5761",
    "Kantonskürzel": "VD",
    "E": "2525255.530000001",
    "N": "1171830.489",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sergey",
    "PLZ": "1355",
    "Zusatzziffer": "2",
    "Gemeindename": "Sergey",
    "BFS-Nr": "5762",
    "Kantonskürzel": "VD",
    "E": "2528258.8999999985",
    "N": "1178158.4290000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Valeyres-sous-Rances",
    "PLZ": "1358",
    "Zusatzziffer": "0",
    "Gemeindename": "Valeyres-sous-Rances",
    "BFS-Nr": "5763",
    "Kantonskürzel": "VD",
    "E": "2529759.6279999986",
    "N": "1178271.9930000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vallorbe",
    "PLZ": "1337",
    "Zusatzziffer": "0",
    "Gemeindename": "Vallorbe",
    "BFS-Nr": "5764",
    "Kantonskürzel": "VD",
    "E": "2519058.1000000015",
    "N": "1174069.2360000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vaulion",
    "PLZ": "1325",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaulion",
    "BFS-Nr": "5765",
    "Kantonskürzel": "VD",
    "E": "2519814.4310000017",
    "N": "1171426.6030000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuiteboeuf",
    "PLZ": "1445",
    "Zusatzziffer": "0",
    "Gemeindename": "Vuiteboeuf",
    "BFS-Nr": "5766",
    "Kantonskürzel": "VD",
    "E": "2532161.2589999996",
    "N": "1184376.511",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corcelles-le-Jorat",
    "PLZ": "1082",
    "Zusatzziffer": "0",
    "Gemeindename": "Corcelles-le-Jorat",
    "BFS-Nr": "5785",
    "Kantonskürzel": "VD",
    "E": "2546591.199000001",
    "N": "1161929.4759999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Essertes",
    "PLZ": "1078",
    "Zusatzziffer": "0",
    "Gemeindename": "Essertes",
    "BFS-Nr": "5788",
    "Kantonskürzel": "VD",
    "E": "2550198.670000002",
    "N": "1157002.2380000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Maracon",
    "PLZ": "1613",
    "Zusatzziffer": "0",
    "Gemeindename": "Maracon",
    "BFS-Nr": "5790",
    "Kantonskürzel": "VD",
    "E": "2556466.3550000004",
    "N": "1155533.9100000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montpreveyres",
    "PLZ": "1081",
    "Zusatzziffer": "0",
    "Gemeindename": "Montpreveyres",
    "BFS-Nr": "5792",
    "Kantonskürzel": "VD",
    "E": "2547080.1130000018",
    "N": "1159452.4400000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ropraz",
    "PLZ": "1088",
    "Zusatzziffer": "0",
    "Gemeindename": "Ropraz",
    "BFS-Nr": "5798",
    "Kantonskürzel": "VD",
    "E": "2547376.9409999996",
    "N": "1162864.6849999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Servion",
    "PLZ": "1077",
    "Zusatzziffer": "0",
    "Gemeindename": "Servion",
    "BFS-Nr": "5799",
    "Kantonskürzel": "VD",
    "E": "2549922.34",
    "N": "1158743.6279999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Cullayes",
    "PLZ": "1080",
    "Zusatzziffer": "0",
    "Gemeindename": "Servion",
    "BFS-Nr": "5799",
    "Kantonskürzel": "VD",
    "E": "2547320.1389999986",
    "N": "1158225.511",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vulliens",
    "PLZ": "1085",
    "Zusatzziffer": "0",
    "Gemeindename": "Vulliens",
    "BFS-Nr": "5803",
    "Kantonskürzel": "VD",
    "E": "2550831.835999999",
    "N": "1163581.6730000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montaubion-Chardonney",
    "PLZ": "1041",
    "Zusatzziffer": "22",
    "Gemeindename": "Jorat-Menthue",
    "BFS-Nr": "5804",
    "Kantonskürzel": "VD",
    "E": "2544549.761",
    "N": "1166460.8779999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-Tiercelin",
    "PLZ": "1058",
    "Zusatzziffer": "0",
    "Gemeindename": "Jorat-Menthue",
    "BFS-Nr": "5804",
    "Kantonskürzel": "VD",
    "E": "2543771.5439999998",
    "N": "1164235.2690000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Peney-le-Jorat",
    "PLZ": "1059",
    "Zusatzziffer": "0",
    "Gemeindename": "Jorat-Menthue",
    "BFS-Nr": "5804",
    "Kantonskürzel": "VD",
    "E": "2545552.3539999984",
    "N": "1164993.2289999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-Mendraz",
    "PLZ": "1061",
    "Zusatzziffer": "0",
    "Gemeindename": "Jorat-Menthue",
    "BFS-Nr": "5804",
    "Kantonskürzel": "VD",
    "E": "2545701.7380000018",
    "N": "1166479.8530000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sottens",
    "PLZ": "1062",
    "Zusatzziffer": "0",
    "Gemeindename": "Jorat-Menthue",
    "BFS-Nr": "5804",
    "Kantonskürzel": "VD",
    "E": "2546684.903999999",
    "N": "1167376.3900000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Palézieux",
    "PLZ": "1607",
    "Zusatzziffer": "0",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2553911.583999999",
    "N": "1154763.1680000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Palézieux-Village",
    "PLZ": "1607",
    "Zusatzziffer": "1",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2553362.0689999983",
    "N": "1156338.2399999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Tavernes",
    "PLZ": "1607",
    "Zusatzziffer": "3",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2551839.046",
    "N": "1156143.6330000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Thioleyres",
    "PLZ": "1607",
    "Zusatzziffer": "4",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2551927.890999999",
    "N": "1154458.0929999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Oron-le-Châtel",
    "PLZ": "1608",
    "Zusatzziffer": "0",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2553927.66",
    "N": "1158338.4930000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bussigny-sur-Oron",
    "PLZ": "1608",
    "Zusatzziffer": "2",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2555861.6380000003",
    "N": "1157762.3830000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chesalles-sur-Oron",
    "PLZ": "1608",
    "Zusatzziffer": "3",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2555152.072999999",
    "N": "1158544.8850000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Oron-la-Ville",
    "PLZ": "1610",
    "Zusatzziffer": "0",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2553130.399",
    "N": "1157872.9640000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châtillens",
    "PLZ": "1610",
    "Zusatzziffer": "1",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2552335.9329999983",
    "N": "1157588.8000000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vuibroye",
    "PLZ": "1610",
    "Zusatzziffer": "2",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2551453.324000001",
    "N": "1158054.9409999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ecoteaux",
    "PLZ": "1612",
    "Zusatzziffer": "0",
    "Gemeindename": "Oron",
    "BFS-Nr": "5805",
    "Kantonskürzel": "VD",
    "E": "2555795.9389999993",
    "N": "1155101.7980000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ferlens VD",
    "PLZ": "1076",
    "Zusatzziffer": "0",
    "Gemeindename": "Jorat-Mézières",
    "BFS-Nr": "5806",
    "Kantonskürzel": "VD",
    "E": "2549958.835999999",
    "N": "1159953.754999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mézières VD",
    "PLZ": "1083",
    "Zusatzziffer": "0",
    "Gemeindename": "Jorat-Mézières",
    "BFS-Nr": "5806",
    "Kantonskürzel": "VD",
    "E": "2548855.75",
    "N": "1160659.2030000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Carrouge VD",
    "PLZ": "1084",
    "Zusatzziffer": "0",
    "Gemeindename": "Jorat-Mézières",
    "BFS-Nr": "5806",
    "Kantonskürzel": "VD",
    "E": "2549172.697999999",
    "N": "1161718.2490000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champtauroz",
    "PLZ": "1537",
    "Zusatzziffer": "0",
    "Gemeindename": "Champtauroz",
    "BFS-Nr": "5812",
    "Kantonskürzel": "VD",
    "E": "2550277.3779999986",
    "N": "1178986.943",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chevroux",
    "PLZ": "1545",
    "Zusatzziffer": "0",
    "Gemeindename": "Chevroux",
    "BFS-Nr": "5813",
    "Kantonskürzel": "VD",
    "E": "2559499.66",
    "N": "1193076.0579999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corcelles-près-Payerne",
    "PLZ": "1562",
    "Zusatzziffer": "0",
    "Gemeindename": "Corcelles-près-Payerne",
    "BFS-Nr": "5816",
    "Kantonskürzel": "VD",
    "E": "2563575.1240000017",
    "N": "1186832.9230000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandcour",
    "PLZ": "1543",
    "Zusatzziffer": "0",
    "Gemeindename": "Grandcour",
    "BFS-Nr": "5817",
    "Kantonskürzel": "VD",
    "E": "2561259.8500000015",
    "N": "1191323.7789999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Henniez",
    "PLZ": "1525",
    "Zusatzziffer": "0",
    "Gemeindename": "Henniez",
    "BFS-Nr": "5819",
    "Kantonskürzel": "VD",
    "E": "2557617.5210000016",
    "N": "1176838.9459999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Missy",
    "PLZ": "1565",
    "Zusatzziffer": "0",
    "Gemeindename": "Missy",
    "BFS-Nr": "5821",
    "Kantonskürzel": "VD",
    "E": "2564308.5",
    "N": "1192246.8839999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Payerne",
    "PLZ": "1530",
    "Zusatzziffer": "0",
    "Gemeindename": "Payerne",
    "BFS-Nr": "5822",
    "Kantonskürzel": "VD",
    "E": "2561778.7479999997",
    "N": "1185631.8940000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vers-chez-Perrin",
    "PLZ": "1551",
    "Zusatzziffer": "0",
    "Gemeindename": "Payerne",
    "BFS-Nr": "5822",
    "Kantonskürzel": "VD",
    "E": "2562135.3880000003",
    "N": "1182926.2780000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Trey",
    "PLZ": "1552",
    "Zusatzziffer": "0",
    "Gemeindename": "Trey",
    "BFS-Nr": "5827",
    "Kantonskürzel": "VD",
    "E": "2560837.0989999995",
    "N": "1180027.2890000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Treytorrens (Payerne)",
    "PLZ": "1538",
    "Zusatzziffer": "0",
    "Gemeindename": "Treytorrens (Payerne)",
    "BFS-Nr": "5828",
    "Kantonskürzel": "VD",
    "E": "2551287.1160000004",
    "N": "1180194.2600000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sédeilles",
    "PLZ": "1554",
    "Zusatzziffer": "0",
    "Gemeindename": "Villarzel",
    "BFS-Nr": "5830",
    "Kantonskürzel": "VD",
    "E": "2561353.6350000016",
    "N": "1177703.6460000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rossens VD",
    "PLZ": "1554",
    "Zusatzziffer": "2",
    "Gemeindename": "Villarzel",
    "BFS-Nr": "5830",
    "Kantonskürzel": "VD",
    "E": "2560541.664000001",
    "N": "1176118.1149999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villarzel",
    "PLZ": "1555",
    "Zusatzziffer": "0",
    "Gemeindename": "Villarzel",
    "BFS-Nr": "5830",
    "Kantonskürzel": "VD",
    "E": "2559811.324000001",
    "N": "1177657.563000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Granges-près-Marnand",
    "PLZ": "1523",
    "Zusatzziffer": "0",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2558057.7529999986",
    "N": "1178899.1829999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Marnand",
    "PLZ": "1524",
    "Zusatzziffer": "0",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2558932.960999999",
    "N": "1178466.5940000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Seigneux",
    "PLZ": "1525",
    "Zusatzziffer": "2",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2557184.840999998",
    "N": "1175365.1290000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sassel",
    "PLZ": "1534",
    "Zusatzziffer": "0",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2555629.3099999987",
    "N": "1180838.8709999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Combremont-le-Grand",
    "PLZ": "1535",
    "Zusatzziffer": "0",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2552603.6750000007",
    "N": "1179159.8509999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Combremont-le-Petit",
    "PLZ": "1536",
    "Zusatzziffer": "0",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2551903.162999999",
    "N": "1177777.8729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-Bramard",
    "PLZ": "1682",
    "Zusatzziffer": "4",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2558707.2600000016",
    "N": "1174275.0350000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cerniaz VD",
    "PLZ": "1682",
    "Zusatzziffer": "5",
    "Gemeindename": "Valbroye",
    "BFS-Nr": "5831",
    "Kantonskürzel": "VD",
    "E": "2558518.8260000013",
    "N": "1175255.8830000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Château-d'Oex",
    "PLZ": "1660",
    "Zusatzziffer": "0",
    "Gemeindename": "Château-d'Oex",
    "BFS-Nr": "5841",
    "Kantonskürzel": "VD",
    "E": "2576251.3900000006",
    "N": "1146871.7589999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Moulins",
    "PLZ": "1660",
    "Zusatzziffer": "2",
    "Gemeindename": "Château-d'Oex",
    "BFS-Nr": "5841",
    "Kantonskürzel": "VD",
    "E": "2574496.5639999993",
    "N": "1145482.5350000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "L'Etivaz",
    "PLZ": "1660",
    "Zusatzziffer": "3",
    "Gemeindename": "Château-d'Oex",
    "BFS-Nr": "5841",
    "Kantonskürzel": "VD",
    "E": "2577645.0749999993",
    "N": "1141511.8790000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Lécherette",
    "PLZ": "1660",
    "Zusatzziffer": "4",
    "Gemeindename": "Château-d'Oex",
    "BFS-Nr": "5841",
    "Kantonskürzel": "VD",
    "E": "2574448.9580000006",
    "N": "1141160.3880000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rossinière",
    "PLZ": "1658",
    "Zusatzziffer": "0",
    "Gemeindename": "Rossinière",
    "BFS-Nr": "5842",
    "Kantonskürzel": "VD",
    "E": "2572541.8139999993",
    "N": "1146177.484000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Tine",
    "PLZ": "1658",
    "Zusatzziffer": "2",
    "Gemeindename": "Rossinière",
    "BFS-Nr": "5842",
    "Kantonskürzel": "VD",
    "E": "2570111.063000001",
    "N": "1146464.9360000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rougemont",
    "PLZ": "1659",
    "Zusatzziffer": "0",
    "Gemeindename": "Rougemont",
    "BFS-Nr": "5843",
    "Kantonskürzel": "VD",
    "E": "2582156.267999999",
    "N": "1148497.329",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Flendruz",
    "PLZ": "1659",
    "Zusatzziffer": "2",
    "Gemeindename": "Rougemont",
    "BFS-Nr": "5843",
    "Kantonskürzel": "VD",
    "E": "2580329.2540000007",
    "N": "1148050.5689999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Allaman",
    "PLZ": "1165",
    "Zusatzziffer": "0",
    "Gemeindename": "Allaman",
    "BFS-Nr": "5851",
    "Kantonskürzel": "VD",
    "E": "2519867.0500000007",
    "N": "1147146.8550000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bursinel",
    "PLZ": "1195",
    "Zusatzziffer": "3",
    "Gemeindename": "Bursinel",
    "BFS-Nr": "5852",
    "Kantonskürzel": "VD",
    "E": "2512816.7239999995",
    "N": "1143632.7679999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bursins",
    "PLZ": "1183",
    "Zusatzziffer": "0",
    "Gemeindename": "Bursins",
    "BFS-Nr": "5853",
    "Kantonskürzel": "VD",
    "E": "2511739.8790000007",
    "N": "1145288.4640000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Burtigny",
    "PLZ": "1268",
    "Zusatzziffer": "2",
    "Gemeindename": "Burtigny",
    "BFS-Nr": "5854",
    "Kantonskürzel": "VD",
    "E": "2509409.504999999",
    "N": "1146636.1499999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dully",
    "PLZ": "1195",
    "Zusatzziffer": "2",
    "Gemeindename": "Dully",
    "BFS-Nr": "5855",
    "Kantonskürzel": "VD",
    "E": "2512079.162999999",
    "N": "1142644.460000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Essertines-sur-Rolle",
    "PLZ": "1186",
    "Zusatzziffer": "0",
    "Gemeindename": "Essertines-sur-Rolle",
    "BFS-Nr": "5856",
    "Kantonskürzel": "VD",
    "E": "2513957.6449999996",
    "N": "1149708.245000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gilly",
    "PLZ": "1182",
    "Zusatzziffer": "0",
    "Gemeindename": "Gilly",
    "BFS-Nr": "5857",
    "Kantonskürzel": "VD",
    "E": "2512549.636",
    "N": "1145957.1539999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Luins",
    "PLZ": "1184",
    "Zusatzziffer": "0",
    "Gemeindename": "Luins",
    "BFS-Nr": "5858",
    "Kantonskürzel": "VD",
    "E": "2510413.5529999994",
    "N": "1144566.4690000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mont-sur-Rolle",
    "PLZ": "1185",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-sur-Rolle",
    "BFS-Nr": "5859",
    "Kantonskürzel": "VD",
    "E": "2515386.098000001",
    "N": "1147219.015999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Perroy",
    "PLZ": "1166",
    "Zusatzziffer": "0",
    "Gemeindename": "Perroy",
    "BFS-Nr": "5860",
    "Kantonskürzel": "VD",
    "E": "2517565.791000001",
    "N": "1146816.8790000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rolle",
    "PLZ": "1180",
    "Zusatzziffer": "0",
    "Gemeindename": "Rolle",
    "BFS-Nr": "5861",
    "Kantonskürzel": "VD",
    "E": "2515306.7349999994",
    "N": "1145844.6389999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Tartegnin",
    "PLZ": "1180",
    "Zusatzziffer": "2",
    "Gemeindename": "Tartegnin",
    "BFS-Nr": "5862",
    "Kantonskürzel": "VD",
    "E": "2513697.6350000016",
    "N": "1146736.1889999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vinzel",
    "PLZ": "1184",
    "Zusatzziffer": "1",
    "Gemeindename": "Vinzel",
    "BFS-Nr": "5863",
    "Kantonskürzel": "VD",
    "E": "2510888.25",
    "N": "1144751.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Pont",
    "PLZ": "1342",
    "Zusatzziffer": "0",
    "Gemeindename": "L'Abbaye",
    "BFS-Nr": "5871",
    "Kantonskürzel": "VD",
    "E": "2515273.960000001",
    "N": "1168750.5830000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "L'Abbaye",
    "PLZ": "1344",
    "Zusatzziffer": "0",
    "Gemeindename": "L'Abbaye",
    "BFS-Nr": "5871",
    "Kantonskürzel": "VD",
    "E": "2514337.723000001",
    "N": "1167152.2280000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Bioux",
    "PLZ": "1346",
    "Zusatzziffer": "0",
    "Gemeindename": "L'Abbaye",
    "BFS-Nr": "5871",
    "Kantonskürzel": "VD",
    "E": "2510823.524",
    "N": "1164099.7300000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "L'Orient",
    "PLZ": "1341",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Chenit",
    "BFS-Nr": "5872",
    "Kantonskürzel": "VD",
    "E": "2508036.033",
    "N": "1161780.6259999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Sentier",
    "PLZ": "1347",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Chenit",
    "BFS-Nr": "5872",
    "Kantonskürzel": "VD",
    "E": "2507559.4739999995",
    "N": "1162607.754999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Solliat",
    "PLZ": "1347",
    "Zusatzziffer": "1",
    "Gemeindename": "Le Chenit",
    "BFS-Nr": "5872",
    "Kantonskürzel": "VD",
    "E": "2507771.8110000007",
    "N": "1163876.484000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Brassus",
    "PLZ": "1348",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Chenit",
    "BFS-Nr": "5872",
    "Kantonskürzel": "VD",
    "E": "2505841.037999999",
    "N": "1159695.7580000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Charbonnières",
    "PLZ": "1343",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Lieu",
    "BFS-Nr": "5873",
    "Kantonskürzel": "VD",
    "E": "2513894.66",
    "N": "1169010.7459999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Lieu",
    "PLZ": "1345",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Lieu",
    "BFS-Nr": "5873",
    "Kantonskürzel": "VD",
    "E": "2511461.649",
    "N": "1166899.5340000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Séchey",
    "PLZ": "1345",
    "Zusatzziffer": "1",
    "Gemeindename": "Le Lieu",
    "BFS-Nr": "5873",
    "Kantonskürzel": "VD",
    "E": "2512852.881000001",
    "N": "1168289.2980000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Blonay",
    "PLZ": "1807",
    "Zusatzziffer": "0",
    "Gemeindename": "Blonay",
    "BFS-Nr": "5881",
    "Kantonskürzel": "VD",
    "E": "2558195.653000001",
    "N": "1146204.2010000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Mont-Pèlerin",
    "PLZ": "1801",
    "Zusatzziffer": "0",
    "Gemeindename": "Chardonne",
    "BFS-Nr": "5882",
    "Kantonskürzel": "VD",
    "E": "2553276.8759999983",
    "N": "1147988.9910000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chardonne",
    "PLZ": "1803",
    "Zusatzziffer": "0",
    "Gemeindename": "Chardonne",
    "BFS-Nr": "5882",
    "Kantonskürzel": "VD",
    "E": "2553026.408",
    "N": "1147463.4409999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corseaux",
    "PLZ": "1802",
    "Zusatzziffer": "0",
    "Gemeindename": "Corseaux",
    "BFS-Nr": "5883",
    "Kantonskürzel": "VD",
    "E": "2553469.625",
    "N": "1146956.3000000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corsier-sur-Vevey",
    "PLZ": "1804",
    "Zusatzziffer": "0",
    "Gemeindename": "Corsier-sur-Vevey",
    "BFS-Nr": "5884",
    "Kantonskürzel": "VD",
    "E": "2554150.3060000017",
    "N": "1146716.504999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Monts-de-Corsier",
    "PLZ": "1808",
    "Zusatzziffer": "0",
    "Gemeindename": "Corsier-sur-Vevey",
    "BFS-Nr": "5884",
    "Kantonskürzel": "VD",
    "E": "2556867.2300000004",
    "N": "1149952.7690000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fenil-sur-Corsier",
    "PLZ": "1809",
    "Zusatzziffer": "0",
    "Gemeindename": "Corsier-sur-Vevey",
    "BFS-Nr": "5884",
    "Kantonskürzel": "VD",
    "E": "2556041.796",
    "N": "1148193.311999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Jongny",
    "PLZ": "1805",
    "Zusatzziffer": "0",
    "Gemeindename": "Jongny",
    "BFS-Nr": "5885",
    "Kantonskürzel": "VD",
    "E": "2554190.6460000016",
    "N": "1147573.0540000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Clarens",
    "PLZ": "1815",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2558029.2809999995",
    "N": "1143556.370000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chailly-Montreux",
    "PLZ": "1816",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2558001.1400000006",
    "N": "1144933.9530000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brent",
    "PLZ": "1817",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2558729.379999999",
    "N": "1145281.4310000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montreux",
    "PLZ": "1820",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2559582.1559999995",
    "N": "1142301.2430000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Territet",
    "PLZ": "1820",
    "Zusatzziffer": "5",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2560375.3960000016",
    "N": "1141847.3460000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chernex",
    "PLZ": "1822",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2559653.278000001",
    "N": "1143607.8159999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Glion",
    "PLZ": "1823",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2560568.789000001",
    "N": "1142438.3990000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Caux",
    "PLZ": "1824",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2561566.1510000005",
    "N": "1142497.4010000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chamby",
    "PLZ": "1832",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2559651.074000001",
    "N": "1144353.9620000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villard-sur-Chamby",
    "PLZ": "1832",
    "Zusatzziffer": "2",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2561034.1750000007",
    "N": "1145941.5119999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Avants",
    "PLZ": "1833",
    "Zusatzziffer": "0",
    "Gemeindename": "Montreux",
    "BFS-Nr": "5886",
    "Kantonskürzel": "VD",
    "E": "2561905.357999999",
    "N": "1144761.879999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Légier-La Chiésaz",
    "PLZ": "1806",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Légier-La Chiésaz",
    "BFS-Nr": "5888",
    "Kantonskürzel": "VD",
    "E": "2557399.6750000007",
    "N": "1146822.4649999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Tour-de-Peilz",
    "PLZ": "1814",
    "Zusatzziffer": "0",
    "Gemeindename": "La Tour-de-Peilz",
    "BFS-Nr": "5889",
    "Kantonskürzel": "VD",
    "E": "2555313.4629999995",
    "N": "1144863.4789999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vevey",
    "PLZ": "1800",
    "Zusatzziffer": "0",
    "Gemeindename": "Vevey",
    "BFS-Nr": "5890",
    "Kantonskürzel": "VD",
    "E": "2554636.886",
    "N": "1145474.563000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Veytaux",
    "PLZ": "1820",
    "Zusatzziffer": "6",
    "Gemeindename": "Veytaux",
    "BFS-Nr": "5891",
    "Kantonskürzel": "VD",
    "E": "2560806.254999999",
    "N": "1141179.7879999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Belmont-sur-Yverdon",
    "PLZ": "1432",
    "Zusatzziffer": "0",
    "Gemeindename": "Belmont-sur-Yverdon",
    "BFS-Nr": "5902",
    "Kantonskürzel": "VD",
    "E": "2537743.1999999993",
    "N": "1177472.8330000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bioley-Magnoux",
    "PLZ": "1407",
    "Zusatzziffer": "4",
    "Gemeindename": "Bioley-Magnoux",
    "BFS-Nr": "5903",
    "Kantonskürzel": "VD",
    "E": "2544440.2749999985",
    "N": "1175334.3740000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chamblon",
    "PLZ": "1436",
    "Zusatzziffer": "2",
    "Gemeindename": "Chamblon",
    "BFS-Nr": "5904",
    "Kantonskürzel": "VD",
    "E": "2536455.9279999994",
    "N": "1181157.2309999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champvent",
    "PLZ": "1443",
    "Zusatzziffer": "0",
    "Gemeindename": "Champvent",
    "BFS-Nr": "5905",
    "Kantonskürzel": "VD",
    "E": "2534063.9409999996",
    "N": "1181731.6039999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Essert-sous-Champvent",
    "PLZ": "1443",
    "Zusatzziffer": "2",
    "Gemeindename": "Champvent",
    "BFS-Nr": "5905",
    "Kantonskürzel": "VD",
    "E": "2534798.159000002",
    "N": "1182802.482999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-sous-Champvent",
    "PLZ": "1443",
    "Zusatzziffer": "3",
    "Gemeindename": "Champvent",
    "BFS-Nr": "5905",
    "Kantonskürzel": "VD",
    "E": "2534682.9640000015",
    "N": "1182044.8150000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chavannes-le-Chêne",
    "PLZ": "1464",
    "Zusatzziffer": "0",
    "Gemeindename": "Chavannes-le-Chêne",
    "BFS-Nr": "5907",
    "Kantonskürzel": "VD",
    "E": "2549478.215",
    "N": "1180845.2589999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chêne-Pâquier",
    "PLZ": "1464",
    "Zusatzziffer": "2",
    "Gemeindename": "Chêne-Pâquier",
    "BFS-Nr": "5908",
    "Kantonskürzel": "VD",
    "E": "2548837.234000001",
    "N": "1180236.818",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cheseaux-Noréaz",
    "PLZ": "1400",
    "Zusatzziffer": "5",
    "Gemeindename": "Cheseaux-Noréaz",
    "BFS-Nr": "5909",
    "Kantonskürzel": "VD",
    "E": "2541828.671",
    "N": "1181607.9780000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cronay",
    "PLZ": "1406",
    "Zusatzziffer": "0",
    "Gemeindename": "Cronay",
    "BFS-Nr": "5910",
    "Kantonskürzel": "VD",
    "E": "2543291.9959999993",
    "N": "1178708.8399999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cuarny",
    "PLZ": "1404",
    "Zusatzziffer": "0",
    "Gemeindename": "Cuarny",
    "BFS-Nr": "5911",
    "Kantonskürzel": "VD",
    "E": "2542727.6290000007",
    "N": "1180152.6180000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Démoret",
    "PLZ": "1415",
    "Zusatzziffer": "2",
    "Gemeindename": "Démoret",
    "BFS-Nr": "5912",
    "Kantonskürzel": "VD",
    "E": "2547865.5130000003",
    "N": "1177588.5029999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Donneloye",
    "PLZ": "1407",
    "Zusatzziffer": "0",
    "Gemeindename": "Donneloye",
    "BFS-Nr": "5913",
    "Kantonskürzel": "VD",
    "E": "2544789.278999999",
    "N": "1177352.6429999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gossens",
    "PLZ": "1407",
    "Zusatzziffer": "2",
    "Gemeindename": "Donneloye",
    "BFS-Nr": "5913",
    "Kantonskürzel": "VD",
    "E": "2543568.9580000006",
    "N": "1176724.0009999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mézery-près-Donneloye",
    "PLZ": "1407",
    "Zusatzziffer": "3",
    "Gemeindename": "Donneloye",
    "BFS-Nr": "5913",
    "Kantonskürzel": "VD",
    "E": "2545655.715",
    "N": "1177789.4860000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Prahins",
    "PLZ": "1408",
    "Zusatzziffer": "0",
    "Gemeindename": "Donneloye",
    "BFS-Nr": "5913",
    "Kantonskürzel": "VD",
    "E": "2546616.046",
    "N": "1176247.4050000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ependes VD",
    "PLZ": "1434",
    "Zusatzziffer": "0",
    "Gemeindename": "Ependes (VD)",
    "BFS-Nr": "5914",
    "Kantonskürzel": "VD",
    "E": "2536562.800999999",
    "N": "1177435.0879999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mathod",
    "PLZ": "1438",
    "Zusatzziffer": "0",
    "Gemeindename": "Mathod",
    "BFS-Nr": "5919",
    "Kantonskürzel": "VD",
    "E": "2533442.541000001",
    "N": "1179882.386",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Molondin",
    "PLZ": "1415",
    "Zusatzziffer": "0",
    "Gemeindename": "Molondin",
    "BFS-Nr": "5921",
    "Kantonskürzel": "VD",
    "E": "2547280.379999999",
    "N": "1178960.9750000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montagny-près-Yverdon",
    "PLZ": "1442",
    "Zusatzziffer": "0",
    "Gemeindename": "Montagny-près-Yverdon",
    "BFS-Nr": "5922",
    "Kantonskürzel": "VD",
    "E": "2536909.6059999987",
    "N": "1182759.7430000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Oppens",
    "PLZ": "1047",
    "Zusatzziffer": "0",
    "Gemeindename": "Oppens",
    "BFS-Nr": "5923",
    "Kantonskürzel": "VD",
    "E": "2542946.022999998",
    "N": "1173927.7749999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Orges",
    "PLZ": "1430",
    "Zusatzziffer": "0",
    "Gemeindename": "Orges",
    "BFS-Nr": "5924",
    "Kantonskürzel": "VD",
    "E": "2535115.6449999996",
    "N": "1184448.0450000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Orzens",
    "PLZ": "1413",
    "Zusatzziffer": "0",
    "Gemeindename": "Orzens",
    "BFS-Nr": "5925",
    "Kantonskürzel": "VD",
    "E": "2542284.539000001",
    "N": "1174743.870000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pomy",
    "PLZ": "1405",
    "Zusatzziffer": "0",
    "Gemeindename": "Pomy",
    "BFS-Nr": "5926",
    "Kantonskürzel": "VD",
    "E": "2541238.5540000014",
    "N": "1178970.7250000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rovray",
    "PLZ": "1463",
    "Zusatzziffer": "0",
    "Gemeindename": "Rovray",
    "BFS-Nr": "5928",
    "Kantonskürzel": "VD",
    "E": "2548574.454",
    "N": "1181702.3060000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Suchy",
    "PLZ": "1433",
    "Zusatzziffer": "0",
    "Gemeindename": "Suchy",
    "BFS-Nr": "5929",
    "Kantonskürzel": "VD",
    "E": "2535810.348000001",
    "N": "1174929.3979999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Suscévaz",
    "PLZ": "1437",
    "Zusatzziffer": "0",
    "Gemeindename": "Suscévaz",
    "BFS-Nr": "5930",
    "Kantonskürzel": "VD",
    "E": "2534253.6130000018",
    "N": "1179589.9459999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Treycovagnes",
    "PLZ": "1436",
    "Zusatzziffer": "0",
    "Gemeindename": "Treycovagnes",
    "BFS-Nr": "5931",
    "Kantonskürzel": "VD",
    "E": "2536667.6279999986",
    "N": "1180663.4679999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ursins",
    "PLZ": "1412",
    "Zusatzziffer": "2",
    "Gemeindename": "Ursins",
    "BFS-Nr": "5932",
    "Kantonskürzel": "VD",
    "E": "2541116.528999999",
    "N": "1176305.9200000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Valeyres-sous-Montagny",
    "PLZ": "1441",
    "Zusatzziffer": "0",
    "Gemeindename": "Valeyres-sous-Montagny",
    "BFS-Nr": "5933",
    "Kantonskürzel": "VD",
    "E": "2536658.3040000014",
    "N": "1183422.3610000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Valeyres-sous-Ursins",
    "PLZ": "1412",
    "Zusatzziffer": "0",
    "Gemeindename": "Valeyres-sous-Ursins",
    "BFS-Nr": "5934",
    "Kantonskürzel": "VD",
    "E": "2540165.9979999997",
    "N": "1177533.2679999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-Epeney",
    "PLZ": "1404",
    "Zusatzziffer": "2",
    "Gemeindename": "Villars-Epeney",
    "BFS-Nr": "5935",
    "Kantonskürzel": "VD",
    "E": "2543357.5029999986",
    "N": "1181439.693",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vugelles-La Mothe",
    "PLZ": "1431",
    "Zusatzziffer": "0",
    "Gemeindename": "Vugelles-La Mothe",
    "BFS-Nr": "5937",
    "Kantonskürzel": "VD",
    "E": "2534374.8280000016",
    "N": "1186242.5989999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Yverdon-les-Bains",
    "PLZ": "1400",
    "Zusatzziffer": "0",
    "Gemeindename": "Yverdon-les-Bains",
    "BFS-Nr": "5938",
    "Kantonskürzel": "VD",
    "E": "2539038.1400000006",
    "N": "1181165.7179999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gressy",
    "PLZ": "1432",
    "Zusatzziffer": "2",
    "Gemeindename": "Yverdon-les-Bains",
    "BFS-Nr": "5938",
    "Kantonskürzel": "VD",
    "E": "2538630.708999999",
    "N": "1177737.6840000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Yvonand",
    "PLZ": "1462",
    "Zusatzziffer": "0",
    "Gemeindename": "Yvonand",
    "BFS-Nr": "5939",
    "Kantonskürzel": "VD",
    "E": "2547053.6689999998",
    "N": "1183241.8779999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brig",
    "PLZ": "3900",
    "Zusatzziffer": "0",
    "Gemeindename": "Brig-Glis",
    "BFS-Nr": "6002",
    "Kantonskürzel": "VS",
    "E": "2642436.274",
    "N": "1129614.8500000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gamsen",
    "PLZ": "3900",
    "Zusatzziffer": "2",
    "Gemeindename": "Brig-Glis",
    "BFS-Nr": "6002",
    "Kantonskürzel": "VS",
    "E": "2639771.609000001",
    "N": "1128330.688000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brigerbad",
    "PLZ": "3900",
    "Zusatzziffer": "3",
    "Gemeindename": "Brig-Glis",
    "BFS-Nr": "6002",
    "Kantonskürzel": "VS",
    "E": "2636984.4010000005",
    "N": "1127743.2060000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Glis",
    "PLZ": "3902",
    "Zusatzziffer": "0",
    "Gemeindename": "Brig-Glis",
    "BFS-Nr": "6002",
    "Kantonskürzel": "VS",
    "E": "2641552.5709999986",
    "N": "1128958.859000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eggerberg",
    "PLZ": "3939",
    "Zusatzziffer": "0",
    "Gemeindename": "Eggerberg",
    "BFS-Nr": "6004",
    "Kantonskürzel": "VS",
    "E": "2633769.649",
    "N": "1128448.9800000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mund",
    "PLZ": "3903",
    "Zusatzziffer": "0",
    "Gemeindename": "Naters",
    "BFS-Nr": "6007",
    "Kantonskürzel": "VS",
    "E": "2638545.409000002",
    "N": "1129561.886",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Birgisch",
    "PLZ": "3903",
    "Zusatzziffer": "1",
    "Gemeindename": "Naters",
    "BFS-Nr": "6007",
    "Kantonskürzel": "VS",
    "E": "2639979.1449999996",
    "N": "1129835.3940000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Naters",
    "PLZ": "3904",
    "Zusatzziffer": "0",
    "Gemeindename": "Naters",
    "BFS-Nr": "6007",
    "Kantonskürzel": "VS",
    "E": "2642318.6730000004",
    "N": "1130615.8830000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Blatten b. Naters",
    "PLZ": "3914",
    "Zusatzziffer": "0",
    "Gemeindename": "Naters",
    "BFS-Nr": "6007",
    "Kantonskürzel": "VS",
    "E": "2641924.9499999993",
    "N": "1134198.8110000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Belalp",
    "PLZ": "3914",
    "Zusatzziffer": "1",
    "Gemeindename": "Naters",
    "BFS-Nr": "6007",
    "Kantonskürzel": "VS",
    "E": "2641241.8850000016",
    "N": "1135737.677000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rothwald",
    "PLZ": "3901",
    "Zusatzziffer": "23",
    "Gemeindename": "Ried-Brig",
    "BFS-Nr": "6008",
    "Kantonskürzel": "VS",
    "E": "2646410.653000001",
    "N": "1125687.8880000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ried-Brig",
    "PLZ": "3911",
    "Zusatzziffer": "0",
    "Gemeindename": "Ried-Brig",
    "BFS-Nr": "6008",
    "Kantonskürzel": "VS",
    "E": "2644560.4459999986",
    "N": "1129348.938000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Simplon Dorf",
    "PLZ": "3907",
    "Zusatzziffer": "0",
    "Gemeindename": "Simplon",
    "BFS-Nr": "6009",
    "Kantonskürzel": "VS",
    "E": "2647673.8040000014",
    "N": "1116233.9670000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Simplon Hospiz",
    "PLZ": "3907",
    "Zusatzziffer": "2",
    "Gemeindename": "Simplon",
    "BFS-Nr": "6009",
    "Kantonskürzel": "VS",
    "E": "2645538.7410000004",
    "N": "1121861.9730000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gabi (Simplon)",
    "PLZ": "3907",
    "Zusatzziffer": "3",
    "Gemeindename": "Simplon",
    "BFS-Nr": "6009",
    "Kantonskürzel": "VS",
    "E": "2648970.1389999986",
    "N": "1115025.8660000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Termen",
    "PLZ": "3912",
    "Zusatzziffer": "0",
    "Gemeindename": "Termen",
    "BFS-Nr": "6010",
    "Kantonskürzel": "VS",
    "E": "2644840.017999999",
    "N": "1130906.857999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Rosswald",
    "PLZ": "3913",
    "Zusatzziffer": "0",
    "Gemeindename": "Termen",
    "BFS-Nr": "6010",
    "Kantonskürzel": "VS",
    "E": "2646370.8139999993",
    "N": "1128259.7410000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gondo",
    "PLZ": "3907",
    "Zusatzziffer": "4",
    "Gemeindename": "Zwischbergen",
    "BFS-Nr": "6011",
    "Kantonskürzel": "VS",
    "E": "2654215.908",
    "N": "1116275.0960000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ardon",
    "PLZ": "1957",
    "Zusatzziffer": "0",
    "Gemeindename": "Ardon",
    "BFS-Nr": "6021",
    "Kantonskürzel": "VS",
    "E": "2585639.291000001",
    "N": "1117682.5799999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chamoson",
    "PLZ": "1955",
    "Zusatzziffer": "0",
    "Gemeindename": "Chamoson",
    "BFS-Nr": "6022",
    "Kantonskürzel": "VS",
    "E": "2583510.631000001",
    "N": "1116761.1609999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mayens-de-Chamoson",
    "PLZ": "1955",
    "Zusatzziffer": "1",
    "Gemeindename": "Chamoson",
    "BFS-Nr": "6022",
    "Kantonskürzel": "VS",
    "E": "2580159.2859999985",
    "N": "1117193.386",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Vérines (Chamoson)",
    "PLZ": "1955",
    "Zusatzziffer": "2",
    "Gemeindename": "Chamoson",
    "BFS-Nr": "6022",
    "Kantonskürzel": "VS",
    "E": "2581843.7399999984",
    "N": "1117700.3159999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Némiaz (Chamoson)",
    "PLZ": "1955",
    "Zusatzziffer": "3",
    "Gemeindename": "Chamoson",
    "BFS-Nr": "6022",
    "Kantonskürzel": "VS",
    "E": "2583396.6099999994",
    "N": "1117897.0890000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grugnay (Chamoson)",
    "PLZ": "1955",
    "Zusatzziffer": "4",
    "Gemeindename": "Chamoson",
    "BFS-Nr": "6022",
    "Kantonskürzel": "VS",
    "E": "2582810.278000001",
    "N": "1117277.3420000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Pierre-de-Clages",
    "PLZ": "1955",
    "Zusatzziffer": "5",
    "Gemeindename": "Chamoson",
    "BFS-Nr": "6022",
    "Kantonskürzel": "VS",
    "E": "2584468.846000001",
    "N": "1115663.0489999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Conthey",
    "PLZ": "1964",
    "Zusatzziffer": "0",
    "Gemeindename": "Conthey",
    "BFS-Nr": "6023",
    "Kantonskürzel": "VS",
    "E": "2589679.368999999",
    "N": "1119553.989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Séverin",
    "PLZ": "1975",
    "Zusatzziffer": "0",
    "Gemeindename": "Conthey",
    "BFS-Nr": "6023",
    "Kantonskürzel": "VS",
    "E": "2589525.75",
    "N": "1120818.625",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Erde",
    "PLZ": "1976",
    "Zusatzziffer": "0",
    "Gemeindename": "Conthey",
    "BFS-Nr": "6023",
    "Kantonskürzel": "VS",
    "E": "2588791.1440000013",
    "N": "1120856.4409999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aven",
    "PLZ": "1976",
    "Zusatzziffer": "1",
    "Gemeindename": "Conthey",
    "BFS-Nr": "6023",
    "Kantonskürzel": "VS",
    "E": "2587171.469999999",
    "N": "1120574.4039999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Daillon",
    "PLZ": "1976",
    "Zusatzziffer": "2",
    "Gemeindename": "Conthey",
    "BFS-Nr": "6023",
    "Kantonskürzel": "VS",
    "E": "2589781.412999999",
    "N": "1122751.109000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Clèbes (Nendaz)",
    "PLZ": "1993",
    "Zusatzziffer": "2",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2592111.427000001",
    "N": "1114862.3429999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aproz (Nendaz)",
    "PLZ": "1994",
    "Zusatzziffer": "2",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2590530.8220000006",
    "N": "1117260.2049999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Basse-Nendaz",
    "PLZ": "1996",
    "Zusatzziffer": "0",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2590274.620000001",
    "N": "1115343.1510000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fey (Nendaz)",
    "PLZ": "1996",
    "Zusatzziffer": "1",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2586861.182",
    "N": "1115114.438000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bieudron (Nendaz)",
    "PLZ": "1996",
    "Zusatzziffer": "2",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2587586.2490000017",
    "N": "1115933.4059999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Condémines (Nendaz)",
    "PLZ": "1996",
    "Zusatzziffer": "3",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2585881.8000000007",
    "N": "1113961.0819999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saclentse",
    "PLZ": "1996",
    "Zusatzziffer": "4",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2590817.8649999984",
    "N": "1113834.6680000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Baar (Nendaz)",
    "PLZ": "1996",
    "Zusatzziffer": "5",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2591573.533",
    "N": "1117033.5540000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Beuson (Nendaz)",
    "PLZ": "1996",
    "Zusatzziffer": "6",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2591126.0780000016",
    "N": "1114447.4250000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brignon (Nendaz)",
    "PLZ": "1996",
    "Zusatzziffer": "7",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2591406.204",
    "N": "1115296.4530000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Haute-Nendaz",
    "PLZ": "1997",
    "Zusatzziffer": "0",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2588865.6499999985",
    "N": "1114661.3740000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Siviez (Nendaz)",
    "PLZ": "1997",
    "Zusatzziffer": "1",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2590550.7699999996",
    "N": "1109371.4439999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sornard (Nendaz)",
    "PLZ": "1997",
    "Zusatzziffer": "2",
    "Gemeindename": "Nendaz",
    "BFS-Nr": "6024",
    "Kantonskürzel": "VS",
    "E": "2589661.1180000007",
    "N": "1114899.6020000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vétroz",
    "PLZ": "1963",
    "Zusatzziffer": "0",
    "Gemeindename": "Vétroz",
    "BFS-Nr": "6025",
    "Kantonskürzel": "VS",
    "E": "2587890.765999999",
    "N": "1119543.6499999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bourg-St-Pierre",
    "PLZ": "1946",
    "Zusatzziffer": "0",
    "Gemeindename": "Bourg-Saint-Pierre",
    "BFS-Nr": "6032",
    "Kantonskürzel": "VS",
    "E": "2582467.2349999994",
    "N": "1088696.2149999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Liddes",
    "PLZ": "1945",
    "Zusatzziffer": "0",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2580325.414999999",
    "N": "1093310.136",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fontaine Dessus (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "2",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2579501.2760000005",
    "N": "1094943.3539999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fontaine Dessous (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "3",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2579034.9690000005",
    "N": "1095416.0909999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dranse (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "4",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2579995.8040000014",
    "N": "1093165.6499999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chandonne (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "5",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2579941.8729999997",
    "N": "1094884.300999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rive Haute (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "6",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2579276.829999998",
    "N": "1095051.2630000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fornex (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "7",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2578655.2650000006",
    "N": "1095298.7589999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Moulins VS (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "8",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2579038.881000001",
    "N": "1094790.1510000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vichères (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "9",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2578920.848000001",
    "N": "1093917.7710000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Palasuit (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "10",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2580923.587000001",
    "N": "1092379.7780000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chez Petit (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "11",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2579812.471999999",
    "N": "1092918.7140000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Petit Vichères (Liddes)",
    "PLZ": "1945",
    "Zusatzziffer": "12",
    "Gemeindename": "Liddes",
    "BFS-Nr": "6033",
    "Kantonskürzel": "VS",
    "E": "2578584.0989999995",
    "N": "1093287.0280000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Orsières",
    "PLZ": "1937",
    "Zusatzziffer": "0",
    "Gemeindename": "Orsières",
    "BFS-Nr": "6034",
    "Kantonskürzel": "VS",
    "E": "2577356.447999999",
    "N": "1097770.3449999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champex-Lac",
    "PLZ": "1938",
    "Zusatzziffer": "0",
    "Gemeindename": "Orsières",
    "BFS-Nr": "6034",
    "Kantonskürzel": "VS",
    "E": "2575259.0130000003",
    "N": "1097287.4849999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Praz-de-Fort",
    "PLZ": "1943",
    "Zusatzziffer": "0",
    "Gemeindename": "Orsières",
    "BFS-Nr": "6034",
    "Kantonskürzel": "VS",
    "E": "2575733.090999998",
    "N": "1093210.0100000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Fouly VS",
    "PLZ": "1944",
    "Zusatzziffer": "0",
    "Gemeindename": "Orsières",
    "BFS-Nr": "6034",
    "Kantonskürzel": "VS",
    "E": "2573532.563000001",
    "N": "1086769.438000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sembrancher",
    "PLZ": "1933",
    "Zusatzziffer": "0",
    "Gemeindename": "Sembrancher",
    "BFS-Nr": "6035",
    "Kantonskürzel": "VS",
    "E": "2577711.3729999997",
    "N": "1103007.8480000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chamoille (Sembrancher)",
    "PLZ": "1933",
    "Zusatzziffer": "3",
    "Gemeindename": "Sembrancher",
    "BFS-Nr": "6035",
    "Kantonskürzel": "VS",
    "E": "2577859.3159999996",
    "N": "1101088.9659999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Garde (Sembrancher)",
    "PLZ": "1933",
    "Zusatzziffer": "4",
    "Gemeindename": "Sembrancher",
    "BFS-Nr": "6035",
    "Kantonskürzel": "VS",
    "E": "2577391.109000001",
    "N": "1101557.1490000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chemin",
    "PLZ": "1927",
    "Zusatzziffer": "0",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2573593.0430000015",
    "N": "1104304.2349999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vens (Sembrancher)",
    "PLZ": "1933",
    "Zusatzziffer": "2",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2575838.6259999983",
    "N": "1104148.993999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Châble VS",
    "PLZ": "1934",
    "Zusatzziffer": "0",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2582670.789999999",
    "N": "1103123.6099999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bruson",
    "PLZ": "1934",
    "Zusatzziffer": "1",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2582959.0659999996",
    "N": "1101622.3729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Verbier",
    "PLZ": "1936",
    "Zusatzziffer": "0",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2583055.223000001",
    "N": "1104753.3509999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vollèges",
    "PLZ": "1941",
    "Zusatzziffer": "0",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2579126.9510000013",
    "N": "1104020.5150000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cries (Vollèges)",
    "PLZ": "1941",
    "Zusatzziffer": "2",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2579766.9629999995",
    "N": "1104464.4079999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Levron",
    "PLZ": "1942",
    "Zusatzziffer": "0",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2578608.149",
    "N": "1105201.3440000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Versegères",
    "PLZ": "1947",
    "Zusatzziffer": "0",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2584158.5249999985",
    "N": "1101610.0009999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champsec",
    "PLZ": "1947",
    "Zusatzziffer": "5",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2584916.307",
    "N": "1100531.9140000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lourtier",
    "PLZ": "1948",
    "Zusatzziffer": "0",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2586448.3550000004",
    "N": "1100110.1460000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fionnay",
    "PLZ": "1948",
    "Zusatzziffer": "1",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2590003.352000002",
    "N": "1097870.7760000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sarreyer",
    "PLZ": "1948",
    "Zusatzziffer": "2",
    "Gemeindename": "Val de Bagnes",
    "BFS-Nr": "6037",
    "Kantonskürzel": "VS",
    "E": "2585391.9919999987",
    "N": "1101169.9510000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bellwald",
    "PLZ": "3997",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellwald",
    "BFS-Nr": "6052",
    "Kantonskürzel": "VS",
    "E": "2655461.401999999",
    "N": "1141620.079",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Binn",
    "PLZ": "3996",
    "Zusatzziffer": "0",
    "Gemeindename": "Binn",
    "BFS-Nr": "6054",
    "Kantonskürzel": "VS",
    "E": "2657334.234000001",
    "N": "1135139.8979999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ernen",
    "PLZ": "3995",
    "Zusatzziffer": "0",
    "Gemeindename": "Ernen",
    "BFS-Nr": "6056",
    "Kantonskürzel": "VS",
    "E": "2654245.3949999996",
    "N": "1138854.6939999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ausserbinn",
    "PLZ": "3995",
    "Zusatzziffer": "2",
    "Gemeindename": "Ernen",
    "BFS-Nr": "6056",
    "Kantonskürzel": "VS",
    "E": "2654664.7430000007",
    "N": "1137012.421",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mühlebach (Goms)",
    "PLZ": "3995",
    "Zusatzziffer": "3",
    "Gemeindename": "Ernen",
    "BFS-Nr": "6056",
    "Kantonskürzel": "VS",
    "E": "2655210.2600000016",
    "N": "1139912.2049999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steinhaus",
    "PLZ": "3995",
    "Zusatzziffer": "4",
    "Gemeindename": "Ernen",
    "BFS-Nr": "6056",
    "Kantonskürzel": "VS",
    "E": "2656830.4180000015",
    "N": "1141432.7490000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fiesch",
    "PLZ": "3984",
    "Zusatzziffer": "0",
    "Gemeindename": "Fiesch",
    "BFS-Nr": "6057",
    "Kantonskürzel": "VS",
    "E": "2653479.9899999984",
    "N": "1139383.8880000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Jungfraujoch",
    "PLZ": "3801",
    "Zusatzziffer": "33",
    "Gemeindename": "Fieschertal",
    "BFS-Nr": "6058",
    "Kantonskürzel": "VS",
    "E": "2641777.875",
    "N": "1155270.625",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fieschertal",
    "PLZ": "3984",
    "Zusatzziffer": "2",
    "Gemeindename": "Fieschertal",
    "BFS-Nr": "6058",
    "Kantonskürzel": "VS",
    "E": "2654155.098000001",
    "N": "1141695.9519999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lax",
    "PLZ": "3994",
    "Zusatzziffer": "0",
    "Gemeindename": "Lax",
    "BFS-Nr": "6061",
    "Kantonskürzel": "VS",
    "E": "2652209.460999999",
    "N": "1137804.7690000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ulrichen",
    "PLZ": "3988",
    "Zusatzziffer": "0",
    "Gemeindename": "Obergoms",
    "BFS-Nr": "6076",
    "Kantonskürzel": "VS",
    "E": "2666379.3280000016",
    "N": "1150961.2270000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Obergesteln",
    "PLZ": "3988",
    "Zusatzziffer": "2",
    "Gemeindename": "Obergoms",
    "BFS-Nr": "6076",
    "Kantonskürzel": "VS",
    "E": "2667991.4450000003",
    "N": "1151885.4990000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberwald",
    "PLZ": "3999",
    "Zusatzziffer": "0",
    "Gemeindename": "Obergoms",
    "BFS-Nr": "6076",
    "Kantonskürzel": "VS",
    "E": "2669795.6279999986",
    "N": "1154102.7919999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Münster VS",
    "PLZ": "3985",
    "Zusatzziffer": "0",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2663550.572999999",
    "N": "1149009.3139999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Geschinen",
    "PLZ": "3985",
    "Zusatzziffer": "2",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2664806.6130000018",
    "N": "1149722.3449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Biel VS",
    "PLZ": "3989",
    "Zusatzziffer": "0",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2659804.8649999984",
    "N": "1145235.1499999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ritzingen",
    "PLZ": "3989",
    "Zusatzziffer": "2",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2660288.2490000017",
    "N": "1145388.6099999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Selkingen",
    "PLZ": "3989",
    "Zusatzziffer": "3",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2659541.697999999",
    "N": "1145063.0799999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niederwald",
    "PLZ": "3989",
    "Zusatzziffer": "4",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2657684.052000001",
    "N": "1142985.2230000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Blitzingen",
    "PLZ": "3989",
    "Zusatzziffer": "5",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2658680.1779999994",
    "N": "1143623.813000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Reckingen VS",
    "PLZ": "3998",
    "Zusatzziffer": "0",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2661759.2289999984",
    "N": "1146786.6550000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gluringen",
    "PLZ": "3998",
    "Zusatzziffer": "1",
    "Gemeindename": "Goms",
    "BFS-Nr": "6077",
    "Kantonskürzel": "VS",
    "E": "2660944.978",
    "N": "1146191.3949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Fortunau (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "2",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2598251.1689999998",
    "N": "1125989.7690000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Luc (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "3",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2598415.2639999986",
    "N": "1125709.5670000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Romain (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "4",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2598009.1169999987",
    "N": "1125689.802000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saxonne (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "5",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2597554.335999999",
    "N": "1125424.1939999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villa (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "6",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2598226.041000001",
    "N": "1124941.4629999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Place (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "7",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2597973.1889999993",
    "N": "1124728.9290000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Botyre (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "8",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2597370.6099999994",
    "N": "1124987.6590000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Blignou (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "9",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2596922.8770000003",
    "N": "1124521.5859999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Argnou (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "10",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2596987.204999998",
    "N": "1123499.682",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Signèse (Ayent)",
    "PLZ": "1966",
    "Zusatzziffer": "11",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2597105.715999998",
    "N": "1122892.8000000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Anzère",
    "PLZ": "1972",
    "Zusatzziffer": "0",
    "Gemeindename": "Ayent",
    "BFS-Nr": "6082",
    "Kantonskürzel": "VS",
    "E": "2596760.938000001",
    "N": "1127197.8489999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Evolène",
    "PLZ": "1983",
    "Zusatzziffer": "0",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2604433.6209999993",
    "N": "1106841.1169999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lanna",
    "PLZ": "1983",
    "Zusatzziffer": "2",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2603086.102000002",
    "N": "1107710.5920000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Haudères",
    "PLZ": "1984",
    "Zusatzziffer": "0",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2605415.9530000016",
    "N": "1103658.892000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Tour VS",
    "PLZ": "1984",
    "Zusatzziffer": "1",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2605186.9310000017",
    "N": "1105161.9279999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Sage",
    "PLZ": "1985",
    "Zusatzziffer": "0",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2605860.5890000015",
    "N": "1105261.2980000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Forclaz VS",
    "PLZ": "1985",
    "Zusatzziffer": "1",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2606102.971999999",
    "N": "1103831.5980000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villa (Evolène)",
    "PLZ": "1985",
    "Zusatzziffer": "2",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2605704.734000001",
    "N": "1106097.6380000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arolla",
    "PLZ": "1986",
    "Zusatzziffer": "0",
    "Gemeindename": "Evolène",
    "BFS-Nr": "6083",
    "Kantonskürzel": "VS",
    "E": "2603452.478",
    "N": "1097032.3759999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Euseigne",
    "PLZ": "1982",
    "Zusatzziffer": "0",
    "Gemeindename": "Hérémence",
    "BFS-Nr": "6084",
    "Kantonskürzel": "VS",
    "E": "2598738.6180000007",
    "N": "1113352.256000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Hérémence",
    "PLZ": "1987",
    "Zusatzziffer": "0",
    "Gemeindename": "Hérémence",
    "BFS-Nr": "6084",
    "Kantonskürzel": "VS",
    "E": "2597365.1279999986",
    "N": "1114341.7829999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Martin VS",
    "PLZ": "1969",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Martin (VS)",
    "BFS-Nr": "6087",
    "Kantonskürzel": "VS",
    "E": "2600396.272",
    "N": "1113442.7509999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Liez (St-Martin)",
    "PLZ": "1969",
    "Zusatzziffer": "2",
    "Gemeindename": "Saint-Martin (VS)",
    "BFS-Nr": "6087",
    "Kantonskürzel": "VS",
    "E": "2600946.267000001",
    "N": "1112337.0500000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Trogne (St-Martin)",
    "PLZ": "1969",
    "Zusatzziffer": "3",
    "Gemeindename": "Saint-Martin (VS)",
    "BFS-Nr": "6087",
    "Kantonskürzel": "VS",
    "E": "2600999.728",
    "N": "1112931.2250000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Suen (St-Martin)",
    "PLZ": "1969",
    "Zusatzziffer": "4",
    "Gemeindename": "Saint-Martin (VS)",
    "BFS-Nr": "6087",
    "Kantonskürzel": "VS",
    "E": "2599866.84",
    "N": "1114443.1939999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Eison (St-Martin)",
    "PLZ": "1969",
    "Zusatzziffer": "5",
    "Gemeindename": "Saint-Martin (VS)",
    "BFS-Nr": "6087",
    "Kantonskürzel": "VS",
    "E": "2602171.647",
    "N": "1111337.9780000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vex",
    "PLZ": "1981",
    "Zusatzziffer": "0",
    "Gemeindename": "Vex",
    "BFS-Nr": "6089",
    "Kantonskürzel": "VS",
    "E": "2596813.602000002",
    "N": "1117821.879999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Thyon",
    "PLZ": "1988",
    "Zusatzziffer": "1",
    "Gemeindename": "Vex",
    "BFS-Nr": "6089",
    "Kantonskürzel": "VS",
    "E": "2594944.0830000006",
    "N": "1114448.960000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Collons",
    "PLZ": "1988",
    "Zusatzziffer": "2",
    "Gemeindename": "Vex",
    "BFS-Nr": "6089",
    "Kantonskürzel": "VS",
    "E": "2595948.8649999984",
    "N": "1114854.1240000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Mayens-de-Sion",
    "PLZ": "1992",
    "Zusatzziffer": "1",
    "Gemeindename": "Vex",
    "BFS-Nr": "6089",
    "Kantonskürzel": "VS",
    "E": "2595565.6680000015",
    "N": "1116732.1550000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vernamiège",
    "PLZ": "1961",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Noble",
    "BFS-Nr": "6090",
    "Kantonskürzel": "VS",
    "E": "2599947.4459999986",
    "N": "1117634.079",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mase",
    "PLZ": "1968",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Noble",
    "BFS-Nr": "6090",
    "Kantonskürzel": "VS",
    "E": "2599834.6950000003",
    "N": "1115972.9450000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Nax",
    "PLZ": "1973",
    "Zusatzziffer": "0",
    "Gemeindename": "Mont-Noble",
    "BFS-Nr": "6090",
    "Kantonskürzel": "VS",
    "E": "2599164.7540000007",
    "N": "1119673.1380000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Agarn",
    "PLZ": "3951",
    "Zusatzziffer": "0",
    "Gemeindename": "Agarn",
    "BFS-Nr": "6101",
    "Kantonskürzel": "VS",
    "E": "2617311.6669999994",
    "N": "1127274.8900000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Albinen",
    "PLZ": "3955",
    "Zusatzziffer": "0",
    "Gemeindename": "Albinen",
    "BFS-Nr": "6102",
    "Kantonskürzel": "VS",
    "E": "2614990.7430000007",
    "N": "1132128.3990000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ergisch",
    "PLZ": "3947",
    "Zusatzziffer": "0",
    "Gemeindename": "Ergisch",
    "BFS-Nr": "6104",
    "Kantonskürzel": "VS",
    "E": "2621147.7580000013",
    "N": "1126654.7589999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Inden",
    "PLZ": "3953",
    "Zusatzziffer": "1",
    "Gemeindename": "Inden",
    "BFS-Nr": "6109",
    "Kantonskürzel": "VS",
    "E": "2613855.2239999995",
    "N": "1132511.004999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Susten",
    "PLZ": "3952",
    "Zusatzziffer": "0",
    "Gemeindename": "Leuk",
    "BFS-Nr": "6110",
    "Kantonskürzel": "VS",
    "E": "2615748.2399999984",
    "N": "1128567.7520000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leuk Stadt",
    "PLZ": "3953",
    "Zusatzziffer": "0",
    "Gemeindename": "Leuk",
    "BFS-Nr": "6110",
    "Kantonskürzel": "VS",
    "E": "2615130.530000001",
    "N": "1129570.8319999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Erschmatt",
    "PLZ": "3957",
    "Zusatzziffer": "0",
    "Gemeindename": "Leuk",
    "BFS-Nr": "6110",
    "Kantonskürzel": "VS",
    "E": "2619564.1440000013",
    "N": "1130046.807",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Leukerbad",
    "PLZ": "3954",
    "Zusatzziffer": "0",
    "Gemeindename": "Leukerbad",
    "BFS-Nr": "6111",
    "Kantonskürzel": "VS",
    "E": "2614665.515999999",
    "N": "1136463.960000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Oberems",
    "PLZ": "3948",
    "Zusatzziffer": "1",
    "Gemeindename": "Oberems",
    "BFS-Nr": "6112",
    "Kantonskürzel": "VS",
    "E": "2619755.7859999985",
    "N": "1125594.0700000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Salgesch",
    "PLZ": "3970",
    "Zusatzziffer": "0",
    "Gemeindename": "Salgesch",
    "BFS-Nr": "6113",
    "Kantonskürzel": "VS",
    "E": "2610178.91",
    "N": "1128933.796",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Varen",
    "PLZ": "3953",
    "Zusatzziffer": "2",
    "Gemeindename": "Varen",
    "BFS-Nr": "6116",
    "Kantonskürzel": "VS",
    "E": "2612991.5749999993",
    "N": "1129472.2479999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Guttet-Feschel",
    "PLZ": "3956",
    "Zusatzziffer": "0",
    "Gemeindename": "Guttet-Feschel",
    "BFS-Nr": "6117",
    "Kantonskürzel": "VS",
    "E": "2617459.59",
    "N": "1130416.2109999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gampel",
    "PLZ": "3945",
    "Zusatzziffer": "0",
    "Gemeindename": "Gampel-Bratsch",
    "BFS-Nr": "6118",
    "Kantonskürzel": "VS",
    "E": "2623344.443",
    "N": "1129414.6860000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niedergampel",
    "PLZ": "3945",
    "Zusatzziffer": "1",
    "Gemeindename": "Gampel-Bratsch",
    "BFS-Nr": "6118",
    "Kantonskürzel": "VS",
    "E": "2621219.822999999",
    "N": "1129015.9580000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bratsch",
    "PLZ": "3957",
    "Zusatzziffer": "2",
    "Gemeindename": "Gampel-Bratsch",
    "BFS-Nr": "6118",
    "Kantonskürzel": "VS",
    "E": "2620822.813000001",
    "N": "1129885.2080000006",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Turtmann",
    "PLZ": "3946",
    "Zusatzziffer": "0",
    "Gemeindename": "Turtmann-Unterems",
    "BFS-Nr": "6119",
    "Kantonskürzel": "VS",
    "E": "2620357.1339999996",
    "N": "1127790.4620000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gruben",
    "PLZ": "3946",
    "Zusatzziffer": "2",
    "Gemeindename": "Turtmann-Unterems",
    "BFS-Nr": "6119",
    "Kantonskürzel": "VS",
    "E": "2620626.3960000016",
    "N": "1117734.1259999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterems",
    "PLZ": "3948",
    "Zusatzziffer": "0",
    "Gemeindename": "Turtmann-Unterems",
    "BFS-Nr": "6119",
    "Kantonskürzel": "VS",
    "E": "2619885.1840000004",
    "N": "1126766.2829999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bovernier",
    "PLZ": "1932",
    "Zusatzziffer": "0",
    "Gemeindename": "Bovernier",
    "BFS-Nr": "6131",
    "Kantonskürzel": "VS",
    "E": "2572828.162999999",
    "N": "1103221.7789999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Valettes (Bovernier)",
    "PLZ": "1932",
    "Zusatzziffer": "2",
    "Gemeindename": "Bovernier",
    "BFS-Nr": "6131",
    "Kantonskürzel": "VS",
    "E": "2571832.721000001",
    "N": "1102747.0439999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fully",
    "PLZ": "1926",
    "Zusatzziffer": "0",
    "Gemeindename": "Fully",
    "BFS-Nr": "6133",
    "Kantonskürzel": "VS",
    "E": "2574888.954999998",
    "N": "1109689.3599999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Isérables",
    "PLZ": "1914",
    "Zusatzziffer": "0",
    "Gemeindename": "Isérables",
    "BFS-Nr": "6134",
    "Kantonskürzel": "VS",
    "E": "2585048.851",
    "N": "1112243.182",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ovronnaz",
    "PLZ": "1911",
    "Zusatzziffer": "0",
    "Gemeindename": "Leytron",
    "BFS-Nr": "6135",
    "Kantonskürzel": "VS",
    "E": "2579431.590999998",
    "N": "1116473.0709999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Leytron",
    "PLZ": "1912",
    "Zusatzziffer": "0",
    "Gemeindename": "Leytron",
    "BFS-Nr": "6135",
    "Kantonskürzel": "VS",
    "E": "2582706.5309999995",
    "N": "1115180.3440000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Produit (Leytron)",
    "PLZ": "1912",
    "Zusatzziffer": "2",
    "Gemeindename": "Leytron",
    "BFS-Nr": "6135",
    "Kantonskürzel": "VS",
    "E": "2581168.8209999986",
    "N": "1115038.0439999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montagnon (Leytron)",
    "PLZ": "1912",
    "Zusatzziffer": "3",
    "Gemeindename": "Leytron",
    "BFS-Nr": "6135",
    "Kantonskürzel": "VS",
    "E": "2581027.796",
    "N": "1115459.6609999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dugny (Leytron)",
    "PLZ": "1912",
    "Zusatzziffer": "4",
    "Gemeindename": "Leytron",
    "BFS-Nr": "6135",
    "Kantonskürzel": "VS",
    "E": "2580256.9400000013",
    "N": "1115350.6380000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Charrat",
    "PLZ": "1906",
    "Zusatzziffer": "0",
    "Gemeindename": "Martigny",
    "BFS-Nr": "6136",
    "Kantonskürzel": "VS",
    "E": "2576126.5",
    "N": "1108046.2190000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Martigny",
    "PLZ": "1920",
    "Zusatzziffer": "0",
    "Gemeindename": "Martigny",
    "BFS-Nr": "6136",
    "Kantonskürzel": "VS",
    "E": "2571782.7490000017",
    "N": "1105522.8440000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Martigny-Croix",
    "PLZ": "1921",
    "Zusatzziffer": "0",
    "Gemeindename": "Martigny-Combe",
    "BFS-Nr": "6137",
    "Kantonskürzel": "VS",
    "E": "2570244.149",
    "N": "1103967.5309999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ravoire",
    "PLZ": "1928",
    "Zusatzziffer": "0",
    "Gemeindename": "Martigny-Combe",
    "BFS-Nr": "6137",
    "Kantonskürzel": "VS",
    "E": "2569634.6750000007",
    "N": "1105148.868999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Riddes",
    "PLZ": "1908",
    "Zusatzziffer": "0",
    "Gemeindename": "Riddes",
    "BFS-Nr": "6139",
    "Kantonskürzel": "VS",
    "E": "2583143.5029999986",
    "N": "1113044.8940000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Auddes-sur-Riddes",
    "PLZ": "1914",
    "Zusatzziffer": "2",
    "Gemeindename": "Riddes",
    "BFS-Nr": "6139",
    "Kantonskürzel": "VS",
    "E": "2584968.8729999997",
    "N": "1111456.7129999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Tzoumaz",
    "PLZ": "1918",
    "Zusatzziffer": "0",
    "Gemeindename": "Riddes",
    "BFS-Nr": "6139",
    "Kantonskürzel": "VS",
    "E": "2584309.151999999",
    "N": "1110327.754999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saillon",
    "PLZ": "1913",
    "Zusatzziffer": "0",
    "Gemeindename": "Saillon",
    "BFS-Nr": "6140",
    "Kantonskürzel": "VS",
    "E": "2580390.642999999",
    "N": "1113354.9780000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saxon",
    "PLZ": "1907",
    "Zusatzziffer": "0",
    "Gemeindename": "Saxon",
    "BFS-Nr": "6141",
    "Kantonskürzel": "VS",
    "E": "2580073.291000001",
    "N": "1110460.8979999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Trient",
    "PLZ": "1929",
    "Zusatzziffer": "0",
    "Gemeindename": "Trient",
    "BFS-Nr": "6142",
    "Kantonskürzel": "VS",
    "E": "2565644.585999999",
    "N": "1100595.015999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champéry",
    "PLZ": "1874",
    "Zusatzziffer": "0",
    "Gemeindename": "Champéry",
    "BFS-Nr": "6151",
    "Kantonskürzel": "VS",
    "E": "2556109.0439999998",
    "N": "1114250.4510000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Collombey",
    "PLZ": "1868",
    "Zusatzziffer": "0",
    "Gemeindename": "Collombey-Muraz",
    "BFS-Nr": "6152",
    "Kantonskürzel": "VS",
    "E": "2561106.302000001",
    "N": "1122538.6660000011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Collombey",
    "PLZ": "1868",
    "Zusatzziffer": "0",
    "Gemeindename": "Collombey-Muraz",
    "BFS-Nr": "6152",
    "Kantonskürzel": "VS",
    "E": "2562047.5989999995",
    "N": "1124182.3999999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Muraz (Collombey)",
    "PLZ": "1893",
    "Zusatzziffer": "0",
    "Gemeindename": "Collombey-Muraz",
    "BFS-Nr": "6152",
    "Kantonskürzel": "VS",
    "E": "2560468.914000001",
    "N": "1125550.1510000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Monthey",
    "PLZ": "1870",
    "Zusatzziffer": "0",
    "Gemeindename": "Monthey",
    "BFS-Nr": "6153",
    "Kantonskürzel": "VS",
    "E": "2562086.1909999996",
    "N": "1122482.0500000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Monthey",
    "PLZ": "1870",
    "Zusatzziffer": "0",
    "Gemeindename": "Monthey",
    "BFS-Nr": "6153",
    "Kantonskürzel": "VS",
    "E": "2551884.118999999",
    "N": "1117479.5450000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Choëx",
    "PLZ": "1871",
    "Zusatzziffer": "0",
    "Gemeindename": "Monthey",
    "BFS-Nr": "6153",
    "Kantonskürzel": "VS",
    "E": "2563159.3189999983",
    "N": "1121462.699000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Giettes",
    "PLZ": "1871",
    "Zusatzziffer": "2",
    "Gemeindename": "Monthey",
    "BFS-Nr": "6153",
    "Kantonskürzel": "VS",
    "E": "2563253.3150000013",
    "N": "1119392.7710000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bouveret",
    "PLZ": "1897",
    "Zusatzziffer": "0",
    "Gemeindename": "Port-Valais",
    "BFS-Nr": "6154",
    "Kantonskürzel": "VS",
    "E": "2554808.2479999997",
    "N": "1137210.9679999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Evouettes",
    "PLZ": "1897",
    "Zusatzziffer": "3",
    "Gemeindename": "Port-Valais",
    "BFS-Nr": "6154",
    "Kantonskürzel": "VS",
    "E": "2556775.5599999987",
    "N": "1134721.9160000011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Gingolph",
    "PLZ": "1898",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Gingolph",
    "BFS-Nr": "6155",
    "Kantonskürzel": "VS",
    "E": "2551186.3680000007",
    "N": "1138112.8029999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Troistorrents",
    "PLZ": "1872",
    "Zusatzziffer": "0",
    "Gemeindename": "Troistorrents",
    "BFS-Nr": "6156",
    "Kantonskürzel": "VS",
    "E": "2559911.4739999995",
    "N": "1119946.3139999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Morgins",
    "PLZ": "1875",
    "Zusatzziffer": "0",
    "Gemeindename": "Troistorrents",
    "BFS-Nr": "6156",
    "Kantonskürzel": "VS",
    "E": "2555170.2989999987",
    "N": "1120809.5989999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Val-d'Illiez",
    "PLZ": "1873",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-d'Illiez",
    "BFS-Nr": "6157",
    "Kantonskürzel": "VS",
    "E": "2557892.208999999",
    "N": "1117146.3530000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champoussin",
    "PLZ": "1873",
    "Zusatzziffer": "2",
    "Gemeindename": "Val-d'Illiez",
    "BFS-Nr": "6157",
    "Kantonskürzel": "VS",
    "E": "2555664.853",
    "N": "1117702.3139999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Crosets",
    "PLZ": "1873",
    "Zusatzziffer": "3",
    "Gemeindename": "Val-d'Illiez",
    "BFS-Nr": "6157",
    "Kantonskürzel": "VS",
    "E": "2553725.289999999",
    "N": "1115074.8680000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vionnaz",
    "PLZ": "1895",
    "Zusatzziffer": "0",
    "Gemeindename": "Vionnaz",
    "BFS-Nr": "6158",
    "Kantonskürzel": "VS",
    "E": "2558478.1510000005",
    "N": "1128762.835000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Torgon",
    "PLZ": "1899",
    "Zusatzziffer": "0",
    "Gemeindename": "Vionnaz",
    "BFS-Nr": "6158",
    "Kantonskürzel": "VS",
    "E": "2556698.204",
    "N": "1129973.8539999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vouvry",
    "PLZ": "1896",
    "Zusatzziffer": "0",
    "Gemeindename": "Vouvry",
    "BFS-Nr": "6159",
    "Kantonskürzel": "VS",
    "E": "2557449.2140000015",
    "N": "1131710.1640000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Miex",
    "PLZ": "1896",
    "Zusatzziffer": "2",
    "Gemeindename": "Vouvry",
    "BFS-Nr": "6159",
    "Kantonskürzel": "VS",
    "E": "2555729.5439999998",
    "N": "1132058.995000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bister",
    "PLZ": "3983",
    "Zusatzziffer": "2",
    "Gemeindename": "Bister",
    "BFS-Nr": "6172",
    "Kantonskürzel": "VS",
    "E": "2648289.9450000003",
    "N": "1134692.620000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bitsch",
    "PLZ": "3982",
    "Zusatzziffer": "0",
    "Gemeindename": "Bitsch",
    "BFS-Nr": "6173",
    "Kantonskürzel": "VS",
    "E": "2644550.2300000004",
    "N": "1131787.3949999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grengiols",
    "PLZ": "3993",
    "Zusatzziffer": "0",
    "Gemeindename": "Grengiols",
    "BFS-Nr": "6177",
    "Kantonskürzel": "VS",
    "E": "2650388.254999999",
    "N": "1135810.8200000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Goppisberg",
    "PLZ": "3983",
    "Zusatzziffer": "4",
    "Gemeindename": "Riederalp",
    "BFS-Nr": "6181",
    "Kantonskürzel": "VS",
    "E": "2647082.8000000007",
    "N": "1135755.3000000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Greich",
    "PLZ": "3983",
    "Zusatzziffer": "5",
    "Gemeindename": "Riederalp",
    "BFS-Nr": "6181",
    "Kantonskürzel": "VS",
    "E": "2646232.215999998",
    "N": "1135185.4499999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ried-Mörel",
    "PLZ": "3986",
    "Zusatzziffer": "0",
    "Gemeindename": "Riederalp",
    "BFS-Nr": "6181",
    "Kantonskürzel": "VS",
    "E": "2645774.9389999993",
    "N": "1134085.511",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Riederalp",
    "PLZ": "3987",
    "Zusatzziffer": "0",
    "Gemeindename": "Riederalp",
    "BFS-Nr": "6181",
    "Kantonskürzel": "VS",
    "E": "2645322.927000001",
    "N": "1136414.2919999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ausserberg",
    "PLZ": "3938",
    "Zusatzziffer": "0",
    "Gemeindename": "Ausserberg",
    "BFS-Nr": "6191",
    "Kantonskürzel": "VS",
    "E": "2631770.909000002",
    "N": "1129297.9759999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Blatten (Lötschen)",
    "PLZ": "3919",
    "Zusatzziffer": "0",
    "Gemeindename": "Blatten",
    "BFS-Nr": "6192",
    "Kantonskürzel": "VS",
    "E": "2629270.5029999986",
    "N": "1141080.688000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bürchen",
    "PLZ": "3935",
    "Zusatzziffer": "0",
    "Gemeindename": "Bürchen",
    "BFS-Nr": "6193",
    "Kantonskürzel": "VS",
    "E": "2629117.3079999983",
    "N": "1125723.199000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eischoll",
    "PLZ": "3943",
    "Zusatzziffer": "0",
    "Gemeindename": "Eischoll",
    "BFS-Nr": "6194",
    "Kantonskürzel": "VS",
    "E": "2626246.969999999",
    "N": "1127097.8489999995",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ferden",
    "PLZ": "3916",
    "Zusatzziffer": "0",
    "Gemeindename": "Ferden",
    "BFS-Nr": "6195",
    "Kantonskürzel": "VS",
    "E": "2624671.4930000007",
    "N": "1138113.9880000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Goppenstein",
    "PLZ": "3917",
    "Zusatzziffer": "2",
    "Gemeindename": "Ferden",
    "BFS-Nr": "6195",
    "Kantonskürzel": "VS",
    "E": "2624339.2529999986",
    "N": "1135229.3060000017",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kippel",
    "PLZ": "3917",
    "Zusatzziffer": "0",
    "Gemeindename": "Kippel",
    "BFS-Nr": "6197",
    "Kantonskürzel": "VS",
    "E": "2625743.1860000007",
    "N": "1138679.8889999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Niedergesteln",
    "PLZ": "3942",
    "Zusatzziffer": "2",
    "Gemeindename": "Niedergesteln",
    "BFS-Nr": "6198",
    "Kantonskürzel": "VS",
    "E": "2626432.0760000013",
    "N": "1129110.8000000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Raron",
    "PLZ": "3942",
    "Zusatzziffer": "0",
    "Gemeindename": "Raron",
    "BFS-Nr": "6199",
    "Kantonskürzel": "VS",
    "E": "2627846.1180000007",
    "N": "1128948.061999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. German",
    "PLZ": "3942",
    "Zusatzziffer": "3",
    "Gemeindename": "Raron",
    "BFS-Nr": "6199",
    "Kantonskürzel": "VS",
    "E": "2629448.102000002",
    "N": "1129058.4679999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Unterbäch VS",
    "PLZ": "3944",
    "Zusatzziffer": "0",
    "Gemeindename": "Unterbäch",
    "BFS-Nr": "6201",
    "Kantonskürzel": "VS",
    "E": "2627872.4010000005",
    "N": "1126004.015999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Wiler (Lötschen)",
    "PLZ": "3918",
    "Zusatzziffer": "0",
    "Gemeindename": "Wiler (Lötschen)",
    "BFS-Nr": "6202",
    "Kantonskürzel": "VS",
    "E": "2626524.7080000006",
    "N": "1139263.300999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mörel",
    "PLZ": "3983",
    "Zusatzziffer": "0",
    "Gemeindename": "Mörel-Filet",
    "BFS-Nr": "6203",
    "Kantonskürzel": "VS",
    "E": "2646642.261",
    "N": "1134023.2969999984",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Filet",
    "PLZ": "3983",
    "Zusatzziffer": "3",
    "Gemeindename": "Mörel-Filet",
    "BFS-Nr": "6203",
    "Kantonskürzel": "VS",
    "E": "2647244.4299999997",
    "N": "1134595.4569999985",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Steg VS",
    "PLZ": "3940",
    "Zusatzziffer": "0",
    "Gemeindename": "Steg-Hohtenn",
    "BFS-Nr": "6204",
    "Kantonskürzel": "VS",
    "E": "2623749.8000000007",
    "N": "1129331.8079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Hohtenn",
    "PLZ": "3949",
    "Zusatzziffer": "0",
    "Gemeindename": "Steg-Hohtenn",
    "BFS-Nr": "6204",
    "Kantonskürzel": "VS",
    "E": "2624470.482000001",
    "N": "1129743.8269999996",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Betten",
    "PLZ": "3991",
    "Zusatzziffer": "0",
    "Gemeindename": "Bettmeralp",
    "BFS-Nr": "6205",
    "Kantonskürzel": "VS",
    "E": "2648533.920000002",
    "N": "1136353.9349999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bettmeralp",
    "PLZ": "3992",
    "Zusatzziffer": "0",
    "Gemeindename": "Bettmeralp",
    "BFS-Nr": "6205",
    "Kantonskürzel": "VS",
    "E": "2647993.971000001",
    "N": "1137827.7879999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Martisberg",
    "PLZ": "3994",
    "Zusatzziffer": "2",
    "Gemeindename": "Bettmeralp",
    "BFS-Nr": "6205",
    "Kantonskürzel": "VS",
    "E": "2650873.7569999993",
    "N": "1137363.2159999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Collonges",
    "PLZ": "1903",
    "Zusatzziffer": "0",
    "Gemeindename": "Collonges",
    "BFS-Nr": "6211",
    "Kantonskürzel": "VS",
    "E": "2568743.6229999997",
    "N": "1113431.425999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dorénaz",
    "PLZ": "1905",
    "Zusatzziffer": "0",
    "Gemeindename": "Dorénaz",
    "BFS-Nr": "6212",
    "Kantonskürzel": "VS",
    "E": "2569342.8200000003",
    "N": "1110904.9659999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Evionnaz",
    "PLZ": "1902",
    "Zusatzziffer": "0",
    "Gemeindename": "Evionnaz",
    "BFS-Nr": "6213",
    "Kantonskürzel": "VS",
    "E": "2567948.3550000004",
    "N": "1114196.2780000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Finhaut",
    "PLZ": "1925",
    "Zusatzziffer": "0",
    "Gemeindename": "Finhaut",
    "BFS-Nr": "6214",
    "Kantonskürzel": "VS",
    "E": "2564065.9340000004",
    "N": "1103539.9649999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Châtelard VS",
    "PLZ": "1925",
    "Zusatzziffer": "1",
    "Gemeindename": "Finhaut",
    "BFS-Nr": "6214",
    "Kantonskürzel": "VS",
    "E": "2562705.0139999986",
    "N": "1100833.300999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Massongex",
    "PLZ": "1869",
    "Zusatzziffer": "0",
    "Gemeindename": "Massongex",
    "BFS-Nr": "6215",
    "Kantonskürzel": "VS",
    "E": "2565364.204",
    "N": "1121344.243999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Maurice",
    "PLZ": "1890",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Maurice",
    "BFS-Nr": "6217",
    "Kantonskürzel": "VS",
    "E": "2566388.2190000005",
    "N": "1118528.870000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mex VS",
    "PLZ": "1890",
    "Zusatzziffer": "1",
    "Gemeindename": "Saint-Maurice",
    "BFS-Nr": "6217",
    "Kantonskürzel": "VS",
    "E": "2566097.853",
    "N": "1114930.4389999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Salvan",
    "PLZ": "1922",
    "Zusatzziffer": "0",
    "Gemeindename": "Salvan",
    "BFS-Nr": "6218",
    "Kantonskürzel": "VS",
    "E": "2567710.072999999",
    "N": "1107767.8090000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Granges (Salvan)",
    "PLZ": "1922",
    "Zusatzziffer": "1",
    "Gemeindename": "Salvan",
    "BFS-Nr": "6218",
    "Kantonskürzel": "VS",
    "E": "2567787.5780000016",
    "N": "1108587.2729999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Marécottes",
    "PLZ": "1923",
    "Zusatzziffer": "0",
    "Gemeindename": "Salvan",
    "BFS-Nr": "6218",
    "Kantonskürzel": "VS",
    "E": "2566788.3110000007",
    "N": "1106871.6559999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Trétien",
    "PLZ": "1923",
    "Zusatzziffer": "2",
    "Gemeindename": "Salvan",
    "BFS-Nr": "6218",
    "Kantonskürzel": "VS",
    "E": "2565959.0529999994",
    "N": "1105595.3489999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vernayaz",
    "PLZ": "1904",
    "Zusatzziffer": "0",
    "Gemeindename": "Vernayaz",
    "BFS-Nr": "6219",
    "Kantonskürzel": "VS",
    "E": "2569094.3830000013",
    "N": "1109281.390999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vérossaz",
    "PLZ": "1891",
    "Zusatzziffer": "11",
    "Gemeindename": "Vérossaz",
    "BFS-Nr": "6220",
    "Kantonskürzel": "VS",
    "E": "2565251.4679999985",
    "N": "1117775.8379999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chalais",
    "PLZ": "3966",
    "Zusatzziffer": "0",
    "Gemeindename": "Chalais",
    "BFS-Nr": "6232",
    "Kantonskürzel": "VS",
    "E": "2605087.8889999986",
    "N": "1123757.995000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Réchy",
    "PLZ": "3966",
    "Zusatzziffer": "1",
    "Gemeindename": "Chalais",
    "BFS-Nr": "6232",
    "Kantonskürzel": "VS",
    "E": "2604513.2399999984",
    "N": "1123369.2740000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vercorin",
    "PLZ": "3967",
    "Zusatzziffer": "0",
    "Gemeindename": "Chalais",
    "BFS-Nr": "6232",
    "Kantonskürzel": "VS",
    "E": "2607286.300999999",
    "N": "1122899.664999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chippis",
    "PLZ": "3965",
    "Zusatzziffer": "0",
    "Gemeindename": "Chippis",
    "BFS-Nr": "6235",
    "Kantonskürzel": "VS",
    "E": "2607830.7349999994",
    "N": "1125466.1149999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grône",
    "PLZ": "3979",
    "Zusatzziffer": "0",
    "Gemeindename": "Grône",
    "BFS-Nr": "6238",
    "Kantonskürzel": "VS",
    "E": "2601209.9439999983",
    "N": "1122251.4800000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Icogne",
    "PLZ": "1977",
    "Zusatzziffer": "0",
    "Gemeindename": "Icogne",
    "BFS-Nr": "6239",
    "Kantonskürzel": "VS",
    "E": "2599992.5489999987",
    "N": "1126590.3790000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lens",
    "PLZ": "1978",
    "Zusatzziffer": "0",
    "Gemeindename": "Lens",
    "BFS-Nr": "6240",
    "Kantonskürzel": "VS",
    "E": "2600520.9400000013",
    "N": "1125280.6149999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Flanthey",
    "PLZ": "3978",
    "Zusatzziffer": "0",
    "Gemeindename": "Lens",
    "BFS-Nr": "6240",
    "Kantonskürzel": "VS",
    "E": "2601178.1039999984",
    "N": "1124268.563000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Léonard",
    "PLZ": "1958",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Léonard",
    "BFS-Nr": "6246",
    "Kantonskürzel": "VS",
    "E": "2598544.1400000006",
    "N": "1122718.4809999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sierre",
    "PLZ": "3960",
    "Zusatzziffer": "0",
    "Gemeindename": "Sierre",
    "BFS-Nr": "6248",
    "Kantonskürzel": "VS",
    "E": "2607529.386",
    "N": "1126915.6389999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Muraz (Sierre)",
    "PLZ": "3960",
    "Zusatzziffer": "5",
    "Gemeindename": "Sierre",
    "BFS-Nr": "6248",
    "Kantonskürzel": "VS",
    "E": "2607017.784000002",
    "N": "1127349.6600000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Noës",
    "PLZ": "3976",
    "Zusatzziffer": "0",
    "Gemeindename": "Sierre",
    "BFS-Nr": "6248",
    "Kantonskürzel": "VS",
    "E": "2605233.476",
    "N": "1125416.2179999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Granges VS",
    "PLZ": "3977",
    "Zusatzziffer": "0",
    "Gemeindename": "Sierre",
    "BFS-Nr": "6248",
    "Kantonskürzel": "VS",
    "E": "2601998.9499999993",
    "N": "1123114.0089999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Niouc",
    "PLZ": "3960",
    "Zusatzziffer": "4",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2608748.1739999987",
    "N": "1124393.7430000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vissoie",
    "PLZ": "3961",
    "Zusatzziffer": "21",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2611302.465999998",
    "N": "1118213.0769999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Luc",
    "PLZ": "3961",
    "Zusatzziffer": "22",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2612278.432",
    "N": "1118759.1730000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chandolin",
    "PLZ": "3961",
    "Zusatzziffer": "23",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2611662.0009999983",
    "N": "1122349.2019999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ayer",
    "PLZ": "3961",
    "Zusatzziffer": "24",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2612682.6799999997",
    "N": "1114164.5709999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Zinal",
    "PLZ": "3961",
    "Zusatzziffer": "25",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2614524.6730000004",
    "N": "1109389.517000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grimentz",
    "PLZ": "3961",
    "Zusatzziffer": "26",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2610609.6750000007",
    "N": "1114178.0249999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mission",
    "PLZ": "3961",
    "Zusatzziffer": "27",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2612044.5780000016",
    "N": "1115688.0309999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Jean VS",
    "PLZ": "3961",
    "Zusatzziffer": "41",
    "Gemeindename": "Anniviers",
    "BFS-Nr": "6252",
    "Kantonskürzel": "VS",
    "E": "2611290.511",
    "N": "1116117.927000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corin-de-la-Crête",
    "PLZ": "3960",
    "Zusatzziffer": "2",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2604946.943",
    "N": "1125902.6400000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Loc",
    "PLZ": "3960",
    "Zusatzziffer": "3",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2605586.721000001",
    "N": "1126898.8209999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Crans-Montana",
    "PLZ": "3963",
    "Zusatzziffer": "0",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2603315.460000001",
    "N": "1129036.614",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montana",
    "PLZ": "3963",
    "Zusatzziffer": "3",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2603955.217",
    "N": "1127333.960000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aminona",
    "PLZ": "3963",
    "Zusatzziffer": "4",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2606702.7529999986",
    "N": "1131248.0359999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chermignon",
    "PLZ": "3971",
    "Zusatzziffer": "0",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2602782.5879999995",
    "N": "1126158.4109999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chermignon-d'en-Bas",
    "PLZ": "3971",
    "Zusatzziffer": "1",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2603379.215999998",
    "N": "1125546.1330000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ollon VS",
    "PLZ": "3971",
    "Zusatzziffer": "2",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2602990.5199999996",
    "N": "1124699.6240000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mollens VS",
    "PLZ": "3974",
    "Zusatzziffer": "0",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2606328.8649999984",
    "N": "1129449.3559999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Randogne",
    "PLZ": "3975",
    "Zusatzziffer": "0",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2605557.908",
    "N": "1129068.8139999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champzabé",
    "PLZ": "3976",
    "Zusatzziffer": "2",
    "Gemeindename": "Crans-Montana",
    "BFS-Nr": "6253",
    "Kantonskürzel": "VS",
    "E": "2604595.9360000007",
    "N": "1125312.2360000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Veyras",
    "PLZ": "3968",
    "Zusatzziffer": "0",
    "Gemeindename": "Noble-Contrée",
    "BFS-Nr": "6254",
    "Kantonskürzel": "VS",
    "E": "2607580.4310000017",
    "N": "1127897.9010000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Miège",
    "PLZ": "3972",
    "Zusatzziffer": "0",
    "Gemeindename": "Noble-Contrée",
    "BFS-Nr": "6254",
    "Kantonskürzel": "VS",
    "E": "2608363.9560000002",
    "N": "1128975.7980000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Venthône",
    "PLZ": "3973",
    "Zusatzziffer": "0",
    "Gemeindename": "Noble-Contrée",
    "BFS-Nr": "6254",
    "Kantonskürzel": "VS",
    "E": "2607191.403000001",
    "N": "1128486.4549999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arbaz",
    "PLZ": "1974",
    "Zusatzziffer": "0",
    "Gemeindename": "Arbaz",
    "BFS-Nr": "6261",
    "Kantonskürzel": "VS",
    "E": "2595748.4329999983",
    "N": "1124539.6030000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grimisuat",
    "PLZ": "1971",
    "Zusatzziffer": "0",
    "Gemeindename": "Grimisuat",
    "BFS-Nr": "6263",
    "Kantonskürzel": "VS",
    "E": "2595910.5749999993",
    "N": "1123166.3049999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champlan (Grimisuat)",
    "PLZ": "1971",
    "Zusatzziffer": "2",
    "Gemeindename": "Grimisuat",
    "BFS-Nr": "6263",
    "Kantonskürzel": "VS",
    "E": "2595017.4290000014",
    "N": "1121722.6240000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Savièse",
    "PLZ": "1965",
    "Zusatzziffer": "0",
    "Gemeindename": "Savièse",
    "BFS-Nr": "6265",
    "Kantonskürzel": "VS",
    "E": "2593146.2859999985",
    "N": "1122152.7479999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Sion",
    "PLZ": "1950",
    "Zusatzziffer": "0",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2594086.899",
    "N": "1120223.4649999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Uvrier",
    "PLZ": "1958",
    "Zusatzziffer": "2",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2597980.039999999",
    "N": "1122147.0249999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pont-de-la-Morge (Sion)",
    "PLZ": "1962",
    "Zusatzziffer": "0",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2590623.7639999986",
    "N": "1119193.3530000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bramois",
    "PLZ": "1967",
    "Zusatzziffer": "0",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2597323.4459999986",
    "N": "1119924.0480000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Salins",
    "PLZ": "1991",
    "Zusatzziffer": "0",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2593710.829999998",
    "N": "1117686.3949999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Arvillard (Salins)",
    "PLZ": "1991",
    "Zusatzziffer": "2",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2592844.6680000015",
    "N": "1117731.4800000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pravidondaz (Salins)",
    "PLZ": "1991",
    "Zusatzziffer": "3",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2593487.102000002",
    "N": "1117836.5650000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Turin (Salins)",
    "PLZ": "1991",
    "Zusatzziffer": "4",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2593474.3949999996",
    "N": "1118232.1039999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Misériez (Salins)",
    "PLZ": "1991",
    "Zusatzziffer": "5",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2592809.6900000013",
    "N": "1117303.3440000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Agettes",
    "PLZ": "1992",
    "Zusatzziffer": "0",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2595404.8330000006",
    "N": "1117416.3489999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Vernaz (Les Agettes)",
    "PLZ": "1992",
    "Zusatzziffer": "2",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2594829.098000001",
    "N": "1117664.3319999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Crête-à-l'Oeil (Les Agettes)",
    "PLZ": "1992",
    "Zusatzziffer": "3",
    "Gemeindename": "Sion",
    "BFS-Nr": "6266",
    "Kantonskürzel": "VS",
    "E": "2594726.879999999",
    "N": "1117766.1369999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Veysonnaz",
    "PLZ": "1993",
    "Zusatzziffer": "0",
    "Gemeindename": "Veysonnaz",
    "BFS-Nr": "6267",
    "Kantonskürzel": "VS",
    "E": "2592077.965999998",
    "N": "1116072.546",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Baltschieder",
    "PLZ": "3937",
    "Zusatzziffer": "0",
    "Gemeindename": "Baltschieder",
    "BFS-Nr": "6281",
    "Kantonskürzel": "VS",
    "E": "2632832.947999999",
    "N": "1128760.2479999997",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Baltschieder",
    "PLZ": "3937",
    "Zusatzziffer": "0",
    "Gemeindename": "Baltschieder",
    "BFS-Nr": "6281",
    "Kantonskürzel": "VS",
    "E": "2634045.75",
    "N": "1136158.375",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eisten",
    "PLZ": "3922",
    "Zusatzziffer": "2",
    "Gemeindename": "Eisten",
    "BFS-Nr": "6282",
    "Kantonskürzel": "VS",
    "E": "2635095.993999999",
    "N": "1116654.640999999",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Kalpetran",
    "PLZ": "3922",
    "Zusatzziffer": "1",
    "Gemeindename": "Embd",
    "BFS-Nr": "6283",
    "Kantonskürzel": "VS",
    "E": "2630812.217",
    "N": "1118158.8880000003",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Embd",
    "PLZ": "3926",
    "Zusatzziffer": "0",
    "Gemeindename": "Embd",
    "BFS-Nr": "6283",
    "Kantonskürzel": "VS",
    "E": "2630068.704999998",
    "N": "1118245.1579999998",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Grächen",
    "PLZ": "3925",
    "Zusatzziffer": "0",
    "Gemeindename": "Grächen",
    "BFS-Nr": "6285",
    "Kantonskürzel": "VS",
    "E": "2630862.3159999996",
    "N": "1116057.1640000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Lalden",
    "PLZ": "3931",
    "Zusatzziffer": "1",
    "Gemeindename": "Lalden",
    "BFS-Nr": "6286",
    "Kantonskürzel": "VS",
    "E": "2635898.484000001",
    "N": "1127618.984000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Randa",
    "PLZ": "3928",
    "Zusatzziffer": "0",
    "Gemeindename": "Randa",
    "BFS-Nr": "6287",
    "Kantonskürzel": "VS",
    "E": "2626733.796",
    "N": "1105574.7509999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saas-Almagell",
    "PLZ": "3905",
    "Zusatzziffer": "0",
    "Gemeindename": "Saas-Almagell",
    "BFS-Nr": "6288",
    "Kantonskürzel": "VS",
    "E": "2640245.243999999",
    "N": "1104823.142000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saas-Balen",
    "PLZ": "3908",
    "Zusatzziffer": "0",
    "Gemeindename": "Saas-Balen",
    "BFS-Nr": "6289",
    "Kantonskürzel": "VS",
    "E": "2637767.7250000015",
    "N": "1111527.6959999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saas-Fee",
    "PLZ": "3906",
    "Zusatzziffer": "0",
    "Gemeindename": "Saas-Fee",
    "BFS-Nr": "6290",
    "Kantonskürzel": "VS",
    "E": "2637586.0029999986",
    "N": "1106303.359000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Saas-Grund",
    "PLZ": "3910",
    "Zusatzziffer": "0",
    "Gemeindename": "Saas-Grund",
    "BFS-Nr": "6291",
    "Kantonskürzel": "VS",
    "E": "2638504.329",
    "N": "1108182.9589999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "St. Niklaus VS",
    "PLZ": "3924",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Niklaus",
    "BFS-Nr": "6292",
    "Kantonskürzel": "VS",
    "E": "2628143.5650000013",
    "N": "1114095.7459999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Herbriggen",
    "PLZ": "3927",
    "Zusatzziffer": "0",
    "Gemeindename": "St. Niklaus",
    "BFS-Nr": "6292",
    "Kantonskürzel": "VS",
    "E": "2627334.908",
    "N": "1109386.9050000012",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Stalden VS",
    "PLZ": "3922",
    "Zusatzziffer": "0",
    "Gemeindename": "Stalden (VS)",
    "BFS-Nr": "6293",
    "Kantonskürzel": "VS",
    "E": "2633368.8220000006",
    "N": "1120426.2280000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Staldenried",
    "PLZ": "3933",
    "Zusatzziffer": "0",
    "Gemeindename": "Staldenried",
    "BFS-Nr": "6294",
    "Kantonskürzel": "VS",
    "E": "2634641.5080000013",
    "N": "1119867.3680000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Täsch",
    "PLZ": "3929",
    "Zusatzziffer": "0",
    "Gemeindename": "Täsch",
    "BFS-Nr": "6295",
    "Kantonskürzel": "VS",
    "E": "2626278.164000001",
    "N": "1101785.0709999986",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Törbel",
    "PLZ": "3923",
    "Zusatzziffer": "0",
    "Gemeindename": "Törbel",
    "BFS-Nr": "6296",
    "Kantonskürzel": "VS",
    "E": "2631934.835999999",
    "N": "1120688.5920000002",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Visp",
    "PLZ": "3930",
    "Zusatzziffer": "0",
    "Gemeindename": "Visp",
    "BFS-Nr": "6297",
    "Kantonskürzel": "VS",
    "E": "2634103.022",
    "N": "1126649.4279999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eyholz",
    "PLZ": "3930",
    "Zusatzziffer": "2",
    "Gemeindename": "Visp",
    "BFS-Nr": "6297",
    "Kantonskürzel": "VS",
    "E": "2636245.916000001",
    "N": "1127086.5839999989",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Visperterminen",
    "PLZ": "3932",
    "Zusatzziffer": "0",
    "Gemeindename": "Visperterminen",
    "BFS-Nr": "6298",
    "Kantonskürzel": "VS",
    "E": "2635635.4679999985",
    "N": "1123068.2619999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zeneggen",
    "PLZ": "3934",
    "Zusatzziffer": "0",
    "Gemeindename": "Zeneggen",
    "BFS-Nr": "6299",
    "Kantonskürzel": "VS",
    "E": "2633006.0359999985",
    "N": "1124710.3559999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Zermatt",
    "PLZ": "3920",
    "Zusatzziffer": "0",
    "Gemeindename": "Zermatt",
    "BFS-Nr": "6300",
    "Kantonskürzel": "VS",
    "E": "2623861.1790000014",
    "N": "1096487.5749999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Areuse",
    "PLZ": "2015",
    "Zusatzziffer": "0",
    "Gemeindename": "Boudry",
    "BFS-Nr": "6404",
    "Kantonskürzel": "NE",
    "E": "2555709.2010000013",
    "N": "1200456.3209999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Boudry",
    "PLZ": "2017",
    "Zusatzziffer": "0",
    "Gemeindename": "Boudry",
    "BFS-Nr": "6404",
    "Kantonskürzel": "NE",
    "E": "2554208.7129999995",
    "N": "1200213.9409999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cortaillod",
    "PLZ": "2016",
    "Zusatzziffer": "0",
    "Gemeindename": "Cortaillod",
    "BFS-Nr": "6408",
    "Kantonskürzel": "NE",
    "E": "2554903.6999999993",
    "N": "1199264.6440000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rochefort",
    "PLZ": "2019",
    "Zusatzziffer": "0",
    "Gemeindename": "Rochefort",
    "BFS-Nr": "6413",
    "Kantonskürzel": "NE",
    "E": "2552164.914999999",
    "N": "1203148.7280000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chambrelien",
    "PLZ": "2019",
    "Zusatzziffer": "2",
    "Gemeindename": "Rochefort",
    "BFS-Nr": "6413",
    "Kantonskürzel": "NE",
    "E": "2552338.3489999995",
    "N": "1201999.3390000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montezillon",
    "PLZ": "2037",
    "Zusatzziffer": "2",
    "Gemeindename": "Rochefort",
    "BFS-Nr": "6413",
    "Kantonskürzel": "NE",
    "E": "2554242.824000001",
    "N": "1204127.4239999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Champ-du-Moulin",
    "PLZ": "2149",
    "Zusatzziffer": "0",
    "Gemeindename": "Rochefort",
    "BFS-Nr": "6413",
    "Kantonskürzel": "NE",
    "E": "2549460.0430000015",
    "N": "1201194.3790000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fretereules",
    "PLZ": "2149",
    "Zusatzziffer": "2",
    "Gemeindename": "Rochefort",
    "BFS-Nr": "6413",
    "Kantonskürzel": "NE",
    "E": "2548818.6790000014",
    "N": "1201416.449000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brot-Dessous",
    "PLZ": "2149",
    "Zusatzziffer": "3",
    "Gemeindename": "Rochefort",
    "BFS-Nr": "6413",
    "Kantonskürzel": "NE",
    "E": "2547371.1330000013",
    "N": "1200746.2190000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Auvernier",
    "PLZ": "2012",
    "Zusatzziffer": "0",
    "Gemeindename": "Milvignes",
    "BFS-Nr": "6416",
    "Kantonskürzel": "NE",
    "E": "2557284.844999999",
    "N": "1203121.5249999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Colombier NE",
    "PLZ": "2013",
    "Zusatzziffer": "0",
    "Gemeindename": "Milvignes",
    "BFS-Nr": "6416",
    "Kantonskürzel": "NE",
    "E": "2556118.2250000015",
    "N": "1201858.6640000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bôle",
    "PLZ": "2014",
    "Zusatzziffer": "0",
    "Gemeindename": "Milvignes",
    "BFS-Nr": "6416",
    "Kantonskürzel": "NE",
    "E": "2554448.329999998",
    "N": "1201852.0929999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bevaix",
    "PLZ": "2022",
    "Zusatzziffer": "0",
    "Gemeindename": "La Grande Béroche",
    "BFS-Nr": "6417",
    "Kantonskürzel": "NE",
    "E": "2552555.9389999993",
    "N": "1197935.568",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gorgier",
    "PLZ": "2023",
    "Zusatzziffer": "0",
    "Gemeindename": "La Grande Béroche",
    "BFS-Nr": "6417",
    "Kantonskürzel": "NE",
    "E": "2549856.9910000004",
    "N": "1194883.9959999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Aubin-Sauges",
    "PLZ": "2024",
    "Zusatzziffer": "0",
    "Gemeindename": "La Grande Béroche",
    "BFS-Nr": "6417",
    "Kantonskürzel": "NE",
    "E": "2549323.4530000016",
    "N": "1194044.8209999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chez-le-Bart",
    "PLZ": "2025",
    "Zusatzziffer": "0",
    "Gemeindename": "La Grande Béroche",
    "BFS-Nr": "6417",
    "Kantonskürzel": "NE",
    "E": "2550258.313000001",
    "N": "1194665.9400000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montalchez",
    "PLZ": "2027",
    "Zusatzziffer": "2",
    "Gemeindename": "La Grande Béroche",
    "BFS-Nr": "6417",
    "Kantonskürzel": "NE",
    "E": "2547156.6849999987",
    "N": "1194271.6539999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fresens",
    "PLZ": "2027",
    "Zusatzziffer": "3",
    "Gemeindename": "La Grande Béroche",
    "BFS-Nr": "6417",
    "Kantonskürzel": "NE",
    "E": "2547205.813000001",
    "N": "1193291.3830000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vaumarcus",
    "PLZ": "2028",
    "Zusatzziffer": "0",
    "Gemeindename": "La Grande Béroche",
    "BFS-Nr": "6417",
    "Kantonskürzel": "NE",
    "E": "2547897.1840000004",
    "N": "1192176.640999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Chaux-de-Fonds",
    "PLZ": "2300",
    "Zusatzziffer": "0",
    "Gemeindename": "La Chaux-de-Fonds",
    "BFS-Nr": "6421",
    "Kantonskürzel": "NE",
    "E": "2554034.675999999",
    "N": "1217192.1490000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Cibourg",
    "PLZ": "2300",
    "Zusatzziffer": "8",
    "Gemeindename": "La Chaux-de-Fonds",
    "BFS-Nr": "6421",
    "Kantonskürzel": "NE",
    "E": "2557289.2465000004",
    "N": "1218547.7129864774",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Crêt-du-Locle",
    "PLZ": "2322",
    "Zusatzziffer": "0",
    "Gemeindename": "La Chaux-de-Fonds",
    "BFS-Nr": "6421",
    "Kantonskürzel": "NE",
    "E": "2550321.5069999993",
    "N": "1214235.8680000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Cibourg",
    "PLZ": "2333",
    "Zusatzziffer": "2",
    "Gemeindename": "La Chaux-de-Fonds",
    "BFS-Nr": "6421",
    "Kantonskürzel": "NE",
    "E": "2558365.6675798465",
    "N": "1219507.8000000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Cibourg",
    "PLZ": "2616",
    "Zusatzziffer": "2",
    "Gemeindename": "La Chaux-de-Fonds",
    "BFS-Nr": "6421",
    "Kantonskürzel": "NE",
    "E": "2558612.6895000003",
    "N": "1218732.4614502476",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Planchettes",
    "PLZ": "2325",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Planchettes",
    "BFS-Nr": "6422",
    "Kantonskürzel": "NE",
    "E": "2549319.079999998",
    "N": "1217466.0610000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Sagne NE",
    "PLZ": "2314",
    "Zusatzziffer": "0",
    "Gemeindename": "La Sagne",
    "BFS-Nr": "6423",
    "Kantonskürzel": "NE",
    "E": "2551438.204",
    "N": "1210013.9050000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Brévine",
    "PLZ": "2406",
    "Zusatzziffer": "0",
    "Gemeindename": "La Brévine",
    "BFS-Nr": "6432",
    "Kantonskürzel": "NE",
    "E": "2536772.030000001",
    "N": "1203531.357999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Taillères",
    "PLZ": "2406",
    "Zusatzziffer": "2",
    "Gemeindename": "La Brévine",
    "BFS-Nr": "6432",
    "Kantonskürzel": "NE",
    "E": "2534368",
    "N": "1202536.875",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Châtagne",
    "PLZ": "2406",
    "Zusatzziffer": "3",
    "Gemeindename": "La Brévine",
    "BFS-Nr": "6432",
    "Kantonskürzel": "NE",
    "E": "2539382.2289999984",
    "N": "1204332.370000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Brouillet",
    "PLZ": "2406",
    "Zusatzziffer": "4",
    "Gemeindename": "La Brévine",
    "BFS-Nr": "6432",
    "Kantonskürzel": "NE",
    "E": "2531193.188000001",
    "N": "1200327.3539999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Brot-Plamboz",
    "PLZ": "2318",
    "Zusatzziffer": "0",
    "Gemeindename": "Brot-Plamboz",
    "BFS-Nr": "6433",
    "Kantonskürzel": "NE",
    "E": "2547301",
    "N": "1203362",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Cerneux-Péquignot",
    "PLZ": "2414",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Cerneux-Péquignot",
    "BFS-Nr": "6434",
    "Kantonskürzel": "NE",
    "E": "2541179.5260000005",
    "N": "1207612.4849999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Chaux-du-Milieu",
    "PLZ": "2405",
    "Zusatzziffer": "0",
    "Gemeindename": "La Chaux-du-Milieu",
    "BFS-Nr": "6435",
    "Kantonskürzel": "NE",
    "E": "2543986.2760000005",
    "N": "1207208.9530000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Locle",
    "PLZ": "2400",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Locle",
    "BFS-Nr": "6436",
    "Kantonskürzel": "NE",
    "E": "2547644.721000001",
    "N": "1212133.9990000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Prévoux",
    "PLZ": "2400",
    "Zusatzziffer": "2",
    "Gemeindename": "Le Locle",
    "BFS-Nr": "6436",
    "Kantonskürzel": "NE",
    "E": "2544017.0359999985",
    "N": "1209915.7980000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Brenets",
    "PLZ": "2416",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Locle",
    "BFS-Nr": "6436",
    "Kantonskürzel": "NE",
    "E": "2544311.1050000004",
    "N": "1213213.1889999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Ponts-de-Martel",
    "PLZ": "2316",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Ponts-de-Martel",
    "BFS-Nr": "6437",
    "Kantonskürzel": "NE",
    "E": "2546225.5139999986",
    "N": "1205652.984000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Petit-Martel",
    "PLZ": "2316",
    "Zusatzziffer": "2",
    "Gemeindename": "Les Ponts-de-Martel",
    "BFS-Nr": "6437",
    "Kantonskürzel": "NE",
    "E": "2547775.1559999995",
    "N": "1206839.9059999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cornaux NE",
    "PLZ": "2087",
    "Zusatzziffer": "0",
    "Gemeindename": "Cornaux",
    "BFS-Nr": "6451",
    "Kantonskürzel": "NE",
    "E": "2568258.3110000007",
    "N": "1209741.0610000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cressier NE",
    "PLZ": "2088",
    "Zusatzziffer": "0",
    "Gemeindename": "Cressier (NE)",
    "BFS-Nr": "6452",
    "Kantonskürzel": "NE",
    "E": "2569382.596000001",
    "N": "1211185.3150000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Enges",
    "PLZ": "2073",
    "Zusatzziffer": "0",
    "Gemeindename": "Enges",
    "BFS-Nr": "6453",
    "Kantonskürzel": "NE",
    "E": "2567609.6889999993",
    "N": "1211803.9340000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Hauterive NE",
    "PLZ": "2068",
    "Zusatzziffer": "0",
    "Gemeindename": "Hauterive (NE)",
    "BFS-Nr": "6454",
    "Kantonskürzel": "NE",
    "E": "2564494.550999999",
    "N": "1207055.7540000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Landeron",
    "PLZ": "2525",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Landeron",
    "BFS-Nr": "6455",
    "Kantonskürzel": "NE",
    "E": "2571612.0760000013",
    "N": "1211470.1240000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lignières",
    "PLZ": "2523",
    "Zusatzziffer": "0",
    "Gemeindename": "Lignières",
    "BFS-Nr": "6456",
    "Kantonskürzel": "NE",
    "E": "2571656.1389999986",
    "N": "1214486.3509999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Neuchâtel",
    "PLZ": "2000",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuchâtel",
    "BFS-Nr": "6458",
    "Kantonskürzel": "NE",
    "E": "2561032.530000001",
    "N": "1204681.6009999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Peseux",
    "PLZ": "2034",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuchâtel",
    "BFS-Nr": "6458",
    "Kantonskürzel": "NE",
    "E": "2558160.280000001",
    "N": "1204174.261",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corcelles NE",
    "PLZ": "2035",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuchâtel",
    "BFS-Nr": "6458",
    "Kantonskürzel": "NE",
    "E": "2557264.1350000016",
    "N": "1203991.3850000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cormondrèche",
    "PLZ": "2036",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuchâtel",
    "BFS-Nr": "6458",
    "Kantonskürzel": "NE",
    "E": "2556716.3200000003",
    "N": "1203439.3509999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Valangin",
    "PLZ": "2042",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuchâtel",
    "BFS-Nr": "6458",
    "Kantonskürzel": "NE",
    "E": "2559583.0549999997",
    "N": "1207372.710000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chaumont",
    "PLZ": "2067",
    "Zusatzziffer": "0",
    "Gemeindename": "Neuchâtel",
    "BFS-Nr": "6458",
    "Kantonskürzel": "NE",
    "E": "2563349.693",
    "N": "1208708.1810000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Blaise",
    "PLZ": "2072",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Blaise",
    "BFS-Nr": "6459",
    "Kantonskürzel": "NE",
    "E": "2565650.280000001",
    "N": "1207030.0390000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Marin-Epagnier",
    "PLZ": "2074",
    "Zusatzziffer": "0",
    "Gemeindename": "La Tène",
    "BFS-Nr": "6461",
    "Kantonskürzel": "NE",
    "E": "2566734.0579999983",
    "N": "1206561.7089999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Thielle",
    "PLZ": "2075",
    "Zusatzziffer": "1",
    "Gemeindename": "La Tène",
    "BFS-Nr": "6461",
    "Kantonskürzel": "NE",
    "E": "2568908.0130000003",
    "N": "1207873.4090000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Wavre",
    "PLZ": "2075",
    "Zusatzziffer": "2",
    "Gemeindename": "La Tène",
    "BFS-Nr": "6461",
    "Kantonskürzel": "NE",
    "E": "2568383.609000001",
    "N": "1208534.3729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montmollin",
    "PLZ": "2037",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2554989.6689999998",
    "N": "1204837.254999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Boudevilliers",
    "PLZ": "2043",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2558294.5689999983",
    "N": "1208752.4039999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fontaines NE",
    "PLZ": "2046",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2559140.785",
    "N": "1210437.693",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fontainemelon",
    "PLZ": "2052",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2558117.140999999",
    "N": "1211782.4750000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Vue-des-Alpes",
    "PLZ": "2052",
    "Zusatzziffer": "2",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2556784.8709999993",
    "N": "1213695.4389999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cernier",
    "PLZ": "2053",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2559427.3539999984",
    "N": "1212409.136",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chézard-St-Martin",
    "PLZ": "2054",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2561604.743999999",
    "N": "1212953.7239999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Vieux-Prés",
    "PLZ": "2054",
    "Zusatzziffer": "2",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2561864.4299999997",
    "N": "1214633.2230000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dombresson",
    "PLZ": "2056",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2563689.629999999",
    "N": "1213641.0040000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villiers",
    "PLZ": "2057",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2564525.829",
    "N": "1213844.2030000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Pâquier NE",
    "PLZ": "2058",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2565699.3680000007",
    "N": "1216471.4109999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vilars NE",
    "PLZ": "2063",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2561126.3550000004",
    "N": "1209106.625",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Engollon",
    "PLZ": "2063",
    "Zusatzziffer": "2",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2560685.7600000016",
    "N": "1209794.6160000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fenin",
    "PLZ": "2063",
    "Zusatzziffer": "3",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2560793.234000001",
    "N": "1208243.0689999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saules",
    "PLZ": "2063",
    "Zusatzziffer": "4",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2562028.6160000004",
    "N": "1209826.4690000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Savagnier",
    "PLZ": "2065",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2563127.015999999",
    "N": "1210819.5340000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Geneveys-sur-Coffrane",
    "PLZ": "2206",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2554983.8330000006",
    "N": "1207028.0500000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Coffrane",
    "PLZ": "2207",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2556197.4910000004",
    "N": "1206738.8850000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Hauts-Geneveys",
    "PLZ": "2208",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Ruz",
    "BFS-Nr": "6487",
    "Kantonskürzel": "NE",
    "E": "2557066.954",
    "N": "1210971.2890000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Côte-aux-Fées",
    "PLZ": "2117",
    "Zusatzziffer": "0",
    "Gemeindename": "La Côte-aux-Fées",
    "BFS-Nr": "6504",
    "Kantonskürzel": "NE",
    "E": "2527638.528999999",
    "N": "1191087.9609999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Verrières",
    "PLZ": "2126",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Verrières",
    "BFS-Nr": "6511",
    "Kantonskürzel": "NE",
    "E": "2526886.1689999998",
    "N": "1195333.5989999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Noiraigue",
    "PLZ": "2103",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2545525.085000001",
    "N": "1200802.1130000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Travers",
    "PLZ": "2105",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2541834.890999999",
    "N": "1199149.243999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Couvet",
    "PLZ": "2108",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2538607.6799999997",
    "N": "1197416.9279999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Môtiers NE",
    "PLZ": "2112",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2536992.405000001",
    "N": "1195977.5179999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Boveresse",
    "PLZ": "2113",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2536173.3960000016",
    "N": "1196489.5700000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fleurier",
    "PLZ": "2114",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2534717.1050000004",
    "N": "1194827.636",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Buttes",
    "PLZ": "2115",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2532433.9299999997",
    "N": "1193541.3729999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mont-de-Buttes",
    "PLZ": "2116",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2529149.1169999987",
    "N": "1192211.1259999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Sulpice NE",
    "PLZ": "2123",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2533227.2060000002",
    "N": "1195928.3409999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Sagnettes",
    "PLZ": "2124",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2536044.25",
    "N": "1199014.813000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Bayards",
    "PLZ": "2127",
    "Zusatzziffer": "0",
    "Gemeindename": "Val-de-Travers",
    "BFS-Nr": "6512",
    "Kantonskürzel": "NE",
    "E": "2530035.484000001",
    "N": "1196603.7309999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aire-la-Ville",
    "PLZ": "1288",
    "Zusatzziffer": "0",
    "Gemeindename": "Aire-la-Ville",
    "BFS-Nr": "6601",
    "Kantonskürzel": "GE",
    "E": "2492286.6460000016",
    "N": "1116445.8150000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Anières",
    "PLZ": "1247",
    "Zusatzziffer": "0",
    "Gemeindename": "Anières",
    "BFS-Nr": "6602",
    "Kantonskürzel": "GE",
    "E": "2506291.096000001",
    "N": "1125705.5100000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Avully",
    "PLZ": "1237",
    "Zusatzziffer": "0",
    "Gemeindename": "Avully",
    "BFS-Nr": "6603",
    "Kantonskürzel": "GE",
    "E": "2488890.085000001",
    "N": "1114131.4499999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Athenaz (Avusy)",
    "PLZ": "1285",
    "Zusatzziffer": "0",
    "Gemeindename": "Avusy",
    "BFS-Nr": "6604",
    "Kantonskürzel": "GE",
    "E": "2489424.2690000013",
    "N": "1112478.1660000011",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Croix-de-Rozon",
    "PLZ": "1257",
    "Zusatzziffer": "0",
    "Gemeindename": "Bardonnex",
    "BFS-Nr": "6605",
    "Kantonskürzel": "GE",
    "E": "2499381.6680000015",
    "N": "1111250.2540000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bellevue",
    "PLZ": "1293",
    "Zusatzziffer": "0",
    "Gemeindename": "Bellevue",
    "BFS-Nr": "6606",
    "Kantonskürzel": "GE",
    "E": "2500885.210000001",
    "N": "1123295.550999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bernex",
    "PLZ": "1233",
    "Zusatzziffer": "0",
    "Gemeindename": "Bernex",
    "BFS-Nr": "6607",
    "Kantonskürzel": "GE",
    "E": "2494281.094999999",
    "N": "1114506.256000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Carouge GE",
    "PLZ": "1227",
    "Zusatzziffer": "0",
    "Gemeindename": "Carouge (GE)",
    "BFS-Nr": "6608",
    "Kantonskürzel": "GE",
    "E": "2499698.458999999",
    "N": "1115667.6909999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cartigny",
    "PLZ": "1236",
    "Zusatzziffer": "0",
    "Gemeindename": "Cartigny",
    "BFS-Nr": "6609",
    "Kantonskürzel": "GE",
    "E": "2490227.8889999986",
    "N": "1114589.0249999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Céligny",
    "PLZ": "1298",
    "Zusatzziffer": "0",
    "Gemeindename": "Céligny",
    "BFS-Nr": "6610",
    "Kantonskürzel": "GE",
    "E": "2502754.715999998",
    "N": "1133714.0899999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Céligny",
    "PLZ": "1298",
    "Zusatzziffer": "0",
    "Gemeindename": "Céligny",
    "BFS-Nr": "6610",
    "Kantonskürzel": "GE",
    "E": "2504278.0480000004",
    "N": "1133966.8449999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chancy",
    "PLZ": "1284",
    "Zusatzziffer": "0",
    "Gemeindename": "Chancy",
    "BFS-Nr": "6611",
    "Kantonskürzel": "GE",
    "E": "2486639.5089999996",
    "N": "1111974.390999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chêne-Bougeries",
    "PLZ": "1224",
    "Zusatzziffer": "0",
    "Gemeindename": "Chêne-Bougeries",
    "BFS-Nr": "6612",
    "Kantonskürzel": "GE",
    "E": "2503508.7600000016",
    "N": "1117024.460000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Conches",
    "PLZ": "1231",
    "Zusatzziffer": "0",
    "Gemeindename": "Chêne-Bougeries",
    "BFS-Nr": "6612",
    "Kantonskürzel": "GE",
    "E": "2502558.3000000007",
    "N": "1115554.3399999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chêne-Bourg",
    "PLZ": "1225",
    "Zusatzziffer": "0",
    "Gemeindename": "Chêne-Bourg",
    "BFS-Nr": "6613",
    "Kantonskürzel": "GE",
    "E": "2503756.675999999",
    "N": "1116573.7080000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Choulex",
    "PLZ": "1244",
    "Zusatzziffer": "0",
    "Gemeindename": "Choulex",
    "BFS-Nr": "6614",
    "Kantonskürzel": "GE",
    "E": "2506492.5089999996",
    "N": "1120355.4499999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Collex",
    "PLZ": "1239",
    "Zusatzziffer": "0",
    "Gemeindename": "Collex-Bossy",
    "BFS-Nr": "6615",
    "Kantonskürzel": "GE",
    "E": "2498412.710999999",
    "N": "1125219.7080000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vésenaz",
    "PLZ": "1222",
    "Zusatzziffer": "0",
    "Gemeindename": "Collonge-Bellerive",
    "BFS-Nr": "6616",
    "Kantonskürzel": "GE",
    "E": "2504432.1750000007",
    "N": "1121434.4849999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Collonge-Bellerive",
    "PLZ": "1245",
    "Zusatzziffer": "0",
    "Gemeindename": "Collonge-Bellerive",
    "BFS-Nr": "6616",
    "Kantonskürzel": "GE",
    "E": "2504797.193",
    "N": "1123222.6590000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cologny",
    "PLZ": "1223",
    "Zusatzziffer": "0",
    "Gemeindename": "Cologny",
    "BFS-Nr": "6617",
    "Kantonskürzel": "GE",
    "E": "2502970.4690000005",
    "N": "1119091.4450000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Confignon",
    "PLZ": "1232",
    "Zusatzziffer": "0",
    "Gemeindename": "Confignon",
    "BFS-Nr": "6618",
    "Kantonskürzel": "GE",
    "E": "2495426.223000001",
    "N": "1114515.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corsier GE",
    "PLZ": "1246",
    "Zusatzziffer": "0",
    "Gemeindename": "Corsier (GE)",
    "BFS-Nr": "6619",
    "Kantonskürzel": "GE",
    "E": "2506522.3900000006",
    "N": "1124175.4730000012",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Plaine",
    "PLZ": "1283",
    "Zusatzziffer": "0",
    "Gemeindename": "Dardagny",
    "BFS-Nr": "6620",
    "Kantonskürzel": "GE",
    "E": "2488894.6180000007",
    "N": "1115164.8330000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Dardagny",
    "PLZ": "1283",
    "Zusatzziffer": "1",
    "Gemeindename": "Dardagny",
    "BFS-Nr": "6620",
    "Kantonskürzel": "GE",
    "E": "2488583.8440000005",
    "N": "1116954.3489999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1201",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2500293.468411973",
    "N": "1118468.7879999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1202",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2500484.317874665",
    "N": "1119710.284",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1203",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2498512.0858956254",
    "N": "1118311.182500001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1204",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2500148.8307749503",
    "N": "1117598.8419999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1205",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2500032.098144176",
    "N": "1116801.9920000006",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1206",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2501189.4855000004",
    "N": "1115885.0446502443",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1207",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2501441.5610000007",
    "N": "1118116.1963602281",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1208",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2501757.959853923",
    "N": "1117235.9800000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1209",
    "Zusatzziffer": "0",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2498641.530796523",
    "N": "1119785.2429999989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Acacias",
    "PLZ": "1227",
    "Zusatzziffer": "4",
    "Gemeindename": "Genève",
    "BFS-Nr": "6621",
    "Kantonskürzel": "GE",
    "E": "2499220.074000001",
    "N": "1116622.0760000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genthod",
    "PLZ": "1294",
    "Zusatzziffer": "0",
    "Gemeindename": "Genthod",
    "BFS-Nr": "6622",
    "Kantonskürzel": "GE",
    "E": "2501234.1000000015",
    "N": "1124513.1810000017",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Genève",
    "PLZ": "1215",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Grand-Saconnex",
    "BFS-Nr": "6623",
    "Kantonskürzel": "GE",
    "E": "2497269.528999999",
    "N": "1121290.368999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Grand-Saconnex",
    "PLZ": "1218",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Grand-Saconnex",
    "BFS-Nr": "6623",
    "Kantonskürzel": "GE",
    "E": "2498586.6130000018",
    "N": "1120961.7149999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Gy",
    "PLZ": "1251",
    "Zusatzziffer": "0",
    "Gemeindename": "Gy",
    "BFS-Nr": "6624",
    "Kantonskürzel": "GE",
    "E": "2508925.090999998",
    "N": "1123024.8889999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Hermance",
    "PLZ": "1248",
    "Zusatzziffer": "0",
    "Gemeindename": "Hermance",
    "BFS-Nr": "6625",
    "Kantonskürzel": "GE",
    "E": "2507994.5540000014",
    "N": "1128477.4409999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Jussy",
    "PLZ": "1254",
    "Zusatzziffer": "0",
    "Gemeindename": "Jussy",
    "BFS-Nr": "6626",
    "Kantonskürzel": "GE",
    "E": "2509546.728",
    "N": "1121116.381000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Laconnex",
    "PLZ": "1287",
    "Zusatzziffer": "0",
    "Gemeindename": "Laconnex",
    "BFS-Nr": "6627",
    "Kantonskürzel": "GE",
    "E": "2491541.5540000014",
    "N": "1112445.8280000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grand-Lancy",
    "PLZ": "1212",
    "Zusatzziffer": "0",
    "Gemeindename": "Lancy",
    "BFS-Nr": "6628",
    "Kantonskürzel": "GE",
    "E": "2498258.5130000003",
    "N": "1115400.4750000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Petit-Lancy",
    "PLZ": "1213",
    "Zusatzziffer": "0",
    "Gemeindename": "Lancy",
    "BFS-Nr": "6628",
    "Kantonskürzel": "GE",
    "E": "2497984.4690000005",
    "N": "1116151.9389999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Meinier",
    "PLZ": "1252",
    "Zusatzziffer": "0",
    "Gemeindename": "Meinier",
    "BFS-Nr": "6629",
    "Kantonskürzel": "GE",
    "E": "2507113",
    "N": "1122395.0210000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cointrin",
    "PLZ": "1216",
    "Zusatzziffer": "0",
    "Gemeindename": "Meyrin",
    "BFS-Nr": "6630",
    "Kantonskürzel": "GE",
    "E": "2497146.1570000015",
    "N": "1120097.9100000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Meyrin",
    "PLZ": "1217",
    "Zusatzziffer": "0",
    "Gemeindename": "Meyrin",
    "BFS-Nr": "6630",
    "Kantonskürzel": "GE",
    "E": "2494464.3079999983",
    "N": "1120569.063000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Onex",
    "PLZ": "1213",
    "Zusatzziffer": "3",
    "Gemeindename": "Onex",
    "BFS-Nr": "6631",
    "Kantonskürzel": "GE",
    "E": "2496713.6689999998",
    "N": "1115392.9129999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Perly",
    "PLZ": "1258",
    "Zusatzziffer": "0",
    "Gemeindename": "Perly-Certoux",
    "BFS-Nr": "6632",
    "Kantonskürzel": "GE",
    "E": "2495639.2600000016",
    "N": "1112513.079",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Plan-les-Ouates",
    "PLZ": "1228",
    "Zusatzziffer": "0",
    "Gemeindename": "Plan-les-Ouates",
    "BFS-Nr": "6633",
    "Kantonskürzel": "GE",
    "E": "2497837.8150000013",
    "N": "1113817.324000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chambésy",
    "PLZ": "1292",
    "Zusatzziffer": "0",
    "Gemeindename": "Pregny-Chambésy",
    "BFS-Nr": "6634",
    "Kantonskürzel": "GE",
    "E": "2500370.5469999984",
    "N": "1121851.0210000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Presinge",
    "PLZ": "1243",
    "Zusatzziffer": "0",
    "Gemeindename": "Presinge",
    "BFS-Nr": "6635",
    "Kantonskürzel": "GE",
    "E": "2508672.693",
    "N": "1119300.822999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Puplinge",
    "PLZ": "1241",
    "Zusatzziffer": "0",
    "Gemeindename": "Puplinge",
    "BFS-Nr": "6636",
    "Kantonskürzel": "GE",
    "E": "2506910.6240000017",
    "N": "1118321.0689999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Russin",
    "PLZ": "1281",
    "Zusatzziffer": "0",
    "Gemeindename": "Russin",
    "BFS-Nr": "6637",
    "Kantonskürzel": "GE",
    "E": "2490016.7459999993",
    "N": "1116119.0639999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Satigny",
    "PLZ": "1242",
    "Zusatzziffer": "0",
    "Gemeindename": "Satigny",
    "BFS-Nr": "6638",
    "Kantonskürzel": "GE",
    "E": "2491416.903000001",
    "N": "1119507.136",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Soral",
    "PLZ": "1286",
    "Zusatzziffer": "0",
    "Gemeindename": "Soral",
    "BFS-Nr": "6639",
    "Kantonskürzel": "GE",
    "E": "2492169.3550000004",
    "N": "1111328.6900000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Thônex",
    "PLZ": "1226",
    "Zusatzziffer": "0",
    "Gemeindename": "Thônex",
    "BFS-Nr": "6640",
    "Kantonskürzel": "GE",
    "E": "2504268.9629999995",
    "N": "1115959.0029999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Troinex",
    "PLZ": "1256",
    "Zusatzziffer": "0",
    "Gemeindename": "Troinex",
    "BFS-Nr": "6641",
    "Kantonskürzel": "GE",
    "E": "2500347.2140000015",
    "N": "1113007.2589999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vandoeuvres",
    "PLZ": "1253",
    "Zusatzziffer": "0",
    "Gemeindename": "Vandoeuvres",
    "BFS-Nr": "6642",
    "Kantonskürzel": "GE",
    "E": "2504626.9990000017",
    "N": "1119614.9789999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vernier",
    "PLZ": "1214",
    "Zusatzziffer": "0",
    "Gemeindename": "Vernier",
    "BFS-Nr": "6643",
    "Kantonskürzel": "GE",
    "E": "2495663.787999999",
    "N": "1119105.175999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Lignon",
    "PLZ": "1219",
    "Zusatzziffer": "0",
    "Gemeindename": "Vernier",
    "BFS-Nr": "6643",
    "Kantonskürzel": "GE",
    "E": "2496334.5210000016",
    "N": "1117864.9010000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Aïre",
    "PLZ": "1219",
    "Zusatzziffer": "10",
    "Gemeindename": "Vernier",
    "BFS-Nr": "6643",
    "Kantonskürzel": "GE",
    "E": "2496546.265999999",
    "N": "1117382.1600000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châtelaine",
    "PLZ": "1219",
    "Zusatzziffer": "12",
    "Gemeindename": "Vernier",
    "BFS-Nr": "6643",
    "Kantonskürzel": "GE",
    "E": "2497508.3260000013",
    "N": "1118715.4659999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Avanchets",
    "PLZ": "1220",
    "Zusatzziffer": "0",
    "Gemeindename": "Vernier",
    "BFS-Nr": "6643",
    "Kantonskürzel": "GE",
    "E": "2497403.3949999996",
    "N": "1119691.949000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Versoix",
    "PLZ": "1290",
    "Zusatzziffer": "0",
    "Gemeindename": "Versoix",
    "BFS-Nr": "6644",
    "Kantonskürzel": "GE",
    "E": "2501861.7129999995",
    "N": "1126407.9389999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vessy",
    "PLZ": "1234",
    "Zusatzziffer": "0",
    "Gemeindename": "Veyrier",
    "BFS-Nr": "6645",
    "Kantonskürzel": "GE",
    "E": "2500844.590999998",
    "N": "1114240.0529999994",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Veyrier",
    "PLZ": "1255",
    "Zusatzziffer": "0",
    "Gemeindename": "Veyrier",
    "BFS-Nr": "6645",
    "Kantonskürzel": "GE",
    "E": "2503258.109000001",
    "N": "1113587.8949999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Boécourt",
    "PLZ": "2856",
    "Zusatzziffer": "0",
    "Gemeindename": "Boécourt",
    "BFS-Nr": "6702",
    "Kantonskürzel": "JU",
    "E": "2583144.8649999984",
    "N": "1244261.074000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montavon",
    "PLZ": "2857",
    "Zusatzziffer": "0",
    "Gemeindename": "Boécourt",
    "BFS-Nr": "6702",
    "Kantonskürzel": "JU",
    "E": "2584694.4109999985",
    "N": "1246973.8940000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bourrignon",
    "PLZ": "2803",
    "Zusatzziffer": "0",
    "Gemeindename": "Bourrignon",
    "BFS-Nr": "6703",
    "Kantonskürzel": "JU",
    "E": "2585258.7060000002",
    "N": "1249691.0469999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Châtillon JU",
    "PLZ": "2843",
    "Zusatzziffer": "0",
    "Gemeindename": "Châtillon (JU)",
    "BFS-Nr": "6704",
    "Kantonskürzel": "JU",
    "E": "2592910.4930000007",
    "N": "1241729.8099999987",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courchapoix",
    "PLZ": "2825",
    "Zusatzziffer": "0",
    "Gemeindename": "Courchapoix",
    "BFS-Nr": "6706",
    "Kantonskürzel": "JU",
    "E": "2600967.8790000007",
    "N": "1244054.2950000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courrendlin",
    "PLZ": "2830",
    "Zusatzziffer": "0",
    "Gemeindename": "Courrendlin",
    "BFS-Nr": "6708",
    "Kantonskürzel": "JU",
    "E": "2595513.9459999986",
    "N": "1243057.2879999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vellerat",
    "PLZ": "2830",
    "Zusatzziffer": "2",
    "Gemeindename": "Courrendlin",
    "BFS-Nr": "6708",
    "Kantonskürzel": "JU",
    "E": "2594809.1229999997",
    "N": "1241051.2190000005",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rebeuvelier",
    "PLZ": "2832",
    "Zusatzziffer": "0",
    "Gemeindename": "Courrendlin",
    "BFS-Nr": "6708",
    "Kantonskürzel": "JU",
    "E": "2597788.774",
    "N": "1241544.9510000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courroux",
    "PLZ": "2822",
    "Zusatzziffer": "0",
    "Gemeindename": "Courroux",
    "BFS-Nr": "6709",
    "Kantonskürzel": "JU",
    "E": "2595202.2760000005",
    "N": "1245709.989",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courcelon",
    "PLZ": "2823",
    "Zusatzziffer": "0",
    "Gemeindename": "Courroux",
    "BFS-Nr": "6709",
    "Kantonskürzel": "JU",
    "E": "2596831.0089999996",
    "N": "1245677.2129999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtételle",
    "PLZ": "2852",
    "Zusatzziffer": "0",
    "Gemeindename": "Courtételle",
    "BFS-Nr": "6710",
    "Kantonskürzel": "JU",
    "E": "2590726.460999999",
    "N": "1243291.25",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Delémont",
    "PLZ": "2800",
    "Zusatzziffer": "0",
    "Gemeindename": "Delémont",
    "BFS-Nr": "6711",
    "Kantonskürzel": "JU",
    "E": "2592824.6669999994",
    "N": "1245988.3249999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Develier",
    "PLZ": "2802",
    "Zusatzziffer": "0",
    "Gemeindename": "Develier",
    "BFS-Nr": "6712",
    "Kantonskürzel": "JU",
    "E": "2588867.675999999",
    "N": "1244884.7300000004",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ederswiler",
    "PLZ": "2813",
    "Zusatzziffer": "0",
    "Gemeindename": "Ederswiler",
    "BFS-Nr": "6713",
    "Kantonskürzel": "JU",
    "E": "2592224.425999999",
    "N": "1252882.5540000014",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mervelier",
    "PLZ": "2827",
    "Zusatzziffer": "0",
    "Gemeindename": "Mervelier",
    "BFS-Nr": "6715",
    "Kantonskürzel": "JU",
    "E": "2604919.6829999983",
    "N": "1243712.5359999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Mettembert",
    "PLZ": "2806",
    "Zusatzziffer": "0",
    "Gemeindename": "Mettembert",
    "BFS-Nr": "6716",
    "Kantonskürzel": "JU",
    "E": "2591004.5830000006",
    "N": "1249681.5439999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Movelier",
    "PLZ": "2812",
    "Zusatzziffer": "0",
    "Gemeindename": "Movelier",
    "BFS-Nr": "6718",
    "Kantonskürzel": "JU",
    "E": "2590771.476",
    "N": "1251035.936999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pleigne",
    "PLZ": "2807",
    "Zusatzziffer": "0",
    "Gemeindename": "Pleigne",
    "BFS-Nr": "6719",
    "Kantonskürzel": "JU",
    "E": "2589092.6389999986",
    "N": "1250767.767000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lucelle",
    "PLZ": "2807",
    "Zusatzziffer": "1",
    "Gemeindename": "Pleigne",
    "BFS-Nr": "6719",
    "Kantonskürzel": "JU",
    "E": "2585369.5",
    "N": "1252168.631000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rossemaison",
    "PLZ": "2842",
    "Zusatzziffer": "0",
    "Gemeindename": "Rossemaison",
    "BFS-Nr": "6721",
    "Kantonskürzel": "JU",
    "E": "2592754.960999999",
    "N": "1243886.857999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saulcy",
    "PLZ": "2873",
    "Zusatzziffer": "0",
    "Gemeindename": "Saulcy",
    "BFS-Nr": "6722",
    "Kantonskürzel": "JU",
    "E": "2578489.1680000015",
    "N": "1239080.4930000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Soyhières",
    "PLZ": "2805",
    "Zusatzziffer": "0",
    "Gemeindename": "Soyhières",
    "BFS-Nr": "6724",
    "Kantonskürzel": "JU",
    "E": "2594778.647999998",
    "N": "1248974.0179999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courfaivre",
    "PLZ": "2853",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Sorne",
    "BFS-Nr": "6729",
    "Kantonskürzel": "JU",
    "E": "2587730.289000001",
    "N": "1242423.4409999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bassecourt",
    "PLZ": "2854",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Sorne",
    "BFS-Nr": "6729",
    "Kantonskürzel": "JU",
    "E": "2585331.7289999984",
    "N": "1242840.5199999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Glovelier",
    "PLZ": "2855",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Sorne",
    "BFS-Nr": "6729",
    "Kantonskürzel": "JU",
    "E": "2581948.6209999993",
    "N": "1242333.5240000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Undervelier",
    "PLZ": "2863",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Sorne",
    "BFS-Nr": "6729",
    "Kantonskürzel": "JU",
    "E": "2583806.285",
    "N": "1239210.5210000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Soulce",
    "PLZ": "2864",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Sorne",
    "BFS-Nr": "6729",
    "Kantonskürzel": "JU",
    "E": "2587298.2969999984",
    "N": "1239388.3110000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vicques",
    "PLZ": "2824",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Terbi",
    "BFS-Nr": "6730",
    "Kantonskürzel": "JU",
    "E": "2598073.6860000007",
    "N": "1244035.2930000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Corban",
    "PLZ": "2826",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Terbi",
    "BFS-Nr": "6730",
    "Kantonskürzel": "JU",
    "E": "2602978.914999999",
    "N": "1244139.1909999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montsevelier",
    "PLZ": "2828",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Terbi",
    "BFS-Nr": "6730",
    "Kantonskürzel": "JU",
    "E": "2605419.1330000013",
    "N": "1245366.710000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vermes",
    "PLZ": "2829",
    "Zusatzziffer": "0",
    "Gemeindename": "Val Terbi",
    "BFS-Nr": "6730",
    "Kantonskürzel": "JU",
    "E": "2602980.3079999983",
    "N": "1242101.756000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Bémont JU",
    "PLZ": "2360",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Bémont (JU)",
    "BFS-Nr": "6741",
    "Kantonskürzel": "JU",
    "E": "2567873.6400000006",
    "N": "1234789.313000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Bois",
    "PLZ": "2336",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Bois",
    "BFS-Nr": "6742",
    "Kantonskürzel": "JU",
    "E": "2559557.8830000013",
    "N": "1225209.7109999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Breuleux",
    "PLZ": "2345",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Breuleux",
    "BFS-Nr": "6743",
    "Kantonskürzel": "JU",
    "E": "2567040.0130000003",
    "N": "1229169.6180000007",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "La Chaux-des-Breuleux",
    "PLZ": "2345",
    "Zusatzziffer": "3",
    "Gemeindename": "La Chaux-des-Breuleux",
    "BFS-Nr": "6744",
    "Kantonskürzel": "JU",
    "E": "2568858.659000002",
    "N": "1230279.1609999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Enfers",
    "PLZ": "2363",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Enfers",
    "BFS-Nr": "6745",
    "Kantonskürzel": "JU",
    "E": "2570166.381000001",
    "N": "1237533.9629999995",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Genevez JU",
    "PLZ": "2714",
    "Zusatzziffer": "0",
    "Gemeindename": "Les Genevez (JU)",
    "BFS-Nr": "6748",
    "Kantonskürzel": "JU",
    "E": "2576668.2630000003",
    "N": "1234338.484000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Prédame",
    "PLZ": "2714",
    "Zusatzziffer": "2",
    "Gemeindename": "Les Genevez (JU)",
    "BFS-Nr": "6748",
    "Kantonskürzel": "JU",
    "E": "2574901.3740000017",
    "N": "1234119.7140000015",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lajoux JU",
    "PLZ": "2718",
    "Zusatzziffer": "0",
    "Gemeindename": "Lajoux (JU)",
    "BFS-Nr": "6750",
    "Kantonskürzel": "JU",
    "E": "2577358.572999999",
    "N": "1236523.5359999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fornet-Dessus",
    "PLZ": "2718",
    "Zusatzziffer": "2",
    "Gemeindename": "Lajoux (JU)",
    "BFS-Nr": "6750",
    "Kantonskürzel": "JU",
    "E": "2578842.3889999986",
    "N": "1236505.381000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montfaucon",
    "PLZ": "2362",
    "Zusatzziffer": "0",
    "Gemeindename": "Montfaucon",
    "BFS-Nr": "6751",
    "Kantonskürzel": "JU",
    "E": "2570679.484000001",
    "N": "1236935.3929999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montfavergier",
    "PLZ": "2362",
    "Zusatzziffer": "2",
    "Gemeindename": "Montfaucon",
    "BFS-Nr": "6751",
    "Kantonskürzel": "JU",
    "E": "2573621.545000002",
    "N": "1239914.8460000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Emibois",
    "PLZ": "2338",
    "Zusatzziffer": "0",
    "Gemeindename": "Muriaux",
    "BFS-Nr": "6753",
    "Kantonskürzel": "JU",
    "E": "2565718.4899999984",
    "N": "1231353.8880000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Muriaux",
    "PLZ": "2338",
    "Zusatzziffer": "2",
    "Gemeindename": "Muriaux",
    "BFS-Nr": "6753",
    "Kantonskürzel": "JU",
    "E": "2565149.3539999984",
    "N": "1232899.232999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Cerneux-Veusil",
    "PLZ": "2345",
    "Zusatzziffer": "2",
    "Gemeindename": "Muriaux",
    "BFS-Nr": "6753",
    "Kantonskürzel": "JU",
    "E": "2563925.9869999997",
    "N": "1225984.4290000014",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Le Noirmont",
    "PLZ": "2340",
    "Zusatzziffer": "0",
    "Gemeindename": "Le Noirmont",
    "BFS-Nr": "6754",
    "Kantonskürzel": "JU",
    "E": "2563563.0659999996",
    "N": "1230727.6209999993",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Saignelégier",
    "PLZ": "2350",
    "Zusatzziffer": "0",
    "Gemeindename": "Saignelégier",
    "BFS-Nr": "6757",
    "Kantonskürzel": "JU",
    "E": "2566471.9530000016",
    "N": "1233964.495000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Les Pommerats",
    "PLZ": "2353",
    "Zusatzziffer": "0",
    "Gemeindename": "Saignelégier",
    "BFS-Nr": "6757",
    "Kantonskürzel": "JU",
    "E": "2565818.8900000006",
    "N": "1235732.609000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Goumois",
    "PLZ": "2354",
    "Zusatzziffer": "0",
    "Gemeindename": "Saignelégier",
    "BFS-Nr": "6757",
    "Kantonskürzel": "JU",
    "E": "2563212.596000001",
    "N": "1234612.572999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Brais",
    "PLZ": "2364",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Brais",
    "BFS-Nr": "6758",
    "Kantonskürzel": "JU",
    "E": "2575335.4990000017",
    "N": "1239401.2789999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Ursanne",
    "PLZ": "2882",
    "Zusatzziffer": "0",
    "Gemeindename": "Saint-Brais",
    "BFS-Nr": "6758",
    "Kantonskürzel": "JU",
    "E": "2575299.25",
    "N": "1241132.75",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Soubey",
    "PLZ": "2887",
    "Zusatzziffer": "0",
    "Gemeindename": "Soubey",
    "BFS-Nr": "6759",
    "Kantonskürzel": "JU",
    "E": "2570500.7859999985",
    "N": "1239927.6009999998",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Alle",
    "PLZ": "2942",
    "Zusatzziffer": "0",
    "Gemeindename": "Alle",
    "BFS-Nr": "6771",
    "Kantonskürzel": "JU",
    "E": "2576618.1350000016",
    "N": "1252986.2859999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Beurnevésin",
    "PLZ": "2935",
    "Zusatzziffer": "0",
    "Gemeindename": "Beurnevésin",
    "BFS-Nr": "6773",
    "Kantonskürzel": "JU",
    "E": "2577283.5879999995",
    "N": "1260351.6339999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Boncourt",
    "PLZ": "2926",
    "Zusatzziffer": "0",
    "Gemeindename": "Boncourt",
    "BFS-Nr": "6774",
    "Kantonskürzel": "JU",
    "E": "2567945.164999999",
    "N": "1260718.3209999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bonfol",
    "PLZ": "2944",
    "Zusatzziffer": "0",
    "Gemeindename": "Bonfol",
    "BFS-Nr": "6775",
    "Kantonskürzel": "JU",
    "E": "2578055.131000001",
    "N": "1258792.2179999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bure",
    "PLZ": "2915",
    "Zusatzziffer": "0",
    "Gemeindename": "Bure",
    "BFS-Nr": "6778",
    "Kantonskürzel": "JU",
    "E": "2567367.193",
    "N": "1254537.5799999982",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Coeuve",
    "PLZ": "2932",
    "Zusatzziffer": "0",
    "Gemeindename": "Coeuve",
    "BFS-Nr": "6781",
    "Kantonskürzel": "JU",
    "E": "2574178.405000001",
    "N": "1256028.875",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Cornol",
    "PLZ": "2952",
    "Zusatzziffer": "0",
    "Gemeindename": "Cornol",
    "BFS-Nr": "6782",
    "Kantonskürzel": "JU",
    "E": "2579258.0190000013",
    "N": "1250602.0549999997",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courchavon",
    "PLZ": "2922",
    "Zusatzziffer": "0",
    "Gemeindename": "Courchavon",
    "BFS-Nr": "6783",
    "Kantonskürzel": "JU",
    "E": "2571056.688000001",
    "N": "1254530.8280000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courgenay",
    "PLZ": "2950",
    "Zusatzziffer": "0",
    "Gemeindename": "Courgenay",
    "BFS-Nr": "6784",
    "Kantonskürzel": "JU",
    "E": "2576417.9310000017",
    "N": "1250299.1389999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtemautruy",
    "PLZ": "2950",
    "Zusatzziffer": "2",
    "Gemeindename": "Courgenay",
    "BFS-Nr": "6784",
    "Kantonskürzel": "JU",
    "E": "2577358.6909999996",
    "N": "1249479.7199999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtedoux",
    "PLZ": "2905",
    "Zusatzziffer": "0",
    "Gemeindename": "Courtedoux",
    "BFS-Nr": "6785",
    "Kantonskürzel": "JU",
    "E": "2570105.039999999",
    "N": "1250952.7199999988",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Damphreux",
    "PLZ": "2933",
    "Zusatzziffer": "2",
    "Gemeindename": "Damphreux",
    "BFS-Nr": "6787",
    "Kantonskürzel": "JU",
    "E": "2574655.6730000004",
    "N": "1258508.443",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fahy",
    "PLZ": "2916",
    "Zusatzziffer": "0",
    "Gemeindename": "Fahy",
    "BFS-Nr": "6789",
    "Kantonskürzel": "JU",
    "E": "2563066.594999999",
    "N": "1252013.5199999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fontenais",
    "PLZ": "2902",
    "Zusatzziffer": "0",
    "Gemeindename": "Fontenais",
    "BFS-Nr": "6790",
    "Kantonskürzel": "JU",
    "E": "2573011.642999999",
    "N": "1250302.572999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Villars-sur-Fontenais",
    "PLZ": "2903",
    "Zusatzziffer": "0",
    "Gemeindename": "Fontenais",
    "BFS-Nr": "6790",
    "Kantonskürzel": "JU",
    "E": "2572979.5130000003",
    "N": "1248621.1279999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Bressaucourt",
    "PLZ": "2904",
    "Zusatzziffer": "0",
    "Gemeindename": "Fontenais",
    "BFS-Nr": "6790",
    "Kantonskürzel": "JU",
    "E": "2569667.9880000018",
    "N": "1248617.6149999984",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Grandfontaine",
    "PLZ": "2908",
    "Zusatzziffer": "0",
    "Gemeindename": "Grandfontaine",
    "BFS-Nr": "6792",
    "Kantonskürzel": "JU",
    "E": "2562333.4690000005",
    "N": "1249110.2529999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Lugnez",
    "PLZ": "2933",
    "Zusatzziffer": "3",
    "Gemeindename": "Lugnez",
    "BFS-Nr": "6793",
    "Kantonskürzel": "JU",
    "E": "2574295.471000001",
    "N": "1259225.6959999986",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Porrentruy",
    "PLZ": "2900",
    "Zusatzziffer": "0",
    "Gemeindename": "Porrentruy",
    "BFS-Nr": "6800",
    "Kantonskürzel": "JU",
    "E": "2572696.5879999995",
    "N": "1251739.688000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vendlincourt",
    "PLZ": "2943",
    "Zusatzziffer": "0",
    "Gemeindename": "Vendlincourt",
    "BFS-Nr": "6806",
    "Kantonskürzel": "JU",
    "E": "2577957.5009999983",
    "N": "1255627.5650000013",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Courtemaîche",
    "PLZ": "2923",
    "Zusatzziffer": "0",
    "Gemeindename": "Basse-Allaine",
    "BFS-Nr": "6807",
    "Kantonskürzel": "JU",
    "E": "2570363.1350000016",
    "N": "1256509.5280000009",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montignez",
    "PLZ": "2924",
    "Zusatzziffer": "0",
    "Gemeindename": "Basse-Allaine",
    "BFS-Nr": "6807",
    "Kantonskürzel": "JU",
    "E": "2571411.4459999986",
    "N": "1259655.2149999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Buix",
    "PLZ": "2925",
    "Zusatzziffer": "0",
    "Gemeindename": "Basse-Allaine",
    "BFS-Nr": "6807",
    "Kantonskürzel": "JU",
    "E": "2569079.2639999986",
    "N": "1258819.0700000003",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "St-Ursanne",
    "PLZ": "2882",
    "Zusatzziffer": "0",
    "Gemeindename": "Clos du Doubs",
    "BFS-Nr": "6808",
    "Kantonskürzel": "JU",
    "E": "2578468.6790000014",
    "N": "1246020.0340000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montmelon",
    "PLZ": "2883",
    "Zusatzziffer": "0",
    "Gemeindename": "Clos du Doubs",
    "BFS-Nr": "6808",
    "Kantonskürzel": "JU",
    "E": "2580556.3880000003",
    "N": "1244882.4609999992",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Montenol",
    "PLZ": "2884",
    "Zusatzziffer": "0",
    "Gemeindename": "Clos du Doubs",
    "BFS-Nr": "6808",
    "Kantonskürzel": "JU",
    "E": "2578339.5309999995",
    "N": "1244708.3850000016",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Epauvillers",
    "PLZ": "2885",
    "Zusatzziffer": "0",
    "Gemeindename": "Clos du Doubs",
    "BFS-Nr": "6808",
    "Kantonskürzel": "JU",
    "E": "2575583.9389999993",
    "N": "1242753.765999999",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Epiquerez",
    "PLZ": "2886",
    "Zusatzziffer": "0",
    "Gemeindename": "Clos du Doubs",
    "BFS-Nr": "6808",
    "Kantonskürzel": "JU",
    "E": "2571221.671",
    "N": "1242047.1130000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Seleute",
    "PLZ": "2888",
    "Zusatzziffer": "0",
    "Gemeindename": "Clos du Doubs",
    "BFS-Nr": "6808",
    "Kantonskürzel": "JU",
    "E": "2575284.7859999985",
    "N": "1246188.1259999983",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Ocourt",
    "PLZ": "2889",
    "Zusatzziffer": "0",
    "Gemeindename": "Clos du Doubs",
    "BFS-Nr": "6808",
    "Kantonskürzel": "JU",
    "E": "2572746.2360000014",
    "N": "1244626.704",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Chevenez",
    "PLZ": "2906",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Ajoie",
    "BFS-Nr": "6809",
    "Kantonskürzel": "JU",
    "E": "2566979.7930000015",
    "N": "1249085.585000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Rocourt",
    "PLZ": "2907",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Ajoie",
    "BFS-Nr": "6809",
    "Kantonskürzel": "JU",
    "E": "2563589.022999998",
    "N": "1248827.875",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Réclère",
    "PLZ": "2912",
    "Zusatzziffer": "2",
    "Gemeindename": "Haute-Ajoie",
    "BFS-Nr": "6809",
    "Kantonskürzel": "JU",
    "E": "2561106.8850000016",
    "N": "1247330.370000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Roche-d'Or",
    "PLZ": "2912",
    "Zusatzziffer": "3",
    "Gemeindename": "Haute-Ajoie",
    "BFS-Nr": "6809",
    "Kantonskürzel": "JU",
    "E": "2563394.649",
    "N": "1246376.375",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Damvant",
    "PLZ": "2914",
    "Zusatzziffer": "0",
    "Gemeindename": "Haute-Ajoie",
    "BFS-Nr": "6809",
    "Kantonskürzel": "JU",
    "E": "2559098.1739999987",
    "N": "1247002.8460000008",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Miécourt",
    "PLZ": "2946",
    "Zusatzziffer": "0",
    "Gemeindename": "La Baroche",
    "BFS-Nr": "6810",
    "Kantonskürzel": "JU",
    "E": "2580216.675999999",
    "N": "1252840.7179999985",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Charmoille",
    "PLZ": "2947",
    "Zusatzziffer": "0",
    "Gemeindename": "La Baroche",
    "BFS-Nr": "6810",
    "Kantonskürzel": "JU",
    "E": "2582326.307",
    "N": "1252547.0659999996",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Fregiécourt",
    "PLZ": "2953",
    "Zusatzziffer": "2",
    "Gemeindename": "La Baroche",
    "BFS-Nr": "6810",
    "Kantonskürzel": "JU",
    "E": "2581835.1050000004",
    "N": "1251236.381000001",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Pleujouse",
    "PLZ": "2953",
    "Zusatzziffer": "3",
    "Gemeindename": "La Baroche",
    "BFS-Nr": "6810",
    "Kantonskürzel": "JU",
    "E": "2582820.5560000017",
    "N": "1251254.3420000002",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Asuel",
    "PLZ": "2954",
    "Zusatzziffer": "0",
    "Gemeindename": "La Baroche",
    "BFS-Nr": "6810",
    "Kantonskürzel": "JU",
    "E": "2582828.846000001",
    "N": "1250153.4770000018",
    "Sprache": "fr"
  },
  {
    "Ortschaftsname": "Vaduz",
    "PLZ": "9490",
    "Zusatzziffer": "0",
    "Gemeindename": "Vaduz",
    "BFS-Nr": "7001",
    "Kantonskürzel": "LI",
    "E": "2758077.0190000013",
    "N": "1222684.8150000013",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Triesen",
    "PLZ": "9495",
    "Zusatzziffer": "0",
    "Gemeindename": "Triesen",
    "BFS-Nr": "7002",
    "Kantonskürzel": "LI",
    "E": "2758755.2820000015",
    "N": "1219338.4750000015",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Balzers",
    "PLZ": "9496",
    "Zusatzziffer": "0",
    "Gemeindename": "Balzers",
    "BFS-Nr": "7003",
    "Kantonskürzel": "LI",
    "E": "2756750.2600000016",
    "N": "1214756.0610000007",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Triesenberg",
    "PLZ": "9497",
    "Zusatzziffer": "0",
    "Gemeindename": "Triesenberg",
    "BFS-Nr": "7004",
    "Kantonskürzel": "LI",
    "E": "2759753.022999998",
    "N": "1220688.7729999982",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schaan",
    "PLZ": "9494",
    "Zusatzziffer": "0",
    "Gemeindename": "Schaan",
    "BFS-Nr": "7005",
    "Kantonskürzel": "LI",
    "E": "2756734.34",
    "N": "1226748.6999999993",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Planken",
    "PLZ": "9498",
    "Zusatzziffer": "0",
    "Gemeindename": "Planken",
    "BFS-Nr": "7006",
    "Kantonskürzel": "LI",
    "E": "2759689.9849999994",
    "N": "1228164.3449999988",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Nendeln",
    "PLZ": "9485",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschen",
    "BFS-Nr": "7007",
    "Kantonskürzel": "LI",
    "E": "2759518.7410000004",
    "N": "1229630.7309999987",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Eschen",
    "PLZ": "9492",
    "Zusatzziffer": "0",
    "Gemeindename": "Eschen",
    "BFS-Nr": "7007",
    "Kantonskürzel": "LI",
    "E": "2757977.3390000015",
    "N": "1231111.6160000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schaanwald",
    "PLZ": "9486",
    "Zusatzziffer": "0",
    "Gemeindename": "Mauren",
    "BFS-Nr": "7008",
    "Kantonskürzel": "LI",
    "E": "2760824.1950000003",
    "N": "1231314.2280000001",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Mauren FL",
    "PLZ": "9493",
    "Zusatzziffer": "0",
    "Gemeindename": "Mauren",
    "BFS-Nr": "7008",
    "Kantonskürzel": "LI",
    "E": "2759436.283",
    "N": "1232066.6840000004",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Gamprin-Bendern",
    "PLZ": "9487",
    "Zusatzziffer": "0",
    "Gemeindename": "Gamprin",
    "BFS-Nr": "7009",
    "Kantonskürzel": "LI",
    "E": "2756594.239",
    "N": "1231420.7919999994",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Ruggell",
    "PLZ": "9491",
    "Zusatzziffer": "0",
    "Gemeindename": "Ruggell",
    "BFS-Nr": "7010",
    "Kantonskürzel": "LI",
    "E": "2758240.811999999",
    "N": "1234338.7380000018",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Schellenberg",
    "PLZ": "9488",
    "Zusatzziffer": "0",
    "Gemeindename": "Schellenberg",
    "BFS-Nr": "7011",
    "Kantonskürzel": "LI",
    "E": "2759569.642999999",
    "N": "1233302.7210000008",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Thunersee",
    "PLZ": "9999",
    "Zusatzziffer": "1",
    "Gemeindename": "Thunersee",
    "BFS-Nr": "9073",
    "Kantonskürzel": "BE",
    "E": "2621181.563000001",
    "N": "1170794.3079999983",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Brienzersee",
    "PLZ": "9999",
    "Zusatzziffer": "2",
    "Gemeindename": "Brienzersee",
    "BFS-Nr": "9089",
    "Kantonskürzel": "BE",
    "E": "2640931.114",
    "N": "1175395.6539999992",
    "Sprache": "de"
  },
  {
    "Ortschaftsname": "Bielersee",
    "PLZ": "9999",
    "Zusatzziffer": "0",
    "Gemeindename": "Bielersee (BE)",
    "BFS-Nr": "9149",
    "Kantonskürzel": "BE",
    "E": "2580262.096000001",
    "N": "1215169.4349999987",
    "Sprache": "de"
  }

]


mock.onGet('/addresses').reply(config => {
  // eslint-disable-next-line object-curly-newline
  //   const {
  //     ort = '',
  //       kanton = '',
  //       plz = '',
  //   } = config.params


  //   /* eslint-enable */
  //   debugger
  //   const queryOrtLowered = ort.toLowerCase()
  //   const queryKantonLowered = kanton.toLowerCase()
  //   const queryPlzLowered = plz.toLowerCase()
  //   const filteredData = data.filter(
  //     a =>
  //     /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
  //     (a.Ortschaftsname.toLowerCase().includes(queryOrtLowered) ||
  //       a.Kantonskürzel.toLowerCase().includes(queryKantonLowered)),
  //     a.PLZ.toLowerCase().includes(queryPlzLowered));
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  var plzList = data.map(item => {
    return item.PLZ;
  }).filter(onlyUnique);

  var ortList = data.map(item => {
    return {
      name: item.Ortschaftsname,
      plz: item.PLZ
    }
  }).filter(onlyUnique);

  var kantonList = data.map(item => {
    return {
      name: item.Kantonskürzel,
      plz: item.PLZ
    }
  }).filter(onlyUnique);

  return [
    200,
    {
      data: {
        plzList: plzList,
        ortList: ortList,
        kantonList: kantonList
      }
    },
  ]
})