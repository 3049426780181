import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    treatments: [{ "id": 1, "kundID": 1, "mitarbeiterID": 1, "behandlungsdatum": "2021-05-04T11:36:26Z", "kundName": "Dulcie", "mitarbeiterName": "Hart", "behandlung": "behandlung1", "koerperregion": "koerperregion1", "preis": 798.82, "rabatt": 759.24, "energie": 691.32, "frequenz": 622.13, "durchmesser": 739.51, "energiedichte": 70.33, "bemerkung": "scelerisque quam turpis adipiscing lorem vitae mattis nibh ligula nec sem" },
      { "id": 2, "kundID": 2, "mitarbeiterID": 2, "behandlungsdatum": "2021-04-11T21:15:28Z", "kundName": "Ermina", "mitarbeiterName": "Amory", "behandlung": "behandlung3", "koerperregion": "koerperregion2", "preis": 392.54, "rabatt": 549.34, "energie": 758.93, "frequenz": 720.43, "durchmesser": 663.93, "energiedichte": 221.35, "bemerkung": "aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas" },
      { "id": 3, "kundID": 3, "mitarbeiterID": 3, "behandlungsdatum": "2021-07-26T15:19:08Z", "kundName": "Algernon", "mitarbeiterName": "Simona", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 849.59, "rabatt": 564.0, "energie": 987.52, "frequenz": 648.64, "durchmesser": 698.8, "energiedichte": 936.09, "bemerkung": "amet sem fusce consequat nulla nisl nunc nisl duis bibendum felis sed interdum venenatis turpis enim blandit" },
      { "id": 4, "kundID": 4, "mitarbeiterID": 4, "behandlungsdatum": "2021-07-17T06:32:49Z", "kundName": "Freddy", "mitarbeiterName": "Blondie", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 157.34, "rabatt": 814.68, "energie": 68.19, "frequenz": 169.42, "durchmesser": 109.76, "energiedichte": 825.57, "bemerkung": "enim lorem ipsum dolor sit amet consectetuer adipiscing elit proin interdum mauris" },
      { "id": 5, "kundID": 5, "mitarbeiterID": 5, "behandlungsdatum": "2021-01-01T14:14:20Z", "kundName": "Audi", "mitarbeiterName": "Silvanus", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 925.16, "rabatt": 482.14, "energie": 908.62, "frequenz": 463.17, "durchmesser": 88.41, "energiedichte": 345.87, "bemerkung": "venenatis tristique fusce congue diam id ornare imperdiet sapien urna pretium nisl ut" },
      { "id": 6, "kundID": 6, "mitarbeiterID": 6, "behandlungsdatum": "2021-10-30T23:55:11Z", "kundName": "Clarine", "mitarbeiterName": "Royce", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 323.56, "rabatt": 232.22, "energie": 724.42, "frequenz": 548.95, "durchmesser": 128.67, "energiedichte": 561.59, "bemerkung": "urna pretium nisl ut volutpat sapien arcu sed augue aliquam erat volutpat" },
      { "id": 7, "kundID": 7, "mitarbeiterID": 7, "behandlungsdatum": "2021-04-09T05:03:42Z", "kundName": "Ailbert", "mitarbeiterName": "Cordey", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 471.31, "rabatt": 551.97, "energie": 568.56, "frequenz": 389.97, "durchmesser": 611.0, "energiedichte": 570.04, "bemerkung": "velit vivamus vel nulla eget eros elementum pellentesque quisque porta volutpat erat quisque erat eros viverra eget congue eget semper" },
      { "id": 8, "kundID": 8, "mitarbeiterID": 8, "behandlungsdatum": "2021-08-22T20:12:08Z", "kundName": "Bevon", "mitarbeiterName": "Jessamyn", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 262.76, "rabatt": 178.24, "energie": 681.85, "frequenz": 937.07, "durchmesser": 263.92, "energiedichte": 458.28, "bemerkung": "ac est lacinia nisi venenatis tristique fusce congue diam id ornare imperdiet sapien urna pretium nisl" },
      { "id": 9, "kundID": 9, "mitarbeiterID": 9, "behandlungsdatum": "2021-04-11T14:40:20Z", "kundName": "Adolphe", "mitarbeiterName": "Ivor", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 509.36, "rabatt": 586.15, "energie": 745.15, "frequenz": 797.68, "durchmesser": 269.75, "energiedichte": 698.12, "bemerkung": "quam turpis adipiscing lorem vitae mattis nibh ligula nec sem duis aliquam convallis nunc proin at turpis a pede" },
      { "id": 10, "kundID": 10, "mitarbeiterID": 10, "behandlungsdatum": "2021-11-07T08:34:47Z", "kundName": "Goldia", "mitarbeiterName": "Genovera", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 129.55, "rabatt": 802.9, "energie": 311.35, "frequenz": 168.4, "durchmesser": 599.47, "energiedichte": 759.0, "bemerkung": "sodales scelerisque mauris sit amet eros suspendisse accumsan tortor quis turpis" },
      { "id": 11, "kundID": 11, "mitarbeiterID": 11, "behandlungsdatum": "2021-05-07T12:36:53Z", "kundName": "Kimmi", "mitarbeiterName": "Tana", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 684.03, "rabatt": 368.64, "energie": 585.16, "frequenz": 260.1, "durchmesser": 100.35, "energiedichte": 550.07, "bemerkung": "ullamcorper purus sit amet nulla quisque arcu libero rutrum ac lobortis vel dapibus at diam nam tristique tortor" },
      { "id": 12, "kundID": 12, "mitarbeiterID": 12, "behandlungsdatum": "2021-09-22T10:03:58Z", "kundName": "Ermentrude", "mitarbeiterName": "Madalena", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 907.04, "rabatt": 257.44, "energie": 177.16, "frequenz": 843.0, "durchmesser": 174.58, "energiedichte": 718.04, "bemerkung": "ultricies eu nibh quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus et" },
      { "id": 13, "kundID": 13, "mitarbeiterID": 13, "behandlungsdatum": "2021-07-12T18:57:45Z", "kundName": "Dulce", "mitarbeiterName": "Violetta", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 602.23, "rabatt": 956.65, "energie": 847.5, "frequenz": 370.28, "durchmesser": 738.69, "energiedichte": 973.24, "bemerkung": "arcu sed augue aliquam erat volutpat in congue etiam justo etiam" },
      { "id": 14, "kundID": 14, "mitarbeiterID": 14, "behandlungsdatum": "2021-07-13T09:08:55Z", "kundName": "Abbott", "mitarbeiterName": "Emmalynn", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 167.26, "rabatt": 888.19, "energie": 984.57, "frequenz": 804.36, "durchmesser": 579.22, "energiedichte": 905.48, "bemerkung": "proin leo odio porttitor id consequat in consequat ut nulla sed" },
      { "id": 15, "kundID": 15, "mitarbeiterID": 15, "behandlungsdatum": "2021-02-10T10:54:00Z", "kundName": "Ora", "mitarbeiterName": "Panchito", "behandlung": "behandlung3", "koerperregion": "koerperregion2", "preis": 576.91, "rabatt": 177.65, "energie": 720.56, "frequenz": 885.21, "durchmesser": 61.09, "energiedichte": 95.43, "bemerkung": "nec euismod scelerisque quam turpis adipiscing lorem vitae mattis nibh ligula nec sem duis" },
      { "id": 16, "kundID": 16, "mitarbeiterID": 16, "behandlungsdatum": "2021-04-11T11:12:49Z", "kundName": "Burg", "mitarbeiterName": "Bette", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 402.95, "rabatt": 381.47, "energie": 698.08, "frequenz": 412.94, "durchmesser": 64.05, "energiedichte": 513.16, "bemerkung": "nunc viverra dapibus nulla suscipit ligula in lacus curabitur at ipsum ac tellus semper interdum mauris ullamcorper purus sit" },
      { "id": 17, "kundID": 17, "mitarbeiterID": 17, "behandlungsdatum": "2021-02-19T20:13:32Z", "kundName": "Corette", "mitarbeiterName": "Sibyl", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 793.61, "rabatt": 809.03, "energie": 604.16, "frequenz": 514.59, "durchmesser": 256.8, "energiedichte": 148.52, "bemerkung": "integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet" },
      { "id": 18, "kundID": 18, "mitarbeiterID": 18, "behandlungsdatum": "2021-08-17T09:27:16Z", "kundName": "Angelika", "mitarbeiterName": "Noam", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 958.64, "rabatt": 299.36, "energie": 254.19, "frequenz": 260.68, "durchmesser": 192.43, "energiedichte": 737.01, "bemerkung": "vel sem sed sagittis nam congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend pede libero" },
      { "id": 19, "kundID": 19, "mitarbeiterID": 19, "behandlungsdatum": "2020-12-14T05:55:01Z", "kundName": "Brett", "mitarbeiterName": "Augustina", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 85.68, "rabatt": 572.89, "energie": 519.08, "frequenz": 72.71, "durchmesser": 409.97, "energiedichte": 614.87, "bemerkung": "semper interdum mauris ullamcorper purus sit amet nulla quisque arcu libero rutrum ac lobortis vel dapibus at" },
      { "id": 20, "kundID": 20, "mitarbeiterID": 20, "behandlungsdatum": "2020-12-22T02:59:02Z", "kundName": "Delila", "mitarbeiterName": "Ximenez", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 68.84, "rabatt": 723.27, "energie": 573.95, "frequenz": 415.9, "durchmesser": 37.57, "energiedichte": 374.87, "bemerkung": "iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate vitae nisl aenean lectus pellentesque" },
      { "id": 21, "kundID": 21, "mitarbeiterID": 21, "behandlungsdatum": "2021-10-14T04:30:48Z", "kundName": "Nananne", "mitarbeiterName": "Annabell", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 542.11, "rabatt": 20.79, "energie": 143.29, "frequenz": 177.1, "durchmesser": 430.1, "energiedichte": 414.66, "bemerkung": "odio in hac habitasse platea dictumst maecenas ut massa quis augue luctus tincidunt nulla mollis molestie" },
      { "id": 22, "kundID": 22, "mitarbeiterID": 22, "behandlungsdatum": "2021-01-25T08:39:31Z", "kundName": "Arlan", "mitarbeiterName": "Astrix", "behandlung": "behandlung1", "koerperregion": "koerperregion1", "preis": 46.86, "rabatt": 141.86, "energie": 430.24, "frequenz": 301.99, "durchmesser": 711.78, "energiedichte": 978.79, "bemerkung": "ultrices posuere cubilia curae mauris viverra diam vitae quam suspendisse potenti nullam" },
      { "id": 23, "kundID": 23, "mitarbeiterID": 23, "behandlungsdatum": "2021-04-26T02:59:07Z", "kundName": "Faustina", "mitarbeiterName": "Tootsie", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 211.94, "rabatt": 619.59, "energie": 663.92, "frequenz": 868.27, "durchmesser": 601.35, "energiedichte": 231.46, "bemerkung": "nisl aenean lectus pellentesque eget nunc donec quis orci eget orci" },
      { "id": 24, "kundID": 24, "mitarbeiterID": 24, "behandlungsdatum": "2021-05-15T10:14:39Z", "kundName": "Rutter", "mitarbeiterName": "Bree", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 140.71, "rabatt": 793.24, "energie": 469.78, "frequenz": 69.19, "durchmesser": 557.15, "energiedichte": 572.51, "bemerkung": "ipsum dolor sit amet consectetuer adipiscing elit proin risus praesent lectus vestibulum quam" },
      { "id": 25, "kundID": 25, "mitarbeiterID": 25, "behandlungsdatum": "2021-08-17T09:42:36Z", "kundName": "Hilly", "mitarbeiterName": "Gibb", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 997.95, "rabatt": 830.83, "energie": 457.22, "frequenz": 10.62, "durchmesser": 715.71, "energiedichte": 207.12, "bemerkung": "scelerisque mauris sit amet eros suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus aenean fermentum" },
      { "id": 26, "kundID": 26, "mitarbeiterID": 26, "behandlungsdatum": "2021-07-06T06:22:38Z", "kundName": "Dolf", "mitarbeiterName": "Norine", "behandlung": "behandlung2", "koerperregion": "koerperregion3", "preis": 251.25, "rabatt": 750.31, "energie": 135.19, "frequenz": 653.68, "durchmesser": 186.14, "energiedichte": 763.31, "bemerkung": "aliquet maecenas leo odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam cras pellentesque" },
      { "id": 27, "kundID": 27, "mitarbeiterID": 27, "behandlungsdatum": "2021-01-21T20:26:46Z", "kundName": "Gill", "mitarbeiterName": "Virginie", "behandlung": "behandlung1", "koerperregion": "koerperregion1", "preis": 132.08, "rabatt": 541.45, "energie": 266.64, "frequenz": 522.41, "durchmesser": 496.89, "energiedichte": 210.76, "bemerkung": "faucibus orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec odio justo sollicitudin" },
      { "id": 28, "kundID": 28, "mitarbeiterID": 28, "behandlungsdatum": "2021-01-17T18:47:35Z", "kundName": "Torrie", "mitarbeiterName": "Calhoun", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 755.47, "rabatt": 475.26, "energie": 338.4, "frequenz": 818.76, "durchmesser": 481.87, "energiedichte": 559.32, "bemerkung": "vestibulum ante ipsum primis in faucibus orci luctus et ultrices" },
      { "id": 29, "kundID": 29, "mitarbeiterID": 29, "behandlungsdatum": "2021-08-11T20:29:51Z", "kundName": "Cate", "mitarbeiterName": "Lorrie", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 74.05, "rabatt": 908.35, "energie": 517.41, "frequenz": 831.51, "durchmesser": 788.25, "energiedichte": 74.37, "bemerkung": "eros suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus aenean fermentum" },
      { "id": 30, "kundID": 30, "mitarbeiterID": 30, "behandlungsdatum": "2021-03-29T04:09:41Z", "kundName": "Farrell", "mitarbeiterName": "Saul", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 160.37, "rabatt": 464.53, "energie": 92.46, "frequenz": 860.54, "durchmesser": 44.34, "energiedichte": 317.02, "bemerkung": "nonummy integer non velit donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum primis" },
      { "id": 31, "kundID": 31, "mitarbeiterID": 31, "behandlungsdatum": "2021-09-29T17:30:04Z", "kundName": "Nollie", "mitarbeiterName": "Dom", "behandlung": "behandlung2", "koerperregion": "koerperregion1", "preis": 13.3, "rabatt": 292.08, "energie": 705.56, "frequenz": 129.66, "durchmesser": 306.04, "energiedichte": 614.06, "bemerkung": "vel pede morbi porttitor lorem id ligula suspendisse ornare consequat lectus in est risus auctor sed" },
      { "id": 32, "kundID": 32, "mitarbeiterID": 32, "behandlungsdatum": "2021-04-11T11:24:05Z", "kundName": "Billy", "mitarbeiterName": "Marcelle", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 749.49, "rabatt": 818.07, "energie": 740.07, "frequenz": 591.51, "durchmesser": 291.7, "energiedichte": 596.9, "bemerkung": "ut massa volutpat convallis morbi odio odio elementum eu interdum eu tincidunt in leo maecenas" },
      { "id": 33, "kundID": 33, "mitarbeiterID": 33, "behandlungsdatum": "2021-05-02T19:56:50Z", "kundName": "Payton", "mitarbeiterName": "Dredi", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 21.5, "rabatt": 456.86, "energie": 871.37, "frequenz": 144.01, "durchmesser": 658.52, "energiedichte": 294.68, "bemerkung": "vulputate justo in blandit ultrices enim lorem ipsum dolor sit amet consectetuer adipiscing elit proin interdum mauris non ligula" },
      { "id": 34, "kundID": 34, "mitarbeiterID": 34, "behandlungsdatum": "2021-05-20T06:14:54Z", "kundName": "Kermie", "mitarbeiterName": "Rheba", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 657.33, "rabatt": 994.36, "energie": 271.53, "frequenz": 492.73, "durchmesser": 471.33, "energiedichte": 119.57, "bemerkung": "sapien sapien non mi integer ac neque duis bibendum morbi non quam" },
      { "id": 35, "kundID": 35, "mitarbeiterID": 35, "behandlungsdatum": "2021-08-07T19:37:43Z", "kundName": "Ralf", "mitarbeiterName": "Whitaker", "behandlung": "behandlung3", "koerperregion": "koerperregion2", "preis": 263.19, "rabatt": 983.05, "energie": 557.49, "frequenz": 435.28, "durchmesser": 573.37, "energiedichte": 180.76, "bemerkung": "quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in" },
      { "id": 36, "kundID": 36, "mitarbeiterID": 36, "behandlungsdatum": "2021-06-19T21:53:03Z", "kundName": "Jana", "mitarbeiterName": "Goldy", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 344.41, "rabatt": 828.91, "energie": 440.18, "frequenz": 85.79, "durchmesser": 888.55, "energiedichte": 162.14, "bemerkung": "quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus" },
      { "id": 37, "kundID": 37, "mitarbeiterID": 37, "behandlungsdatum": "2020-12-03T03:19:14Z", "kundName": "Roberta", "mitarbeiterName": "Emilee", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 803.84, "rabatt": 241.07, "energie": 156.3, "frequenz": 612.48, "durchmesser": 924.46, "energiedichte": 423.31, "bemerkung": "duis bibendum felis sed interdum venenatis turpis enim blandit mi in porttitor pede justo eu massa donec" },
      { "id": 38, "kundID": 38, "mitarbeiterID": 38, "behandlungsdatum": "2021-01-12T06:36:22Z", "kundName": "Marlena", "mitarbeiterName": "Daphne", "behandlung": "behandlung2", "koerperregion": "koerperregion3", "preis": 205.95, "rabatt": 199.68, "energie": 184.15, "frequenz": 135.04, "durchmesser": 958.14, "energiedichte": 673.0, "bemerkung": "odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam" },
      { "id": 39, "kundID": 39, "mitarbeiterID": 39, "behandlungsdatum": "2021-04-22T04:51:51Z", "kundName": "Deb", "mitarbeiterName": "Sandra", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 881.76, "rabatt": 713.06, "energie": 712.04, "frequenz": 721.4, "durchmesser": 334.35, "energiedichte": 129.68, "bemerkung": "tempor turpis nec euismod scelerisque quam turpis adipiscing lorem vitae mattis nibh ligula nec sem duis aliquam convallis nunc" },
      { "id": 40, "kundID": 40, "mitarbeiterID": 40, "behandlungsdatum": "2020-11-20T18:14:19Z", "kundName": "Bertrando", "mitarbeiterName": "Kevon", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 306.34, "rabatt": 880.75, "energie": 275.95, "frequenz": 628.56, "durchmesser": 45.49, "energiedichte": 700.57, "bemerkung": "curae nulla dapibus dolor vel est donec odio justo sollicitudin" },
      { "id": 41, "kundID": 41, "mitarbeiterID": 41, "behandlungsdatum": "2021-05-18T13:09:06Z", "kundName": "Joice", "mitarbeiterName": "Haskel", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 248.14, "rabatt": 49.76, "energie": 465.78, "frequenz": 157.54, "durchmesser": 782.96, "energiedichte": 304.62, "bemerkung": "metus aenean fermentum donec ut mauris eget massa tempor convallis nulla neque libero convallis eget eleifend luctus" },
      { "id": 42, "kundID": 42, "mitarbeiterID": 42, "behandlungsdatum": "2021-09-23T13:48:36Z", "kundName": "Sheff", "mitarbeiterName": "Jamal", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 81.79, "rabatt": 558.49, "energie": 232.12, "frequenz": 809.4, "durchmesser": 367.69, "energiedichte": 805.11, "bemerkung": "lectus pellentesque at nulla suspendisse potenti cras in purus eu magna vulputate luctus cum sociis natoque penatibus et magnis" },
      { "id": 43, "kundID": 43, "mitarbeiterID": 43, "behandlungsdatum": "2021-04-29T13:09:31Z", "kundName": "Neall", "mitarbeiterName": "Benton", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 172.3, "rabatt": 291.32, "energie": 739.17, "frequenz": 600.42, "durchmesser": 115.92, "energiedichte": 503.68, "bemerkung": "tortor id nulla ultrices aliquet maecenas leo odio condimentum id" },
      { "id": 44, "kundID": 44, "mitarbeiterID": 44, "behandlungsdatum": "2021-06-27T05:00:00Z", "kundName": "Doria", "mitarbeiterName": "Lucy", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 399.03, "rabatt": 40.97, "energie": 905.57, "frequenz": 989.48, "durchmesser": 309.57, "energiedichte": 334.05, "bemerkung": "faucibus accumsan odio curabitur convallis duis consequat dui nec nisi volutpat eleifend donec ut dolor morbi vel" },
      { "id": 45, "kundID": 45, "mitarbeiterID": 45, "behandlungsdatum": "2021-01-04T11:24:04Z", "kundName": "Gill", "mitarbeiterName": "Richy", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 569.11, "rabatt": 620.83, "energie": 381.67, "frequenz": 981.37, "durchmesser": 153.37, "energiedichte": 392.47, "bemerkung": "interdum in ante vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae" },
      { "id": 46, "kundID": 46, "mitarbeiterID": 46, "behandlungsdatum": "2021-04-13T12:36:38Z", "kundName": "Breena", "mitarbeiterName": "Antoni", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 37.58, "rabatt": 451.79, "energie": 577.05, "frequenz": 400.25, "durchmesser": 162.62, "energiedichte": 433.32, "bemerkung": "nulla nisl nunc nisl duis bibendum felis sed interdum venenatis turpis" },
      { "id": 47, "kundID": 47, "mitarbeiterID": 47, "behandlungsdatum": "2021-09-11T09:03:23Z", "kundName": "Anatole", "mitarbeiterName": "Samantha", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 761.86, "rabatt": 57.78, "energie": 167.6, "frequenz": 639.37, "durchmesser": 185.72, "energiedichte": 123.41, "bemerkung": "mauris eget massa tempor convallis nulla neque libero convallis eget eleifend luctus ultricies eu" },
      { "id": 48, "kundID": 48, "mitarbeiterID": 48, "behandlungsdatum": "2020-12-16T18:23:51Z", "kundName": "Desmond", "mitarbeiterName": "Karoline", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 343.91, "rabatt": 158.31, "energie": 869.93, "frequenz": 566.37, "durchmesser": 594.02, "energiedichte": 660.2, "bemerkung": "volutpat sapien arcu sed augue aliquam erat volutpat in congue etiam justo etiam pretium" },
      { "id": 49, "kundID": 49, "mitarbeiterID": 49, "behandlungsdatum": "2021-01-23T01:32:35Z", "kundName": "Alaine", "mitarbeiterName": "Raphael", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 262.34, "rabatt": 204.78, "energie": 723.5, "frequenz": 549.39, "durchmesser": 820.1, "energiedichte": 790.77, "bemerkung": "urna ut tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi cras" },
      { "id": 50, "kundID": 50, "mitarbeiterID": 50, "behandlungsdatum": "2021-04-15T23:34:01Z", "kundName": "Danila", "mitarbeiterName": "Esmaria", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 399.72, "rabatt": 810.6, "energie": 182.57, "frequenz": 194.32, "durchmesser": 202.73, "energiedichte": 339.05, "bemerkung": "consequat lectus in est risus auctor sed tristique in tempus sit amet" },
      { "id": 51, "kundID": 51, "mitarbeiterID": 51, "behandlungsdatum": "2021-09-29T22:09:19Z", "kundName": "Toddy", "mitarbeiterName": "Colan", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 206.51, "rabatt": 630.22, "energie": 916.89, "frequenz": 701.53, "durchmesser": 865.99, "energiedichte": 810.48, "bemerkung": "commodo placerat praesent blandit nam nulla integer pede justo lacinia eget tincidunt eget tempus vel pede morbi porttitor" },
      { "id": 52, "kundID": 52, "mitarbeiterID": 52, "behandlungsdatum": "2021-05-27T13:40:52Z", "kundName": "Worth", "mitarbeiterName": "Betsey", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 727.26, "rabatt": 28.36, "energie": 483.53, "frequenz": 692.69, "durchmesser": 320.6, "energiedichte": 741.36, "bemerkung": "cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat dui" },
      { "id": 53, "kundID": 53, "mitarbeiterID": 53, "behandlungsdatum": "2021-11-03T03:34:25Z", "kundName": "Arturo", "mitarbeiterName": "Cob", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 166.63, "rabatt": 75.13, "energie": 385.21, "frequenz": 58.96, "durchmesser": 971.56, "energiedichte": 466.99, "bemerkung": "volutpat eleifend donec ut dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed vestibulum sit" },
      { "id": 54, "kundID": 54, "mitarbeiterID": 54, "behandlungsdatum": "2021-11-12T13:58:40Z", "kundName": "Elayne", "mitarbeiterName": "Anny", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 421.22, "rabatt": 370.67, "energie": 55.58, "frequenz": 694.53, "durchmesser": 784.65, "energiedichte": 266.23, "bemerkung": "nisl duis ac nibh fusce lacus purus aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam" },
      { "id": 55, "kundID": 55, "mitarbeiterID": 55, "behandlungsdatum": "2020-12-16T15:16:02Z", "kundName": "Teador", "mitarbeiterName": "Katherine", "behandlung": "behandlung1", "koerperregion": "koerperregion3", "preis": 818.15, "rabatt": 371.96, "energie": 268.07, "frequenz": 396.53, "durchmesser": 934.75, "energiedichte": 74.14, "bemerkung": "congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend pede libero" },
      { "id": 56, "kundID": 56, "mitarbeiterID": 56, "behandlungsdatum": "2021-07-20T21:05:11Z", "kundName": "Kellie", "mitarbeiterName": "Tibold", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 76.97, "rabatt": 370.63, "energie": 232.24, "frequenz": 706.59, "durchmesser": 824.24, "energiedichte": 996.92, "bemerkung": "iaculis diam erat fermentum justo nec condimentum neque sapien placerat ante nulla" },
      { "id": 57, "kundID": 57, "mitarbeiterID": 57, "behandlungsdatum": "2021-07-11T04:02:45Z", "kundName": "Emmaline", "mitarbeiterName": "Shayne", "behandlung": "behandlung3", "koerperregion": "koerperregion2", "preis": 348.91, "rabatt": 793.0, "energie": 440.17, "frequenz": 138.91, "durchmesser": 770.27, "energiedichte": 475.38, "bemerkung": "quis odio consequat varius integer ac leo pellentesque ultrices mattis" },
      { "id": 58, "kundID": 58, "mitarbeiterID": 58, "behandlungsdatum": "2021-09-26T01:34:39Z", "kundName": "Luigi", "mitarbeiterName": "Barbey", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 55.21, "rabatt": 289.19, "energie": 674.42, "frequenz": 931.13, "durchmesser": 523.13, "energiedichte": 873.76, "bemerkung": "rutrum neque aenean auctor gravida sem praesent id massa id nisl venenatis lacinia aenean" },
      { "id": 59, "kundID": 59, "mitarbeiterID": 59, "behandlungsdatum": "2021-07-27T22:59:45Z", "kundName": "Fraze", "mitarbeiterName": "Raoul", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 596.08, "rabatt": 217.1, "energie": 759.19, "frequenz": 365.57, "durchmesser": 454.96, "energiedichte": 922.19, "bemerkung": "arcu libero rutrum ac lobortis vel dapibus at diam nam tristique tortor" },
      { "id": 60, "kundID": 60, "mitarbeiterID": 60, "behandlungsdatum": "2021-03-10T07:47:20Z", "kundName": "Meris", "mitarbeiterName": "Audry", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 805.3, "rabatt": 72.91, "energie": 235.85, "frequenz": 658.13, "durchmesser": 744.83, "energiedichte": 93.99, "bemerkung": "ultrices posuere cubilia curae donec pharetra magna vestibulum aliquet ultrices erat tortor sollicitudin mi sit amet lobortis" },
      { "id": 61, "kundID": 61, "mitarbeiterID": 61, "behandlungsdatum": "2021-03-21T06:30:06Z", "kundName": "Port", "mitarbeiterName": "Simona", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 708.29, "rabatt": 86.46, "energie": 549.49, "frequenz": 842.2, "durchmesser": 708.02, "energiedichte": 587.6, "bemerkung": "donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum" },
      { "id": 62, "kundID": 62, "mitarbeiterID": 62, "behandlungsdatum": "2020-11-27T22:12:06Z", "kundName": "Willa", "mitarbeiterName": "Verne", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 15.64, "rabatt": 220.22, "energie": 641.39, "frequenz": 990.75, "durchmesser": 363.67, "energiedichte": 427.34, "bemerkung": "condimentum curabitur in libero ut massa volutpat convallis morbi odio odio elementum eu interdum eu tincidunt in leo maecenas pulvinar" },
      { "id": 63, "kundID": 63, "mitarbeiterID": 63, "behandlungsdatum": "2021-09-10T11:24:48Z", "kundName": "Alix", "mitarbeiterName": "Hunter", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 55.6, "rabatt": 222.11, "energie": 19.86, "frequenz": 467.2, "durchmesser": 885.23, "energiedichte": 301.1, "bemerkung": "dolor sit amet consectetuer adipiscing elit proin interdum mauris non ligula pellentesque ultrices phasellus id sapien in sapien iaculis congue" },
      { "id": 64, "kundID": 64, "mitarbeiterID": 64, "behandlungsdatum": "2021-11-13T20:05:19Z", "kundName": "Mathe", "mitarbeiterName": "Rhodia", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 142.69, "rabatt": 350.6, "energie": 299.64, "frequenz": 934.79, "durchmesser": 749.91, "energiedichte": 805.09, "bemerkung": "pede malesuada in imperdiet et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor" },
      { "id": 65, "kundID": 65, "mitarbeiterID": 65, "behandlungsdatum": "2020-12-08T05:28:31Z", "kundName": "Willdon", "mitarbeiterName": "Tera", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 265.19, "rabatt": 332.9, "energie": 334.55, "frequenz": 800.35, "durchmesser": 843.58, "energiedichte": 706.21, "bemerkung": "suspendisse ornare consequat lectus in est risus auctor sed tristique in tempus sit" },
      { "id": 66, "kundID": 66, "mitarbeiterID": 66, "behandlungsdatum": "2021-11-03T03:59:17Z", "kundName": "Dame", "mitarbeiterName": "Marieann", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 602.81, "rabatt": 554.15, "energie": 316.9, "frequenz": 619.36, "durchmesser": 348.0, "energiedichte": 62.55, "bemerkung": "libero non mattis pulvinar nulla pede ullamcorper augue a suscipit nulla elit" },
      { "id": 67, "kundID": 67, "mitarbeiterID": 67, "behandlungsdatum": "2021-07-17T15:02:00Z", "kundName": "Jaquenette", "mitarbeiterName": "Mart", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 919.96, "rabatt": 808.87, "energie": 491.16, "frequenz": 595.32, "durchmesser": 83.35, "energiedichte": 738.09, "bemerkung": "posuere metus vitae ipsum aliquam non mauris morbi non lectus aliquam sit amet diam in magna bibendum imperdiet" },
      { "id": 68, "kundID": 68, "mitarbeiterID": 68, "behandlungsdatum": "2021-03-01T18:52:26Z", "kundName": "Mia", "mitarbeiterName": "Kay", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 498.66, "rabatt": 679.61, "energie": 493.6, "frequenz": 123.36, "durchmesser": 228.88, "energiedichte": 487.88, "bemerkung": "luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec" },
      { "id": 69, "kundID": 69, "mitarbeiterID": 69, "behandlungsdatum": "2021-01-29T15:17:02Z", "kundName": "Lorrayne", "mitarbeiterName": "Vaclav", "behandlung": "behandlung2", "koerperregion": "koerperregion3", "preis": 627.46, "rabatt": 27.76, "energie": 942.58, "frequenz": 828.62, "durchmesser": 130.75, "energiedichte": 894.01, "bemerkung": "ligula nec sem duis aliquam convallis nunc proin at turpis a pede posuere nonummy" },
      { "id": 70, "kundID": 70, "mitarbeiterID": 70, "behandlungsdatum": "2021-01-27T05:58:18Z", "kundName": "Malena", "mitarbeiterName": "Jorrie", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 907.41, "rabatt": 192.01, "energie": 976.47, "frequenz": 79.58, "durchmesser": 931.13, "energiedichte": 480.64, "bemerkung": "dapibus duis at velit eu est congue elementum in hac habitasse platea dictumst morbi" },
      { "id": 71, "kundID": 71, "mitarbeiterID": 71, "behandlungsdatum": "2021-10-14T07:56:24Z", "kundName": "Tillie", "mitarbeiterName": "Cecile", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 551.71, "rabatt": 849.86, "energie": 726.16, "frequenz": 524.42, "durchmesser": 399.89, "energiedichte": 726.12, "bemerkung": "libero nam dui proin leo odio porttitor id consequat in consequat ut nulla sed" },
      { "id": 72, "kundID": 72, "mitarbeiterID": 72, "behandlungsdatum": "2021-07-05T12:43:28Z", "kundName": "Sanders", "mitarbeiterName": "Tabb", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 812.09, "rabatt": 802.16, "energie": 586.19, "frequenz": 774.63, "durchmesser": 918.62, "energiedichte": 611.71, "bemerkung": "volutpat eleifend donec ut dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo" },
      { "id": 73, "kundID": 73, "mitarbeiterID": 73, "behandlungsdatum": "2021-06-21T19:56:01Z", "kundName": "Cammy", "mitarbeiterName": "Joy", "behandlung": "behandlung1", "koerperregion": "koerperregion3", "preis": 28.19, "rabatt": 585.25, "energie": 221.63, "frequenz": 421.46, "durchmesser": 206.45, "energiedichte": 638.27, "bemerkung": "luctus et ultrices posuere cubilia curae mauris viverra diam vitae" },
      { "id": 74, "kundID": 74, "mitarbeiterID": 74, "behandlungsdatum": "2021-04-30T14:09:21Z", "kundName": "Margarita", "mitarbeiterName": "Waldemar", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 798.57, "rabatt": 451.2, "energie": 621.53, "frequenz": 387.29, "durchmesser": 790.41, "energiedichte": 582.0, "bemerkung": "sollicitudin mi sit amet lobortis sapien sapien non mi integer ac neque" },
      { "id": 75, "kundID": 75, "mitarbeiterID": 75, "behandlungsdatum": "2021-10-20T18:34:58Z", "kundName": "Rivy", "mitarbeiterName": "Klarrisa", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 120.8, "rabatt": 274.44, "energie": 44.66, "frequenz": 805.88, "durchmesser": 361.17, "energiedichte": 460.49, "bemerkung": "lacinia erat vestibulum sed magna at nunc commodo placerat praesent blandit" },
      { "id": 76, "kundID": 76, "mitarbeiterID": 76, "behandlungsdatum": "2021-01-17T16:43:25Z", "kundName": "Terry", "mitarbeiterName": "Carlyn", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 768.71, "rabatt": 393.95, "energie": 825.17, "frequenz": 351.41, "durchmesser": 464.62, "energiedichte": 836.33, "bemerkung": "quis lectus suspendisse potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas" },
      { "id": 77, "kundID": 77, "mitarbeiterID": 77, "behandlungsdatum": "2021-07-17T01:24:16Z", "kundName": "Hubey", "mitarbeiterName": "Elissa", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 999.76, "rabatt": 337.81, "energie": 217.53, "frequenz": 972.04, "durchmesser": 359.44, "energiedichte": 991.71, "bemerkung": "vel est donec odio justo sollicitudin ut suscipit a feugiat et eros vestibulum ac est lacinia nisi venenatis" },
      { "id": 78, "kundID": 78, "mitarbeiterID": 78, "behandlungsdatum": "2021-03-18T19:12:51Z", "kundName": "Gerrard", "mitarbeiterName": "Lisbeth", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 860.82, "rabatt": 933.38, "energie": 369.17, "frequenz": 508.99, "durchmesser": 542.27, "energiedichte": 131.87, "bemerkung": "justo aliquam quis turpis eget elit sodales scelerisque mauris sit amet eros suspendisse accumsan tortor quis turpis sed" },
      { "id": 79, "kundID": 79, "mitarbeiterID": 79, "behandlungsdatum": "2021-03-13T18:19:06Z", "kundName": "Adham", "mitarbeiterName": "Doyle", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 178.13, "rabatt": 973.91, "energie": 382.43, "frequenz": 10.75, "durchmesser": 975.05, "energiedichte": 865.56, "bemerkung": "amet consectetuer adipiscing elit proin risus praesent lectus vestibulum quam sapien varius" },
      { "id": 80, "kundID": 80, "mitarbeiterID": 80, "behandlungsdatum": "2021-09-28T15:31:55Z", "kundName": "Muffin", "mitarbeiterName": "Odele", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 94.47, "rabatt": 356.73, "energie": 967.43, "frequenz": 276.62, "durchmesser": 236.29, "energiedichte": 368.33, "bemerkung": "id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi vulputate nonummy maecenas" },
      { "id": 81, "kundID": 81, "mitarbeiterID": 81, "behandlungsdatum": "2021-08-13T01:56:27Z", "kundName": "Gert", "mitarbeiterName": "Axe", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 915.75, "rabatt": 333.71, "energie": 827.69, "frequenz": 987.56, "durchmesser": 402.93, "energiedichte": 211.53, "bemerkung": "fermentum donec ut mauris eget massa tempor convallis nulla neque" },
      { "id": 82, "kundID": 82, "mitarbeiterID": 82, "behandlungsdatum": "2021-06-02T20:46:27Z", "kundName": "Matthias", "mitarbeiterName": "Rhetta", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 256.31, "rabatt": 398.75, "energie": 466.05, "frequenz": 507.22, "durchmesser": 245.75, "energiedichte": 690.82, "bemerkung": "eleifend donec ut dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed vestibulum" },
      { "id": 83, "kundID": 83, "mitarbeiterID": 83, "behandlungsdatum": "2021-07-25T19:50:07Z", "kundName": "Shaine", "mitarbeiterName": "Matthiew", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 218.04, "rabatt": 903.84, "energie": 223.3, "frequenz": 377.32, "durchmesser": 15.47, "energiedichte": 166.43, "bemerkung": "neque libero convallis eget eleifend luctus ultricies eu nibh quisque id justo sit amet" },
      { "id": 84, "kundID": 84, "mitarbeiterID": 84, "behandlungsdatum": "2021-02-05T12:32:31Z", "kundName": "Melisande", "mitarbeiterName": "Malvin", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 436.95, "rabatt": 486.58, "energie": 532.18, "frequenz": 899.49, "durchmesser": 692.89, "energiedichte": 365.61, "bemerkung": "vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere" },
      { "id": 85, "kundID": 85, "mitarbeiterID": 85, "behandlungsdatum": "2021-06-19T03:34:19Z", "kundName": "Luca", "mitarbeiterName": "Queenie", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 68.42, "rabatt": 750.85, "energie": 336.83, "frequenz": 491.07, "durchmesser": 674.57, "energiedichte": 28.18, "bemerkung": "sollicitudin vitae consectetuer eget rutrum at lorem integer tincidunt ante vel ipsum praesent" },
      { "id": 86, "kundID": 86, "mitarbeiterID": 86, "behandlungsdatum": "2021-08-23T06:56:37Z", "kundName": "Garwin", "mitarbeiterName": "Jaine", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 310.98, "rabatt": 1.35, "energie": 736.5, "frequenz": 992.4, "durchmesser": 611.91, "energiedichte": 321.4, "bemerkung": "vestibulum sit amet cursus id turpis integer aliquet massa id" },
      { "id": 87, "kundID": 87, "mitarbeiterID": 87, "behandlungsdatum": "2021-07-23T17:45:06Z", "kundName": "Dale", "mitarbeiterName": "Selina", "behandlung": "behandlung1", "koerperregion": "koerperregion4", "preis": 689.69, "rabatt": 778.29, "energie": 371.34, "frequenz": 755.34, "durchmesser": 429.2, "energiedichte": 721.31, "bemerkung": "ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec" },
      { "id": 88, "kundID": 88, "mitarbeiterID": 88, "behandlungsdatum": "2021-09-07T20:55:48Z", "kundName": "Akim", "mitarbeiterName": "Danell", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 393.28, "rabatt": 224.12, "energie": 611.8, "frequenz": 498.91, "durchmesser": 931.2, "energiedichte": 904.8, "bemerkung": "rhoncus sed vestibulum sit amet cursus id turpis integer aliquet massa id lobortis convallis tortor" },
      { "id": 89, "kundID": 89, "mitarbeiterID": 89, "behandlungsdatum": "2020-12-21T16:24:30Z", "kundName": "Adoree", "mitarbeiterName": "Alyse", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 116.63, "rabatt": 279.36, "energie": 199.15, "frequenz": 628.19, "durchmesser": 800.1, "energiedichte": 978.32, "bemerkung": "in hac habitasse platea dictumst morbi vestibulum velit id pretium iaculis diam erat fermentum justo nec condimentum" },
      { "id": 90, "kundID": 90, "mitarbeiterID": 90, "behandlungsdatum": "2021-07-31T22:27:04Z", "kundName": "Shara", "mitarbeiterName": "Major", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 504.74, "rabatt": 75.35, "energie": 410.98, "frequenz": 978.77, "durchmesser": 577.2, "energiedichte": 18.75, "bemerkung": "ipsum ac tellus semper interdum mauris ullamcorper purus sit amet nulla quisque arcu libero rutrum ac" },
      { "id": 91, "kundID": 91, "mitarbeiterID": 91, "behandlungsdatum": "2021-09-13T11:59:36Z", "kundName": "Fowler", "mitarbeiterName": "Adda", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 863.08, "rabatt": 988.89, "energie": 387.03, "frequenz": 932.99, "durchmesser": 295.63, "energiedichte": 730.23, "bemerkung": "congue eget semper rutrum nulla nunc purus phasellus in felis donec semper" },
      { "id": 92, "kundID": 92, "mitarbeiterID": 92, "behandlungsdatum": "2021-08-19T06:00:42Z", "kundName": "Perri", "mitarbeiterName": "Amble", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 293.14, "rabatt": 925.9, "energie": 434.84, "frequenz": 667.1, "durchmesser": 331.0, "energiedichte": 894.8, "bemerkung": "odio condimentum id luctus nec molestie sed justo pellentesque viverra pede ac diam cras pellentesque" },
      { "id": 93, "kundID": 93, "mitarbeiterID": 93, "behandlungsdatum": "2021-01-15T19:11:48Z", "kundName": "Jerry", "mitarbeiterName": "Elaine", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 47.65, "rabatt": 91.7, "energie": 918.42, "frequenz": 289.03, "durchmesser": 467.82, "energiedichte": 933.48, "bemerkung": "nunc donec quis orci eget orci vehicula condimentum curabitur in libero ut massa volutpat convallis morbi odio" },
      { "id": 94, "kundID": 94, "mitarbeiterID": 94, "behandlungsdatum": "2021-02-06T04:23:03Z", "kundName": "Rycca", "mitarbeiterName": "Hobart", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 453.92, "rabatt": 909.81, "energie": 141.66, "frequenz": 462.01, "durchmesser": 952.25, "energiedichte": 770.78, "bemerkung": "porttitor lorem id ligula suspendisse ornare consequat lectus in est risus auctor sed tristique in tempus" },
      { "id": 95, "kundID": 95, "mitarbeiterID": 95, "behandlungsdatum": "2021-03-11T18:36:05Z", "kundName": "Hyacinthe", "mitarbeiterName": "Vivyan", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 850.32, "rabatt": 44.49, "energie": 436.84, "frequenz": 314.26, "durchmesser": 929.74, "energiedichte": 222.28, "bemerkung": "pede venenatis non sodales sed tincidunt eu felis fusce posuere felis" },
      { "id": 96, "kundID": 96, "mitarbeiterID": 96, "behandlungsdatum": "2021-05-30T01:40:05Z", "kundName": "Rozalie", "mitarbeiterName": "Trip", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 199.47, "rabatt": 309.61, "energie": 238.76, "frequenz": 918.41, "durchmesser": 210.74, "energiedichte": 306.83, "bemerkung": "risus praesent lectus vestibulum quam sapien varius ut blandit non interdum in ante vestibulum ante ipsum" },
      { "id": 97, "kundID": 97, "mitarbeiterID": 97, "behandlungsdatum": "2020-12-14T03:40:56Z", "kundName": "Geraldine", "mitarbeiterName": "Norbert", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 744.42, "rabatt": 445.08, "energie": 653.87, "frequenz": 217.75, "durchmesser": 203.81, "energiedichte": 823.11, "bemerkung": "sit amet justo morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate" },
      { "id": 98, "kundID": 98, "mitarbeiterID": 98, "behandlungsdatum": "2021-11-02T22:21:34Z", "kundName": "Inglebert", "mitarbeiterName": "Jesse", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 884.25, "rabatt": 252.66, "energie": 412.63, "frequenz": 341.95, "durchmesser": 239.46, "energiedichte": 475.46, "bemerkung": "potenti nullam porttitor lacus at turpis donec posuere metus vitae ipsum aliquam non mauris morbi non lectus aliquam" },
      { "id": 99, "kundID": 99, "mitarbeiterID": 99, "behandlungsdatum": "2021-09-23T17:09:31Z", "kundName": "Nertie", "mitarbeiterName": "Dunstan", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 304.6, "rabatt": 960.25, "energie": 47.69, "frequenz": 601.06, "durchmesser": 384.41, "energiedichte": 249.86, "bemerkung": "lectus in quam fringilla rhoncus mauris enim leo rhoncus sed vestibulum sit amet cursus id" },
      { "id": 100, "kundID": 100, "mitarbeiterID": 100, "behandlungsdatum": "2021-03-20T15:14:38Z", "kundName": "Gilly", "mitarbeiterName": "Fletch", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 764.1, "rabatt": 819.6, "energie": 807.26, "frequenz": 524.17, "durchmesser": 67.18, "energiedichte": 632.98, "bemerkung": "mattis egestas metus aenean fermentum donec ut mauris eget massa tempor convallis nulla neque libero convallis eget eleifend luctus ultricies" },
      { "id": 101, "kundID": 101, "mitarbeiterID": 101, "behandlungsdatum": "2021-10-13T10:38:12Z", "kundName": "Christiane", "mitarbeiterName": "Willamina", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 66.56, "rabatt": 834.29, "energie": 943.22, "frequenz": 961.65, "durchmesser": 900.36, "energiedichte": 790.93, "bemerkung": "luctus et ultrices posuere cubilia curae duis faucibus accumsan odio" },
      { "id": 102, "kundID": 102, "mitarbeiterID": 102, "behandlungsdatum": "2021-02-22T12:55:00Z", "kundName": "Meryl", "mitarbeiterName": "Kalina", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 47.61, "rabatt": 249.83, "energie": 888.25, "frequenz": 635.17, "durchmesser": 541.5, "energiedichte": 926.44, "bemerkung": "dui vel sem sed sagittis nam congue risus semper porta volutpat quam pede lobortis ligula sit" },
      { "id": 103, "kundID": 103, "mitarbeiterID": 103, "behandlungsdatum": "2021-08-22T22:45:33Z", "kundName": "Prentiss", "mitarbeiterName": "Benton", "behandlung": "behandlung1", "koerperregion": "koerperregion4", "preis": 194.28, "rabatt": 79.08, "energie": 782.78, "frequenz": 632.81, "durchmesser": 663.37, "energiedichte": 234.91, "bemerkung": "leo odio porttitor id consequat in consequat ut nulla sed accumsan felis ut at dolor quis odio consequat varius" },
      { "id": 104, "kundID": 104, "mitarbeiterID": 104, "behandlungsdatum": "2021-08-01T15:53:01Z", "kundName": "Lottie", "mitarbeiterName": "Darcy", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 642.66, "rabatt": 66.15, "energie": 953.18, "frequenz": 243.17, "durchmesser": 640.08, "energiedichte": 654.87, "bemerkung": "quam suspendisse potenti nullam porttitor lacus at turpis donec posuere metus vitae ipsum aliquam non mauris" },
      { "id": 105, "kundID": 105, "mitarbeiterID": 105, "behandlungsdatum": "2021-05-30T23:45:49Z", "kundName": "Alfredo", "mitarbeiterName": "Vassili", "behandlung": "behandlung1", "koerperregion": "koerperregion3", "preis": 762.26, "rabatt": 803.41, "energie": 779.5, "frequenz": 403.38, "durchmesser": 727.89, "energiedichte": 600.83, "bemerkung": "nulla suspendisse potenti cras in purus eu magna vulputate luctus" },
      { "id": 106, "kundID": 106, "mitarbeiterID": 106, "behandlungsdatum": "2021-05-25T22:12:37Z", "kundName": "Gwenny", "mitarbeiterName": "Stella", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 919.39, "rabatt": 409.27, "energie": 542.8, "frequenz": 798.8, "durchmesser": 629.36, "energiedichte": 325.3, "bemerkung": "habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla ut" },
      { "id": 107, "kundID": 107, "mitarbeiterID": 107, "behandlungsdatum": "2021-07-04T19:54:06Z", "kundName": "Patricia", "mitarbeiterName": "Constantia", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 120.44, "rabatt": 380.12, "energie": 113.67, "frequenz": 12.04, "durchmesser": 950.76, "energiedichte": 987.96, "bemerkung": "montes nascetur ridiculus mus vivamus vestibulum sagittis sapien cum sociis natoque penatibus et magnis dis parturient" },
      { "id": 108, "kundID": 108, "mitarbeiterID": 108, "behandlungsdatum": "2021-03-04T16:23:22Z", "kundName": "Maxy", "mitarbeiterName": "Liz", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 486.42, "rabatt": 759.82, "energie": 189.87, "frequenz": 430.75, "durchmesser": 727.14, "energiedichte": 689.59, "bemerkung": "ornare imperdiet sapien urna pretium nisl ut volutpat sapien arcu sed augue aliquam" },
      { "id": 109, "kundID": 109, "mitarbeiterID": 109, "behandlungsdatum": "2021-02-05T12:41:45Z", "kundName": "Janetta", "mitarbeiterName": "Rodney", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 578.91, "rabatt": 762.62, "energie": 681.4, "frequenz": 800.29, "durchmesser": 23.36, "energiedichte": 367.65, "bemerkung": "vel accumsan tellus nisi eu orci mauris lacinia sapien quis libero nullam sit amet turpis" },
      { "id": 110, "kundID": 110, "mitarbeiterID": 110, "behandlungsdatum": "2021-04-10T21:17:27Z", "kundName": "Meggy", "mitarbeiterName": "Junette", "behandlung": "behandlung1", "koerperregion": "koerperregion4", "preis": 29.7, "rabatt": 827.03, "energie": 839.04, "frequenz": 913.28, "durchmesser": 75.62, "energiedichte": 515.48, "bemerkung": "in felis eu sapien cursus vestibulum proin eu mi nulla ac enim in tempor turpis nec euismod" },
      { "id": 111, "kundID": 111, "mitarbeiterID": 111, "behandlungsdatum": "2021-05-27T02:28:07Z", "kundName": "Hayley", "mitarbeiterName": "Elspeth", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 597.05, "rabatt": 889.35, "energie": 117.42, "frequenz": 584.07, "durchmesser": 88.1, "energiedichte": 720.21, "bemerkung": "in imperdiet et commodo vulputate justo in blandit ultrices enim lorem" },
      { "id": 112, "kundID": 112, "mitarbeiterID": 112, "behandlungsdatum": "2021-11-05T15:38:33Z", "kundName": "Nicoli", "mitarbeiterName": "Charley", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 902.19, "rabatt": 869.18, "energie": 315.16, "frequenz": 754.03, "durchmesser": 336.67, "energiedichte": 892.84, "bemerkung": "aenean sit amet justo morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in blandit" },
      { "id": 113, "kundID": 113, "mitarbeiterID": 113, "behandlungsdatum": "2020-11-21T15:50:32Z", "kundName": "Sophi", "mitarbeiterName": "Mordecai", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 714.16, "rabatt": 523.58, "energie": 318.36, "frequenz": 453.62, "durchmesser": 29.17, "energiedichte": 884.39, "bemerkung": "lacinia eget tincidunt eget tempus vel pede morbi porttitor lorem id" },
      { "id": 114, "kundID": 114, "mitarbeiterID": 114, "behandlungsdatum": "2021-06-28T10:55:31Z", "kundName": "Janey", "mitarbeiterName": "Pamelina", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 438.15, "rabatt": 264.72, "energie": 346.49, "frequenz": 18.84, "durchmesser": 97.4, "energiedichte": 661.07, "bemerkung": "mi integer ac neque duis bibendum morbi non quam nec dui luctus rutrum nulla tellus in sagittis dui vel" },
      { "id": 115, "kundID": 115, "mitarbeiterID": 115, "behandlungsdatum": "2021-05-20T20:21:53Z", "kundName": "Shaine", "mitarbeiterName": "Lonna", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 241.52, "rabatt": 943.35, "energie": 372.41, "frequenz": 580.11, "durchmesser": 359.12, "energiedichte": 187.87, "bemerkung": "iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate vitae nisl aenean" },
      { "id": 116, "kundID": 116, "mitarbeiterID": 116, "behandlungsdatum": "2021-11-09T06:10:25Z", "kundName": "Wynn", "mitarbeiterName": "Darryl", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 81.85, "rabatt": 600.58, "energie": 985.47, "frequenz": 412.19, "durchmesser": 217.73, "energiedichte": 222.21, "bemerkung": "leo maecenas pulvinar lobortis est phasellus sit amet erat nulla tempus vivamus" },
      { "id": 117, "kundID": 117, "mitarbeiterID": 117, "behandlungsdatum": "2021-02-24T05:10:28Z", "kundName": "Randa", "mitarbeiterName": "Arie", "behandlung": "behandlung1", "koerperregion": "koerperregion4", "preis": 227.97, "rabatt": 418.53, "energie": 410.64, "frequenz": 833.59, "durchmesser": 105.57, "energiedichte": 851.26, "bemerkung": "sapien iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate vitae nisl aenean lectus pellentesque" },
      { "id": 118, "kundID": 118, "mitarbeiterID": 118, "behandlungsdatum": "2021-11-15T09:31:54Z", "kundName": "Rebe", "mitarbeiterName": "Bobette", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 904.07, "rabatt": 599.34, "energie": 545.96, "frequenz": 615.69, "durchmesser": 628.48, "energiedichte": 695.66, "bemerkung": "dapibus dolor vel est donec odio justo sollicitudin ut suscipit a feugiat et eros vestibulum ac est lacinia nisi venenatis" },
      { "id": 119, "kundID": 119, "mitarbeiterID": 119, "behandlungsdatum": "2021-03-01T04:35:32Z", "kundName": "Lanie", "mitarbeiterName": "Glennis", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 228.16, "rabatt": 403.27, "energie": 536.08, "frequenz": 619.32, "durchmesser": 779.09, "energiedichte": 746.44, "bemerkung": "sollicitudin mi sit amet lobortis sapien sapien non mi integer ac neque duis bibendum morbi non" },
      { "id": 120, "kundID": 120, "mitarbeiterID": 120, "behandlungsdatum": "2021-02-16T09:57:19Z", "kundName": "Dulce", "mitarbeiterName": "Jonis", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 352.76, "rabatt": 468.72, "energie": 629.72, "frequenz": 957.03, "durchmesser": 681.32, "energiedichte": 530.04, "bemerkung": "pretium iaculis justo in hac habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla" },
      { "id": 121, "kundID": 121, "mitarbeiterID": 121, "behandlungsdatum": "2020-12-13T08:57:30Z", "kundName": "Phillipe", "mitarbeiterName": "Ellsworth", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 846.69, "rabatt": 820.56, "energie": 308.86, "frequenz": 383.77, "durchmesser": 964.82, "energiedichte": 320.93, "bemerkung": "fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus" },
      { "id": 122, "kundID": 122, "mitarbeiterID": 122, "behandlungsdatum": "2021-09-06T00:14:49Z", "kundName": "Corri", "mitarbeiterName": "Neils", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 811.34, "rabatt": 938.46, "energie": 839.67, "frequenz": 958.31, "durchmesser": 971.89, "energiedichte": 628.07, "bemerkung": "tincidunt ante vel ipsum praesent blandit lacinia erat vestibulum sed magna at nunc commodo placerat" },
      { "id": 123, "kundID": 123, "mitarbeiterID": 123, "behandlungsdatum": "2021-08-24T09:12:07Z", "kundName": "Lotti", "mitarbeiterName": "Odo", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 548.33, "rabatt": 757.12, "energie": 316.38, "frequenz": 479.91, "durchmesser": 943.0, "energiedichte": 106.54, "bemerkung": "ullamcorper purus sit amet nulla quisque arcu libero rutrum ac lobortis vel dapibus at diam nam tristique" },
      { "id": 124, "kundID": 124, "mitarbeiterID": 124, "behandlungsdatum": "2021-11-05T07:47:29Z", "kundName": "Elyn", "mitarbeiterName": "Martelle", "behandlung": "behandlung2", "koerperregion": "koerperregion2", "preis": 858.29, "rabatt": 638.74, "energie": 632.99, "frequenz": 103.22, "durchmesser": 273.72, "energiedichte": 62.09, "bemerkung": "orci eget orci vehicula condimentum curabitur in libero ut massa volutpat" },
      { "id": 125, "kundID": 125, "mitarbeiterID": 125, "behandlungsdatum": "2020-11-28T08:22:49Z", "kundName": "Ninnette", "mitarbeiterName": "Gawain", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 22.98, "rabatt": 788.32, "energie": 456.09, "frequenz": 3.47, "durchmesser": 951.73, "energiedichte": 770.36, "bemerkung": "tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi" },
      { "id": 126, "kundID": 126, "mitarbeiterID": 126, "behandlungsdatum": "2021-10-10T04:38:19Z", "kundName": "Torrin", "mitarbeiterName": "Hewett", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 546.02, "rabatt": 537.67, "energie": 459.8, "frequenz": 779.97, "durchmesser": 284.36, "energiedichte": 470.32, "bemerkung": "leo rhoncus sed vestibulum sit amet cursus id turpis integer aliquet massa id" },
      { "id": 127, "kundID": 127, "mitarbeiterID": 127, "behandlungsdatum": "2021-01-23T05:32:27Z", "kundName": "Joy", "mitarbeiterName": "Rossie", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 106.16, "rabatt": 837.78, "energie": 991.78, "frequenz": 537.69, "durchmesser": 664.63, "energiedichte": 919.27, "bemerkung": "ac diam cras pellentesque volutpat dui maecenas tristique est et tempus semper est quam pharetra magna ac consequat metus" },
      { "id": 128, "kundID": 128, "mitarbeiterID": 128, "behandlungsdatum": "2021-08-04T23:53:42Z", "kundName": "Gannie", "mitarbeiterName": "Bartel", "behandlung": "behandlung1", "koerperregion": "koerperregion4", "preis": 355.24, "rabatt": 442.27, "energie": 709.72, "frequenz": 317.98, "durchmesser": 576.63, "energiedichte": 996.58, "bemerkung": "mollis molestie lorem quisque ut erat curabitur gravida nisi at nibh in hac habitasse" },
      { "id": 129, "kundID": 129, "mitarbeiterID": 129, "behandlungsdatum": "2021-10-29T07:54:45Z", "kundName": "Mohandis", "mitarbeiterName": "Kelley", "behandlung": "behandlung4", "koerperregion": "koerperregion3", "preis": 890.03, "rabatt": 235.88, "energie": 158.13, "frequenz": 888.76, "durchmesser": 435.96, "energiedichte": 93.39, "bemerkung": "risus dapibus augue vel accumsan tellus nisi eu orci mauris lacinia sapien" },
      { "id": 130, "kundID": 130, "mitarbeiterID": 130, "behandlungsdatum": "2021-07-31T22:04:15Z", "kundName": "Samuel", "mitarbeiterName": "Wain", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 981.88, "rabatt": 410.99, "energie": 948.49, "frequenz": 175.01, "durchmesser": 196.21, "energiedichte": 785.39, "bemerkung": "id luctus nec molestie sed justo pellentesque viverra pede ac diam" },
      { "id": 131, "kundID": 131, "mitarbeiterID": 131, "behandlungsdatum": "2021-09-06T08:43:37Z", "kundName": "Jonie", "mitarbeiterName": "Emerson", "behandlung": "behandlung3", "koerperregion": "koerperregion2", "preis": 913.14, "rabatt": 511.94, "energie": 93.87, "frequenz": 114.97, "durchmesser": 299.82, "energiedichte": 149.66, "bemerkung": "pede venenatis non sodales sed tincidunt eu felis fusce posuere felis sed lacus morbi sem mauris laoreet ut" },
      { "id": 132, "kundID": 132, "mitarbeiterID": 132, "behandlungsdatum": "2021-04-25T23:55:59Z", "kundName": "Jed", "mitarbeiterName": "Patty", "behandlung": "behandlung3", "koerperregion": "koerperregion2", "preis": 332.89, "rabatt": 32.74, "energie": 491.1, "frequenz": 51.74, "durchmesser": 285.92, "energiedichte": 418.09, "bemerkung": "sit amet erat nulla tempus vivamus in felis eu sapien cursus" },
      { "id": 133, "kundID": 133, "mitarbeiterID": 133, "behandlungsdatum": "2020-12-31T11:30:45Z", "kundName": "Crawford", "mitarbeiterName": "Ward", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 363.52, "rabatt": 495.39, "energie": 691.57, "frequenz": 472.55, "durchmesser": 933.1, "energiedichte": 647.57, "bemerkung": "aenean auctor gravida sem praesent id massa id nisl venenatis lacinia aenean sit amet justo morbi" },
      { "id": 134, "kundID": 134, "mitarbeiterID": 134, "behandlungsdatum": "2021-07-22T10:34:56Z", "kundName": "Taber", "mitarbeiterName": "Samaria", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 502.63, "rabatt": 588.62, "energie": 244.05, "frequenz": 171.04, "durchmesser": 295.84, "energiedichte": 440.48, "bemerkung": "phasellus id sapien in sapien iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate vitae nisl aenean lectus pellentesque" },
      { "id": 135, "kundID": 135, "mitarbeiterID": 135, "behandlungsdatum": "2021-05-07T15:57:20Z", "kundName": "Dan", "mitarbeiterName": "Darb", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 447.51, "rabatt": 356.39, "energie": 422.17, "frequenz": 135.38, "durchmesser": 920.81, "energiedichte": 659.32, "bemerkung": "maecenas tristique est et tempus semper est quam pharetra magna ac consequat metus sapien ut nunc vestibulum ante ipsum" },
      { "id": 136, "kundID": 136, "mitarbeiterID": 136, "behandlungsdatum": "2021-05-19T07:31:50Z", "kundName": "Stella", "mitarbeiterName": "Lorrayne", "behandlung": "behandlung2", "koerperregion": "koerperregion1", "preis": 438.41, "rabatt": 630.16, "energie": 737.63, "frequenz": 901.35, "durchmesser": 629.84, "energiedichte": 696.68, "bemerkung": "in faucibus orci luctus et ultrices posuere cubilia curae mauris viverra diam vitae" },
      { "id": 137, "kundID": 137, "mitarbeiterID": 137, "behandlungsdatum": "2021-07-16T23:02:56Z", "kundName": "Corrine", "mitarbeiterName": "Maryjane", "behandlung": "behandlung1", "koerperregion": "koerperregion3", "preis": 435.86, "rabatt": 949.74, "energie": 873.73, "frequenz": 233.29, "durchmesser": 963.17, "energiedichte": 332.61, "bemerkung": "elementum nullam varius nulla facilisi cras non velit nec nisi vulputate nonummy maecenas tincidunt lacus at velit vivamus vel" },
      { "id": 138, "kundID": 138, "mitarbeiterID": 138, "behandlungsdatum": "2021-01-29T00:27:13Z", "kundName": "Myrtia", "mitarbeiterName": "Joela", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 22.43, "rabatt": 401.1, "energie": 735.95, "frequenz": 783.89, "durchmesser": 153.07, "energiedichte": 988.09, "bemerkung": "pede lobortis ligula sit amet eleifend pede libero quis orci nullam" },
      { "id": 139, "kundID": 139, "mitarbeiterID": 139, "behandlungsdatum": "2021-11-04T03:47:36Z", "kundName": "Yoshiko", "mitarbeiterName": "Ronnie", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 810.93, "rabatt": 82.85, "energie": 431.72, "frequenz": 152.61, "durchmesser": 374.24, "energiedichte": 954.72, "bemerkung": "et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor sit amet consectetuer adipiscing elit proin interdum mauris" },
      { "id": 140, "kundID": 140, "mitarbeiterID": 140, "behandlungsdatum": "2021-10-30T08:19:41Z", "kundName": "Willey", "mitarbeiterName": "Curtice", "behandlung": "behandlung1", "koerperregion": "koerperregion1", "preis": 320.13, "rabatt": 115.2, "energie": 158.83, "frequenz": 882.97, "durchmesser": 500.09, "energiedichte": 993.09, "bemerkung": "vel sem sed sagittis nam congue risus semper porta volutpat quam pede" },
      { "id": 141, "kundID": 141, "mitarbeiterID": 141, "behandlungsdatum": "2021-05-04T16:14:07Z", "kundName": "Alyss", "mitarbeiterName": "Luelle", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 274.9, "rabatt": 311.67, "energie": 124.72, "frequenz": 669.1, "durchmesser": 748.73, "energiedichte": 783.45, "bemerkung": "aliquet maecenas leo odio condimentum id luctus nec molestie sed justo" },
      { "id": 142, "kundID": 142, "mitarbeiterID": 142, "behandlungsdatum": "2021-08-21T12:52:40Z", "kundName": "Olivette", "mitarbeiterName": "Elnora", "behandlung": "behandlung1", "koerperregion": "koerperregion1", "preis": 513.11, "rabatt": 705.04, "energie": 13.15, "frequenz": 917.96, "durchmesser": 64.89, "energiedichte": 795.49, "bemerkung": "bibendum felis sed interdum venenatis turpis enim blandit mi in porttitor pede justo eu massa donec dapibus duis at" },
      { "id": 143, "kundID": 143, "mitarbeiterID": 143, "behandlungsdatum": "2021-06-11T18:29:41Z", "kundName": "Tiebout", "mitarbeiterName": "Maurie", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 511.18, "rabatt": 72.05, "energie": 440.87, "frequenz": 54.61, "durchmesser": 943.89, "energiedichte": 695.9, "bemerkung": "suscipit a feugiat et eros vestibulum ac est lacinia nisi venenatis" },
      { "id": 144, "kundID": 144, "mitarbeiterID": 144, "behandlungsdatum": "2021-10-13T22:23:40Z", "kundName": "Alvera", "mitarbeiterName": "Blair", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 84.14, "rabatt": 710.62, "energie": 580.21, "frequenz": 367.34, "durchmesser": 789.44, "energiedichte": 239.75, "bemerkung": "in quis justo maecenas rhoncus aliquam lacus morbi quis tortor id nulla ultrices aliquet maecenas" },
      { "id": 145, "kundID": 145, "mitarbeiterID": 145, "behandlungsdatum": "2021-07-10T14:14:27Z", "kundName": "Dewain", "mitarbeiterName": "Nicola", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 884.04, "rabatt": 138.89, "energie": 231.31, "frequenz": 735.26, "durchmesser": 745.69, "energiedichte": 802.95, "bemerkung": "vestibulum sit amet cursus id turpis integer aliquet massa id lobortis convallis tortor" },
      { "id": 146, "kundID": 146, "mitarbeiterID": 146, "behandlungsdatum": "2020-12-03T16:22:45Z", "kundName": "Cordi", "mitarbeiterName": "Friederike", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 144.15, "rabatt": 532.88, "energie": 775.0, "frequenz": 824.25, "durchmesser": 951.93, "energiedichte": 264.33, "bemerkung": "non quam nec dui luctus rutrum nulla tellus in sagittis dui vel nisl duis ac nibh" },
      { "id": 147, "kundID": 147, "mitarbeiterID": 147, "behandlungsdatum": "2021-09-23T14:08:13Z", "kundName": "Brittni", "mitarbeiterName": "Nomi", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 904.7, "rabatt": 837.05, "energie": 902.2, "frequenz": 436.08, "durchmesser": 437.98, "energiedichte": 934.59, "bemerkung": "cursus urna ut tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi cras non velit" },
      { "id": 148, "kundID": 148, "mitarbeiterID": 148, "behandlungsdatum": "2021-10-16T12:05:51Z", "kundName": "Nancie", "mitarbeiterName": "Mufinella", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 251.3, "rabatt": 513.76, "energie": 336.56, "frequenz": 370.15, "durchmesser": 242.66, "energiedichte": 125.63, "bemerkung": "imperdiet et commodo vulputate justo in blandit ultrices enim lorem ipsum dolor sit amet consectetuer adipiscing" },
      { "id": 149, "kundID": 149, "mitarbeiterID": 149, "behandlungsdatum": "2021-10-15T03:47:18Z", "kundName": "Myrna", "mitarbeiterName": "Frankie", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 684.0, "rabatt": 781.65, "energie": 481.82, "frequenz": 25.71, "durchmesser": 272.32, "energiedichte": 298.2, "bemerkung": "diam vitae quam suspendisse potenti nullam porttitor lacus at turpis donec posuere metus vitae ipsum aliquam non mauris" },
      { "id": 150, "kundID": 150, "mitarbeiterID": 150, "behandlungsdatum": "2021-05-26T16:59:59Z", "kundName": "Sherie", "mitarbeiterName": "Roby", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 864.16, "rabatt": 420.24, "energie": 346.75, "frequenz": 27.54, "durchmesser": 385.19, "energiedichte": 410.62, "bemerkung": "porta volutpat erat quisque erat eros viverra eget congue eget semper rutrum nulla nunc purus phasellus in felis" },
      { "id": 151, "kundID": 151, "mitarbeiterID": 151, "behandlungsdatum": "2021-06-12T21:31:58Z", "kundName": "Tootsie", "mitarbeiterName": "Clayborne", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 354.67, "rabatt": 686.11, "energie": 352.32, "frequenz": 433.76, "durchmesser": 358.2, "energiedichte": 817.62, "bemerkung": "vivamus vel nulla eget eros elementum pellentesque quisque porta volutpat erat" },
      { "id": 152, "kundID": 152, "mitarbeiterID": 152, "behandlungsdatum": "2021-01-18T14:28:55Z", "kundName": "Valentine", "mitarbeiterName": "Elia", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 449.37, "rabatt": 286.5, "energie": 869.85, "frequenz": 425.01, "durchmesser": 506.23, "energiedichte": 722.68, "bemerkung": "eu felis fusce posuere felis sed lacus morbi sem mauris laoreet ut rhoncus aliquet pulvinar" },
      { "id": 153, "kundID": 153, "mitarbeiterID": 153, "behandlungsdatum": "2021-04-08T07:48:34Z", "kundName": "Cristy", "mitarbeiterName": "Anneliese", "behandlung": "behandlung1", "koerperregion": "koerperregion1", "preis": 455.21, "rabatt": 295.55, "energie": 884.89, "frequenz": 238.06, "durchmesser": 350.74, "energiedichte": 709.57, "bemerkung": "sapien non mi integer ac neque duis bibendum morbi non quam nec dui luctus rutrum nulla tellus" },
      { "id": 154, "kundID": 154, "mitarbeiterID": 154, "behandlungsdatum": "2021-03-09T02:26:16Z", "kundName": "Nico", "mitarbeiterName": "Loralie", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 770.77, "rabatt": 476.52, "energie": 391.45, "frequenz": 471.21, "durchmesser": 976.05, "energiedichte": 566.15, "bemerkung": "eros vestibulum ac est lacinia nisi venenatis tristique fusce congue diam id ornare imperdiet sapien" },
      { "id": 155, "kundID": 155, "mitarbeiterID": 155, "behandlungsdatum": "2021-06-08T14:18:45Z", "kundName": "Ruby", "mitarbeiterName": "Aurelea", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 878.01, "rabatt": 69.85, "energie": 186.38, "frequenz": 48.56, "durchmesser": 496.65, "energiedichte": 846.89, "bemerkung": "luctus et ultrices posuere cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat dui nec nisi volutpat eleifend donec" },
      { "id": 156, "kundID": 156, "mitarbeiterID": 156, "behandlungsdatum": "2021-03-08T15:47:17Z", "kundName": "Mannie", "mitarbeiterName": "Margot", "behandlung": "behandlung2", "koerperregion": "koerperregion1", "preis": 14.98, "rabatt": 499.81, "energie": 797.33, "frequenz": 619.73, "durchmesser": 793.31, "energiedichte": 864.87, "bemerkung": "neque libero convallis eget eleifend luctus ultricies eu nibh quisque id" },
      { "id": 157, "kundID": 157, "mitarbeiterID": 157, "behandlungsdatum": "2021-03-27T09:09:52Z", "kundName": "Meaghan", "mitarbeiterName": "Remy", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 464.83, "rabatt": 939.27, "energie": 8.38, "frequenz": 631.08, "durchmesser": 679.85, "energiedichte": 553.13, "bemerkung": "orci mauris lacinia sapien quis libero nullam sit amet turpis elementum ligula vehicula consequat morbi a ipsum" },
      { "id": 158, "kundID": 158, "mitarbeiterID": 158, "behandlungsdatum": "2021-09-21T01:41:06Z", "kundName": "Ninnetta", "mitarbeiterName": "Ray", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 284.14, "rabatt": 922.44, "energie": 910.45, "frequenz": 25.82, "durchmesser": 684.66, "energiedichte": 440.84, "bemerkung": "dolor morbi vel lectus in quam fringilla rhoncus mauris enim" },
      { "id": 159, "kundID": 159, "mitarbeiterID": 159, "behandlungsdatum": "2021-10-25T06:21:37Z", "kundName": "Phylys", "mitarbeiterName": "Urbain", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 923.95, "rabatt": 911.46, "energie": 770.77, "frequenz": 151.67, "durchmesser": 148.7, "energiedichte": 935.81, "bemerkung": "suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis mattis egestas metus aenean fermentum donec ut mauris eget" },
      { "id": 160, "kundID": 160, "mitarbeiterID": 160, "behandlungsdatum": "2021-01-24T10:41:43Z", "kundName": "Ryan", "mitarbeiterName": "Maitilde", "behandlung": "behandlung3", "koerperregion": "koerperregion1", "preis": 551.1, "rabatt": 427.23, "energie": 320.93, "frequenz": 172.75, "durchmesser": 196.17, "energiedichte": 618.07, "bemerkung": "sapien ut nunc vestibulum ante ipsum primis in faucibus orci" },
      { "id": 161, "kundID": 161, "mitarbeiterID": 161, "behandlungsdatum": "2021-11-06T01:02:02Z", "kundName": "Keven", "mitarbeiterName": "Lishe", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 446.98, "rabatt": 525.92, "energie": 842.48, "frequenz": 483.94, "durchmesser": 487.71, "energiedichte": 853.76, "bemerkung": "est lacinia nisi venenatis tristique fusce congue diam id ornare imperdiet sapien urna pretium nisl" },
      { "id": 162, "kundID": 162, "mitarbeiterID": 162, "behandlungsdatum": "2021-08-23T15:10:00Z", "kundName": "Binnie", "mitarbeiterName": "Jared", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 283.3, "rabatt": 865.92, "energie": 8.71, "frequenz": 162.88, "durchmesser": 360.25, "energiedichte": 463.83, "bemerkung": "eu nibh quisque id justo sit amet sapien dignissim vestibulum vestibulum ante ipsum primis in faucibus orci luctus et" },
      { "id": 163, "kundID": 163, "mitarbeiterID": 163, "behandlungsdatum": "2021-10-15T01:01:38Z", "kundName": "Robby", "mitarbeiterName": "Emery", "behandlung": "behandlung2", "koerperregion": "koerperregion1", "preis": 349.65, "rabatt": 409.08, "energie": 356.29, "frequenz": 653.31, "durchmesser": 350.0, "energiedichte": 207.38, "bemerkung": "libero rutrum ac lobortis vel dapibus at diam nam tristique" },
      { "id": 164, "kundID": 164, "mitarbeiterID": 164, "behandlungsdatum": "2021-04-29T14:32:40Z", "kundName": "Brockie", "mitarbeiterName": "Gabriel", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 314.97, "rabatt": 702.3, "energie": 865.7, "frequenz": 88.83, "durchmesser": 773.8, "energiedichte": 150.67, "bemerkung": "dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed vestibulum sit amet cursus id turpis integer" },
      { "id": 165, "kundID": 165, "mitarbeiterID": 165, "behandlungsdatum": "2021-03-14T16:57:56Z", "kundName": "Amalia", "mitarbeiterName": "Di", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 623.18, "rabatt": 87.11, "energie": 195.19, "frequenz": 111.06, "durchmesser": 741.7, "energiedichte": 765.37, "bemerkung": "primis in faucibus orci luctus et ultrices posuere cubilia curae nulla dapibus dolor vel est donec odio" },
      { "id": 166, "kundID": 166, "mitarbeiterID": 166, "behandlungsdatum": "2021-10-09T17:42:33Z", "kundName": "Malva", "mitarbeiterName": "Johny", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 465.99, "rabatt": 877.57, "energie": 763.88, "frequenz": 251.99, "durchmesser": 112.6, "energiedichte": 848.92, "bemerkung": "integer ac leo pellentesque ultrices mattis odio donec vitae nisi nam ultrices libero non mattis pulvinar nulla" },
      { "id": 167, "kundID": 167, "mitarbeiterID": 167, "behandlungsdatum": "2021-05-06T15:57:18Z", "kundName": "Jessie", "mitarbeiterName": "Elfreda", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 499.15, "rabatt": 35.0, "energie": 129.47, "frequenz": 251.59, "durchmesser": 606.49, "energiedichte": 420.48, "bemerkung": "elementum in hac habitasse platea dictumst morbi vestibulum velit id pretium iaculis diam" },
      { "id": 168, "kundID": 168, "mitarbeiterID": 168, "behandlungsdatum": "2021-09-13T22:50:04Z", "kundName": "Chickie", "mitarbeiterName": "Marga", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 194.34, "rabatt": 763.57, "energie": 474.93, "frequenz": 197.44, "durchmesser": 36.51, "energiedichte": 617.04, "bemerkung": "orci nullam molestie nibh in lectus pellentesque at nulla suspendisse potenti cras in purus eu magna" },
      { "id": 169, "kundID": 169, "mitarbeiterID": 169, "behandlungsdatum": "2021-07-11T22:40:47Z", "kundName": "Allix", "mitarbeiterName": "Palm", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 922.98, "rabatt": 959.3, "energie": 154.47, "frequenz": 769.1, "durchmesser": 969.22, "energiedichte": 986.71, "bemerkung": "auctor gravida sem praesent id massa id nisl venenatis lacinia aenean sit amet justo morbi ut odio cras" },
      { "id": 170, "kundID": 170, "mitarbeiterID": 170, "behandlungsdatum": "2021-10-09T15:12:59Z", "kundName": "Homere", "mitarbeiterName": "Darline", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 469.72, "rabatt": 642.25, "energie": 733.74, "frequenz": 818.08, "durchmesser": 577.28, "energiedichte": 564.48, "bemerkung": "at velit vivamus vel nulla eget eros elementum pellentesque quisque porta volutpat" },
      { "id": 171, "kundID": 171, "mitarbeiterID": 171, "behandlungsdatum": "2020-12-16T16:38:37Z", "kundName": "Rip", "mitarbeiterName": "Sybilla", "behandlung": "behandlung3", "koerperregion": "koerperregion3", "preis": 684.39, "rabatt": 853.95, "energie": 50.97, "frequenz": 597.61, "durchmesser": 554.27, "energiedichte": 963.22, "bemerkung": "praesent id massa id nisl venenatis lacinia aenean sit amet justo morbi ut" },
      { "id": 172, "kundID": 172, "mitarbeiterID": 172, "behandlungsdatum": "2021-05-13T13:40:26Z", "kundName": "Wash", "mitarbeiterName": "Katlin", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 954.13, "rabatt": 610.97, "energie": 103.3, "frequenz": 613.1, "durchmesser": 618.71, "energiedichte": 53.42, "bemerkung": "id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi" },
      { "id": 173, "kundID": 173, "mitarbeiterID": 173, "behandlungsdatum": "2021-01-01T20:19:19Z", "kundName": "Mord", "mitarbeiterName": "Torr", "behandlung": "behandlung2", "koerperregion": "koerperregion1", "preis": 348.46, "rabatt": 376.8, "energie": 760.55, "frequenz": 432.95, "durchmesser": 22.71, "energiedichte": 930.23, "bemerkung": "curae nulla dapibus dolor vel est donec odio justo sollicitudin ut suscipit a feugiat et eros vestibulum ac" },
      { "id": 174, "kundID": 174, "mitarbeiterID": 174, "behandlungsdatum": "2021-09-15T04:57:35Z", "kundName": "Carlin", "mitarbeiterName": "Barbra", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 340.5, "rabatt": 167.25, "energie": 187.63, "frequenz": 115.16, "durchmesser": 119.2, "energiedichte": 512.47, "bemerkung": "rhoncus dui vel sem sed sagittis nam congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend pede" },
      { "id": 175, "kundID": 175, "mitarbeiterID": 175, "behandlungsdatum": "2021-06-20T03:12:18Z", "kundName": "Mamie", "mitarbeiterName": "Holli", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 358.72, "rabatt": 623.01, "energie": 865.05, "frequenz": 199.36, "durchmesser": 23.53, "energiedichte": 953.05, "bemerkung": "turpis nec euismod scelerisque quam turpis adipiscing lorem vitae mattis nibh ligula nec sem" },
      { "id": 176, "kundID": 176, "mitarbeiterID": 176, "behandlungsdatum": "2021-01-09T03:51:48Z", "kundName": "Billie", "mitarbeiterName": "Drusi", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 852.45, "rabatt": 15.71, "energie": 551.13, "frequenz": 688.46, "durchmesser": 63.52, "energiedichte": 515.33, "bemerkung": "metus sapien ut nunc vestibulum ante ipsum primis in faucibus" },
      { "id": 177, "kundID": 177, "mitarbeiterID": 177, "behandlungsdatum": "2021-09-09T19:16:04Z", "kundName": "Norine", "mitarbeiterName": "Myra", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 30.0, "rabatt": 866.22, "energie": 441.13, "frequenz": 692.52, "durchmesser": 969.93, "energiedichte": 644.63, "bemerkung": "praesent blandit lacinia erat vestibulum sed magna at nunc commodo placerat praesent blandit nam nulla integer pede" },
      { "id": 178, "kundID": 178, "mitarbeiterID": 178, "behandlungsdatum": "2021-06-02T16:46:37Z", "kundName": "Carmelia", "mitarbeiterName": "Sven", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 581.39, "rabatt": 182.86, "energie": 274.06, "frequenz": 957.16, "durchmesser": 781.36, "energiedichte": 158.24, "bemerkung": "dolor morbi vel lectus in quam fringilla rhoncus mauris enim leo rhoncus sed vestibulum sit amet cursus id turpis" },
      { "id": 179, "kundID": 179, "mitarbeiterID": 179, "behandlungsdatum": "2021-03-14T08:26:41Z", "kundName": "Giles", "mitarbeiterName": "Kenon", "behandlung": "behandlung1", "koerperregion": "koerperregion3", "preis": 598.64, "rabatt": 392.27, "energie": 224.34, "frequenz": 687.04, "durchmesser": 822.6, "energiedichte": 944.36, "bemerkung": "suspendisse potenti in eleifend quam a odio in hac habitasse platea dictumst maecenas ut massa" },
      { "id": 180, "kundID": 180, "mitarbeiterID": 180, "behandlungsdatum": "2021-02-16T07:28:16Z", "kundName": "Jackquelin", "mitarbeiterName": "Erinn", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 642.84, "rabatt": 919.16, "energie": 394.09, "frequenz": 592.32, "durchmesser": 375.93, "energiedichte": 486.01, "bemerkung": "magna ac consequat metus sapien ut nunc vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae" },
      { "id": 181, "kundID": 181, "mitarbeiterID": 181, "behandlungsdatum": "2021-01-10T23:53:07Z", "kundName": "Reynard", "mitarbeiterName": "Fitz", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 937.58, "rabatt": 601.43, "energie": 283.13, "frequenz": 370.74, "durchmesser": 636.69, "energiedichte": 282.52, "bemerkung": "posuere cubilia curae duis faucibus accumsan odio curabitur convallis duis consequat" },
      { "id": 182, "kundID": 182, "mitarbeiterID": 182, "behandlungsdatum": "2020-12-10T23:35:30Z", "kundName": "Rhoda", "mitarbeiterName": "Daniella", "behandlung": "behandlung2", "koerperregion": "koerperregion5", "preis": 978.25, "rabatt": 840.26, "energie": 968.02, "frequenz": 15.58, "durchmesser": 785.95, "energiedichte": 518.98, "bemerkung": "tellus in sagittis dui vel nisl duis ac nibh fusce" },
      { "id": 183, "kundID": 183, "mitarbeiterID": 183, "behandlungsdatum": "2021-07-07T13:16:16Z", "kundName": "Berti", "mitarbeiterName": "Noella", "behandlung": "behandlung1", "koerperregion": "koerperregion5", "preis": 967.5, "rabatt": 546.76, "energie": 163.13, "frequenz": 5.5, "durchmesser": 335.44, "energiedichte": 290.72, "bemerkung": "venenatis turpis enim blandit mi in porttitor pede justo eu massa donec" },
      { "id": 184, "kundID": 184, "mitarbeiterID": 184, "behandlungsdatum": "2021-09-28T07:26:10Z", "kundName": "Yance", "mitarbeiterName": "Frederich", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 285.88, "rabatt": 325.69, "energie": 751.24, "frequenz": 990.93, "durchmesser": 203.7, "energiedichte": 79.19, "bemerkung": "nulla justo aliquam quis turpis eget elit sodales scelerisque mauris sit amet eros suspendisse accumsan tortor" },
      { "id": 185, "kundID": 185, "mitarbeiterID": 185, "behandlungsdatum": "2021-09-22T22:43:58Z", "kundName": "Jerry", "mitarbeiterName": "Thedric", "behandlung": "behandlung1", "koerperregion": "koerperregion3", "preis": 369.96, "rabatt": 561.32, "energie": 945.33, "frequenz": 833.04, "durchmesser": 920.9, "energiedichte": 92.88, "bemerkung": "mattis pulvinar nulla pede ullamcorper augue a suscipit nulla elit ac nulla sed vel" },
      { "id": 186, "kundID": 186, "mitarbeiterID": 186, "behandlungsdatum": "2021-04-26T20:33:35Z", "kundName": "Fred", "mitarbeiterName": "Tammy", "behandlung": "behandlung1", "koerperregion": "koerperregion4", "preis": 426.08, "rabatt": 667.02, "energie": 988.02, "frequenz": 591.51, "durchmesser": 28.21, "energiedichte": 527.65, "bemerkung": "ut tellus nulla ut erat id mauris vulputate elementum nullam varius nulla facilisi cras non velit nec nisi vulputate" },
      { "id": 187, "kundID": 187, "mitarbeiterID": 187, "behandlungsdatum": "2021-08-20T23:55:12Z", "kundName": "Rivkah", "mitarbeiterName": "Horatia", "behandlung": "behandlung4", "koerperregion": "koerperregion4", "preis": 217.6, "rabatt": 489.33, "energie": 257.33, "frequenz": 682.28, "durchmesser": 157.45, "energiedichte": 939.13, "bemerkung": "phasellus sit amet erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin eu mi" },
      { "id": 188, "kundID": 188, "mitarbeiterID": 188, "behandlungsdatum": "2021-05-20T17:06:54Z", "kundName": "Nixie", "mitarbeiterName": "Brunhilde", "behandlung": "behandlung3", "koerperregion": "koerperregion4", "preis": 366.77, "rabatt": 568.27, "energie": 7.59, "frequenz": 928.78, "durchmesser": 919.6, "energiedichte": 998.52, "bemerkung": "posuere cubilia curae donec pharetra magna vestibulum aliquet ultrices erat tortor" },
      { "id": 189, "kundID": 189, "mitarbeiterID": 189, "behandlungsdatum": "2021-06-24T05:52:21Z", "kundName": "Kristin", "mitarbeiterName": "Maxine", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 615.41, "rabatt": 62.78, "energie": 815.8, "frequenz": 437.63, "durchmesser": 855.55, "energiedichte": 464.36, "bemerkung": "ultrices posuere cubilia curae mauris viverra diam vitae quam suspendisse potenti nullam porttitor lacus" },
      { "id": 190, "kundID": 190, "mitarbeiterID": 190, "behandlungsdatum": "2021-11-09T17:26:32Z", "kundName": "Ham", "mitarbeiterName": "Rickey", "behandlung": "behandlung3", "koerperregion": "koerperregion5", "preis": 36.42, "rabatt": 778.77, "energie": 949.15, "frequenz": 256.38, "durchmesser": 804.62, "energiedichte": 783.16, "bemerkung": "integer pede justo lacinia eget tincidunt eget tempus vel pede morbi porttitor" },
      { "id": 191, "kundID": 191, "mitarbeiterID": 191, "behandlungsdatum": "2021-04-05T07:33:26Z", "kundName": "Alf", "mitarbeiterName": "Tasha", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 263.66, "rabatt": 51.2, "energie": 107.35, "frequenz": 148.8, "durchmesser": 133.93, "energiedichte": 76.07, "bemerkung": "est phasellus sit amet erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin eu mi nulla" },
      { "id": 192, "kundID": 192, "mitarbeiterID": 192, "behandlungsdatum": "2021-09-11T23:14:24Z", "kundName": "Dania", "mitarbeiterName": "Reynolds", "behandlung": "behandlung3", "koerperregion": "koerperregion2", "preis": 873.1, "rabatt": 661.42, "energie": 907.36, "frequenz": 724.89, "durchmesser": 728.44, "energiedichte": 413.44, "bemerkung": "scelerisque mauris sit amet eros suspendisse accumsan tortor quis turpis sed" },
      { "id": 193, "kundID": 193, "mitarbeiterID": 193, "behandlungsdatum": "2021-08-21T16:52:44Z", "kundName": "Dagmar", "mitarbeiterName": "Madonna", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 126.25, "rabatt": 75.44, "energie": 718.84, "frequenz": 752.21, "durchmesser": 295.26, "energiedichte": 962.89, "bemerkung": "pellentesque viverra pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus semper" },
      { "id": 194, "kundID": 194, "mitarbeiterID": 194, "behandlungsdatum": "2021-11-05T09:43:08Z", "kundName": "Desmund", "mitarbeiterName": "Kissiah", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 784.22, "rabatt": 557.28, "energie": 620.46, "frequenz": 93.25, "durchmesser": 3.7, "energiedichte": 213.74, "bemerkung": "suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis" },
      { "id": 195, "kundID": 195, "mitarbeiterID": 195, "behandlungsdatum": "2021-02-01T07:23:28Z", "kundName": "Fredric", "mitarbeiterName": "Kirbie", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 229.06, "rabatt": 25.05, "energie": 314.03, "frequenz": 743.37, "durchmesser": 99.02, "energiedichte": 340.88, "bemerkung": "massa tempor convallis nulla neque libero convallis eget eleifend luctus ultricies eu nibh quisque id" },
      { "id": 196, "kundID": 196, "mitarbeiterID": 196, "behandlungsdatum": "2021-10-18T04:35:01Z", "kundName": "Jedd", "mitarbeiterName": "Dedie", "behandlung": "behandlung4", "koerperregion": "koerperregion1", "preis": 563.55, "rabatt": 453.38, "energie": 336.84, "frequenz": 526.35, "durchmesser": 821.72, "energiedichte": 891.46, "bemerkung": "montes nascetur ridiculus mus vivamus vestibulum sagittis sapien cum sociis natoque penatibus" },
      { "id": 197, "kundID": 197, "mitarbeiterID": 197, "behandlungsdatum": "2021-03-03T16:14:32Z", "kundName": "Bartlett", "mitarbeiterName": "Dora", "behandlung": "behandlung1", "koerperregion": "koerperregion2", "preis": 233.81, "rabatt": 292.86, "energie": 458.3, "frequenz": 549.01, "durchmesser": 473.88, "energiedichte": 533.98, "bemerkung": "dapibus nulla suscipit ligula in lacus curabitur at ipsum ac tellus semper interdum mauris ullamcorper purus sit amet nulla quisque" },
      { "id": 198, "kundID": 198, "mitarbeiterID": 198, "behandlungsdatum": "2021-06-15T22:56:31Z", "kundName": "Greer", "mitarbeiterName": "Floyd", "behandlung": "behandlung4", "koerperregion": "koerperregion5", "preis": 294.03, "rabatt": 214.28, "energie": 648.05, "frequenz": 72.69, "durchmesser": 815.51, "energiedichte": 826.5, "bemerkung": "rutrum neque aenean auctor gravida sem praesent id massa id nisl venenatis lacinia aenean sit amet justo morbi ut odio" },
      { "id": 199, "kundID": 199, "mitarbeiterID": 199, "behandlungsdatum": "2021-01-05T20:34:14Z", "kundName": "Beverlee", "mitarbeiterName": "Sarina", "behandlung": "behandlung4", "koerperregion": "koerperregion2", "preis": 306.27, "rabatt": 570.71, "energie": 110.82, "frequenz": 933.68, "durchmesser": 108.37, "energiedichte": 918.95, "bemerkung": "magna ac consequat metus sapien ut nunc vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere" },
      { "id": 200, "kundID": 200, "mitarbeiterID": 200, "behandlungsdatum": "2021-09-17T05:49:22Z", "kundName": "Roseann", "mitarbeiterName": "Tore", "behandlung": "behandlung2", "koerperregion": "koerperregion4", "preis": 194.96, "rabatt": 988.32, "energie": 306.28, "frequenz": 68.79, "durchmesser": 716.26, "energiedichte": 721.86, "bemerkung": "vestibulum aliquet ultrices erat tortor sollicitudin mi sit amet lobortis sapien sapien non" }
    ],
  }
  /* eslint-enable */

// ------------------------------------------------
// GET: Return Treatments
// ------------------------------------------------
mock.onGet('/apps/treatment/treatments').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
      perPage = 10,
      page = 1,
      sortBy = 'id',
      sortDesc = false,
  } = config.params
    /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.treatments.filter(
      treatment =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (treatment.kundName.toLowerCase().includes(queryLowered) || treatment.mitarbeiterName.toLowerCase().includes(queryLowered)),
    )
    /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      treatments: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Treatments
// ------------------------------------------------
mock.onGet('/apps/treatment/customer-treatments').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
      perPage = 5,
      page = 1,
      sortBy = 'id',
      sortDesc = false,
  } = config.params
    /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.treatments.filter(
      treatment =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (treatment.kundName.toLowerCase().includes(queryLowered) || treatment.mitarbeiterName.toLowerCase().includes(queryLowered)),
    )
    /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      treatments: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})