import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    "services": [{
        "ID": "ASC-1",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Oberlippe",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-2",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Kinn",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-3",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Gesicht komplett (Oberlippe + Kinn)",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-4",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Achsel",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-5",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Décollté",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-6",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Unterarme",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-7",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Rücken",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-8",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Bauch",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-9",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Bikini",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-10",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Bikini und Intim",
        "Price": "75",
        "Time": "25"
      },
      {
        "ID": "ASC-11",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Oberschenkel",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-12",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Unterschenkel",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-13",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Pobacke",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-14",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Pobacke inkl. Gesässsplat",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-15",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper, exkl. Bauch/Rücken oder Gesicht/Décollté",
        "Price": "210",
        "Time": "90"
      },
      {
        "ID": "ASC-16",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Oberlippe",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-17",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Kinn",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-18",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Gesicht komplett (Oberlippe + Kinn)",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-19",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Hals",
        "Price": "45",
        "Time": "15"
      },
      {
        "ID": "ASC-20",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Achsel",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-21",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Brust",
        "Price": "60",
        "Time": "20"
      },
      {
        "ID": "ASC-22",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Unterarme",
        "Price": "75",
        "Time": "25"
      },
      {
        "ID": "ASC-23",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Rücken",
        "Price": "75",
        "Time": "25"
      },
      {
        "ID": "ASC-24",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Bauch",
        "Price": "75",
        "Time": "25"
      },
      {
        "ID": "ASC-25",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Oberschenkel",
        "Price": "75",
        "Time": "25"
      },
      {
        "ID": "ASC-26",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Unterschenkel",
        "Price": "75",
        "Time": "25"
      },
      {
        "ID": "ASC-27",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Ganzkörper, exkl. Intimbereich",
        "Price": "210",
        "Time": "90"
      },
      {
        "ID": "ASC-28",
        "Category": "Tattooentfernung",
        "ServiceName": "Grösse S, bis 50cm2",
        "Price": "90",
        "Time": "20"
      },
      {
        "ID": "ASC-29",
        "Category": "Tattooentfernung",
        "ServiceName": "Grösse M, bis 100cm2",
        "Price": "170",
        "Time": "25"
      },
      {
        "ID": "ASC-30",
        "Category": "Tattooentfernung",
        "ServiceName": "Grösse L, bis 150cm2",
        "Price": "250",
        "Time": "30"
      },
      {
        "ID": "ASC-31",
        "Category": "Hollywood Carbon Peeling",
        "ServiceName": "Gesicht",
        "Price": "120",
        "Time": "60"
      },
      {
        "ID": "ASC-32",
        "Category": "Hollywood Carbon Peeling",
        "ServiceName": "Gesicht und Hals",
        "Price": "160",
        "Time": "75"
      },
      {
        "ID": "ASC-33",
        "Category": "Hollywood Carbon Peeling",
        "ServiceName": "Gesicht, Hals und Décollté",
        "Price": "200",
        "Time": "90"
      },
      {
        "ID": "ASC-34",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung 1. set",
        "Price": "160",
        "Time": "120"
      },
      {
        "ID": "ASC-35",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +1 Woche",
        "Price": "45",
        "Time": "90"
      },
      {
        "ID": "ASC-36",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +2 Woche",
        "Price": "65",
        "Time": "90"
      },
      {
        "ID": "ASC-37",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +3 Woche",
        "Price": "85",
        "Time": "90"
      },
      {
        "ID": "ASC-38",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +4 Woche",
        "Price": "105",
        "Time": "90"
      },
      {
        "ID": "ASC-39",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung 1. set",
        "Price": "170",
        "Time": "120"
      },
      {
        "ID": "ASC-40",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +1 Woche",
        "Price": "55",
        "Time": "45"
      },
      {
        "ID": "ASC-41",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +2 Woche",
        "Price": "75",
        "Time": "60"
      },
      {
        "ID": "ASC-42",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +3 Woche",
        "Price": "95",
        "Time": "75"
      },
      {
        "ID": "ASC-43",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +4 Woche",
        "Price": "115",
        "Time": "90"
      }
    ],
    "categories": [{
        "Name": "Haarentfernung - Frauen",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: false
      },
      {
        "Name": "Haarentfernung - Männer",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: false
      },
      {
        "Name": "Tattooentfernung",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: true
      },
      {
        "Name": "Hollywood Carbon Peeling",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: false,
        durchmesser: true
      },
      {
        "Name": "Wimpernverlängerung - 1:1 Einzeltechnick",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: false,
        durchmesser: true
      },
      {
        "Name": "Wimpernverlängerung - 3D-10D Volumentechnick",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: false,
        durchmesser: true
      }
    ]
  }
  /* eslint-enable */

// ------------------------------------------------
// GET: Return Customers
// ------------------------------------------------
mock.onGet('/apps/service/services').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
      category = '',
      perPage = 1000,
      page = 1,
      sortBy = 'ID',
      sortDesc = false,
  } = config.params
    /* eslint-enable */

  const categoryLowered = category.toLowerCase()
  const filteredData = data.services
    .filter(
      service =>
      (categoryLowered == '' || service.Category.toLowerCase() === categoryLowered))
    /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      services: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Customers
// ------------------------------------------------
mock.onGet('/apps/service/categories').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
      perPage = 1000,
      page = 1,
      sortBy = 'Name',
      sortDesc = false,
  } = config.params
    /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.categories.filter(
      cat =>
      (cat.Name.toLowerCase().includes(queryLowered)))
    /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      categories: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})


// ------------------------------------------------
// GET: Return Single Employee
// ------------------------------------------------
mock.onGet(/\/apps\/service\/services\/\d+/).reply(config => {
  // Get event id from URL
  let employeeId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  employeeId = Number(employeeId)

  const employeeIndex = data.employees.findIndex(e => e.id === employeeId)
  const employee = data.employees[employeeIndex]

  if (employee) return [200, employee]
  return [404]
})