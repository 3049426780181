import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    employees: [{ "id": 1, "vorname": "Caryl", "nachname": "Fennessy", "emailadresse": "cfennessy0@mozilla.com", "role": "Employee" },
      { "id": 2, "vorname": "Orel", "nachname": "Brandsma", "emailadresse": "obrandsma1@freewebs.com", "role": "Employee" },
      { "id": 3, "vorname": "Norry", "nachname": "Brosi", "emailadresse": "nbrosi2@adobe.com", "role": "Employee" },
      { "id": 4, "vorname": "Eb", "nachname": "Anstead", "emailadresse": "eanstead3@istockphoto.com", "role": "SuperAdmin" },
      { "id": 5, "vorname": "Gabriela", "nachname": "Tommis", "emailadresse": "gtommis4@devhub.com", "role": "SuperAdmin" },
      { "id": 6, "vorname": "Cymbre", "nachname": "Andrei", "emailadresse": "candrei5@mysql.com", "role": "Employee" },
      { "id": 7, "vorname": "Cleo", "nachname": "Gulliver", "emailadresse": "cgulliver6@pbs.org", "role": "SuperAdmin" },
      { "id": 8, "vorname": "Gail", "nachname": "Prydden", "emailadresse": "gprydden7@google.fr", "role": "SuperAdmin" },
      { "id": 9, "vorname": "Rayshell", "nachname": "Hardwicke", "emailadresse": "rhardwicke8@who.int", "role": "SuperAdmin" },
      { "id": 10, "vorname": "Kimberlee", "nachname": "Thying", "emailadresse": "kthying9@adobe.com", "role": "SuperAdmin" },
      { "id": 11, "vorname": "Riobard", "nachname": "Monteith", "emailadresse": "rmonteitha@canalblog.com", "role": "Employee" },
      { "id": 12, "vorname": "Lauryn", "nachname": "Paskerful", "emailadresse": "lpaskerfulb@umich.edu", "role": "SuperAdmin" },
      { "id": 13, "vorname": "Guntar", "nachname": "McCafferty", "emailadresse": "gmccaffertyc@apple.com", "role": "Employee" },
      { "id": 14, "vorname": "Abby", "nachname": "Gamlyn", "emailadresse": "agamlynd@amazonaws.com", "role": "Employee" },
      { "id": 15, "vorname": "Helen", "nachname": "Charteris", "emailadresse": "hcharterise@bbb.org", "role": "Employee" },
      { "id": 16, "vorname": "Keane", "nachname": "Malamore", "emailadresse": "kmalamoref@accuweather.com", "role": "Employee" },
      { "id": 17, "vorname": "Booth", "nachname": "Carbin", "emailadresse": "bcarbing@addtoany.com", "role": "Employee" },
      { "id": 18, "vorname": "Clarissa", "nachname": "Whittock", "emailadresse": "cwhittockh@joomla.org", "role": "Employee" },
      { "id": 19, "vorname": "Othello", "nachname": "Bugby", "emailadresse": "obugbyi@flickr.com", "role": "Employee" },
      { "id": 20, "vorname": "Sallie", "nachname": "Klawi", "emailadresse": "sklawij@ameblo.jp", "role": "SuperAdmin" }
    ],
  }
  /* eslint-enable */

// ------------------------------------------------
// GET: Return Customers
// ------------------------------------------------
mock.onGet('/apps/employee/employees').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
      perPage = 10,
      page = 1,
      sortBy = 'id',
      sortDesc = false,
  } = config.params
    /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.employees.filter(
      employee =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (employee.vorname.toLowerCase().includes(queryLowered) || employee.nachname.toLowerCase().includes(queryLowered)),
    )
    /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      employees: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})


// ------------------------------------------------
// GET: Return Single Employee
// ------------------------------------------------
mock.onGet(/\/apps\/employee\/employees\/\d+/).reply(config => {
  // Get event id from URL
  let employeeId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  employeeId = Number(employeeId)

  const employeeIndex = data.employees.findIndex(e => e.id === employeeId)
  const employee = data.employees[employeeIndex]

  if (employee) return [200, employee]
  return [404]
})